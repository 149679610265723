import { gql } from '@apollo/client';

export const GetDowntimeReasons = gql`
    query GetDowntimeReasons {
        downtime_reasons {
            downtime_reason_ID,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            downtime_reason
        }
    }
`;

export const GetDowntimes = gql`
    query GetDowntimes($product_record_ID: Int) {
        downtimes(production_record_ID: $production_record_ID) {
            downtime_ID
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            date_scheduled,
            downtime_hours,
            downtime_minutes,
            downtime_reason,
            downtime_reason_ID,
            machine_ID,
            notes,
            production_record_ID,
            shift_ID,
            time_clock_record_ID
        }
    }
`;

export const ArchiveDowntime = gql`
	mutation downtimeArchive($input: DowntimeInput) {
		downtimeArchive (input: $input) {
            UpdatedDowntime {
                downtime_ID
                archiver_ID,
                creator_ID,
                date_archived,
                date_created,
                downtime_hours,
                downtime_minutes,
                downtime_reason,
                downtime_reason_ID,
                notes,
                production_record_ID
            }
		}
	}
`;


export const SaveDowntimeReason = gql`
	mutation downtimeReasonSave($input: DowntimeReasonInput) {
		downtimeReasonSave (input: $input) {
            UpdatedDowntimeReason {
                downtime_reason_ID,
                archiver_ID,
                creator_ID,
                date_archived,
                date_created,
                downtime_reason
            }
		}
	}
`;

export const SaveDowntime = gql`
	mutation downtimeSave($input: DowntimeInput) {
		downtimeSave (input: $input) {
            UpdatedDowntime {
                downtime_ID
                archiver_ID,
                creator_ID,
                date_archived,
                date_created,
                date_scheduled,
                downtime_hours,
                downtime_minutes,
                downtime_reason_ID,
                machine_ID,
                notes,
                production_record_ID,
                shift_ID,
                time_clock_record_ID
            }
		}
	}
`;