import React from 'react';
// import moment from 'moment';
import _ from 'lodash';
import {flowRight as compose} from 'lodash';
import { queryWithLoading } from '../utilities/NSDb.js';
import { useState } from 'react';
// import dayjs from 'dayjs';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
import { useMutation } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// GRAPHQL QUERY
import { 
    GetUserDetailsPage,
    SaveUser
} from '../users/users_graphql.js';

function EmployeeContent(props) {

    const { departments: Departments, positions: Positions } = props.componentData;
    const [save_user] = useMutation(SaveUser);

    // Set the value of the return back button based on the called_from value is props
    let return_back_button_text = 'Return To Previous Page'
    if ( props.called_from === 'employee_list') {
        return_back_button_text = 'Back to Employee List';
    }

    // const DefaultUser = {
    //     user_ID: 0,
    //     active: 1,
    //     apprenticeship: '',
    //     archiver_ID: 0,
    //     assignment: '',
    //     badge_number: '',
    //     citizenship: '',
    //     citizenship_note: '',
    //     college: '',
    //     creator_ID: 0,
    //     date_archived: '',
    //     date_birth: '',
    //     date_created: '',
    //     date_hired: '',
    //     date_next_review: '',
    //     date_visa_expiration: '',
    //     date_visa_issued: '',
    //     default_cost_sub_group: '',
    //     department_ID: 0,
    //     educational_grade: '',
    //     email: '',
    //     emergency_note: '',
    //     employee_fax: '',
    //     employee_phone: '',
    //     employee_number: '',
    //     employment_status: 1,
    //     first_name: '',
    //     gender: '',
    //     home_address: '',
    //     home_phone: '',
    //     hourly_rate: '',
    //     individual_job_description: '',
    //     individual_mission_statement: '',
    //     last_name: '',
    //     logged_in: 0,
    //     martial_status: '',
    //     middle_name: '',
    //     mobile_phone: '',
    //     next_review_form_ID: 0,
    //     other_experience: '',
    //     overtime_rate: '',
    //     password: '',
    //     position_ID: 0,
    //     reports_to_user_ID: 0,
    //     source_of_hire: '',
    //     visa_type: '',
    //     user_name: '',
    //     user_type_ID: 0,
    //     work_eligibility_proof: '',
    //     work_schedule_ID: 0
    // };

    const [User, setUser] = useState({ ...props.User });
    const [BaseState, setBaseState] = useState({
        action: props.action || '',
        Users: props.Users || [],
        WorkSchedules: props.WorkSchedules || []
    });

    // Archive / Unarchive the user
    // archiveUser = (user_ID, archiver_ID=0) => {
    //     let new_archiver_ID = archiver_ID > 0 ? 0 : 1;
    //     saveUser(user_ID, new_archiver_ID, 'archive');
    // }

    // Save user details
    async function saveUser(user_ID) {

        let SaveData = {...User};

        // Parse string values that are IDs to be integers, saving stringified numbers to the database throws an error.
        let MakeIntegers = ['active', 'user_ID', 'archiver_ID', 'company_ID', 'creator_ID', 'default_cost_sub_group', 'department_ID', 'position_ID', 'reports_to_user_ID', 'user_type_ID', 'work_schedule_ID'];
        

        MakeIntegers.forEach((mi) => SaveData[mi] = parseInt(SaveData[mi]));

        // Remove save mesasge and button text from User object for saving
        SaveData = _.omit(SaveData, ['save_button_text','save_message','employment_status']);

        const result = await save_user({ variables: { input: { ...SaveData } }});

        // Update the Users - save returns an array of all User objects
        if(result.data && result.data.userSave.User) {

            let TempUsers = [ ...BaseState.Users ];
            let temp_action = '';

            // If this was a create, update the User object with the new user_ID
            if(parseInt(user_ID) === 0) {

                // Update the button and save message to show for created success
                setUser({
                    ...User,
                    user_ID: parseInt(result.data.userSave.User.user_ID),
                    save_button_text: 'Saved'
                });

                // Push the new User object to the front of the Users array
                TempUsers.unshift(result.data.userSave.User);
                temp_action = 'added_user';

            } else {
            
                temp_action = 'updated_user';

                // Update the User with the new user_ID that was created
                setUser({
                    ...User,
                    save_button_text: 'Saved'
                });

                // Update this updated User data in the TempUsers array to later send back to the employee list
                TempUsers = TempUsers.map((u) => {
                    if(parseInt(u.user_ID) === parseInt(User.user_ID)) {
                        return User;
                    } else {
                        return u;
                    }
                })

            }

            // For insert and update, set the action and new Users
            setBaseState({
                ...BaseState,
                action: temp_action,
                Users: TempUsers
            });

            // Update the User in the parent component's list of Users
            props.handleChangeParentBaseState(props.action, TempUsers)
        }
    }

    // Handler function for form's changing inputs
    function changeInputValue(name, value) {

        if ( name === 'hourly_rate' || name === 'overtime_rate' || name === 'benefits_cost' || name === 'salary' ) {
            if(value !== '') {
                // Strips non-numeric characters, leaves decimal
                value = value.replace(/[^\d.-]/g, '');
                // Handle decimal length restrictions
                if (value.includes('.')) {
                    let decimal_length = value.split('.')[1].length;
                    value = decimal_length > 1 ? parseFloat(value).toFixed(2) : value;
                };
            }
        } 

        setUser({
            ...User, 
            [name]: value,
            save_button_text: 'Save', 
            save_message: ''
        });

    }

    return (
        <>
            <div>

                {/* HEADER WITH BUTTONS */}
                <div className="row mb-3">
                    <div className="text-center h4">
                        {User.first_name} {User.last_name}
                    </div>
                    <div className="text-center clearfix mt-2">
                            <span className="">
                                <button className="btn fw-bold col-auto border-success border-3 text-success float-start" onClick={() => saveUser(parseInt(User.user_ID))}><FontAwesomeIcon icon="fa-floppy-disk" className="me-2" />{User.save_button_text} {User.save_button_text === 'Saved' && <FontAwesomeIcon icon="fa-check" className="ms-1" />}</button>
                            </span>
                            {/* {
                                User.save_message !== '' &&
                                <span className="ms-4">
                                    <div className="alert alert-success w-auto p-2 mb-0">{User.save_message}</div>
                                </span>
                            } */}
                            <span className="">
                                <button className="btn fw-bold col-auto border-secondary border-3 text-secondary float-end" onClick={() => props.handleChangeParentBaseState('', BaseState.Users)}><FontAwesomeIcon icon="fa-arrow-rotate-left" className="me-2" />{return_back_button_text}</button>
                            </span>
                    </div>
                </div>

                {/* USER INFORMATION SECTIONS */}
                <div className="row">

                    {/* PERSONAL INFO CONTAINER */}
                    <div className="col">
                        <div className="card h-100 border border-secondary">
                            <div className="card-header text-center text-white bg-secondary">
                                Personal Info
                            </div>
                            <div className="card-body bg-light">
                                <div className="form-floating mb-2">
                                    <input className="form-control float" id="first_name" value={User.first_name || ''} onChange={(event) => changeInputValue('first_name', event.target.value)} /> 
                                    <label className="text-muted" htmlFor="first_name">First Name</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <input className="form-control float" id="middle_name" value={User.middle_name || ''} onChange={(event) => changeInputValue('middle_name', event.target.value)} /> 
                                    <label className="text-muted" htmlFor="middle_name">Middle Name</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <input className="form-control float" id="last_name" value={User.last_name || ''} onChange={(event) => changeInputValue('last_name', event.target.value)} /> 
                                    <label className="text-muted" htmlFor="last_name">Last Name</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <input className="form-control float" id="employee_number" value={User.employee_number || ''} onChange={(event) => changeInputValue('employee_number', event.target.value)} /> 
                                    <label className="text-muted" htmlFor="employee_number">Employee Number</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <input className="form-control float" id="badge_number" value={User.badge_number || ''} onChange={(event) => changeInputValue('badge_number', event.target.value)} /> 
                                    <label className="text-muted" htmlFor="badge_number">Badge Number</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <select 
                                        className="form-select" 
                                        disabled={false}
                                        id="Gender"
                                        onChange={(event) => changeInputValue('gender', event.target.value)}
                                        placeholder="Gender"
                                        value={User.gender}
                                    >
                                        <option key={0} value="">Select Gender</option>
                                        <option key={1} value="Female">Female</option>
                                        <option key={2} value="Male">Male</option>
                                    </select>
                                    <label className="text-muted" htmlFor="Gender">Gender</label>
                                </div>
                                {/* <div className="form-floating mb-2">
                                    <select 
                                        className="form-select" 
                                        disabled={false}
                                        id="Martial Status"
                                        onChange={(event) => changeInputValue('martial_status', event.target.value)}
                                        placeholder="Martial Status"
                                        value={User.martial_status}
                                    >
                                        <option key={0} value="">Select Martial Status</option>
                                        <option key={1} value="">Common Law</option>
                                        <option key={2} value="">Divorced</option>
                                        <option key={3} value="">Married</option>
                                        <option key={4} value="">Single</option>
                                        <option key={5} value="">Widowed</option>
                                    </select>
                                    <label className="text-muted" htmlFor="Martial Status">Martial Status</label>
                                </div> */}
                                <div className="form-floating">
                                    <input type="date" className="form-control float" id="date_birth" value={User.date_birth || ""} onChange={(event) => changeInputValue('date_birth', event.target.value)} /> 
                                    <label className="text-muted" htmlFor="date_birth">Date of Birth</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* END PERSONAL INFO CONTAINER */}

                    {/* CONTACT INFO CONTAINER */}
                    <div className="col">
                        <div className="card h-100 border border-secondary">
                            <div className="card-header text-center text-white bg-secondary">
                                Contact Info
                            </div>
                            <div className="card-body bg-light">
                                <div className="form-floating mb-2">
                                    <input className="form-control float" id="email" value={User.email || ''} onChange={(event) => changeInputValue('email', event.target.value)} /> 
                                    <label className="text-muted" htmlFor="email">Work Email</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <input className="form-control float" id="email_personal" value={User.email_personal || ''} onChange={(event) => changeInputValue('email_personal', event.target.value)} /> 
                                    <label className="text-muted" htmlFor="email_personal">Personal Email</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <input type="phone" className="form-control float" id="employee_phone" value={User.employee_phone || ''} onChange={(event) => changeInputValue('employee_phone', event.target.value)} /> 
                                    <label className="text-muted" htmlFor="employee_phone">Employee Phone</label>
                                </div>
                                {/* <div className="form-floating mb-2">
                                    <input className="form-control float" id="employee_fax" value={User.employee_fax} onChange={(event) => changeInputValue('employee_fax', event.target.value)} /> 
                                    <label className="text-muted" htmlFor="employee_fax">Employee Fax</label>
                                </div> */}
                                <div className="form-floating mb-2">
                                    <input className="form-control float" id="mobile_phone" value={User.mobile_phone || ''} onChange={(event) => changeInputValue('mobile_phone', event.target.value)} /> 
                                    <label className="text-muted" htmlFor="mobile_phone">Mobile Phone</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <input className="form-control float" id="home_phone" value={User.home_phone || ''} onChange={(event) => changeInputValue('home_phone', event.target.value)} /> 
                                    <label className="text-muted" htmlFor="home_phone">Home Phone</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <textarea className="form-control" id="home_address" value={User.home_address || ''} rows="2" onChange={(event) => changeInputValue('home_address', event.target.value)} />
                                    {/* <input className="form-control float" id="home_address" value={User.home_address} onChange={(event) => changeInputValue('home_address', event.target.value)} />  */}
                                    <label className="text-muted" htmlFor="home_address">Home Address</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <textarea className="form-control" id="emergency_note" value={User.emergency_note || ''} rows="2" onChange={(event) => changeInputValue('emergency_note', event.target.value)} />
                                    {/* <input className="form-control float" id="emergency_note" value={User.emergency_note} onChange={(event) => changeInputValue('emergency_note', event.target.value)} />  */}
                                    <label className="text-muted" htmlFor="emergency_note">Emergency Note</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* END CONTACT INFO CONTAINER */}

                    {/* EMPLOYMENT DETAILS CONTAINER */}
                    <div className="col">
                        <div className="card h-100 border border-secondary">
                            <div className="card-header text-center text-white bg-secondary">
                                Employment Details
                            </div>
                            <div className="card-body bg-light">
                                <div className="form-floating mb-2">
                                    {/* There is a db column employment_status that included 'On Leave' as an option. Unless we use that, just make this input tied to 'active' in Users */}
                                    <select 
                                        className="form-select" 
                                        disabled={false}
                                        id="Employment Status"
                                        onChange={(event) => changeInputValue('active', event.target.value)}
                                        placeholder="Employment Status"
                                        value={User.active}
                                    >
                                        <option key={0} value="0">Inactive</option>
                                        <option key={1} value="1">Active</option>
                                        {/* <option key={2} value="">On Leave</option> */}
                                    </select>
                                    <label className="text-muted" htmlFor="Employment Status">Employment Status</label>
                                </div>
                                <div className="form-floating mb-2">
                                    {/* TO DO: Pull this from the db */}
                                    <select 
                                        className="form-select" 
                                        disabled={false}
                                        id="User Type"
                                        onChange={(event) => changeInputValue('user_type_ID', event.target.value)}
                                        placeholder="User Type"
                                        value={User.user_type_ID}
                                    >
                                        <option key={1} value="1">Subcontractor</option>
                                        <option key={2} value="2">Operator</option>
                                        <option key={3} value="3">Office</option>
                                        <option key={4} value="4">Admin</option>
                                    </select>
                                    <label className="text-muted" htmlFor="User Type">User Type</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <input className="form-control float" id="user_name" value={User.user_name || ''} onChange={(event) => changeInputValue('user_name', event.target.value)} /> 
                                    <label className="text-muted" htmlFor="user_name">Login Username</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <input type="date" className="form-control float" id="date_hired" value={User.date_hired || ""} onChange={(event) => changeInputValue('date_hired', event.target.value)} /> 
                                    <label className="text-muted" htmlFor="date_hired">Hired Date</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <input type="date" className="form-control float" id="date_next_review" value={User.date_next_review || ""} onChange={(event) => changeInputValue('date_next_review', event.target.value)} /> 
                                    <label className="text-muted" htmlFor="date_next_review">Next Review Date</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <input className="form-control float" id="source_of_hire" value={User.source_of_hire || ''} onChange={(event) => changeInputValue('source_of_hire', event.target.value)} /> 
                                    <label className="text-muted" htmlFor="source_of_hire">Source of Hire</label>
                                </div>
                                <div className="text-center mb-2">
                                    <div className="btn-group btn-group-sm" role="group">
                                        <button className={`btn btn-outline-secondary ${(parseInt(User.direct_labor) === 1 && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => changeInputValue('direct_labor', 1)}>Direct Labor</button>
                                        <button className={`btn btn-outline-secondary ${(parseInt(User.direct_labor) === 0 && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => changeInputValue('direct_labor', 0)}>Indirect Labor</button>
                                    </div>                            
                                </div>
                                <div className="row">
                                    <div className="form-floating mb-2 col-6 pe-2">
                                        <input className="form-control float" id="hourly_rate" value={`$${User.hourly_rate || ''}`} onChange={(event) => changeInputValue('hourly_rate', event.target.value)} /> 
                                        <label className="text-muted ms-2" htmlFor="hourly_rate">Hourly Rate</label>
                                    </div>
                                    <div className="form-floating mb-2 col-6 ps-2">
                                        <input className="form-control float" id="overtime_rate" value={`$${User.overtime_rate || ''}`} onChange={(event) => changeInputValue('overtime_rate', event.target.value)} /> 
                                        <label className="text-muted ms-2" htmlFor="overtime_rate">Overtime Rate</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-floating mb-2 col-6 pe-2">
                                        <input className="form-control float" id="salary" value={`$${User.salary || ''}`} onChange={(event) => changeInputValue('salary', event.target.value)} /> 
                                        <label className="text-muted ms-2" htmlFor="salary">Salary</label>
                                    </div>
                                    <div className="form-floating mb-2 col-6 ps-2">
                                        <input className="form-control float" id="benefits_cost" value={`$${User.benefits_cost || ''}`} onChange={(event) => changeInputValue('benefits_cost', event.target.value)} /> 
                                        <label className="text-muted ms-2" htmlFor="benefits_cost">Monthly Benefits</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* END EMPLOYMENT DETAILS CONTAINER */}

                    {/* JOB DESCRIPTION CONTAINER */}
                    <div className="col">
                        <div className="card h-100 border border-secondary">
                            <div className="card-header text-center text-white bg-secondary">
                                Job Description
                            </div>
                            <div className="card-body bg-light">
                                <div className="form-floating mb-2">
                                    <select 
                                        className="form-select" 
                                        disabled={false}
                                        id="Company"
                                        onChange={(event) => changeInputValue('company_ID', event.target.value)}
                                        placeholder="Company"
                                        value={User.company_ID}
                                    >
                                        <option key={0} value="">Select Company</option>
                                        {props.Companies.map((c) => {
                                            return (
                                                <option key={c.company_ID} value={c.company_ID}>{c.company}</option>
                                            )
                                        })}
                                    </select>
                                    <label className="text-muted" htmlFor="Company">Company</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <select 
                                        className="form-select" 
                                        disabled={false}
                                        id="Department"
                                        onChange={(event) => changeInputValue('department_ID', event.target.value)}
                                        placeholder="Department"
                                        value={User.department_ID}
                                    >
                                        <option key={0} value="">Select Department</option>
                                        {Departments.map((d) => {
                                            return (
                                                <option key={d.department_ID} value={d.department_ID}>{d.department_name}</option>
                                            )
                                        })}
                                    </select>
                                    <label className="text-muted" htmlFor="Department">Department</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <select 
                                        className="form-select" 
                                        disabled={false}
                                        id="Position"
                                        onChange={(event) => changeInputValue('position_ID', event.target.value)}
                                        placeholder="Position"
                                        value={User.position_ID}
                                    >
                                        <option key={0} value="">Select Position</option>
                                        {Positions.map((p) => {
                                            return (
                                                <option key={p.position_ID} value={p.position_ID}>{p.position}</option>
                                            )
                                        })}
                                    </select>
                                    <label className="text-muted" htmlFor="Position">Position</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <select 
                                        className="form-select" 
                                        disabled={false}
                                        id="Work Schedule"
                                        onChange={(event) => changeInputValue('work_schedule_ID', event.target.value)}
                                        placeholder="Work Schedule"
                                        value={User.work_schedule_ID}
                                    >
                                        <option key={0} value="">Select Work Schedule</option>
                                        {BaseState.WorkSchedules.map((ws) => {
                                            return (
                                                <option key={ws.work_schedule_ID} value={ws.work_schedule_ID}>{ws.description}</option>
                                            )
                                        })}
                                    </select>
                                    <label className="text-muted" htmlFor="Work Schedule">Work Schedule</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <select 
                                        className="form-select" 
                                        disabled={false}
                                        id="Reports To"
                                        onChange={(event) => changeInputValue('reports_to_user_ID', event.target.value)}
                                        placeholder="Reports To"
                                        value={User.reports_to_user_ID}
                                    >
                                        <option key={0} value="">Select Reports To</option>
                                        {BaseState.Users.filter((u) => parseInt(u.user_type_ID) > 2 && parseInt(u.active) === 1 ).map((u) => {
                                            return (
                                                <option key={u.user_ID} value={u.user_ID}>{u.last_name}, {u.first_name}</option>
                                            )
                                        })}
                                    </select>
                                    <label className="text-muted" htmlFor="Reports To">Reports To</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <textarea className="form-control" id="assignment" value={User.assignment || ''} rows="2" onChange={(event) => changeInputValue('assignment', event.target.value)} />
                                    {/* <input className="form-control float" id="assignment" value={User.assignment} onChange={(event) => changeInputValue('assignment', event.target.value)} />  */}
                                    <label className="text-muted" htmlFor="assignment">Assignment</label>
                                </div>

                                {/* <div className="form-floating mb-2">
                                    <input className="form-control float" disabled id="default_cost_sub_group" value={User.default_cost_sub_group} onChange={(event) => changeInputValue('default_cost_sub_group', event.target.value)} /> 
                                    <label className="text-muted" htmlFor="default_cost_sub_group">Default Cost Sub Group</label>
                                </div> */}
                                <div className="form-floating mb-2">
                                    <textarea className="form-control" id="individual_job_description" value={User.individual_job_description || ''} rows="2" onChange={(event) => changeInputValue('individual_job_description', event.target.value)} />
                                    {/* <input className="form-control float" id="individual_job_description" value={User.individual_job_description} onChange={(event) => changeInputValue('individual_job_description', event.target.value)} />  */}
                                    <label className="text-muted" htmlFor="individual_job_description">Individual Job Description</label>
                                </div>
                                {/* <div className="form-floating mb-2">
                                    <textarea className="form-control" id="individual_mission_statement" value={User.individual_mission_statement} rows="2" onChange={(event) => changeInputValue('individual_mission_statement', event.target.value)} />
                                    <input className="form-control float" id="individual_mission_statement" value={User.individual_mission_statement} onChange={(event) => changeInputValue('individual_mission_statement', event.target.value)} />
                                    <label className="text-muted" htmlFor="individual_mission_statement">Individual Mission Statement</label>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>            
            </div>


                    {/* 
                    
                        BACKGROUND

                        <div className="card">
                            <div className="card-header text-center text-white bg-secondary">
                                Background
                            </div>
                            <div className="card-body bg-light">
                                <div className="form-floating mb-2">
                                    <input className="form-control float" id="educational_grade" value={User.educational_grade} onChange={(event) => changeInputValue('educational_grade', event.target.value)} /> 
                                    <label className="text-muted" htmlFor="educational_grade">Educational Grade</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <textarea className="form-control" id="college" value={User.college} rows="2" onChange={(event) => changeInputValue('college', event.target.value)} />
                                    
                                    <label className="text-muted" htmlFor="college">College</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <textarea className="form-control" id="apprenticeship" value={User.apprenticeship} rows="2" onChange={(event) => changeInputValue('apprenticeship', event.target.value)} />
                                    
                                    <label className="text-muted" htmlFor="apprenticeship">Apprenticeship</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <textarea className="form-control" id="other_experience" value={User.other_experience} rows="2" onChange={(event) => changeInputValue('other_experience', event.target.value)} />
                                    <input className="form-control float" id="other_experience" value={User.other_experience} onChange={(event) => changeInputValue('other_experience', event.target.value)} />
                                    <label className="text-muted" htmlFor="other_experience">Other Experience</label>
                                </div>
                            </div>
                        </div> 

                        CITIZENSHIP

                        <div className="col-3">
                            <div className="card h-100 mb-3">
                                <div className="card-header text-center text-white bg-secondary">
                                    Citizenship
                                </div>
                                <div className="card-body bg-light">
                                    <div className="form-floating mb-2">
                                        <input className="form-control float" id="citizenship" value={User.citizenship} onChange={(event) => changeInputValue('citizenship', event.target.value)} /> 
                                        <label className="text-muted" htmlFor="citizenship">Citizenship</label>
                                    </div>
                                    <div className="form-floating mb-2">
                                        <input className="form-control float" id="date_visa_issued" value={User.date_visa_issued} onChange={(event) => changeInputValue('date_visa_issued', event.target.value)} /> 
                                        <label className="text-muted" htmlFor="date_visa_issued">Visa Issued Date</label>
                                    </div>
                                    <div className="form-floating mb-2">
                                        <input className="form-control float" id="date_visa_expiration" value={User.date_visa_expiration} onChange={(event) => changeInputValue('date_visa_expiration', event.target.value)} /> 
                                        <label className="text-muted" htmlFor="date_visa_expiration">Visa Expiration Date</label>
                                    </div>
                                    <div className="form-floating mb-2">
                                        <input className="form-control float" id="visa_type" value={User.visa_type} onChange={(event) => changeInputValue('visa_type', event.target.value)} /> 
                                        <label className="text-muted" htmlFor="visa_type">Visa Type</label>
                                    </div>
                                    <div className="form-floating mb-2">
                                        <input className="form-control float" id="work_eligibility_proof" value={User.work_eligibility_proof} onChange={(event) => changeInputValue('work_eligibility_proof', event.target.value)} /> 
                                        <label className="text-muted" htmlFor="work_eligibility_proof">Work Eligibility Proof</label>
                                    </div>
                                    <div className="form-floating mb-2">
                                        <input className="form-control float" id="citizenship_note" value={User.citizenship_note} onChange={(event) => changeInputValue('citizenship_note', event.target.value)} /> 
                                        <label className="text-muted" htmlFor="citizenship_note">Note</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    */}

        </>
    )
}

// export const EmployeeComponent = EmployeeContent;
export const EmployeeComponent = compose(
	queryWithLoading({
		gqlString: GetUserDetailsPage,
        name: "componentData"
	})
)(EmployeeContent)

