import React from 'react';
// import { Multiselect } from 'multiselect-react-dropdown';
// import {flowRight as compose} from 'lodash';
// import { queryWithLoading } from '../utilities/NSDb.js';
// import { graphql } from '@apollo/react-hoc';
import { useState } from 'react';
// import _ from "lodash";
// import dayjs from 'dayjs';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
// import { useMutation, useQuery } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function DowntimeFormContent(props) {
    // Set const values from props
    const { closeDowntime, DowntimeReasons, saveDowntime, SelectedDowntime: DefaultSelectedDowntime } = props;

    // const DefaultSelectedDowntime = {
    //     downtime_ID: 0,
    //     archiver_ID: 0,
    //     creator_ID: 0,
    //     date_archived: '',
    //     date_created: '',
    //     downtime_hours: 0,
    //     downtime_minutes: 0,
    //     downtime_reason_ID: 0,
    //     notes: ''
    // };

    const [SelectedDowntime, setSelectedDowntime] = useState({ ...DefaultSelectedDowntime });

    // const [BaseState, setBaseState] = useState({loaded: true})

    // Update the state for any changing input in the Production Record details form
    function changeSelectedDowntimeInputValue(name, value) {

        // For inputs that must be integers, do not allow non numeric characters, and parseInt on the value
        if ( name === 'downtime_hours' || name === 'downtime_minutes' ) {
            if(value !== '') {
                value = parseInt(value)
                if(name === 'downtime_minutes' && value > 60) {
                    return null;
                }
                if( isNaN(value) ) {
                    value = 0;
                }
            } else {
                // This is to avoid attempting to save "" as an INT
                value = 0;
            }
        }

        setSelectedDowntime({
            ...SelectedDowntime,
            [name]: value,
            save_button_text: 'Save', 
            save_message: ''
        })

    }

    // Handler for Save button click
    function handleSave() {
        // VALIDATION: Must select a downtime reason
        if(parseInt(SelectedDowntime.downtime_reason_ID) === 0) {
            setSelectedDowntime({
                ...SelectedDowntime,
                save_button_text: 'Save', 
                save_message: 'Enter downtime reason'
            })
        }
        // VALIDATION: Do not allow 0 time entered
        else if(parseInt(SelectedDowntime.downtime_hours) === 0 && parseInt(SelectedDowntime.downtime_minutes) === 0) {
            setSelectedDowntime({
                ...SelectedDowntime,
                save_button_text: 'Save', 
                save_message: 'Enter downtime length'
            })
        }
        // VALIDATION: Must have note
        else if(SelectedDowntime.notes === '') {
            setSelectedDowntime({
                ...SelectedDowntime,
                save_button_text: 'Save', 
                save_message: 'Note required'
            })
        }

        if(SelectedDowntime.notes !== '' && parseInt(SelectedDowntime.downtime_reason_ID) > 0 && ( ( SelectedDowntime.downtime_hours !== '' && SelectedDowntime.downtime_hours !== 0 ) || ( SelectedDowntime.downtime_minutes !== '' && SelectedDowntime.downtime_minutes !== 0 ) ) ) {
            setSelectedDowntime({
                ...SelectedDowntime, 
                save_button_text: 'Saved'
            })

            // Save Downtime form to parent component
            saveDowntime({...SelectedDowntime, save_button_text: 'Saved'})
        }
    }

    return (
        <>
            {/* Downtime Reason */}
            <td className="border-0 col-2">
                <div className="form-floating">
                    <select 
                        className="form-select mb-3" 
                        disabled={false}
                        id="downtime_reason"
                        onChange={(event) => changeSelectedDowntimeInputValue('downtime_reason_ID', event.target.value)}
                        placeholder="Downtime Reason"
                        value={SelectedDowntime.downtime_reason_ID}
                    >
                        <option key={0} value={0}>Select Downtime Reason</option>
                        {DowntimeReasons.map((dr) => {
                            return (
                                <option key={dr.downtime_reason_ID} value={dr.downtime_reason_ID}>{dr.downtime_reason}</option>
                            )
                        })}
                    </select>
                    <label className="text-muted" htmlFor="downtime_reason">Downtime Reason</label>
                </div>
            </td>

            {/* Hours */}
            <td className="border-0 col-1">
                <div className="form-floating">
                    <input className="form-control" id="downtime_hours" placeholder="Good" value={SelectedDowntime.downtime_hours || ""} onChange={(event) => changeSelectedDowntimeInputValue('downtime_hours', event.target.value)} />
                    <label className="text-muted" htmlFor="downtime_hours">Hours</label>
                </div>
            </td>

            {/* Minutes */}
            <td className="border-0 col-1">
                <div className="form-floating">
                    <input className="form-control" id="downtime_minutes" placeholder="Good" value={SelectedDowntime.downtime_minutes || ""} onChange={(event) => changeSelectedDowntimeInputValue('downtime_minutes', event.target.value)} />
                    <label className="text-muted" htmlFor="downtime_minutes">Minutes</label>
                </div>
            </td>

            {/* Downtime Record Notes */}
            <td className="border-0 col-6">
                <div className="form-floating">
                    <textarea className="form-control" id="downtime_record_notes" placeholder="Enter notes here" value={SelectedDowntime.notes} rows="2" onChange={(event) => changeSelectedDowntimeInputValue('notes', event.target.value)} />
                    <label className="text-muted" htmlFor="downtime_record_notes">Note - <span className="text-danger">Required</span></label>
                </div>
            </td>

            {/* Save Downtime button and Message */}
            {
                SelectedDowntime.save_message !== '' && 
                <td className="border-0 col-2 pt-4">
                    <div className="text-danger">{SelectedDowntime.save_message}</div>
                </td>
            }
            {
                SelectedDowntime.save_message === '' && 
                <>
                <td className="border-0 col-1 pt-0">
                    <button className="btn mt-3 border-success border-3 fw-bold text-success" disabled={SelectedDowntime.tooling_description === ''} onClick={() => handleSave()}><FontAwesomeIcon icon="fa-floppy-disk" className="me-2" />{SelectedDowntime.save_button_text}</button>
                </td>
                <td className="border-0 col-1 pt-0 pe-0">
                    <button className="btn mt-3 border-secondary float-end border-3 fw-bold text-secondary" onClick={() => closeDowntime('close_downtime')}><FontAwesomeIcon icon="fa-times" className="me-2" />Close</button>
                </td>
                </>
            }
        </>
    )
}

