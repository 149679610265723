import { gql } from '@apollo/client';

export const GetManufacturers = gql`
    query GetManufacturers {
        manufacturers {
            manufacturer_ID,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            manufacturer_name
        }
    }
`;

export const SaveManufacturer = gql`
	mutation manufacturerSave($input: ManufacturerInput, $manufacturer_ID: ID) {
		manufacturerSave (input: $input) {
            UpdatedManufacturer (manufacturer_ID: $manufacturer_ID) {
                manufacturer_ID,
                archiver_ID,
                creator_ID,
                date_archived,
                date_created,
                manufacturer_name
            }
            Manufacturer {
                manufacturer_ID,
                archiver_ID,
                creator_ID,
                date_archived,
                date_created,
                manufacturer_name
            }
		}
	}
`;