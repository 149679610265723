import React from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import {flowRight as compose} from 'lodash';
import { queryWithLoading } from '../utilities/NSDb.js';
// import { graphql } from '@apollo/react-hoc';
import { useState } from 'react';
import _ from "lodash";
import dayjs from 'dayjs';
import { useAuthUser } from 'react-auth-kit';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
import { useMutation } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// GRAPHQL QUERY
import {  
    ArchiveDowntime
    // ,
    // SaveDowntime 
} from '../downtimes/downtimes_graphql.js';
// import {  GetMachines } from '../machines/machines_graphql.js';
// import {  GetParts } from '../parts/parts_graphql.js';
// import {  GetShifts } from '../shifts/shifts_graphql.js';
// import {  GetTooling } from '../tooling/tooling_graphql.js';
import {  ArchiveToolingUsage } from '../tooling_usage/tooling_usage_graphql.js';
// import {  GetUsers } from '../users/users_graphql.js';

import { 
    GetProductionRecordsPage,
    SaveProductionRecord
} from './production_records_graphql.js';

// IMPORT COMPONENTS
import {DowntimeFormContent} from '../downtimes/downtime_form.js'

function ProductionRecordsContent(props) {

    const auth = useAuthUser();
    const called_from = props.called_from ? props.called_from : '';

    console.log({props})
    // Set const values from props
    const { 
        downtime_reasons: DowntimeReasons,
        downtimes: Downtimes,
        machines: Machines,
        parts: Parts,
        production_records: ProductionRecords,
        production_records_users: ProductionRecordsUsers,
        shifts: Shifts,
        tooling: Tooling,
        tooling_usages: ToolingUsage
    } = props.componentData;

    
    const Operators = props.componentData.users.map((u) => { return { ...u, full_name: `${u.first_name} ${u.last_name}`, short_name: `${u.first_name} ${u.last_name.charAt(0)}.` } });
    console.log({Operators})
    // Add the Downtimes and ToolingUsage arrays to their associated Production Records
    let TempProductionRecords = [...ProductionRecords];

    TempProductionRecords = TempProductionRecords.map((tpr) => {
        // Add Downtimes for this Production Record
        let TempDowntimes = Downtimes.filter((dt) => parseInt(dt.production_record_ID) === parseInt(tpr.production_record_ID));
        let PRDowntime = CalculatePRDowntime(TempDowntimes, 'array');
        // Create individual Downtimes record strings
        TempDowntimes = TempDowntimes.map((dt) => {
            let TempDT = CalculatePRDowntime(dt);
            return {...dt, downtime_hours_string: TempDT.downtime_hours, downtime_minutes_string: TempDT.downtime_minutes }
        })

        let TempOperators = ProductionRecordsUsers.filter((pru) => parseInt(pru.production_record_ID) === parseInt(tpr.production_record_ID));

        // Add ToolingUsage for this Production Record
        let TempToolingUsage = ToolingUsage.filter((tu) => parseInt(tu.production_record_ID) === parseInt(tpr.production_record_ID));

        return {...tpr, downtime_hours: PRDowntime.downtime_hours, downtime_minutes: PRDowntime.downtime_minutes, Downtimes: TempDowntimes, Operators: TempOperators, ToolingUsage: TempToolingUsage };
    })

    // Given a Production Record's Downtimes, calculate the total hours and minutes of downtime
    function CalculatePRDowntime(Downtimes, action) {
        let temp_downtime_hours = 0;
        let temp_downtime_hr_string = 0;
        let temp_downtime_minutes = 0;
        let temp_downtime_min_string = 0;
        // Add up the total downtime time for this Production Record
        if(action === 'array' && Downtimes.length > 0) {

            Downtimes.forEach((dt) => {
                if(parseInt(dt.downtime_hours) > 0) temp_downtime_hours = parseInt(temp_downtime_hours)+parseInt(dt.downtime_hours);
                if(parseInt(dt.downtime_minutes) > 0) {
                    temp_downtime_minutes = parseInt(temp_downtime_minutes)+parseInt(dt.downtime_minutes);
                    if(parseInt(temp_downtime_minutes) > 59) {
                        temp_downtime_minutes = parseInt(temp_downtime_minutes)-60;
                        temp_downtime_hours = parseInt(temp_downtime_hours)+1;
                    }
                }
            });
        }
        else {
            if(parseInt(Downtimes.downtime_hours) > 0) temp_downtime_hours = parseInt(temp_downtime_hours)+parseInt(Downtimes.downtime_hours);
            if(parseInt(Downtimes.downtime_minutes) > 0) {
                temp_downtime_minutes = parseInt(temp_downtime_minutes)+parseInt(Downtimes.downtime_minutes);
                if(parseInt(temp_downtime_minutes) > 59) {
                    temp_downtime_minutes = parseInt(temp_downtime_minutes)-60;
                    temp_downtime_hours = parseInt(temp_downtime_hours)+1;
                }
            }
        }
        // temp_downtime_hr_string = temp_downtime_hours > 0 ? `${temp_downtime_hours} ${temp_downtime_hours > 0 && ((temp_downtime_hours > 1 && 'hrs ') || 'hr ')}` : 0;
        // temp_downtime_min_string = temp_downtime_minutes > 0 ? `${temp_downtime_minutes} ${temp_downtime_minutes > 0 && ((temp_downtime_minutes > 1 && 'mins') || 'min')}` : 0;
        temp_downtime_hr_string = temp_downtime_hours > 0 ? `${temp_downtime_hours} ${temp_downtime_hours > 0 && 'hr '}` : 0;
        temp_downtime_min_string = temp_downtime_minutes > 0 ? `${temp_downtime_minutes} ${temp_downtime_minutes > 0 && 'min'}` : 0;
        if(temp_downtime_hr_string !== 0 && temp_downtime_min_string === 0) temp_downtime_min_string = '0 min';

        return { downtime_hours: temp_downtime_hr_string, downtime_minutes: temp_downtime_min_string }
    }

    const [archive_tooling_usage] = useMutation(ArchiveToolingUsage);
    const [archive_downtime] = useMutation(ArchiveDowntime);
    //const [save_downtime] = useMutation(SaveDowntime);
    const [save_production_record] = useMutation(SaveProductionRecord);
    // const [save_tooling_usage] = useMutation(SaveToolingUsage);

    // Create the default SelectedProductionRecord object for setting after form is closed
    const DefaultSelectedProductionRecord = {
        date_production: dayjs().format('YYYY-MM-DD'),
        Downtimes: [],
        good_parts_produced: 0,
        hours_worked: '',
        machine_ID: 0,
        notes: '',
        Operators: [],
        part_ID: 0,
        production_record_ID: 0,
        scrap_parts_produced: 0,
        shift_ID: 0,
        ToolingUsage: [],
        updated_standard_hourly_rate: ''
    };

    const DefaultSelectedDowntime = {
        downtime_ID: 0,
        archiver_ID: 0,
        creator_ID: 0,
        date_archived: '',
        date_created: '',
        downtime_hours: 0,
        downtime_minutes: 0,
        downtime_reason_ID: 0,
        notes: '',
        save_button_text: 'Save',
        save_message: ''
    };

    const DefaultSelectedTooling = {
        tooling_usage_ID: 0,
        archiver_ID: 0,
        creator_ID: 0,
        date_archived: '',
        date_created: '',
        notes: '',
        production_record_ID: 0,
        tooling_ID: 0,
        tooling_usage_quantity: 1
    };

    const [SelectedDowntime, setSelectedDowntime] = useState({ ...DefaultSelectedDowntime });
    const [SelectedProductionRecord, setSelectedProductionRecord] = useState({ ...DefaultSelectedProductionRecord });
    const [SelectedTooling, setSelectedTooling] = useState({ ...DefaultSelectedTooling });

    const [BaseState, setBaseState] = useState(
        {
            action: props.action || '',
            action_downtime: '',
            action_tooling_usage: '',
            Downtimes: props.componentData.downtimes,
            filter: '',
            filter_search: '',
            filter_search_message: '',
            filter_text_searched: '',
            FilteredProductionRecords: TempProductionRecords,
            ProductionRecords: TempProductionRecords,
            save_button_text: 'Save',
            save_button_text_downtime: 'Save',
            save_button_text_tooling: 'Save',
            save_message: '',
            save_message_downtime: '',
            save_message_tooling: '',
            ToolingUsage: props.componentData.tooling_usage
        }
    );

    // // Get all of the queries that we need for this component
    // const { loading, error, data } = useQuery(GetProductionRecordsPage, { variables: { active: 1, user_type_ID: 2 }});
    // // Set the immutable variables for our query data
    // if (loading) return null;
    // if (error) return `Error! ${error}`;
    // let {
    //     downtime_reasons: DowntimeReasons,
    //     downtimes: Downtimes,
    //     machines: Machines,
    //     parts: Parts,
    //     production_records: ProductionRecords,
    //     shifts: Shifts,
    //     tooling: Tooling,
    //     tooling_usage: ToolingUsage,
    //     users: Operators
    // } = data;
    

    // Alphabetically sort the parts
    // function sortPartNames(parts) {
    //     let TempParts = [...parts];
    //     if(TempParts.length > 0) {
    //         return TempParts.sort(function(a,b) {
    //             if(a.part_number.toLowerCase() < b.part_number.toLowerCase()) return -1;
    //             if(a.part_number.toLowerCase() > b.part_number.toLowerCase()) return 1;
    //             return 0;
    //         });
    //     } else {
    //         return [];
    //     }
    // }

    // Update the state for any changing input in the Production Record details form
    function changeSelectedDowntimeInputValue(name, value) {
        console.log({name})
        console.log({value})

        // For inputs that must be integers, do not allow non numeric characters, and parseInt on the value
        if ( name === 'downtime_hours' || name === 'downtime_minutes' ) {
            if(value !== '') {
                value = parseInt(value)
                if(name === 'downtime_minutes' && value > 60) {
                    return null;
                }
            } else {
                // This is to avoid attempting to save "" as an INT
                value = null;
            }
        }

        setBaseState({
            ...BaseState,
            save_button_text: 'Save', 
            save_button_text_downtime: 'Save',
            save_message_downtime: ''
        })

        setSelectedDowntime({
            ...SelectedDowntime,
            [name]: value,
        })

    }

    function changeSelectedToolingInputValue(name, value) {
    
         // For inputs that must be integers, do not allow non numeric characters, and parseInt on the value
        if ( name === 'tooling_usage_quantity' ) {
            if(value !== '') {
                value = parseInt(value)
            } else {
                // This is to avoid attempting to save "" as an INT
                value = 0;
            }
        }

       setBaseState({
            ...BaseState,
            save_button_text: 'Save', 
            save_button_text_tooling: 'Save Tooling',
            save_message_tooling: ''
        })

        setSelectedTooling({
            ...SelectedTooling,
                [name]: value,
        })

    }

    // Update the state for any changing input in the Production Record details form
    function changeSelectedProductionRecordInputValue(name, value) {
        console.log({name})
        console.log({value})
        let update_selected_production_record = true;
        // For inputs that must be integers, do not allow non numeric characters, and parseInt on the value
        if ( name === 'good_parts_produced' || name === 'scrap_parts_produced' || name === 'downtime_hours' || name === 'downtime_minutes' ) {
            if(value !== '') {
                value = parseInt(value)
            } else {
                // This is to avoid attempting to save "" as an INT
                value = null;
            }
        } else if ( name === 'shift_ID' ) {
            // Automatically update the hours worked to be what is on the shift record's shift_hours_worked column
            if(value !== "" && parseInt(value) > 0) {
                let temp_hours_worked = props.componentData.shifts.filter((s) => parseInt(s.shift_ID) === parseInt(value))[0].shift_hours_worked;
                // Update state for SelectedProductionRecord here, since we need to add in hours_worked
                setSelectedProductionRecord({
                    ...SelectedProductionRecord,
                    hours_worked: temp_hours_worked,
                    [name]: value
                });
                update_selected_production_record = false;
            }
        } else if ( name === 'hours_worked') {
            if(value !== '') {
                // Strips non-numeric characters, leaves decimal
                value = value.replace(/[^\d.-]/g, '');
                // Handle decimal length restrictions
                if (value.includes('.')) {
                    let decimal_length = value.split('.')[1].length;
                    value = decimal_length > 1 ? parseFloat(value).toFixed(2) : value;
                };
            }
        } 

        // Reset the Save button to show that the form can be saved.
        setBaseState({
            ...BaseState,
            save_button_text: 'Save', 
            save_message: ''
        })

        // Don't do this update here for shift_ID, its changing value occurs above
        if(update_selected_production_record) {
            setSelectedProductionRecord({
                ...SelectedProductionRecord,
                    [name]: value,
            })
        }
    }

    // Archive / Unarchive the Downtimes record
    async function archiveDowntime(downtime_ID, archiver_ID=0) {
        // TODO: replace with actual user_ID
        let new_archiver_ID = archiver_ID > 0 ? 0 : 1;
        let result = await archive_downtime({ variables: { input: {downtime_ID: parseInt(downtime_ID), archiver_ID: new_archiver_ID} } })
        console.log("Archive Downtime, result: ", result.data.downtimeArchive.UpdatedDowntime);

        // Update the SelectedProductionRecord with the new Downtimes object, and the ProductionRecords for the list / filter list - we can only edit PRs from the list view, so we know that the PR for this Downtimes will be in the filtered list
        let TempDowntimes = [...SelectedProductionRecord.Downtimes];
        TempDowntimes = TempDowntimes.map((d) => {
            if(parseInt(d.downtime_ID) === parseInt(result.data.downtimeArchive.UpdatedDowntime.downtime_ID)) { 
                let TempDT = CalculatePRDowntime(d);
                return {...result.data.downtimeArchive.UpdatedDowntime, downtime_hours_string: TempDT.downtime_hours, downtime_minutes_string: TempDT.downtime_minutes }
            }
            else { return d }
        });
        setSelectedProductionRecord({
            ...SelectedProductionRecord,
            Downtimes: TempDowntimes
        });

        let TempFilteredProductionRecords = BaseState.FilteredProductionRecords.map((fpr) => {
            if(parseInt(fpr.production_record_ID) === parseInt(SelectedProductionRecord.production_record_ID)) { return { ...fpr, Downtimes: TempDowntimes }; } 
            else { return fpr; }
        })
        let TempProductionRecords = BaseState.ProductionRecords.map((pr) => {
            if(parseInt(pr.production_record_ID) === parseInt(SelectedProductionRecord.production_record_ID)) { return { ...pr, Downtimes: TempDowntimes }; } 
            else { return pr; }
        })
        
        setBaseState({
            ...BaseState,
            FilteredProductionRecords: TempFilteredProductionRecords,
            ProductionRecords: TempProductionRecords
        });
    }

    // Archive / Unarchive the ToolingUsage record
    async function archiveToolingUsage(tooling_usage_ID, archiver_ID=0) {
        // TODO: replace with actual user_ID
        let new_archiver_ID = archiver_ID > 0 ? 0 : 1;
        let result = await archive_tooling_usage({ variables: { input: {tooling_usage_ID, archiver_ID: new_archiver_ID} } })
        console.log("Archive Tooling Usage, result: ", result.data.toolingUsageArchive.UpdatedToolingUsage);

        // Update the SelectedProductionRecord with the new ToolingUsage object, and the ProductionRecords for the list / filter list - we can only edit PRs from the list view, so we know that the PR for this ToolingUsage will be in the filtered list
        let TempToolingUsage = [...SelectedProductionRecord.ToolingUsage];
        TempToolingUsage = TempToolingUsage.map((t) => {
            if(parseInt(t.tooling_usage_ID) === parseInt(result.data.toolingUsageArchive.UpdatedToolingUsage.tooling_usage_ID)) { return result.data.toolingUsageArchive.UpdatedToolingUsage }
            else { return t }
        });
        setSelectedProductionRecord({
            ...SelectedProductionRecord,
            ToolingUsage: TempToolingUsage
        });

        let TempFilteredProductionRecords = BaseState.FilteredProductionRecords.map((fpr) => {
            if(parseInt(fpr.production_record_ID) === parseInt(SelectedProductionRecord.production_record_ID)) { return { ...fpr, ToolingUsage: TempToolingUsage }; } 
            else { return fpr; }
        })
        let TempProductionRecords = BaseState.ProductionRecords.map((pr) => {
            if(parseInt(pr.production_record_ID) === parseInt(SelectedProductionRecord.production_record_ID)) { return { ...pr, ToolingUsage: TempToolingUsage }; } 
            else { return pr; }
        })
        
        setBaseState({
            ...BaseState,
            FilteredProductionRecords: TempFilteredProductionRecords,
            ProductionRecords: TempProductionRecords
        });
    }

    // Archive / Unarchive the Production Record
    function archiveProductionRecord(production_record_ID, archiver_ID=0) {
        let new_archiver_ID = archiver_ID > 0 ? 0 : 1;
        // Need to make sure to archive any tooling_usage or downtimes records this PR also
        saveProductionRecord(production_record_ID, new_archiver_ID, 'archive');
    
    }

    // Save Downtime Form
    function saveDowntime(SelectedDowntime) {
        console.log({SelectedDowntime})
        // VALIDATION: Must select a downtime reason
        if(parseInt(SelectedDowntime.downtime_reason_ID) === 0) {
            setBaseState({
                ...BaseState,
                save_button_text: 'Save', 
                save_message_downtime: 'Enter downtime reason'
            })
        }
        // VALIDATION: Do not allow 0 time entered
        else if(( SelectedDowntime.downtime_hours === '' || parseInt(SelectedDowntime.downtime_hours) === 0 ) && ( SelectedDowntime.downtime_minutes === '' || parseInt(SelectedDowntime.downtime_minutes) === 0 )) {
            setBaseState({
                ...BaseState,
                save_button_text: 'Save', 
                save_message_downtime: 'Enter downtime length'
            })
        }

        if(parseInt(SelectedDowntime.downtime_reason_ID) > 0 && ( ( SelectedDowntime.downtime_hours !== '' && parseInt(SelectedDowntime.downtime_hours) !== 0 ) || ( SelectedDowntime.downtime_minutes !== '' && parseInt(SelectedDowntime.downtime_minutes) !== 0 ) ) ) {
        
            // If this Production Record has not been saved, then we will not be able to actually save the Downtime because we do not have a production_record_ID yet. So generate a temporary downtime_ID just based off of the length Downtimes array on the selected Production Record.
            let temp_down_time_ID = parseInt(SelectedProductionRecord.production_record_ID) === 0 && parseInt(SelectedDowntime.downtime_ID) === 0 ? SelectedProductionRecord.Downtimes.length+1 : parseInt(SelectedDowntime.downtime_ID);

            // Turn downtime inputs into an object to push to the SelectedProductionRecord's Downtimes array
            let TempDowntimes = [...SelectedProductionRecord.Downtimes];
            TempDowntimes.push({ ...SelectedDowntime, downtime_ID: temp_down_time_ID });

            // Add new Downtime to SelectedProdcutionRecord
            setSelectedProductionRecord({
                ...SelectedProductionRecord,
                Downtimes: TempDowntimes
            });

            // Reset the SelecteDowntime state
            setSelectedDowntime({
                DefaultSelectedDowntime
            });

            // Reset downtime action in order to close the Downtime form
            setBaseState({
                ...BaseState,
                action_downtime: ''
            })

        }
    
    }

    function saveTooling() {
    
        // VALIDATION: Must select a downtime reason
        if(parseInt(SelectedTooling.tooling_ID) === 0) {
            setBaseState({
                ...BaseState,
                save_button_text: 'Save', 
                save_message_tooling: 'Select tooling used'
            })
        }
        // VALIDATION: Do not allow 0 time entered
        else if(SelectedTooling.tooling_usage_quantity === '' || parseInt(SelectedTooling.tooling_usage_quantity) === 0 ) {
            setBaseState({
                ...BaseState,
                save_button_text: 'Save', 
                save_message_tooling: 'Enter tooling quantity used'
            })
        }

        if( parseInt(SelectedTooling.tooling_ID) > 0 && SelectedTooling.tooling_usage_quantity !== '' && parseInt(SelectedTooling.tooling_usage_quantity) !== 0 ) {
        
            // Turn tooling usage inputs into an object to push to the SelectedProductionRecord's Downtimes array
            let TempToolingUsage = [...SelectedProductionRecord.ToolingUsage];
            TempToolingUsage.push({ tooling_ID: SelectedTooling.tooling_ID, tooling_usage_quantity: SelectedTooling.tooling_usage_quantity, notes: SelectedTooling.notes });

            // Add new Tooling Usage to SelectedProdcutionRecord
            setSelectedProductionRecord({
                ...SelectedProductionRecord,
                ToolingUsage: TempToolingUsage
            });

            // Reset Tooling Usage state to clear form
            setSelectedTooling({
                ...DefaultSelectedTooling
            });

            setBaseState({
                ...BaseState,
                save_button_text_tooling: 'Saved'
            })
        }
    
    }

    // Save button clicked in Tooling form, if production_record_ID > 0 this is an edit/update, if production_record_ID=0 this is adding a tooling record
    async function saveProductionRecord(production_record_ID, archiver_ID=0, action='') {
        console.log("Save clicked")
        let result;

        if(action !== 'archive') {

            setBaseState({
                ...BaseState, 
                save_button_text: 'Saving'
            });

            // Add new input key for ToolingParts to be a list of part_IDs for ease of saving to the tooling_parts database
            //let SaveData = { ...SelectedProductionRecord, tooling_part_IDs: SelectedProductionRecord.ToolingParts.map((tp) => tp.part_ID).join() };
            
            let SaveData = { ...SelectedProductionRecord };
            // Parse string values that are IDs to be integers, saving stringified numbers to the database throws an error.
            let MakeIntegers = ['machine_ID', 'part_ID', 'shift_ID', 'updated_standard_hourly_rate'];
            MakeIntegers.forEach((mi) => SaveData[mi] = parseInt(SaveData[mi]));

            // Parse ints for Downtimes if there are any
            if(SaveData.Downtimes.length > 0) { 
                // let TempDowntimes = [...SaveData.Downtimes];
                SaveData.Downtimes = SaveData.Downtimes.map((dt) => { return {..._.omit(dt, ['downtime_hours_string','downtime_minutes_string','downtime_reason']), downtime_reason_ID: parseInt(dt.downtime_reason_ID) } });
                // SaveData.Downtimes = TempDowntimes;
            }

            // Parse ints for ToolingUsage if there are any
            if(SaveData.ToolingUsage.length > 0) { 
                SaveData.ToolingUsage = SaveData.ToolingUsage.map((tu) => { return {...tu, tooling_ID: parseInt(tu.tooling_ID) } });
            }

            // Parse Operators into array of just user_ID and production_record_ID for passing to server
            SaveData.Operators = SaveData.Operators.map((o) => { return { user_ID: parseInt(o.user_ID), production_record_ID: parseInt(production_record_ID)} });

            console.log({SaveData})
            // Save this Production Record's form details and await the resulting object to update overall state with. Do not send the Operators arrays.
            result = await save_production_record({ variables: { input: _.omit(SaveData, ['downtime_hours', 'downtime_minutes', 'machine_name', 'machine_short_name', 'part_number', 'shift_name']) } })
            //_.omit(SaveData, ['Operators'])

        } else {
        
            result = await save_production_record({ variables: { input: { production_record_ID, archiver_ID, active: archiver_ID > 0 ? 0 : 1, action } } })
        }

        console.log({result})
        // Save returns a single Production Record object, either an updated one if production_record_ID > 0 or a new object if this was adding a Production Record
        if(result.data.productionRecordSave.UpdatedProductionRecord && result.data.productionRecordSave.UpdatedProductionRecord.production_record_ID > 0) {
console.log("Gets into here")
            let TempProductionRecords = [...BaseState.ProductionRecords];
            let temp_save_message = '';

            // UPDATES: update the Production Record object for the updated production_record_ID
            if(production_record_ID > 0) {
                TempProductionRecords = TempProductionRecords.map((pr) => {
                    if(parseInt(pr.production_record_ID) === parseInt(result.data.productionRecordSave.UpdatedProductionRecord.production_record_ID)) {
                        return result.data.productionRecordSave.UpdatedProductionRecord
                    } else {
                        return pr
                    }
                })

                // Set save_message for next to Save button
                temp_save_message = 'Production record successfully updated'

            } 
            // INSERTS
            else {
            
                // For inserts, add the new Production Record object to the Production Record array, then re-sort the Production Record by description string
                TempProductionRecords.push(result.data.productionRecordSave.UpdatedProductionRecord);
console.log({TempProductionRecords})
                // Update the SelectedProductionRecord with the new production_record_ID that was created
                setSelectedProductionRecord({
                    ...SelectedProductionRecord,
                    production_record_ID: parseInt(result.data.productionRecordSave.UpdatedProductionRecord.production_record_ID)
                })

                // Set save_message for next to Save button
                temp_save_message = 'Production record successfully added'

            }

            // Get the new filtered ProductionRecord saved on the updated ProductionRecord values
            let TempFilteredProductionRecords = handleProductionRecordFilter(BaseState.filter, BaseState.filter_search, false, TempProductionRecords);
            console.log({TempFilteredProductionRecords})
            setBaseState({
                ...BaseState,
                FilteredProductionRecords: TempFilteredProductionRecords,
                ProductionRecords: TempProductionRecords, 
                save_button_text: 'Saved',
                save_message: temp_save_message
            });

        } else {
            console.log("SOMETHING WENT WRONG DURING SAVING")
        }
    }

    // Handler for clicking the 'Add Production Record', 'Close', and edit icon (pencil) buttons that open and close the Production Record form
    function handleButtonClicked(action='', Selected) {

        // Set BaseState, the only variable between the buttons here is what 'action' they pass in
        setBaseState({
            ...BaseState, 
            action: action, 
            save_button_text: 'Save', 
            save_message: ''
        });

        // Open Add Production Record Form
        if(action === 'add_production_record') {
            setSelectedProductionRecord({ 
                ...SelectedProductionRecord, 
                ...DefaultSelectedProductionRecord, 
                production_record_ID: 0
            });
        } 
        // Open Add Downtime Form
        else if(action === 'add_downtime' || action === 'edit_downtime') {
            setSelectedDowntime({ 
                ...Selected
            });
            setBaseState({
                ...BaseState,
                action_downtime: action
            });
        } 
        else if(action === 'close_downtime') {
            setSelectedDowntime({ 
                ...SelectedDowntime, 
                ...DefaultSelectedDowntime
            });
            setBaseState({
                ...BaseState,
                action_downtime: ''
            })
        } 

        // Open Add Downtime Form
        else if(action === 'add_tooling') {
            setSelectedTooling({ 
                ...SelectedTooling, 
                ...DefaultSelectedTooling, 
                tooling_usage_ID: 0
            });
        } 
        // Open Edit Production Record Form
        else if(action === 'edit_production_record') {
            setSelectedProductionRecord({ 
                ...Selected
            });
        } 
        // Close Production Record Form
        else if(action === '' ) {
            setSelectedProductionRecord({ 
                ...SelectedProductionRecord, 
                ...DefaultSelectedProductionRecord
            });
        }
    }

    // Handler for the Search and Clear buttons for the filter input search
    function handleFilterSearch(action, value) {
        if(action === 'filter_search') {
            // Perform filtering with regular filters and the new search value
            handleProductionRecordFilter(BaseState.filter, BaseState.filter_search);

        } else if(action === 'clear') {
            // Clear search input and re-filter
            handleProductionRecordFilter(BaseState.filter, '', true, [], 'clear_filters');

        } else if(action === 'change_input') {
            let temp_filter_search_message = value.includes(' ') ? 'Search works best without spaces' : '';

            setBaseState({
                ...BaseState,
                filter_search_message: temp_filter_search_message,
                filter_search: value
            });
        }
    }

    // Filter Tooling list - this is called whenever a filter button is clicked
    function handleProductionRecordFilter(filter, filter_search, do_filtering=true, ProductionRecord=[], action) {
        console.log({filter});
        console.log({filter_search})
        console.log({do_filtering})
        console.log({ProductionRecord})
        console.log({action})
        let TempFilteredProductionRecord = ProductionRecord.length > 0 ? ProductionRecord : BaseState.ProductionRecord;

        let save_filter_text_searched = filter_search !== '' ? true : false;
        // Search input filtering - compare to most of the shown columns in the table
        if(filter_search !== '') {
            TempFilteredProductionRecord = TempFilteredProductionRecord.filter((t) => {
                // DO LATER: If the search includes spaces, then split the search result into individual strings for comparing
                // if(filter_search.includes(' ')) {
                //     let search_string_list = filter_search.split(" ");
                //     const index = array.indexOf(5);
                //     if (index > -1) { // only splice array when item is found
                //     array.splice(index, 1); // 2nd parameter means remove one item only
                //     }
                // }
                // if(
                //     (t.tooling_description !== null && t.tooling_description.toLowerCase().includes(filter_search.toLowerCase()) ) ||
                //     (t.supplier_name !== null && t.supplier_name.toLowerCase().includes(filter_search.toLowerCase()) ) ||
                //     (t.manufacturer_name !== null && t.manufacturer_name.toLowerCase().includes(filter_search.toLowerCase()) ) ||
                //     (t.manufacturer_part_number !== null && t.manufacturer_part_number.toLowerCase().includes(filter_search.toLowerCase()) ) ||
                //     (t.supplier_part_number !== null && t.supplier_part_number.toLowerCase().includes(filter_search.toLowerCase()) ) ||
                //     (t.notes !== null && t.notes.toLowerCase().includes(filter_search.toLowerCase()) )
                // ) {
                //     return t
                // } else {
                    return t
                // }
            });
        }

        // All / Active / Obsolete filtering
        if(filter === 'Active') {
            TempFilteredProductionRecord = TempFilteredProductionRecord.filter((t) => parseInt(t.active) === 1);
        } else if(filter === 'Obsolete') {
            TempFilteredProductionRecord = TempFilteredProductionRecord.filter((t) => parseInt(t.active) === 0);
        }

        if(do_filtering) {
            // If the text search was just clicked, update the state variable to preserve that string for the filter details output
            let temp_filter_text_searched = save_filter_text_searched ? BaseState.filter_search : BaseState.filter_text_searched;
            setBaseState({
                ...BaseState,
                filter: filter,
                filter_search: action === 'clear_filters' ? '' : BaseState.filter_search,
                filter_search_message: action === 'clear_filters' ? '' : BaseState.filter_search_message,
                filter_text_searched: action === 'clear_filters' ? '' : temp_filter_text_searched,
                FilteredProductionRecords: TempFilteredProductionRecord
            })
        } else {
            return TempFilteredProductionRecord;
        }

    }

    console.log({BaseState})
    console.log({SelectedProductionRecord})

    // Handler for the Operators multiselect. This provides 'list', which is an array of the current Operator objects in the select, 'item' is the Operator object that was just added/removed, 'action' is extra to explain either 'select' or 'remove'.
    function handleOperatorMultiselect(list, item, action) {

        console.log({action})
        console.log({list})
        console.log({item})
        // let temp_operator_IDs = list.length > 0 ? list.map((l) => parseInt(l.user_ID)).join() : '';
        // let temp_operator_names = list.length > 0 ? list.map((l) => `${l.first_name} ${l.last_name}`).join() : '';
        setSelectedProductionRecord({
            ...SelectedProductionRecord,
            Operators: list
            // operator_IDs: temp_operator_IDs,
            // operator_names: temp_operator_names
        })
    }

    // Filter PRoduction Records list - this is called whenever a filter button is clicked
    function handleToolingFilter(filter, filter_tooling_type_ID, filter_search, do_filtering=true, Tooling=[], action) {
        let TempFilteredTooling = Tooling.length > 0 ? Tooling : BaseState.Tooling;

        let save_filter_text_searched = filter_search !== '' ? true : false;
        // Search input filtering - compare to most of the shown columns in the table
        if(filter_search !== '') {
            TempFilteredTooling = TempFilteredTooling.filter((t) => {
                // DO LATER: If the search includes spaces, then split the search result into individual strings for comparing
                // if(filter_search.includes(' ')) {
                //     let search_string_list = filter_search.split(" ");
                //     const index = array.indexOf(5);
                //     if (index > -1) { // only splice array when item is found
                //     array.splice(index, 1); // 2nd parameter means remove one item only
                //     }
                // }
                if(
                    (t.tooling_description !== null && t.tooling_description.toLowerCase().includes(filter_search.toLowerCase()) ) ||
                    (t.supplier_name !== null && t.supplier_name.toLowerCase().includes(filter_search.toLowerCase()) ) ||
                    (t.manufacturer_name !== null && t.manufacturer_name.toLowerCase().includes(filter_search.toLowerCase()) ) ||
                    (t.manufacturer_part_number !== null && t.manufacturer_part_number.toLowerCase().includes(filter_search.toLowerCase()) ) ||
                    (t.supplier_part_number !== null && t.supplier_part_number.toLowerCase().includes(filter_search.toLowerCase()) ) ||
                    (t.notes !== null && t.notes.toLowerCase().includes(filter_search.toLowerCase()) )
                ) {
                    return t
                } else {
                    return null
                }
            });
        }

        // Tool Type filtering
        if(parseInt(filter_tooling_type_ID) > 0) {
            TempFilteredTooling = TempFilteredTooling.filter((t) => parseInt(t.tooling_type_ID) === parseInt(filter_tooling_type_ID));
        }

        // All / Active / Obsolete filtering
        if(filter === 'Active') {
            TempFilteredTooling = TempFilteredTooling.filter((t) => parseInt(t.active) === 1);
        } else if(filter === 'Obsolete') {
            TempFilteredTooling = TempFilteredTooling.filter((t) => parseInt(t.active) === 0);
        }

        if(do_filtering) {
            // If the text search was just clicked, update the state variable to preserve that string for the filter details output
            let temp_filter_text_searched = save_filter_text_searched ? BaseState.filter_search : BaseState.filter_text_searched;
            setBaseState({
                ...BaseState,
                filter: filter,
                filter_search: action === 'clear_filters' ? '' : BaseState.filter_search,
                filter_search_message: action === 'clear_filters' ? '' : BaseState.filter_search_message,
                filter_text_searched: action === 'clear_filters' ? '' : temp_filter_text_searched,
                filter_tooling_type_ID: filter_tooling_type_ID
            })
        } else {
            return TempFilteredTooling;
        }

    }


console.log("SelectedDowntime: ", SelectedDowntime);
console.log({BaseState})
    return (
        <>
            <div>
                { BaseState.action !== 'add_production_record' &&
                    <>
                        <div className="row clearfix mb-3 justify-content-center">

                            {/* ACTIVE / OBSOLETE FILTER BUTTONS */}
                            <div className="col-auto">
                                <div className="btn-group btn-group-sm" role="group">
                                    <button className={`btn btn-outline-secondary ${(BaseState.filter === '' && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleToolingFilter('', BaseState.filter_tooling_type_ID, BaseState.filter_search)}>
                                        All Tooling
                                    </button>
                                    <button className={`btn btn-outline-secondary ${(BaseState.filter === 'Active' && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleToolingFilter('Active', BaseState.filter_tooling_type_ID, BaseState.filter_search)}>
                                        Only Active
                                    </button>
                                    <button className={`btn btn-outline-secondary ${(BaseState.filter === 'Obsolete' && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleToolingFilter('Obsolete', BaseState.filter_tooling_type_ID, BaseState.filter_search)}>
                                        Only Obsolete
                                    </button>
                                </div>                            
                            </div>

                            {/* TOOLING TYPES FILTER BUTTONS */}
                            <div className="col-auto">
                                <div className="btn-group btn-group-sm" role="group">
                                    {/* {BaseState.ToolingTypes.map((tt) => {
                                        return (
                                            <button key={tt.tooling_type_ID} className={`btn btn-outline-secondary ${(BaseState.filter_tooling_type_ID === tt.tooling_type_ID && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleToolingFilter(BaseState.filter, tt.tooling_type_ID, BaseState.filter_search)}>
                                                {tt.tooling_type}
                                            </button>
                                        )
                                    })} */}
                                </div>                            
                            </div>

                            {/* SEARCH INPUT */}
                            <div className="col-auto">
                                <div className="input-group input-group-sm">
                                    <button 
                                        className="btn btn-success" 
                                        onClick={() => handleFilterSearch('filter_search')}
                                    >
                                        Search
                                    </button>
                                    <input className="form-control border border-secondary" value={BaseState.filter_search || ""} onChange={(event) => handleFilterSearch( 'change_input', event.target.value )} />
                                    <button 
                                        className="btn btn-light border border-secondary" 
                                        onClick={() => handleFilterSearch('clear')}
                                    >
                                        Clear
                                    </button>
                                </div>
                                {BaseState.filter_search_message !== '' &&
                                    <div className="text-danger">{BaseState.filter_search_message}</div>
                                }
                            </div>
                        </div>

                        <div className="row mb-3">

                            {/* RESULTS COUNTER AND FILTERS */}
                            <div className="col h4 mb-1 pt-1">
                                
                                {/* {BaseState.filter === 'Active' && ' Active'}{BaseState.filter === 'Obsolete' && ' Obsolete'}
                                {` ${BaseState.ToolingTypes.filter((tt) => parseInt(tt.tooling_type_ID) === parseInt(BaseState.filter_tooling_type_ID))[0].tooling_type}`}
                                {` ${(BaseState.FilteredTooling.length === 1 && 'Tool') || 'Tools'}`}
                                {
                                    BaseState.filter_text_searched !== '' &&
                                    <span className=""> - Matching Search "{BaseState.filter_text_searched}"</span>
                                } */}
                            </div>

                        </div>

                        <div className={`row mb-3 ${called_from === 'control_panel' && 'text-center'}`}>

                            {/* RESULTS COUNTER AND FILTERS */}
                            {/* <div className="col h4 mb-1 pt-1">
                                {BaseState.FilteredProductionRecords.length}
                                {BaseState.filter === 'Active' && ' Active'}{BaseState.filter === 'Obsolete' && ' Obsolete'}
                                {` ${(BaseState.FilteredProductionRecords.length === 1 && 'Tool') || 'Tools'}`}
                                {
                                    BaseState.filter_text_searched !== '' &&
                                    <span className=""> - Matching Search "{BaseState.filter_text_searched}"</span>
                                }
                            </div> */}

                            {/* ADD PRODUCTION RECORD BUTTON */}
                            <div className="">
                                <div className="col-auto">
                                {
                                    SelectedProductionRecord.production_record_ID === 0 && BaseState.action === 'add_production_record' &&
                                    <button 
                                        className="col-auto btn border-primary border-3 text-primary fw-bold" 
                                        disabled={true}
                                    >
                                    Enter New Production Record Below
                                    </button>
                                }
                                {
                                    ((SelectedProductionRecord.production_record_ID === 0 && BaseState.action !== 'add_production_record') || (SelectedProductionRecord.production_record_ID > 0)) &&
                                    <button 
                                        className={`btn fw-bold col-auto border-primary border-3 float-end text-primary`} 
                                        onClick={() => handleButtonClicked('add_production_record')}
                                    >
                                        <FontAwesomeIcon icon="fa-plus" className="me-2" />Add Production Record
                                    </button>
                                }
                                </div>
                            </div>

                        </div>
                    </>
                }
                {BaseState.action === 'add_production_record' &&
                    <div className="bg-light rounded border border-secondary mb-4 p-3">

                        {/* PRODUCTION RECORD DETAILS CONTENT */}
                        <div className="row ">
                            <div className="h5 text-secondary">Production Record Details</div>

                            {/* Tooling Description */}
                            <div className="border-0 col-2">
                                <div className="form-floating">
                                    <input type="date" className="form-control mb-3" id="date_production" placeholder="Enter a Production Date" value={SelectedProductionRecord.date_production || ""} onChange={(event) => changeSelectedProductionRecordInputValue('date_production', event.target.value)} />
                                    <label className="text-muted" htmlFor="date_production">Production Date</label>
                                </div>
                            </div>

                            {/* Part Nummber */}
                            <div className="border-0 col-2">
                                <div className="form-floating">
                                    <select 
                                        className="form-select mb-3" 
                                        disabled={false}
                                        id="Part"
                                        onChange={(event) => changeSelectedProductionRecordInputValue('part_ID', event.target.value)}
                                        placeholder="Part Name"
                                        value={SelectedProductionRecord.part_ID}
                                    >
                                        <option key={0} value={0}>Select Part</option>
                                        {Parts.map((p) => {
                                            return (
                                                <option key={p.part_ID} value={p.part_ID}>{p.part_number}</option>
                                            )
                                        })}
                                    </select>
                                    <label className="text-muted" htmlFor="Part">Part</label>
                                </div>
                            </div>

                            {/* Shift */}
                            <div className="border-0 col-2">
                                <div className="form-floating">
                                    <select 
                                        className="form-select mb-3" 
                                        disabled={false}
                                        id="Shift"
                                        onChange={(event) => changeSelectedProductionRecordInputValue('shift_ID', event.target.value)}
                                        placeholder="Shift Name"
                                        value={SelectedProductionRecord.shift_ID}
                                    >
                                        <option key={0} value={0}>Select Shift</option>
                                        {Shifts.map((s) => {
                                            return (
                                                <option key={s.shift_ID} value={s.shift_ID}>{s.shift_name}</option>
                                            )
                                        })}
                                    </select>
                                    <label className="text-muted" htmlFor="Shift">Shift</label>
                                </div>
                            </div>

                            {/* WorkCenter (Machine) */}
                            <div className="border-0 col-2">
                                <div className="form-floating">
                                    <select 
                                        className="form-select mb-3" 
                                        disabled={false}
                                        id="Machine"
                                        onChange={(event) => changeSelectedProductionRecordInputValue('machine_ID', event.target.value)}
                                        placeholder="Machine Name"
                                        value={SelectedProductionRecord.machine_ID}
                                    >
                                        <option key={0} value={0}>Select Machine</option>
                                        {Machines.map((m) => {
                                            return (
                                                <option key={m.machine_ID} value={m.machine_ID}>{m.machine_name}</option>
                                            )
                                        })}
                                    </select>
                                    <label className="text-muted" htmlFor="Machine">Machine</label>
                                </div>
                            </div>

                            {/* Operator */}
                            <div className="border-0 col-4">
                                <Multiselect 
                                    className="bg-white"
                                    id="Operator"
                                    onRemove={(selectedList, removedItem, action) => handleOperatorMultiselect(selectedList, removedItem, 'remove')}
                                    onSelect={(selectedList, selectedItem, action) => handleOperatorMultiselect(selectedList, selectedItem, 'select')}
                                    options={Operators}
                                    selectedValues={SelectedProductionRecord.Operators}
                                    displayValue="full_name"
                                    placeholder="Operators"
                                    hidePlaceholder="true"
                                />
                            </div>

                            {/* Good Parts Produced */}
                            <div className="border-0 col-1">
                                <div className="form-floating">
                                    <input className="form-control" id="good_parts" placeholder="Good" value={SelectedProductionRecord.good_parts_produced || ""} onChange={(event) => changeSelectedProductionRecordInputValue('good_parts_produced', event.target.value)} />
                                    <label className="text-muted" htmlFor="good_parts">Good</label>
                                </div>
                            </div>

                            {/* Scrap Parts Produced */}
                            <div className="border-0 col-1">
                                <div className="form-floating">
                                    <input className="form-control" id="scrap_parts" placeholder="Scrap" value={SelectedProductionRecord.scrap_parts_produced || ""} onChange={(event) => changeSelectedProductionRecordInputValue('scrap_parts_produced', event.target.value)} />
                                    <label className="text-muted" htmlFor="scrap_parts">Scrap</label>
                                </div>
                            </div>

                            {/* Selected Part's Hourly Standard Rate */}
                            <div className="border-0 col-1 text-center">
                                <div className="fw-bolder text-secondary">Standard</div>
                                <div id="standard_hourly" className="">{SelectedProductionRecord.part_ID > 0 && Parts.filter((p) => parseInt(p.part_ID) === parseInt(SelectedProductionRecord.part_ID))[0].hourly_standard_rate}</div>
                            </div>

                            {/* Update Hourly Standard Rate */}
                            <div className="border-0 col-1">
                                <div className="form-floating">
                                    <input className="form-control" id="updated_standard" placeholder="Scrap" value={SelectedProductionRecord.updated_standard_hourly_rate || ""} onChange={(event) => changeSelectedProductionRecordInputValue('updated_standard_hourly_rate', event.target.value)} />
                                    <label className="text-muted" htmlFor="updated_standard">Std.</label>
                                </div>
                            </div>

                            {/* Hours Worked */}
                            <div className="border-0 col-2">
                                <div className="form-floating">
                                    <input className="form-control" id="hours_worked" placeholder="Hours Worked" value={SelectedProductionRecord.hours_worked || ""} onChange={(event) => changeSelectedProductionRecordInputValue('hours_worked', event.target.value)} />
                                    <label className="text-muted" htmlFor="hours_worked">Hours Worked</label>
                                </div>
                            </div>

                            {/* Production Record Notes */}
                            <div className="border-0 col-6 pt-0">
                                <div className="form-floating">
                                    <textarea className="form-control" id="production_record_notes" placeholder="Enter notes here" value={SelectedProductionRecord.notes} rows="2" onChange={(event) => changeSelectedProductionRecordInputValue('notes', event.target.value)} />
                                    <label className="text-muted" htmlFor="production_record_notes">Production Record Notes</label>
                                </div>
                            </div>
        
                        </div>

                        {/* DOWNTIME RECORD CONTENT */}
                        <div className="row mt-4">
                            <div className="h5 text-secondary pb-1">
                                Downtime Details
                                <span 
                                    className={`col-auto border border-primary btn btn-sm text-primary ms-2 mb-1 ${BaseState.action_downtime !== '' && 'disabled'}`}
                                    onClick={() => handleButtonClicked('add_downtime', DefaultSelectedDowntime)}
                                >
                                    <FontAwesomeIcon icon="fa-plus" size="lg" className="" />
                                </span>
                            </div>

                            {/* Downtime list for SelectedProductionRecord */}
                            {
                                (SelectedProductionRecord.Downtimes.length > 0 || BaseState.action_downtime === 'add_downtime') &&
                                <div className="ms-2 me-3 mt-1">
                                    <table className="table">
                                        <thead className="row me-2 border border-2 border-top-0 border-start-0 border-end-0 border-dark mb-0">
                                            <th className="col-2">Reason</th>
                                            <th className="col-1">Hours</th>
                                            <th className="col-1">Minutes</th>
                                            <th className="col-6">Note</th>
                                            <th className="col-1 ps-4">Edit</th>
                                            <th className="col-1">Delete</th>
                                        </thead>
                                        <tbody>
                                            {BaseState.action_downtime === 'add_downtime' &&
                                                <tr key={0} className="row me-2">
                                                    <DowntimeFormContent 
                                                        closeDowntime={(action) => handleButtonClicked(action)}
                                                        DowntimeReasons={DowntimeReasons}
                                                        saveDowntime={(SelectedDowntime) => saveDowntime(SelectedDowntime)}
                                                        SelectedDowntime={SelectedDowntime}
                                                    />
                                                </tr>
                                            }
                                            {SelectedProductionRecord.Downtimes.length > 0 && 
                                                SelectedProductionRecord.Downtimes.map((dt, index) => {
                                                    return (
                                                        <tr key={index} className="row me-2">
                                                            <td className="col-2"><span className="ps-1">{DowntimeReasons.filter((r) => parseInt(dt.downtime_reason_ID) === parseInt(r.downtime_reason_ID))[0].downtime_reason}</span></td>
                                                            <td className="col-1"><span className="ps-1">{dt.downtime_hours}</span></td>
                                                            <td className="col-1"><span className="ps-1">{dt.downtime_minutes}</span></td>
                                                            <td className="col-6"><span className="ps-1">{dt.notes}</span></td>
                                                            <td className="col-1">
                                                                <button className="btn ms-1 text-primary border-primary" onClick={() => handleButtonClicked('edit_downtime', dt)}><FontAwesomeIcon icon="fa-pencil" className="" /></button>
                                                            </td>
                                                            <td className="col-1">
                                                                <button className={`btn ms-3 text-danger border-danger float-start`} onClick={() => archiveDowntime()}><FontAwesomeIcon icon="fa-trash-can" className="" /></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>


                        {/* TOOLING USAGE RECORD CONTENT */}
                        <div className="row mt-3">
                            <div className="h5 text-secondary">Tooling Usage Details</div>

                            {/* Tooling Used */}
                            <div className="border-0 col-3">
                                <div className="form-floating">
                                    <select 
                                        className="form-select mb-3" 
                                        disabled={false}
                                        id="tooling"
                                        onChange={(event) => changeSelectedToolingInputValue('tooling_ID', event.target.value)}
                                        placeholder="Tooling"
                                        value={SelectedTooling.tooling_ID}
                                    >
                                        <option key={0} value={0}>Select Tooling</option>
                                        {Tooling.map((t) => {
                                            return (
                                                <option key={t.tooling_ID} value={t.tooling_ID}>{t.tooling_description} - {t.manufacturer_name} {t.manufacturer_part_number}</option>
                                            )
                                        })}
                                    </select>
                                    <label className="text-muted" htmlFor="tooling">Tooling Used</label>
                                </div>
                            </div>

                            {/* Quantity */}
                            <div className="border-0 col-1">
                                <div className="form-floating">
                                    <input className="form-control" id="tooling_usage_quantity" placeholder="Tooling Usage Quantity" value={SelectedTooling.tooling_usage_quantity} onChange={(event) => changeSelectedToolingInputValue('tooling_usage_quantity', event.target.value)} />
                                    <label className="text-muted" htmlFor="tooling_usage_quantity">Quantity</label>
                                </div>
                            </div>

                            {/* Tooling Usage  Notes */}
                            <div className="border-0 col-6 pt-0">
                                <div className="form-floating">
                                    <textarea className="form-control" id="tooling_notes" placeholder="Enter notes here" value={SelectedTooling.notes} rows="2" onChange={(event) => changeSelectedToolingInputValue('notes', event.target.value)} />
                                    <label className="text-muted" htmlFor="tooling_notes">Tooling Notes</label>
                                </div>
                            </div>

                            {/* Save Tooling Usage button and Message */}
                            {
                                BaseState.save_message_tooling !== '' && 
                                <div className="border-0 col-2 pt-4">
                                    <div className="text-danger">{BaseState.save_message_tooling}</div>
                                </div>
                            }
                            {
                                BaseState.save_message_tooling === '' && 
                                <div className="border-0 col-2 pt-0">
                                    <button className="btn mt-3 border-success border-3 fw-bold text-success" onClick={() => saveTooling()}><FontAwesomeIcon icon="fa-floppy-disk" className="me-2" />{BaseState.save_button_text_tooling}</button>
                                </div>
                            }

                            {/* Tooling Usage list for SelectedProductionRecord */}
                            {
                                SelectedProductionRecord.ToolingUsage.length > 0 &&
                                <div className="ms-2 me-3 mt-1">
                                    <table className="table">
                                        <thead className="row me-2 border border-2 border-top-0 border-start-0 border-end-0 border-dark mb-0">
                                            <th className="col-3">Tooling Usage</th>
                                            <th className="col-1">Quantity</th>
                                            <th className="col-6">Note</th>
                                            <th className="col-1 ps-4">Edit</th>
                                            <th className="col-1">Delete</th>
                                        </thead>
                                        <tbody>
                                            {SelectedProductionRecord.ToolingUsage.map((tu) => {
                                                let TempToolingUsage = Tooling.filter((t) => parseInt(tu.tooling_ID) === parseInt(t.tooling_ID))[0];
                                                console.log({TempToolingUsage})
                                                return (
                                                    <tr key={tu.tooling_ID} className="row me-2">
                                                        <td className="col-3"><span className="ps-1">{TempToolingUsage.tooling_description} - {TempToolingUsage.manufacturer_name} {TempToolingUsage.manufacturer_part_number}</span></td>
                                                        <td className="col-1"><span className="ps-1">{tu.tooling_usage_quantity}</span></td>
                                                        <td className="col-6"><span className="ps-1">{tu.notes}</span></td>
                                                        <td className="col-1">
                                                            <button className="btn ms-1 text-primary border-primary" onClick={() => handleButtonClicked('edit_tooling_usage')}><FontAwesomeIcon icon="fa-pencil" className="" /></button>
                                                        </td>
                                                        <td className="col-1">
                                                            <button className={`btn ms-3 text-danger border-danger float-start`} onClick={() => archiveToolingUsage(tu.tooling_usage_ID)}><FontAwesomeIcon icon="fa-trash-can" className="" /></button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                        {/* END - TOOLING USAGE RECORD CONTENT */}

                        {/* Save and Close buttons */}
                        <div className="row mt-3">
                            <span className="col-auto float-start">
                                <button className="btn border-success border-3 fw-bold text-success" disabled={SelectedProductionRecord.tooling_description === ''} onClick={() => saveProductionRecord(SelectedProductionRecord.production_record_ID)}><FontAwesomeIcon icon="fa-floppy-disk" className="me-2" />{BaseState.save_button_text}</button>
                            </span>
                            {
                                BaseState.save_message !== '' &&
                                <span className="col-auto float-start">
                                    <div className="alert alert-success p-2 mb-0"><FontAwesomeIcon icon="fa-check-circle" className="me-2 fa-lg" />{BaseState.save_message}</div>
                                </span>
                            }
                            <span className="col float-end">
                                {
                                    (called_from === 'control_panel' &&
                                        <button className="btn border-secondary border-3 fw-bold text-secondary float-end" onClick={() => props.handleCloseProductionRecord()}><FontAwesomeIcon icon="fa-times" className="me-2" />Close</button>
                                    ) ||
                                    <button className="btn border-secondary border-3 fw-bold text-secondary float-end" onClick={() => handleButtonClicked()}><FontAwesomeIcon icon="fa-times" className="me-2" />Close</button>
                                }
                            </span>
                        </div>
                    </div>
                }
                
                {/* Only show table view to Office and Admins */}
                {
                    parseInt(auth().User.user_type_ID) > 2 &&
                    <table className={`table ${SelectedProductionRecord.production_record_ID === '' && 'table-hover'}`}>
                        <thead className="row h6 border border-2 border-top-0 border-start-0 border-end-0 border-dark mb-0">
                            <tr className="col-1">Active / Edit</tr>
                            <tr className="col-1">Date</tr>
                            <tr className="col-1">Part / Workcenter</tr>
                            <tr className="col-2">Operators</tr>
                            <tr className="col-1">Good / Scrap</tr>
                            <tr className="col-1">Std / Actual</tr>
                            <tr className="col-2">Downtime</tr>
                            <tr className="col-2">Tooling Used</tr>
                            <tr className="col-1">Shift</tr>
                        </thead>
                        {
                            (BaseState.FilteredProductionRecords.length > 0 || BaseState.action === 'add_production_record') &&
                            <tbody className="">
                                {BaseState.FilteredProductionRecords.map((pr) => {
                                    if(parseInt(pr.production_record_ID) === parseInt(SelectedProductionRecord.production_record_ID) && BaseState.action === 'edit_production_record') {
                                        // Disable the save button until an input has changed.
                                        // let inputs_not_changed = true;

                                        // Compare SelectedProductionRecord to it's state values to see if anything has been changed
                                        // for (const [key, value] of Object.entries(SelectedProductionRecord)) {
                                        //     if(key !== 'ToolingParts') {
                                        //         // If the unchanged Tooling array object's key if typeof 'number', and the changed SelectedProductionRecord object's key is typeof 'string', change the SelectTooling to 'number'
                                        //         let temp_value = typeof pr[key] === 'number' && typeof value === 'string' ? parseInt(value) : value;
                                        //         if(temp_value !== pr[key]) {
                                        //             inputs_not_changed = false;
                                        //         }
                                        //     } else if(key === 'ToolingParts') {
                                        //         // If the ToolingParts array is not the same length, we automatically know that something has changed, we dont need to both with a compare function of the arrays.
                                        //         if(pr.ToolingParts.length !== value.length) {
                                        //             inputs_not_changed = false;
                                        //         } else {
                                        //             // Loop through the server's values, and if the part_ID of each array object is found in the SelectedProductionRecord.ToolingParts array objects, then nothing has changed. This cannot be a forEach loop because of ESLint rule warning/error no-loop-func
                                        //             for (let i = 0; i < pr.ToolingParts.length; i++) {
                                        //                 if(value.findIndex((v) => parseInt(v.part_ID) === parseInt(pr.ToolingParts[i].part_ID)) === -1) {
                                        //                     inputs_not_changed = false;
                                        //                 }
                                        //             }
                                        //         }
                                        //     }
                                        // }

                                        // let disable_save_button = inputs_not_changed || BaseState.save_button_text === 'Saving' || BaseState.save_button_text === 'Saved';

                                        return (
                                                <tr key={pr.production_record_ID} className="row bg-light border border-secondary">
                                                    <td className="border-0">
                                                        <div className="bg-light rounded border border-secondary mb-4 p-3">

                                                            {/* PRODUCTION RECORD DETAILS CONTENT */}
                                                            <div className="row ">
                                                                <div className="h5 text-secondary">Production Record Details</div>

                                                                {/* Tooling Description */}
                                                                <div className="border-0 col-2">
                                                                    <div className="form-floating">
                                                                        <input type="date" className="form-control mb-3" id="date_production" placeholder="Enter a Production Date" value={dayjs(SelectedProductionRecord.date_production).format('YYYY-MM-DD') || ""} onChange={(event) => changeSelectedProductionRecordInputValue('date_production', event.target.value)} />
                                                                        <label className="text-muted" htmlFor="date_production">Production Date</label>
                                                                    </div>
                                                                </div>

                                                                {/* Part Nummber */}
                                                                <div className="border-0 col-2">
                                                                    <div className="form-floating">
                                                                        <select 
                                                                            className="form-select mb-3" 
                                                                            disabled={false}
                                                                            id="Part"
                                                                            onChange={(event) => changeSelectedProductionRecordInputValue('part_ID', event.target.value)}
                                                                            placeholder="Part Name"
                                                                            value={SelectedProductionRecord.part_ID}
                                                                        >
                                                                            <option key={0} value={0}>Select Part</option>
                                                                            {Parts.map((p) => {
                                                                                return (
                                                                                    <option key={p.part_ID} value={p.part_ID}>{p.part_number}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                        <label className="text-muted" htmlFor="Part">Part</label>
                                                                    </div>
                                                                </div>

                                                                {/* Shift */}
                                                                <div className="border-0 col-2">
                                                                    <div className="form-floating">
                                                                        <select 
                                                                            className="form-select mb-3" 
                                                                            disabled={false}
                                                                            id="Shift"
                                                                            onChange={(event) => changeSelectedProductionRecordInputValue('shift_ID', event.target.value)}
                                                                            placeholder="Shift Name"
                                                                            value={SelectedProductionRecord.shift_ID}
                                                                        >
                                                                            <option key={0} value={0}>Select Shift</option>
                                                                            {Shifts.map((s) => {
                                                                                return (
                                                                                    <option key={s.shift_ID} value={s.shift_ID}>{s.shift_name}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                        <label className="text-muted" htmlFor="Shift">Shift</label>
                                                                    </div>
                                                                </div>

                                                                {/* WorkCenter (Machine) */}
                                                                <div className="border-0 col-2">
                                                                    <div className="form-floating">
                                                                        <select 
                                                                            className="form-select mb-3" 
                                                                            disabled={false}
                                                                            id="Machine"
                                                                            onChange={(event) => changeSelectedProductionRecordInputValue('machine_ID', event.target.value)}
                                                                            placeholder="Machine Name"
                                                                            value={SelectedProductionRecord.machine_ID}
                                                                        >
                                                                            <option key={0} value={0}>Select Machine</option>
                                                                            {Machines.map((m) => {
                                                                                return (
                                                                                    <option key={m.machine_ID} value={m.machine_ID}>{m.machine_name}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                        <label className="text-muted" htmlFor="Machine">Machine</label>
                                                                    </div>
                                                                </div>

                                                                {/* Operator */}
                                                                <div className="border-0 col-4">
                                                                    <Multiselect 
                                                                        className="bg-white"
                                                                        id="Operator"
                                                                        onRemove={(selectedList, removedItem, action) => handleOperatorMultiselect(selectedList, removedItem, 'remove')}
                                                                        onSelect={(selectedList, selectedItem, action) => handleOperatorMultiselect(selectedList, selectedItem, 'select')}
                                                                        options={Operators}
                                                                        selectedValues={SelectedProductionRecord.Operators}
                                                                        displayValue="first_name"
                                                                        placeholder="Operators"
                                                                        hidePlaceholder="true"
                                                                    />
                                                                </div>

                                                                {/* Good Parts Produced */}
                                                                <div className="border-0 col-1">
                                                                    <div className="form-floating">
                                                                        <input className="form-control" id="good_parts" placeholder="Good" value={SelectedProductionRecord.good_parts_produced || ""} onChange={(event) => changeSelectedProductionRecordInputValue('good_parts_produced', event.target.value)} />
                                                                        <label className="text-muted" htmlFor="good_parts">Good</label>
                                                                    </div>
                                                                </div>

                                                                {/* Scrap Parts Produced */}
                                                                <div className="border-0 col-1">
                                                                    <div className="form-floating">
                                                                        <input className="form-control" id="scrap_parts" placeholder="Scrap" value={SelectedProductionRecord.scrap_parts_produced || ""} onChange={(event) => changeSelectedProductionRecordInputValue('scrap_parts_produced', event.target.value)} />
                                                                        <label className="text-muted" htmlFor="scrap_parts">Scrap</label>
                                                                    </div>
                                                                </div>

                                                                {/* Selected Part's Hourly Standard Rate */}
                                                                <div className="border-0 col-1 text-center">
                                                                    <div className="fw-bolder text-secondary">Standard</div>
                                                                    <div id="standard_hourly" className="">{SelectedProductionRecord.part_ID > 0 && Parts.filter((p) => parseInt(p.part_ID) === parseInt(SelectedProductionRecord.part_ID))[0].hourly_standard_rate}</div>
                                                                </div>

                                                                {/* Update Hourly Standard Rate */}
                                                                <div className="border-0 col-1">
                                                                    <div className="form-floating">
                                                                        <input className="form-control" id="updated_standard" placeholder="Scrap" value={SelectedProductionRecord.updated_standard_hourly_rate || ""} onChange={(event) => changeSelectedProductionRecordInputValue('updated_standard_hourly_rate', event.target.value)} />
                                                                        <label className="text-muted" htmlFor="updated_standard">Std.</label>
                                                                    </div>
                                                                </div>

                                                                {/* Hours Worked */}
                                                                <div className="border-0 col-2">
                                                                    <div className="form-floating">
                                                                        <input className="form-control" id="hours_worked" placeholder="Hours Worked" value={SelectedProductionRecord.hours_worked || ""} onChange={(event) => changeSelectedProductionRecordInputValue('hours_worked', event.target.value)} />
                                                                        <label className="text-muted" htmlFor="hours_worked">Hours Worked</label>
                                                                    </div>
                                                                </div>

                                                                {/* Production Record Notes */}
                                                                <div className="border-0 col-6 pt-0">
                                                                    <div className="form-floating">
                                                                        <textarea className="form-control" id="production_record_notes" placeholder="Enter notes here" value={SelectedProductionRecord.notes} rows="2" onChange={(event) => changeSelectedProductionRecordInputValue('notes', event.target.value)} />
                                                                        <label className="text-muted" htmlFor="production_record_notes">Production Record Notes</label>
                                                                    </div>
                                                                </div>
                                            
                                                            </div>

                                                            {/* DOWNTIME RECORD CONTENT */}
                                                            <div className="row mt-4">
                                                                <div className="h5 text-secondary">Downtime Details</div>

                                                                {/* Downtime Reason */}
                                                                <div className="border-0 col-2">
                                                                    <div className="form-floating">
                                                                        <select 
                                                                            className="form-select mb-3" 
                                                                            disabled={false}
                                                                            id="downtime_reason"
                                                                            onChange={(event) => changeSelectedDowntimeInputValue('downtime_reason_ID', event.target.value)}
                                                                            placeholder="Downtime Reason"
                                                                            value={SelectedDowntime.downtime_reason_ID}
                                                                        >
                                                                            <option key={0} value={0}>Select Downtime Reason</option>
                                                                            {DowntimeReasons.map((dr) => {
                                                                                return (
                                                                                    <option key={dr.downtime_reason_ID} value={dr.downtime_reason_ID}>{dr.downtime_reason}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                        <label className="text-muted" htmlFor="downtime_reason">Downtime Reason</label>
                                                                    </div>
                                                                </div>

                                                                {/* Hours */}
                                                                <div className="border-0 col-1">
                                                                    <div className="form-floating">
                                                                        <input className="form-control" id="downtime_hours" placeholder="Good" value={SelectedDowntime.downtime_hours} onChange={(event) => changeSelectedDowntimeInputValue('downtime_hours', event.target.value)} />
                                                                        <label className="text-muted" htmlFor="downtime_hours">Hours</label>
                                                                    </div>
                                                                </div>

                                                                {/* Minutes */}
                                                                <div className="border-0 col-1">
                                                                    <div className="form-floating">
                                                                        <input className="form-control" id="downtime_minutes" placeholder="Good" value={SelectedDowntime.downtime_minutes} onChange={(event) => changeSelectedDowntimeInputValue('downtime_minutes', event.target.value)} />
                                                                        <label className="text-muted" htmlFor="downtime_minutes">Minutes</label>
                                                                    </div>
                                                                </div>

                                                                {/* Downtime Record Notes */}
                                                                <div className="border-0 col-6 pt-0">
                                                                    <div className="form-floating">
                                                                        <textarea className="form-control" id="downtime_record_notes" placeholder="Enter notes here" value={SelectedDowntime.notes} rows="2" onChange={(event) => changeSelectedDowntimeInputValue('notes', event.target.value)} />
                                                                        <label className="text-muted" htmlFor="downtime_record_notes">Downtime Notes</label>
                                                                    </div>
                                                                </div>

                                                                {/* Save Downtime button and Message */}
                                                                {
                                                                    BaseState.save_message_downtime !== '' && 
                                                                    <div className="border-0 col-2 pt-4">
                                                                        <div className="text-danger">{BaseState.save_message_downtime}</div>
                                                                    </div>
                                                                }
                                                                {
                                                                    BaseState.save_message_downtime === '' && 
                                                                    <div className="border-0 col-2 pt-0">
                                                                        <button className="btn mt-3 border-success border-3 fw-bold text-success" disabled={SelectedDowntime.tooling_description === ''} onClick={() => saveDowntime(SelectedDowntime.production_record_ID)}><FontAwesomeIcon icon="fa-floppy-disk" className="me-2" />{BaseState.save_button_text_downtime}</button>
                                                                    </div>
                                                                }

                                                                {/* Downtime list for SelectedProductionRecord */}
                                                                {
                                                                    SelectedProductionRecord.Downtimes.length > 0 &&
                                                                    <div className="ms-2 me-3 mt-1">
                                                                        <table className="table">
                                                                            <thead className="row me-2 border border-2 border-top-0 border-start-0 border-end-0 border-dark mb-0">
                                                                                <th className="col-2">Reason</th>
                                                                                <th className="col-1">Hours</th>
                                                                                <th className="col-1">Minutes</th>
                                                                                <th className="col-6">Note</th>
                                                                                <th className="col-1 ps-4">Edit</th>
                                                                                <th className="col-1">Delete</th>
                                                                            </thead>
                                                                            <tbody>
                                                                                {SelectedProductionRecord.Downtimes.map((dt, index) => {
                                                                                    if(parseInt(dt.downtime_ID) === parseInt(SelectedDowntime.downtime_ID)) {
                                                                                        // TO DO: Add edit in line here
                                                                                        return false;
                                                                                    } else {
                                                                                        return (
                                                                                            <tr key={index} className="row me-2">
                                                                                                <td className="col-2"><span className="ps-1">{DowntimeReasons.filter((r) => parseInt(dt.downtime_reason_ID) === parseInt(r.downtime_reason_ID))[0].downtime_reason}</span></td>
                                                                                                <td className="col-1"><span className="ps-1">{dt.downtime_hours}</span></td>
                                                                                                <td className="col-1"><span className="ps-1">{dt.downtime_minutes}</span></td>
                                                                                                <td className="col-6"><span className="ps-1">{dt.notes}</span></td>
                                                                                                <td className="col-1"> 
                                                                                                    <button className={`btn ms-3 text-danger border-danger float-start`} onClick={() => archiveDowntime(dt.downtime_ID, dt.archiver_ID)}><FontAwesomeIcon icon="fa-trash-can" className="" /></button>
                                                                                                </td>
                                                                                                <td className="col-1">
                                                                                                    <button className="btn ms-1 text-primary border-primary" onClick={() => handleButtonClicked('edit_downtime', dt)}><FontAwesomeIcon icon="fa-pencil" className="" /></button>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    }
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                }
                                                            </div>


                                                            {/* TOOLING USAGE RECORD CONTENT */}
                                                            <div className="row mt-3">
                                                                <div className="h5 text-secondary">Tooling Usage Details</div>

                                                                {/* Tooling Used */}
                                                                <div className="border-0 col-3">
                                                                    <div className="form-floating">
                                                                        <select 
                                                                            className="form-select mb-3" 
                                                                            disabled={false}
                                                                            id="tooling"
                                                                            onChange={(event) => changeSelectedToolingInputValue('tooling_ID', event.target.value)}
                                                                            placeholder="Tooling"
                                                                            value={SelectedTooling.tooling_ID}
                                                                        >
                                                                            <option key={0} value={0}>Select Tooling</option>
                                                                            {Tooling.map((t) => {
                                                                                return (
                                                                                    <option key={t.tooling_ID} value={t.tooling_ID}>{t.tooling_description} - {t.manufacturer_name} {t.manufacturer_part_number}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                        <label className="text-muted" htmlFor="tooling">Tooling Used</label>
                                                                    </div>
                                                                </div>

                                                                {/* Quantity */}
                                                                <div className="border-0 col-1">
                                                                    <div className="form-floating">
                                                                        <input className="form-control" id="tooling_usage_quantity" placeholder="Tooling Usage Quantity" value={SelectedTooling.tooling_usage_quantity} onChange={(event) => changeSelectedToolingInputValue('tooling_usage_quantity', event.target.value)} />
                                                                        <label className="text-muted" htmlFor="tooling_usage_quantity">Quantity</label>
                                                                    </div>
                                                                </div>

                                                                {/* Tooling Usage  Notes */}
                                                                <div className="border-0 col-6 pt-0">
                                                                    <div className="form-floating">
                                                                        <textarea className="form-control" id="tooling_notes" placeholder="Enter notes here" value={SelectedTooling.notes} rows="2" onChange={(event) => changeSelectedToolingInputValue('notes', event.target.value)} />
                                                                        <label className="text-muted" htmlFor="tooling_notes">Tooling Notes</label>
                                                                    </div>
                                                                </div>

                                                                {/* Save Tooling Usage button and Message */}
                                                                {
                                                                    BaseState.save_message_tooling !== '' && 
                                                                    <div className="border-0 col-2 pt-4">
                                                                        <div className="text-danger">{BaseState.save_message_tooling}</div>
                                                                    </div>
                                                                }
                                                                {
                                                                    BaseState.save_message_tooling === '' && 
                                                                    <div className="border-0 col-2 pt-0">
                                                                        <button className="btn mt-3 border-success border-3 fw-bold text-success" onClick={() => saveTooling()}><FontAwesomeIcon icon="fa-floppy-disk" className="me-2" />{BaseState.save_button_text_tooling}</button>
                                                                    </div>
                                                                }

                                                                {/* Tooling Usage list for SelectedProductionRecord */}
                                                                {
                                                                    SelectedProductionRecord.ToolingUsage.length > 0 &&
                                                                    <div className="ms-2 me-3 mt-1">
                                                                        <table className="table">
                                                                            <thead className="row me-2 border border-2 border-top-0 border-start-0 border-end-0 border-dark mb-0">
                                                                                <th className="col-3">Tooling Usage</th>
                                                                                <th className="col-1">Quantity</th>
                                                                                <th className="col-6">Note</th>
                                                                                <th className="col-1 ps-4">Edit</th>
                                                                                <th className="col-1">Delete</th>
                                                                            </thead>
                                                                            <tbody>
                                                                                {SelectedProductionRecord.ToolingUsage.map((tu) => {
                                                                                    let TempToolingUsage = Tooling.filter((t) => parseInt(tu.tooling_ID) === parseInt(t.tooling_ID))[0];
                                                                                    console.log({TempToolingUsage})
                                                                                    return (
                                                                                        <tr key={tu.tooling_ID} className="row me-2">
                                                                                            <td className="col-3"><span className="ps-1">{TempToolingUsage.tooling_description} - {TempToolingUsage.manufacturer_name} {TempToolingUsage.manufacturer_part_number}</span></td>
                                                                                            <td className="col-1"><span className="ps-1">{tu.tooling_usage_quantity}</span></td>
                                                                                            <td className="col-6"><span className="ps-1">{tu.notes}</span></td>
                                                                                            <td className="col-1">
                                                                                                <button className="btn ms-1 text-primary border-primary" onClick={() => handleButtonClicked('edit_tooling_usage')}><FontAwesomeIcon icon="fa-pencil" className="" /></button>
                                                                                            </td>
                                                                                            <td className="col-1">
                                                                                                <button className={`btn ms-3 text-danger border-danger float-start`} onClick={() => archiveToolingUsage(tu.tooling_usage_ID, tu.archiver_ID)}><FontAwesomeIcon icon="fa-trash-can" className="" /></button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                }
                                                            </div>
                                                            {/* END - TOOLING USAGE RECORD CONTENT */}

                                                            {/* Save and Close buttons */}
                                                            <div className="row mt-3">
                                                                <span className="col-auto float-start">
                                                                    <button className="btn border-success border-3 fw-bold text-success" disabled={SelectedProductionRecord.tooling_description === ''} onClick={() => saveProductionRecord(SelectedProductionRecord.production_record_ID)}><FontAwesomeIcon icon="fa-floppy-disk" className="me-2" />{BaseState.save_button_text}</button>
                                                                </span>
                                                                {
                                                                    BaseState.save_message !== '' &&
                                                                    <span className="col-auto float-start">
                                                                        <div className="alert alert-success p-2 mb-0"><FontAwesomeIcon icon="fa-check-circle" className="me-2 fa-lg" />{BaseState.save_message}</div>
                                                                    </span>
                                                                }
                                                                <span className="col float-end">
                                                                    <button className="btn border-secondary border-3 fw-bold text-secondary float-end" onClick={() => handleButtonClicked()}><FontAwesomeIcon icon="fa-times" className="me-2" />Close</button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                        )
                                    } else {
                                        let archive_button_class = parseInt(pr.archiver_ID) > 0 ? 'border-danger text-danger' : 'border-success text-success';
                                        let archive_button_icon = parseInt(pr.archiver_ID) > 0 ? 'fa-trash-can' : 'fa-rotate-left';
                                        //let archive_button_text = parseInt(pr.active) === 1 ? 'Active' : 'Obsolete';
                                        //let tooling_description_text = parseInt(pr.archiver_ID) === 0 ? `(${pr.tooling_description})` : pr.tooling_description;
                                        //let tooling_type = BaseState.filter_tooling_type_ID === 0 ? `Type: ${BaseState.ToolingTypes.filter((tt) => parseInt(tpr.tooling_type_ID) === parseInt(pr.tooling_type_ID))[0].tooling_type}` : '';

                                        return (
                                            <tr key={pr.production_record_ID} className={`row ${(BaseState.action === 'add_production_record' || BaseState.action === 'edit_production_record') && 'table-secondary text-muted'}`}>
                                                <td className="col-1 align-middle">
                                                    {/* {archive_button_text} */}
                                                    <button className={`btn btn-sm ${archive_button_class} float-start`} disabled={BaseState.save_button_text === 'Saving'} onClick={() => archiveProductionRecord(pr.production_record_ID, pr.archiver_ID)}><FontAwesomeIcon icon={archive_button_icon} className="" /></button>
                                                    <button className="btn btn-sm border-primary text-primary ms-2" onClick={() => handleButtonClicked('edit_production_record', pr)}><FontAwesomeIcon icon="fa-pencil" className="" /></button>
                                                </td>
                                                <td className={`col-1 ps-3 ${parseInt(pr.archiver_ID) > 0 && 'text-muted'}`}>{dayjs(pr.date_created).format('M-D-YY')}</td>
                                                <td className={`col-1 ps-3 ${parseInt(pr.archiver_ID) > 0 && 'text-muted'}`}>{pr.part_number}<br />{pr.machine_short_name}</td>
                                                <td className={`col-2 ps-3 ${parseInt(pr.archiver_ID) > 0 && 'text-muted'}`}>
                                                    {pr.Operators.map((o) => {
                                                        return (<div key={o.user_ID} className="">{o.first_name} {o.last_name}</div>)
                                                    })}
                                                </td>
                                                <td className={`col-1 ps-3 ${parseInt(pr.archiver_ID) > 0 && 'text-muted'}`}>{pr.good_parts_produced} / {pr.scrap_parts_produced}</td>
                                                <td className={`col-1 ps-3 ${parseInt(pr.archiver_ID) > 0 && 'text-muted'}`}> / {pr.good_parts_produced+pr.scrap_parts_produced}</td>
                                                <td className={`col-2 ps-3 ${parseInt(pr.archiver_ID) > 0 && 'text-muted'}`}>
                                                    {pr.Downtimes.length > 0 &&
                                                        pr.Downtimes.filter((dt) => !dt.archiver_ID > 0).map((dt) => {
                                                            return <div key={dt.downtime_ID} className="clearfix"><span className="text-muted" style={{fontSize: 13+'px'}}>{dt.downtime_reason}:</span> <span className={`${pr.Downtimes.length > 1 && 'float-end'}`}>{dt.downtime_hours_string !== 0 && dt.downtime_hours_string} {dt.downtime_minutes_string !== 0 && dt.downtime_minutes_string}</span></div>
                                                        })
                                                    }
                                                    {pr.Downtimes.filter((dt) => !dt.archiver_ID > 0).length > 1 && 
                                                        <div className="border-top"><span className="text-muted" style={{fontSize: 13+'px'}}>Total Downtime:</span> <span className="float-end">{pr.downtime_hours !== 0 && pr.downtime_hours} {pr.downtime_minutes !== 0 && pr.downtime_minutes}</span></div>
                                                    }
                                                </td>
                                                <td className={`col-2 ps-3 ${parseInt(pr.archiver_ID) > 0 && 'text-muted'}`}>
                                                    {pr.ToolingUsage.length > 0 &&
                                                        pr.ToolingUsage.filter((tu) => !tu.archiver_ID > 0).map((tu) => {
                                                            return <div key={tu.tooling_usage_ID} className="clearfix">({tu.tooling_usage_quantity}) <span className="text-muted" style={{fontSize: 13+'px'}}>{Tooling.filter((t) => parseInt(t.tooling_ID) === parseInt(tu.tooling_ID))[0].tooling_description.slice(0,30)}</span></div>
                                                        })
                                                    }
                                                </td>
                                                <td className={`col-1 ps-3 ${parseInt(pr.archiver_ID) > 0 && 'text-muted'}`}>{pr.shift_name}<br/>{pr.hours_worked} hrs.</td>
                                            </tr>
                                        )
                                    }
                                })}
                            </tbody>
                        }
                        {
                            BaseState.FilteredProductionRecords.length === 0 &&
                            <tbody className="">
                                <tr>
                                    <td colSpan="7">
                                        <div className="alert alert-info m-0">No Production Records matching filters</div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </table>
                }
            </div>
        </>
    )
}

export const ProductionRecordsComponent = compose(
	queryWithLoading({
		gqlString: GetProductionRecordsPage,
        name: "componentData",
        variablesFunction: () => ({ active: 1, user_type_ID: 2 })
	})
)(ProductionRecordsContent)
