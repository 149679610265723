import React from 'react';
// import _ from 'lodash';
import {flowRight as compose} from 'lodash';
import { withMutation, queryWithLoading } from '../utilities/NSDb.js';
import { graphql } from '@apollo/react-hoc';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// GRAPHQL QUERY
import { 
    GetMachines,
    SaveMachine
} from './machines_graphql.js';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
// import { useQuery, useMutation } from '@apollo/client';

class MachinesClass extends React.Component {
	constructor(props) {
    	super(props)

        // Alphabetically sort the machines
        let TempMachines = this.sortMachineNames(props.Machines.machines);

		this.state = {
            filter: 'all',
            FilteredMachines: TempMachines,
            Machines: TempMachines,
            save_button_text: 'Save',
            selected_machine_ID: '',
            selected_machine_name: ''
		}
	}

    // Alphabetically sort the machines
    sortMachineNames = (machines) => {
        let TempMachines = [...machines];
        if(TempMachines.length > 0) {
            return TempMachines.sort(function(a,b) {
                if(a.machine_name.toLowerCase() < b.machine_name.toLowerCase()) return -1;
                if(a.machine_name.toLowerCase() > b.machine_name.toLowerCase()) return 1;
                return 0;
            });
        } else {
            return [];
        }
    }

    // Archive / Unarchive the machine
    archiveMachine = (machine_ID, machine_name, archiver_ID=0) => {
        let new_archiver_ID = archiver_ID > 0 ? 0 : 1;
        this.saveMachine(machine_ID, machine_name, new_archiver_ID);
    }

    // Save machine details
    saveMachine = async (machine_ID, machine_name, archiver_ID=0) => {
        this.setState({
            action: '',
            save_button_text: 'Saving'
        });

        const result = await this.props.SaveMachine({ input: {
                                            machine_ID: parseInt(machine_ID),
                                            machine_name: machine_name,
                                            archiver_ID: archiver_ID
                                        } });
        
        // Updating a machine - save returns a single machine object
        if(result.data.machineSave.UpdatedMachine && result.data.machineSave.UpdatedMachine.machine_ID > 0) {
            // Update the Machines array with the new saved information
            let TempMachines = [...this.state.Machines];

            TempMachines = TempMachines.map((m) => {
                if(parseInt(m.machine_ID) === parseInt(result.data.machineSave.UpdatedMachine.machine_ID)) {
                    return result.data.machineSave.UpdatedMachine
                } else {
                    return m
                }
            })

            // Get the new filtered machines saved on the updated machine values
            let TempFilteredMachines = this.handleMachinesFilter(this.state.filter, false, TempMachines);
            this.setState({ FilteredMachines: TempFilteredMachines, Machines: TempMachines, save_button_text: 'Saved'})
        } 
        // Adding a machine - save returns entire list of machines. 
        else if(result.data.machineSave.Machines && result.data.machineSave.Machines.length > 0) {
            let SortedMachines = this.sortMachineNames(result.data.machineSave.Machines);
            // After creating a new machine, go back to the 'all' filter view
            let TempFilteredMachines = this.handleMachinesFilter('all', false, SortedMachines);
            this.setState({ 
                action: 'added_machine',
                filter: 'all',
                FilteredMachines: TempFilteredMachines,
                Machines: SortedMachines, 
                save_button_text: 'Saved',
                selected_machine_ID: '',
                selected_machine_name: ''
            })
        }
    }

    // Filter Machines list
    handleMachinesFilter = (filter, do_filtering=true, Machines=[]) => {

        let TempFilteredMachines = Machines.length > 0 ? Machines : this.state.Machines;
        if(filter === 'active') {
            TempFilteredMachines = TempFilteredMachines.filter((m) => !m.archiver_ID > 0);
        } else if(filter === 'archived') {
            TempFilteredMachines = TempFilteredMachines.filter((m) => m.archiver_ID > 0);
        }

        if(do_filtering) {
            this.setState({
                filter: filter,
                FilteredMachines: TempFilteredMachines
            })
        } else {
            return TempFilteredMachines;
        }

    }

	render () {
        let filter = this.state.filter;
		return (
            <React.Fragment>
                <div>
                    <div className="row clearfix mb-3">
                        <div className="col">
                            <div className="btn-group btn-group-sm" role="group">
                                <button className={`btn btn-outline-secondary ${(filter === 'all' && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => this.handleMachinesFilter('all')}>
                                    All Machines
                                </button>
                                <button className={`btn btn-outline-secondary ${(filter === 'active' && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => this.handleMachinesFilter('active')}>
                                    Only Active
                                </button>
                                <button className={`btn btn-outline-secondary ${(filter === 'archived' && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => this.handleMachinesFilter('archived')}>
                                    Only Archived
                                </button>
                            </div>                            
                        </div>
                        <div className="col-auto">
                            <div className="float-end">
                            {
                                this.state.selected_machine_ID !== '' && this.state.selected_machine_ID === 0 &&
                                <div className="">
                                    <span className="col-auto float-end ms-2">
                                        <button 
                                            className="col-auto btn border-3 border-success fw-bold text-success"
                                            disabled={this.state.selected_machine_name === ''}
                                            onClick={() => this.saveMachine(0, this.state.selected_machine_name)}
                                        >
                                        Save
                                        </button>
                                    </span>
                                    <span className="col-auto float-end">
                                        <input 
                                            className="col-auto form-control" 
                                            onChange={(event) => this.setState({save_button_text: 'Save', selected_machine_name: event.target.value})}
                                            placeholder="New Machine Name" 
                                            value={this.state.selected_machine_name || ""} 
                                        />
                                    </span>
                                </div>
                            }
                            {
                                this.state.selected_machine_ID !== 0 &&
                                <div className="">
                                    <button 
                                        className="btn fw-bold col-auto border-primary border-3 float-end text-primary"
                                        onClick={() => this.setState({action: '', save_button_text: 'Save', selected_machine_ID: 0, selected_machine_name: ""})}
                                    >
                                        <FontAwesomeIcon icon="fa-plus" className="me-2" />Add Machine
                                    </button>
                                    {
                                        this.state.action === 'added_machine' &&
                                        <span className="col-auto float-end me-2">
                                            <div className="alert alert-success p-2">Machine added successfully</div>
                                        </span>
                                    }
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                    {
                        <table className="table">
                            <thead className="row h6 border border-2 border-top-0 border-start-0 border-end-0 border-dark">
                                <tr className="col-1">Archive</tr>
                                <tr className="col-1">Edit</tr>
                                <tr className="col-10">Machine Name</tr>
                            </thead>
                            {
                                this.state.FilteredMachines.length > 0 &&
                                <tbody className="">
                                    {this.state.FilteredMachines.map((m) => {
                                        // let archive_button_class = m.archiver_ID > 0 ? 'btn-success' : 'btn-danger';
                                        // let archive_button_text = m.archiver_ID > 0 ? 'Unarchive' : 'Archive';
                                        if(m.machine_ID === this.state.selected_machine_ID) {
                                            // Disable the save button until the machine_name has changed.
                                            let disable_save_button = this.state.selected_machine_name === m.machine_name || this.state.selected_machine_name === '' || this.state.save_button_text === 'Saving' || this.state.save_button_text === 'Saved';
                                            let save_button_tip = disable_save_button ? 'Machine name has not been changed' : '';
                                            return (
                                                <tr className="row align-items-center" key={m.machine_ID}>
                                                    {/* <td className="col-1">
                                                        <button 
                                                            className={`btn ${archive_button_class}`} 
                                                            disabled 
                                                            onClick={() => this.archiveMachine(m.machine_ID, m.machine_name, m.archiver_ID)}
                                                        >
                                                            <FontAwesomeIcon icon="fa-folder-minus" className="me-2" />{archive_button_text} 
                                                        </button>
                                                    </td>*/}
                                                    <td className="col-2">
                                                        <button 
                                                            className="btn border-secondary border-3 fw-bold text-secondary float-start" 
                                                            onClick={() => this.setState({save_button_text: 'Save', selected_machine_ID: '', selected_machine_name: ""})}
                                                        >
                                                            {/* <FontAwesomeIcon icon="fa-times" className="me-2" />*/} Close 
                                                        </button>
                                                    </td>
                                                    <td className="col-10 clearfix">
                                                        <div className="row">
                                                            <span className="col-auto">
                                                                <input className="form-control float-start" value={this.state.selected_machine_name || ""} onChange={(event) => this.setState({save_button_text: 'Save', selected_machine_name: event.target.value})} />
                                                            </span>
                                                            <span className="col-auto p-0" data-bs-toggle="tooltip" data-bs-placement="top" title={save_button_tip} >
                                                                <button className="btn border-3 border-success fw-bold text-success float-end" disabled={disable_save_button} onClick={() => this.saveMachine(m.machine_ID, this.state.selected_machine_name, m.archiver_ID)}>{this.state.save_button_text} {this.state.save_button_text === 'Saved' && <FontAwesomeIcon icon="fa-check" className="ms-1" />}</button>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        } else {
                                            let machine_name_text = m.archiver_ID > 0 ? `(${m.machine_name})` : m.machine_name;
                                            let archive_button_class = m.archiver_ID > 0 ? 'border-success text-success' : 'border-danger text-danger';
                                            let archive_button_icon = m.archiver_ID > 0 ? 'fa-rotate-left' : 'fa-trash-can';
                                            return (
                                                <tr className="row" key={m.machine_ID}>
                                                    <td className="col-1 align-middle"><button className={`btn btn-sm ${archive_button_class}`} disabled={this.state.save_button_text === 'Saving'} onClick={() => this.archiveMachine(m.machine_ID, m.machine_name, m.archiver_ID)}><FontAwesomeIcon icon={archive_button_icon} className="" /></button></td>
                                                    <td className="col-1 align-middle"><button className="btn btn-sm border-primary text-primary ms-2" onClick={() => this.setState({action: '', save_button_text: 'Save', selected_machine_ID: m.machine_ID, selected_machine_name: m.machine_name})}><FontAwesomeIcon icon="fa-pencil" className="" /></button></td>
                                                    <td className={`col-10 align-center ${m.archiver_ID > 0 && 'text-muted'}`}>{machine_name_text}</td>
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            }
                            {
                                this.state.FilteredMachines.length === 0 &&
                                <tbody className="">
                                    <tr>
                                        <td colSpan="3">
                                            <div className="alert alert-info m-0">There are no Machines matching this filter</div>
                                        </td>
                                    </tr>
                                </tbody>
                            }
                        </table>
                    }
                </div>
            </React.Fragment>
		)
	}
}

export const MachinesComponent = compose(
	queryWithLoading({
		gqlString: GetMachines,
        name: "Machines"
	}),
	withMutation(SaveMachine, "SaveMachine"),
    graphql(SaveMachine)
)(MachinesClass)

	// withMutation(MachineSaveMutation, "MachineSaveMutation", [{query: getMachinesQuery}]),
