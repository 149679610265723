import { gql } from '@apollo/client';

export const GetContainerInspections = gql`
    query GetContainerInspections {
        container_inspections {
            container_inspection_ID
            creator_ID
            date_created
            date_finished
            finisher_ID
            bad_parts
            bad_parts_end
            bad_parts_end_2
            bad_parts_start
            good_parts
            good_parts_end
            good_parts_end_2
            good_parts_start
            serial_number
            visual_count
        }
    }
`;

export const GetContainerInspection = gql`
    query GetContainerInspection($container_inspection_ID: ID, $serial_number: Int) {
        container_inspection(container_inspection_ID: $container_inspection_ID, serial_number: $serial_number) {
            container_inspection_ID
            creator_ID
            date_created
            date_finished
            finisher_ID
            first_name
            bad_parts
            bad_parts_end
            bad_parts_end_2
            bad_parts_start
            good_parts
            good_parts_end
            good_parts_end_2
            good_parts_start
            last_name
            serial_number
            visual_count
        }
    }
`;

export const GetInspectionUsers = gql`
    query GetInspectionUsers{
        users_with_production {
            user_ID
            first_name
            last_name
        }
    }
`;

export const SaveContainerInspection = gql`
	mutation containerInspectionSave($input: ContainerInspectionInput) {
		containerInspectionSave (input: $input) {
            ContainerInspection {
                container_inspection_ID
                creator_ID
                date_created
                date_finished
                finisher_ID
                first_name
                bad_parts
                bad_parts_end
                bad_parts_end_2
                bad_parts_start
                good_parts
                good_parts_end
                good_parts_end_2
                good_parts_start
                last_name
                serial_number
                visual_count
            }
		}
	}
`;

export const SaveInspection = gql`
	mutation inspectionSave($input: InspectionInput) {
		inspectionSave (input: $input) {
            Inspection {
                inspection_ID
                account_ID
                date_created
                date_inspected
                bad_parts
                good_parts
                machine_ID
                part_ID
                rerun
                run_time_hours
                run_time_minutes
            }
		}
	}
`;

// export const GetContainerInspectionsComponentData = gql`
//     query GetContainerInspectionsComponentData($active: Int) {
//         container_inspections {
//             part_ID,
//             archiver_ID,
//             creator_ID,
//             date_archived,
//             date_created,
//             hourly_standard_rate,
//             image_ID,
//             image_filename,
//             part_number,
//             part_number_external,
//             sale_price,
//             scrap_percentage_cost_sheet,
//             standard_tool_cost
//         }
//         materials {
//             material_ID,
//             archiver_ID,
//             cost_per_piece,
//             cost_per_pound,
//             creator_ID,
//             date_archived,
//             date_created,
//             description,
//             material_type,
//             material_type_ID,
//             part_number,
//             container_inspections_per,
//             supplier_ID,
//             supplier_name,
//             weight
//         }
//         material_BOMs {
//             material_BOM_ID,
//             archiver_ID,
//             creator_ID,
//             date_archived,
//             date_created,
//             description,
//             material_ID,
//             material_type,
//             part_ID,
//             part_number,
//             requirement_per_piece,
//             supplier_name
//         }
//         tooling(active: $active) {
//             tooling_ID,
//             active,
//             archiver_ID,
//             creator_ID,
//             cutting_edges,
//             date_archived,
//             date_created,
//             manufacturer_ID,
//             manufacturer_name,
//             manufacturer_part_number,
//             notes,
//             supplier_active,
//             supplier_ID,
//             supplier_name,
//             supplier_part_number,
//             tooling_cost,
//             tooling_description,
//             tooling_min,
//             tooling_part_IDs,
//             tooling_type_ID
//         }
//         tooling_BOMs {
//             tooling_BOM_ID,
//             archiver_ID,
//             cost_per_part,
//             creator_ID,
//             cutting_edges,
//             date_archived,
//             date_created,
//             manufacturer_name,
//             manufacturer_part_number,
//             part_ID,
//             pieces_per_edge,
//             supplier_name,
//             supplier_part_number,
//             tool_station,
//             tooling_description,
//             tooling_ID,
//             tooling_type
//         }
//     }
// `;



