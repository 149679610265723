import { gql } from '@apollo/client';

export const GetToolingUsage = gql`
    query GetToolingUsage {
        tooling_usage {
            tooling_usage_ID,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            date_scheduled,
            notes,
            production_record_ID,
            shift_ID,
            time_clock_record_ID,
            tooling_ID,
            tooling_usage_quantity
        }
    }
`;

export const ArchiveToolingUsage = gql`
	mutation toolingUsageArchive($input: ToolingUsageInput) {
		toolingUsageArchive (input: $input) {
            UpdatedToolingUsage {
                tooling_usage_ID,
                archiver_ID,
                creator_ID,
                date_archived,
                date_created,
                date_scheduled,
                notes,
                production_record_ID,
                shift_ID,
                time_clock_record_ID,
                tooling_ID,
                tooling_usage_quantity
            }
            
		}
	}
`;

export const SaveToolingUsage = gql`
	mutation toolingUsageSave($input: ToolingUsageInput) {
		toolingUsageSave (input: $input) {
            UpdatedToolingUsage {
                tooling_usage_ID,
                archiver_ID,
                creator_ID,
                date_archived,
                date_created,
                date_scheduled,
                machine_ID,
                notes,
                production_record_ID,
                shift_ID,
                time_clock_record_ID,
                tooling_ID,
                tooling_usage_quantity
            }
            
		}
	}
`;