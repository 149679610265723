import gql from 'graphql-tag';

// Generic Session info gql.
// const sessionInfo = `
// 	Session {
// 		sessionId
// 		LoggedIn
// 		User {
// 			userId
// 			companyId
// 			firstName
// 			lastName
// 			userTypeId
// 			Permissions {
// 				userPermissionId
// 				permissionLevel
// 				permissionLevelString
// 				Permission {
// 					permissionId
// 					permission
// 				}
// 			}
// 		}
// 	}
// `;
// ${sessionInfo}

export const GetUsersNoPassword = gql`
    query GetUsersNoPassword {
        users_no_login {
            user_ID,
            user_name
        }
    }
`;

export const LoginMutation = gql`
    mutation login($input: LoginInput) {
		login (input: $input) {
            user_ID
            date_actual_in
            date_scheduled
            department
            department_ID
            email
            first_name
            last_name
            logged_in
            message
            Messages {
                first_name
                last_name
                message
            }
            position
            position_ID
            reports_to
            reports_to_user_ID
            shift_group
            shift_ID
            time_actual_in
            time_actual_out
            time_clock_record_ID            
            time_clock_records_actual_ID            
            work_schedule_ID
            user_name
            user_type_ID
            UTC
		}
}`;

export const UserClockInOutLogInOut = gql`
    mutation userClockInOutLogInOut($input: ClockLogInOutInput) {
		userClockInOutLogInOut (input: $input) {
            user_ID
            date_scheduled
            first_name
            last_name
            logged_in
            message
            shift_ID
            time_actual_hours
            time_actual_in
            time_actual_out
            time_clock_record_ID
            work_schedule_ID
            user_name
            user_type_ID
		}
}`;



