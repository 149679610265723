import { gql } from '@apollo/client';

export const GetProductionRecords = gql`
    query GetProductionRecords {
        production_records {
            production_record_ID,
            active,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            date_production,
            good_parts_produced,
            hours_worked,
            machine_ID,
            machine_name,
            machine_short_name,
            notes,
            part_ID,
            part_number,
            scrap_parts_produced,
            shift_ID,
            shift_name,
            updated_standard_hourly_rate,
            use_updated_standard
        }
    }
`;

// Lazy Query for the Production Records Report component - clicking "Search", gets PRs matching filters
export const GetProductionRecordsReport = gql`
    query GetProductionRecordsReport($action: String, $date_end: DateTime, $date_start: DateTime) {
        production_records_report(action: $action, date_end: $date_end, date_start: $date_start) {
            production_record_ID,
            active,
            archiver_ID,
            bad_parts_end,
            bad_parts_end_2,
            bad_parts_start,
            creator_ID,
            customer_ID,
            date_archived,
            date_created,
            date_production,
            date_scheduled,
            first_name,
            good_parts_produced,
            good_parts_end,
            good_parts_end_2,
            good_parts_start,
            hourly_standard_rate,
            hours_worked,
            last_name,
            machine_ID,
            machine_name,
            machine_short_name,
            notes,
            part_ID,
            part_number,
            published,
            scrap_parts_produced,
            serial_number,
            shift_group,
            shift_ID,
            shift_name,
            updated_standard_hourly_rate,
            use_updated_standard
        }
    }
`;


export const GetOperatorProductionPage = gql`
    query GetOperatorProductionPage($active: Int) {
        downtime_reasons {
            downtime_reason_ID,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            downtime_reason
        }
        tooling(active: $active) {
            tooling_ID,
            active,
            archiver_ID,
            creator_ID,
            cutting_edges,
            date_archived,
            date_created,
            manufacturer_ID,
            manufacturer_name,
            manufacturer_part_number,
            notes,
            supplier_active,
            supplier_ID,
            supplier_name,
            supplier_part_number,
            tooling_cost,
            tooling_description,
            tooling_min,
            tooling_part_IDs,
            tooling_type_ID
        }
    }
`;

export const GetProductionRecordsPage = gql`
    query GetProductionRecordsPage($active: Int, $user_type_ID: Int) {
        production_records {
            production_record_ID,
            active,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            date_production,
            good_parts_produced,
            hours_worked,
            machine_ID,
            machine_name,
            machine_short_name,
            notes,
            part_ID,
            part_number,
            scrap_parts_produced,
            shift_ID,
            shift_name,
            updated_standard_hourly_rate,
            use_updated_standard
        }
        downtime_reasons {
            downtime_reason_ID,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            downtime_reason
        }
        downtimes {
            downtime_ID
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            downtime_hours,
            downtime_minutes,
            downtime_reason_ID,
            downtime_reason,
            notes,
            production_record_ID
        }
        machines {
            machine_ID,
            machine_name,
            machine_short_name,
            creator_ID,
            archiver_ID,
            date_created,
            date_archived
        }
        parts {
            part_ID,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            hourly_standard_rate,
            image_ID,
            image_filename,
            part_number,
            sale_price,
            scrap_percentage_cost_sheet,
            standard_tool_cost
        }
        production_records_users {
            production_record_ID
            user_ID,
            first_name,
            last_name
        }
        shifts {
            shift_ID,
            active,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            break_end_1,
            break_end_2,
            break_start_1,
            break_start_2,
            lunch_end,
            lunch_start,
            lunch_paid,
            note,
            shift_end,
            shift_hours_worked,
            shift_name,
            shift_start
        }
        tooling(active: $active) {
            tooling_ID,
            active,
            archiver_ID,
            creator_ID,
            cutting_edges,
            date_archived,
            date_created,
            manufacturer_ID,
            manufacturer_name,
            manufacturer_part_number,
            notes,
            supplier_active,
            supplier_ID,
            supplier_name,
            supplier_part_number,
            tooling_cost,
            tooling_description,
            tooling_min,
            tooling_part_IDs,
            tooling_type_ID
        }
        tooling_usages {
            tooling_usage_ID,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            notes,
            production_record_ID,
            tooling_ID,
            tooling_usage_quantity
        }
        users(user_type_ID: $user_type_ID) {
            user_ID,
            creator_ID,
            date_birth,
            date_created,
            email,
            first_name,
            hourly_rate,
            last_name,
            overtime_rate,
            user_type_ID
        }
    }
`;

// Used for production_records_report_component.js on load
export const GetProductionRecordsReportPage = gql`
    query GetProductionRecordsReportPage {
        customers {
            customer_ID
            customer
        }
        downtime_reasons {
            downtime_reason_ID,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            downtime_reason
        }
        machines {
            machine_ID,
            machine_name,
            machine_short_name,
            part_ID,
            creator_ID,
            archiver_ID,
            date_created,
            date_archived
        }
        parts {
            part_ID,
            archiver_ID,
            creator_ID,
            customer_ID,
            date_archived,
            date_created,
            hourly_standard_rate,
            image_ID,
            image_filename,
            part_number,
            part_number_short,
            pr_report_sort,
            sale_price,
            scrap_percentage_cost_sheet,
            standard_tool_cost
        }
        shifts {
            shift_ID,
            active,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            break_end_1,
            break_end_2,
            break_start_1,
            break_start_2,
            lunch_end,
            lunch_start,
            lunch_paid,
            note,
            shift_end,
            shift_hours_worked,
            shift_name,
            shift_start
        }
        tooling {
            tooling_ID,
            active,
            archiver_ID,
            creator_ID,
            cutting_edges,
            date_archived,
            date_created,
            manufacturer_ID,
            manufacturer_name,
            manufacturer_part_number,
            notes,
            supplier_active,
            supplier_ID,
            supplier_name,
            supplier_part_number,
            tooling_cost,
            tooling_description,
            tooling_min,
            tooling_part_IDs,
            tooling_type_ID
        }
        users {
            user_ID,
            active,
            creator_ID,
            date_birth,
            date_created,
            email,
            first_name,
            hourly_rate,
            last_name,
            overtime_rate,
            user_type_ID
        }
    }
`;

export const GetOperatorProductionDetails = gql`
    query GetOperatorProductionDetails( $date_scheduled: DateTime, $machine_ID: Int, $shift_ID: Int, $time_clock_record_ID: Int, $creator_ID: Int) {
        production_records_control_panel( date_scheduled: $date_scheduled, machine_ID: $machine_ID, shift_ID: $shift_ID, time_clock_record_ID: $time_clock_record_ID, creator_ID: $creator_ID) {
            production_record_ID,
            active,
            container_status,
            creator_ID,
            date_created,
            date_production,
            date_scheduled,
            first_name,
            good_parts_produced,
            last_name,
            machine_ID,
            notes,
            Operators {
                production_record_user_ID
                production_record_ID
                user_ID
                first_name
                last_name
            },
            part_ID,
            part_number,
            scrap_parts_produced,
            serial_number,
            shift_ID
        }
        downtimes_control_panel( date_scheduled: $date_scheduled, machine_ID: $machine_ID, shift_ID: $shift_ID, time_clock_record_ID: $time_clock_record_ID, creator_ID: $creator_ID) {
            downtime_ID
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            downtime_hours,
            downtime_minutes,
            downtime_reason_ID,
            downtime_reason,
            notes,
            production_record_ID
        }
        tooling_usages( date_scheduled: $date_scheduled, machine_ID: $machine_ID, shift_ID: $shift_ID, time_clock_record_ID: $time_clock_record_ID, creator_ID: $creator_ID) {
            tooling_usage_ID,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            notes,
            production_record_ID,
            tooling_ID,
            tooling_usage_quantity
        }
    }
`;

export const SaveProductionRecordReportFormSave = gql`
	mutation productionRecordReportFormSave($input: PRReportFormInput) {
		productionRecordReportFormSave (input: $input) {
            UpdatedProductionRecord {
                date_archived
                production_record_ID
            }
		}
	}
`;


export const SaveProductionRecordPublish = gql`
	mutation productionRecordPublish($input: PRPublishInput) {
		productionRecordPublish (input: $input) {
            UpdatedProductionRecord {
                production_record_ID
            }
		}
	}
`;

export const SaveProductionRecord = gql`
	mutation productionRecordSave($input: ProductionRecordInput) {
		productionRecordSave (input: $input) {
            UpdatedProductionRecord {
                production_record_ID,
                active,
                archiver_ID,
                container_status,
                creator_ID,
                date_archived,
                date_created,
                date_production,
                date_scheduled,
                Downtimes {
                    downtime_ID
                    archiver_ID,
                    creator_ID,
                    date_archived,
                    date_created,
                    downtime_hours,
                    downtime_minutes,
                    downtime_reason_ID,
                    notes,
                    production_record_ID
                },
                first_name,
                good_parts_produced,
                hours_worked,
                last_name,
                machine_ID,
                notes,
                Operators {
                    production_record_user_ID,
                    production_record_ID,
                    user_ID
                }
                part_ID,
                part_number
                scrap_parts_produced,
                serial_number,
                shift_ID,
                time_clock_record_ID,
                ToolingUsage {
                    tooling_usage_ID,
                    archiver_ID,
                    creator_ID,
                    date_archived,
                    date_created,
                    notes,
                    production_record_ID,
                    tooling_ID,
                    tooling_usage_quantity
                },
                updated_standard_hourly_rate,
                use_updated_standard
            }
		}
	}
`;

export const SaveProductionRecordsContainers = gql`
	mutation productionRecordContainerSave($input: ProductionRecordContainerInput) {
		productionRecordContainerSave (input: $input) {
            ProductionRecordsContainers {
                production_record_container_ID
                production_record_ID
                container_status
                creator_ID
                date_created
                serial_number
            }
		}
	}
`;


