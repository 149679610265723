import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import {flowRight as compose} from 'lodash';
import { queryWithLoading, displayDateTime } from '../utilities/NSDb.js';
import { useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import dayjs from 'dayjs';
import { add } from 'mathjs';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
import { useMutation, useLazyQuery } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// GRAPHQL QUERY
import { 
    GetMessages,
    GetMessagesPage,
    SaveMessage
} from './messages_graphql.js';

function AdminMessagesContent(props) {
    const auth = useAuthUser();
console.log({props})
    const { companies: Companies, users: Users } = props.componentData;

    const [save_message] = useMutation(SaveMessage);
    // const [get_messages] = useLazyQuery(GetMessages, { fetchPolicy: 'network-only'});

    // let DefaultMachine = {
    //     machine_ID: 0,
    //     Messages: []
    // }

    // let DefaultMessage = {
    //     archiver_ID: null,
    //     company_ID: 1,
    //     creator_ID: null,
    //     date_archived: null,
    //     date_created: null,
    //     date_end: null,
    //     date_start: '2023-12-12',
    //     first_name: '',
    //     last_name: '',
    //     message_ID: 0,
    //     message: 'Test Message',
    //     old_message: '',
    //     save_button_text: 'Save',
    //     show_for_days: '1',
    //     show_for_hours: '6',
    //     start_am_pm: 'AM',
    //     start_hours: '09',
    //     Users: []
    // };
    let DefaultMessage = {
        archiver_ID: null,
        company_ID: -1,
        creator_ID: null,
        date_archived: null,
        date_created: null,
        date_end: null,
        date_start: null,
        first_name: '',
        last_name: '',
        message_ID: 0,
        message: '',
        old_message: '',
        save_button_text: 'Save',
        show_for_days: '',
        show_for_hours: '0',
        start_am_pm: 'AM',
        start_hours: 0,
        Users: []
    };

    // const [SelectedMachine, setSelectedMachine] = useState({ ...DefaultMachine });
    const [SelectedMessage, setSelectedMessage] = useState({ ...DefaultMessage });
    const [BaseState, setBaseState] = useState({ show_users: false });
    const [Messages, setMessages] = useState( props.componentData.messages );
    // const [Machines, setMachines] = useState( props.componentData.machines_axon );

    // Save shift details
    async function saveMessage() {

        let SaveData = {...SelectedMessage};

        // Parse string values that are IDs to be integers, saving stringified numbers to the database throws an error.
        let MakeIntegers = ['company_ID', 'message_ID', 'start_hours'];
        MakeIntegers.forEach((mi) => SaveData[mi] = parseInt(SaveData[mi]));
        // Take start_am_pm, start_hours and add to the date_start
        let temp_start_hours = SaveData.start_am_pm === 'PM' ? add(SaveData.start_hours, 12) : SaveData.start_hours;
        SaveData.date_start = dayjs(`${dayjs(SaveData.date_start).format('YYYY-MM-DD')} ${temp_start_hours}:00:00`).format();

        // Create the date_end based on the date_start and the 'show_for_XX' variables
        let temp_date_end = SaveData.show_for_days !== '' && parseInt(SaveData.show_for_days) > 0 ? dayjs(SaveData.date_start).add(SaveData.show_for_days, 'day').format() : SaveData.date_start;
        temp_date_end = SaveData.show_for_hours > 0 ? dayjs(temp_date_end).add(SaveData.show_for_hours, 'hour').format() : temp_date_end;

        // Remove replaced_by_message_ID and old_note from object for saving
        SaveData = _.omit(SaveData, [ 'company', 'company_short', 'first_name', 'last_name', 'old_message', 'save_button_text', 'saved', 'show_for_days', 'show_for_hours', 'start_am_pm', 'start_hours']);

        // Only save date_created, creator_ID when this is a new message
        if ( parseInt(SaveData.message_ID) === 0 ) {
            SaveData.date_created = dayjs().format();
            SaveData.creator_ID = parseInt(auth().User.user_ID);
        } else {
            SaveData = _.omit(SaveData, [ 'creator_ID', 'date_created' ]);
        }

        let result = {};
        // If "All Companies" is selected, we have to send the array of Companies, and clean their integer values
        if ( parseInt(SelectedMessage.company_ID) === 0 ) {
            let TempCompanies = Companies.map((c) => {
                return {company_ID: parseInt(c.company_ID)}
            })
            result = await save_message({ variables: { input: { ...SaveData, date_end: temp_date_end, Companies: Companies.map((c) => {return {company_ID: parseInt(c.company_ID)}}) } }});
        } else {
            result = await save_message({ variables: { input: { ...SaveData, date_end: temp_date_end } }});
        }

        console.log({result})

        // Update the Messages with the newly saved one
        if( result.data && result.data.messageSave.Message ) {

            let NewMessage = result.data.messageSave.Message;
            let TempMessages = [ ...Messages ];

            if ( parseInt(SelectedMessage.message_ID) === 0 ) {

                // Clean the datetime values to not be DB UTC format
                NewMessage.date_created = dayjs(NewMessage.date_created).format();
                NewMessage.date_end = dayjs(NewMessage.date_end).format();
                NewMessage.date_start = dayjs(NewMessage.date_start).format();
                
                TempMessages.unshift(NewMessage)

            } else {

                TempMessages = TempMessages.map((m) => {

                    if ( parseInt(m.message_ID) === parseInt(NewMessage.message_ID) ) {
                        return NewMessage;
                    } else {
                        return m;
                    }
                });
            }

            // Update Selected Message
            setSelectedMessage({
                ...SelectedMessage,
                ...NewMessage,
                old_message: NewMessage.message,
                save_button_text: 'Saved'
            });

            // Update Messages
            setMessages(
                [...TempMessages]
            );
        }
    }

    // Handle input changes for State
    function changeInputValue(name, value) {

        // For inputs that must be integers, do not allow non numeric characters, and parseInt on the value
        if ( name === 'show_for_days' ) {
            if(value !== '') {
                value = parseInt(value)
                // Prevent 'NaN' 
                if( isNaN(value) ) {
                    value = null;
                }
            } else {
                // This is to avoid attempting to save "" as an INT
                value = null;
            }

        }

        // Reset the Save button to show that the form can be saved.
        setSelectedMessage({
            ...SelectedMessage,
            [name]: value ,
            save_button_text: 'Save'
        });

    }

    // Handler function for changing filters
    // async function handleChangeFilter(name, value) {
        
    // }

    // Handler for editing a message - need to parse out the 'show' values based on the dates
    function handleEditMessage(M) {
    
        // Set start_hours / am_pm
        let start_hours = dayjs(M.date_start).format('HH') > 12 ? dayjs(M.date_start).add(-12, 'hour').format('HH') : dayjs(M.date_start).format('HH');
        let start_am_pm = dayjs(M.date_start).format('A');
        console.log({start_hours})
        console.log({start_am_pm})

        // Set show_for variables
        let show_for_days = dayjs(M.date_end).diff(dayjs(M.date_start).format(), 'days');
        console.log({show_for_days})
        let show_for_hours = dayjs(M.date_end).diff(dayjs(M.date_start).add(show_for_days, 'day'), 'hour');
        console.log({show_for_hours})

        setSelectedMessage({
            ...DefaultMessage,
            ...M,
            company_ID: M.company_ID === null ? 0 : parseInt(M.company_ID),
            date_start: dayjs(M.date_start).format('YYYY-MM-DD'),
            show_for_days,
            show_for_hours,
            start_am_pm,
            start_hours
        })
    }

    console.log({SelectedMessage})
    console.log({Messages})
    console.log(auth().User)
    console.log("dayjs().format(): ", dayjs().format());
    
    // Validation to require all fields - cannot click save button
    let save_disabled = SelectedMessage.message === '' || parseInt(SelectedMessage.company_ID) === -1 || SelectedMessage.date_start === null || SelectedMessage.start_hours === null || (SelectedMessage.show_for_days === '' && parseInt(SelectedMessage.show_for_hours) === 0);
    
    return (
        <div className="">
            {
                (parseInt(auth().User.user_type_ID) > 2 &&

                <div className="">
                    
                    {/* Create a new Message */}
                    <div className="card">
                        <div className="card-header">
                            <span className="float-start">{( parseInt(SelectedMessage.message_ID) === 0 && 'Create New' ) || 'Update'} Message</span>
                            <span className="float-end">{SelectedMessage.first_name !== '' && SelectedMessage.first_name} {SelectedMessage.last_name !== '' && SelectedMessage.last_name} {SelectedMessage.date_created !== null && dayjs(SelectedMessage.date_created).format('M-D-YY h:mm a')}</span>
                        </div>
                        <div className="card-body">
                            <div className="mb-3">
                                <textarea className="form-control" id="message" placeholder="Enter message here" value={SelectedMessage.message} rows="5" onChange={(event) => changeInputValue('message', event.target.value)} />
                            </div>
                            <div className="clearfix">

                                {/* START SHOWING DATE*/}
                                <div className="form-floating float-start me-5 py-1">
                                    <input type="date" className="form-control mb-3" id="date_start" placeholder="Enter a Start Date" value={dayjs(SelectedMessage.date_start).format('YYYY-MM-DD') || ""} onChange={(event) => changeInputValue('date_start', event.target.value)} />
                                    <label className="text-muted" htmlFor="date_start">Start Showing</label>
                                </div>

                                {/* START SHOWING TIME */}
                                <div className="float-start me-5">
                                    <label className="text-muted" htmlFor="show_for_days">Start Showing At:</label>
                                    <div className="clearfix">
                                        <div className="float-start me-3">
                                                <select 
                                                    className="form-select" 
                                                    disabled={false}
                                                    onChange={(event) => changeInputValue('start_hours', event.target.value)}
                                                    value={SelectedMessage.start_hours}
                                                >
                                                    <option value="0">--</option>
                                                    <option value="01">01</option>
                                                    <option value="02">02</option>
                                                    <option value="03">03</option>
                                                    <option value="04">04</option>
                                                    <option value="05">05</option>
                                                    <option value="06">06</option>
                                                    <option value="07">07</option>
                                                    <option value="08">08</option>
                                                    <option value="09">09</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                </select>
                                            </div>
                                        <div className="float-start">
                                                <select 
                                                    className="form-select" 
                                                    disabled={false}
                                                    onChange={(event) => changeInputValue('start_am_pm', event.target.value)}
                                                    value={SelectedMessage.start_am_pm}
                                                >
                                                    <option value="AM">AM</option>
                                                    <option value="PM">PM</option>
                                                </select>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* SHOW MESSAGE FOR LENGTH DAYS / HOURS */}
                                <div className="float-start me-5">
                                    <label className="text-muted" htmlFor="show_for_days">Show Message For:</label>
                                    <div className="clearfix">
                                        <div className="float-start me-3">
                                            <div className="input-group" style={{height: 38+'px'}}>
                                                <input className="form-control float-end text-end px-1" style={{fontSize: 16+'px', width: 60+'px'}} value={SelectedMessage.show_for_days || ""} onChange={(event) => changeInputValue('show_for_days', event.target.value) } />
                                                <span className="input-group-text px-1" style={{fontSize: 13+'px'}}>Days</span>
                                            </div>
                                        </div>
                                        <div className="float-start">
                                            <div className="input-group" style={{height: 38+'px'}}>
                                                <select 
                                                    className="form-select" 
                                                    disabled={false}
                                                    onChange={(event) => changeInputValue('show_for_hours', event.target.value)}
                                                    value={SelectedMessage.show_for_hours}
                                                >
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="23">23</option>
                                                </select>
                                                <span className="input-group-text px-1" style={{fontSize: 13+'px'}}>Hour(s)</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* SHOW TO COMPANIES */}
                                <div className="float-start me-5">
                                    <label className="text-muted" htmlFor="show_for_days">Who Will See This Message:</label>
                                    <div className="clearfix">
                                        <div className="float-start me-3">
                                            <div className="input-group" style={{height: 38+'px'}}>
                                                <select 
                                                    className="form-select" 
                                                    disabled={false}
                                                    id="company_ID"
                                                    onChange={(event) => changeInputValue('company_ID', event.target.value)}
                                                    placeholder="Companies"
                                                    value={SelectedMessage.company_ID}
                                                >
                                                    <option key="-1" value="-1">Select Company</option>
                                                    <option key="0" value="0">All Companies</option>
                                                    {Companies.map((c) => {
                                                        return <option key={parseInt(c.company_ID)} value={parseInt(c.company_ID)}>{c.company}</option>
                                                    })}
                                                </select> 
                                                <button onClick={() => setBaseState({...BaseState, show_users: !BaseState.show_users})} className={`input-group-text ${parseInt(SelectedMessage.company_ID) > -1 && 'bg-primary text-white border border-primary'}`} disabled={parseInt(SelectedMessage.company_ID) === -1} >
                                                    {(!BaseState.show_users && 'Show Employees') || 'Hide Employees'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                {/* SHOW EMPLOYEES OF SELECTED COMPANY */}
                                {
                                    parseInt(SelectedMessage.company_ID) > -1 && BaseState.show_users &&
                                    <div className="float-start me-5 mt-1 ms-2">
                                        {Users.map((u) => {
                                            if ( (parseInt(SelectedMessage.company_ID) > 0 && parseInt(u.company_ID) === parseInt(SelectedMessage.company_ID)) || parseInt(SelectedMessage.company_ID) === 0 ) {
                                                return (
                                                    <div className="">{u.first_name} {u.last_name}</div>
                                                )
                                            } else {
                                                return false;
                                            }
                                        })}
                                    </div>
                                }
                                </div>


                            </div>
                            <div className="clearfix">
                                <button className="float-start me-3 btn fw-bold border-success border-3 text-success" disabled={SelectedMessage.save_button_text === 'Saved' || save_disabled} 
                                    onClick={() => saveMessage()}><FontAwesomeIcon icon="fa-floppy-disk" className="mt-1 me-2" />{SelectedMessage.save_button_text} {SelectedMessage.save_button_text === 'Saved' && <FontAwesomeIcon icon="fa-check" className="ms-1" />}
                                </button>
                                {parseInt(SelectedMessage.message_ID) === 0 && <div className="float-start mb-0 py-2 alert alert-info alert-sm">All fields are required for saving</div>}
                                <button className="float-end btn fw-bold border-secondary border-3 text-secondary" onClick={() => setSelectedMessage({ ...DefaultMessage })}>
                                    <FontAwesomeIcon icon="fa-times" className="mt-1 me-2" />{( parseInt(SelectedMessage.message_ID) > 0 && 'Close' ) || 'Cancel'}
                                </button>
                            </div>
                        </div>
                    </div>
                
                    {/* MESSAGES LIST */}
                    {
                        Messages.length > 0 &&
                        <>
                            <div className="mt-4">Messages created in the last 30 days:</div>
                            <table className={`mt-3 table ${parseInt(SelectedMessage.message_ID) === 0 && 'table-hover'}`} style={{fontSize: 12+'px'}}>
                                <thead className="row h6 border border-2 border-top-0 border-start-0 border-end-0 border-dark mb-0">
                                    <tr className="col-1">Created</tr>
                                    <tr className="col-1">Start Showing</tr>
                                    <tr className="col-1">Stop Showing</tr>
                                    <tr className="col-1">Visibility</tr>
                                    <tr className="col-8">Message</tr>
                                </thead>
                                <tbody className="">
                                    {Messages.map((m) => {
    console.log("Start: ", dayjs(m.date_start).diff(dayjs().format(), 'minute'), " | ", dayjs(m.date_start).format());
    console.log("End: ", dayjs(m.date_end).diff(dayjs().format(), 'minute'), " | ", dayjs(m.date_end).format());
                                        return (
                                            <React.Fragment key={m.message_ID}>
                                                <tr className="row" onClick={() => handleEditMessage(m)}>
                                                    <td className="col-1">
                                                        <span>{m.first_name} {m.last_name}<br />{dayjs(m.date_created).format('M-D h:mma')}</span>
                                                    </td>
                                                    <td className="col-1">
                                                        <span>{dayjs(m.date_start).format('M-D h:mma')}</span>
                                                    </td>
                                                    <td className="col-1">
                                                        <span>{dayjs(m.date_end).format('M-D h:mma')}</span>
                                                    </td>
                                                    <td className="col-1">
                                                        <span>{(parseInt(m.company_ID) > 0 && <span>{m.company}</span>) || 'All Companies'}</span><br />
                                                        {
                                                            dayjs(m.date_start).diff(dayjs().format(), 'minute') < 0 && dayjs(m.date_end).diff(dayjs().format(), 'minute') > 0 && 
                                                            <span className="badge badge-2 fw-bolders bg-success py-1 text-white">ACTIVE</span>
                                                        }
                                                    </td>
                                                    <td className="col-8">
                                                        <span>{m.message}</span>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </>
                    }
                </div>

                ) ||
                <div className="alert alert-warning">You do not have permission to set company wide Messages</div>
            }
        </div>
    )
}

export const AdminMessagesComponent = compose(
	queryWithLoading({
		gqlString: GetMessagesPage,
        name: "componentData",
        variablesFunction: () => ({ active: 1, date_end: dayjs().add(1, 'days').format(), date_start: dayjs().add(-30, 'days').format(), user_type_ID: 2 })
	})
)(AdminMessagesContent)

