import React from 'react';
// import { Multiselect } from 'multiselect-react-dropdown';
import {flowRight as compose} from 'lodash';
import { queryWithLoading } from '../utilities/NSDb.js';
// import { graphql } from '@apollo/react-hoc';
import { useEffect, useState } from 'react';
import { add, multiply, subtract, divide } from 'mathjs';
import dayjs from 'dayjs';
import { useAuthUser } from 'react-auth-kit';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
import { useMutation, useLazyQuery } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// GRAPHQL QUERY
// import {  
//     ArchiveDowntime
//     // ,
//     // SaveDowntime 
// } from '../downtimes/downtimes_graphql.js';
// import {  GetMachines } from '../machines/machines_graphql.js';
// import {  GetParts } from '../parts/parts_graphql.js';
// import {  GetShifts } from '../shifts/shifts_graphql.js';
// import {  GetTooling } from '../tooling/tooling_graphql.js';
// import {  ArchiveToolingUsage } from '../tooling_usage/tooling_usage_graphql.js';
// import {  GetUsers } from '../users/users_graphql.js';

import { 
    GetProductionRecordsReport,
    GetProductionRecordsReportPage,
    SaveProductionRecordReportFormSave,
    SaveProductionRecordPublish
} from './production_records_graphql.js';


function ProductionRecordsReportContent(props) {
    console.log({props})
    const auth = useAuthUser();

    // Set const values from props
    const { 
        customers: Customers,
        // downtime_reasons: DowntimeReasons,
        machines: Machines
        // shifts: Shifts,
        // tooling: Tooling
    } = props.componentData;

    let TempParts = [...props.componentData.parts];

    // Only active parts:
    TempParts = TempParts.filter((tp) => tp.archiver_ID === null);
    let Parts = [];

    // If logged in, sort by customer_ID. If logged out, sort by order
    if ( auth() ) {
        Parts = TempParts.sort(function(a,b) {
                    if(a.customer_ID > b.customer_ID) return -1;
                    if(a.customer_ID < b.customer_ID) return 1;
                    return 0;
                });
    } else {
        Parts = TempParts;
    }

    let Operators = props.componentData.users.filter((u) => parseInt(u.user_type_ID) === 2 && parseInt(u.active) === 1);

    // Testing - cumulative totals for part counts
    let CumulativePartCounts = { Shifts: [], Machines: [], Day: { good: 0, scrap: 0, std_diff: 0 }};

    const [save_PR] = useMutation(SaveProductionRecordReportFormSave);
    const [save_publish_PR] = useMutation(SaveProductionRecordPublish);
    const [get_production_records_report] = useLazyQuery(GetProductionRecordsReport, { fetchPolicy: 'network-only'});

    let temp_schedule_filter = handleScheduleFilter('yesterday');

    const [BaseState, setBaseState] = useState(
        {
            action: props.action || '',
            action_downtime: '',
            action_tooling_usage: '',
            Downtimes: props.componentData.downtimes,
            filter: '',
            filter_search: '',
            filter_search_message: '',
            filter_text_searched: '',
            FilteredPartsMachines: [],
            FilteredProductionRecords: [],
            PartsMachines: [],
            ProductionRecords: [],
            save_button_text: 'Save',
            save_button_text_downtime: 'Save',
            save_button_text_tooling: 'Save',
            save_message: '',
            save_message_downtime: '',
            save_message_tooling: '',
            searched: false,
            ToolingUsage: props.componentData.tooling_usage
        }
    );

    const [Filters, setFilters] = useState({
        customer_ID: 1,
        date_range: 'yesterday',
        display_machines: true,
        display_operator: true,
        display_scrap: false,
        display_view: auth() ? 'days' : 'parts',
        hide_no_records: true,
        message: '',
        part_ID: 0,
        schedule_end: temp_schedule_filter.temp_schedule_end,
        schedule_start: temp_schedule_filter.temp_schedule_start
    });

    const DefaultSelectedPR = {
        archiver_ID: 0, 
        creator_ID: 0,
        date_created: '',
        date_scheduled: '',
        good_parts_produced: 0,
        machine_ID: 0,
        operator_name: '',
        part_ID: 0,
        production_record_ID: 0,
        shift_group: 0
    }
    const [SelectedPR, setSelectedPR] = useState({ ...DefaultSelectedPR })

    // When logged out, on page load we need to get the latest production data
    useEffect(() => {

        console.log("USE EFFECT");
        if ( !auth() ) {
            handleSearchRecords().then(response => {
                setBaseState({
                    ...BaseState,
                    ...response
                })
            });
        }
    }, []);

    // Handler function for changing filters
    function handleChangeFilter(filter_name, filter_value, parent_object, key) {
        if ( filter_name === 'date_range' ) {
            let temp_schedule_filter = handleScheduleFilter(filter_value);
            let temp_display_view = filter_value === 'yesterday' || filter_value === 'today' ? 'days' : Filters.display_view;
            setFilters({
                ...Filters,
                display_view: temp_display_view,
                schedule_end: temp_schedule_filter.temp_schedule_end,
                schedule_start: temp_schedule_filter.temp_schedule_start,
                [filter_name]: filter_value,
                message: ''
            })
        } 
        else if ( filter_name === 'display_view' && filter_value === 'parts' ) {

            // IF "All Parts" is selected, then set the filter part_ID to the first option
            setFilters({
                ...Filters,
                [filter_name]: filter_value,
                display_operator: false,
                display_scrap: false,
                hide_no_records: false,
                part_ID: parseInt(Filters.part_ID) === 0 && Parts.length > 0 ? parseInt(Parts[0].part_ID) : parseInt(Filters.part_ID),
                message: ''
            });
        }
        else if ( filter_name === 'count_against_std' ) {
        
            let TempStd = { ...Filters.count_against_std[parent_object] };
            TempStd[key] = filter_value;
            setFilters({
                ...Filters,
                count_against_std: {
                    ...Filters.count_against_std,
                    [parent_object]: TempStd
                },
                message: ''
            });
        }
        else {
            setFilters({
                ...Filters,
                [filter_name]: filter_value,
                message: ''
            });
        }
    }

    // Take today's date and loop backwards until you get Sunday to use as your start day
    function handleScheduleFilter(filter) {
        let temp_weekday = dayjs().format('dddd');
        let temp_schedule_start = dayjs().format('YYYY-MM-DD');
        let temp_schedule_end = dayjs().add(6, 'day').format('YYYY-MM-DD');

        // Set this week's start and end
        if ( temp_weekday !== 'Sunday') {
            [...Array(6)].forEach((d, index) => {
                // If this is Sunday, set schedule_start
                if ( dayjs().add(-index, 'day').format('dddd') === 'Sunday' ) {
                    temp_schedule_start = dayjs().add(-index, 'day').format('YYYY-MM-DD');
                    temp_schedule_end = dayjs(temp_schedule_start).add(6, 'day').format('YYYY-MM-DD');
                }
            });
        }

        if ( filter === 'last_week' ) {
            temp_schedule_start = dayjs(temp_schedule_start).add(-7, 'day').format('YYYY-MM-DD');
            temp_schedule_end = dayjs(temp_schedule_end).add(-7, 'day').format('YYYY-MM-DD');
        } else if ( filter === 'last_month' ) {
            temp_schedule_start = dayjs(dayjs().add(-1, 'month')).startOf('month').format('YYYY-MM-DD');
            temp_schedule_end = dayjs(dayjs().add(-1, 'month')).endOf('month').format('YYYY-MM-DD');
        } else if ( filter === 'this_month' ) {
            temp_schedule_start = dayjs().startOf('month').format('YYYY-MM-DD');
            temp_schedule_end = dayjs().endOf('month').format('YYYY-MM-DD');
        } else if ( filter === 'yesterday' ) {
            temp_schedule_start = dayjs().add(-1, 'day').format('YYYY-MM-DD');
            temp_schedule_end = dayjs().add(-1, 'day').format('YYYY-MM-DD');
        } else if ( filter === 'today' ) {
            temp_schedule_start = dayjs().format('YYYY-MM-DD');
            temp_schedule_end = dayjs().format('YYYY-MM-DD');
        }

        return { temp_schedule_end, temp_schedule_start };
    }

    // Handler for publishing / unpublishing the PR Report for the day to show to operators
    async function handlePublishPRReport(action, date_scheduled) {
    
        await save_publish_PR({ variables: { input: { published: action === 'Publish' ? 1 : 0, date_scheduled: date_scheduled } }});
        // Update all of the FilteredPRs with this new flag
        let NewPRs = BaseState.FilteredProductionRecords.map((fpr) => {
            return { ...fpr, PR: fpr.PR.map((pr) => { return { ...pr, published: action === 'Publish' ? 1 : 0 }}) }
        })
        setBaseState({
            ...BaseState,
            FilteredProductionRecords: NewPRs
        })
    }

    // Get Production Records based on the filters
    async function handleSearchRecords(date_end='', date_start='') {
    
        const Records = await get_production_records_report({ variables: { action: !auth() ? 'shop_tv' : '', date_end: date_end, date_start: date_start } });
console.log({Records})
        if (Records.data && Records.data.production_records_report) {

            // For each Part, get the full list of machines that made that Part. This will be our header.
            let TempParts = [];
            let TempShiftGroups = [];

            Records.data.production_records_report.forEach((pr) => {

                let temp_index = TempParts.findIndex((tp) => parseInt(pr.part_ID) === parseInt(tp.part_ID));
                // If this part is in the array already, check if the machine is also on it too
                if ( temp_index === -1 ) {

                    // Add this part and its machine as a new object
                    TempParts.push({ ...Parts.filter((p) => parseInt(p.part_ID) === parseInt(pr.part_ID))[0], part_ID: parseInt(pr.part_ID), part_number: pr.part_number, Machines: [{ machine_ID: parseInt(pr.machine_ID), machine_name: pr.machine_name, machine_short_name: pr.machine_short_name }] });

                } 
                // Add machine to Parts Machines array if it is not there
                else if ( TempParts[temp_index].Machines.findIndex((m) => parseInt(m.machine_ID) === parseInt(pr.machine_ID)) === -1 ) {

                    TempParts[temp_index].Machines.push({ machine_ID: parseInt(pr.machine_ID), machine_name: pr.machine_name, machine_short_name: pr.machine_short_name });
                }

                // Also get a list of the shifts working for ease of building out the monthly view
                if ( TempShiftGroups.findIndex((tsg) => parseInt(tsg.shift_group) === parseInt(pr.shift_group)) === -1 ) {
                    TempShiftGroups.push({ shift_group: parseInt(pr.shift_group) });
                }
            });

            // Loop through TempParts and add any machines to it that are supposed to be making parts but did not run. This is to show what we could have been producing if all machines were operational
            TempParts = TempParts.map((p) => {
            
                let TempPartsMachines = p.Machines;

                // Get all Machines that are making this part, based off of the machines db record's part_ID. Do not include Shipping or QC.
                // TO DO: Remove this 60 and 40 for commercialization
                let TempMachines = Machines.filter((m) => parseInt(m.part_ID) === parseInt(p.part_ID) && parseInt(m.machine_ID) !== 60 && parseInt(m.machine_ID) !== 40);

                // Loop these Machines and ensure that each is on the TempParts array, if not, then push that Machine to the PartsMachines array
                TempMachines.forEach((m) => {

                    if ( p.Machines.findIndex((pm) => parseInt(pm.machine_ID) === parseInt(m.machine_ID)) === -1 ) {
                        TempPartsMachines.push({ machine_ID: parseInt(m.machine_ID), machine_name: m.machine_name, machine_short_name: m.machine_short_name })
                    }
                });

                // Sort the Machines since we just added new ones
                TempPartsMachines = TempPartsMachines.sort(function(a,b) {
                    if(parseInt(a.machine_ID) < parseInt(b.machine_ID)) return -1;
                    if(parseInt(a.machine_ID) > parseInt(b.machine_ID)) return 1;
                    return 0;
                });

                return { ...p, Machines: TempPartsMachines }

            });

            // Sort the ShiftGroups so that 1 is always first
            TempShiftGroups = TempShiftGroups.sort(function(a,b) {
                if(a.shift_group < b.shift_group) return -1;
                if(a.shift_group > b.shift_group) return 1;
                return 0;
            });

            // If we are logged out, displaying PRs for the Shop TV, we will only ever show a single day. We get all of a day's PR records for whatever the latest published PR record's date_scheduled is
            let days = 1;
            let TempPRDays = [];
            if ( !auth() ) {
                TempPRDays = [...Array(days)].map((d, index) => {

                    // If there are no records, setup a default structure
                    if ( Records.data.production_records_report.length === 0 ) {
                        return { date_scheduled: dayjs().format('YYYY-MM-DD'), PR: [] };
                    } else {
                        // All PR records when logged out have the same date_scheduled, so just grab the first record's
                        // let temp_day = dayjs(Records.data.production_records_report[0].date_scheduled).format('YYYY-MM-DD');
                        return { date_scheduled: dayjs(Records.data.production_records_report[0].date_scheduled).format('YYYY-MM-DD'), PR: Records.data.production_records_report }
                    }
                    
                })
            } else {
                // Number of days of records to build out:
                days = auth() ? dayjs(Filters.schedule_end).diff(dayjs(Filters.schedule_start), 'day')+1 : 1;
                
                // Group production records by the date_scheduled, do not return an array object if there are no production records on that day
                TempPRDays = [...Array(days)].map((d, index) => {

                    let temp_day = dayjs(Filters.schedule_start).add(index, 'day').format('YYYY-MM-DD');
                    return { date_scheduled: temp_day, PR: Records.data.production_records_report.filter((pr) => dayjs(temp_day).isSame(pr.date_scheduled, 'day')) };
                }).filter((d) => d.PR.length > 0);
            }


            //////////  DAILY VIEW BUILD OUT //////////////
                // Group days by part FOR THE DAILY VIEW
                TempPRDays = TempPRDays.map((prd) => {

                    let TempParts = [];
                    prd.PR.forEach((pr) => {
                        
                        let temp_part_index = TempParts.findIndex((tp) => parseInt(tp.part_ID) === parseInt(pr.part_ID));
                        // If Part already exists in the TempParts array, then push the 'pr' object of TempPRDays' map to this Part's TempParts PR array
                        if ( temp_part_index > -1 ) {
                            TempParts[temp_part_index].PR.push(pr);
                        } 
                        // Otherwise, create a new array object for this Part
                        else {
                            TempParts.push({ customer_ID: pr.customer_ID, hourly_standard_rate: pr.hourly_standard_rate, part_ID: parseInt(pr.part_ID), part_number: pr.part_number, PR: [pr] });
                        }
                    });

                    return { ...prd, Parts: TempParts };
                });

                // Group parts by machine FOR THE DAILY VIEW
                TempPRDays = TempPRDays.map((prd) => {

                    let TempParts = [...prd.Parts];
                    TempParts = TempParts.map((p) => {

                        let TempMachines = [];
                        p.PR.forEach((pr) => {
                        
                            let temp_machine_index = TempMachines.findIndex((tm) => parseInt(tm.machine_ID) === parseInt(pr.machine_ID));
                            // If Machine already exists in the TempMachines array, then push the 'pr' object of TempPRDays' map to this Machine's TempMachines PR array
                            if ( temp_machine_index > -1 ) {
                                TempMachines[temp_machine_index].PR.push(pr);
                            } 
                            // Otherwise, create a new array object for this Machine
                            else {
                                TempMachines.push({ machine_ID: parseInt(pr.machine_ID), machine_name: pr.machine_name, machine_short_name: pr.machine_short_name, PR: [pr] });
                            }
                        });

                        // Before finalizing the Machines that made this Part, check if there are Machines that were supposed to make this part, based off of the machines db record's part_ID, but were not operational so we can count those against standard.
                        // TO DO: Remove this 60 and 40 for commercialization
                        let TempPartsMachines = Machines.filter((m) => parseInt(m.part_ID) === parseInt(p.part_ID) && parseInt(m.machine_ID) !== 60 && parseInt(m.machine_ID) !== 40);

                        // Loop these Machines and ensure that each is on the TempParts array, if not, then push that Machine to the PartsMachines array
                        TempPartsMachines.forEach((m) => {

                            if ( TempMachines.findIndex((tm) => parseInt(tm.machine_ID) === parseInt(m.machine_ID)) === -1 ) {
                                TempMachines.push({ machine_ID: parseInt(m.machine_ID), machine_name: m.machine_name, machine_short_name: m.machine_short_name, PR: [] })
                            }
                        });

                        // Sort the Machines since we just added new ones
                        TempMachines = TempMachines.sort(function(a,b) {
                            if(parseInt(a.machine_ID) < parseInt(b.machine_ID)) return -1;
                            if(parseInt(a.machine_ID) > parseInt(b.machine_ID)) return 1;
                            return 0;
                        });

                        return { ...p, Machines: TempMachines };
                        
                    });

                    return { ...prd, Parts: TempParts };
                });
            //////////  END OF DAILY VIEW BUILD OUT //////////////


            ////////// MONTHLY VIEW BUILD OUT /////////////
                // Group PRs by Part, then Day, Shift, Machine
                TempParts = TempParts.map((tp) => {
                
                    // Calculate the std for each part per 8 hour shift and all Machines for ease of use later.
                    let shift_std = 0;
                    let machine_std = 0;
                    let shift_rate = tp.hourly_standard_rate !== '' && tp.hourly_standard_rate !== null ? multiply(tp.hourly_standard_rate, 8) : 0;

                    // Loop through each Day there is a PR
                    let TempDays = TempPRDays.map((prd, index) => {
                    
                        // Add the PR records for each shift
                        let temp_shifts = TempShiftGroups.map((tsg, s_index) => {

                            if ( index === 0 && tp.hourly_standard_rate !== '' && tp.hourly_standard_rate !== null ) {
                                machine_std = add(machine_std, shift_rate);
                            }

                            // For each Machine used for this Part
                            let temp_machines = tp.Machines.map((m, m_index) => {
                            
                                if ( index === 0 && s_index === 0 && tp.hourly_standard_rate !== '' && tp.hourly_standard_rate !== null ) {
                                    shift_std = add(shift_std, shift_rate);
                                }
                                // Filter this day's PRs down by Part
                                let temp_PRs = prd.PR.filter((pr) => parseInt(pr.machine_ID) === parseInt(m.machine_ID) && parseInt(pr.shift_group) === parseInt(tsg.shift_group) && parseInt(pr.part_ID) === parseInt(tp.part_ID));

                                // Temp fix for combined 157s
                                // if ( parseInt(tp.part_ID) === -1 ) {
                                //     temp_PRs = prd.PR.filter((pr) => parseInt(pr.machine_ID) === parseInt(m.machine_ID) && parseInt(pr.shift_group) === parseInt(tsg.shift_group) && (parseInt(pr.part_ID) === 1 || parseInt(pr.part_ID) === 36));
                                // } 
                                // else if ( parseInt(tp.part_ID) === -2 ) {
                                //     temp_PRs = prd.PR.filter((pr) => parseInt(pr.machine_ID) === parseInt(m.machine_ID) && parseInt(pr.shift_group) === parseInt(tsg.shift_group) && (parseInt(pr.part_ID) === 1 || parseInt(pr.part_ID) === 36));
                                // }


                                return { machine_ID: parseInt(m.machine_ID), PR: temp_PRs };

                            });

                            return { shift_group: parseInt(tsg.shift_group), Machines: temp_machines };

                        });

                        return { date_scheduled: prd.date_scheduled, Shifts: temp_shifts };
                    });

                    return { ...tp, PRDates: TempDays, machine_std, shift_rate, shift_std };

                });    


                // Sort the TempParts by the part's pr_report_sort value
                TempParts = TempParts.sort(function(a,b) {
                                if(a.pr_report_sort < b.pr_report_sort) return -1;
                                if(a.pr_report_sort > b.pr_report_sort) return 1;
                                return 0;
                            });

                // When logged out, we only want to show the Axon parts, and the 300,400,500,600 parts.
                if ( !auth() ) {
                    let AllowedPartIDs = [36,2,3,4,5,7,10,11]
                    TempParts = TempParts.filter((p) => AllowedPartIDs.findIndex((ap) => ap === parseInt(p.part_ID)) > -1 );
                }
       
                let TempFilteredParts = TempParts;
                let TempFilteredPRDays = TempPRDays;

                // If a part filter is selected, filter down to just show that part
                if ( parseInt(Filters.part_ID) !== 0 ) {
                    TempFilteredParts = TempFilteredParts.filter((p) => parseInt(p.part_ID) === parseInt(Filters.part_ID));
                    TempFilteredPRDays = TempFilteredPRDays.map((pr) => {

                        let temp_parts = pr.Parts.filter((p) => parseInt(p.part_ID) === parseInt(Filters.part_ID));
                        return { ...pr, Parts: temp_parts };
                    })
                }
            ////////// END OF MONTHLY VIEW BUILD OUT /////////////

            // If logged out, return new BaseState object to use on component load. In logged in, update BaseState.
            if ( auth() ) {
                setBaseState({
                    ...BaseState,
                    FilteredProductionRecords: TempFilteredPRDays,
                    FilteredPartsMachines: TempFilteredParts,
                    PartsMachines: TempParts,
                    ProductionRecords: TempPRDays,
                    searched: true
                });
            }
            else {
                return ({
                    FilteredProductionRecords: TempFilteredPRDays,
                    FilteredPartsMachines: TempFilteredParts,
                    PartsMachines: TempParts,
                    ProductionRecords: TempPRDays,
                    searched: true
                })
            }
            
        } else {
            setBaseState({
                ...BaseState,
                searched: true
            });
        }
    }

    // // Robot Part Counter - takes start, end, end_2 and returns are part count. End 2 is the part count at the end of a shift.
    // function handleRobotCount(start, end, end_2, running_total) {
    //     // end_2 > 0 on shift change only. Subtract end_2 (the end of the shift's part count) and the start count of the container. Add end (the end count for the container, its start will be 0 since it is the start of a new shift). Add the running total.
    //     return end_2 !== null && end_2 !== '' ? add(subtract(end_2, start), end, running_total) : add(subtract(end, start), running_total);
    // }

    // Handler to Archive/Unarchive production record
    async function handleSavePR() {
    
        let result = {};

        // Send different save data based on the Selected PR action
        if ( SelectedPR.action === 'edit' ) {
            result = await save_PR({ variables: { input: { creator_ID: parseInt(SelectedPR.creator_ID), date_scheduled: SelectedPR.date_scheduled, good_parts_produced: parseInt(SelectedPR.good_parts_produced), production_record_ID: parseInt(SelectedPR.production_record_ID), shift_group: parseInt(SelectedPR.shift_group) } }});
        } else {
            result = await save_PR({ variables: { input: { archiver_ID: parseInt(SelectedPR.archiver_ID), production_record_ID: parseInt(SelectedPR.production_record_ID) } }});
        }

        console.log({result})

        // Update all of the FilteredPRs with this new flag
        if ( result.data && result.data.productionRecordReportFormSave && result.data.productionRecordReportFormSave.UpdatedProductionRecord && result.data.productionRecordReportFormSave.UpdatedProductionRecord.production_record_ID && parseInt(result.data.productionRecordReportFormSave.UpdatedProductionRecord.production_record_ID) > 0 ) {

            let NewPRs = BaseState.FilteredProductionRecords.map((fpr) => {

                // Only make updates to the date_scheduled for the SelectedPR's date_scheduled
                if ( fpr.date_scheduled === SelectedPR.date_scheduled ) {

                    let TempParts = fpr.Parts.map((p) => {

                        // Only make updates to the Part for the SelctedPA's Part
                        if ( parseInt(p.part_ID) === parseInt(SelectedPR.part_ID )) {
                            
                            let TempMachines = p.Machines.map((m) => {
                            
                                // Only make updates to the Machine for the SelctedPA's Machine
                                if ( parseInt(m.machine_ID) === parseInt(SelectedPR.machine_ID )) {
                                    
                                    let TempPRs = m.PR.map((pr) => {
                                    
                                        // Only update the selected PR
                                        if ( parseInt(pr.production_record_ID) === parseInt(SelectedPR.production_record_ID) ) {

                                            // Change the data of the SelectedPR based on the action
                                            if ( SelectedPR.action === 'edit' ) {
                                                // Get the saved Operator
                                                let NewOperator = Operators.filter((o) => parseInt(o.user_ID) === parseInt(SelectedPR.creator_ID))[0];

                                                return { ...pr, creator_ID: parseInt(SelectedPR.creator_ID), date_scheduled: SelectedPR.date_scheduled, first_name: NewOperator.first_name, good_parts_produced: parseInt(SelectedPR.good_parts_produced), last_name: NewOperator.last_name, shift_group: parseInt(SelectedPR.shift_group) }
                                            } else {
                                                return { ...pr, archiver_ID: parseInt(SelectedPR.archiver_ID) }
                                            }
                                        }
                                        else return pr;

                                    });
                                    return { ...m, PR: TempPRs };
                                } 
                                else return m;
                            
                            });
                            return { ...p, Machines: TempMachines };
                        } 
                        else return p;
                    });
                    return { ...fpr, Parts: TempParts }
                } 
                else return fpr
            });
            console.log("Update new PRs")
            setBaseState({
                ...BaseState,
                FilteredProductionRecords: NewPRs
            });

            handleClosePRArchiveConfirmation();

        }
    }

    // Update SelectedPR state to close the archive confirmation form - we still want the PR details to be open so we preserve the date, machine, and part
    function handleClosePRArchiveConfirmation() {
        setSelectedPR({ 
            ...DefaultSelectedPR, 
            date_scheduled: SelectedPR.date_scheduled, 
            machine_ID: parseInt(SelectedPR.machine_ID), 
            part_ID: parseInt(SelectedPR.part_ID)
        });
    }
    
console.log({BaseState})
console.log({SelectedPR})

    // LOGGED IN VIEW
    if ( auth() !== null ) {

        // If the search was for the 'Day' view, 'All Parts', and a single day, show the 'Publish Report' button
        let show_publish_report = '';
        if ( Filters.display_view === 'days' && parseInt(Filters.part_ID) === 0 && Filters.schedule_end === Filters.schedule_start && BaseState.FilteredProductionRecords.length > 0) {
            // If ANY are published, they are all considered published
            let published = BaseState.FilteredProductionRecords[0].PR.findIndex((pr) => parseInt(pr.published) === 1) > -1;
            show_publish_report = published ? 'Unpublish' : 'Publish';
        }
        return (
            <>
                <div>
                    <>
                        <div className="row clearfix mb-3 justify-content-center">
                            {/* RESULTS COUNTER AND FILTERS */}

                            {/* DATE SELECTIONS */}
                            <div className="col-auto">
                                <div className="form-floating">
                                    <select 
                                        className="form-select" 
                                        disabled={false}
                                        id="date_range"
                                        onChange={(event) => handleChangeFilter('date_range', event.target.value)}
                                        placeholder="Date Range"
                                        value={Filters.date_range}
                                    >
                                        <option key="-1" value="today">Today</option>
                                        <option key="0" value="yesterday">Yesterday</option>
                                        <option key="1" value="this_week">This Week</option>
                                        <option key="2" value="this_month">This Month</option>
                                        <option key="3" value="last_month">Last Month</option>
                                        <option key="4" value="last_week">Last Week</option>
                                    </select> 
                                    <label className="text-muted" htmlFor="date_range">Date Range</label>                                                   
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-floating">
                                    <input type="date" className="form-control mb-3" id="schedule_start" placeholder="Enter a Start Date" value={Filters.schedule_start || ""} onChange={(event) => setFilters({ ...Filters, schedule_start: event.target.value, searched: false })} />
                                    <label className="text-muted" htmlFor="schedule_start">Start Date</label>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-floating">
                                    <input type="date" className="form-control mb-3" id="schedule_end" placeholder="Enter an End Date" value={Filters.schedule_end || ""} onChange={(event) => setFilters({ ...Filters, schedule_end: event.target.value, searched: false })} />
                                    <label className="text-muted" htmlFor="schedule_end">End Date</label>
                                </div>
                            </div>

                            {/* PART SELECTION */}
                            <div className="col-auto">
                                <div className="form-floating">
                                    <select 
                                        className="form-select" 
                                        disabled={false}
                                        id="part_ID"
                                        onChange={(event) => handleChangeFilter('part_ID', event.target.value)}
                                        placeholder="Part Selection"
                                        value={Filters.part_ID}
                                    >
                                        <option key="0" value="0">All Parts</option>
                                        {Parts.map((p) => {
                                            return <option key={parseInt(p.part_ID)} value={parseInt(p.part_ID)}>{p.part_number}</option>
                                        })}
                                    </select> 
                                    <label className="text-muted" htmlFor="part_ID">Part Selection</label>                                                   
                                </div>
                            </div>

                            
                            {/* <Multiselect 
                                className="bg-white"
                                id="Operator"
                                onRemove={(selectedList, removedItem, action) => handleFilterFlagsMultiselect(selectedList, removedItem, 'remove')}
                                onSelect={(selectedList, selectedItem, action) => handleFilterFlagsMultiselect(selectedList, selectedItem, 'select')}
                                options={FilterFlags}
                                selectedValues={BaseState.FilterFlags}
                                displayValue="flag"
                                placeholder="Time Filters"
                                hidePlaceholder="true"
                                style={{
                                    searchBox: {
                                        'paddingTop': '15px',
                                        'paddingBottom': '5px'
                                    }
                                }}
                            /> */}

                            {/* VIEW FILTER BUTTONS */}
                            <div className="col-auto">
                                <div className="">View</div>
                                <div className="btn-group btn-group-sm" role="group">
                                    <button className={`btn btn-outline-secondary ${(Filters.display_view === 'days' && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('display_view', 'days')}>Days</button>
                                    <button className={`btn btn-outline-secondary ${(Filters.display_view === 'parts' && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('display_view', 'parts')}>Part</button>
                                </div>                            
                            </div>

                            {/* VIEW FILTER BUTTONS */}
                            <div className="col-auto">
                                <div className="">Count No Production Against Standard</div>
                                <div className="btn-group btn-group-sm" role="group">
                                    <button className={`btn btn-outline-secondary ${(Filters.hide_no_records === true && 'btn-secondary text-white') || 'text-dark'}`} disabled={Filters.display_view === 'parts'} onClick={() => handleChangeFilter('hide_no_records', true)}>No</button>
                                    <button className={`btn btn-outline-secondary ${(Filters.hide_no_records === false && 'btn-secondary text-white') || 'text-dark'} ${Filters.display_view === 'parts' && 'bg-secondary'}`} disabled={Filters.display_view === 'parts'} onClick={() => handleChangeFilter('hide_no_records', false)}>Yes</button>
                                    {Filters.display_view === 'parts' && <span className="text-danger ms-2 pt-1">Disabled for Part View</span>}
                                </div>                            
                            </div>

                            {/* CUSTOMER FILTER BUTTONS */}
                            <div className="col-auto">
                                <div className="">Customers</div>
                                <div className="btn-group btn-group-sm" role="group">
                                    <button className={`btn btn-outline-secondary ${(Filters.customer_ID === '' && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('customer_ID', '')}>
                                        All
                                    </button>
                                    {Customers.map((c) => {
                                        return (
                                            <button key={c.customer_ID} className={`btn btn-outline-secondary ${(parseInt(Filters.customer_ID) === parseInt(c.customer_ID) && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('customer_ID', parseInt(c.customer_ID))}>
                                                {c.customer}
                                            </button>
                                        )
                                    })}
                                </div>                            
                            </div>

                            {/* DISPLAY FILTER BUTTONS */}
                            <div className="col-auto">
                                <div className="">Display in Results</div>
                                <div className="btn-group btn-group-sm" role="group">
                                    <button className={`btn btn-outline-secondary ${(Filters.display_machines === true && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('display_machines', !Filters.display_machines)}>
                                        Machines
                                    </button>
                                    <button className={`btn btn-outline-secondary ${(Filters.display_operator === true && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('display_operator', !Filters.display_operator)}>
                                        Operator
                                    </button>
                                    <button className={`btn btn-outline-secondary ${(Filters.display_scrap === true && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('display_scrap', !Filters.display_scrap)}>
                                        Scrap
                                    </button>
                                </div>                            
                            </div>

                            {/* DAYS FILTER BUTTONS */}
                            {/*                             
                            <div className="col-auto">
                                <div className="">Count Against Standard</div>
                                <div className="btn-group btn-group-sm" role="group">
                                    <button className={`btn btn-outline-secondary ${(Filters.count_against_std.days.Monday === true && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('count_against_std', !Filters.count_against_std.days.Monday, 'days', 'Monday')}>M</button>
                                    <button className={`btn btn-outline-secondary ${(Filters.count_against_std.days.Tuesday === true && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('count_against_std', !Filters.count_against_std.days.Tuesday, 'days', 'Tuesday')}>T</button>
                                    <button className={`btn btn-outline-secondary ${(Filters.count_against_std.days.Wednesday === true && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('count_against_std', !Filters.count_against_std.days.Wednesday, 'days', 'Wednesday')}>W</button>
                                    <button className={`btn btn-outline-secondary ${(Filters.count_against_std.days.Thursday === true && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('count_against_std', !Filters.count_against_std.days.Thursday, 'days', 'Thursday')}>T</button>
                                    <button className={`btn btn-outline-secondary ${(Filters.count_against_std.days.Friday === true && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('count_against_std', !Filters.count_against_std.days.Friday, 'days', 'Friday')}>F</button>
                                    <button className={`btn btn-outline-secondary ${(Filters.count_against_std.days.Saturday === true && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('count_against_std', !Filters.count_against_std.days.Saturday, 'days', 'Saturday')}>S</button>
                                    <button className={`btn btn-outline-secondary ${(Filters.count_against_std.days.Sunday === true && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('count_against_std', !Filters.count_against_std.days.Sunday, 'days', 'Sunday')}>S</button>
                                </div>                            
                            </div> */}

                            {/* MACHINES FILTER BUTTONS */}
                            {/* {
                                parseInt(Filters.part_ID) !== 0 && BaseState.FilteredPartsMachines.length > 0 &&
                                <div className="col-auto">
                                    <div className="">Count Against Standard</div>
                                    <div className="btn-group btn-group-sm" role="group">
                                        <button className={`btn btn-outline-secondary ${(Filters.count_against_std.days.Monday === true && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('count_against_std', !Filters.count_against_std.days.Monday, 'days', 'Monday')}>M</button>
                                        <button className={`btn btn-outline-secondary ${(Filters.count_against_std.days.Tuesday === true && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('count_against_std', !Filters.count_against_std.days.Tuesday, 'days', 'Tuesday')}>T</button>
                                        <button className={`btn btn-outline-secondary ${(Filters.count_against_std.days.Wednesday === true && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('count_against_std', !Filters.count_against_std.days.Wednesday, 'days', 'Wednesday')}>W</button>
                                        <button className={`btn btn-outline-secondary ${(Filters.count_against_std.days.Thursday === true && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('count_against_std', !Filters.count_against_std.days.Thursday, 'days', 'Thursday')}>T</button>
                                        <button className={`btn btn-outline-secondary ${(Filters.count_against_std.days.Friday === true && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('count_against_std', !Filters.count_against_std.days.Friday, 'days', 'Friday')}>F</button>
                                        <button className={`btn btn-outline-secondary ${(Filters.count_against_std.days.Saturday === true && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('count_against_std', !Filters.count_against_std.days.Saturday, 'days', 'Saturday')}>S</button>
                                        <button className={`btn btn-outline-secondary ${(Filters.count_against_std.days.Sunday === true && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('count_against_std', !Filters.count_against_std.days.Sunday, 'days', 'Sunday')}>S</button>
                                    </div>                            
                                </div>
                            } */}

                            <div className="col-auto">
                                <button 
                                    className="btn mt-1 fw-bold col-auto border-primary border-3 float-end text-primary" 
                                    onClick={() => handleSearchRecords(Filters.schedule_end, Filters.schedule_start)}
                                    disabled={Filters.schedule_end === '' || Filters.schedule_start === ''}
                                >
                                    Search
                                </button>
                            </div>
                        </div>

                        <div className="row mb-3">

                            {/* RESULTS COUNTER AND FILTERS */}
                            <div className="col h4 mb-1 pt-1">
                                
                                {/* {BaseState.filter === 'Active' && ' Active'}{BaseState.filter === 'Obsolete' && ' Obsolete'}
                                {` ${BaseState.ToolingTypes.filter((tt) => parseInt(tt.tooling_type_ID) === parseInt(BaseState.filter_tooling_type_ID))[0].tooling_type}`}
                                {` ${(BaseState.FilteredTooling.length === 1 && 'Tool') || 'Tools'}`}
                                {
                                    BaseState.filter_text_searched !== '' &&
                                    <span className=""> - Matching Search "{BaseState.filter_text_searched}"</span>
                                } */}
                            </div>

                        </div>
                    </>


                    {/* DAY VIEW - Only show table view to Office and Admins */}
                    {
                        parseInt(auth().User.user_type_ID) > 2 && Filters.display_view === 'days' &&
                        <>
                            {
                                BaseState.FilteredProductionRecords.length > 0 &&
                                <>
                                    { show_publish_report !== '' && 
                                        <div className="row justify-content-center mb-3">
                                            <div className={`btn ${(show_publish_report === 'Publish' && 'btn-success') || 'btn-danger'} col-auto`} onClick={() => handlePublishPRReport(show_publish_report, Filters.schedule_end)}>
                                                <FontAwesomeIcon icon="fa-clipboard-list" className="mt-1 me-2" />{show_publish_report} Production Report for <span className="fw-bolder">{dayjs(Filters.schedule_end).format('dddd M-D')}</span>
                                            </div>
                                        </div>
                                    }
                                    <table className="table table-hover" style={{fontSize: 12+'px'}}>
                                        <tbody className="">
                                            {BaseState.FilteredProductionRecords.map((pr) => {

                                                return (
                                                    <React.Fragment key={pr.date_scheduled}>
                                                        {/* Top row with Date */}
                                                        <tr className="row">
                                                            <td className="col-12 text-white bg-dark">
                                                                <span>{dayjs(pr.date_scheduled).format('dddd M-D-YYYY')}</span>
                                                                <span className="ms-4">* Denotes multiple Production Records for a single Machine on the same Shift</span>
                                                            </td>
                                                        </tr>

                                                        {pr.Parts.map((p) => {
                                                            // Set Part totals for the entire Shift
                                                            let temp_part_shift_1_total = 0;
                                                            let temp_part_shift_2_total = 0;
                                                            let temp_part_shift_3_total = 0;
                                                            let temp_part_shift_1_total_scrap = 0;
                                                            let temp_part_shift_2_total_scrap = 0;
                                                            let temp_part_shift_3_total_scrap = 0;
                                                            let temp_part_shift_standard = p.hourly_standard_rate !== '' && p.hourly_standard_rate !== null ? multiply(p.hourly_standard_rate, 8) : '';

                                                            let temp_machine_shifts_total = 0;
                                                            let temp_total = 0;
                                                            let temp_part_shift_1_diff = 0;
                                                            let temp_part_shift_2_diff = 0;
                                                            let temp_part_shift_3_diff = 0;
                                                            let temp_part_diff = 0;

                                                            // If Customer filter selected, only show Customer's parts
                                                            if ( Filters.customer_ID === '' || (parseInt(Filters.customer_ID) > 0 && parseInt(p.customer_ID) === parseInt(Filters.customer_ID))) {
                                                                return (
                                                                    <React.Fragment key={p.part_ID}>
                                                                        <tr className="row">
                                                                            <td className="col-2 bg-secondary text-white p-1">{p.part_number} {p.hourly_standard_rate !== '' && p.hourly_standard_rate !== null && <span className="h6"><span className="text-secondary bg-white badge">{p.hourly_standard_rate} / hr</span></span>}</td>
                                                                            <td className="col-3 bg-secondary text-white p-1">1st Shift {p.hourly_standard_rate !== '' && p.hourly_standard_rate !== null && <span className="h6 ms-2"><span className="text-secondary bg-white badge">{multiply(p.hourly_standard_rate, 8)} / shift</span></span>}</td>
                                                                            <td className="col-3 bg-secondary text-white p-1">2nd Shift {p.hourly_standard_rate !== '' && p.hourly_standard_rate !== null && <span className="h6 ms-2"><span className="text-secondary bg-white badge">{multiply(p.hourly_standard_rate, 8)} / shift</span></span>}</td>
                                                                            <td className="col-3 bg-secondary text-white p-1">3rd Shift {p.hourly_standard_rate !== '' && p.hourly_standard_rate !== null && <span className="h6 ms-2"><span className="text-secondary bg-white badge">{multiply(p.hourly_standard_rate, 8)} / shift</span></span>}</td>
                                                                            <td className="col-1 bg-secondary text-white p-1">Machine Total</td>
                                                                        </tr>
                                                                        
                                                                        {p.Machines.map((m) => {
                                                                            // Set Part totals for the Machine for each Shift
                                                                            let temp_shift_1_total = 0;
                                                                            let temp_shift_2_total = 0;
                                                                            let temp_shift_3_total = 0;
                                                                            let temp_shift_1_total_scrap = 0;
                                                                            let temp_shift_2_total_scrap = 0;
                                                                            let temp_shift_3_total_scrap = 0;
                                                                            // Set Part totals for the Machine for each Shift - Robot Good
                                                                            // let temp_shift_1_total_robot = 0;
                                                                            // let temp_shift_2_total_robot = 0;
                                                                            // let temp_shift_3_total_robot = 0;
                                                                            // let temp_shift_1_total_robot_bad = 0;
                                                                            // let temp_shift_2_total_robot_bad = 0;
                                                                            // let temp_shift_3_total_robot_bad = 0;
                                                                            // Set Operators for each Shift
                                                                            let TempOperatorsShift1 = [];
                                                                            let TempOperatorsShift2 = [];
                                                                            let TempOperatorsShift3 = [];

                                                                            // Combine all PRs for this machine for each shift
                                                                            let pr_shift_1 = false;
                                                                            let pr_shift_2 = false;
                                                                            let pr_shift_3 = false;

                                                                            // Count the number of PR records each Shift / Machine to flag where there are more than 1 PR created
                                                                            let pr_shift_1_count = 0;
                                                                            let pr_shift_2_count  = 0;
                                                                            let pr_shift_3_count  = 0;

                                                                            // Denote if there is an archived PR on the shift
                                                                            let pr_shift_1_archived = 0;
                                                                            let pr_shift_2_archived = 0;
                                                                            let pr_shift_3_archived = 0;

                                                                            m.PR.forEach((mpr) => {

                                                                                // DO NOT COUNT THIS PRODUCTION RECORD TOWARDS ANYTHING IF IT IS ARCHIVED
                                                                                if ( parseInt(mpr.archiver_ID) === 0 ) {
                                                                                    if ( parseInt(mpr.shift_group) === 1 ) { 
                                                                                        pr_shift_1 = true;
                                                                                        pr_shift_1_count = add(pr_shift_1_count,1);
                                                                                        temp_shift_1_total = add(temp_shift_1_total, parseInt(mpr.good_parts_produced));
                                                                                        temp_shift_1_total_scrap = add(temp_shift_1_total_scrap, parseInt(mpr.scrap_parts_produced));
                                                                                        temp_part_shift_1_total = add(temp_part_shift_1_total, parseInt(mpr.good_parts_produced));
                                                                                        temp_part_shift_1_total_scrap = add(temp_part_shift_1_total_scrap, parseInt(mpr.scrap_parts_produced));
                                                                                        if ( TempOperatorsShift1.findIndex((o) => o === `${mpr.first_name} ${mpr.last_name.charAt(0)}`) === -1 ) {
                                                                                            TempOperatorsShift1.push(`${mpr.first_name} ${mpr.last_name.charAt(0)}`);
                                                                                        }
                                                                                        // temp_shift_1_total_robot = mpr.good_parts_start !== null && mpr.good_parts_end !== null ? handleRobotCount(mpr.good_parts_start, mpr.good_parts_end, mpr.good_parts_end_2, temp_shift_1_total_robot) : temp_shift_1_total_robot;
                                                                                        // temp_shift_1_total_robot_bad = mpr.bad_parts_start !== null && mpr.bad_parts_end !== null ? handleRobotCount(mpr.bad_parts_start, mpr.bad_parts_end, mpr.bad_parts_end_2, temp_shift_1_total_robot_bad) : temp_shift_1_total_robot_bad;
                                                                                    } else if ( parseInt(mpr.shift_group) === 2 ) { 
                                                                                        pr_shift_2 = true;
                                                                                        pr_shift_2_count = add(pr_shift_2_count,1);
                                                                                        temp_shift_2_total = add(temp_shift_2_total, parseInt(mpr.good_parts_produced));
                                                                                        temp_shift_2_total_scrap = add(temp_shift_2_total_scrap, parseInt(mpr.scrap_parts_produced));
                                                                                        temp_part_shift_2_total = add(temp_part_shift_2_total, parseInt(mpr.good_parts_produced));
                                                                                        temp_part_shift_2_total_scrap = add(temp_part_shift_2_total_scrap, parseInt(mpr.scrap_parts_produced));
                                                                                        if ( TempOperatorsShift2.findIndex((o) => o === `${mpr.first_name} ${mpr.last_name.charAt(0)}`) === -1 ) {
                                                                                            TempOperatorsShift2.push(`${mpr.first_name} ${mpr.last_name.charAt(0)}`);
                                                                                        }
                                                                                        // temp_shift_2_total_robot = mpr.good_parts_start !== null && mpr.good_parts_end !== null ? handleRobotCount(mpr.good_parts_start, mpr.good_parts_end, mpr.good_parts_end_2, temp_shift_2_total_robot) : temp_shift_2_total_robot;
                                                                                        // temp_shift_2_total_robot_bad = mpr.bad_parts_start !== null && mpr.bad_parts_end !== null ? handleRobotCount(mpr.bad_parts_start, mpr.bad_parts_end, mpr.bad_parts_end_2, temp_shift_2_total_robot_bad) : temp_shift_2_total_robot_bad;
                                                                                    } else if ( parseInt(mpr.shift_group) === 3 ) { 
                                                                                        pr_shift_3 = true;
                                                                                        pr_shift_3_count = add(pr_shift_3_count,1);
                                                                                        temp_shift_3_total = add(temp_shift_3_total, parseInt(mpr.good_parts_produced));
                                                                                        temp_shift_3_total_scrap = add(temp_shift_3_total_scrap, parseInt(mpr.scrap_parts_produced));
                                                                                        temp_part_shift_3_total = add(temp_part_shift_3_total, parseInt(mpr.good_parts_produced));
                                                                                        temp_part_shift_3_total_scrap = add(temp_part_shift_3_total_scrap, parseInt(mpr.scrap_parts_produced));
                                                                                        if ( TempOperatorsShift3.findIndex((o) => o === `${mpr.first_name} ${mpr.last_name.charAt(0)}`) === -1 ) {
                                                                                            TempOperatorsShift3.push(`${mpr.first_name} ${mpr.last_name.charAt(0)}`);
                                                                                        }
                                                                                        // temp_shift_3_total_robot = mpr.good_parts_start !== null && mpr.good_parts_end !== null ? handleRobotCount(mpr.good_parts_start, mpr.good_parts_end, mpr.good_parts_end_2, temp_shift_3_total_robot) : temp_shift_3_total_robot;
                                                                                        // temp_shift_3_total_robot_bad = mpr.bad_parts_start !== null && mpr.bad_parts_end !== null ? handleRobotCount(mpr.bad_parts_start, mpr.bad_parts_end, mpr.bad_parts_end_2, temp_shift_3_total_robot_bad) : temp_shift_3_total_robot_bad;
                                                                                    }
                                                                                } else {
                                                                                    if ( parseInt(mpr.shift_group) === 1 ) { 
                                                                                        pr_shift_1_archived = 1;
                                                                                    } else if ( parseInt(mpr.shift_group) === 2 ) { 
                                                                                        pr_shift_2_archived = 1;
                                                                                    } else if ( parseInt(mpr.shift_group) === 3 ) { 
                                                                                        pr_shift_3_archived = 1;
                                                                                    }
                                                                                }
                                                                            })
                                                                            temp_machine_shifts_total = add(temp_shift_1_total,temp_shift_2_total,temp_shift_3_total);
                                                                            temp_total = add(temp_part_shift_1_total,temp_part_shift_2_total,temp_part_shift_3_total);

                                                                            // Get the different between the standard and the parts made on this machine, but not if filter is selected to not count no PR against standard.
                                                                            // These are for just this shift / machine
                                                                            let temp_shift_1_diff = temp_part_shift_standard !== '' && (( Filters.hide_no_records && pr_shift_1 === true ) || Filters.hide_no_records === false ) ? subtract(temp_shift_1_total, temp_part_shift_standard) : 0;
                                                                            let temp_shift_2_diff = temp_part_shift_standard !== '' && (( Filters.hide_no_records && pr_shift_2 === true ) || Filters.hide_no_records === false ) ? subtract(temp_shift_2_total, temp_part_shift_standard) : 0;
                                                                            let temp_shift_3_diff = temp_part_shift_standard !== '' && (( Filters.hide_no_records && pr_shift_3 === true ) || Filters.hide_no_records === false ) ? subtract(temp_shift_3_total, temp_part_shift_standard) : 0;
                                                                            let temp_shifts_diff = add(temp_shift_1_diff,temp_shift_2_diff,temp_shift_3_diff);

                                                                            // These are for the entire shift
                                                                            temp_part_shift_1_diff = temp_part_shift_standard !== '' ? add(temp_part_shift_1_diff, temp_shift_1_diff) : temp_part_shift_1_diff;
                                                                            temp_part_shift_2_diff = temp_part_shift_standard !== '' ? add(temp_part_shift_2_diff, temp_shift_2_diff) : temp_part_shift_2_diff;
                                                                            temp_part_shift_3_diff = temp_part_shift_standard !== '' ? add(temp_part_shift_3_diff, temp_shift_3_diff) : temp_part_shift_3_diff;
                                                                            temp_part_diff = temp_part_shift_standard !== '' ? add(temp_part_shift_1_diff,temp_part_shift_2_diff,temp_part_shift_3_diff) : 0;

                                                                            if ( Filters.display_machines ) {
                                                                                return (
                                                                                    <>
                                                                                        <tr key={m.machine_ID} className="row" onClick={() => setSelectedPR({ date_scheduled: pr.date_scheduled, machine_ID: parseInt(m.machine_ID), part_ID: parseInt(p.part_ID)})}>
                                                                                            <td className="p-1 col-2">{(m.machine_short_name !== '' && m.machine_short_name !== null && m.machine_short_name) || m.machine_name}</td>
                                                                                            <td className="p-1 col-3">
                                                                                                {
                                                                                                    ((( Filters.hide_no_records && pr_shift_1 ) || Filters.hide_no_records === false ) &&
                                                                                                    <>
                                                                                                    {pr_shift_1_count > 1 && '*'}{pr_shift_1_archived === 1 && <span className="text-danger">*</span>}
                                                                                                    {temp_shift_1_total}{Filters.display_scrap && temp_shift_1_total_scrap > 0 && <span className="badge bg-secondary text-white ms-2">{temp_shift_1_total_scrap}</span>}{temp_shift_1_diff !== '' && <span className={`badge text-white ms-2 ${ ( temp_shift_1_diff > 0 && 'bg-success' ) || 'bg-danger'}`}>{temp_shift_1_diff}</span>}
                                                                                                    {Filters.display_operator && TempOperatorsShift1.length > 0 && TempOperatorsShift1.map((o, index) => <span key={index} className="ms-2">{o}</span>)}
                                                                                                    {/* {temp_shift_1_total_robot !== 0 && <span className="badge bg-primary text-white ms-2">{temp_shift_1_total_robot}</span>} */}
                                                                                                    </>) ||
                                                                                                    <>
                                                                                                        {pr_shift_1_archived === 1 && <span className="text-danger">*</span>}
                                                                                                        <span className="badge bg-white text-secondary border border-secondary">No Production</span>
                                                                                                    </>
                                                                                                }
                                                                                            </td>
                                                                                            <td className="p-1 col-3">
                                                                                                {
                                                                                                    ((( Filters.hide_no_records && pr_shift_2 ) || Filters.hide_no_records === false ) &&
                                                                                                    <>
                                                                                                    {pr_shift_2_count > 1 && '*'}{pr_shift_2_archived === 1 && <span className="text-danger">*</span>}
                                                                                                    {temp_shift_2_total}{Filters.display_scrap && temp_shift_2_total_scrap > 0 && <span className="badge bg-secondary text-white ms-2">{temp_shift_2_total_scrap}</span>}{temp_shift_2_diff !== '' && <span className={`badge text-white ms-2 ${ ( temp_shift_2_diff > 0 && 'bg-success' ) || 'bg-danger'}`}>{temp_shift_2_diff}</span>}
                                                                                                    {Filters.display_operator && TempOperatorsShift2.length > 0 && TempOperatorsShift2.map((o, index) => <span key={index} className="ms-2">{o}</span>)}
                                                                                                    {/* {temp_shift_2_total_robot !== 0 && <span className="badge bg-primary text-white ms-2">{temp_shift_2_total_robot}</span>} */}
                                                                                                    </>) ||
                                                                                                    <>
                                                                                                        {pr_shift_2_archived === 1 && <span className="text-danger">*</span>}
                                                                                                        <span className="badge bg-white text-secondary border border-secondary">No Production</span>
                                                                                                    </>
                                                                                                }
                                                                                            </td>
                                                                                            <td className="p-1 col-3">
                                                                                                {
                                                                                                    ((( Filters.hide_no_records && pr_shift_3 ) || Filters.hide_no_records === false ) &&
                                                                                                    <>
                                                                                                    {pr_shift_3_count > 1 && '*'}{pr_shift_3_archived === 1 && <span className="text-danger">*</span>}
                                                                                                    {temp_shift_3_total}{Filters.display_scrap && temp_shift_3_total_scrap > 0 && <span className="badge bg-secondary text-white ms-2">{temp_shift_3_total_scrap}</span>}{temp_shift_3_diff !== '' && <span className={`badge text-white ms-2 ${ ( temp_shift_3_diff > 0 && 'bg-success' ) || 'bg-danger'}`}>{temp_shift_3_diff}</span>}
                                                                                                    {Filters.display_operator && TempOperatorsShift3.length > 0 && TempOperatorsShift3.map((o, index) => <span key={index} className="ms-2">{o}</span>)}
                                                                                                    {/* {temp_shift_3_total_robot !== 0 && <span className="badge bg-primary text-white ms-2">{temp_shift_3_total_robot}</span>} */}
                                                                                                    </>) ||
                                                                                                    <>
                                                                                                        {pr_shift_3_archived === 1 && <span className="text-danger">*</span>}
                                                                                                        <span className="badge bg-white text-secondary border border-secondary">No Production</span>
                                                                                                    </>
                                                                                                }
                                                                                            </td>
                                                                                            <td className="p-1 col-1">
                                                                                                {temp_machine_shifts_total}
                                                                                                {temp_shifts_diff !== 0 && <span className={`badge text-white ms-2 ${ ( temp_shifts_diff > 0 && 'bg-success' ) || 'bg-danger'}`}>{temp_shifts_diff}</span>}
                                                                                            </td>
                                                                                        </tr>

                                                                                        {/* If this Machine's row is clicked, show the PR details for each shift on that machine */}
                                                                                        {
                                                                                            SelectedPR.date_scheduled === pr.date_scheduled && SelectedPR.machine_ID === parseInt(m.machine_ID) && SelectedPR.part_ID === parseInt(p.part_ID) &&
                                                                                            <>

                                                                                                {/* LIST EACH SHIFTS INDIVIDUAL PRODUCTION RECORDS FOR A MACHINE */}
                                                                                                <tr key={`${m.machine_ID}-0`} className="row border-top">
                                                                                                    <td className="p-1 col-2">
                                                                                                        <div className="fw-bold">Production Details</div>
                                                                                                        <div className="btn border btn-sm fw-bolder py-0 border-secondary border-3 text-secondary" onClick={() => setSelectedPR({ date_scheduled: '', machine_ID: 0, part_ID: 0})}><FontAwesomeIcon icon="fa-times" className="me-2" />Close</div>
                                                                                                    </td>
                                                                                                    <td className="p-1 col-3">
                                                                                                        {
                                                                                                            m.PR.map((pr1) => {
                                                                                                                if ( parseInt(pr1.shift_group) === 1 ) {
                                                                                                                    return (
                                                                                                                        <div className="">
                                                                                                                            <span className="me-1" onClick={() => setSelectedPR({ 
                                                                                                                                    ...SelectedPR, 
                                                                                                                                    action: 'edit',
                                                                                                                                    creator_ID: parseInt(pr1.creator_ID),
                                                                                                                                    date_created: pr1.date_created,
                                                                                                                                    good_parts_produced: pr1.good_parts_produced,
                                                                                                                                    operator_name: `${pr1.first_name} ${pr1.last_name.charAt(0)}`,
                                                                                                                                    production_record_ID: parseInt(pr1.production_record_ID),
                                                                                                                                    shift_group: parseInt(pr1.shift_group)
                                                                                                                                })}><FontAwesomeIcon icon="fa-pen" className="text-success" />
                                                                                                                            </span>
                                                                                                                            <span className="fw-bold me-2">{pr1.good_parts_produced}</span>
                                                                                                                            <span className="me-1">{pr1.first_name} {pr1.last_name.charAt(0)}</span>@
                                                                                                                            <span className="ms-1">{dayjs(pr1.date_created).format('h:mm:ss a')}</span>
                                                                                                                            <span className={`ms-2 badge text-white ${(parseInt(pr1.archiver_ID) > 0 && 'bg-success') || 'bg-danger'}`} 
                                                                                                                                onClick={() => setSelectedPR({ 
                                                                                                                                    ...SelectedPR, 
                                                                                                                                    action: parseInt(pr1.archiver_ID) > 0 ? 'unarchive' : 'archive',
                                                                                                                                    archiver_ID: parseInt(pr1.archiver_ID) > 0 ? 0 : parseInt(auth().User.user_ID), 
                                                                                                                                    date_created: pr1.date_created,
                                                                                                                                    good_parts_produced: pr1.good_parts_produced,
                                                                                                                                    operator_name: `${pr1.first_name} ${pr1.last_name.charAt(0)}`,
                                                                                                                                    production_record_ID: parseInt(pr1.production_record_ID)
                                                                                                                                })}>
                                                                                                                                {(parseInt(pr1.archiver_ID) > 0 && 'Unarchive') || 'Archive'}
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                } else return false;
                                                                                                            })
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td className="p-1 col-3">
                                                                                                        {
                                                                                                            m.PR.map((pr2) => {
                                                                                                                if ( parseInt(pr2.shift_group) === 2 ) {
                                                                                                                    return (
                                                                                                                        <div className="">
                                                                                                                            <span className="me-1" onClick={() => setSelectedPR({ 
                                                                                                                                    ...SelectedPR, 
                                                                                                                                    action: 'edit',
                                                                                                                                    creator_ID: parseInt(pr2.creator_ID),
                                                                                                                                    date_created: pr2.date_created,
                                                                                                                                    good_parts_produced: pr2.good_parts_produced,
                                                                                                                                    operator_name: `${pr2.first_name} ${pr2.last_name.charAt(0)}`,
                                                                                                                                    production_record_ID: parseInt(pr2.production_record_ID),
                                                                                                                                    shift_group: parseInt(pr2.shift_group)
                                                                                                                                })}><FontAwesomeIcon icon="fa-pen" className="text-success" />
                                                                                                                            </span>
                                                                                                                            <span className="fw-bold me-2">{pr2.good_parts_produced}</span>
                                                                                                                            <span className="me-1">{pr2.first_name} {pr2.last_name.charAt(0)}</span>@
                                                                                                                            <span className="ms-1">{dayjs(pr2.date_created).format('h:mm:ss a')}</span>
                                                                                                                            <span className={`ms-2 badge text-white ${(parseInt(pr2.archiver_ID) > 0 && 'bg-success') || 'bg-danger'}`} 
                                                                                                                                onClick={() => setSelectedPR({ 
                                                                                                                                    ...SelectedPR, 
                                                                                                                                    action: parseInt(pr2.archiver_ID) > 0 ? 'unarchive' : 'archive',
                                                                                                                                    archiver_ID: parseInt(pr2.archiver_ID) > 0 ? 0 : parseInt(auth().User.user_ID), 
                                                                                                                                    date_created: pr2.date_created,
                                                                                                                                    good_parts_produced: pr2.good_parts_produced,
                                                                                                                                    operator_name: `${pr2.first_name} ${pr2.last_name.charAt(0)}`,
                                                                                                                                    production_record_ID: parseInt(pr2.production_record_ID)
                                                                                                                                })}>
                                                                                                                                {(parseInt(pr2.archiver_ID) > 0 && 'Unarchive') || 'Archive'}
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                } else return false;
                                                                                                            })
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td className="p-1 col-3">
                                                                                                        {
                                                                                                            m.PR.map((pr3) => {
                                                                                                                if ( parseInt(pr3.shift_group) === 3 ) {
                                                                                                                    return (
                                                                                                                        <div className="">
                                                                                                                            <span className="me-1" onClick={() => setSelectedPR({ 
                                                                                                                                    ...SelectedPR, 
                                                                                                                                    action: 'edit',
                                                                                                                                    creator_ID: parseInt(pr3.creator_ID),
                                                                                                                                    date_created: pr3.date_created,
                                                                                                                                    good_parts_produced: pr3.good_parts_produced,
                                                                                                                                    operator_name: `${pr3.first_name} ${pr3.last_name.charAt(0)}`,
                                                                                                                                    production_record_ID: parseInt(pr3.production_record_ID),
                                                                                                                                    shift_group: parseInt(pr3.shift_group)
                                                                                                                                })}><FontAwesomeIcon icon="fa-pen" className="text-success" />
                                                                                                                            </span>
                                                                                                                            <span className="fw-bold me-2">{pr3.good_parts_produced}</span>
                                                                                                                            <span className="me-1">{pr3.first_name} {pr3.last_name.charAt(0)}</span>@
                                                                                                                            <span className="ms-1">{dayjs(pr3.date_created).format('h:mm:ss a')}</span>
                                                                                                                            <span className={`ms-2 badge text-white ${(parseInt(pr3.archiver_ID) > 0 && 'bg-success') || 'bg-danger'}`} 
                                                                                                                                onClick={() => setSelectedPR({ 
                                                                                                                                    ...SelectedPR, 
                                                                                                                                    action: parseInt(pr3.archiver_ID) > 0 ? 'unarchive' : 'archive',
                                                                                                                                    archiver_ID: parseInt(pr3.archiver_ID) > 0 ? 0 : parseInt(auth().User.user_ID), 
                                                                                                                                    date_created: pr3.date_created,
                                                                                                                                    good_parts_produced: pr3.good_parts_produced,
                                                                                                                                    operator_name: `${pr3.first_name} ${pr3.last_name.charAt(0)}`,
                                                                                                                                    production_record_ID: parseInt(pr3.production_record_ID)
                                                                                                                                })}>
                                                                                                                                {(parseInt(pr3.archiver_ID) > 0 && 'Unarchive') || 'Archive'}
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                } else return false;
                                                                                                            })
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td className="p-1 col-1">

                                                                                                    </td>
                                                                                                </tr>

                                                                                                {/* CONFIRM ARCHIVE OR DELETE OF PRODUCTION RECORDS */}
                                                                                                {
                                                                                                    parseInt(SelectedPR.production_record_ID) > 0 && ( SelectedPR.action === 'archive' || SelectedPR.action === 'unarchive' ) &&
                                                                                                    <>
                                                                                                        <tr className="row">
                                                                                                            <td className="col-2"></td>
                                                                                                            <td className="col-9">
                                                                                                                <div className="alert alert-info m-0 py-1">
                                                                                                                    Do not archive a record here if the Good Parts record is just incorrect - use the green pen icon above to open the edit form to correct the Good Parts if needed. In general, only Archived a record to leave a single Production Record if the operator recorded duplicate records.
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td className="col-1"></td>
                                                                                                        </tr>
                                                                                                        <tr className="row">
                                                                                                            <td className="col-2"></td>
                                                                                                            <td className="col-9">
                                                                                                                <div className="alert alert-danger m-0 py-1">
                                                                                                                    I know what I am doing and want to {`${(parseInt(SelectedPR.archiver_ID) === 0 && 'Unarchive') || 'Archive'}`} this Production Record
                                                                                                                    <div className={`btn btn-sm bg-white fw-bolder py-0 px-1 ms-2 border border-3 ${(parseInt(SelectedPR.archiver_ID) === 0 && 'border-success text-success') || 'border-danger text-danger'}`} onClick={() => handleSavePR()}>{(parseInt(SelectedPR.archiver_ID) === 0 && 'Unarchive PR') || 'Archive PR'}</div>
                                                                                                                    <div className="btn btn-sm bg-white text-secondary fw-bolder py-0 border border-3 border-secondary float-end" onClick={() => handleClosePRArchiveConfirmation()}><FontAwesomeIcon icon="fa-times" className="me-2" />Close</div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td className="col-1"></td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                }

                                                                                                {/* EDIT PRODUCTION RECORD */}
                                                                                                {
                                                                                                    parseInt(SelectedPR.production_record_ID) > 0 && ( SelectedPR.action === 'edit' ) &&
                                                                                                    <>
                                                                                                        <tr className="row">
                                                                                                            <td className="col-2"></td>
                                                                                                            <td className="col-9">
                                                                                                                <div className="alert alert-info m-0 py-1">
                                                                                                                    In general, only change the Good Parts if the operator recorded a single Production Record for this shift / machine, and the number is just incorrect. If the operator recorded duplicate records, use the red 'Archive' button above instead to remove those parts from reporting.
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td className="col-1"></td>
                                                                                                        </tr>
                                                                                                        <tr className="row">
                                                                                                            <td className="col-2"></td>
                                                                                                            <td className="col-9">
                                                                                                                <div className="row clearfix mb-2">

                                                                                                                    {/* GOOD PARTS */}
                                                                                                                    <div className="col-auto">
                                                                                                                        <div className="form-floating">
                                                                                                                            <input className="form-control" id="good_parts_produced" placeholder="" value={SelectedPR.good_parts_produced || ""} onChange={(event) => setSelectedPR({ ...SelectedPR, good_parts_produced: event.target.value })} />
                                                                                                                            <label className="text-muted" htmlFor="good_parts_produced">Good Parts</label>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    {/* OPERATORS */}
                                                                                                                    <div className="col-auto">
                                                                                                                        <div className="form-floating">
                                                                                                                            <select 
                                                                                                                                className="form-select" 
                                                                                                                                disabled={false}
                                                                                                                                id="creator_ID"
                                                                                                                                onChange={(event) => setSelectedPR({ ...SelectedPR, creator_ID: event.target.value })}
                                                                                                                                placeholder="Operator"
                                                                                                                                value={SelectedPR.creator_ID}
                                                                                                                            >
                                                                                                                                {Operators.map((o) => {
                                                                                                                                    return <option key={o.user_ID} value={parseInt(o.user_ID)}>{o.first_name} {o.last_name.charAt(0)}</option>
                                                                                                                                })}
                                                                                                                            </select> 
                                                                                                                            <label className="text-muted" htmlFor="creator_ID">Operator</label>                                                   
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    {/* DATE SCHEDULED */}
                                                                                                                    {/* <div className="col-auto">
                                                                                                                        <div className="form-floating">
                                                                                                                            <input type="date" className="form-control" id="date_scheduled" placeholder="" value={SelectedPR.date_scheduled || ""} onChange={(event) => setSelectedPR({ ...SelectedPR, date_scheduled: event.target.value })} />
                                                                                                                            <label className="text-muted" htmlFor="date_scheduled">Shift Date Worked</label>
                                                                                                                        </div>
                                                                                                                    </div> */}
                                                                                                                    {/* SHIFT GROUP */}
                                                                                                                    <div className="col-auto">
                                                                                                                        <div className="form-floating">
                                                                                                                            <select 
                                                                                                                                className="form-select" 
                                                                                                                                disabled={false}
                                                                                                                                id="shift_group"
                                                                                                                                onChange={(event) => setSelectedPR({ ...SelectedPR, shift_group: event.target.value })}
                                                                                                                                placeholder="Shift Group"
                                                                                                                                value={SelectedPR.shift_group}
                                                                                                                            >
                                                                                                                                <option key={1} value={1}>1st Shift</option>
                                                                                                                                <option key={2} value={2}>2nd Shift</option>
                                                                                                                                <option key={3} value={3}>3rd Shift</option>
                                                                                                                            </select> 
                                                                                                                            <label className="text-muted" htmlFor="shift_group">Shift Group</label>                                                   
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                                <div className="">
                                                                                                                    <div className="btn btn-sm bg-white fw-bolder py-0 px-1 border border-3 border-success text-success" onClick={() => handleSavePR()}><FontAwesomeIcon icon="fa-floppy-disk" className="me-2" />Save</div>
                                                                                                                    <div className="btn btn-sm bg-white text-secondary fw-bolder py-0 border border-3 border-secondary float-end" onClick={() => handleClosePRArchiveConfirmation()}><FontAwesomeIcon icon="fa-times" className="me-2" />Close</div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td className="col-1"></td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            } else {
                                                                                return false;
                                                                            }
                                                                        })}

                                                                        {/* Total Part count for Shifts / total */}
                                                                        <tr className="row border-top border-dark mb-3 ">
                                                                            <td className="p-1 col-2 bg-light">Total {p.part_number}</td>
                                                                            <td className="p-1 col-3 bg-light">
                                                                                {temp_part_shift_1_total}
                                                                                {temp_part_shift_1_diff !== 0 && <span className={`badge text-white ms-2 ${(temp_part_shift_1_diff > 0 && 'bg-success') || 'bg-danger'}`}>{temp_part_shift_1_diff}</span>}
                                                                            </td>
                                                                            <td className="p-1 col-3 bg-light">
                                                                                {temp_part_shift_2_total}
                                                                                {temp_part_shift_2_diff !== 0 && <span className={`badge text-white ms-2 ${(temp_part_shift_2_diff > 0 && 'bg-success') || 'bg-danger'}`}>{temp_part_shift_2_diff}</span>}
                                                                            </td>
                                                                            <td className="p-1 col-3 bg-light">
                                                                                {temp_part_shift_3_total}
                                                                                {temp_part_shift_3_diff !== 0 && <span className={`badge text-white ms-2 ${(temp_part_shift_3_diff > 0 && 'bg-success') || 'bg-danger'}`}>{temp_part_shift_3_diff}</span>}
                                                                            </td>
                                                                            <td className="p-1 col-1 bg-info">
                                                                                {temp_total}
                                                                                {temp_part_diff !== 0 && <span className={`badge text-white ms-2 ${(temp_part_diff > 0 && 'bg-success') || 'bg-danger'}`}>{temp_part_diff}</span>}
                                                                            </td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                )
                                                            
                                                            } else {
                                                                return false;
                                                            }
                                                        })}
                                                    </React.Fragment>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </>
                            }
                            {
                                BaseState.FilteredProductionRecords.length === 0 && BaseState.searched &&
                                <div className="alert alert-info m-0">No Production Records matching filters</div>
                            }
                            {
                                BaseState.searched === false &&
                                <div className="alert alert-info m-0">Please click Search with your desired filters to view Production Records</div>
                            }
                        </>
                    }
                    {/* END DAY VIEW */}

                    {/* PART VIEW - Only show table view to Office and Admins */}
                    {
                        parseInt(auth().User.user_type_ID) > 2 && Filters.display_view === 'parts' &&
                        ((
                            BaseState.FilteredPartsMachines.length > 0 && BaseState.FilteredPartsMachines[0].Machines.length > 0 &&
                            <table className="table" style={{fontSize: 12+'px'}}>
                                <thead className="row h6 border border-2 border-top-0 border-start-0 border-end-0 border-dark mb-0">
                                    <tr className="row">
                                        <th className="col-1">
                                            {BaseState.FilteredPartsMachines[0].part_number}<br />
                                            {BaseState.FilteredPartsMachines[0].hourly_standard_rate !== '' && BaseState.FilteredPartsMachines[0].hourly_standard_rate !== null && <span className=""><span className="text-white bg-secondary badge">{BaseState.FilteredPartsMachines[0].hourly_standard_rate} / hr</span></span>}
                                        </th>
                                        {
                                            BaseState.FilteredPartsMachines[0].Machines.map((m) => {
                                                let temp_std = BaseState.FilteredPartsMachines[0].hourly_standard_rate !== '' && BaseState.FilteredPartsMachines[0].hourly_standard_rate !== null ? BaseState.FilteredPartsMachines[0].hourly_standard_rate : 0;
                                                return (
                                                    <th className={`${(BaseState.FilteredPartsMachines[0].Machines.length < 5 && 'col-2') || 'col-1'}`} key={`header-${parseInt(m.machine_ID)}`}>
                                                        {(m.machine_short_name !== '' && m.machine_short_name !== null && m.machine_short_name) || m.machine_name}<br />
                                                        {temp_std !== '' && temp_std !== null && <span className=""><span className="text-white bg-secondary badge">{multiply(temp_std, 8)} / 8hr</span></span>}
                                                    </th>
                                                )
                                            })
                                        }
                                        <th className="col-1 ms-2">
                                            Shift Totals<br />
                                            {BaseState.FilteredPartsMachines[0].hourly_standard_rate !== '' && BaseState.FilteredPartsMachines[0].hourly_standard_rate !== null && <span className=""><span className="text-white bg-secondary badge">{multiply(multiply(BaseState.FilteredPartsMachines[0].hourly_standard_rate, 8), BaseState.FilteredPartsMachines[0].Machines.length)} / Shift</span></span>}
                                        </th>
                                        <th className="col-1 ms-2">Cumulative Totals</th>
                                    </tr>
                                </thead>
                                    <tbody className="">
                                        {/* Loop each PRDate on the Filtered Parts */}
                                        {BaseState.FilteredPartsMachines[0].PRDates.map((pr, index) => {

                                            // Set Part totals for the entire Day
                                            let TempPartCounts = { Shifts: [], Machines: [], Day: { good: 0, scrap: 0, std_diff: 0 }};

                                            // Display each DATE ROW
                                            return (
                                                <React.Fragment key={`${index}`}>

                                                    {/* Loop each Shift per Date */}
                                                    {pr.Shifts.map((p, shift_index) => {
                                                        let temp_shift_std_diff = 0;
                                                        let shift_std = BaseState.FilteredPartsMachines[0].shift_std;
                                                        
                                                        // Display each Shift's production ROW
                                                        return (
                                                            <React.Fragment key={`${index}-${p.shift_group}`}>
                                                                {/* ROW */}
                                                                <tr className={`row ${shift_index === 0 && 'border-top'}`}>

                                                                    {/* Date & Shift Group */}
                                                                    <td className="col-1 p-1 border-end">
                                                                        {shift_index === 0 && <span className="float-start h6 m-0 fw-bolder">{dayjs(pr.date_scheduled).format('dd M-D')}</span>}
                                                                        <span className="float-end">{parseInt(p.shift_group)}</span>
                                                                    </td>

                                                                    {/* Loop each Machine used for this Part */}
                                                                    {p.Machines.map((m, machine_index) => {

                                                                        // Loop all of the Production Records on this Machine to get the total good parts / scrap for just this Machine on this Shift
                                                                        let temp_shift_total = 0;
                                                                        let temp_shift_total_scrap = 0;
                                                                        let temp_std_diff = 0;
                                                                        let TempOperators = [];

                                                                        m.PR.forEach((mpr) => {
                                                                            // DO NOT COUNT THIS PRODUCTION RECORD TOWARDS ANYTHING IF IT IS ARCHIVED
                                                                            if ( parseInt(mpr.archiver_ID) === 0 ) {
                                                                                temp_shift_total = add(temp_shift_total, parseInt(mpr.good_parts_produced));
                                                                                temp_shift_total_scrap = add(temp_shift_total_scrap, parseInt(mpr.scrap_parts_produced));
                                                                                if ( TempOperators.findIndex((o) => o === `${mpr.first_name} ${mpr.last_name.charAt(0)}`) === -1 ) {
                                                                                    TempOperators.push(`${mpr.first_name} ${mpr.last_name.charAt(0)}`);
                                                                                }
                                                                            }
                                                                        });

                                                                        // Calculate difference from Std for 8 hour shift
                                                                        temp_std_diff = BaseState.FilteredPartsMachines[0].shift_rate > 0 ? subtract(temp_shift_total, BaseState.FilteredPartsMachines[0].shift_rate) : 0;
                                                                        temp_shift_std_diff = add(temp_shift_std_diff, temp_std_diff);

                                                                        // Update the TempPartCounts array for this Shift on this Machine
                                                                        if ( machine_index === 0 ) {
                                                                            // On the first Machine, start the count for this shift
                                                                            TempPartCounts.Shifts.push({ good: temp_shift_total, scrap: temp_shift_total_scrap, std_diff: 0 });

                                                                            // On the first Day, start the cumulative count
                                                                            if( index === 0 ) {
                                                                                CumulativePartCounts.Shifts.push({ good: temp_shift_total, scrap: temp_shift_total_scrap, std_diff: temp_shift_std_diff });
                                                                            } else {
                                                                                CumulativePartCounts.Shifts[shift_index].good = add(temp_shift_total, CumulativePartCounts.Shifts[shift_index].good);
                                                                                CumulativePartCounts.Shifts[shift_index].scrap = add(temp_shift_total_scrap, CumulativePartCounts.Shifts[shift_index].scrap);                                                                        
                                                                                CumulativePartCounts.Shifts[shift_index].std_diff = add(temp_std_diff, CumulativePartCounts.Shifts[shift_index].std_diff);                                                                        
                                                                            }
                                                                        } else {
                                                                            // On next Machines loop, add their part counts
                                                                            TempPartCounts.Shifts[shift_index].good = add(temp_shift_total, TempPartCounts.Shifts[shift_index].good);
                                                                            TempPartCounts.Shifts[shift_index].scrap = add(temp_shift_total_scrap, TempPartCounts.Shifts[shift_index].scrap); 

                                                                            // On the first Day, start the cumulative count
                                                                            CumulativePartCounts.Shifts[shift_index].good = add(temp_shift_total, CumulativePartCounts.Shifts[shift_index].good);
                                                                            CumulativePartCounts.Shifts[shift_index].scrap = add(temp_shift_total_scrap, CumulativePartCounts.Shifts[shift_index].scrap);                                                                        
                                                                            CumulativePartCounts.Shifts[shift_index].std_diff = add(temp_std_diff, CumulativePartCounts.Shifts[shift_index].std_diff);                                                                        
                                                                        }

                                                                        // Update the TempPartCounts array for this Machine on this Shift
                                                                        if ( shift_index === 0 ) {
                                                                            // On the first Shift, start the count for this Machine
                                                                            TempPartCounts.Machines.push({ good: temp_shift_total, scrap: temp_shift_total_scrap, std_diff: temp_std_diff });

                                                                        } else {
                                                                            // On next Machines loop, add their part counts
                                                                            TempPartCounts.Machines[machine_index].good = add(temp_shift_total, TempPartCounts.Machines[machine_index].good);
                                                                            TempPartCounts.Machines[machine_index].scrap = add(temp_shift_total_scrap, TempPartCounts.Machines[machine_index].scrap);                                                                        
                                                                            TempPartCounts.Machines[machine_index].std_diff = add(temp_std_diff, TempPartCounts.Machines[machine_index].std_diff); 

                                                                        }

                                                                        return (
                                                                            <td key={`${index}-${p.shift_group}-${m.machine_ID}`} className={`${parseInt(auth().User.user_ID) === 1 && BaseState.FilteredPartsMachines[0].hourly_standard_rate !== '' && BaseState.FilteredPartsMachines[0].hourly_standard_rate !== null && temp_std_diff > multiply(multiply(BaseState.FilteredPartsMachines[0].hourly_standard_rate, 0.3), 8) && 'border border-danger'} ${(BaseState.FilteredPartsMachines[0].Machines.length < 5 && 'col-2') || 'col-1'} p-1`}>
                                                                                <div>
                                                                                    {temp_shift_total}{Filters.display_scrap && temp_shift_total_scrap > 0 && <span className="badge bg-secondary text-white ms-1">{temp_shift_total_scrap}</span>}
                                                                                    {temp_std_diff !== 0 && <span className={`badge text-white ms-1 ${ ( temp_std_diff > 0 && 'bg-success' ) || 'bg-danger'}`}>{temp_std_diff}</span>}
                                                                                </div>
                                                                                {Filters.display_operator && TempOperators.length > 0 && TempOperators.map((o) => <div className="badge text-secondary border border-secondary bg-white">{o}</div>)}
                                                                                {/* {temp_shift_1_total_robot !== 0 && <span className="badge bg-primary text-white ms-2">{temp_shift_1_total_robot}</span>} */}
                                                                            </td>
                                                                        )
                                                                    })}

                                                                    {/* Shift totals across all Machines */}
                                                                    <td className="col-1 p-1 border-start">
                                                                        {TempPartCounts.Shifts[shift_index].good}{Filters.display_scrap && TempPartCounts.Shifts[shift_index].scrap > 0 && <span className="badge bg-secondary text-white ms-1">{TempPartCounts.Shifts[shift_index].scrap}</span>}
                                                                        {
                                                                            shift_std > 0 && temp_shift_std_diff !== 0 &&
                                                                            <span className={`badge text-white ms-1 ${ ( temp_shift_std_diff > 0 && 'bg-success' ) || 'bg-danger'}`}>{temp_shift_std_diff}</span>
                                                                        }
                                                                    </td>
                                                                    
                                                                    {/* Cumulative totals across all Machines */}
                                                                    <td className="col-1 p-1 border-start bg-warning">
                                                                        {CumulativePartCounts.Shifts[shift_index].good}{Filters.display_scrap && CumulativePartCounts.Shifts[shift_index].scrap > 0 && <span className="badge bg-secondary text-white ms-1">{CumulativePartCounts.Shifts[shift_index].scrap}</span>}
                                                                        {
                                                                            shift_std > 0 && CumulativePartCounts.Shifts[shift_index].std_diff !== 0 &&
                                                                            <span className={`badge text-white ms-1 ${ ( CumulativePartCounts.Shifts[shift_index].std_diff > 0 && 'bg-success' ) || 'bg-danger'}`}>{CumulativePartCounts.Shifts[shift_index].std_diff}</span>
                                                                        }
                                                                    </td>
                                                                    
                                                                </tr>

                                                                {/* DATE'S TOTAL PARTS PER MACHINE */}
                                                                {
                                                                    shift_index+1 === pr.Shifts.length && 
                                                                    <>
                                                                        <tr className={`row border-top border-bottom border-secondary ${index === 0 && 'mb-4'}`}>
                                                                            <td className="col-1 p-1 border-end">Machine Totals</td>
                                                                            {p.Machines.map((m, machine_index) => {

                                                                                // Total up the Parts for the Day
                                                                                TempPartCounts.Day.good = add(TempPartCounts.Machines[machine_index].good, TempPartCounts.Day.good);
                                                                                TempPartCounts.Day.scrap = add(TempPartCounts.Machines[machine_index].scrap, TempPartCounts.Day.scrap);
                                                                                TempPartCounts.Day.std_diff = add(TempPartCounts.Machines[machine_index].std_diff, TempPartCounts.Day.std_diff);
                                                                                // Add to Cumulative total Day count
                                                                                CumulativePartCounts.Day.good = add(TempPartCounts.Machines[machine_index].good, CumulativePartCounts.Day.good);
                                                                                CumulativePartCounts.Day.scrap = add(TempPartCounts.Machines[machine_index].scrap, CumulativePartCounts.Day.scrap);
                                                                                CumulativePartCounts.Day.std_diff = add(TempPartCounts.Machines[machine_index].std_diff, CumulativePartCounts.Day.std_diff);

                                                                                // On the first Day, start the cumulative count for the machine
                                                                                if( index === 0 ) {
                                                                                    CumulativePartCounts.Machines.push({ good: TempPartCounts.Machines[machine_index].good, scrap: TempPartCounts.Machines[machine_index].scrap, std_diff: TempPartCounts.Machines[machine_index].std_diff });
                                                                                } else {
                                                                                    CumulativePartCounts.Machines[machine_index].good = add(CumulativePartCounts.Machines[machine_index].good, TempPartCounts.Machines[machine_index].good);
                                                                                    CumulativePartCounts.Machines[machine_index].scrap = add(CumulativePartCounts.Machines[machine_index].scrap, TempPartCounts.Machines[machine_index].scrap);                                                                        
                                                                                    CumulativePartCounts.Machines[machine_index].std_diff = add(CumulativePartCounts.Machines[machine_index].std_diff, TempPartCounts.Machines[machine_index].std_diff);                                                                        
                                                                                }

                                                                                return (
                                                                                    <td key={`PRDate-${index}-${p.shift_group}-${m.machine_ID}`} className={`${(BaseState.FilteredPartsMachines[0].Machines.length < 5 && 'col-2') || 'col-1'} p-1`}>
                                                                                        {TempPartCounts.Machines[machine_index].good}{Filters.display_scrap && TempPartCounts.Machines[machine_index].scrap > 0 && <span className="badge bg-secondary text-white ms-1">{TempPartCounts.Machines[machine_index].scrap}</span>}
                                                                                        {TempPartCounts.Machines[machine_index].std_diff !== 0 && <span className={`badge text-white ms-1 ${ ( TempPartCounts.Machines[machine_index].std_diff > 0 && 'bg-success' ) || 'bg-danger'}`}>{TempPartCounts.Machines[machine_index].std_diff}</span>}
                                                                                    </td>
                                                                                )
                                                                            })}
                                                                            <td className="col-1 p-1 border-start bg-info">
                                                                                {TempPartCounts.Day.good}
                                                                                {Filters.display_scrap && TempPartCounts.Day.scrap > 0 && <span className="badge bg-secondary text-white ms-1">{TempPartCounts.Day.scrap}</span>}
                                                                                {TempPartCounts.Day.std_diff !== 0 && <span className={`badge text-white ms-1 ${ ( TempPartCounts.Day.std_diff > 0 && 'bg-success' ) || 'bg-danger'}`}>{TempPartCounts.Day.std_diff}</span>}
                                                                            </td>
                                                                            <td className="col-1"></td>
                                                                        </tr>
                                                                        { index !== 0 &&
                                                                            <tr className="row border-top border-bottom border-secondary mb-4">
                                                                                <td className="col-1 p-1 border-end bg-warning fw-bolder">Cumulative Totals</td>
                                                                                {p.Machines.map((m, machine_index) => {

                                                                                    return (
                                                                                        <td key={`PRDate-${index}-${p.shift_group}-${m.machine_ID}`} className={`${(BaseState.FilteredPartsMachines[0].Machines.length < 5 && 'col-2') || 'col-1'} bg-warning p-1`}>
                                                                                            {CumulativePartCounts.Machines[machine_index].good}{Filters.display_scrap && CumulativePartCounts.Machines[machine_index].scrap > 0 && <span className="badge bg-secondary text-white ms-1">{CumulativePartCounts.Machines[machine_index].scrap}</span>}
                                                                                            {CumulativePartCounts.Machines[machine_index].std_diff !== 0 && <span className={`badge text-white ms-1 ${ ( CumulativePartCounts.Machines[machine_index].std_diff > 0 && 'bg-success' ) || 'bg-danger'}`}>{CumulativePartCounts.Machines[machine_index].std_diff}</span>}
                                                                                        </td>
                                                                                    )
                                                                                })}
                                                                                <td className="col-1 p-1 border-start bg-warning">
                                                                                    {CumulativePartCounts.Day.good}
                                                                                    {Filters.display_scrap && CumulativePartCounts.Day.scrap > 0 && <span className="badge bg-secondary text-white ms-1">{CumulativePartCounts.Day.scrap}</span>}
                                                                                    {CumulativePartCounts.Day.std_diff !== 0 && <span className={`badge text-white ms-1 ${ ( CumulativePartCounts.Day.std_diff > 0 && 'bg-success' ) || 'bg-danger'}`}>{CumulativePartCounts.Day.std_diff}</span>}
                                                                                </td>
                                                                                <td className="col-1"></td>                                                                            
                                                                            </tr>
                                                                        }
                                                                    </>
                                                                }

                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </React.Fragment>
                                            )
                                        })}
                                    </tbody>
                            </table>
                        ) || 
                        (
                            BaseState.searched === true && 
                            <tbody className="">
                                <tr>
                                    <td>
                                        <div className="alert alert-info m-0">No Production Records matching filters</div>
                                    </td>
                                </tr>
                            </tbody>                  
                        ) || 
                        (
                            BaseState.searched === false &&
                            <tbody className="">
                                <tr>
                                    <td>
                                        <div className="alert alert-info m-0">Please click Search with your desired filters to view Production Records</div>
                                    </td>
                                </tr>
                            </tbody>                    
                        ))
                    }
                    {/* END MONTH VIEW */}
                </div>
            </>
        )
    } 
    
    // LOGGED OUT VIEW
    else {
        return (
            <>
                <div>
                    {/* PART VIEW - Only show table view to Office and Admins */}
                    {
                            BaseState.FilteredPartsMachines.length > 0 && 
                            <>
                                <div className="row justify-content-center">
                                    <div className="alert alert-info text-center col-auto h3 py-1"><span className="fw-bolder">{dayjs(BaseState.FilteredPartsMachines[0].PRDates[0].date_scheduled).format('dddd MMM D')} Production</span></div>
                                </div>
                                {BaseState.FilteredPartsMachines.map((fpm, index) => {
                                    let column_width = divide(window.innerWidth, fpm.Machines.length);
                                    let temp_std = fpm.hourly_standard_rate !== '' && fpm.hourly_standard_rate !== null ? fpm.hourly_standard_rate : 0;
                                    return (
                                        <table key={index} className="table" style={{fontSize: 12+'px'}}>
                                            {/* <thead className="row border border-2 border-top-0 border-start-0 border-end-0 border-dark mb-0">
                                                <tr className="row">
                                                    <th className="col-1 p-1">
                                                        {( fpm.part_number_short !== '' && fpm.part_number_short !== null && fpm.part_number_short ) || fpm.part_number}
                                                        {temp_std !== '' && temp_std !== null && <span className="ms-1"><span className="text-white bg-secondary badge">{multiply(temp_std, 8)} / 8hr</span></span>}
                                                    </th>
                                                    {
                                                        fpm.Machines.map((m) => {
                                                            return (
                                                                <th className="col p-1" style={{width: column_width+'px'}} key={`header-${parseInt(m.machine_ID)}`}>
                                                                    {(m.machine_short_name !== '' && m.machine_short_name !== null && m.machine_short_name) || m.machine_name}
                                                                </th>
                                                            )
                                                        })
                                                    }
                                                    <th className="col-1 p-1">
                                                        Shift Totals<br />
                                                    </th>
                                                </tr>
                                            </thead> */}
                                            <tbody className="">
                                                <tr className="row border border-2 border-top-0 border-start-0 border-end-0 border-dark">
                                                    <th className="col-1 p-1">
                                                        <span className="h5">{( fpm.part_number_short !== '' && fpm.part_number_short !== null && fpm.part_number_short ) || fpm.part_number}</span>
                                                        {temp_std !== '' && temp_std !== null && temp_std !== 0 && <span className="ms-1"><span className="text-white bg-secondary badge">{multiply(temp_std, 8)} / 8hr</span></span>}
                                                    </th>
                                                    {
                                                        fpm.Machines.map((m) => {
                                                            return (
                                                                <th className="col p-1" style={{width: column_width+'px'}} key={`header-${parseInt(m.machine_ID)}`}>
                                                                    {(m.machine_short_name !== '' && m.machine_short_name !== null && m.machine_short_name) || m.machine_name}
                                                                </th>
                                                            )
                                                        })
                                                    }
                                                    <th className="col-1 p-1">
                                                        Shift Totals<br />
                                                        {/* {fpm.hourly_standard_rate !== '' && fpm.hourly_standard_rate !== null && <span className=""><span className="text-white bg-secondary badge">{multiply(multiply(fpm.hourly_standard_rate, 8), fpm.Machines.length)} / Shift</span></span>} */}
                                                    </th>
                                                </tr>
                                                {/* Loop each PRDate on the Filtered Parts */}
                                                {fpm.PRDates.map((pr, index) => {

                                                    // Set Part totals for the entire Day
                                                    let TempPartCounts = { Shifts: [], Machines: [], Day: { good: 0, scrap: 0, std_diff: 0 }};

                                                    // Display each DATE ROW
                                                    return (
                                                        <React.Fragment key={`${index}`}>

                                                            {/* Loop each Shift per Date */}
                                                            {pr.Shifts.map((p, shift_index) => {
                                                                let temp_shift_std_diff = 0;
                                                                let shift_std = fpm.shift_std;
                                                                
                                                                // Display each Shift's production ROW
                                                                return (
                                                                    <React.Fragment key={`${index}-${p.shift_group}`}>
                                                                        {/* ROW */}
                                                                        <tr className={`row ${shift_index === 0 && 'border-top'}`}>

                                                                            {/* Date & Shift Group */}
                                                                            <td className="col-1 p-1">
                                                                                <span className="float-end">Shift {parseInt(p.shift_group)}</span>
                                                                            </td>

                                                                            {/* Loop each Machine used for this Part */}
                                                                            {p.Machines.map((m, machine_index) => {

                                                                                // Loop all of the Production Records on this Machine to get the total good parts / scrap for just this Machine on this Shift
                                                                                let temp_shift_total = 0;
                                                                                let temp_shift_total_scrap = 0;
                                                                                let temp_std_diff = 0;
                                                                                let TempOperators = [];

                                                                                m.PR.forEach((mpr) => {
                                                                                    // DO NOT COUNT THIS PRODUCTION RECORD TOWARDS ANYTHING IF IT IS ARCHIVED
                                                                                    if ( parseInt(mpr.archiver_ID) === 0 ) {
                                                                                        temp_shift_total = add(temp_shift_total, parseInt(mpr.good_parts_produced));
                                                                                        temp_shift_total_scrap = add(temp_shift_total_scrap, parseInt(mpr.scrap_parts_produced));
                                                                                        if ( TempOperators.findIndex((o) => o === `${mpr.first_name} ${mpr.last_name.charAt(0)}`) === -1 ) {
                                                                                            TempOperators.push(`${mpr.first_name} ${mpr.last_name.charAt(0)}`);
                                                                                        }
                                                                                    }
                                                                                });

                                                                                // Calculate difference from Std for 8 hour shift
                                                                                temp_std_diff = fpm.shift_rate > 0 ? subtract(temp_shift_total, fpm.shift_rate) : 0;
                                                                                temp_shift_std_diff = add(temp_shift_std_diff, temp_std_diff);

                                                                                // Update the TempPartCounts array for this Shift on this Machine
                                                                                if ( machine_index === 0 ) {
                                                                                    // On the first Machine, start the count for this shift
                                                                                    TempPartCounts.Shifts.push({ good: temp_shift_total, scrap: temp_shift_total_scrap, std_diff: 0 });

                                                                                    // On the first Day, start the cumulative count
                                                                                    if( index === 0 ) {
                                                                                        CumulativePartCounts.Shifts.push({ good: temp_shift_total, scrap: temp_shift_total_scrap, std_diff: temp_shift_std_diff });
                                                                                    } else {
                                                                                        CumulativePartCounts.Shifts[shift_index].good = add(temp_shift_total, CumulativePartCounts.Shifts[shift_index].good);
                                                                                        CumulativePartCounts.Shifts[shift_index].scrap = add(temp_shift_total_scrap, CumulativePartCounts.Shifts[shift_index].scrap);                                                                        
                                                                                        CumulativePartCounts.Shifts[shift_index].std_diff = add(temp_std_diff, CumulativePartCounts.Shifts[shift_index].std_diff);                                                                        
                                                                                    }
                                                                                } else {
                                                                                    // On next Machines loop, add their part counts
                                                                                    TempPartCounts.Shifts[shift_index].good = add(temp_shift_total, TempPartCounts.Shifts[shift_index].good);
                                                                                    TempPartCounts.Shifts[shift_index].scrap = add(temp_shift_total_scrap, TempPartCounts.Shifts[shift_index].scrap); 

                                                                                    // On the first Day, start the cumulative count
                                                                                    CumulativePartCounts.Shifts[shift_index].good = add(temp_shift_total, CumulativePartCounts.Shifts[shift_index].good);
                                                                                    CumulativePartCounts.Shifts[shift_index].scrap = add(temp_shift_total_scrap, CumulativePartCounts.Shifts[shift_index].scrap);                                                                        
                                                                                    CumulativePartCounts.Shifts[shift_index].std_diff = add(temp_std_diff, CumulativePartCounts.Shifts[shift_index].std_diff);                                                                        
                                                                                }

                                                                                // Update the TempPartCounts array for this Machine on this Shift
                                                                                if ( shift_index === 0 ) {
                                                                                    // On the first Shift, start the count for this Machine
                                                                                    TempPartCounts.Machines.push({ good: temp_shift_total, scrap: temp_shift_total_scrap, std_diff: temp_std_diff });

                                                                                } else {
                                                                                    // On next Machines loop, add their part counts
                                                                                    TempPartCounts.Machines[machine_index].good = add(temp_shift_total, TempPartCounts.Machines[machine_index].good);
                                                                                    TempPartCounts.Machines[machine_index].scrap = add(temp_shift_total_scrap, TempPartCounts.Machines[machine_index].scrap);                                                                        
                                                                                    TempPartCounts.Machines[machine_index].std_diff = add(temp_std_diff, TempPartCounts.Machines[machine_index].std_diff); 

                                                                                }

                                                                                return (
                                                                                    <td key={`${index}-${p.shift_group}-${m.machine_ID}`} className="col p-1 border-start" style={{width: column_width+'px'}}>
                                                                                        <span>
                                                                                            {temp_shift_total}{Filters.display_scrap && temp_shift_total_scrap > 0 && <span className="badge bg-secondary text-white ms-1">{temp_shift_total_scrap}</span>}
                                                                                            {temp_std_diff !== 0 && <span className={`badge text-white ms-1 ${ ( temp_std_diff > 0 && 'bg-success' ) || 'bg-danger'}`}>{temp_std_diff}</span>}
                                                                                        </span>
                                                                                        {Filters.display_operator && TempOperators.length > 0 && TempOperators.map((o) => <span className="badge text-secondary border border-secondary bg-white ms-1">{o}</span>)}
                                                                                        {/* {temp_shift_1_total_robot !== 0 && <span className="badge bg-primary text-white ms-2">{temp_shift_1_total_robot}</span>} */}
                                                                                    </td>
                                                                                )
                                                                            })}

                                                                            {/* Shift totals across all Machines */}
                                                                            <td className="col-1 p-1 border-start">
                                                                                {TempPartCounts.Shifts[shift_index].good}{Filters.display_scrap && TempPartCounts.Shifts[shift_index].scrap > 0 && <span className="badge bg-secondary text-white ms-1">{TempPartCounts.Shifts[shift_index].scrap}</span>}
                                                                                {
                                                                                    shift_std > 0 && temp_shift_std_diff !== 0 &&
                                                                                    <span className={`badge text-white ms-1 ${ ( temp_shift_std_diff > 0 && 'bg-success' ) || 'bg-danger'}`}>{temp_shift_std_diff}</span>
                                                                                }
                                                                            </td>
                                                                            
                                                                        </tr>

                                                                        {/* DATE'S TOTAL PARTS PER MACHINE */}
                                                                        {
                                                                            shift_index+1 === pr.Shifts.length && 
                                                                            <>
                                                                                <tr className={`row border-top border-bottom border-secondary ${index === 0 && 'mb-2'}`}>
                                                                                    <td className="col-1 p-1 float-end">Totals</td>
                                                                                    {p.Machines.map((m, machine_index) => {

                                                                                        // Total up the Parts for the Day
                                                                                        TempPartCounts.Day.good = add(TempPartCounts.Machines[machine_index].good, TempPartCounts.Day.good);
                                                                                        TempPartCounts.Day.scrap = add(TempPartCounts.Machines[machine_index].scrap, TempPartCounts.Day.scrap);
                                                                                        TempPartCounts.Day.std_diff = add(TempPartCounts.Machines[machine_index].std_diff, TempPartCounts.Day.std_diff);
                                                                                        // Add to Cumulative total Day count
                                                                                        CumulativePartCounts.Day.good = add(TempPartCounts.Machines[machine_index].good, CumulativePartCounts.Day.good);
                                                                                        CumulativePartCounts.Day.scrap = add(TempPartCounts.Machines[machine_index].scrap, CumulativePartCounts.Day.scrap);
                                                                                        CumulativePartCounts.Day.std_diff = add(TempPartCounts.Machines[machine_index].std_diff, CumulativePartCounts.Day.std_diff);

                                                                                        // On the first Day, start the cumulative count for the machine
                                                                                        if( index === 0 ) {
                                                                                            CumulativePartCounts.Machines.push({ good: TempPartCounts.Machines[machine_index].good, scrap: TempPartCounts.Machines[machine_index].scrap, std_diff: TempPartCounts.Machines[machine_index].std_diff });
                                                                                        } else {
                                                                                            CumulativePartCounts.Machines[machine_index].good = add(CumulativePartCounts.Machines[machine_index].good, TempPartCounts.Machines[machine_index].good);
                                                                                            CumulativePartCounts.Machines[machine_index].scrap = add(CumulativePartCounts.Machines[machine_index].scrap, TempPartCounts.Machines[machine_index].scrap);                                                                        
                                                                                            CumulativePartCounts.Machines[machine_index].std_diff = add(CumulativePartCounts.Machines[machine_index].std_diff, TempPartCounts.Machines[machine_index].std_diff);                                                                        
                                                                                        }

                                                                                        return (
                                                                                            <td key={`PRDate-${index}-${p.shift_group}-${m.machine_ID}`} className="col p-1 border-start" style={{width: column_width+'px'}}>
                                                                                                {TempPartCounts.Machines[machine_index].good}{Filters.display_scrap && TempPartCounts.Machines[machine_index].scrap > 0 && <span className="badge bg-secondary text-white ms-1">{TempPartCounts.Machines[machine_index].scrap}</span>}
                                                                                                {TempPartCounts.Machines[machine_index].std_diff !== 0 && <span className={`badge text-white ms-1 ${ ( TempPartCounts.Machines[machine_index].std_diff > 0 && 'bg-success' ) || 'bg-danger'}`}>{TempPartCounts.Machines[machine_index].std_diff}</span>}
                                                                                            </td>
                                                                                        )
                                                                                    })}
                                                                                    <td className="col-1 p-1 border-start bg-info">
                                                                                        {TempPartCounts.Day.good}
                                                                                        {Filters.display_scrap && TempPartCounts.Day.scrap > 0 && <span className="badge bg-secondary text-white ms-1">{TempPartCounts.Day.scrap}</span>}
                                                                                        {TempPartCounts.Day.std_diff !== 0 && <span className={`badge text-white ms-1 ${ ( TempPartCounts.Day.std_diff > 0 && 'bg-success' ) || 'bg-danger'}`}>{TempPartCounts.Day.std_diff}</span>}
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        }

                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    )
                                })}
                            </>
                    }
                    {/* END MONTH VIEW */}
                </div>
            </>
        )
    }
}

export const ProductionRecordsReportComponent = compose(
	queryWithLoading({
		gqlString: GetProductionRecordsReportPage,
        name: "componentData",
        variablesFunction: () => ({ active: 1 })
	})
)(ProductionRecordsReportContent)
