import { gql } from '@apollo/client';

export const GetMaterials = gql`
    query GetMaterials {
        materials {
            material_ID,
            archiver_ID,
            cost_per_piece,
            cost_per_pound,
            creator_ID,
            date_archived,
            date_created,
            description,
            material_type,
            material_type_ID,
            part_number,
            parts_per,
            supplier_ID,
            supplier_name,
            weight
        }
    }
`;

export const GetMaterialsPage = gql`
    query GetMaterialsPage {
        materials {
            material_ID,
            archiver_ID,
            cost_per_piece,
            cost_per_pound,
            creator_ID,
            date_archived,
            date_created,
            description,
            material_type,
            material_type_ID,
            part_number,
            parts_per,
            supplier_ID,
            supplier_name,
            weight
        }
        suppliers {
            supplier_ID,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            supplier_name
        }
    }
`;

export const SaveMaterial = gql`
	mutation materialSave($input: MaterialInput) {
		materialSave (input: $input) {
            UpdatedMaterial {
                material_ID,
                archiver_ID,
                cost_per_piece,
                cost_per_pound,
                creator_ID,
                date_archived,
                date_created,
                description,
                material_type,
                material_type_ID,
                part_number,
                parts_per,
                supplier_ID,
                supplier_name,
                weight
            }
		}
	}
`;

export const SaveMaterialBOM = gql`
	mutation materialBOMSave($input: MaterialBOMInput) {
		materialBOMSave (input: $input) {
            UpdatedMaterialBOM {
                material_BOM_ID,
                archiver_ID,
                creator_ID,
                date_archived,
                date_created,
                description,
                material_ID,
                material_type,
                part_ID,
                part_number,
                requirement_per_piece,
                supplier_name
            }
		}
	}
`;

