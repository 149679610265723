import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import {flowRight as compose} from 'lodash';
import { queryWithLoading } from '../utilities/NSDb.js';
import { useState } from 'react';
// import dayjs from 'dayjs';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
import { useMutation } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// GRAPHQL QUERY
import { 
    GetShifts,
    GetScheduling,
    SaveShift
} from '../shifts/shifts_graphql.js';

function SchedulingContent(props) {

    const [save_shift] = useMutation(SaveShift);

    const DefaultSelectedShift = {
        shift_ID: 0,
        active: 1,
        archiver_ID: 0,
        creator_ID: 0,
        date_archived: '',
        date_created: '',
        break_end_1: '',
        break_end_1_hour: '00',
        break_end_1_minute: '00',
        break_end_1_am_pm: 'AM',
        break_end_2: '',
        break_end_2_hour: '00',
        break_end_2_minute: '00',
        break_end_2_am_pm: 'AM',
        break_start_1: '',
        break_start_1_hour: '00',
        break_start_1_minute: '00',
        break_start_1_am_pm: 'AM',
        break_start_2: '',
        break_start_2_hour: '00',
        break_start_2_minute: '00',
        break_start_2_am_pm: 'AM',
        lunch_end: '',
        lunch_end_hour: '00',
        lunch_end_minute: '00',
        lunch_end_am_pm: 'AM',
        lunch_start: '',
        lunch_start_hour: '00',
        lunch_start_minute: '00',
        lunch_start_am_pm: 'AM',
        lunch_paid: 0,
        note: '',
        shift_end: '',
        shift_end_hour: '00',
        shift_end_minute: '00',
        shift_end_am_pm: 'AM',
        shift_group: 0,
        shift_hours_worked: '',
        shift_name: '',
        shift_start: '',
        shift_start_hour: '00',
        shift_start_minute: '00',
        shift_start_am_pm: 'AM'
    };

    const [SelectedShift, setSelectedShift] = useState({ ...DefaultSelectedShift });
    const [BaseState, setBaseState] = useState({
        action: '',
        Shifts: props.componentData.shifts
    });

    // Archive / Unarchive the shift
    // archiveShift = (shift_ID, archiver_ID=0) => {
    //     let new_archiver_ID = archiver_ID > 0 ? 0 : 1;
    //     saveShift(shift_ID, new_archiver_ID, 'archive');
    // }

    // Save shift details
    async function saveShift(shift_ID) {

        let SaveData = {...SelectedShift};

        // Parse string values that are IDs to be integers, saving stringified numbers to the database throws an error.
        let MakeIntegers = ['active', 'lunch_paid', 'shift_group', 'shift_ID'];
        MakeIntegers.forEach((mi) => SaveData[mi] = parseInt(SaveData[mi]));

        // Saving each shift part's hour, minute, AM/PM - needs to convert these into a single time variale
        let ShiftParts = ['break_end_1', 'break_end_2', 'break_start_1', 'break_start_2', 'lunch_end', 'lunch_start', 'shift_end', 'shift_start'];
        ShiftParts.forEach((sp) => {
            // Create date time string to give to moment() to parse into a valid time
            SaveData[sp] = moment('01-01-2000 ' + SaveData[`${sp}_hour`]+':'+SaveData[`${sp}_minute`]+':00 '+SaveData[`${sp}_am_pm`]).format('HH:mm:ss');
            // Remove the temp keys for hour, minute, am/pm from the SaveData object so it doesn't send those to the db
            SaveData = {..._.omit(SaveData, [`${sp}_hour`, `${sp}_minute`, `${sp}_am_pm`])};
        });

        // Remove save mesasge and button text from SelectedShift object for saving
        SaveData = _.omit(SaveData, ['save_button_text','save_message','']);
        const result = await save_shift({ variables: { input: { ...SaveData } }});

        // Update the BaseState.Shifts - save returns an array of all Shift objects
        if(result.data && result.data.shiftSave.Shifts) {

            let TempShifts = [ ...BaseState.Shifts ];
            let temp_action = '';

            // If this was a create, update the SelectedShift object with the new shift_ID
            if(parseInt(shift_ID) === 0) {

                // Update the button and save message to show for created success
                setSelectedShift({
                    ...SelectedShift,
                    shift_ID: parseInt(result.data.shiftSave.Shifts[0].shift_ID),
                    save_button_text: 'Saved',
                    save_message: 'Shift successfully added'
                });

                // Push the new Shift object to the front of the Shifts array
                TempShifts.unshift(result.data.shiftSave.Shifts[0]);
                temp_action = 'added_shift';

            } else {
            
                // For updates, replace the entire array of shifts
                TempShifts = result.data.shiftSave.Shifts;
                temp_action = 'updated_shift';

                // Update the SelectedShift with the new shift_ID that was created
                setSelectedShift({
                    ...SelectedShift,
                    save_button_text: 'Saved',
                    save_message: 'Shift successfully updated'
                });

            }

            // For insert and update, set the action and new Shifts
            setBaseState({
                ...BaseState,
                action: temp_action,
                Shifts: TempShifts
            });
        }
    }

    // Handler function for form's changing inputs
    function changeInputValue(name, value) {
        setSelectedShift({
            ...SelectedShift, 
            [name]: value,
            save_button_text: 'Save', 
            save_message: ''
        });

    }

    // Handler function for all non-save buttons - Selected will always be the SelectedShift object of whatever is being clicked. 'Add' is the defaultSelectedShift object.
    function handleButtonClick(action, Selected) {
        let TempSelected = action === 'close_shift' ? { ...Selected } : { ...Selected, save_button_text: 'Save', save_message: ''};
        // console.log("Temp: ", TempSelected.break_end_1)
        // console.log("moment hour: ", moment(TempSelected.break_end_1, 'hh:mm:ss').format('A'));
        // console.log("moment min: ", moment(TempSelected.break_end_1, 'hh:mm:ss').format('mm'));
        if ( action === 'edit_shift' ) {
            TempSelected = { ...TempSelected,
                break_end_1_hour: moment(TempSelected.break_end_1, 'hh:mm:ss').format('hh'),
                break_end_1_minute: moment(TempSelected.break_end_1, 'hh:mm:ss').format('mm'),
                break_end_1_am_pm: moment(TempSelected.break_end_1, 'hh:mm:ss').format('A'),
                break_end_2_hour: moment(TempSelected.break_end_2, 'hh:mm:ss').format('hh'),
                break_end_2_minute: moment(TempSelected.break_end_2, 'hh:mm:ss').format('mm'),
                break_end_2_am_pm: moment(TempSelected.break_end_2, 'hh:mm:ss').format('A'),
                break_start_1_hour: moment(TempSelected.break_start_1, 'hh:mm:ss').format('hh'),
                break_start_1_minute: moment(TempSelected.break_start_1, 'hh:mm:ss').format('mm'),
                break_start_1_am_pm: moment(TempSelected.break_start_1, 'hh:mm:ss').format('A'),
                break_start_2_hour: moment(TempSelected.break_start_2, 'hh:mm:ss').format('hh'),
                break_start_2_minute: moment(TempSelected.break_start_2, 'hh:mm:ss').format('mm'),
                break_start_2_am_pm: moment(TempSelected.break_start_2, 'hh:mm:ss').format('A'),
                lunch_end_hour: moment(TempSelected.lunch_end, 'hh:mm:ss').format('hh'),
                lunch_end_minute: moment(TempSelected.lunch_end, 'hh:mm:ss').format('mm'),
                lunch_end_am_pm: moment(TempSelected.lunch_end, 'hh:mm:ss').format('A'),
                lunch_start_hour: moment(TempSelected.lunch_start, 'hh:mm:ss').format('hh'),
                lunch_start_minute: moment(TempSelected.lunch_start, 'hh:mm:ss').format('mm'),
                lunch_start_am_pm: moment(TempSelected.lunch_start, 'hh:mm:ss').format('A'),
                shift_end_hour: moment(TempSelected.shift_end, 'hh:mm:ss').format('hh'),
                shift_end_minute: moment(TempSelected.shift_end, 'hh:mm:ss').format('mm'),
                shift_end_am_pm: moment(TempSelected.shift_end, 'hh:mm:ss').format('A'),
                shift_start_hour: moment(TempSelected.shift_start, 'hh:mm:ss').format('hh'),
                shift_start_minute: moment(TempSelected.shift_start, 'hh:mm:ss').format('mm'),
                shift_start_am_pm: moment(TempSelected.shift_start, 'hh:mm:ss').format('A')
            }
        }

        setSelectedShift({
            ...TempSelected
        });

        setBaseState({
            ...BaseState,
            action: action
        })
    }

    function TimeInput(name) {
        return (
            <div className="input-group">
                <select 
                    className="form-select" 
                    disabled={false}
                    onChange={(event) => changeInputValue(`${name}_hour`, event.target.value)}
                    value={SelectedShift[`${name}_hour`]}
                >
                    <option value="00">00</option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                </select> 
                <select 
                    className="form-select" 
                    disabled={false}
                    onChange={(event) => changeInputValue(`${name}_minute`, event.target.value)}
                    value={SelectedShift[`${name}_minute`]}
                >
                    <option value="00">00</option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                    <option value="32">32</option>
                    <option value="33">33</option>
                    <option value="34">34</option>
                    <option value="35">35</option>
                    <option value="36">36</option>
                    <option value="37">37</option>
                    <option value="38">38</option>
                    <option value="39">39</option>
                    <option value="40">40</option>
                    <option value="41">41</option>
                    <option value="42">42</option>
                    <option value="43">43</option>
                    <option value="44">44</option>
                    <option value="45">45</option>
                    <option value="46">46</option>
                    <option value="47">47</option>
                    <option value="48">48</option>
                    <option value="49">49</option>
                    <option value="50">50</option>
                    <option value="51">51</option>
                    <option value="52">52</option>
                    <option value="53">53</option>
                    <option value="54">54</option>
                    <option value="55">55</option>
                    <option value="56">56</option>
                    <option value="57">57</option>
                    <option value="58">58</option>
                    <option value="59">59</option>
                </select>
                <select 
                    className="form-select" 
                    disabled={false}
                    onChange={(event) => changeInputValue(`${name}_am_pm`, event.target.value)}
                    value={SelectedShift[`${name}_am_pm`]}
                >
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                </select>
            </div>
        )
    }
    console.log({SelectedShift})
    console.log(BaseState)
    return (
        <>
            <div>
                <div className="row clearfix mb-3">
                    {/* RESULTS COUNTER AND FILTERS */}
                    <div className="col h4 mb-0 pt-3">
                        {BaseState.Shifts.length} Shifts
                    </div>

                    <div className="col float-end">
                        {
                            BaseState.action === 'add_shift' &&
                            <div className="">
                                <span className="col-auto float-end ms-2">
                                    <button 
                                        className="col-auto btn border-primary border-3 text-primary fw-bold disabled" 
                                        disabled={true}
                                    >
                                    Enter New Shift Info Below
                                    </button>
                                </span>
                            </div>
                        }
                        {
                            ((SelectedShift.shift_ID === 0 && BaseState.action !== 'add_shift') || SelectedShift.shift_ID > 0) &&
                            <div className="">
                                <button 
                                    className="btn fw-bold col-auto border-primary border-3 float-end text-primary" 
                                    onClick={() => handleButtonClick('add_shift', DefaultSelectedShift)}
                                >
                                    <FontAwesomeIcon icon="fa-plus" className="me-2" />Add Shift
                                </button>
                                {
                                    BaseState.action === 'added_shift' &&
                                    <span className="col-auto float-end me-2">
                                        <div className="alert alert-success p-2">Shift added successfully</div>
                                    </span>
                                }
                            </div>
                        }
                    </div>
                </div>
                {
                    <table className="table">
                        <thead className="row h6 border border-2 border-top-0 border-start-0 border-end-0 border-dark">
                            <tr className="col-1">Edit</tr>
                            <tr className="col-3">Name</tr>
                            <tr className="col-2">Shift</tr>
                            <tr className="col-2">Break 1</tr>
                            <tr className="col-2">Lunch</tr>
                            <tr className="col-2">Break 2</tr>
                        </thead>
                        {
                            (BaseState.Shifts.length > 0 || BaseState.action === 'add_shift') &&
                            <tbody className="">

                                {/* Add new shift form */}
                                {BaseState.action === 'add_shift' &&
                                    <tr className="row" key="0">
                                        <td className="border-0 col-1">
                                        </td>
                                        <td className="border-0 col-3">
                                            <div className="form-floating">
                                                <input className="form-control float" id="shift_name" value={SelectedShift.shift_name} onChange={(event) => changeInputValue('shift_name', event.target.value)} /> 
                                                <label className="text-muted" htmlFor="shift_name">Shift Name</label>
                                            </div>
                                            <div className="clearfix mt-3 d-flex align-items-center ">
                                                <div className="form-floating float-start">
                                                    <select 
                                                        className="form-select" 
                                                        disabled={false}
                                                        id="shift_group"
                                                        onChange={(event) => changeInputValue('shift_group', event.target.value)}
                                                        placeholder="Shift Group"
                                                        value={SelectedShift.shift_group}
                                                    >
                                                        <option value={0}>Choose Option</option>
                                                        <option value="1">Shift 1</option>
                                                        <option value="2">Shift 2</option>
                                                        <option value="3">Shift 3</option>
                                                    </select> 
                                                    <label className="text-muted" htmlFor="shift_group">Shift Group</label>                                                   
                                                </div>
                                                <div className="form-floating float-start ms-4">
                                                    <select 
                                                        className="form-select" 
                                                        disabled={false}
                                                        id="lunch_paid"
                                                        onChange={(event) => changeInputValue('lunch_paid', event.target.value)}
                                                        placeholder="Lunch Paid"
                                                        value={SelectedShift.lunch_paid}
                                                    >
                                                        <option value={0}>Lunch Not Paid</option>
                                                        <option value={1}>Lunch Paid</option>
                                                    </select> 
                                                    <label className="text-muted" htmlFor="lunch_paid">Lunch Paid</label>                                                   
                                                </div>
                                                <div className="form-floating float-start ms-4">
                                                    <select 
                                                        className="form-select" 
                                                        disabled={false}
                                                        id="active"
                                                        onChange={(event) => changeInputValue('active', event.target.value)}
                                                        placeholder="Active"
                                                        value={SelectedShift.active}
                                                    >
                                                        <option value={1}>Shift Active</option>
                                                        <option value={0}>Shift Inactive</option>
                                                    </select> 
                                                    <label className="text-muted" htmlFor="active">Active</label>                                                   
                                                </div>
                                            </div>
                                        </td>
                                        <td className="border-0 pt-1 col-2">
                                            <div className="mb-2">
                                                <label className="text-muted" htmlFor="shift_start_hour">Shift Start</label>
                                                {TimeInput("shift_start")}
                                            </div>
                                            <div className="">
                                                <label className="text-muted" htmlFor="shift_end_hour">Shift End</label>
                                                {TimeInput("shift_end")}
                                            </div>
                                        </td>
                                        <td className="border-0 pt-1 col-2">
                                            <div className="mb-2">
                                                <label className="text-muted" htmlFor="break_start_1">Break 1 Start</label>
                                                {TimeInput("break_start_1")}
                                            </div>
                                            <div className="">
                                                <label className="text-muted" htmlFor="break_1_end">Break 1 End</label>
                                                {TimeInput("break_end_1")}
                                            </div>
                                        </td>
                                        <td className="border-0 pt-1 col-2">
                                            <div className="mb-2">
                                                <label className="text-muted" htmlFor="lunch_start">Lunch Start</label>
                                                {TimeInput("lunch_start")}
                                            </div>
                                            <div className="">
                                                <label className="text-muted" htmlFor="lunch_end">Lunch End</label>
                                                {TimeInput("lunch_end")}
                                            </div>
                                        </td>
                                        <td className="border-0 pt-1 col-2">
                                            <div className="mb-2">
                                                <label className="text-muted" htmlFor="break_start_2">Break 2 Start</label>
                                                {TimeInput("break_start_2")}
                                            </div>
                                            <div className="">
                                                <label className="text-muted" htmlFor="break_2_end">Break 2 End</label>
                                                {TimeInput("break_end_2")}
                                            </div>
                                        </td>

                                        {/* Second Form row - Shift Group and Note */}
                                        <td className="border-0 col-1" />
                                        <td className="border-0 col-11">
                                            <div className="form-floating">
                                                <input className="form-control float" id="note" value={SelectedShift.note} onChange={(event) => changeInputValue('note', event.target.value)} /> 
                                                <label className="text-muted" htmlFor="note">Note</label>
                                            </div>
                                        </td>
                                        
                                        {/* Buttons Row */}
                                        <td className="border-0 col-1" />
                                        <td className="border-0 col-11 pt-0">
                                            <div className="row">
                                                <span className="col-auto float-start">
                                                    <button className="btn fw-bold col-auto border-success border-3 text-success" disabled={SelectedShift.shift_name === ''} onClick={() => saveShift(0)}>{SelectedShift.save_button_text} {SelectedShift.save_button_text === 'Saved' && <FontAwesomeIcon icon="fa-check" className="ms-1" />}</button>
                                                </span>
                                                {
                                                    SelectedShift.save_message !== '' &&
                                                    <span className="col-auto float-start">
                                                        <div className="alert alert-success p-2 mb-0">{SelectedShift.save_message}</div>
                                                    </span>
                                                }
                                                <span className="col float-end">
                                                    <button className="btn fw-bold col-auto border-secondary border-3 text-secondary float-end" onClick={() => handleButtonClick('', DefaultSelectedShift)}>{(parseInt(SelectedShift.shift_ID) > 0 && 'Close') || 'Cancel'}</button>
                                                </span>
                                            </div>
                                        </td>

                                    </tr>
                                }
                                {BaseState.Shifts.map((m) => {
                                    // let archive_button_class = m.archiver_ID > 0 ? 'btn-success' : 'btn-danger';
                                    // let archive_button_text = m.archiver_ID > 0 ? 'Unarchive' : 'Archive';
                                    if(parseInt(m.shift_ID) === parseInt(SelectedShift.shift_ID)) {
                                        // Disable the save button until an input has changed.
                                        // let {part_number, hourly_standard_rate, sale_price, standard_tool_cost, scrap_percentage_cost_sheet} = SelectedShift;
                                        // let inputs_not_changed = part_number === m.part_number && hourly_standard_rate === m.hourly_standard_rate && sale_price === m.sale_price && standard_tool_cost === m.standard_tool_cost && scrap_percentage_cost_sheet === m.scrap_percentage_cost_sheet;
                                        // let disable_save_button = inputs_not_changed || SelectedShift.save_button_text === 'Saving' || SelectedShift.save_button_text === 'Saved';
                                        // let disable_save_button = SelectedShift.save_button_text === 'Saving' || SelectedShift.save_button_text === 'Saved';
                                        // If adding a new shift and the part_number is blank, do not allow saving
                                        // if(save_button_tip === '' && SelectedShift.part_number === '') {
                                        //     disable_save_button = true;
                                        //     save_button_tip = 'You must enter a shift number'
                                        // }
                                        return (
                                            <tr className="row" key="0">
                                                <td className="border-0 col-1">
                                                </td>
                                                <td className="border-0 col-3">
                                                    <div className="form-floating">
                                                        <input className="form-control float" id="shift_name" value={SelectedShift.shift_name} onChange={(event) => changeInputValue('shift_name', event.target.value)} /> 
                                                        <label className="text-muted" htmlFor="shift_name">Shift Name</label>
                                                    </div>
                                                    <div className="clearfix mt-3 d-flex align-items-center ">
                                                        <div className="form-floating float-start">
                                                            <select 
                                                                className="form-select" 
                                                                disabled={false}
                                                                id="shift_group"
                                                                onChange={(event) => changeInputValue('shift_group', event.target.value)}
                                                                placeholder="Shift Group"
                                                                value={SelectedShift.shift_group}
                                                            >
                                                                <option value={0}>Choose Option</option>
                                                                <option value="1">Shift 1</option>
                                                                <option value="2">Shift 2</option>
                                                                <option value="3">Shift 3</option>
                                                            </select> 
                                                            <label className="text-muted" htmlFor="shift_group">Shift Group</label>                                                   
                                                        </div>
                                                        <div className="form-floating float-start ms-4">
                                                            <select 
                                                                className="form-select" 
                                                                disabled={false}
                                                                id="lunch_paid"
                                                                onChange={(event) => changeInputValue('lunch_paid', event.target.value)}
                                                                placeholder="Lunch Paid"
                                                                value={SelectedShift.lunch_paid}
                                                            >
                                                                <option value={0}>Lunch Not Paid</option>
                                                                <option value={1}>Lunch Paid</option>
                                                            </select> 
                                                            <label className="text-muted" htmlFor="lunch_paid">Lunch Paid</label>                                                   
                                                        </div>
                                                        <div className="form-floating float-start ms-4">
                                                            <select 
                                                                className="form-select" 
                                                                disabled={false}
                                                                id="active"
                                                                onChange={(event) => changeInputValue('active', event.target.value)}
                                                                placeholder="Active"
                                                                value={SelectedShift.active}
                                                            >
                                                                <option value={1}>Shift Active</option>
                                                                <option value={0}>Shift Inactive</option>
                                                            </select> 
                                                            <label className="text-muted" htmlFor="active">Active</label>                                                   
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="border-0 pt-1 col-2">
                                                    <div className="mb-2">
                                                        <label className="text-muted" htmlFor="shift_start_hour">Shift Start</label>
                                                        {TimeInput("shift_start")}
                                                    </div>
                                                    <div className="">
                                                        <label className="text-muted" htmlFor="shift_end_hour">Shift End</label>
                                                        {TimeInput("shift_end")}
                                                    </div>
                                                </td>
                                                <td className="border-0 pt-1 col-2">
                                                    <div className="mb-2">
                                                        <label className="text-muted" htmlFor="break_start_1">Break 1 Start</label>
                                                        {TimeInput("break_start_1")}
                                                    </div>
                                                    <div className="">
                                                        <label className="text-muted" htmlFor="break_1_end">Break 1 End</label>
                                                        {TimeInput("break_end_1")}
                                                    </div>
                                                </td>
                                                <td className="border-0 pt-1 col-2">
                                                    <div className="mb-2">
                                                        <label className="text-muted" htmlFor="lunch_start">Lunch Start</label>
                                                        {TimeInput("lunch_start")}
                                                    </div>
                                                    <div className="">
                                                        <label className="text-muted" htmlFor="lunch_end">Lunch End</label>
                                                        {TimeInput("lunch_end")}
                                                    </div>
                                                </td>
                                                <td className="border-0 pt-1 col-2">
                                                    <div className="mb-2">
                                                        <label className="text-muted" htmlFor="break_start_2">Break 2 Start</label>
                                                        {TimeInput("break_start_2")}
                                                    </div>
                                                    <div className="">
                                                        <label className="text-muted" htmlFor="break_2_end">Break 2 End</label>
                                                        {TimeInput("break_end_2")}
                                                    </div>
                                                </td>

                                                {/* Second Form row - Shift Group and Note */}
                                                <td className="border-0 col-1" />
                                                <td className="border-0 col-11">
                                                    <div className="form-floating">
                                                        <input className="form-control float" id="note" value={SelectedShift.note} onChange={(event) => changeInputValue('note', event.target.value)} /> 
                                                        <label className="text-muted" htmlFor="note">Note</label>
                                                    </div>
                                                </td>
                                                
                                                {/* Buttons Row */}
                                                <td className="border-0 col-1" />
                                                <td className="border-0 col-11">
                                                    <div className="row">
                                                        <span className="col-auto float-start">
                                                            <button className="btn fw-bold col-auto border-success border-3 text-success" disabled={SelectedShift.shift_name === ''} onClick={() => saveShift(parseInt(SelectedShift.shift_ID))}>{SelectedShift.save_button_text} {SelectedShift.save_button_text === 'Saved' && <FontAwesomeIcon icon="fa-check" className="ms-1" />}</button>
                                                        </span>
                                                        {
                                                            SelectedShift.save_message !== '' &&
                                                            <span className="col-auto float-start">
                                                                <div className="alert alert-success p-2 mb-0">{SelectedShift.save_message}</div>
                                                            </span>
                                                        }
                                                        <span className="col float-end">
                                                            <button className="btn fw-bold col-auto border-secondary border-3 text-secondary float-end" onClick={() => handleButtonClick('', DefaultSelectedShift)}>{(parseInt(SelectedShift.shift_ID) > 0 && 'Close') || 'Cancel'}</button>
                                                        </span>
                                                    </div>
                                                </td>

                                            </tr>
                                        )

                                    } else {
                                        // let archive_button_class = parseInt(m.archiver_ID) > 0 ? 'border-success text-success' : 'border-danger text-danger';
                                        // let archive_button_icon = parseInt(m.archiver_ID) > 0 ? 'fa-rotate-left' : 'fa-trash-can';

                                        return (
                                            <tr className="row" key={m.shift_ID}>
                                                <td className="col-1 d-flex align-items-center">
                                                    <button className="btn btn-sm border-primary text-primary" onClick={() => handleButtonClick('edit_shift', m)}><FontAwesomeIcon icon="fa-pencil" className="" /></button>
                                                    { parseInt(m.active) === 0 && <span className="ms-4 badge rounded-pill border border-danger text-danger">Inactive</span> }
                                                    { parseInt(m.active) === 1 && <span className="ms-4 badge rounded-pill border border-success text-success">Active</span> }
                                                </td>
                                                <td className={`col-3 ps-3 ${m.archiver_ID > 0 && 'text-muted'}`}>
                                                    <div className="">
                                                        {m.shift_name}
                                                        {parseInt(m.shift_group) !== 0 && ` - Shift ${m.shift_group}`}
                                                        {parseInt(m.lunch_paid) === 1 && ` - Paid Lunch`}
                                                        {parseInt(m.lunch_paid) === 0 && ` - Unpaid Lunch`}
                                                    </div>
                                                    {
                                                        m.note !== '' && 
                                                        <div className="">
                                                            <span className="text-muted">NOTE:</span> {m.note}
                                                        </div>
                                                    }
                                                </td>
                                                <td className={`col-2 ps-3 ${m.archiver_ID > 0 && 'text-muted'}`}>
                                                    {
                                                        m.shift_start !== '00:00:00' && m.shift_end !== '00:00:00' &&
                                                        <>{moment(`01-01-2000 ${m.shift_start}`).format('h:mm A')} - {moment(`01-01-2000 ${m.shift_end}`).format('h:mm A')}</>
                                                    }
                                                </td>
                                                <td className={`col-2 ps-3 ${m.archiver_ID > 0 && 'text-muted'}`}>
                                                    {
                                                        (m.break_start_1 !== '00:00:00' || m.break_end_1 !== '00:00:00') &&
                                                        <>{moment(`01-01-2000 ${m.break_start_1}`).format('h:mm A')} - {moment(`01-01-2000 ${m.break_end_1}`).format('h:mm A')}</>
                                                    }
                                                </td>
                                                <td className={`col-2 ps-3 ${m.archiver_ID > 0 && 'text-muted'}`}>
                                                    {
                                                        (m.lunch_start !== '00:00:00' || m.lunch_end !== '00:00:00') &&
                                                        <>{moment(`01-01-2000 ${m.lunch_start}`).format('h:mm A')} - {moment(`01-01-2000 ${m.lunch_end}`).format('h:mm A')}</>
                                                    }
                                                </td>
                                                <td className={`col-2 ps-3 ${m.archiver_ID > 0 && 'text-muted'}`}>
                                                    {
                                                        (m.break_start_2 !== '00:00:00' || m.break_end_2 !== '00:00:00') &&
                                                        <>{moment(`01-01-2000 ${m.break_start_2}`).format('h:mm A')} - {moment(`01-01-2000 ${m.break_end_2}`).format('h:mm A')}</>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                })}
                            </tbody>
                        }
                    </table>
                }
            </div>
        </>
    )
}

export const SchedulingComponent = compose(
	queryWithLoading({
		gqlString: GetScheduling,
        name: "componentData"
	})
)(SchedulingContent)

