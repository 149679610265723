import React from 'react';
// import { Multiselect } from 'multiselect-react-dropdown';
// import {flowRight as compose} from 'lodash';
// import { queryWithLoading } from '../utilities/NSDb.js';
// import { graphql } from '@apollo/react-hoc';
import { useState } from 'react';
// import _ from "lodash";
// import dayjs from 'dayjs';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
// import { useMutation, useQuery } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function ToolingUsageFormContent(props) {
    // Set const values from props
    const { closeTooling, Tooling, saveTooling, SelectedTooling: DefaultSelectedTooling } = props;

    const [SelectedTooling, setSelectedTooling] = useState({ ...DefaultSelectedTooling });

    // const [SelectedTooling, setSelectedTooling] = useState({loaded: true})

    // Update the state for any changing input in the Production Record details form
    function changeSelectedToolingInputValue(name, value) {
        if ( name === 'tooling_usage_quantity' ) {
            if(value !== '') {
                value = parseInt(value)
                if( isNaN(value) ) {
                    value = '';
                }
            } else {
                // This is to avoid attempting to save "" as an INT
                value = 0;
            }
        } 

        setSelectedTooling({
            ...SelectedTooling,
            [name]: value,
            save_button_text: 'Save', 
            save_message: ''
        })

    }

    // Handler for Save button click
    function handleSave() {
        // VALIDATION: Must select a tool
        if ( parseInt(SelectedTooling.tooling_ID) === 0 ) {
            setSelectedTooling({
                ...SelectedTooling,
                save_button_text: 'Save', 
                save_message: 'Select a Tool'
            })
        }
        // VALIDATION: Do not allow 0 quantity entered
        else if ( SelectedTooling.tooling_usage_quantity === '' || SelectedTooling.tooling_usage_quantity === null || parseInt(SelectedTooling.tooling_usage_quantity) === 0 ) {
            setSelectedTooling({
                ...SelectedTooling,
                save_button_text: 'Save', 
                save_message: 'Enter a Quantity'
            })
        }
        // VALIDATION: Must have note
        else if(SelectedTooling.notes === '') {
            setSelectedTooling({
                ...SelectedTooling,
                save_button_text: 'Save', 
                save_message: 'Note required'
            })
        }

        if( SelectedTooling.notes !== '' && parseInt(SelectedTooling.tooling_ID) !== 0 && SelectedTooling.tooling_usage_quantity !== '' && SelectedTooling.tooling_usage_quantity !== null && parseInt(SelectedTooling.tooling_usage_quantity) !== 0 ) {

            setSelectedTooling({
                ...SelectedTooling, 
                save_button_text: 'Saved'
            })
            // Save ToolingUsage form to parent component
            saveTooling({...SelectedTooling, save_button_text: 'Saved'})
        }    
    }

    return (
        <>
            {/* ToolingUsage Reason */}
            <td className="border-0 col-6">
                <div className="form-floating">
                    <select 
                        className="form-select mb-3" 
                        disabled={false}
                        id="tooling"
                        onChange={(event) => changeSelectedToolingInputValue('tooling_ID', event.target.value)}
                        placeholder="Tooling"
                        value={SelectedTooling.tooling_ID}
                    >
                        <option key={0} value={0}>Select Tooling</option>
                        <option key={-1} value={-1}>Tool Not Found - See Notes</option>
                        {Tooling.map((t) => {
                            return (
                                <option key={t.tooling_ID} value={t.tooling_ID}>{t.tooling_description} - {t.manufacturer_name} {t.manufacturer_part_number}</option>
                            )
                        })}
                    </select>
                    <label className="text-muted" htmlFor="tooling">Tooling Used</label>
                </div>
            </td>

            {/* Quantity */}
            <td className="border-0 col-1">
                <div className="form-floating">
                    <input className="form-control" id="tooling_usage_quantity" placeholder="Tooling Usage Quantity" value={SelectedTooling.tooling_usage_quantity || ""} onChange={(event) => changeSelectedToolingInputValue('tooling_usage_quantity', event.target.value)} />
                    <label className="text-muted" htmlFor="tooling_usage_quantity">Quantity</label>
                </div>
            </td>

            {/* Tooling Usage  Notes */}
            <td className="border-0 col-3">
                <div className="form-floating">
                    <textarea className="form-control" id="tooling_notes" placeholder="Enter notes here" value={SelectedTooling.notes} rows="2" onChange={(event) => changeSelectedToolingInputValue('notes', event.target.value)} />
                    <label className="text-muted" htmlFor="tooling_notes">Note - <span className="text-danger">Required</span></label>
                </div>
            </td>

            {/* Save ToolingUsage button and Message */}
            {
                SelectedTooling.save_message !== '' && 
                <td className="border-0 col-2 pt-4">
                    <div className="text-danger">{SelectedTooling.save_message}</div>
                </td>
            }
            {
                SelectedTooling.save_message === '' && 
                <>
                <td className="border-0 col-1 pt-0">
                    <button className="btn mt-3 border-success border-3 fw-bold text-success" disabled={SelectedTooling.tooling_description === ''} onClick={() => handleSave()}><FontAwesomeIcon icon="fa-floppy-disk" className="me-2" />{SelectedTooling.save_button_text}</button>
                </td>
                <td className="border-0 col-1 pt-0 pe-0">
                    <button className="btn mt-3 border-secondary float-end border-3 fw-bold text-secondary" onClick={() => closeTooling('close_tooling_usage')}><FontAwesomeIcon icon="fa-times" className="me-2" />Close</button>
                </td>
                </>
            }
        </>
    )
}

