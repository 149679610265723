import React from 'react';
import _ from 'lodash';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
// import { useAuthUser } from 'react-auth-kit';
import { queryWithLoading } from '../utilities/NSDb.js';
import { add } from 'mathjs';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
import { useMutation, useLazyQuery } from '@apollo/client';


// GRAPHQL QUERY
import { 
    GetInspectionUsers,
    SaveInspection
} from './inspection_graphql.js';
import { 
    GetPartsByCustomer
} from '../parts/parts_graphql.js';
import { 
    GetMachinesByType
} from '../machines/machines_graphql.js';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
// import { useQuery, useMutation } from '@apollo/client';

export function InspectionUsersComponent(props) {

    const [save_inspection] = useMutation(SaveInspection);
    
    const [get_machines] = useLazyQuery(GetMachinesByType, { fetchPolicy: 'network-only'});
    const [get_parts] = useLazyQuery(GetPartsByCustomer, { fetchPolicy: 'network-only'});
    const [get_users] = useLazyQuery(GetInspectionUsers, { fetchPolicy: 'network-only'});

    const DefaultSelectedContainer = {
        inspection_ID: 0,
        account_ID: 0,
        bad_parts: '',
        good_parts: '',
        machine_ID: 0,
        part_ID: 0,
        rerun: 0,
        run_time_hours: '',
        run_time_minutes: '',
        user_IDs: []
    };

    const DefaultSelectedInspection = {
        inspection_ID: 0,
        account_ID: 0,
        bad_parts: '',
        date_inspected: dayjs().format('YYYY-MM-DD'),
        good_parts: '',
        machine_ID: 0,
        part_ID: 0,
        rerun: 0,
        run_time_hours: '',
        run_time_minutes: '',
        user_IDs: []
    };

    const DefaultBaseState = {
        action: '',
        choose_operators: false,
        counter_type: 'existing',
        date_data_fetched: '',
        form: '',
        Machines: [],
        Parts: [],
        save_button_text: 'SAVE INSPECTION COUNTS',
        save_message: '',
        serial_number: '',
        Users: []
    };

    const [SelectedContainer, setSelectedContainer] = useState({ ...DefaultSelectedContainer });
    const [SelectedInspection, setSelectedInspection] = useState({ ...DefaultSelectedInspection });

    const [BaseState, setBaseState] = useState({ ...DefaultBaseState });

    // Update SelectedInspection state values
    async function changeInputValue(name, value) {

        // Every input on this form is an integer, so strip it down:
        if(value !== '') {
            // Strips non-numeric characters, leaves decimal
            value = parseInt(value.replace(/[^\d.-]/g, ''));
        }
console.log({value})
        setSelectedInspection({
            ...SelectedInspection,
            [name]: value
        });
        setBaseState({
            ...BaseState,
            save_message: ''
        });

    }

    // Handler function for save button
    async function handleSave(action) {

        setBaseState({
            ...BaseState,
            save_button_text: 'SAVING...'
        });

        let SaveData = { ...SelectedInspection };
        // Parse string values that are IDs to be integers, saving stringified numbers to the database throws an error.
        let MakeIntegers = ['bad_parts', 'good_parts', 'inspection_ID', 'rerun', 'run_time_hours', 'run_time_minutes' ];

        MakeIntegers.forEach((mi) => {
            if(SaveData[mi] === '') {
                SaveData[mi] = 0;
            } else {
                SaveData[mi] = parseInt(SaveData[mi])
            }
        });

        let result = {};
        console.log({SaveData})
        result = await save_inspection({ variables: { input: { ...SaveData, date_created: dayjs().format('YYYY-MM-DD HH:mm:ss'), user_IDs: SaveData.user_IDs.join() } }});
        console.log({result})
        if ( result.data && result.data.inspectionSave.Inspection ) {
            let Inspection = result.data.inspectionSave.Inspection;

            if ( parseInt(SelectedInspection.inspection_ID) === 0 ) {
                setSelectedInspection({
                    ...SelectedInspection,
                    inspection_ID: parseInt(Inspection.inspection_ID)
                });
            } 

            setBaseState({
                ...BaseState,
                save_button_text: DefaultBaseState.save_button_text,
                save_message: 'Inspection data successfully saved. Form will automatically reset in 30 seconds. Please confirm that your counts are correct, and correct and resave them if needed.'
            });

            // Reset form after 30 second
            setTimeout(
                () => { resetStateDefaults() },
                30000
            );
        }


        //window.location.reload();
    }

    // Reset State variables to Defaults
    function resetStateDefaults() {
        setSelectedInspection({
            ...DefaultSelectedInspection
        });
        setBaseState({
            ...DefaultBaseState
        });
    }

    // Handler for clicking the button to show the recording, and getting all of the data we need
    async function handleShowForm(form) {
    
        let UpdateBaseData = { counter_type: 'existing', form };

        // Get the data we need for this form.

        let Machines = await get_machines({ variables: { machine_type_ID: 2 } });
        let Parts = await get_parts({ variables: { customer_ID: 1, inspection_type_ID: 1 } });
        let Users = await get_users();

        UpdateBaseData = { ...UpdateBaseData, Machines: Machines.data.machines_by_type, Parts: Parts.data.inspection_parts_by_customer, Users: Users.data.users_with_production };

        setBaseState({
            ...BaseState,
            ...UpdateBaseData 
        });

    }

    // Handler for clicking on the Operator selection button
    function handleUserSelection(user_ID, index) {
        let temp_user_IDs = [...SelectedInspection.user_IDs];

        // If the user_ID is already in here and their button is clicked, remove them from the array.
        if ( index > -1 ) {
            temp_user_IDs.splice(index, 1);
        } else {
            temp_user_IDs.push(user_ID);
        }

        // Update SelectedInspection with new user_IDs
        setSelectedInspection({
            ...SelectedInspection,
            user_IDs: temp_user_IDs
        })
    }

    // Handler for scanning a production record tag's serial number
    function handleSerialNumberScan(serial_number) {
        
    
    }

console.log({BaseState})
console.log({SelectedInspection})

    // LOADING FORM - CLICK BUTTON TO GET MACHINES, PARTS, USERS
    if ( BaseState.form === '' ) {
        return (
            <>
                <div className="row">
                    <div className="col">
                        <NavLink to={`/`} className="btn text-white float-end fw-bolder btn-primary border-3 mt-5" ><FontAwesomeIcon icon="fa-home" className="h4 mx-2 mt-2" /></NavLink>
                    </div>
                </div>
                <div className="clearfix row justify-content-center">
                    <div className="col-auto text-secondary mt-5">
                        <button className="btn text-white fw-bolder btn-primary border-3 py-5 px-5 mt-5" onClick={() => handleShowForm('robot')}><span className="h2"><FontAwesomeIcon icon="fa-clipboard" className="mt-1 me-4" />Record Inspection Station Data</span></button>
                    </div>
                </div>
                {/* <div className="clearfix row justify-content-center">
                    <div className="col-auto text-secondary mt-5">
                        <button className="btn text-white fw-bolder btn-primary border-3 py-5 px-5 mt-5" onClick={() => handleShowForm('counter')}><span className="h2"><FontAwesomeIcon icon="fa-plus" className="mt-1 me-4" />Add Parts To Container</span></button>
                    </div>
                </div> */}
            </>
        )
    } 

    // COUNTER FORM - ADD PARTS TO CONTAINER
    else if ( BaseState.form === 'counter' && BaseState.Machines.length > 0 ) {
    
        return (
            <div className="mt-2 text-secondary pt-3">

                {/* RELOAD PAGE TO GO BACK TO HOME */}
                <div className="row">
                    <div className="col">
                        <div className="btn text-white float-end fw-bolder btn-primary border-3 mb-2" onClick={() => window.location.reload()} ><FontAwesomeIcon icon="fa-rotate-left" className="h4 mx-2 mt-2" /></div>
                    </div>
                </div>

                <div className="row mt-2 justify-content-center ">
                    <div className="col-6">
                        <div className={`btn ${(BaseState.counter_type === 'existing' && 'btn-secondary text-white') || 'btn-white border border-secondary text-secondary'} w-100 p-4`} onClick={() => setBaseState({...BaseState, action: '', choose_operators: true})}><span className="h1">EXISTING CONTAINER</span></div>
                    </div>
                    <div className="col-6">
                        <div className={`btn ${(BaseState.counter_type === 'new' && 'btn-secondary text-white') || 'btn-white border border-secondary text-secondary'} w-100 p-4`} onClick={() => setBaseState({...BaseState, action: 'enter_counts', choose_operators: false})}><span className="h1">NEW CONTAINER</span></div>
                    </div>
                    <div className="col-12">
                        <div className="alert alert-default">Scan production record barcode or click to choose Operator and Machine</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6 text-center">

                        {/* SCAN PRODUCTION RECORD BARCODE */}
                        <h4 className="mb-1">Scan Production Record Tag's Barcode</h4>
                        <input autoFocus className="form-control float" id="serial_number" style={{fontSize: 34+'px'}} value={BaseState.serial_number} onChange={(event) => handleSerialNumberScan(event.target.value)} /> 
                    </div>

                    <div className="col-6">
                        {/* CHOOSE OPERATOR / MACHINE */}
                        <div className="btn btn-primary text-white w-100 p-4" onClick={() => setBaseState({...BaseState, choose_operators: true})}><span className="h1">CHOOSE OPERATOR / PART</span></div>
                    </div>
                </div>

                {/* SHOW PART / OPERATOR SELECTION */}
                {
                    BaseState.choose_operators === true &&
                    <>
                        {/* PART SELECTION */}
                        <div className="row mt-4">
                            {
                                BaseState.Parts.map((p) => {
                                    return (
                                        <div className="col mt-2">
                                            <div className={`border rounded p-4 text-center h3 ${parseInt(SelectedInspection.part_ID) === parseInt(p.part_ID) && 'bg-secondary text-white'}`} onClick={() => setSelectedInspection({...SelectedInspection, part_ID: parseInt(p.part_ID)})}>
                                                {p.part_number}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        {/* OPERATOR */}
                        <div className="row text-center p-0 mt-4">
                            {
                                BaseState.Users.map((u) => {
                                    let index = SelectedInspection.user_IDs.findIndex((user) => parseInt(user) === parseInt(u.user_ID));
                                    return (
                                        <div className="col-3 mt-2">
                                            <div className={`border rounded p-4 text-center h3 ${index > -1 && 'bg-secondary text-white'}`} onClick={() => handleUserSelection(parseInt(u.user_ID), index)}>
                                                {u.first_name} {u.last_name.charAt(0).toUpperCase()}
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </>
                }
            </div>        
        )
    }
    
    // MACHINE / PART SELECTION FORM - USERS AND INSPECTION NUMBERS ENTRY
    else if ( BaseState.form === 'robot' && BaseState.Machines.length > 0 ) {
        return (
            <div className="mt-2 text-secondary pt-3">

                {/* RELOAD PAGE TO GO BACK TO HOME */}
                <div className="row">
                    <div className="col">
                        <div className="btn text-white float-end fw-bolder btn-primary border-3 mb-2" onClick={() => window.location.reload()} ><FontAwesomeIcon icon="fa-rotate-left" className="h4 mx-2 mt-2" /></div>
                    </div>
                </div>

                {/* MACHINE (ROBOT) SELECTION */}
                <div className="row p-0">
                    {
                        BaseState.Machines.map((m) => {
                            return (
                                <div className="col" key={m.machine_ID}>
                                    <div className={`border rounded p-4 text-center h3 ${parseInt(SelectedInspection.machine_ID) === parseInt(m.machine_ID) && 'bg-secondary text-white'}`} onClick={() => setSelectedInspection({...SelectedInspection, machine_ID: parseInt(m.machine_ID)})}>
                                        {( m.machine_short_name !== null && m.machine_short_name !== "" && m.machine_short_name ) || m.machine_name}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                {/* PART SELECTION */}
                <div className="row">
                    {
                        BaseState.Parts.map((p) => {
                            return (
                                <div className="col mt-2">
                                    <div className={`border rounded p-4 text-center h3 ${parseInt(SelectedInspection.part_ID) === parseInt(p.part_ID) && 'bg-secondary text-white'}`} onClick={() => setSelectedInspection({...SelectedInspection, part_ID: parseInt(p.part_ID)})}>
                                        {p.part_number}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                {/*  SHOW USER SELECTION OR ENTER NUMBERS BUTTONS*/}
                {
                    parseInt(SelectedInspection.machine_ID) > 0 && parseInt(SelectedInspection.part_ID) > 0 &&
                    <div className="row mt-2 justify-content-center">
                        {
                            ( BaseState.choose_operators === false || BaseState.action === 'enter_counts' ) &&
                            <div className="col-auto">
                                <div className="btn btn-primary w-100 p-4" onClick={() => setBaseState({...BaseState, action: '', choose_operators: true})}><span className="h3"><FontAwesomeIcon icon="fa-users" className="mt-1 me-2" />CHOOSE OPERATORS</span></div>
                            </div>
                        }
                        {
                            ( BaseState.choose_operators === true || BaseState.action === '' ) &&
                            <div className="col-auto">
                                <div className="btn btn-primary w-100 p-4" onClick={() => setBaseState({...BaseState, action: 'enter_counts', choose_operators: false})}><span className="h3"><FontAwesomeIcon icon="fa-gear" className="mt-1 me-2" />ENTER INSPECTION COUNTS</span></div>
                            </div>
                        }
                    </div>
                }

                {/* SHOW OPERATORS - ONLY WHEN INSPECTION COUNT FORM IS HIDDEN*/}
                {
                    BaseState.choose_operators === true && BaseState.action !== 'enter_counts' &&
                    <div className="row text-center p-0">
                        {
                            BaseState.Users.map((u) => {
                                let index = SelectedInspection.user_IDs.findIndex((user) => parseInt(user) === parseInt(u.user_ID));
                                return (
                                    <div className="col-3 mt-2">
                                        <div className={`border rounded p-4 text-center h3 ${index > -1 && 'bg-secondary text-white'}`} onClick={() => handleUserSelection(parseInt(u.user_ID), index)}>
                                            {u.first_name} {u.last_name.charAt(0).toUpperCase()}
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                }

                {/* SHOW SELECT OPERATORS WHEN INSPECTION COUNT FORM IS SHOWING */}
                {
                    BaseState.action === 'enter_counts' && SelectedInspection.user_IDs.length > 0 &&
                    <div className="row justify-content-center p-0">
                        {
                            SelectedInspection.user_IDs.map((u) => {
                                let index = BaseState.Users.findIndex((user) => parseInt(u) === parseInt(user.user_ID));
                                return (
                                    <div className="col-3 mt-2">
                                        <div className="border rounded p-4 text-center h3 bg-secondary text-white">
                                            {BaseState.Users[index].first_name} {BaseState.Users[index].last_name.charAt(0).toUpperCase()}
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                }

                {/* SHOW INSPECTION COUNTS FORM */}
                {
                    BaseState.action === 'enter_counts' &&
                    <>

                        {/* SAVE INPUTS */}
                        <div className="row text-center mt-4 h4 mb-2">
                            <div className="col-4">
                                <label className="text-muted h4" htmlFor="good_parts">Good Count *</label>
                                <div><input className="border rounded h4 p-3" id="good_parts" value={SelectedInspection.good_parts || ''} onChange={(event) => changeInputValue('good_parts', event.target.value)} /></div>
                            </div>
                            <div className="col-4">
                                <label className="text-muted h4" htmlFor="bad_parts">Bad Count *</label>
                                <div><input className="border rounded h4 p-3" id="bad_parts" value={SelectedInspection.bad_parts || ''} onChange={(event) => changeInputValue('bad_parts', event.target.value)} /></div>
                            </div>
                            <div className="col-4">
                                <label className="text-muted h4" htmlFor="date_inspected">Re-Run of Failed</label><br />
                                <div className="btn-group btn-group-lg" role="group">
                                    <button className={`btn btn-lg btn-outline-secondary ${(parseInt(SelectedInspection.rerun) === 0 && 'btn-secondary text-white') || 'btn-default text-muted'}`} onClick={() => setSelectedInspection({ ...SelectedInspection, rerun: 0 })}>
                                        No
                                    </button>
                                    <button className={`btn btn-lg btn-outline-secondary ${(parseInt(SelectedInspection.rerun) === 1 && 'btn-secondary text-white') || 'btn-default text-muted'}`} onClick={() => setSelectedInspection({ ...SelectedInspection, rerun: 1 })}>
                                        Yes
                                    </button>
                                </div>                            
                            </div>
                        </div>
                        <div className="row text-center mt-4 h4 mb-4">
                            <div className="col-4">
                                <label className="text-muted h4" htmlFor="date_inspected">Inspection Date *</label>
                                <div><input type="date" className="border rounded h4 p-3" id="date_inspected" value={SelectedInspection.date_inspected || ""} onChange={(event) => setSelectedInspection({ ...SelectedInspection, date_inspected: event.target.value })} /></div>
                            </div>
                            <div className="col-8">
                                <label className="text-muted h4" htmlFor="run_time_hours">Record Length of Run Time For These Parts? </label>

                                <div className="row m-0 p-0">
                                    <div className="col-6">
                                        <div className="input-group">
                                            <input className="form-control p-3 text-end" style={{fontSize: 24+'px'}} id="run_time_hours" value={SelectedInspection.run_time_hours || ""} onChange={(event) => changeInputValue('run_time_hours', event.target.value)} />
                                            <span className="input-group-text px-1" style={{fontSize: 24+'px'}}>Hours</span>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="input-group">
                                            <input className="form-control p-3 text-end" style={{fontSize: 24+'px'}} id="run_time_minutes" value={SelectedInspection.run_time_minutes || ""} onChange={(event) => changeInputValue('run_time_minutes', event.target.value)} />
                                            <span className="input-group-text px-1" style={{fontSize: 24+'px'}}>Minutes</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* SAVE MESSAGE */}
                        {
                            BaseState.save_message !== '' && <div className="row justify-content-center h4"><div className="col-auto"><div className="alert alert-success text-center">{BaseState.save_message}</div></div></div>
                        }

                        {/* SAVE BUTTON */}
                        <div className="row justify-content-center">
                            <button className="col-auto btn btn-success p-4" onClick={(event) => handleSave()} disabled={SelectedInspection.good_parts === '' || SelectedInspection.bad_parts === '' || SelectedInspection.date_inspected === '' || BaseState.save_button_text !== DefaultBaseState.save_button_text} ><span className="h3"><FontAwesomeIcon icon="fa-floppy-disk" className="mt-1 me-2" />SAVE INSPECTION COUNTS</span></button>
                        </div>
                    </>
                }

            </div>
        )
    }
}
