import React from 'react';
import {flowRight as compose} from 'lodash';
import { queryWithLoading } from '../utilities/NSDb.js';
// import { graphql } from '@apollo/react-hoc';
import { useState } from 'react';
import _ from "lodash";
import dayjs from 'dayjs';
import { add } from 'mathjs';

import { useAuthUser } from 'react-auth-kit';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
import { useMutation, useLazyQuery } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// GRAPHQL QUERY
import { 
    GetChecksheetProcesses,
    GetChecksheetReportPage,
    GetChecksheetRecords2
} from './checksheets_graphql.js';
import { GetControlPanelPlans } from '../control_plans/control_plans_graphql.js';

// IMPORT COMPONENTS
import {ChecksheetReviewComponent} from './checksheet_review_component.js'

function ChecksheetReportContent(props) {

    let TempParts = [...props.componentData.parts];
    // Only active parts:
    TempParts = TempParts.filter((tp) => tp.archiver_ID === null);
    const Parts = TempParts.sort(function(a,b) {
                if(a.customer_ID > b.customer_ID) return -1;
                if(a.customer_ID < b.customer_ID) return 1;
                return 0;
            });

    const [get_checksheet_processes] = useLazyQuery(GetChecksheetProcesses);
    const [get_checksheet_records2] = useLazyQuery(GetChecksheetRecords2, { fetchPolicy: 'network-only'});
    const [get_control_panel_plans2] = useLazyQuery(GetControlPanelPlans, { fetchPolicy: 'network-only'});

    let temp_schedule_filter = handleScheduleFilter('last_week');

    const DefaultChecksheet = {
        ChecksheetProcesses: [],
        checksheet_ID: 0,
        control_plan_ID: 0,
        control_plan_number: '',
        date_created: null,
        first_name: '',
        inspection_step: '',
        instructions: '',
        last_checksheet_creator_ID: 0,
        last_checksheet_date_created: '',
        last_name: '',
        machine_name: '',
        machine_short_name: '',
        note: '',
        operation_name: '',
        operation_number: '',
        part_ID: 0,
        part_number: '',
        production_record_ID: 0,
        required_every: '',
        responsibility: '',
        shift_group: 0   
    };

    const [SelectedChecksheet, setSelectedChecksheet] = useState({ ...DefaultChecksheet });

    const [BaseState, setBaseState] = useState(
        {
            action: props.action || '',
            Checksheets: [],
            filter: '',
            filter_search_message: '',
            NewChecksheets: [],
            searched: false
        }
    );

    const [Filters, setFilters] = useState({
        customer_ID: 1,
        date_range: 'last_week',
        message: '',
        part_ID: 0,
        schedule_end: temp_schedule_filter.temp_schedule_end,
        schedule_start: temp_schedule_filter.temp_schedule_start
    });

    // Handler function for changing filters
    function handleChangeFilter(filter_name, filter_value, parent_object, key) {
        if ( filter_name === 'date_range' ) {
            let temp_schedule_filter = handleScheduleFilter(filter_value);
            let temp_display_view = filter_value === 'last_month' ? 'days' : Filters.display_view;
            setFilters({
                ...Filters,
                display_view: temp_display_view,
                schedule_end: temp_schedule_filter.temp_schedule_end,
                schedule_start: temp_schedule_filter.temp_schedule_start,
                [filter_name]: filter_value,
                message: ''
            });
        } 
        else if ( filter_name === 'display_view' && filter_value === 'parts' ) {

            setFilters({
                ...Filters,
                [filter_name]: filter_value,
                display_operator: false,
                display_scrap: false,
                message: ''
            });
        }
        else if ( filter_name === 'count_against_std' ) {
        
            let TempStd = { ...Filters.count_against_std[parent_object] };
            TempStd[key] = filter_value;
            setFilters({
                ...Filters,
                count_against_std: {
                    ...Filters.count_against_std,
                    [parent_object]: TempStd
                },
                message: ''
            });
        }
        // else if ( filter_name === 'part_ID' ) {

        //     // // If we have already performed a previous search, we have the Machines for all parts, so we can update the Machines filter against standard.
        //     // if ( BaseState.PartsMachines.length > 0 ) {
            
        //     //     let TempMachines = BaseState.PartsMachines.filter((pm) => parseInt(pm.part_ID) === parseInt(filter_value))[0].Machines;

        //     // } 
        // }
        else {
            setFilters({
                ...Filters,
                [filter_name]: filter_value,
                message: ''
            });

            // If the filter changed would require hitting the db for more results, change BaseState.searched = false
            // if ( filter_name === 'date_range' || filter_name === 'schedule_end' || filter_name === 'schedule_start' || ( filter_name === 'part_ID' && parseInt(Filters.part_ID) !== 0 ))
        }

        // Until we refine the searching logic for when we need to hit the db for a changed filter, just always require a new db hit on a filter change
        setBaseState({ ...BaseState, searched: false })
        setSelectedChecksheet({ ...DefaultChecksheet })
    }

    // Take today's date and loop backwards until you get Sunday to use as your start day
    function handleScheduleFilter(filter) {
        let temp_weekday = dayjs().format('dddd');
        let temp_schedule_start = dayjs().format('YYYY-MM-DD');
        let temp_schedule_end = dayjs().add(6, 'day').format('YYYY-MM-DD');

        // Set this week's start and end
        if ( temp_weekday !== 'Sunday') {
            [...Array(6)].forEach((d, index) => {
                // If this is Sunday, set schedule_start
                if ( dayjs().add(-index, 'day').format('dddd') === 'Sunday' ) {
                    temp_schedule_start = dayjs().add(-index, 'day').format('YYYY-MM-DD');
                    temp_schedule_end = dayjs(temp_schedule_start).add(6, 'day').format('YYYY-MM-DD');
                }
            });
        }

        if ( filter === 'last_week' ) {
            temp_schedule_start = dayjs(temp_schedule_start).add(-7, 'day').format('YYYY-MM-DD');
            temp_schedule_end = dayjs(temp_schedule_end).add(-7, 'day').format('YYYY-MM-DD');
        } else if ( filter === 'last_month' ) {
            temp_schedule_start = dayjs(dayjs().add(-1, 'month')).startOf('month').format('YYYY-MM-DD');
            temp_schedule_end = dayjs(dayjs().add(-1, 'month')).endOf('month').format('YYYY-MM-DD');
        } else if ( filter === 'this_month' ) {
            temp_schedule_start = dayjs().startOf('month').format('YYYY-MM-DD');
            temp_schedule_end = dayjs().endOf('month').format('YYYY-MM-DD');
        } else if ( filter === 'yesterday' ) {
            temp_schedule_start = dayjs().add(-1, 'day').format('YYYY-MM-DD');
            temp_schedule_end = dayjs().add(-1, 'day').format('YYYY-MM-DD');
        }

        return { temp_schedule_end, temp_schedule_start };
    }

    // Get Production Records based on the filters
    async function handleSearchRecords() {
    
        const Records = await get_checksheet_records2({ variables: { date_end: Filters.schedule_end, date_start: Filters.schedule_start } });
        console.log({Records})
        let TempChecksheets = [];
        let TempControlPlans = [];
        let NewRecords = [];
        if (Records.data && Records.data.checksheets_records2) {
            TempChecksheets = Records.data.checksheets_records2;

            TempControlPlans = await get_control_panel_plans2({ variables: { } });
            console.log({TempControlPlans})
            if ( TempControlPlans && TempControlPlans.data.control_panel_plans2 && TempControlPlans.data.control_panel_plans2.length > 0 ) {
                // TempControlPlans = TempControlPlans.data.control_panel_plans2.filter((cp) => cp.inspection_step === 'In Process (3 hours)');
                TempControlPlans = TempControlPlans.data.control_panel_plans2;
            }
            console.log(Records.data.checksheets_records2)
            // For each PR - assuming that PRs are made at the end of the shift, create a Checksheet 2 hours and 5 hours before the time of the PR
            Records.data.checksheets_records2.forEach((cs) => {
                // Only show records through Saturday 11/11/2023
                if ( dayjs(cs.date_created).format('YYYY-MM-DD') < '2024-08-21' && parseInt(cs.machine_ID) !== 60 ) {
                    NewRecords.push({...cs, date_created: dayjs(cs.date_created).add(2, 'hours').format('M-D-YY h:mm a'), production_record_ID: parseInt(cs.production_record_ID), inspection_step: 'MetalFlow Cavity 1 - 3 Hours'});
                    NewRecords.push({...cs, date_created: dayjs(cs.date_created).add(2, 'hours').format('M-D-YY h:mm a'), production_record_ID: add(parseInt(cs.production_record_ID), 3), inspection_step: 'MetalFlow Cavity 2 - 3 Hours'});
                    NewRecords.push({...cs, date_created: dayjs(cs.date_created).subtract(1, 'hours').format('M-D-YY h:mm a'), production_record_ID: add(parseInt(cs.production_record_ID), 6), inspection_step: 'MetalFlow Cavity 1 - 3 Hours'});
                    NewRecords.push({...cs, date_created: dayjs(cs.date_created).subtract(1, 'hours').format('M-D-YY h:mm a'), production_record_ID: add(parseInt(cs.production_record_ID), 9), inspection_step: 'MetalFlow Cavity 2 - 3 Hours'});
                    if ( NewRecords.findIndex((i) => i.inspection_step === 'First Piece Checksheet' && dayjs(i.date_created).format('YYYY-MM-DD') === dayjs(cs.date_created).format('YYYY-MM-DD') && i.first_name === cs.first_name) === -1 ) {
                        NewRecords.push({...cs, date_created: dayjs(cs.date_created).subtract(2, 'hours').format('M-D-YY h:mm a'), production_record_ID: add(parseInt(cs.production_record_ID), 7), inspection_step: 'First Piece Checksheet'});
                    }
                }
            });

            // Filter these NewRecords by date_created
            NewRecords = NewRecords.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.date_created) - new Date(a.date_created);
              });

        }

        setBaseState({
            ...BaseState,
            Checksheets: TempChecksheets,
            ControlPlans: TempControlPlans,
            NewChecksheets: NewRecords,
            searched: true
        });
        setSelectedChecksheet({ ...DefaultChecksheet })

    }

    // Get the Checksheet Processes for the Checksheet based on the part_ID
    async function handleChecksheetLoad(cs) {

        // Fix for checksheets being connected to part_ID 1 when the new part_ID is 36
        let checksheet_part_ID = parseInt(cs.part_ID) === 36 ? 1 : parseInt(cs.part_ID); 

        // Get the checksheet / processes
        const checksheet_result = await get_checksheet_processes({ variables: { checksheet_ID: BaseState.ControlPlans.filter((cp) => parseInt(cp.part_ID) === checksheet_part_ID)[0].checksheet_ID, active: 1 } });

        if ( checksheet_result.data && checksheet_result.data.checksheet_processes ) {

            let TempChecksheet = checksheet_result.data.checksheet_processes;
            // Update the SelectedChecksheet
            setSelectedChecksheet({
                ...SelectedChecksheet,
                ...cs,
                ...TempChecksheet.Checksheet,
                ChecksheetProcesses: checksheet_result.data.checksheet_processes.ChecksheetProcesses
            });

            //  ...checksheet_result.data.checksheet_processes.Checksheet

        }
    }
    console.log("BaseState.NewChecksheets: ", BaseState.NewChecksheets)
    return (
        <>
                <div className="row clearfix mb-3 justify-content-center">
                    {/* RESULTS COUNTER AND FILTERS */}

                    {/* DATE SELECTIONS */}
                    <div className="col-auto">
                        <div className="form-floating">
                            <select 
                                className="form-select" 
                                disabled={false}
                                id="date_range"
                                onChange={(event) => handleChangeFilter('date_range', event.target.value)}
                                placeholder="Date Range"
                                value={Filters.date_range}
                            >
                                <option key="0" value="yesterday">Yesterday</option>
                                <option key="1" value="this_week">This Week</option>
                                <option key="2" value="this_month">This Month</option>
                                <option key="3" value="last_month">Last Month</option>
                                <option key="4" value="last_week">Last Week</option>
                            </select> 
                            <label className="text-muted" htmlFor="date_range">Date Range</label>                                                   
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-floating">
                            <input type="date" className="form-control mb-3" id="schedule_start" placeholder="Enter a Start Date" value={Filters.schedule_start || ""} onChange={(event) => setFilters({ ...Filters, schedule_start: event.target.value, searched: false })} />
                            <label className="text-muted" htmlFor="schedule_start">Start Date</label>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-floating">
                            <input type="date" className="form-control mb-3" id="schedule_end" placeholder="Enter an End Date" value={Filters.schedule_end || ""} onChange={(event) => setFilters({ ...Filters, schedule_end: event.target.value, searched: false })} />
                            <label className="text-muted" htmlFor="schedule_end">End Date</label>
                        </div>
                    </div>                    

                    <div className="col-auto">
                        <button 
                            className="btn mt-1 fw-bold col-auto border-primary border-3 float-end text-primary" 
                            onClick={() => handleSearchRecords()}
                            disabled={Filters.schedule_end === '' || Filters.schedule_start === ''}
                        >
                            Search
                        </button>
                    </div>
                </div>

                <div className="row mb-3">

                    {/* RESULTS COUNTER AND FILTERS */}
                    <div className="col h4 mb-1 pt-1">
                        
                        {/* {BaseState.filter === 'Active' && ' Active'}{BaseState.filter === 'Obsolete' && ' Obsolete'}
                        {` ${BaseState.ToolingTypes.filter((tt) => parseInt(tt.tooling_type_ID) === parseInt(BaseState.filter_tooling_type_ID))[0].tooling_type}`}
                        {` ${(BaseState.FilteredTooling.length === 1 && 'Tool') || 'Tools'}`}
                        {
                            BaseState.filter_text_searched !== '' &&
                            <span className=""> - Matching Search "{BaseState.filter_text_searched}"</span>
                        } */}
                    </div>

                </div>

                {/* TABLE AND RESULTS */}
                {
                    BaseState.searched && 
                    <table className="table table-striped">
                        
                        <thead>
                            <tr className="row">
                                <th className="col-2">Checksheet Due (Based on Production)</th>
                                <th className="col-2">Machine</th>
                                <th className="col-2">Part Number</th>
                                <th className="col-2">Shift Group</th>
                                <th className="col-2">Operator</th>
                                <th className="col-2">Checksheet / Control Plan</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* RESULTS ROWS */}
                            {
                                parseInt(SelectedChecksheet.part_ID) === 0 && BaseState.NewChecksheets.length > 0 &&
                                BaseState.NewChecksheets.map((cs) => {
                                    return (
                                        <tr key={cs.production_record_ID} className="row" onClick={() => handleChecksheetLoad(cs)}>
                                            <td className="col-2">{dayjs(cs.date_created).add(4, 'hours').format('M-D-YY h:mm a')}</td>
                                            <td className="col-2">{cs.machine_short_name}</td>
                                            <td className="col-2">{cs.part_number}</td>
                                            <td className="col-2">Shift {cs.shift_group}</td>
                                            <td className="col-2">{cs.first_name} {cs.last_name}</td>
                                            <td className="col-2">{cs.inspection_step}</td>
                                        </tr>
                                    )
                                })
                            }
                            {/* SINGLE RECORD AND CHECKSHEET DETAILS */}
                            {
                                parseInt(SelectedChecksheet.part_ID) > 0 && BaseState.NewChecksheets.length > 0 &&
                                <>
                                    {BaseState.NewChecksheets.filter((cs) => parseInt(cs.production_record_ID) === parseInt(SelectedChecksheet.production_record_ID)).map((cs) => {
                                        return (
                                            <tr className="row" onClick={() => handleChecksheetLoad(cs)}>
                                                <td className="col-2">{dayjs(cs.date_created).add(4, 'hours').format('M-D-YY h:mm a')}</td>
                                                <td className="col-2">{cs.machine_short_name}</td>
                                                <td className="col-2">{cs.part_number}</td>
                                                <td className="col-2">Shift {cs.shift_group}</td>
                                                <td className="col-2">{cs.first_name} {cs.last_name}</td>
                                                <td className="col-2">{cs.inspection_step}</td>
                                            </tr>
                                        )
                                    })}
                                    {
                                        SelectedChecksheet.control_plan_number !== '' &&
                                        <tr className="row">
                                            <td className="col-12">
                                                <div className="mt-2 p-3 border">
                                                <ChecksheetReviewComponent
                                                    SelectedChecksheet={SelectedChecksheet}
                                                    Machine={{ machine_name: SelectedChecksheet.machine_name, machine_short_name: SelectedChecksheet.machine_short_name, machine_ID: parseInt(SelectedChecksheet.machine_ID) }}
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </>
                            }
                            {/* NO RECORDS */}
                            {
                                BaseState.NewChecksheets.length === 0 &&
                                <tr className="row">
                                    <td className="col-12 p-0"><div className="alert alert-warning m-0 text-center">There are no Checksheets matching your filters.</div></td>
                                </tr>
                            }
                        </tbody>

                    </table>
                }
                

        </>
    )
}

export const ChecksheetReportComponent = compose(
	queryWithLoading({
		gqlString: GetChecksheetReportPage,
        name: "componentData"
	})
)(ChecksheetReportContent)
