import { gql } from '@apollo/client';

export const GetMachineStatusChanges = gql`
    query GetMachineStatusChanges($action: String, $machine_ID: Int, $machine_status_ID: Int) {
        machine_status_changes (action: $action, machine_ID: $machine_ID, machine_status_ID: $machine_status_ID) {
            machine_status_change_ID
            date_end
            date_start
            machine_ID
            machine_status_ID
            user_ID
        }
    }
`;

export const GetMachineStatusChange = gql`
    query GetMachineStatusChange {
        machine_status_change {
            machine_status_change_ID
            date_end
            date_start
            machine_ID
            machine_status_ID
            user_ID
        }
    }
`;

export const SaveMachineStatusChange = gql`
	mutation machineStatusChangeSave($input: MachineStatusChangeInput) {
		machineStatusChangeSave (input: $input) {
            MachineStatusChange {
                machine_status_change_ID
                date_end
                date_start
                machine_ID
                machine_status_ID
                user_ID
            }
		}
	}
`;