import React from 'react';
import moment from 'moment';
// import { Multiselect } from 'multiselect-react-dropdown';
import _ from 'lodash';
import {flowRight as compose} from 'lodash';
import { queryWithLoading } from '../utilities/NSDb.js';
import { useState } from 'react';
import dayjs from 'dayjs';
import { add } from 'mathjs';
import { useAuthUser } from 'react-auth-kit';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
import { useMutation, useLazyQuery } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// GRAPHQL QUERY
import { 
    GetTimeClockRecordsPage,
    GetTimeClockRecords,
    GetTimeClockRecordsActual,
    SaveTimeClockRecord,
    SaveTimeClockRecordActual,
    SaveTimeClockRecords,
    SaveTimeClockRecordScheduleDesignation
} from './time_clock_records_graphql.js';
import { parse } from 'graphql';


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// NOTES
// FILTERING AND FLAGS: Absent, Came Early, Late, Left Early, Left Late
//      When a TCR is updated by an employee clocking in, we do not flag those time_actuals with any of these flags initially in the db upon update because it is possible that a manager will update their TCR time_actual and nullify the flags.
//      Instead, we save these saves when a manager has marked the TCR requiring approval as being approved, and put them back to 0 if the TCR is unapproved. We calculate if each TCR should have a flag when we are building out the SelectedSchedule and EmployeesFitlered arrays.
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function TimeClockRecordsContent(props) {
    const auth = useAuthUser();
console.log({props})
    const { schedule_designations: ScheduleDesignations, shifts: Shifts, users_scheduled: Users, work_schedules: WorkSchedules } = props.componentData;
    // let FilterFlags = [ 
    //     { flag: 'Late', value: 'late' }, 
    //     { flag: 'Left Early', value: 'left_early' }, 
    //     { flag: 'Approved', value: 'approved' }, 
    //     { flag: 'Needs Approval', value: 'approver_ID' }, 
    //     { flag: 'Started Early', value: 'came_early' }, 
    //     { flag: 'Left Late', value: 'left_late' } ];

    // if ( parseInt(auth().User.user_ID) === 32 || parseInt(auth().User.user_ID) === 1 ) FilterFlags.push({ flag: 'Amat', value: 'Amat' }); FilterFlags.push({ flag: 'Dave', value: 'Dave' });

    let FilterFlags = {
        Amat: parseInt(auth().User.user_ID) === 37 ? 1 : 0,
        approved: 0,
        came_early: 0,
        Dave: parseInt(auth().User.user_ID) === 38 ? 1 : 0,
        late: 0,
        left_early: 0,
        left_late: 0,
        needs_approval: 0
    };

    const [save_time_clock_record] = useMutation(SaveTimeClockRecord);
    const [save_time_clock_record_actual] = useMutation(SaveTimeClockRecordActual);
    const [save_time_clock_records] = useMutation(SaveTimeClockRecords);
    const [save_time_clock_record_schedule_designation] = useMutation(SaveTimeClockRecordScheduleDesignation);
    const [get_time_clock_records ] = useLazyQuery(GetTimeClockRecords, { fetchPolicy: 'network-only'});
    const [get_time_clock_records_actual ] = useLazyQuery(GetTimeClockRecordsActual, { fetchPolicy: 'network-only'});

    const DefaultScheduleDesignation = {
        hours: '', 
        minutes: '',
        schedule_designation_ID: 0,
        time_clock_record_ID: 0,
        time_clock_record_schedule_designation_ID: 0
    };

    const DefaultTimeClockRecord = {
        time_clock_record_ID: 0,
        absent: 0,
        approved: 0,
        approver_ID: 0,
        came_early: 0,
        clockin_type_ID: 0,
        date_scheduled: '',
        date_scheduled_in: '',
        date_scheduled_out: '',
        late: 0,
        left_early: 0,
        left_late: 0,
        lunch_paid: '',
        note: '',
        shift_group: 0,
        shift_ID: 0,
        shift_name: '',
        time_scheduled_in: '',
        time_scheduled_out: '',
        time_scheduled_hours: '',
        time_actual_in: '',
        time_actual_out: '',
        time_actual_hours: '',
        time_pay_in: '',
        time_pay_out: '',
        time_pay_hours: '',
        user_ID: 0
    };

    const DefaultTCRA = {
        approved: null,
        approver_ID: null,
        archiver_ID: null,
        date_archived: null,
        time_actual_hours: null,
        time_actual_in: null,
        time_actual_out: null,
        time_clock_record_ID: 0,
        time_clock_records_actual_ID: 0,
        time_pay_hours: null,
        time_pay_in: null,
        time_pay_out: null,
        user_ID: 0
    };

    const DefaultSelectedTimeClockRecord = {
        time_clock_record_ID: 0,
        approved: 0,
        approver_ID: 0,
        clockin_type_ID: 0,
        date_scheduled: '',
        date_scheduled_in: null,
        date_scheduled_out: null,
        lunch_paid: '',
        note: '',
        save_button_text: 'Save',
        save_message: '',
        save_message_class: 'success',
        shift_group: 0,
        shift_ID: 0,
        shift_name: '',
        tcrn_creator: '',
        tcrn_creator_ID: 0,
        tcrn_date_created: '',
        tcrn_date_updated: '',
        tcrn_note: '',
        tcrn_note_original: '',
        tcrn_updater: '',
        tcrn_updater_ID: 0,
        time_clock_record_note_ID: 0,
        time_scheduled_in: '',
        time_scheduled_out: '',
        time_scheduled_hours: '',
        time_actual_in: '',
        time_actual_out: '',
        time_actual_hours: '',
        time_pay_in: '',
        time_pay_out: '',
        time_pay_hours: '',
        user_ID: 0,

        time_scheduled_in_hour: '-1',
        time_scheduled_in_minute: '-1',
        time_scheduled_in_am_pm: '',
        time_scheduled_out_hour: '-1',
        time_scheduled_out_minute: '-1',
        time_scheduled_out_am_pm: '',

        // time_actual_in_hour: '-1',
        // time_actual_in_minute: '-1',
        // time_actual_in_am_pm: '',
        // time_actual_out_hour: '-1',
        // time_actual_out_minute: '-1',
        // time_actual_out_am_pm: '',

        // time_pay_in_hour: '-1',
        // time_pay_in_minute: '-1',
        // time_pay_in_am_pm: '',
        // time_pay_out_hour: '-1',
        // time_pay_out_minute: '-1',
        // time_pay_out_am_pm: '',

        // for schedule designation
        ScheduleDesignation: DefaultScheduleDesignation

    };

    let temp_schedule_filter = handleScheduleFilter('this_week');

    const [SelectedSchedule, setSelectedSchedule] = useState({ Employees: [] });
    const [SelectedTimeClockRecord, setSelectedTimeClockRecord] = useState({ ...DefaultSelectedTimeClockRecord });
    const [BaseState, setBaseState] = useState({
        action: '',
        EmployeesFiltered: [],
        filter_date_range: 'this_week',
        filtered_results_count: 0,
        FilterFlags: FilterFlags,
        message: '',
        message_class: '',
        // schedule_end: '2023-10-14',
        // schedule_start: '2023-10-08',
        schedule_end: temp_schedule_filter.temp_schedule_end,
        schedule_start: temp_schedule_filter.temp_schedule_start,
        view: ''
    });

    // Handler function for changing filters
    function handleChangeFilter(name, value) {
        if ( name === 'filter_date_range' ) {
            let temp_schedule_filter = handleScheduleFilter(value);
            setBaseState({
                ...BaseState,
                schedule_end: temp_schedule_filter.temp_schedule_end,
                schedule_start: temp_schedule_filter.temp_schedule_start,
                action: '',
                filter_date_range: value,
                message: '',
                message_class: '',
                view: ''
            })
        }
    }

    // Take today's date and loop backwards until you get Sunday to use as your start day
    function handleScheduleFilter(filter) {
        let temp_weekday = dayjs().format('dddd');
        let temp_schedule_start = dayjs().format('YYYY-MM-DD');
        let temp_schedule_end = dayjs().add(6, 'day').format('YYYY-MM-DD');

        // Set this week's start and end
        if ( temp_weekday !== 'Sunday') {
            [...Array(6)].forEach((d, index) => {
                // If this is Sunday, set schedule_start
                if ( dayjs().add(-index, 'day').format('dddd') === 'Sunday' ) {
                    temp_schedule_start = dayjs().add(-index, 'day').format('YYYY-MM-DD');
                    temp_schedule_end = dayjs(temp_schedule_start).add(6, 'day').format('YYYY-MM-DD');
                }
            });
        }

        if ( filter === 'last_week' ) {
            temp_schedule_start = dayjs(temp_schedule_start).add(-7, 'day').format('YYYY-MM-DD');
            temp_schedule_end = dayjs(temp_schedule_end).add(-7, 'day').format('YYYY-MM-DD');
        } else if ( filter === 'next_week' ) {
            temp_schedule_start = dayjs(temp_schedule_start).add(7, 'day').format('YYYY-MM-DD');
            temp_schedule_end = dayjs(temp_schedule_end).add(7, 'day').format('YYYY-MM-DD');
        } else if ( filter === 'next_two_weeks' ) {
            temp_schedule_start = dayjs(temp_schedule_start).add(7, 'day').format('YYYY-MM-DD');
            temp_schedule_end = dayjs(temp_schedule_end).add(14, 'day').format('YYYY-MM-DD');
        }

        return { temp_schedule_end, temp_schedule_start };
    }

    // Save time_clock_record details
    async function saveTimeClockRecord(time_clock_record_ID) {
        let SaveData = {...SelectedTimeClockRecord};
        console.log("SAVE SelectedTimeClockRecord: ", SelectedTimeClockRecord)

        // Saving each time_clock_record part's hour, minute, AM/PM - needs to convert these into a single time variale
        let TimeClockRecordParts = ['time_scheduled_in', 'time_scheduled_out'];
        let TCRAParts = ['time_actual_in', 'time_actual_out', 'time_pay_in', 'time_pay_out'];
        let invalid_fields = '';
        let save_validated = true;

        // Time Scheduled In converted to date_scheduled_in
            // If each component of the time_clock_record's part is the default, then save it as null. 
            if ( SaveData.time_scheduled_in_hour === '-1' && SaveData.time_scheduled_in_minute === '-1' && SaveData.time_scheduled_in_am_pm === '' ) {
                SaveData.date_scheduled_in = null;
                // Remove the temp keys for hour, minute, am/pm from the SaveData object so it doesn't send those to the db
                SaveData = {..._.omit(SaveData, ['time_scheduled_in_hour', 'time_scheduled_in_minute', 'time_scheduled_in_am_pm'])};

            } 

            // If any of the part is the default, then add it to the invalid_fields list. DO NOT PROCEED WITH SAVING
            else if ( SaveData.time_scheduled_in_hour === '-1' || SaveData.time_scheduled_in_minute === '-1' || SaveData.time_scheduled_in_am_pm === '' ) {
                invalid_fields = invalid_fields === '' ? 'time_scheduled_in' : invalid_fields+','+'time_scheduled_in';
            } 

            // All parts are valid, create time
            else {
            
                // Create date time string to give to moment() to parse into a valid time
                SaveData.date_scheduled_in = dayjs(`${dayjs(SaveData.date_scheduled_in).format('YYYY-MM-DD')} ${SaveData.time_scheduled_in_hour}:${SaveData.time_scheduled_in_minute} ${SaveData.time_scheduled_in_am_pm}`).format('YYYY-MM-DD HH:mm:ss')
                //moment('01-01-2000 ' + SaveData.time_scheduled_in_hour`]+':'+SaveData[.time_scheduled_in_minute`]+':00 '+SaveData[.time_scheduled_in_am_pm`]).format('HH:mm:ss');

                // Remove the temp keys for hour, minute, am/pm from the SaveData object so it doesn't send those to the db
                SaveData = {..._.omit(SaveData, ['time_scheduled_in_hour', 'time_scheduled_in_minute', 'time_scheduled_in_am_pm'])};
            }

        // Time Scheduled Out converted to date_scheduled_out
            // If each component of the time_clock_record's part is the default, then save it as null. 
            if ( SaveData.time_scheduled_out_hour === '-1' && SaveData.time_scheduled_out_minute === '-1' && SaveData.time_scheduled_out_am_pm === '' ) {
                SaveData.date_scheduled_out = null;
                // Remove the temp keys for hour, minute, am/pm from the SaveData object so it doesn't send those to the db
                SaveData = {..._.omit(SaveData, ['time_scheduled_out_hour', 'time_scheduled_out_minute', 'time_scheduled_out_am_pm'])};

            } 

            // If any of the part is the default, then add it to the invalid_fields list. DO NOT PROCEED WITH SAVING
            else if ( SaveData.time_scheduled_out_hour === '-1' || SaveData.time_scheduled_out_minute === '-1' || SaveData.time_scheduled_out_am_pm === '' ) {
                invalid_fields = invalid_fields === '' ? 'time_scheduled_out' : invalid_fields+','+'time_scheduled_out';
            } 

            // All parts are valid, create time
            else {
                // Create date time string to give to moment() to parse into a valid time
                SaveData.date_scheduled_out = dayjs(`${dayjs(SaveData.date_scheduled_out).format('YYYY-MM-DD')} ${SaveData.time_scheduled_out_hour}:${SaveData.time_scheduled_out_minute} ${SaveData.time_scheduled_out_am_pm}`).format('YYYY-MM-DD HH:mm:ss')
                //moment('01-01-2000 ' + SaveData.time_scheduled_out_hour`]+':'+SaveData[.time_scheduled_out_minute`]+':00 '+SaveData[.time_scheduled_out_am_pm`]).format('HH:mm:ss');

                // Remove the temp keys for hour, minute, am/pm from the SaveData object so it doesn't send those to the db
                SaveData = {..._.omit(SaveData, ['time_scheduled_out_hour', 'time_scheduled_out_minute', 'time_scheduled_out_am_pm'])};
            }


        // For each TCRA, create the final time input
        SaveData.TCRAs = SaveData.TCRAs.map((sd) => {
            let temp_sd = {...sd}
            TCRAParts.forEach((sp) => {

                // If each component of the time_clock_records_actuals's part is the default, then save it as null. 
                if ( temp_sd[`${sp}_hour`] === '-1' && temp_sd[`${sp}_minute`] === '-1' && temp_sd[`${sp}_am_pm`] === '' ) {
                    temp_sd[sp] = null;
                    // Remove the temp keys for hour, minute, am/pm from the temp_sd object so it doesn't send those to the db
                    temp_sd = {..._.omit(temp_sd, [`${sp}_hour`, `${sp}_minute`, `${sp}_am_pm`])};

                } 

                // If any of the part is the default, then add it to the invalid_fields list. DO NOT PROCEED WITH SAVING
                else if ( temp_sd[`${sp}_hour`] === '-1' || temp_sd[`${sp}_minute`] === '-1' || temp_sd[`${sp}_am_pm`] === '' ) {
                    invalid_fields = invalid_fields === '' ? sp : invalid_fields+','+sp;
                } 

                // All parts are valid, create time
                else {
                
                    // TO DO: Account for a scenario where the date scheduled is 10p-6a, but the time_actual/pay_in was blank then the user enters 1am, so below gets the date piece of the date_scheduled_in at 10pm and applies that to the time_in at 1am and it shows they are 21 hours early.
                    
                    let temp_date = dayjs(temp_sd[sp]).format('YYYY-MM-DD');
                    
                    // Create date time string to give to save. If this was a newly created time by the user from a null time, then we will not have the date piece of the variable yet. If so, grab the date piece from the date_scheduled_in/out. If that doesn't exist, grab it from the date_scheduled.
                    if ( temp_date === 'Invalid Date' ) {
                        if ( sp === 'time_actual_in' || sp === 'time_pay_in' ) {
                            temp_date = dayjs(SaveData.date_scheduled_in).format('YYYY-MM-DD')
                            if ( temp_date === 'Invalid Date' ) {
                                temp_date = dayjs(SaveData.date_scheduled).format('YYYY-MM-DD')
                                if ( temp_date === 'Invalid Date' ) {
                                    invalid_fields = invalid_fields === '' ? `${sp} - no date scheduled` : invalid_fields+','+`${sp} - no date scheduled`;
                                }
                            }
                        } else if ( sp === 'time_actual_out' || sp === 'time_pay_out' ) {
                            temp_date = dayjs(SaveData.date_scheduled_out).format('YYYY-MM-DD')
                            if ( temp_date === 'Invalid Date' ) {
                                temp_date = dayjs(SaveData.date_scheduled).format('YYYY-MM-DD')
                                if ( temp_date === 'Invalid Date' ) {
                                    invalid_fields = invalid_fields === '' ? `${sp} - no date scheduled` : invalid_fields+','+`${sp} - no date scheduled`;
                                }
                            }
                        } 
                    }

                    temp_sd[sp] = dayjs(`${temp_date} ${temp_sd[`${sp}_hour`]+':'+temp_sd[`${sp}_minute`]+':00 '+temp_sd[`${sp}_am_pm`]}`).format('YYYY-MM-DD HH:mm:ss');

                    // Remove the temp keys for hour, minute, am/pm from the temp_sd object so it doesn't send those to the db
                    temp_sd = {..._.omit(temp_sd, [`${sp}_hour`, `${sp}_minute`, `${sp}_am_pm`])};

                }
            });
            
            return temp_sd;
        });
        
        // If there are invalid fields, fail validation and show error message with which fields failed
        if ( invalid_fields !== "" ) {
            setSelectedTimeClockRecord({ ...SelectedTimeClockRecord, save_button_text: 'Save', save_message: `Invalid time for ${invalid_fields}`, save_message_class: 'danger' });
        } 
        else if ( parseInt(time_clock_record_ID) === 0 && SelectedSchedule.Employees.filter((e) => parseInt(e.user_ID) === parseInt(SelectedTimeClockRecord.user_ID))[0].Schedule.findIndex((s) => dayjs(s.date_scheduled).isSame(SelectedTimeClockRecord.date_scheduled)) > -1) {
            save_validated = false;
            setSelectedTimeClockRecord({ ...SelectedTimeClockRecord, save_button_text: 'Save', save_message: `Already scheduled for this date`, save_message_class: 'danger' });
        } 
        else if ( parseInt(time_clock_record_ID) === 0 && ( SelectedTimeClockRecord.date_scheduled === '' || SelectedTimeClockRecord.date_scheduled === null ) ) {
            save_validated = false;
            setSelectedTimeClockRecord({ ...SelectedTimeClockRecord, save_button_text: 'Save', save_message: `Must select the New Shift's Date`, save_message_class: 'danger' });
        } 
        else if ( parseInt(SelectedTimeClockRecord.ScheduleDesignation.schedule_designation_ID) === 0 && ( (SelectedTimeClockRecord.ScheduleDesignation.hours !== '' && parseInt(SelectedTimeClockRecord.ScheduleDesignation.hours) !== 0) || (SelectedTimeClockRecord.ScheduleDesignation.minutes !== '' && parseInt(SelectedTimeClockRecord.ScheduleDesignation.minutes) !== 0) )) {
            save_validated = false;
            setSelectedTimeClockRecord({ ...SelectedTimeClockRecord, save_button_text: 'Save', save_message: `Cannot have Schedule Designation Hours / Minutes without a Schedule Designation selected`, save_message_class: 'danger' });
        }


        // If Actual In / Out is null but Pay In / Out is not, fail validation - Check for Each TCRA
        SaveData.TCRAs.forEach((tcra) => {

            if ( tcra.time_actual_in === null && tcra.time_pay_in !== null && parseInt(SaveData.ScheduleDesignation.schedule_designation_ID) === 0 ) {
                save_validated = false;
                setSelectedTimeClockRecord({ ...SelectedTimeClockRecord, save_button_text: 'Save', save_message: `Actual Start cannot be blank if Pay Start is not blank`, save_message_class: 'danger' });
            }
            else if ( tcra.time_actual_out === null && tcra.time_pay_out !== null && parseInt(SaveData.ScheduleDesignation.schedule_designation_ID) === 0 ) {
                save_validated = false;
                setSelectedTimeClockRecord({ ...SelectedTimeClockRecord, save_button_text: 'Save', save_message: `Actual End cannot be blank if Pay End is not blank`, save_message_class: 'danger' });
            }
        
        });
        
        // Validation passed - proceed with saving the time_clock_record
        if ( invalid_fields === '' && save_validated === true ) {
        
            // Parse string values that are IDs to be integers, saving stringified numbers to the database throws an error.
            let MakeIntegers = ['lunch_paid', 'shift_group', 'time_clock_record_ID'];
            MakeIntegers.forEach((mi) => SaveData[mi] = parseInt(SaveData[mi]));

            // Now set the hours value for actual / pay - for each TCRA
            let lunch_break_accounted_for = false;
            SaveData.TCRAs.forEach((tcra) => {
                if ( tcra.time_actual_in !== null && tcra.time_actual_out !== null ) {
                    let temp_time_actual_hours = dayjs(tcra.time_actual_out).diff(dayjs(tcra.time_actual_in), 'hour', true).toFixed(2);
                    tcra.time_actual_hours = temp_time_actual_hours < 0 ? add(temp_time_actual_hours, 24).toFixed(2) : temp_time_actual_hours;
                } else {
                    tcra.time_actual_hours = null;
                }
                if ( tcra.time_pay_in !== null && tcra.time_pay_out !== null ) {
                    let temp_time_pay_hours = dayjs(tcra.time_pay_out).diff(dayjs(tcra.time_pay_in), 'hour', true).toFixed(2);
                    tcra.time_pay_hours = temp_time_pay_hours < 0 ? add(temp_time_pay_hours, 24).toFixed(2) : temp_time_pay_hours;

                    // Subtract lunch 0.5 hours if unpaid lunch on shift
                    if ( lunch_break_accounted_for === false && (SaveData.lunch_paid !== null && parseInt(SaveData.lunch_paid) === 0) || (SaveData.lunch_paid === null && parseInt(SaveData.shifts_lunch_paid) === 0) ) {
                        tcra.time_pay_hours = add(tcra.time_pay_hours, -0.5).toFixed(2);
                        // Only take 0.5 hours for lunch on a single TCRA
                        lunch_break_accounted_for = true;
                    }

                } else {
                    SaveData.time_pay_hours = null;
                }
            });

            // Set hours for Scheduled time
            if ( SaveData.date_scheduled_in !== null && SaveData.date_scheduled_out !== null ) {
                let temp_time_scheduled_hours = dayjs(SaveData.date_scheduled_out).diff(dayjs(SaveData.date_scheduled_in), 'hour', true).toFixed(2);
                SaveData.time_scheduled_hours = temp_time_scheduled_hours < 0 ? add(temp_time_scheduled_hours, 24).toFixed(2) : temp_time_scheduled_hours;
            } else {
                SaveData.time_scheduled_hours = null;
            }

            // Remove save message and button text from SelectedTimeClockRecord object for saving
            SaveData = _.omit(SaveData, ['absent', 'came_early', 'first_name', 'hours', 'last_name', 'late', 'left_early', 'left_late', 'minutes', 'save_button_text','save_message','save_message_class', 'ScheduleDesignation', 'schedule_designation_ID', 'shift_name', 'shifts_lunch_paid', 'shifts_shift_group', 'time_clock_record_schedule_designation_ID']);
            
            // If this is creating a new TCR, add the creator_ID
            SaveData = parseInt(SaveData.time_clock_record_ID) === 0 ? { ...SaveData, creator_ID: parseInt(auth().User.user_ID) } : SaveData;

            const result = await save_time_clock_record({ variables: { input: { ...SaveData } }});
            console.log({result})

            // Update the TimeClockRecords - save returns an array of all TimeClockRecord objects
            if(result.data && result.data.timeClockRecordSave.TimeClockRecords) {

                // Save the Schedule Designation if there was one, and if there is not one Selected, delete any that were on this TCR
                const schedule_designation_result = await save_time_clock_record_schedule_designation({ variables: { input: { 
                    time_clock_record_ID: parseInt(result.data.timeClockRecordSave.TimeClockRecords[0].time_clock_record_ID), 
                    schedule_designation_ID: parseInt(SelectedTimeClockRecord.ScheduleDesignation.schedule_designation_ID), 
                    hours: SelectedTimeClockRecord.ScheduleDesignation.hours === '' ? 0 : parseInt(SelectedTimeClockRecord.ScheduleDesignation.hours),
                    minutes: SelectedTimeClockRecord.ScheduleDesignation.minutes === '' ? 0 : parseInt(SelectedTimeClockRecord.ScheduleDesignation.minutes)
                } }})

                let TempTimeClockRecordScheduleDesignation = schedule_designation_result.data.timeClockRecordScheduleDesignationSave.TimeClockRecordScheduleDesignation;
                let temp_save_message = 'TimeClockRecord successfully updated';
                let TempEmployeeSchedule = [];
                let TempSelectedTCRAs = SelectedTimeClockRecord.TCRAs;

                // If this was a create, update the SelectedTimeClockRecord object with the new time_clock_record_ID
                if(parseInt(SelectedTimeClockRecord.time_clock_record_ID) === 0) {

                    // Filter down the Employee in the SelectedSchedule, then replace the time_clock_record for this employee
                    TempEmployeeSchedule = BaseState.EmployeesFiltered.map((e) => {
                        // Time Clock Record for employee's updated schedule date
                        if ( parseInt(e.user_ID) === parseInt(SelectedTimeClockRecord.user_ID) ) {
                            let TempSchedule = [...e.Schedule];

                            // We save date_scheduled to the database column, but still use time_scheduled for the output UI
                            SaveData.time_scheduled_in = SaveData.date_scheduled_in === null ? "" : dayjs(SaveData.date_scheduled_in).format('YYYY-MM-DD HH:mm');
                            SaveData.time_scheduled_out = SaveData.date_scheduled_out === null ? "" : dayjs(SaveData.date_scheduled_out).format('YYYY-MM-DD HH:mm');
                            SaveData.time_scheduled_hours = SaveData.time_scheduled_hours === null ? "" : SaveData.time_scheduled_hours;

                            let TempTCRA = result.data.timeClockRecordSave.TimeClockRecords[0].TCRAs[0];

                            // Fix any null values need to be made blank. Since these are the true times that were saved to the database from the UI, we do not need to format them for UTC
                            TempTCRA.time_actual_in = TempTCRA.time_actual_in === null ? "" : TempTCRA.time_actual_in;
                            TempTCRA.time_actual_out = TempTCRA.time_actual_out === null ? "" : TempTCRA.time_actual_out;
                            TempTCRA.time_actual_hours = TempTCRA.time_actual_hours === null ? 0 : TempTCRA.time_actual_hours;

                            TempTCRA.time_pay_in = TempTCRA.time_pay_in === null ? "" : TempTCRA.time_pay_in;
                            TempTCRA.time_pay_out = TempTCRA.time_pay_out === null ? "" : TempTCRA.time_pay_out;
                            TempTCRA.time_pay_hours = TempTCRA.time_pay_hours === null ? 0 : TempTCRA.time_pay_hours;

                            // On TCR create, there can only ever be a single TCRA object
                            SaveData.TCRAs[0] = { ...SaveData.TCRAs[0], ...TempTCRA };

                            // Push the new TimeClockRecord object to the front of the TimeClockRecords array
                            TempSchedule.unshift({ ...DefaultTimeClockRecord, 
                                ...SaveData, 
                                time_clock_record_ID: result.data.timeClockRecordSave.TimeClockRecords[0].time_clock_record_ID, 
                                time_clock_record_note_ID: result.data.timeClockRecordSave.TimeClockRecords[0].time_clock_record_note_ID, 
                                ScheduleDesignation: TempTimeClockRecordScheduleDesignation === null ? DefaultScheduleDesignation : TempTimeClockRecordScheduleDesignation
                            });

                            // Add TCRA_ID to SelectedTimeClockRecord, even if we didnt create one, just add the 0 ID
                            TempSelectedTCRAs[0].time_clock_records_actual_ID = parseInt(TempTCRA.time_clock_records_actual_ID);
                            TempSelectedTCRAs[0].time_clock_record_ID = parseInt(TempTCRA.time_clock_record_ID);

                            return { ...e, Schedule: TempSchedule };
                        } else {
                            return e;
                        }
                    });

                    temp_save_message = 'TimeClockRecord successfully added';

                } 
                // TCR Updated
                else {

                    // Filter down the Employee in the SelectedSchedule, then replace the time_clock_record for this employee
                    TempEmployeeSchedule = BaseState.EmployeesFiltered.map((e) => {
                        // Time Clock Record for employee's updated schedule date
                        if ( parseInt(e.user_ID) === parseInt(SelectedTimeClockRecord.user_ID) ) {
                            let TempSchedule = e.Schedule.map((tcr) => {
                                if ( parseInt(tcr.time_clock_record_ID) === parseInt(SelectedTimeClockRecord.time_clock_record_ID) ) {

                                    // We save date_scheduled to the database column, but still use time_scheduled for the output UI
                                    SaveData.time_scheduled_in = SaveData.date_scheduled_in === null ? "" : dayjs(SaveData.date_scheduled_in).format('YYYY-MM-DD HH:mm');
                                    SaveData.time_scheduled_out = SaveData.date_scheduled_out === null ? "" : dayjs(SaveData.date_scheduled_out).format('YYYY-MM-DD HH:mm');
                                    SaveData.time_scheduled_hours = SaveData.time_scheduled_hours === null ? "" : SaveData.time_scheduled_hours;

                                    // Loop through TCRAs and any null values need to be made blank. Since these are the true times that were saved to the database from the UI, we do not need to format them for UTC
                                    let TempTCRAs = result.data.timeClockRecordSave.TimeClockRecords[0].TCRAs.map((tcra) => {

                                        tcra.time_actual_in = tcra.time_actual_in === null ? "" : tcra.time_actual_in;
                                        tcra.time_actual_out = tcra.time_actual_out === null ? "" : tcra.time_actual_out;
                                        tcra.time_actual_hours = tcra.time_actual_hours === null ? 0 : tcra.time_actual_hours;

                                        tcra.time_pay_in = tcra.time_pay_in === null ? "" : tcra.time_pay_in;
                                        tcra.time_pay_out = tcra.time_pay_out === null ? "" : tcra.time_pay_out;
                                        tcra.time_pay_hours = tcra.time_pay_hours === null ? 0 : tcra.time_pay_hours;

                                        // As we loop through here, if we find a tcra_ID that is not in the SelectedTCRAs, then update its ID. This only works until we allow adding TCRAs.
                                        let temp_index = TempSelectedTCRAs.findIndex((tst) => parseInt(tst.time_clock_records_actual_ID) === parseInt(tcra.time_clock_records_actual_ID) );
                                        if ( temp_index === -1 ) {
                                            TempSelectedTCRAs = TempSelectedTCRAs.map((tst) => {
                                                if ( parseInt(tst.time_clock_records_actual_ID) === 0 ) {
                                                    return { ...tst, time_clock_records_actual_ID: parseInt(tcra.time_clock_records_actual_ID), time_clock_record_ID: parseInt(tcra.time_clock_record_ID) };
                                                } else {
                                                    return tst;
                                                }
                                            })
                                        }

                                        return tcra;

                                    });

                                    return { 
                                        ...tcr, 
                                        ...SaveData, 
                                        TCRAs: TempTCRAs,
                                        time_clock_record_note_ID: result.data.timeClockRecordSave.TimeClockRecords[0].time_clock_record_note_ID, 
                                        ScheduleDesignation: TempTimeClockRecordScheduleDesignation === null ? DefaultScheduleDesignation : TempTimeClockRecordScheduleDesignation 
                                    };
                                } else {
                                    return tcr;
                                }
                            });
                            return { ...e, Schedule: TempSchedule };
                        } else {
                            return e;
                        }
                    
                    });
                }

                // Update the SelectedTimeClockRecord with the new time_clock_record_ID that was created
                setSelectedTimeClockRecord({
                    ...SelectedTimeClockRecord,
                    save_button_text: 'Saved',
                    save_message: temp_save_message,
                    save_message_class: 'success',
                    ScheduleDesignation: TempTimeClockRecordScheduleDesignation === null ? DefaultScheduleDesignation : TempTimeClockRecordScheduleDesignation,
                    TCRAs: TempSelectedTCRAs,
                    time_clock_record_ID: parseInt(SelectedTimeClockRecord.time_clock_record_ID) > 0 ? parseInt(SelectedTimeClockRecord.time_clock_record_ID) : result.data.timeClockRecordSave.TimeClockRecords[0].time_clock_record_ID,
                    time_clock_record_note_ID: result.data.timeClockRecordSave.TimeClockRecords[0].time_clock_record_note_ID
                });

                setBaseState({
                    ...BaseState,
                    EmployeesFiltered: [...TempEmployeeSchedule]
                });
            }
        }
    }

    // Handler function for form's changing inputs
    function changeInputValue(name, value, tcra_ID=0) {
        let TempChanges = {
            save_button_text: 'Save', 
            save_message: ''
        }

        console.log("Name: ", name, " | Value: ", value, " | ", tcra_ID)

        // If notes are being changed, then update the note related tracking fields
        if ( name === 'tcrn_note' ) {
            if ( SelectedTimeClockRecord.time_clock_record_note_ID === '' || SelectedTimeClockRecord.time_clock_record_note_ID === 0 || SelectedTimeClockRecord.time_clock_record_note_ID === null  ) {
                TempChanges = { ...TempChanges, tcrn_date_created: dayjs().format('YYYY-MM-DD HH:mm:ss'), tcrn_creator_ID: parseInt(auth().User.user_ID), tcrn_creator: `${auth().User.first_name} ${auth().User.last_name.charAt(0)}`};
            } else {
                TempChanges = { ...TempChanges, tcrn_date_updated: dayjs().format('YYYY-MM-DD HH:mm:ss'), tcrn_updater_ID: parseInt(auth().User.user_ID), tcrn_updater: `${auth().User.first_name} ${auth().User.last_name.charAt(0)}`};
            }
        }
        else if ( name === 'date_scheduled' ) {
            // Check if this user already has a date_scheduled matching this value for the date range that is currently selected
            if ( value !== '' ) {
                let Match = SelectedSchedule.Employees.filter((e) => parseInt(e.user_ID) === parseInt(SelectedTimeClockRecord.user_ID))[0].Schedule.findIndex((s) => dayjs(s.date_scheduled).isSame(value)) > -1;
                TempChanges = Match ? { ...TempChanges, save_message: 'Already scheduled for this date', save_message_class: 'danger' } : TempChanges;

                // Date Scheduled is only available for adding a new schedule TCR, and we enforce that it be the first option selected, so we can use this to put dates on all fields that need it.
                if ( Match === false && dayjs(value).format('YYYY-MM-DD') !== 'Invalid Date') {
                    TempChanges = { ...TempChanges, 
                                    date_scheduled_in: dayjs(`${value} ${SelectedTimeClockRecord.time_scheduled_in}`).format('YYYY-MM-DD HH:mm:ss'), 
                                    date_scheduled_out: dayjs(SelectedTimeClockRecord.time_scheduled_out).format('YYYY-MM-DD') === 'Invalid Date' ? dayjs(`${value} ${SelectedTimeClockRecord.time_scheduled_out}`).format('YYYY-MM-DD HH:mm:ss') : dayjs(`${value} ${dayjs(SelectedTimeClockRecord.time_scheduled_out).format('h:mm a')}`).add(1, 'day').format('YYYY-MM-DD HH:mm:ss'), 
                                }

                }
            }
        }
        // Handle Schedule Designation inputs
        else if ( name === 'schedule_designation_ID' || name === 'hours' || name === 'minutes') {
            // Only allow integers for hours and minutes
            if ( name === 'hours' || name === 'minutes' ) {
                value = value !== '' ? parseInt(value) : '';
                if( isNaN(value) ) value = '';
            }
            TempChanges = { save_button_text: 'Save', save_message_class: 'success', save_message: '', ScheduleDesignation: { ...SelectedTimeClockRecord.ScheduleDesignation, [name]: value }};
            
            if ( name === 'schedule_designation_ID' && parseInt(value) === 0 ) {
                TempChanges = { ...TempChanges, ScheduleDesignation: { ...TempChanges.ScheduleDesignation, hours: '', minutes: '' }};
            }
        }
        // If Scheduled End AM/PM is changed, check if the end is now before the start, and if so, add a day to the date_scheduled_end value
        else if ( name === 'time_scheduled_out_am_pm' ) {
        
            // if(TempShift.length === 1 && dayjs(`${temp_date_scheduled} ${TempShift[0].shift_start}`).format('a') === 'pm' && dayjs(`${temp_date_scheduled} ${TempShift[0].shift_end}`).format('a') === 'am') {
            //     temp_out = `${dayjs(`${temp_date_scheduled} ${TempShift[0].shift_end}`).add(1, 'day').format('M/D/YY h:mm a')}`;
            // }

            // let time_scheduled_in_hour = temp_in !== null && temp_in !== '' ? moment(temp_in, 'hh:mm a').format('hh') : '-1';
            // let time_scheduled_in_minute = temp_in !== null && temp_in !== '' ? moment(temp_in, 'hh:mm a').format('mm') : '-1';
            // let time_scheduled_in_am_pm = temp_in !== null && temp_in !== '' ? moment(temp_in, 'hh:mm a').format('A') : '';
            // let time_scheduled_out_hour = temp_out !== null && temp_out !== '' ? moment(temp_out, 'hh:mm a').format('hh') : '-1';
            // let time_scheduled_out_minute = temp_out !== null && temp_out !== '' ? moment(temp_out, 'hh:mm a').format('mm') : '-1';
            // let time_scheduled_out_am_pm = temp_out !== null && temp_out !== '' ? moment(temp_out, 'hh:mm a').format('A') : '';

        }

        // Handle TCRA actual / pay inputs. We had to take the [name]: value out of the top let TempChanges to work with TCRA, so it is in the else here
        if (    name === 'time_actual_in_minute'    || name === 'time_actual_in_hour'   || name === 'time_actual_in_am_pm' || 
                name === 'time_actual_out_minute'   || name === 'time_actual_out_hour'  || name === 'time_actual_out_am_pm' || 
                name === 'time_pay_in_minute'       || name === 'time_pay_in_hour'      || name === 'time_pay_in_am_pm' || 
                name === 'time_pay_out_minute'      || name === 'time_pay_out_hour'     || name === 'time_pay_out_am_pm' ) {

            // TO DO: Add checks that any changed values cannot overlap with other TCRA values

            // IF tcra_ID is 0, then there is only 1 tcra so we can just use use the first array
            if ( parseInt(tcra_ID) === 0 ) {
                let TCRAs = [...SelectedTimeClockRecord.TCRAs];
                TCRAs[0][name] = value;
                TempChanges = { TCRAs }
            } else {
                // Get the TCRA that we are updating and change its value
                let TempTCRAs = SelectedTimeClockRecord.TCRAs.map((tcra) => {
                    
                    if ( parseInt(tcra.time_clock_records_actual_ID) === parseInt(tcra_ID) ) {
                        return {...tcra, [name]: value, action: 'save'};
                    } else {
                        return tcra
                    }
                })
                TempChanges = { ...TempChanges, TCRAs: TempTCRAs };
            }
        } else {
            TempChanges = { ...TempChanges, [name]: value};
        }

        setSelectedTimeClockRecord({
            ...SelectedTimeClockRecord, 
            ...TempChanges
        });

    }

    // Handler function for all non-save buttons - Selected will always be the SelectedTimeClockRecord object of whatever is being clicked. 'Add' is the defaultSelectedTimeClockRecord object.
    async function handleButtonClick(action, Selected, tcra_ID) {

        if ( action === 'edit_time_clock_record' || action === 'close_time_clock_record') {
            let TempSelected = { ...Selected, save_button_text: 'Save', save_message: ''};
            if( action === 'edit_time_clock_record' ) {
                TempSelected = { ...TempSelected,
                    time_scheduled_in_hour: TempSelected.date_scheduled_in !== null && TempSelected.date_scheduled_in !== '' ? dayjs(TempSelected.date_scheduled_in).format('hh') : '-1',
                    time_scheduled_in_minute: TempSelected.date_scheduled_in !== null && TempSelected.date_scheduled_in !== '' ? dayjs(TempSelected.date_scheduled_in).format('mm') : '-1',
                    time_scheduled_in_am_pm: TempSelected.date_scheduled_in !== null && TempSelected.date_scheduled_in !== '' ? dayjs(TempSelected.date_scheduled_in).format('A') : '',
                    time_scheduled_out_hour: TempSelected.date_scheduled_out !== null && TempSelected.date_scheduled_out !== '' ? dayjs(TempSelected.date_scheduled_out).format('hh') : '-1',
                    time_scheduled_out_minute: TempSelected.date_scheduled_out !== null && TempSelected.date_scheduled_out !== '' ? dayjs(TempSelected.date_scheduled_out).format('mm') : '-1',
                    time_scheduled_out_am_pm: TempSelected.date_scheduled_out !== null && TempSelected.date_scheduled_out !== '' ? dayjs(TempSelected.date_scheduled_out).format('A') : '',

                    lunch_paid: TempSelected.lunch_paid !== null ? parseInt(TempSelected.lunch_paid) : parseInt(TempSelected.shifts_lunch_paid),
                    shift_group: TempSelected.shift_group !== null ? parseInt(TempSelected.shift_group) : parseInt(TempSelected.shifts_shift_group)
                }

                TempSelected.TCRAs = TempSelected.TCRAs.length > 0 ? TempSelected.TCRAs.map((tcra) => {
                    return {
                        ...tcra,
                        time_actual_in_hour: tcra.time_actual_in !== null && tcra.time_actual_in !== '' ? dayjs(tcra.time_actual_in).format('hh') : '-1',
                        time_actual_in_minute: tcra.time_actual_in !== null && tcra.time_actual_in !== '' ? dayjs(tcra.time_actual_in).format('mm') : '-1',
                        time_actual_in_am_pm: tcra.time_actual_in !== null && tcra.time_actual_in !== '' ? dayjs(tcra.time_actual_in).format('A') : '',
                        time_actual_out_hour: tcra.time_actual_out !== null && tcra.time_actual_out !== '' ? dayjs(tcra.time_actual_out).format('hh') : '-1',
                        time_actual_out_minute: tcra.time_actual_out !== null && tcra.time_actual_out !== '' ? dayjs(tcra.time_actual_out).format('mm') : '-1',
                        time_actual_out_am_pm: tcra.time_actual_out !== null && tcra.time_actual_out !== '' ? dayjs(tcra.time_actual_out).format('A') : '',

                        time_pay_in_hour: tcra.time_pay_in !== null && tcra.time_pay_in !== '' ? dayjs(tcra.time_pay_in).format('hh') : '-1',
                        time_pay_in_minute: tcra.time_pay_in !== null && tcra.time_pay_in !== '' ? dayjs(tcra.time_pay_in).format('mm') : '-1',
                        time_pay_in_am_pm: tcra.time_pay_in !== null && tcra.time_pay_in !== '' ? dayjs(tcra.time_pay_in).format('A') : '',
                        time_pay_out_hour: tcra.time_pay_out !== null && tcra.time_pay_out !== '' ? dayjs(tcra.time_pay_out).format('hh') : '-1',
                        time_pay_out_minute: tcra.time_pay_out !== null && tcra.time_pay_out !== '' ? dayjs(tcra.time_pay_out).format('mm') : '-1',
                        time_pay_out_am_pm: tcra.time_pay_out !== null && tcra.time_pay_out !== '' ? dayjs(tcra.time_pay_out).format('A') : '',
                    }
                }) : [{ 
                    ...DefaultTCRA,

                    time_actual_in_hour: '-1',
                    time_actual_in_minute: '-1',
                    time_actual_in_am_pm: '',
                    time_actual_out_hour: '-1',
                    time_actual_out_minute: '-1',
                    time_actual_out_am_pm: '',

                    time_pay_in_hour: '-1',
                    time_pay_in_minute: '-1',
                    time_pay_in_am_pm: '',
                    time_pay_out_hour: '-1',
                    time_pay_out_minute: '-1',
                    time_pay_out_am_pm: '',

                    time_clock_record_ID: parseInt(Selected.time_clock_record_ID),
                    user_ID: parseInt(Selected.user_ID)

                }]
            } 

            setSelectedTimeClockRecord({
                ...DefaultSelectedTimeClockRecord,
                ...TempSelected
            });

            setBaseState({
                ...BaseState,
                action: action
            })
        } 
        
        else if ( action === 'add_time_clock_record' ) {

            // "Selected" passed in only contains user_ID
            setBaseState({
                ...BaseState,
                action: action
            });

            // Grab matching schedule details for their first time_clock
            // Get this employee's Workschedule, from their Users db record's work_schedule_ID
            let TempWorkSchedule = WorkSchedules.filter((ws) => parseInt(ws.work_schedule_ID) === parseInt(Selected.work_schedule_ID));

            // Get the shift_ID for this employee's WorkSchedule, just use today as a base
            let temp_week_day = dayjs().format('dddd').toLowerCase();
            let temp_shift_ID = TempWorkSchedule.length > 0 ? parseInt(TempWorkSchedule[0][`${temp_week_day}1_shift_ID`]) : 0;

            // TO DO: Figure out a default shift_ID if this was created on a nonwork day, we just don't want shift_ID=0 on a TCR for production records. We could enforce selecting a workscheule or shift when adding TCRs.
            
            // Get all of the shift's details for setting the schedule start and end time
            let TempShift = temp_shift_ID > 0 ? Shifts.filter((shifts) => parseInt(shifts.shift_ID) === temp_shift_ID) : [];

            let temp_date_scheduled = dayjs().add(1, 'day').format('YYYY-MM-DD');
            // let temp_in = TempShift.length === 1 ? `${dayjs(`${temp_date_scheduled} ${TempShift[0].shift_start}`).format('hh:mm a')}` : '';
            // let temp_out = TempShift.length === 1 ? `${dayjs(`${temp_date_scheduled} ${TempShift[0].shift_end}`).format('hh:mm a')}` : '';
            // let time_scheduled_in_hour = temp_in !== null && temp_in !== '' ? moment(temp_in, 'hh:mm a').format('hh') : '-1';
            // let time_scheduled_in_minute = temp_in !== null && temp_in !== '' ? moment(temp_in, 'hh:mm a').format('mm') : '-1';
            // let time_scheduled_in_am_pm = temp_in !== null && temp_in !== '' ? moment(temp_in, 'hh:mm a').format('A') : '';
            // let time_scheduled_out_hour = temp_out !== null && temp_out !== '' ? moment(temp_out, 'hh:mm a').format('hh') : '-1';
            // let time_scheduled_out_minute = temp_out !== null && temp_out !== '' ? moment(temp_out, 'hh:mm a').format('mm') : '-1';
            // let time_scheduled_out_am_pm = temp_out !== null && temp_out !== '' ? moment(temp_out, 'hh:mm a').format('A') : '';
            let temp_in = '08:00 am';
            let temp_out = '05:00 pm';
            let time_scheduled_in_hour = '08';
            let time_scheduled_in_minute = '00';
            let time_scheduled_in_am_pm = 'AM';
            let time_scheduled_out_hour = '05';
            let time_scheduled_out_minute = '00';
            let time_scheduled_out_am_pm = 'PM';

            // If the shift ends in the AM and starts in the PM, then add a day to the date_scheduled for displaying in the Schedules table
            // if(TempShift.length === 1 && dayjs(`${temp_date_scheduled} ${TempShift[0].shift_start}`).format('a') === 'pm' && dayjs(`${temp_date_scheduled} ${TempShift[0].shift_end}`).format('a') === 'am') {
            //     temp_out = `${dayjs(`${temp_date_scheduled} ${TempShift[0].shift_end}`).add(1, 'day').format('M/D/YY h:mm a')}`;
            // }

            setSelectedTimeClockRecord({
                ...DefaultSelectedTimeClockRecord,
                date_scheduled: null,
                lunch_paid: TempShift.length === 1 && TempShift[0].lunch_paid !== null && TempShift[0].lunch_paid !== '' ? TempShift[0].lunch_paid : 0,
                shift_group: TempShift.length === 1 && TempShift[0].shift_group !== null && TempShift[0].shift_group !== '' ? TempShift[0].shift_group : 0,
                shift_ID: temp_shift_ID,
                shift_name: TempShift.length === 1 ? TempShift[0].shift_name : '',
                time_scheduled_hours: TempShift.length === 1 ? TempShift[0].shift_hours_worked : '',
                time_scheduled_in: temp_in !== '' ? temp_in : '', 
                time_scheduled_in_hour,
                time_scheduled_in_minute,
                time_scheduled_in_am_pm,
                time_scheduled_out: temp_out !== '' ? temp_out : '', 
                time_scheduled_out_hour,
                time_scheduled_out_minute,
                time_scheduled_out_am_pm,
                user_ID: parseInt(Selected.user_ID),
                TCRAs: [{ 
                    ...DefaultTCRA,

                    time_actual_in_hour: '-1',
                    time_actual_in_minute: '-1',
                    time_actual_in_am_pm: '',
                    time_actual_out_hour: '-1',
                    time_actual_out_minute: '-1',
                    time_actual_out_am_pm: '',

                    time_pay_in_hour: '-1',
                    time_pay_in_minute: '-1',
                    time_pay_in_am_pm: '',
                    time_pay_out_hour: '-1',
                    time_pay_out_minute: '-1',
                    time_pay_out_am_pm: '',

                    user_ID: parseInt(Selected.user_ID)

                }]
            });

        }

        else if ( action === 'set_pay_to_schedule_time') {
        
            // Set this TCR's time_pay_in/out equal to the time_scheduled_in/out
            let temp_time_pay_in = moment(Selected.time_scheduled_in, 'hh:mm:ss A').format('HH:mm:ss');
            let temp_time_pay_out = moment(Selected.time_scheduled_out, 'hh:mm:ss A').format('HH:mm:ss');
            
            const result = await save_time_clock_record({ variables: { input: { time_clock_record_ID: parseInt(Selected.time_clock_record_ID), time_pay_in: temp_time_pay_in, time_pay_out: temp_time_pay_out } }});

            // Update the TimeClockRecord
            if(result.data && result.data.timeClockRecordSave.TimeClockRecords) {
                // Filter down the Employee in the SelectedSchedule, then replace the time_clock_record for this employee
                let TempEmployeeSchedule = BaseState.EmployeesFiltered.map((e) => {

                    // Time Clock Record for employee's updated time pay
                    if ( parseInt(e.user_ID) === parseInt(Selected.user_ID) ) {
                        let TempSchedule = e.Schedule.map((tcr) => {
                            if ( parseInt(tcr.time_clock_record_ID) === parseInt(Selected.time_clock_record_ID) ) {
                                return { ...tcr, time_pay_in: moment(temp_time_pay_in, 'HH:mm:ss').format('h:mm a'), time_pay_out: moment(temp_time_pay_out, 'HH:mm:ss').format('h:mm a') };
                            } else {
                                return tcr;
                            }
                        });

                        return { ...e, Schedule: TempSchedule };
                    } else {
                        return e;
                    }
                });
                
                setBaseState({
                    ...BaseState,
                    EmployeesFiltered: [...TempEmployeeSchedule]
                });
            }
        } 
        // Actions from buttons in the SelectedTimeClockRecord form
        else if ( action === 'set_actual_start_to_schedule_time' || action === 'set_actual_end_to_schedule_time' || action === 'set_pay_start_to_schedule_time' || action === 'set_pay_end_to_schedule_time' || action === 'clear_actual_start' || action === 'clear_actual_end' || action === 'clear_pay_start' || action === 'clear_pay_end' || action === 'recalc_pay_in' || action === 'recalc_pay_out' ) {
            let temp_changes = {};

            if ( action === 'set_actual_start_to_schedule_time' ) temp_changes = { time_actual_in: SelectedTimeClockRecord.date_scheduled_in, time_actual_in_am_pm: SelectedTimeClockRecord.time_scheduled_in_am_pm, time_actual_in_hour: SelectedTimeClockRecord.time_scheduled_in_hour, time_actual_in_minute: SelectedTimeClockRecord.time_scheduled_in_minute, late: 0, came_early: 0 };
            else if ( action === 'set_actual_end_to_schedule_time' ) temp_changes = { time_actual_out: SelectedTimeClockRecord.date_scheduled_out, time_actual_out_am_pm: SelectedTimeClockRecord.time_scheduled_out_am_pm, time_actual_out_hour: SelectedTimeClockRecord.time_scheduled_out_hour, time_actual_out_minute: SelectedTimeClockRecord.time_scheduled_out_minute, left_early: 0, left_late: 0 };
            else if ( action === 'set_pay_start_to_schedule_time' ) temp_changes = { time_pay_in: SelectedTimeClockRecord.date_scheduled_in, time_pay_in_am_pm: SelectedTimeClockRecord.time_scheduled_in_am_pm, time_pay_in_hour: SelectedTimeClockRecord.time_scheduled_in_hour, time_pay_in_minute: SelectedTimeClockRecord.time_scheduled_in_minute };
            else if ( action === 'set_pay_end_to_schedule_time' ) temp_changes = { time_pay_out: SelectedTimeClockRecord.date_scheduled_out, time_pay_out_am_pm: SelectedTimeClockRecord.time_scheduled_out_am_pm, time_pay_out_hour: SelectedTimeClockRecord.time_scheduled_out_hour, time_pay_out_minute: SelectedTimeClockRecord.time_scheduled_out_minute };
            else if ( action === 'clear_actual_start' ) temp_changes = { time_actual_in_am_pm: '', time_actual_in_hour: '-1', time_actual_in_minute: '-1' };
            else if ( action === 'clear_actual_end' ) temp_changes = { time_actual_out_am_pm: '', time_actual_out_hour: '-1', time_actual_out_minute: '-1' };
            else if ( action === 'clear_pay_start' ) temp_changes = { time_pay_in_am_pm: '', time_pay_in_hour: '-1', time_pay_in_minute: '-1' };
            else if ( action === 'clear_pay_end' ) temp_changes = { time_pay_out_am_pm: '', time_pay_out_hour: '-1', time_pay_out_minute: '-1' };
            
            // Edit shift's form - Pay time's "Recalc" button clicked, update the Pay in / out based on which button is clicked
            else if ( action === 'recalc_pay_in' ) {

                // Get the TCRA that this was clicked on
                let temp_index = SelectedTimeClockRecord.TCRAs.findIndex((tcra) => parseInt(tcra.time_clock_records_actual_ID) === parseInt(tcra_ID) );

                // Create a time from the time parts - If any of the part is the default, do not update Pay In 
                if ( temp_index === -1 || SelectedTimeClockRecord.TCRAs[temp_index].time_actual_in_hour === '-1' || SelectedTimeClockRecord.TCRAs[temp_index].time_actual_in_minute === '-1' || SelectedTimeClockRecord.TCRAs[temp_index].time_actual_in_am_pm === '' ) {
                    temp_changes = { 
                        save_button_text: 'Save',
                        save_message: `Invalid time for Actual Time In`,
                        save_message_class: 'danger'
                    } 
                }

                // All parts are valid, create time
                else {
                
                    // Create date time string to give to moment() to parse into a valid time
                    let temp_time_actual_in = moment('01-01-2020 ' + SelectedTimeClockRecord.TCRAs[temp_index].time_actual_in_hour+':'+SelectedTimeClockRecord.TCRAs[temp_index].time_actual_in_minute+':00 '+SelectedTimeClockRecord.TCRAs[temp_index].time_actual_in_am_pm).format('HH:mm:ss');
                    let temp_time_scheduled_in = moment('01-01-2020 ' + SelectedTimeClockRecord.time_scheduled_in_hour+':'+SelectedTimeClockRecord.time_scheduled_in_minute+':00 '+SelectedTimeClockRecord.time_scheduled_in_am_pm).format('HH:mm:ss');
                    let temp_pay_in_diff = dayjs(`01-01-2020 ${temp_time_scheduled_in}`).diff(dayjs(`${dayjs(`01-01-2020 ${temp_time_actual_in}`).format('YYYY-MM-DD HH:mm')}`), 'minute', true);
                    
                    let temp_time_pay_in = temp_pay_in_diff > 10 ? dayjs(`01-01-2020 ${temp_time_actual_in}`).format('HH:mm') : dayjs(`01-01-2020 ${temp_time_scheduled_in}`).format('HH:mm');
                    temp_time_pay_in = temp_pay_in_diff < 0 ? dayjs(`01-01-2020 ${temp_time_actual_in}`).format('HH:mm') : temp_time_pay_in;
                    // TO DO: Grab actual date if there is one and set pay date to it
                    temp_changes = { time_pay_in_am_pm: dayjs(`01-01-2020 ${temp_time_pay_in}`).format('A'), time_pay_in_hour: dayjs(`01-01-2020 ${temp_time_pay_in}`).format('hh'), time_pay_in_minute: dayjs(`01-01-2020 ${temp_time_pay_in}`).format('mm') }

                    // Put new changes into the appropriate TCRA
                    let TempTCRAs = SelectedTimeClockRecord.TCRAs.map((tcra) => {
                        if ( parseInt(tcra.time_clock_records_actual_ID) === parseInt(tcra_ID) ) {
                            return {...tcra, ...temp_changes};
                        } else {
                            return tcra
                        }
                    })
                    temp_changes = { TCRAs: TempTCRAs, save_message: '', save_message_class: 'success' };

                }
            }
            else if ( action === 'recalc_pay_out' ) {

                // Get the TCRA that this was clicked on
                let temp_index = SelectedTimeClockRecord.TCRAs.findIndex((tcra) => parseInt(tcra.time_clock_records_actual_ID) === parseInt(tcra_ID) );

                // Create a time from the time parts - If any of the part is the default, do not update Pay Out
                if ( temp_index === -1 || SelectedTimeClockRecord.TCRAs[temp_index].time_actual_out_hour === '-1' || SelectedTimeClockRecord.TCRAs[temp_index].time_actual_out_minute === '-1' || SelectedTimeClockRecord.TCRAs[temp_index].time_actual_out_am_pm === '' ) {
                    temp_changes = { 
                        save_button_text: 'Save',
                        save_message: `Invalid time for Actual Time Out`,
                        save_message_class: 'danger'
                    } 
                }
                // All parts are valid, create time
                else {
                
                    // Create date time string to give to moment() to parse into a valid time
                    let temp_time_actual_out = moment('01-01-2020 ' + SelectedTimeClockRecord.TCRAs[temp_index].time_actual_out_hour+':'+SelectedTimeClockRecord.TCRAs[temp_index].time_actual_out_minute+':00 '+SelectedTimeClockRecord.TCRAs[temp_index].time_actual_out_am_pm).format('HH:mm:ss');
                    let temp_time_scheduled_out = moment('01-01-2020 ' + SelectedTimeClockRecord.time_scheduled_out_hour+':'+SelectedTimeClockRecord.time_scheduled_out_minute+':00 '+SelectedTimeClockRecord.time_scheduled_out_am_pm).format('HH:mm:ss');
                    let temp_pay_out_diff = dayjs(`01-01-2020 ${temp_time_actual_out}`).diff(dayjs(`${dayjs(`01-01-2020 ${temp_time_scheduled_out}`).format('YYYY-MM-DD HH:mm')}`), 'minute', true);

                    let temp_time_pay_out = temp_pay_out_diff > 10 ? dayjs(`01-01-2020 ${temp_time_actual_out}`).format('HH:mm') : dayjs(`01-01-2020 ${temp_time_scheduled_out}`).format('HH:mm');
                    temp_time_pay_out = temp_pay_out_diff < 0 ? dayjs(`01-01-2020 ${temp_time_actual_out}`).format('HH:mm') : temp_time_pay_out;
                    // TO DO: Grab actual date if there is one and set pay date to it
                    temp_changes = { time_pay_out_am_pm: dayjs(`01-01-2020 ${temp_time_pay_out}`).format('A'), time_pay_out_hour: dayjs(`01-01-2020 ${temp_time_pay_out}`).format('hh'), time_pay_out_minute: dayjs(`01-01-2020 ${temp_time_pay_out}`).format('mm') }

                    // Put new changes into the appropriate TCRA
                    let TempTCRAs = SelectedTimeClockRecord.TCRAs.map((tcra) => {
                        if ( parseInt(tcra.time_clock_records_actual_ID) === parseInt(tcra_ID) ) {
                            return {...tcra, ...temp_changes};
                        } else {
                            return tcra
                        }
                    })
                    temp_changes = { TCRAs: TempTCRAs, save_message: '', save_message_class: 'success' };

                }
            }

            // If we cleared a time entry, update the correct TCRA with the determined temp_changes above
            if ( action === 'set_actual_start_to_schedule_time' || action === 'set_actual_end_to_schedule_time' || action === 'set_pay_start_to_schedule_time' || action === 'set_pay_end_to_schedule_time' || action === 'clear_actual_start' || action === 'clear_actual_end' || action === 'clear_pay_start' || action === 'clear_pay_end' ) {
            
                // If there is not a TCRA for this TCR yet, create a default one
                let TempTCRAs = Selected.TCRAs.map((tcra) => {
                    if ( parseInt(tcra.time_clock_records_actual_ID) === parseInt(tcra_ID) ) {
                        return {...tcra, ...temp_changes};
                    } else {
                        return tcra
                    }
                })
                temp_changes = { TCRAs: TempTCRAs };
            }


            setSelectedTimeClockRecord({
                ...SelectedTimeClockRecord,
                save_button_text: 'Save',
                save_message: '',
                save_message_class: 'success',
                ...temp_changes
            })
        }
    }

    // Reuseable function for hour, minutes. am/pm time values
    function TimeInput(name, tcra_ID=0) {
        let Temp = SelectedTimeClockRecord;
        // If this is a input for something in TCRA, then we need to grab the correct record from it.
        if ( name === 'time_actual_in' || name === 'time_actual_out' || name === 'time_pay_in' || name === 'time_pay_out' ) {
            Temp = Temp.TCRAs.filter((tcra) => parseInt(tcra.time_clock_records_actual_ID) === parseInt(tcra_ID))[0];
        }
        return (
            <div className="input-group">
                <select 
                    className="form-select" 
                    disabled={SelectedTimeClockRecord.date_scheduled === null || SelectedTimeClockRecord.date_scheduled === ''}
                    onChange={(event) => changeInputValue(`${name}_hour`, event.target.value, parseInt(tcra_ID))}
                    value={Temp[`${name}_hour`]}
                >
                    <option value="-1">--</option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                </select> 
                <select 
                    className="form-select" 
                    disabled={SelectedTimeClockRecord.date_scheduled === null || SelectedTimeClockRecord.date_scheduled === ''}
                    onChange={(event) => changeInputValue(`${name}_minute`, event.target.value, parseInt(tcra_ID))}
                    value={Temp[`${name}_minute`]}
                >
                    <option value="-1">--</option>
                    <option value="00">00</option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                    <option value="32">32</option>
                    <option value="33">33</option>
                    <option value="34">34</option>
                    <option value="35">35</option>
                    <option value="36">36</option>
                    <option value="37">37</option>
                    <option value="38">38</option>
                    <option value="39">39</option>
                    <option value="40">40</option>
                    <option value="41">41</option>
                    <option value="42">42</option>
                    <option value="43">43</option>
                    <option value="44">44</option>
                    <option value="45">45</option>
                    <option value="46">46</option>
                    <option value="47">47</option>
                    <option value="48">48</option>
                    <option value="49">49</option>
                    <option value="50">50</option>
                    <option value="51">51</option>
                    <option value="52">52</option>
                    <option value="53">53</option>
                    <option value="54">54</option>
                    <option value="55">55</option>
                    <option value="56">56</option>
                    <option value="57">57</option>
                    <option value="58">58</option>
                    <option value="59">59</option>
                </select>
                <select 
                    className="form-select" 
                    disabled={SelectedTimeClockRecord.date_scheduled === null || SelectedTimeClockRecord.date_scheduled === ''}
                    onChange={(event) => changeInputValue(`${name}_am_pm`, event.target.value, parseInt(tcra_ID))}
                    value={Temp[`${name}_am_pm`]}
                >
                    <option value="">--</option>
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                </select>
            </div>
        )
    }

    // Take the start and end dates and create a SelectedSchedule for each employee - this is the table of displayed shifts only, this function does not save anything
    async function handleGenerateSchedule(view) {
        // Get all Timeclock Records that match the filters of the Schedule created
        const GetTimeClockRecords = await get_time_clock_records({ variables: { date_scheduled_end: BaseState.schedule_end, date_scheduled_start: BaseState.schedule_start, active: 1 } });
        console.log({GetTimeClockRecords})
        if (GetTimeClockRecords.data && GetTimeClockRecords.data.time_clock_records) {

            let TCR_IDs = GetTimeClockRecords.data.time_clock_records.length > 0 ? GetTimeClockRecords.data.time_clock_records.map((tcr) => parseInt(tcr.time_clock_record_ID)).join() : '';

            // Get all Time Clock Records Actual that match the filter dates
            const GetTimeClockRecordsActual = await get_time_clock_records_actual({ variables: { date_scheduled_end: BaseState.schedule_end, date_scheduled_start: BaseState.schedule_start, active: 1 } });
            console.log({GetTimeClockRecordsActual})
            // First Create array of employees to schedule
            let TempSchedule = [ ...Users ];

            // Number of days of schedule to build out:
            let days = dayjs(BaseState.schedule_end).diff(dayjs(BaseState.schedule_start), 'day')+1;

            // Add Schedule of 'days' for each employee
            TempSchedule = TempSchedule.map((s) => {

                // As we loop through the filters' dates next, get the filtered array of TimeClockRecords for this user so we can compare against that smaller array each time in the loop below
                let TempUserTimeClockRecords = [];
                if ( GetTimeClockRecords.data && GetTimeClockRecords.data.time_clock_records && GetTimeClockRecords.data.time_clock_records.length > 0 ) {
                    TempUserTimeClockRecords = GetTimeClockRecords.data.time_clock_records.filter((tcr) => parseInt(tcr.user_ID) === parseInt(s.user_ID) );
                }

                // Filter TCRA down to just this user
                let TempUserTCRA = [];
                if ( GetTimeClockRecordsActual.data && GetTimeClockRecordsActual.data.time_clock_records_actual && GetTimeClockRecordsActual.data.time_clock_records_actual.length > 0 ) {
                    TempUserTCRA = GetTimeClockRecordsActual.data.time_clock_records_actual.filter((tcra) => parseInt(tcra.user_ID) === parseInt(s.user_ID) );
                }
                // Map over the TCRs and add in the TCRAs array to a new key
                TempUserTimeClockRecords = TempUserTimeClockRecords.map((tcr) => {
                    let TempTCRAs = TempUserTCRA.filter((tcra) => parseInt(tcra.time_clock_record_ID) === parseInt(tcr.time_clock_record_ID));
                    TempTCRAs = TempTCRAs.length > 0 ? TempTCRAs : [];
                    return { ... tcr, TCRAs: TempTCRAs };
                })

                // Create an array of the days choosen
                let TempUserDays = [];
                [...Array(days)].forEach((d, index) => {

                    // This is the date of the selected range of the filter
                    let temp_date_scheduled = dayjs(BaseState.schedule_start).add(index, 'day').format('YYYY-MM-DD')
                    

                    // TO DO: If there are multiple TCR for a single day, we need to filter these down to those multiple records


                    // If there is already a TimeClockRecord for this user for this date, add that data to the return object here
                    let TempTCRsDay = TempUserTimeClockRecords.filter((tcr) => dayjs(tcr.date_scheduled).isSame(dayjs(BaseState.schedule_start).add(index, 'day')));

                    // TimeClockRecord(s) already exists for this day, use that
                    if ( TempTCRsDay.length > 0 ) {

                        // Push each TCR to the TempUserDays array, which goes into the Schedule object for each User
                        TempTCRsDay.forEach((tcr) => {
                            let current_TCR = tcr;
                            let has_time_actual_in = false;

                            // Handle time variables for each of the TCRAs
                            current_TCR.TCRAs = current_TCR.TCRAs.map((tcra) => {
                                let temp_tcra = { ...tcra };
                                // Set Time Actual values
                                temp_tcra.time_actual_in = temp_tcra.time_actual_in !== '' && temp_tcra.time_actual_in !== null ? dayjs(`${temp_tcra.time_actual_in}`).format('YYYY-M-D h:mm a') : '';
                                
                                // Use for determining absent - if there is any TCRA with non-blank time_actual_in, they were not absent for this shift.
                                has_time_actual_in = temp_tcra.time_actual_in !== '' ? true : has_time_actual_in;

                                temp_tcra.time_actual_out = temp_tcra.time_actual_out !== '' && temp_tcra.time_actual_out !== null ? dayjs(`${temp_tcra.time_actual_out}`).format('YYYY-M-D h:mm a') : ''; 
                                // Use the database's hours record if there is one
                                temp_tcra.time_actual_hours = temp_tcra.time_actual_hours !== '' && temp_tcra.time_actual_hours !== null ? temp_tcra.time_actual_hours : '';
                                temp_tcra.time_actual_hours = temp_tcra.time_actual_hours === '' && temp_tcra.time_actual_in !== '' && temp_tcra.time_actual_out !== '' ?  dayjs(`${temp_tcra.time_actual_out}`).diff(dayjs(`${temp_tcra.time_actual_in}`), 'hour', true).toFixed(2) : temp_tcra.time_actual_hours;
                                // Account for overnight shifts - Leave this for now, but once temp_tcra is working, this line can go away
                                temp_tcra.time_actual_hours = temp_tcra.time_actual_hours < 0 ? add(temp_tcra.time_actual_hours, 24).toFixed(2) : temp_tcra.time_actual_hours;

                                // Set Time Pay values
                                temp_tcra.time_pay_in = temp_tcra.time_pay_in !== '' && temp_tcra.time_pay_in !== null ? dayjs(`${temp_tcra.time_pay_in}`).format('YYYY-M-D h:mm a') : '';
                                temp_tcra.time_pay_out = temp_tcra.time_pay_out !== '' && temp_tcra.time_pay_out !== null ? dayjs(`${temp_tcra.time_pay_out}`).format('YYYY-M-D h:mm a') : '';
                                // Use the database's hours record if there is one
                                temp_tcra.time_pay_hours = temp_tcra.time_pay_hours !== '' && temp_tcra.time_pay_hours !== null ? temp_tcra.time_pay_hours : '';
                                temp_tcra.time_pay_hours = temp_tcra.time_pay_hours === '' && temp_tcra.time_pay_in !== '' && temp_tcra.time_pay_out !== '' ?  dayjs(`${temp_tcra.time_pay_out}`).diff(dayjs(`${temp_tcra.time_pay_in}`), 'hour', true).toFixed(2) : temp_tcra.time_pay_hours;
                                // Account for overnight shifts - Leave this for now, but once temp_tcra is working, this line can go away
                                temp_tcra.time_pay_hours = temp_tcra.time_pay_hours < 0 ? add(temp_tcra.time_pay_hours, 24).toFixed(2) : temp_tcra.time_pay_hours;
                                
                                // Temporary fix for calculating pay hours 
                                /// Compare date_schdeuled in to actual in, if more than 10 minutes before shift, pay is set to actual, otherwise pay = scheduled
                                // If we have a value saved in the db, use that instead of calculating it from the actual times in/out

                                temp_tcra.time_pay_in = temp_tcra.time_pay_in !== '' && temp_tcra.time_pay_in !== null ? temp_tcra.time_pay_in : '';
                                // Positive temp_pay_in_diff means getting in before scheduled, negative means they're late. 
                                let temp_pay_in_diff = dayjs(`${current_TCR.date_scheduled_in}`).diff(dayjs(`${dayjs(`${temp_tcra.time_actual_in}`).format('YYYY-MM-DD HH:mm')}`), 'minute', true);
                                if(temp_tcra.time_pay_in === '' && temp_tcra.time_actual_in !== '') {
                                    temp_tcra.time_pay_in = temp_pay_in_diff > 10 ? dayjs(`${temp_tcra.time_actual_in}`).format('YYYY-M-D h:mm a') : dayjs(`${current_TCR.date_scheduled_in}`).format('YYYY-M-D h:mm a');
                                    temp_tcra.time_pay_in = temp_pay_in_diff < 0 ? dayjs(`${temp_tcra.time_actual_in}`).format('YYYY-M-D h:mm a') : temp_tcra.time_pay_in;
                                }

                                temp_tcra.time_pay_out = temp_tcra.time_pay_out !== '' && temp_tcra.time_pay_out !== null ? temp_tcra.time_pay_out : '';
                                // positive temp_pay_out_diff means getting in before scheduled, negative means they're late. 
                                let temp_pay_out_diff = dayjs(`${dayjs(`${temp_tcra.time_actual_out}`).format('YYYY-MM-DD HH:mm')}`).diff(dayjs(`${current_TCR.date_scheduled_out}`), 'minute', true);
                                if(temp_tcra.time_pay_out === '' && temp_tcra.time_actual_out !== '') {
                                    temp_tcra.time_pay_out = temp_pay_out_diff > 10 ? dayjs(`${temp_tcra.time_actual_out}`).format('YYYY-M-D h:mm a') : dayjs(`${current_TCR.time_scheduled_out}`).format('YYYY-M-D h:mm a');
                                    temp_tcra.time_pay_out = temp_pay_out_diff < 0 ? dayjs(`${temp_tcra.time_actual_out}`).format('YYYY-M-D h:mm a') : temp_tcra.time_pay_out;
                                }

                                temp_tcra.time_pay_hours = temp_tcra.time_pay_hours !== '' && temp_tcra.time_pay_hours !== null ? temp_tcra.time_pay_hours : '';
                                // Calculate time_pay_hours if it is not in the db
                                if(temp_tcra.time_pay_hours === '' && temp_tcra.time_pay_in !== '' && temp_tcra.time_pay_out !== '') {
                                    temp_tcra.time_pay_hours = dayjs(`${temp_tcra.time_pay_out}`).diff(dayjs(`${temp_tcra.time_pay_in}`), 'hour', true).toFixed(2);
                                    temp_tcra.time_pay_hours = temp_tcra.time_pay_hours < 0 ? add(temp_tcra.time_pay_hours, 24).toFixed(2) : temp_tcra.time_pay_hours;
                                    // Subtract lunch 0.5 hours if unpaid lunch on shift
                                    temp_tcra.time_pay_hours = (current_TCR.lunch_paid !== null && parseInt(current_TCR.lunch_paid) === 0) || (current_TCR.lunch_paid === null && parseInt(current_TCR.shifts_lunch_paid) === 0) ? add(temp_tcra.time_pay_hours, -0.5).toFixed(2) : temp_tcra.time_pay_hours;
                                }

                                // LEFT OFF: Look at notes from Mary - Calculate if this TCRA is absent, came_early, late, left_early, left_late.
                                if ( parseInt(tcra.user_ID) === 5 ) {
                                    console.log("TCRA ID: ", parseInt(tcra.time_clock_records_actual_ID));
                                    console.log("Time Actual Out: ", temp_tcra.time_actual_out, "Time Actual In: ", temp_tcra.time_actual_in, " | Absent: ", dayjs(current_TCR.date_scheduled).diff(dayjs(), 'day') < 0 && temp_tcra.time_actual_in === '' && parseInt(current_TCR.schedule_designation_ID) !== 5 ? 1 : 0)
                                    console.log("PayIn Diff: ", temp_pay_in_diff, " | Early: ", temp_pay_in_diff > 10 ? 1 : 0, " | Late: ", temp_pay_in_diff < 0 ? 1 : 0)
                                    console.log("PayOut Diff: ", temp_pay_out_diff, " | Left Early: ", temp_pay_out_diff < 0 ? 1 : 0, " | Late: ", temp_pay_out_diff > 10 ? 1 : 0)
                                }
                                temp_tcra.came_early = temp_pay_in_diff > 10 ? 1 : 0;
                                temp_tcra.late = temp_pay_in_diff < 0 ? 1 : 0;
                                temp_tcra.left_early = temp_pay_out_diff < 0 ? 1 : 0;
                                temp_tcra.left_late = temp_pay_out_diff > 10 ? 1 : 0;

                                return temp_tcra;
                            });

                            // If the time_clock_record has lunch_paid or shift_group on it, use that, otherwise get it from the shift_ID
                            TempUserDays.push({ 
                                ...current_TCR,
                                absent: dayjs(current_TCR.date_scheduled).diff(dayjs(), 'day') < 0 && has_time_actual_in === false && parseInt(current_TCR.schedule_designation_ID) !== 5 ? 1 : 0,
                                came_early: 0,
                                late: 0,
                                left_early: 0,
                                left_late: 0,
                                // came_early: temp_pay_in_diff > 10 ? 1 : 0,
                                // late: temp_pay_in_diff < 0 ? 1 : 0,
                                // left_early: temp_pay_out_diff < 0 ? 1 : 0,
                                // left_late: temp_pay_out_diff > 10 ? 1 : 0,
                                ScheduleDesignation: {
                                    hours: current_TCR.hours === null ? '' : current_TCR.hours, 
                                    minutes: current_TCR.minutes === null ? '' : current_TCR.minutes,
                                    schedule_designation_ID: current_TCR.schedule_designation_ID === null ? 0 : current_TCR.schedule_designation_ID,
                                    time_clock_record_ID: current_TCR.time_clock_record_ID === null ? 0 : current_TCR.time_clock_record_ID,
                                    time_clock_record_schedule_designation_ID: current_TCR.time_clock_record_schedule_designation_ID === null ? 0 : current_TCR.time_clock_record_schedule_designation_ID
                                },
                                time_scheduled_hours: current_TCR.time_scheduled_hours !== null ? current_TCR.time_scheduled_hours : '',
                                time_scheduled_in: current_TCR.time_scheduled_in !== null ? dayjs(`${current_TCR.date_scheduled} ${current_TCR.time_scheduled_in}`).format('h:mm a') : '', 
                                time_scheduled_out: current_TCR.time_scheduled_out !== null ? dayjs(`${current_TCR.date_scheduled} ${current_TCR.time_scheduled_out}`).format('h:mm a') : '',
                                date_scheduled_in: current_TCR.date_scheduled_in !== null ? dayjs(`${current_TCR.date_scheduled_in}`).format('YYYY-MM-DD HH:mm') : '', 
                                date_scheduled_out: current_TCR.date_scheduled_out !== null ? dayjs(`${current_TCR.date_scheduled_out}`).format('YYYY-MM-DD HH:mm') : '',
                                time_actual_in: '', 
                                time_actual_out: '', 
                                time_actual_hours: '', 
                                time_pay_in: '', 
                                time_pay_out: '', 
                                time_pay_hours: ''
                                // time_actual_in: temp_time_actual_in, 
                                // time_actual_out: temp_time_actual_out, 
                                // time_actual_hours: temp_time_actual_hours, 
                                // time_pay_in: temp_time_pay_in !== '' ? dayjs(`${current_TCR.date_scheduled} ${temp_time_pay_in}`).format('h:mm a') : '', 
                                // time_pay_out: temp_time_pay_out !== '' ? dayjs(`${current_TCR.date_scheduled} ${temp_time_pay_out}`).format('h:mm a') : '', 
                                // time_pay_hours: temp_time_pay_hours
                            });
                        })

                    } 
                    else if(view === 'view_schedule') {
                        // TimeClockRecord does not exist yet - Use the default record structure, with the date_scheduled of this map index

                        // Get this employee's Workschedule, from their Users db record's work_schedule_ID
                        let TempWorkSchedule = WorkSchedules.filter((ws) => parseInt(ws.work_schedule_ID) === parseInt(s.work_schedule_ID));

                        // Determine the day of the week that this index is, and get the shift_ID for this employee's WorkSchedule
                        let temp_week_day = dayjs(temp_date_scheduled).format('dddd').toLowerCase();
                        let temp_shift_ID = TempWorkSchedule.length > 0 ? parseInt(TempWorkSchedule[0][`${temp_week_day}${(index < 7 && 1) || 2}_shift_ID`]) : 0;

                        // Get all of the shift's details for setting the schedule start and end time
                        let TempShift = temp_shift_ID > 0 ? Shifts.filter((shifts) => parseInt(shifts.shift_ID) === temp_shift_ID) : [];

                        // date_scheduled_in/out values to save to TCR
                        let temp_in = TempShift.length === 1 ? `${dayjs(`${temp_date_scheduled} ${TempShift[0].shift_start}`).format('YYYY-MM-DD H:mm:ss')}` : '';
                        let temp_out = TempShift.length === 1 ? `${dayjs(`${temp_date_scheduled} ${TempShift[0].shift_end}`).format('YYYY-MM-DD H:mm:ss')}` : '';

                        // time_scheduled_in/out values for display in UI
                        let temp_time_in = TempShift.length === 1 ? `${dayjs(`${temp_in}`).format('h:mm a')}` : '';
                        let temp_time_out = TempShift.length === 1 ? `${dayjs(`${temp_out}`).format('h:mm a')}` : '';

                        // NEW: If the shift ends before it starts, based on the time from the shift being added to the date format using date_scheduled, then we know that the it is an overnight shift, so add a day to the temp_out.
                        if(TempShift.length === 1 && dayjs(`${temp_out}`).diff(dayjs(`${temp_in}`)) < 0) {
                            temp_out = `${dayjs(`${temp_out}`).add(1, 'day').format('YYYY-MM-DD H:mm:ss')}`;
                            // Update the display times for the UI to have the date also since it is overnight
                            temp_time_in = `${dayjs(`${temp_in}`).format('M/D h:mm a')}`;
                            temp_time_out = `${dayjs(`${temp_out}`).format('M/D h:mm a')}`;

                        }

                        // OLD: If the shift ends in the AM and starts in the PM, then add a day to the date_scheduled for displaying in the Schedules table
                        // if(TempShift.length === 1 && dayjs(`${temp_date_scheduled} ${TempShift[0].shift_start}`).format('a') === 'pm' && dayjs(`${temp_date_scheduled} ${TempShift[0].shift_end}`).format('a') === 'am') {
                        //     temp_out = `${dayjs(`${temp_date_scheduled} ${TempShift[0].shift_end}`).add(1, 'day').format('M/D/YY h:mm a')}`;
                        // }

                        TempUserDays.push({ 
                            ...DefaultTimeClockRecord, 
                            date_scheduled: temp_date_scheduled,
                            date_scheduled_in: temp_in,
                            date_scheduled_out: temp_out,
                            lunch_paid: TempShift.length === 1 && TempShift[0].lunch_paid !== null && TempShift[0].lunch_paid !== '' ? TempShift[0].lunch_paid : 0,
                            reports_to: s.reports_to_first_name,
                            shift_group: TempShift.length === 1 && TempShift[0].shift_group !== null && TempShift[0].shift_group !== '' ? TempShift[0].shift_group : 0,
                            shift_ID: temp_shift_ID,
                            shift_name: TempShift.length === 1 ? TempShift[0].shift_name : '',
                            time_scheduled_hours: TempShift.length === 1 ? TempShift[0].shift_hours_worked : '',
                            // time_scheduled_in: temp_in, 
                            // time_scheduled_out: temp_out, 
                            time_scheduled_in: temp_time_in, 
                            time_scheduled_out: temp_time_out, 
                            user_ID: parseInt(s.user_ID)
                        });
                    } else {
                        return { time_clock_record_ID: -1 };
                    }
                });

                return { ...s, Schedule: TempUserDays}
            });

            setSelectedSchedule({
                Employees: [...TempSchedule]
            });

            if ( view !== '' ) {
                // Filter the TempSchedule down by any additional filters that are selected
                let TempFilteredSchedule = handleScheduleFiltering(TempSchedule);
                setBaseState({ ...BaseState, action: '', EmployeesFiltered: TempFilteredSchedule.ScheduleFiltered, filtered_results_count: TempFilteredSchedule.filtered_results_count, message: '', message_class: '', view: view});
                setSelectedTimeClockRecord({ ...DefaultSelectedTimeClockRecord });
            }
        }
    }

    // Filter down the provided Schedule array for any additional filters selected
    function handleScheduleFiltering(Schedule) {

        let TempSchedule = [ ...Schedule ];
        const { absent, approved, came_early, late, left_early, left_late, needs_approval, Amat, Dave } = BaseState.FilterFlags;

        // Only need to filter down if there are filters
        if ( absent === 1 || approved === 1 || came_early === 1 || late === 1 || left_early === 1 || left_late === 1 || needs_approval === 1 || Amat === 1 || Dave === 1 ) {

            // Narrow Employees to only ones that have TimeClockRecords that match the filters
            let TempScheduleFiltered = [];
            let temp_filtered_results_count = 0;
            TempSchedule.forEach((e) => {
                // Check each TCR for match on filter. The TCR must match on all filters to be returned
                let Temp = e.Schedule.filter((s) => {

                    // If any of these flags are selected, and the TCR does not match them all of the selected ones, do not return it.
                    if ( 
                        ( absent === 1 && s.absent === 0 ) || 
                        ( came_early === 1 && s.came_early === 0 ) || 
                        ( late === 1 && s.late === 0 ) || 
                        ( left_early === 1 && s.left_early === 0 ) || 
                        ( left_late === 1 && s.left_late === 0 ) || 
                        ( Amat === 1 && s.reports_to !== 'Amat' ) || 
                        ( Dave === 1 && s.reports_to !== 'Dave' ) ||
                        ( approved === 1 && s.approved === 0) ||
                        s.time_clock_record_ID === -1
                    ) { return null; }

                    // Needs approval is a special case - If none of the flags are 1 on the TCR, then do not return it
                    else if ( needs_approval === 1 && ( ( s.absent === 0 && s.came_early === 0 && s.late === 0 && s.left_early === 0 && s.left_late === 0 ) || s.approved === 1 ) ) {
                            return null;
                    }

                    else {
                        temp_filtered_results_count = temp_filtered_results_count+1; 
                        return s;
                    }

                });

                // If there are matching TCRs, return them, otherwise return null
                if ( Temp.length > 0 ) TempScheduleFiltered.push({ ...e, Schedule: Temp});

            });
            return { ScheduleFiltered: TempScheduleFiltered, filtered_results_count: temp_filtered_results_count };
        }
        return { ScheduleFiltered: TempSchedule, filtered_results_count: 0 };
    }

    // Save the schedule created for new timeclock records and any changes to the existing timeclock records
    async function handleSaveSchedule() {
    
        // Create array of TimeClockRecords that need to be created or updated, to send just the necessary records to the database for updating
        //let SaveData = [ ...SelectedSchedule ];
        // Loop Employees and loop their schedule to push ones without a time_clock_record_ID to the NewRecords array
        let NewRecords = [];
        SelectedSchedule.Employees.forEach((E) => E.Schedule.forEach((S) => {
            if ( parseInt(S.time_clock_record_ID) === 0 && parseInt(S.shift_ID) !== 0) {
                // Remove the AM / PM from time_scheduled_in/out
                NewRecords.push({ 
                    ..._.omit(S, ['absent', 'came_early', 'late', 'left_early', 'left_late', 'shift_name']), 
                    creator_ID: parseInt(auth().User.user_ID), 
                    date_scheduled_in: S.date_scheduled_in === '' ? null : S.date_scheduled_in,
                    date_scheduled_out: S.date_scheduled_out === '' ? null : S.date_scheduled_out,
                    time_pay_in: S.time_pay_in === '' ? null : S.time_pay_in,
                    time_pay_out: S.time_pay_out === '' ? null : S.time_pay_out,
                    time_pay_hours: S.time_pay_hours === '' ? null : S.time_pay_hours,
                    time_scheduled_in: S.date_scheduled_in !== '' ? dayjs(`${S.date_scheduled_in}`).format('HH:mm:ss') : '', 
                    time_scheduled_out: S.date_scheduled_out !== '' ? dayjs(`${S.date_scheduled_out}`).format('HH:mm:ss') : '',
                    time_actual_in: S.time_actual_in === '' ? null : S.time_actual_in,
                    time_actual_out: S.time_actual_out === '' ? null : S.time_actual_out,
                    time_actual_hours: S.time_actual_hours === '' ? null : S.time_actual_hours
                });
            }
        }));
        console.log({NewRecords})
        if ( NewRecords.length > 0 ) {
            const result = await save_time_clock_records({ variables: { input: { TimeClockRecords: [ ...NewRecords ] } } });
            
            // If we get TimeClockRecords array back, then hit the original Generate Schedule function to just refresh all the data.
            // TO DO: We can update the current state without hitting the db again by matching on user_ID, time_clock_record_ID or shift_ID/date_scheduled
            if ( result.data && result.data.timeClockRecordsSave.TimeClockRecords.length > 0) {
                setBaseState({
                    ...BaseState,
                    action: 'scheduled_saved',
                    message: `${result.data.timeClockRecordsSave.TimeClockRecords.length} Time Clock Records updated`,
                    message_class: 'success'
                });

                handleGenerateSchedule('');
            }
        } else {
            setBaseState({
                ...BaseState,
                action: 'scheduled_saved',
                message: `No Time Clock Records needed to be created or updated`,
                message_class: 'warning'
            });
        }

    }

    // Handler function for the checkbox to Approve / Excuse a Time Clock Record Actual's infraction. Passed in is an object with everything to be saved, just send it to the server and update it.
    async function handleApproveExcuseTCRA(TCRA) {
        console.log({TCRA})
        // let temp_approved = approved === 1 ? 0 : 1;
        // let temp_approver_ID = approved === 0 ? parseInt(auth().User.user_ID) : 0;
        // let temp_excuser_ID = excuser_ID === null ? parseInt(auth().User.user_ID) : null;        
        let result = await save_time_clock_record_actual({ variables: { input: TCRA }});

console.log("RESULT: ", result.data.timeClockRecordActualSave.TimeClockRecordsActual)

        // Update the TCRA throughout
        if(result.data && result.data.timeClockRecordActualSave.TimeClockRecordsActual && parseInt(result.data.timeClockRecordActualSave.TimeClockRecordsActual[0].time_clock_record_ID) > 0) {

            // Update this TCRA in the BaseState.EmployeesFiltered with the new approval data
            let TempEmployeeSchedule = BaseState.EmployeesFiltered.map((e) => {

                if ( parseInt(e.user_ID) === parseInt(TCRA.user_ID) ) {
                    // Find the TCR that this TCRA was in
                    let TempSchedule = e.Schedule.map((tcr) => {

                        if ( parseInt(tcr.time_clock_record_ID) === parseInt(TCRA.time_clock_record_ID) ) {
                            // Update the TCRA
                            let TempTCRA = tcr.TCRAs.map((tcra) => {

                                if ( parseInt(tcra.time_clock_records_actual_ID) === parseInt(TCRA.time_clock_records_actual_ID) ) {
                                    return { ...tcra, ..._.omit(TCRA, ['time_clock_records_actual_ID', 'time_clock_record_ID', 'user_ID']) };
                                } else {
                                    return tcra;
                                }

                            })

                            // If the TCRA passed in has approved=1, add in first_name last_name of current user
                            // let TempTCR = { approved: temp_approved, approver_ID: temp_approver_ID, first_name: temp_approved === 1 ? `${auth().User.first_name}` : '', last_name: temp_approved === 1 ? `${auth().User.last_name}` : ''}
                            // first_name: parseInt(TCRA.approved) === 1 ? `${auth().User.first_name}` : '', last_name: parseInt(TCRA.approved) === 1 ? `${auth().User.last_name}` : '' 
                            return { ...tcr, TCRAs: TempTCRA };
                        } else {
                            return tcr;
                        }
                    });

                    return { ...e, Schedule: TempSchedule };
                } else {
                    return e;
                }
            
            });

            setBaseState({
                ...BaseState,
                EmployeesFiltered: [...TempEmployeeSchedule]
            });
        }
    }

    console.log({SelectedTimeClockRecord})
    console.log(BaseState)
    return (
        <>
            <div>
                <div className="row clearfix">
                    {/* RESULTS COUNTER AND FILTERS */}
                    <div className="col-auto">
                        <div className="form-floating">
                            <select 
                                className="form-select" 
                                disabled={false}
                                id="filter_date_range"
                                onChange={(event) => handleChangeFilter('filter_date_range', event.target.value)}
                                placeholder="Date Range"
                                value={BaseState.filter_date_range}
                            >
                                <option value="this_week">This Week</option>
                                <option value="last_week">Last Week</option>
                                <option value="next_week">Next Week</option>
                                <option value="next_two_weeks">Next 2 Weeks</option>
                            </select> 
                            <label className="text-muted" htmlFor="filter_date_range">Date Range</label>                                                   
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-floating">
                            <input type="date" className="form-control mb-3" id="schedule_start" placeholder="Enter a Start Date" value={BaseState.schedule_start || ""} onChange={(event) => setBaseState({ ...BaseState, action: '', save_button_text: 'Save', save_message: '', schedule_start: event.target.value, view: '' })} />
                            <label className="text-muted" htmlFor="schedule_start">Start Date</label>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-floating">
                            <input type="date" className="form-control mb-3" id="schedule_end" placeholder="Enter an End Date" value={BaseState.schedule_end || ""} onChange={(event) => setBaseState({ ...BaseState, action: '', save_button_text: 'Save', save_message: '', schedule_end: event.target.value, view: '' })} />
                            <label className="text-muted" htmlFor="schedule_end">End Date</label>
                        </div>
                    </div>
                        {/* <Multiselect 
                            className="bg-white"
                            id="Operator"
                            onRemove={(selectedList, removedItem, action) => handleFilterFlagsMultiselect(selectedList, removedItem, 'remove')}
                            onSelect={(selectedList, selectedItem, action) => handleFilterFlagsMultiselect(selectedList, selectedItem, 'select')}
                            options={FilterFlags}
                            selectedValues={BaseState.FilterFlags}
                            displayValue="flag"
                            placeholder="Time Filters"
                            hidePlaceholder="true"
                            style={{
                                searchBox: {
                                    'paddingTop': '15px',
                                    'paddingBottom': '5px'
                                }
                            }}
                        /> */}
                    <div className="col-auto">
                        <div className="">Absent:</div>
                        <div className="btn-group btn-group-sm" role="group">
                            <button className={`btn btn-outline-secondary ${(BaseState.FilterFlags.absent === 1 && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => setBaseState({ ...BaseState, action: '', view: '', FilterFlags: { ...BaseState.FilterFlags, absent: BaseState.FilterFlags.absent === 1 ? 0 : 1, came_early: 0, late: 0, left_early: 0, left_late: 0 }})}>
                                Absent
                            </button>
                        </div>                       
                    </div>
                    <div className="col-auto">
                        <div className="">Started Shift:</div>
                        <div className="btn-group btn-group-sm" role="group">
                            <button className={`btn btn-outline-secondary ${(BaseState.FilterFlags.came_early === 1 && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => setBaseState({ ...BaseState, action: '', view: '', FilterFlags: { ...BaseState.FilterFlags, absent: 0, came_early: BaseState.FilterFlags.came_early === 1 ? 0 : 1, late: 0 }})}>
                                Early
                            </button>
                            <button className={`btn btn-outline-secondary ${(BaseState.FilterFlags.late === 1 && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => setBaseState({ ...BaseState, action: '', view: '', FilterFlags: { ...BaseState.FilterFlags, absent: 0, came_early: 0, late: BaseState.FilterFlags.late === 1 ? 0 : 1 }})}>
                                Late
                            </button>
                        </div>                       
                    </div>
                    <div className="col-auto">
                        <div className="">Left Shift:</div>
                        <div className="btn-group btn-group-sm" role="group">
                            <button className={`btn btn-outline-secondary ${(BaseState.FilterFlags.left_early === 1 && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => setBaseState({ ...BaseState, action: '', view: '', FilterFlags: { ...BaseState.FilterFlags, absent: 0, left_early: BaseState.FilterFlags.left_early === 1 ? 0 : 1, left_late: 0 }})}>
                                Early
                            </button>
                            <button className={`btn btn-outline-secondary ${(BaseState.FilterFlags.left_late === 1 && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => setBaseState({ ...BaseState, action: '', view: '', FilterFlags: { ...BaseState.FilterFlags, absent: 0, left_early: 0, left_late: BaseState.FilterFlags.left_late === 1 ? 0 : 1 }})}>
                                Late
                            </button>
                        </div>                       
                    </div>
                    <div className="col-auto">
                        <div className="">Approval Status:</div>
                        <div className="btn-group btn-group-sm" role="group">
                            <button className={`btn btn-outline-secondary ${(BaseState.FilterFlags.approved === 1 && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => setBaseState({ ...BaseState, action: '', view: '', FilterFlags: { ...BaseState.FilterFlags, approved: BaseState.FilterFlags.approved === 1 ? 0 : 1, needs_approval: 0 }})}>
                                Approved
                            </button>
                            <button className={`btn btn-outline-secondary ${(BaseState.FilterFlags.needs_approval === 1 && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => setBaseState({ ...BaseState, action: '', view: '', FilterFlags: { ...BaseState.FilterFlags, approved: 0, needs_approval: BaseState.FilterFlags.needs_approval === 1 ? 0 : 1 }})}>
                                Needs
                            </button>
                        </div>                       
                    </div>
                    {
                        (parseInt(auth().User.user_ID) === 32 || parseInt(auth().User.user_ID) === 1 || parseInt(auth().User.user_ID) === 38) && 
                        <div className="col-auto">
                            <div className="">Manager:</div>
                            <div className="btn-group btn-group-sm" role="group">
                                <button className={`btn btn-outline-secondary ${(BaseState.FilterFlags.Amat === 1 && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => setBaseState({ ...BaseState, action: '', view: '', FilterFlags: { ...BaseState.FilterFlags, Amat: BaseState.FilterFlags.Amat === 1 ? 0 : 1, Dave: 0 }})}>
                                    Amat
                                </button>
                                <button className={`btn btn-outline-secondary ${(BaseState.FilterFlags.Dave === 1 && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => setBaseState({ ...BaseState, action: '', view: '', FilterFlags: { ...BaseState.FilterFlags, Amat: 0, Dave: BaseState.FilterFlags.Dave === 1 ? 0 : 1 }})}>
                                    Dave
                                </button>
                            </div>                       
                        </div>
                    }
                    {/* {
                        BaseState.action === 'added_time_clock_record' &&
                        <span className="col-auto float-end me-2">
                            <div className="alert alert-success p-2">TimeClockRecord added successfully</div>
                        </span>
                    } */}
                </div>
                <div className="row clearfix mb-4">
                    <div className="col-auto h-100">
                        <button 
                            className="btn mt-1 fw-bold col-auto border-primary border-3 float-end text-primary" 
                            onClick={() => handleGenerateSchedule('view_attendance_summary')}
                            disabled={BaseState.schedule_end === '' || BaseState.schedule_start === ''}
                        >
                            Attendance Summary
                        </button>
                    </div>
                    <div className="col-auto h-100">
                        <button 
                            className="btn mt-1 fw-bold col-auto border-primary border-3 float-end text-primary" 
                            onClick={() => handleGenerateSchedule('view_hours')}
                            disabled={BaseState.schedule_end === '' || BaseState.schedule_start === ''}
                        >
                            Hours
                        </button>
                    </div>
                    <div className="col-auto h-100">
                        <button 
                            className="btn mt-1 fw-bold col-auto border-primary border-3 float-end text-primary" 
                            onClick={() => handleGenerateSchedule('view_schedule')}
                            disabled={BaseState.schedule_end === '' || BaseState.schedule_start === ''}
                        >
                            Schedule
                        </button>
                    </div>
                    {/* When filter flags are applied, show how many records there are. */}
                    {
                        BaseState.view !== '' && ( BaseState.FilterFlags.absent === 1 || BaseState.FilterFlags.approved === 1 || BaseState.FilterFlags.came_early === 1 || BaseState.FilterFlags.late === 1 || BaseState.FilterFlags.left_early === 1 || BaseState.FilterFlags.left_late === 1 || BaseState.FilterFlags.needs_approval === 1 || BaseState.FilterFlags.Amat === 1 || BaseState.FilterFlags.Dave === 1 ) && 
                        <div className="col-auto m-0 mt-3 h4">{BaseState.filtered_results_count} Time Clock Records{BaseState.filtered_results_count === 0 && <span>. Please Adjust Filters.</span>}</div>
                    }
                    {
                        SelectedSchedule.Employees.length > 0 && BaseState.action !== 'scheduled_saved' && BaseState.view === 'view_schedule' &&
                        <div className="col-auto">
                            <button 
                                className="btn mt-1 fw-bold col-auto border-success border-3 float-end text-success" 
                                onClick={() => handleSaveSchedule()}
                                disabled={BaseState.schedule_end === '' || BaseState.schedule_start === ''}
                            >
                                <FontAwesomeIcon icon="fa-floppy-disk" className="" /> Save Schedule
                            </button>
                        </div>
                    }
                    {
                        BaseState.message !== '' &&
                        <div className="col-auto">
                            <div className={`alert m-0 mt-1 py-2 alert-${BaseState.message_class}`}>{BaseState.message}</div>
                        </div>
                    }
                </div>

                {/* Create Schedule - BaseState.view = 'view_schedule */}
                {
                    BaseState.view === 'view_schedule' &&
                    SelectedSchedule.Employees.length > 0 &&
                    <table className="table">
                        <thead className="row h6 border border-2 border-top-0 border-start-0 border-end-0 border-dark">
                            <tr className="col-2">Employee</tr>
                            <tr className="col-1">Shift</tr>
                            <tr className="col-1">Type</tr>
                            <tr className="col-1">Pay Date</tr>
                            <tr className="col-2">In</tr>
                            <tr className="col-2">Out</tr>
                            <tr className="col-1">Hours</tr>
                            <tr className="col-2">Note</tr>
                        </thead>
                        <tbody className="">


                            {SelectedSchedule.Employees.map((ss) => {
                                return (
                                    <React.Fragment key={ss.user_ID}>
                                        {ss.Schedule.map((s, index) => {
                                            return (
                                                <tr className={`row ${parseInt(s.shift_ID) === 0 && 'bg-light'} ${index+1 === ss.Schedule.length && 'border-3 border-secondary border-top-0 border-start-0 border-end-0'}`} key={`${ss.user_ID}_${index}`}>
                                                    <td className="col-2 d-flex align-items-center text-center">
                                                        {index === 0 && <span className="h4 mb-0">{ss.last_name}, {ss.first_name}</span>}
                                                    </td>
                                                    <td className={`col-1 ps-3`}>
                                                        {s.shift_name}
                                                    </td>
                                                    <td className={`col-1 ps-3`}>
                                                        {parseInt(s.shift_ID) > 0 && 'Scheduled'}
                                                    </td>
                                                    <td className={`col-1 ps-3`}>
                                                        {dayjs(s.date_scheduled).format('ddd M/D/YY')}
                                                    </td>
                                                    <td className={`col-2 ps-3`}>
                                                        {s.time_scheduled_in}
                                                    </td>
                                                    <td className={`col-2 ps-3`}>
                                                        {s.time_scheduled_out}
                                                    </td>
                                                    <td className={`col-1 ps-3`}>
                                                        {s.time_scheduled_hours}
                                                    </td>
                                                    <td className={`col-2 ps-3`}>
                                                        {parseInt(s.shift_ID) === 0 && <span className="badge rounded-pill bg-danger ">No Shift</span>}
                                                        {parseInt(s.time_clock_record_ID) > 0 && <span className="badge rounded-pill bg-secondary">Already Scheduled</span>}
                                                        {
                                                            parseInt(s.time_clock_record_ID) === 0 &&
                                                            parseInt(s.shift_ID) > 0 && 
                                                            <span className="badge rounded-pill bg-success ">Will Be Added</span>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </React.Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                }

                {/* Attendance Summary - BaseState.view = 'view_attendance_summary */}
                {
                    BaseState.view === 'view_attendance_summary' &&
                    BaseState.EmployeesFiltered.length > 0 &&
                    <table className="table">
                        <thead className="row h6 border border-2 border-top-0 border-start-0 border-end-0 border-dark">
                            <tr className="col-1">Type</tr>
                            <tr className="col-1">Pay Date</tr>
                            <tr className="col-2">
                                Scheduled
                            </tr>
                            <tr className="col-1">
                                Scheduled Hours
                            </tr>
                            <tr className="col-2">
                                Actual
                            </tr>
                            <tr className="col-1">
                                Actual Hours
                            </tr>
                            <tr className="col-2">
                                Pay
                            </tr>
                            <tr className="col-1">
                                Pay Hours
                            </tr>
                            <tr className="col-1">Approve</tr>
                        </thead>
                        <tbody className="">
                            {BaseState.EmployeesFiltered.map((ss) => {
                                let temp_total_hours_scheduled = 0;
                                let temp_total_hours_actual = 0;
                                let temp_total_hours_holiday = 0;
                                let temp_total_hours_paid = 0;
                                return (
                                    <React.Fragment key={ss.user_ID}>
                                        {/* Top row with employee Name and their manager */}
                                        <tr className="row" key={ss.user_ID}>
                                            <td className="col-12 text-white bg-secondary">
                                                <span className="float-start bg-success px-1 me-2 rounded text-white h4 m-0" onClick={() => handleButtonClick('add_time_clock_record', { work_schedule_ID: parseInt(ss.work_schedule_ID), user_ID: parseInt(ss.user_ID) })}><FontAwesomeIcon icon="fa-plus" className="" /></span>
                                                <span className="h4 mb-0 float-start">{ss.last_name}, {ss.first_name}</span>
                                                {(ss.reports_to_first_name !== null && ss.reports_to_last_name !== null && <span className="h4 mb-0 float-end">Reports To: {ss.reports_to_last_name}, {ss.reports_to_first_name}</span>) || <span className="h4 mb-0 float-end text-danger">Manager Not Setup</span>}
                                            </td>
                                        </tr>

                                        {/* Duplicate functionality of Edit Form for this Add a Shift Form */}
                                        { BaseState.action === 'add_time_clock_record' && parseInt(ss.user_ID) === parseInt(SelectedTimeClockRecord.user_ID) && parseInt(SelectedTimeClockRecord.time_clock_record_ID) === 0 &&
                                            <tr className="row border border-success border-bottom-0">
                                                <td className="col-2 border-bottom-0">
                                                    <>
                                                    <div className="form-floating mt-1">
                                                        <select 
                                                            className="form-select" 
                                                            disabled={SelectedTimeClockRecord.date_scheduled === null || SelectedTimeClockRecord.date_scheduled === ''}
                                                            id="shift_group"
                                                            onChange={(event) => changeInputValue('shift_group', event.target.value)}
                                                            placeholder="Shift Group"
                                                            value={SelectedTimeClockRecord.shift_group}
                                                        >
                                                            <option value={0}>Choose Option</option>
                                                            <option value="1">Shift 1</option>
                                                            <option value="2">Shift 2</option>
                                                            <option value="3">Shift 3</option>
                                                        </select> 
                                                        <label className="text-muted" htmlFor="shift_group">Shift Group</label>                                                   
                                                    </div>
                                                    <div className="form-floating mt-2 pt-1">
                                                        <select 
                                                            className="form-select" 
                                                            disabled={SelectedTimeClockRecord.date_scheduled === null || SelectedTimeClockRecord.date_scheduled === ''}
                                                            id="lunch_paid"
                                                            onChange={(event) => changeInputValue('lunch_paid', event.target.value)}
                                                            placeholder="Lunch Paid"
                                                            value={SelectedTimeClockRecord.lunch_paid}
                                                        >
                                                            <option value={0}>Lunch Not Paid</option>
                                                            <option value={1}>Lunch Paid</option>
                                                        </select> 
                                                        <label className="text-muted" htmlFor="lunch_paid">Lunch Paid</label>                                                   
                                                    </div>
                                                    </>
                                                </td>
                                                <td className="col-3 border-bottom-0">
                                                    <>
                                                    <div className="mb-2">
                                                        <label className="text-muted" htmlFor="shift_start_hour">Scheduled Start</label>
                                                        {TimeInput("time_scheduled_in")}
                                                    </div>
                                                    <div className="">
                                                        <label className="text-muted" htmlFor="shift_end_hour">Scheduled End</label>
                                                        {TimeInput("time_scheduled_out")}
                                                    </div>
                                                    </>
                                                </td>
                                                <td className="col-3 border-bottom-0">
                                                    <>
                                                    <div className="mb-2">
                                                        <label className="text-muted float-start" htmlFor="shift_start_hour">Actual Start</label> 
                                                        {   
                                                            SelectedTimeClockRecord.date_scheduled !== null && SelectedTimeClockRecord.date_scheduled !== '' &&
                                                            <>
                                                            <button className="badge bg-white rounded-pill border border-primary text-primary float-start ms-2" onClick={() => handleButtonClick('set_actual_start_to_schedule_time', SelectedTimeClockRecord, 0)}>Match Sch</button>
                                                            <button className="badge bg-white rounded-pill border border-secondary text-secondary float-end" onClick={() => handleButtonClick('clear_actual_start', SelectedTimeClockRecord, 0)}>Clear</button>
                                                            </>
                                                        }
                                                        {TimeInput("time_actual_in")}
                                                    </div>
                                                    <div className="">
                                                        <label className="text-muted float-start" htmlFor="shift_end_hour">Actual End</label>
                                                        {   
                                                            SelectedTimeClockRecord.date_scheduled !== null && SelectedTimeClockRecord.date_scheduled !== '' &&
                                                            <>
                                                            <button className="badge bg-white rounded-pill border border-primary text-primary float-start ms-2" onClick={() => handleButtonClick('set_actual_end_to_schedule_time', SelectedTimeClockRecord, 0)}>Match Sch</button>
                                                            <button className="badge bg-white rounded-pill border border-secondary text-secondary float-end" onClick={() => handleButtonClick('clear_actual_end', SelectedTimeClockRecord, 0)}>Clear</button>
                                                            </>
                                                        }
                                                        {TimeInput("time_actual_out")}
                                                    </div>
                                                    </>
                                                </td>
                                                <td className="col-3 border-bottom-0">
                                                    <>
                                                    <div className="mb-2">
                                                        <label className="text-muted float-start" htmlFor="shift_start_hour">Pay Start</label>
                                                        {   
                                                            SelectedTimeClockRecord.date_scheduled !== null && SelectedTimeClockRecord.date_scheduled !== '' &&
                                                            <>                                                        
                                                            <button className="badge bg-white rounded-pill border border-primary text-primary float-start ms-2" onClick={() => handleButtonClick('set_pay_start_to_schedule_time', SelectedTimeClockRecord, 0)}>Match Sch</button>
                                                            <button className="badge bg-white rounded-pill border border-primary text-primary float-start ms-2" onClick={() => handleButtonClick('recalc_pay_in', SelectedTimeClockRecord, 0)}>Recalc</button>
                                                            <button className="badge bg-white rounded-pill border border-secondary text-secondary float-end" onClick={() => handleButtonClick('clear_pay_start', SelectedTimeClockRecord, 0)}>Clear</button>
                                                            </>
                                                        }
                                                        {TimeInput("time_pay_in")}
                                                    </div>
                                                    <div className="">
                                                        <label className="text-muted float-start" htmlFor="shift_end_hour">Pay End</label>
                                                        {   
                                                            SelectedTimeClockRecord.date_scheduled !== null && SelectedTimeClockRecord.date_scheduled !== '' &&
                                                            <>
                                                            <button className="badge bg-white rounded-pill border border-primary text-primary float-start ms-2" onClick={() => handleButtonClick('set_pay_end_to_schedule_time', SelectedTimeClockRecord, 0)}>Match Sch</button>
                                                            <button className="badge bg-white rounded-pill border border-primary text-primary float-start ms-2" onClick={() => handleButtonClick('recalc_pay_out', SelectedTimeClockRecord, 0)}>Recalc</button>
                                                            <button className="badge bg-white rounded-pill border border-secondary text-secondary float-end" onClick={() => handleButtonClick('clear_pay_end', SelectedTimeClockRecord, 0)}>Clear</button>
                                                            </>
                                                        }
                                                        {TimeInput("time_pay_out")}
                                                    </div>
                                                    </>
                                                </td>
                                                <td className="col-1 mt-1 border-bottom-0">
                                                    <div className="mt-3 mb-1">
                                                        <button className="btn fw-bold border border-3 border-success text-success w-100" disabled={SelectedTimeClockRecord.date_scheduled === null || SelectedTimeClockRecord.date_scheduled === ''} onClick={() => saveTimeClockRecord(0)}>
                                                            { SelectedTimeClockRecord.save_button_text === 'Save' && <span><FontAwesomeIcon icon="fa-floppy-disk" className="me-2 fa-lg" />Save</span>}
                                                            { SelectedTimeClockRecord.save_button_text === 'Saved' && <span><FontAwesomeIcon icon="fa-check" className="me-2 fa-lg" />Saved</span>}
                                                        </button>
                                                    </div>
                                                    <div className="mt-4">
                                                        <button className="btn mt-2 fw-bold border border-3 border-secondary text-secondary w-100" onClick={() => handleButtonClick('close_time_clock_record', DefaultSelectedTimeClockRecord)}><FontAwesomeIcon icon="fa-times" className="me-2 fa-lg" />{ ( SelectedTimeClockRecord.save_button_text === 'Save' && 'Cancel' ) || 'Close'}</button>
                                                    </div>
                                                </td>
                                                <td className={`col-2 ${SelectedTimeClockRecord.save_message !== '' && 'border-bottom-0'}`}>
                                                    <div className="form-floating">
                                                        <input type="date" className={`form-control mb-3 ${(SelectedTimeClockRecord.date_scheduled === null || SelectedTimeClockRecord.date_scheduled === '') && 'border-danger'}`} id="date_scheduled" placeholder="Enter a Schedule Date" value={SelectedTimeClockRecord.date_scheduled || ""} onChange={(event) => changeInputValue('date_scheduled', event.target.value)} />
                                                        <label className={`${((SelectedTimeClockRecord.date_scheduled === null || SelectedTimeClockRecord.date_scheduled === '') && 'text-danger') || 'text-muted'}`} htmlFor="date_scheduled">New Shift's Date</label>
                                                    </div>
                                                    {
                                                        (SelectedTimeClockRecord.date_scheduled === null || SelectedTimeClockRecord.date_scheduled === '') &&
                                                        <div className="text-danger">Select date to continue</div>
                                                    }
                                                </td>
                                                <td className={`col-2 ${SelectedTimeClockRecord.save_message !== '' && 'border-bottom-0'}`}>
                                                    <div className="form-floating">
                                                        <select 
                                                            className="form-select" 
                                                            disabled={SelectedTimeClockRecord.date_scheduled === null || SelectedTimeClockRecord.date_scheduled === ''}
                                                            id="schedule_designation_ID"
                                                            onChange={(event) => changeInputValue('schedule_designation_ID', event.target.value)}
                                                            placeholder="Schedule Designation"
                                                            value={SelectedTimeClockRecord.ScheduleDesignation.schedule_designation_ID}
                                                        >
                                                        
                                                            <option value={0} key={0}>Choose Designation</option>
                                                            {ScheduleDesignations.map((sd) => {
                                                                return <option value={parseInt(sd.schedule_designation_ID)} key={parseInt(sd.schedule_designation_ID)}>{sd.schedule_designation}</option>
                                                            })}
                                                        </select> 
                                                        <label className="text-muted" htmlFor="schedule_designation_ID">Schedule Designation</label>                                                   
                                                    </div>
                                                </td>
                                                <td className={`col-1 ${SelectedTimeClockRecord.save_message !== '' && 'border-bottom-0'}`}>
                                                    <div className="input-group" style={{height: 25+'px'}}>
                                                        <input className="form-control float-end px-1" disabled={SelectedTimeClockRecord.date_scheduled === null || SelectedTimeClockRecord.date_scheduled === ''} style={{height: 25+'px', fontSize: 13+'px'}} value={SelectedTimeClockRecord.ScheduleDesignation.hours || ''} onChange={(event) => changeInputValue('hours', event.target.value)} />
                                                        <span className="input-group-text px-1" style={{height: 25+'px', fontSize: 13+'px'}}>hours</span>
                                                    </div>
                                                    <div className="input-group mt-2" style={{height: 25+'px'}}>
                                                        <input className="form-control float-end px-1" disabled={SelectedTimeClockRecord.date_scheduled === null || SelectedTimeClockRecord.date_scheduled === ''} style={{height: 25+'px', fontSize: 13+'px'}} value={SelectedTimeClockRecord.ScheduleDesignation.minutes || ''} onChange={(event) => changeInputValue('minutes', event.target.value)} />
                                                        <span className="input-group-text px-1" style={{height: 25+'px', fontSize: 13+'px'}}>mins.</span>
                                                    </div>
                                                </td>
                                                <td className={`col-7 ${SelectedTimeClockRecord.save_message !== '' && 'border-bottom-0'}`}>
                                                    <div className="form-floating">
                                                        <input className="form-control float" id="note" disabled={SelectedTimeClockRecord.date_scheduled === null || SelectedTimeClockRecord.date_scheduled === ''} value={SelectedTimeClockRecord.tcrn_note || ''} onChange={(event) => changeInputValue('tcrn_note', event.target.value)} /> 
                                                        <label className="text-muted" htmlFor="note">Note</label>
                                                    </div>
                                                </td>
                                                {/* <td className={`col-2 ${SelectedTimeClockRecord.save_message !== '' && 'border-bottom-0'}`}>
                                                    {SelectedTimeClockRecord.tcrn_creator !== null && SelectedTimeClockRecord.tcrn_creator !== '' &&
                                                        <div className="">{SelectedTimeClockRecord.tcrn_creator} <span className="float-end">{dayjs(SelectedTimeClockRecord.tcrn_date_created).format('M-D h:mm a')}</span></div>
                                                    }
                                                    {SelectedTimeClockRecord.tcrn_updater !== null && SelectedTimeClockRecord.tcrn_updater !== '' &&
                                                        <div className="">{SelectedTimeClockRecord.tcrn_updater} <span className="float-end">{dayjs(SelectedTimeClockRecord.tcrn_date_updated).format('M-D h:mm a')}</span></div>
                                                    }
                                                </td> */}
                                                {/* Save Message */}
                                                {
                                                    SelectedTimeClockRecord.save_message !== '' && SelectedTimeClockRecord.save_message_class !== '' &&
                                                    <td className="col-12">
                                                        <div className={`alert alert-${SelectedTimeClockRecord.save_message_class} m-0`}>{SelectedTimeClockRecord.save_message}</div>
                                                    </td>
                                                }
                                            </tr>
                                        }
                                        {/* END Add Shift Form */}

                                        {/* Loop through employee's scheduled shifts */}
                                        {ss.Schedule.map((s, index) => {
                                            if ( s.time_clock_record_ID > -1 ) {
                                                temp_total_hours_scheduled = add(temp_total_hours_scheduled, s.time_scheduled_hours).toFixed(2);
                                                let temp_shift_hours_actual = 0;
                                                let temp_shift_hours_actual_count = 0;
                                                let temp_shift_hours_pay = 0;
                                                let temp_shift_hours_pay_count = 0;
                                                // Used for late / left early
                                                let temp_infraction_count = 0;
                                                let temp_infraction_single_TCRA = 0;
                                                // If there are any TCRAs that are not yet completed
                                                let temp_open_TCRA = false;
                                                return (
                                                    <React.Fragment key={`${ss.user_ID}_${index}`}>

                                                        {/* Row of Scheduled Shift Details */}
                                                        <tr className={`row ${parseInt(s.shift_ID) === 0 && 'bg-light'} ${parseInt(SelectedTimeClockRecord.time_clock_record_ID) > 0 && parseInt(SelectedTimeClockRecord.time_clock_record_ID) === parseInt(s.time_clock_record_ID) && 'border border-success border-bottom-0'}`}>
                                                            <td className={`col-1 ps-3`}>
                                                                <span className="text-primary" onClick={() => handleButtonClick('edit_time_clock_record', s)}><FontAwesomeIcon icon="fa-pen" className="me-1" /></span>{(parseInt(s.shift_ID) > 0 && 'Sch') || 'Reg'}
                                                            </td>
                                                            <td className={`col-1 ps-3`}>
                                                                {dayjs(s.date_scheduled).format('ddd M/D/YY')}
                                                            </td>

                                                            {/* TIME SCHEDULED */}
                                                            <td className={`col-2 ps-3`}>
                                                                <span className="float-start">
                                                                    {s.shift_group !== null && s.shift_group !== '' && <span className="fw-bold text-dark">{s.shift_group}: </span>}
                                                                    {(s.shift_group === null && s.shift_group !== '') && s.shifts_shift_group !== null && s.shifts_shift_group !== '' && <span className="fw-bold text-dark">{s.shifts_shift_group}: </span>}
                                                                    {s.date_scheduled_in !== '' && dayjs(s.date_scheduled_in).format('h:mm a')}{ (s.date_scheduled_in !== '' || s.date_scheduled_out !== '') && ' - '} {s.date_scheduled_out !== '' && dayjs(s.date_scheduled_out).format('h:mm a')}
                                                                </span>
                                                                {((s.lunch_paid !== null && parseInt(s.lunch_paid) === 1) || (s.lunch_paid === null && parseInt(s.shifts_lunch_paid) === 1)) && <span className="float-end text-dark me-3"><FontAwesomeIcon icon="fa-dollar-sign" className="" /></span>}
                                                            </td>
                                                            <td className={`col-1 ps-3`}>
                                                                <span className="fw-bolder">{s.time_scheduled_hours}</span>
                                                            </td>

                                                            {/* TIME ACTUAL */}
                                                            <td className={`col-2 ps-3`}>
                                                                { s.absent === 1 && parseInt(s.ScheduleDesignation.schedule_designation_ID) === 0 && <span className="badge bg-white rounded-pill border border-danger text-danger">Absent</span> }
                                                                {/* Loop through TCRA for this shift */}
                                                                { s.TCRAs.map((tcra) => {
                                                                    // If there is a TCRA that is not completed, we want to flag this TCR so that we cannot finalize approve / excuse of the TCRA(s). This will be used in the last column of the table.
                                                                    if ( tcra.time_actual_in === '' || tcra.time_actual_out === '' ) temp_open_TCRA = true;

                                                                    return (
                                                                        <div key={`${tcra.time_clock_records_actual_ID}_actual`}>
                                                                            <span className={`${tcra.late > 0 && tcra.late_excuser_ID === null && 'text-danger'} ${tcra.came_early === 1 && tcra.approver_ID === null && 'text-warning'}`}>{ tcra.time_actual_in !== '' && dayjs(tcra.time_actual_in).format('h:mm a') }</span>
                                                                            { (tcra.time_actual_in !== '' || tcra.time_actual_out !== '') && ' - '} 
                                                                            <span className={`${tcra.left_early > 0 && tcra.left_early_excuser_ID === null && 'text-danger'} ${tcra.left_late === 1 && tcra.approver_ID === null && 'text-warning'}`}>{ tcra.time_actual_out !== '' && dayjs(tcra.time_actual_out).format('h:mm a') }</span>
                                                                        </div>
                                                                    )
                                                                })}
                                                                {/* Schedule Designations */}
                                                                { 
                                                                    parseInt(s.ScheduleDesignation.schedule_designation_ID) > 0 &&
                                                                    <div className="">
                                                                        <span className="badge bg-white rounded-pill border border-primary text-primary">{ScheduleDesignations.filter((sd) => parseInt(sd.schedule_designation_ID) === parseInt(s.ScheduleDesignation.schedule_designation_ID))[0].schedule_designation}</span>
                                                                        <span className="text-primary ms-2">{s.ScheduleDesignation.hours !== '' && parseInt(s.ScheduleDesignation.hours) !== 0 && <span>{s.ScheduleDesignation.hours}h</span>} {s.ScheduleDesignation.minutes !== '' && parseInt(s.ScheduleDesignation.minutes) !== 0 &&  <span>{s.ScheduleDesignation.minutes}m</span>}</span>
                                                                    </div>
                                                                }
                                                            </td>

                                                            {/* ACTUAL HOURS */}
                                                            <td className={`col-1 ps-3`}>
                                                                {/* Loop through TCRA for this shift */}
                                                                { s.TCRAs.map((tcra) => {
                                                                    temp_shift_hours_actual = tcra.time_actual_hours !== '' ? add(temp_shift_hours_actual, parseFloat(tcra.time_actual_hours)).toFixed(2) : temp_shift_hours_actual;
                                                                    temp_shift_hours_actual_count = tcra.time_actual_hours !== '' ? add(temp_shift_hours_actual_count, 1).toFixed(2) : temp_shift_hours_actual_count;
                                                                    // Running total for filter date range 
                                                                    temp_total_hours_actual = add(temp_total_hours_actual, tcra.time_actual_hours).toFixed(2);
                                                                    return (
                                                                        <div key={`${tcra.time_clock_records_actual_ID}_actual_hours`}>
                                                                            <span className="fw-bolder">{(tcra.time_actual_hours !== '' && tcra.time_actual_hours) || (s.TCRAs.length > 1 && '--')}</span>
                                                                        </div>
                                                                    )
                                                                })}
                                                                {/* If there are multiple TCRAs with completed actual time, then show the total actual hours */}
                                                                { temp_shift_hours_actual_count > 1 && 
                                                                    <div className="border-top border-3 border-secondary fw-bolder">{temp_shift_hours_actual}</div>
                                                                }
                                                            </td>

                                                            {/* TIME PAY */}
                                                            <td className={`col-2 ps-3`}>
                                                                {/* Loop through TCRA for this shift */}
                                                                { s.TCRAs.map((tcra) => {
                                                                    return (
                                                                        <div key={`${tcra.time_clock_records_actual_ID}_pay`}>
                                                                            { tcra.time_pay_in !== '' && dayjs(tcra.time_pay_in).format('h:mm a') }
                                                                            { (tcra.time_pay_in !== '' || tcra.time_pay_out !== '') && ' - '} 
                                                                            { tcra.time_pay_out !== '' && dayjs(tcra.time_pay_out).format('h:mm a') }
                                                                        </div>
                                                                    )
                                                                })}
                                                                {/*  TO DO: fix this for each */}
                                                                {s.time_pay_in !== '' && s.time_pay_out !== '' && ( s.date_scheduled_in !== s.time_pay_in || s.date_scheduled_out !== s.time_pay_out ) && <span className="text-success ms-2" onClick={() => handleButtonClick('set_pay_to_schedule_time', s)}><FontAwesomeIcon icon="fa-clock" className="" /></span>}
                                                            </td>

                                                            {/* PAY HOURS */}
                                                            <td className={`col-1 ps-3`}>
                                                                {/* Loop through TCRA for this shift */}
                                                                { s.TCRAs.map((tcra) => {
                                                                    temp_shift_hours_pay = tcra.time_pay_hours !== '' ? add(temp_shift_hours_pay, parseFloat(tcra.time_pay_hours)).toFixed(2) : temp_shift_hours_pay;
                                                                    temp_shift_hours_pay_count = tcra.time_pay_hours !== '' ? add(temp_shift_hours_pay_count, 1).toFixed(2) : temp_shift_hours_pay_count;
                                                                    // Running total for filter date range - Do not add holiday shifts to their total hours paid yet for purposes of distinguishing OT total
                                                                    temp_total_hours_holiday = parseInt(s.ScheduleDesignation.schedule_designation_ID) === 5 ? add(temp_total_hours_holiday, tcra.time_pay_hours).toFixed(2) : temp_total_hours_holiday;
                                                                    temp_total_hours_paid = parseInt(s.ScheduleDesignation.schedule_designation_ID) === 5 ? temp_total_hours_paid : add(temp_total_hours_paid, tcra.time_pay_hours).toFixed(2);
                                                                    return (
                                                                        <div key={`${tcra.time_clock_records_actual_ID}_pay_hours`}>
                                                                            <span className="fw-bolder">{(tcra.time_pay_hours !== '' && tcra.time_pay_hours) || (s.TCRAs.length > 1 && '--')}</span>
                                                                        </div>
                                                                    )
                                                                })}
                                                                {/* If there are multiple TCRAs with completed actual time, then show the total actual hours */}
                                                                { temp_shift_hours_pay_count > 1 && 
                                                                    <div className="border-top border-3 border-secondary fw-bolder">{temp_shift_hours_pay}</div>
                                                                }
                                                            </td>

                                                            <td className={`col-1 ps-3`}>
                                                                {/* Loop through TCRA for this shift */}

                                                                {/* 
                                                                
Disable the inputs if
parseInt(SelectedTimeClockRecord.time_clock_record_ID) === 0
                                                                
                                                                 */}

{ temp_open_TCRA === false && s.TCRAs.map((tcra, index2) => {
    console.log({index2})
    // Get infraction count for this day. Being late or leaving early must be handled individually, but coming early or leaving late can be a single approver_ID since unapproved of these times can just be matched to the regular schedule
    if (tcra.left_early === 1 && tcra.left_early_excuser_ID === null) temp_infraction_count = temp_infraction_count+1; temp_infraction_single_TCRA = parseInt(tcra.time_clock_records_actual_ID);
    if (tcra.late === 1 && tcra.late_excuser_ID === null) temp_infraction_count = temp_infraction_count+1; temp_infraction_single_TCRA = parseInt(tcra.time_clock_records_actual_ID);
    if ((tcra.came_early === 1 || tcra.left_late === 1) && tcra.approver_ID === null) temp_infraction_count = temp_infraction_count+1; temp_infraction_single_TCRA = parseInt(tcra.time_clock_records_actual_ID);
    
    // On the last TCRA, if there are no infractions, show nothing. If 1 infraction, we can show a button to just handle that one, if more than 1, show button to open lower container for handling approval / excusing of each infraction
    if ( index2+1 === s.TCRAs.length && temp_infraction_count === 1) {

        // Determine what the single infraction was - all of these have approved = 1 because it is a single infraction and after handling it, this TCR is considered good.
        if (tcra.left_early === 1 && tcra.left_early_excuser_ID === null) {
            return (
                <>
                    <div><button className="btn btn-sm btn-success" onClick={() => handleApproveExcuseTCRA( { time_clock_records_actual_ID: temp_infraction_single_TCRA, time_clock_record_ID: parseInt(s.time_clock_record_ID), user_ID: parseInt(s.user_ID), left_early: 1, left_early_excuser_ID: parseInt(auth().User.user_ID), approved: 1} )}>Excused</button></div>
                    <div className="mt-2"><button className="btn btn-sm btn-danger" onClick={() => handleApproveExcuseTCRA( { time_clock_records_actual_ID: temp_infraction_single_TCRA, time_clock_record_ID: parseInt(s.time_clock_record_ID), user_ID: parseInt(s.user_ID), left_early: 2, left_early_excuser_ID: parseInt(auth().User.user_ID), approved: 1} )}>Unexcused</button></div>
                </>
            )
        }
        else if (tcra.late === 1 && tcra.late_excuser_ID === null) {
            return (
                <>
                    <div><button className="btn btn-sm btn-success" onClick={() => handleApproveExcuseTCRA( { time_clock_records_actual_ID: temp_infraction_single_TCRA, time_clock_record_ID: parseInt(s.time_clock_record_ID), user_ID: parseInt(s.user_ID), late: 1, late_excuser_ID: parseInt(auth().User.user_ID), approved: 1} )}>Excused</button></div>
                    <div className="mt-2"><button className="btn btn-sm btn-danger" onClick={() => handleApproveExcuseTCRA( { time_clock_records_actual_ID: temp_infraction_single_TCRA, time_clock_record_ID: parseInt(s.time_clock_record_ID), user_ID: parseInt(s.user_ID), late: 2, late_excuser_ID: parseInt(auth().User.user_ID), approved: 1} )}>Unexcused</button></div>
                </>
            )
        } 
        // For coming early and leaving late, just need to approve that, otherwise change the time_actual_in/out values
        else {
            <div><button className="btn btn-sm btn-success" onClick={() => handleApproveExcuseTCRA( { time_clock_records_actual_ID: temp_infraction_single_TCRA, user_ID: parseInt(s.user_ID), came_early: s.TCRAs.filter((t) => parseInt(t.time_clock_records_actual_ID) === temp_infraction_single_TCRA)[0].came_early, left_late: s.TCRAs.filter((t) => parseInt(t.time_clock_records_actual_ID) === temp_infraction_single_TCRA)[0].left_late,  approver_ID: parseInt(auth().User.user_ID), approved: 1} )}>Approved</button></div>
        }

    } else if ( index2+1 === s.TCRAs.length && temp_infraction_count > 1) {
        return <button className="btn btn-sm btn-warning">Manager View</button>
    }
    else {
        return null;
    }

    // Determine if this tcra needs a checkbox for Approving employee coming early or leaving late
    if ( (tcra.left_early === 1 && tcra.left_early_excuser_ID === null) || (tcra.left_late === 1 && tcra.late_excuser_ID === null) ) {
    
    }
    if ( ( tcra.absent === 1 || tcra.came_early === 1 || tcra.late === 1 || tcra.left_early === 1 || tcra.left_late === 1 ) && parseInt(SelectedTimeClockRecord.time_clock_record_ID) === 0 ) {
    
    }

    return (
        <div key={`${tcra.time_clock_records_actual_ID}_approval`}>
            <input className="form-check-input border-secondary mt-1 h5 me-2" type="checkbox" id="" checked={s.approved === 1} value="" onChange={() => handleApproveExcuseTCRA(s.time_clock_record_ID, s.approved, s.user_ID)} />
            { s.first_name !== '' && s.first_name !== null && <span className="ms-2">{s.first_name}</span>}
        </div>
    )
})}
{ s.time_clock_record_note_ID > 0 && <span className="float-end text-primary m-0 p-0 h5" onClick={() => handleButtonClick('edit_time_clock_record', s)}><FontAwesomeIcon icon="fa-clipboard" className="" /></span> } 
                                                            </td>

                                                            {/*  Edit Time Clock Record form */}
                                                            {
                                                                parseInt(SelectedTimeClockRecord.time_clock_record_ID) === parseInt(s.time_clock_record_ID) &&
                                                                <>
                                                                    <td className="col-2 border-bottom-0">
                                                                        <>
                                                                        <div className="form-floating mt-1">
                                                                            <select 
                                                                                className="form-select" 
                                                                                disabled={false}
                                                                                id="shift_group"
                                                                                onChange={(event) => changeInputValue('shift_group', event.target.value)}
                                                                                placeholder="Shift Group"
                                                                                value={SelectedTimeClockRecord.shift_group}
                                                                            >
                                                                                <option value={0}>Choose Option</option>
                                                                                <option value="1">Shift 1</option>
                                                                                <option value="2">Shift 2</option>
                                                                                <option value="3">Shift 3</option>
                                                                            </select> 
                                                                            <label className="text-muted" htmlFor="shift_group">Shift Group</label>                                                   
                                                                        </div>
                                                                        <div className="form-floating mt-2 pt-1">
                                                                            <select 
                                                                                className="form-select" 
                                                                                disabled={false}
                                                                                id="lunch_paid"
                                                                                onChange={(event) => changeInputValue('lunch_paid', event.target.value)}
                                                                                placeholder="Lunch Paid"
                                                                                value={SelectedTimeClockRecord.lunch_paid}
                                                                            >
                                                                                <option value={0}>Lunch Not Paid</option>
                                                                                <option value={1}>Lunch Paid</option>
                                                                            </select> 
                                                                            <label className="text-muted" htmlFor="lunch_paid">Lunch Paid</label>                                                   
                                                                        </div>
                                                                        </>
                                                                    </td>
                                                                    <td className="col-3 border-bottom-0">
                                                                        <>
                                                                        <div className="mb-2">
                                                                            <label className="text-muted" htmlFor="shift_start_hour">Scheduled Start</label>
                                                                            {TimeInput("time_scheduled_in")}
                                                                        </div>
                                                                        <div className="">
                                                                            <label className="text-muted" htmlFor="shift_end_hour">Scheduled End</label>
                                                                            {TimeInput("time_scheduled_out")}
                                                                        </div>
                                                                        </>
                                                                    </td>

                                                                    {/* Time Actual In / Out */}
                                                                    <td className="col-3 border-bottom-0">
                                                                        { 
                                                                            SelectedTimeClockRecord.TCRAs.length > 0 &&
                                                                            SelectedTimeClockRecord.TCRAs.map((tcra, index2) => {
                                                                                return (
                                                                                    <div key={`${tcra.time_clock_records_actual_ID}_time_actual`} className={`${SelectedTimeClockRecord.TCRAs.length-1 !== parseInt(index2) && 'mb-4'} ${index2 > 0 && 'border-top border-secondary pt-4'}`}>
                                                                                        <div className="mb-2">
                                                                                            <label className="text-muted float-start" htmlFor="shift_start_hour">
                                                                                                {/* TO DO: Add date pick that replaced the line of the time picker on click of calendar. This would directly update the time_actual_in date piece of this value. */}
                                                                                                {/* <span className="text-primary" onClick={() => handleButtonClick('show_date_picker', 'time_actual_in', tcra.time_actual_in, tcra.time_clock_records_actual_ID)}><FontAwesomeIcon icon="fa-pen" className="me-1" /></span>{(parseInt(s.shift_ID) > 0 && 'Sch') || 'Reg'} */}
                                                                                                Actual Start
                                                                                            </label> 
                                                                                            {index2 === 0 && <button className="badge bg-white rounded-pill border border-primary text-primary float-start ms-2" onClick={() => handleButtonClick('set_actual_start_to_schedule_time', SelectedTimeClockRecord, tcra.time_clock_records_actual_ID)}>Match Sch</button>}
                                                                                            <button className="badge bg-white rounded-pill border border-secondary text-secondary float-end" onClick={() => handleButtonClick('clear_actual_start', SelectedTimeClockRecord, tcra.time_clock_records_actual_ID)}>Clear</button>
                                                                                            {TimeInput("time_actual_in", tcra.time_clock_records_actual_ID)}
                                                                                        </div>
                                                                                        <div className="">
                                                                                            <label className="text-muted float-start" htmlFor="shift_end_hour">Actual End</label>
                                                                                            {index2 === 0 && <button className="badge bg-white rounded-pill border border-primary text-primary float-start ms-2" onClick={() => handleButtonClick('set_actual_end_to_schedule_time', SelectedTimeClockRecord, tcra.time_clock_records_actual_ID)}>Match Sch</button>}
                                                                                            <button className="badge bg-white rounded-pill border border-secondary text-secondary float-end" onClick={() => handleButtonClick('clear_actual_end', SelectedTimeClockRecord, tcra.time_clock_records_actual_ID)}>Clear</button>
                                                                                            {TimeInput("time_actual_out", tcra.time_clock_records_actual_ID)}
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            SelectedTimeClockRecord.TCRAs.length === 0 &&
                                                                            <>
                                                                                <div className="mb-2">
                                                                                    <label className="text-muted float-start" htmlFor="shift_start_hour">Actual Start</label> 
                                                                                    <button className="badge bg-white rounded-pill border border-primary text-primary float-start ms-2" onClick={() => handleButtonClick('set_actual_start_to_schedule_time', SelectedTimeClockRecord, 0)}>Match Sch</button>
                                                                                    <button className="badge bg-white rounded-pill border border-secondary text-secondary float-end" onClick={() => handleButtonClick('clear_actual_start', SelectedTimeClockRecord, 0)}>Clear</button>
                                                                                    {TimeInput("time_actual_in",0)}
                                                                                </div>
                                                                                <div className="">
                                                                                    <label className="text-muted float-start" htmlFor="shift_end_hour">Actual End</label>
                                                                                    <button className="badge bg-white rounded-pill border border-primary text-primary float-start ms-2" onClick={() => handleButtonClick('set_actual_end_to_schedule_time', SelectedTimeClockRecord, 0)}>Match Sch</button>
                                                                                    <button className="badge bg-white rounded-pill border border-secondary text-secondary float-end" onClick={() => handleButtonClick('clear_actual_end', SelectedTimeClockRecord, 0)}>Clear</button>
                                                                                    {TimeInput("time_actual_out",0)}
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </td>

                                                                    {/* Time Pay In / Out */}
                                                                    <td className="col-3 border-bottom-0">
                                                                        { 
                                                                            SelectedTimeClockRecord.TCRAs.length > 0 &&
                                                                            SelectedTimeClockRecord.TCRAs.map((tcra, index2) => {
                                                                                return (
                                                                                    <div key={`${tcra.time_clock_records_actual_ID}_time_pay`} className={`${SelectedTimeClockRecord.TCRAs.length-1 !== parseInt(index2) && 'mb-4'} ${index2 > 0 && 'border-top border-secondary pt-4'}`}>
                                                                                        <div className="mb-2">
                                                                                            <label className="text-muted float-start" htmlFor="shift_start_hour">Pay Start</label>
                                                                                            {index2 === 0 && <button className="badge bg-white rounded-pill border border-primary text-primary float-start ms-2" onClick={() => handleButtonClick('set_pay_start_to_schedule_time', SelectedTimeClockRecord, tcra.time_clock_records_actual_ID)}>Match Sch</button>}
                                                                                            <button className="badge bg-white rounded-pill border border-primary text-primary float-start ms-2" onClick={() => handleButtonClick('recalc_pay_in', SelectedTimeClockRecord, tcra.time_clock_records_actual_ID)}>Recalc</button>
                                                                                            <button className="badge bg-white rounded-pill border border-secondary text-secondary float-end" onClick={() => handleButtonClick('clear_pay_start', SelectedTimeClockRecord, tcra.time_clock_records_actual_ID)}>Clear</button>
                                                                                            {TimeInput("time_pay_in", tcra.time_clock_records_actual_ID)}
                                                                                        </div>
                                                                                        <div className="">
                                                                                            <label className="text-muted float-start" htmlFor="shift_end_hour">Pay End</label>
                                                                                            {index2 === 0 && <button className="badge bg-white rounded-pill border border-primary text-primary float-start ms-2" onClick={() => handleButtonClick('set_pay_end_to_schedule_time', SelectedTimeClockRecord, tcra.time_clock_records_actual_ID)}>Match Sch</button>}
                                                                                            <button className="badge bg-white rounded-pill border border-primary text-primary float-start ms-2" onClick={() => handleButtonClick('recalc_pay_out', SelectedTimeClockRecord, tcra.time_clock_records_actual_ID)}>Recalc</button>
                                                                                            <button className="badge bg-white rounded-pill border border-secondary text-secondary float-end" onClick={() => handleButtonClick('clear_pay_end', SelectedTimeClockRecord, tcra.time_clock_records_actual_ID)}>Clear</button>
                                                                                            {TimeInput("time_pay_out", tcra.time_clock_records_actual_ID)}
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            SelectedTimeClockRecord.TCRAs.length === 0 &&
                                                                            <>
                                                                                <div className="mb-2">
                                                                                    <label className="text-muted float-start" htmlFor="shift_start_hour">Pay Start</label>
                                                                                    <button className="badge bg-white rounded-pill border border-primary text-primary float-start ms-2" onClick={() => handleButtonClick('set_pay_start_to_schedule_time', SelectedTimeClockRecord, 0)}>Match Sch</button>
                                                                                    <button className="badge bg-white rounded-pill border border-primary text-primary float-start ms-2" onClick={() => handleButtonClick('recalc_pay_in', SelectedTimeClockRecord, 0)}>Recalc</button>
                                                                                    <button className="badge bg-white rounded-pill border border-secondary text-secondary float-end" onClick={() => handleButtonClick('clear_pay_start', SelectedTimeClockRecord, 0)}>Clear</button>
                                                                                    {TimeInput("time_pay_in",0)}
                                                                                </div>
                                                                                <div className="">
                                                                                    <label className="text-muted float-start" htmlFor="shift_end_hour">Pay End</label>
                                                                                    <button className="badge bg-white rounded-pill border border-primary text-primary float-start ms-2" onClick={() => handleButtonClick('set_pay_end_to_schedule_time', SelectedTimeClockRecord, 0)}>Match Sch</button>
                                                                                    <button className="badge bg-white rounded-pill border border-primary text-primary float-start ms-2" onClick={() => handleButtonClick('recalc_pay_out', SelectedTimeClockRecord, 0)}>Recalc</button>
                                                                                    <button className="badge bg-white rounded-pill border border-secondary text-secondary float-end" onClick={() => handleButtonClick('clear_pay_end', SelectedTimeClockRecord, 0)}>Clear</button>
                                                                                    {TimeInput("time_pay_out",0)}
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </td>
                                                                    <td className="col-1 mt-1 border-bottom-0">
                                                                        <div className="mt-3 mb-1">
                                                                            <button className="btn fw-bold border border-3 border-success text-success w-100" onClick={() => saveTimeClockRecord()}>
                                                                                { SelectedTimeClockRecord.save_button_text === 'Save' && <span><FontAwesomeIcon icon="fa-floppy-disk" className="me-2 fa-lg" />Save</span>}
                                                                                { SelectedTimeClockRecord.save_button_text === 'Saved' && <span><FontAwesomeIcon icon="fa-check" className="me-2 fa-lg" />Saved</span>}
                                                                            </button>
                                                                        </div>
                                                                        <div className="mt-4">
                                                                            <button className="btn mt-2 fw-bold border border-3 border-secondary text-secondary w-100" onClick={() => handleButtonClick('close_time_clock_record', DefaultSelectedTimeClockRecord)}><FontAwesomeIcon icon="fa-times" className="me-2 fa-lg" />{ ( SelectedTimeClockRecord.save_button_text === 'Save' && 'Cancel' ) || 'Close'}</button>
                                                                        </div>
                                                                    </td>
                                                                    <td className={`col-2 ${SelectedTimeClockRecord.save_message !== '' && 'border-bottom-0'}`}>
                                                                        <div className="form-floating">
                                                                            <select 
                                                                                className="form-select" 
                                                                                disabled={false}
                                                                                id="schedule_designation_ID"
                                                                                onChange={(event) => changeInputValue('schedule_designation_ID', event.target.value)}
                                                                                placeholder="Schedule Designation"
                                                                                value={SelectedTimeClockRecord.ScheduleDesignation.schedule_designation_ID}
                                                                            >
                                                                            
                                                                                <option value={0} key={0}>Choose Designation</option>
                                                                                {ScheduleDesignations.map((sd) => {
                                                                                    return <option value={parseInt(sd.schedule_designation_ID)} key={parseInt(sd.schedule_designation_ID)}>{sd.schedule_designation}</option>
                                                                                })}
                                                                            </select> 
                                                                            <label className="text-muted" htmlFor="schedule_designation_ID">Schedule Designation</label>                                                   
                                                                        </div>
                                                                    </td>
                                                                    <td className={`col-1 ${SelectedTimeClockRecord.save_message !== '' && 'border-bottom-0'}`}>
                                                                        <div className="input-group" style={{height: 25+'px'}}>
                                                                            <input className="form-control float-end px-1" style={{height: 25+'px', fontSize: 13+'px'}} value={SelectedTimeClockRecord.ScheduleDesignation.hours || ''} onChange={(event) => changeInputValue('hours', event.target.value)} />
                                                                            <span className="input-group-text px-1" style={{height: 25+'px', fontSize: 13+'px'}}>hours</span>
                                                                        </div>
                                                                        <div className="input-group mt-2" style={{height: 25+'px'}}>
                                                                            <input className="form-control float-end px-1" style={{height: 25+'px', fontSize: 13+'px'}} value={SelectedTimeClockRecord.ScheduleDesignation.minutes || ''} onChange={(event) => changeInputValue('minutes', event.target.value)} />
                                                                            <span className="input-group-text px-1" style={{height: 25+'px', fontSize: 13+'px'}}>mins.</span>
                                                                        </div>
                                                                    </td>
                                                                    <td className={`col-7  ${SelectedTimeClockRecord.save_message !== '' && 'border-bottom-0'}`}>
                                                                        <div className="form-floating">
                                                                            <input className="form-control float" id="note" value={SelectedTimeClockRecord.tcrn_note || ''} onChange={(event) => changeInputValue('tcrn_note', event.target.value)} /> 
                                                                            <label className="text-muted" htmlFor="note">Note</label>
                                                                        </div>
                                                                    </td>
                                                                    <td className={`col-2  ${SelectedTimeClockRecord.save_message !== '' && 'border-bottom-0'}`}>
                                                                        {SelectedTimeClockRecord.tcrn_creator !== null && SelectedTimeClockRecord.tcrn_creator !== '' &&
                                                                            <div className="">{SelectedTimeClockRecord.tcrn_creator} <span className="float-end">{dayjs(SelectedTimeClockRecord.tcrn_date_created).format('M-D h:mm a')}</span></div>
                                                                        }
                                                                        {SelectedTimeClockRecord.tcrn_updater !== null && SelectedTimeClockRecord.tcrn_updater !== '' &&
                                                                            <div className="">{SelectedTimeClockRecord.tcrn_updater} <span className="float-end">{dayjs(SelectedTimeClockRecord.tcrn_date_updated).format('M-D h:mm a')}</span></div>
                                                                        }
                                                                    </td>
                                                                    {/* Save Message */}
                                                                    {
                                                                        SelectedTimeClockRecord.save_message !== '' &&
                                                                        <td className="col-12">
                                                                            <div className={`alert alert-${SelectedTimeClockRecord.save_message_class} m-0`}>{SelectedTimeClockRecord.save_message}</div>
                                                                        </td>
                                                                    }
                                                                </>
                                                            }
                                                        </tr>

                                                        {
                                                            index+1 === ss.Schedule.length &&
                                                            <tr className="row border border-secondary border-3 border-bottom-0 border-start-0 border-end-0 mb-2">
                                                                <td className={`col-1 ps-3`}>
                                                                </td>
                                                                <td className={`col-1 ps-3`}>
                                                                </td>
                                                                <td className={`col-2 ps-3`}>
                                                                </td>
                                                                <td className={`col-1 ps-3`}>
                                                                    <span className="fw-bolder">{temp_total_hours_scheduled}</span>
                                                                </td>
                                                                <td className={`col-2 ps-3`}>
                                                                </td>
                                                                <td className={`col-1 ps-3`}>
                                                                    <span className="fw-bolder">{temp_total_hours_actual}</span>
                                                                </td>
                                                                <td className={`col-2 ps-3`}>
                                                                </td>
                                                                <td className={`col-2 ps-3`}>
                                                                    <span className="fw-bolder">{add(temp_total_hours_paid, temp_total_hours_holiday).toFixed(2)} {temp_total_hours_holiday !== 0 && <span> ({temp_total_hours_holiday} Holiday)</span>} {temp_total_hours_paid > 40 && <div>({add(temp_total_hours_paid, -40).toFixed(2)} OT)</div>}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </React.Fragment>
                                                )
                                            } else if ( index+1 === ss.Schedule.length ) {
                                                return (
                                                    <tr className="row border border-secondary border-3 border-bottom-0 border-start-0 border-end-0 mb-2" key={`${ss.user_ID}_${index}_0`}>
                                                        <td className={`col-1 ps-3`}>
                                                        </td>
                                                        <td className={`col-1 ps-3`}>
                                                        </td>
                                                        <td className={`col-2 ps-3`}>
                                                        </td>
                                                        <td className={`col-1 ps-3`}>
                                                            <span className="fw-bolder">{temp_total_hours_scheduled}</span>
                                                        </td>
                                                        <td className={`col-2 ps-3`}>
                                                        </td>
                                                        <td className={`col-1 ps-3`}>
                                                            <span className="fw-bolder">{temp_total_hours_actual}</span>
                                                        </td>
                                                        <td className={`col-2 ps-3`}>
                                                        </td>
                                                        <td className={`col-2 ps-3`}>
                                                            <span className="fw-bolder">{add(temp_total_hours_paid, temp_total_hours_holiday).toFixed(2)} {temp_total_hours_holiday !== 0 && <span> ({temp_total_hours_holiday} Holiday)</span>} {temp_total_hours_paid > 40 && <div>({add(temp_total_hours_paid, -40).toFixed(2)} OT)</div>}</span>
                                                        </td>
                                                    </tr>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </React.Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                }

                {/* Hours Summary - BaseState.view = 'view_hours */}
                {
                    BaseState.view === 'view_hours' &&
                    SelectedSchedule.Employees.length > 0 &&
                    <table className="table table-striped">
                        <thead className="row h6 border border-2 border-top-0 border-start-0 border-end-0 border-dark">
                            <tr className="col-2">Employee</tr>
                            <tr className="col-2">Week Of</tr>
                            <tr className="col-1">
                                Regular
                            </tr>
                            <tr className="col-1">
                                OT
                            </tr>
                            <tr className="col-1">
                                Holiday
                            </tr>
                            {/* <tr className="col-1">
                                Personal
                            </tr>
                            <tr className="col-1">
                                Holiday
                            </tr> */}
                            <tr className="col-3">
                                Total Hours
                            </tr>
                        </thead>
                        <tbody className="">
                            {/* Loop each Employee */}
                            {SelectedSchedule.Employees.map((ss) => {
                                let temp_total_hours_paid = 0;
                                let temp_holiday_hours = 0;
                                // Loop each TCR
                                ss.Schedule.forEach((s) => {
                                    // Loop each TCRA for this TCR
                                    s.TCRAs.forEach((tcra) => {
                                        if( tcra.time_pay_hours !== '' && tcra.time_pay_hours !== undefined && parseInt(s.ScheduleDesignation.schedule_designation_ID) === 0) temp_total_hours_paid = add(temp_total_hours_paid, tcra.time_pay_hours).toFixed(2);
                                        if( tcra.time_pay_hours !== '' && tcra.time_pay_hours !== undefined && parseInt(s.ScheduleDesignation.schedule_designation_ID) === 5) temp_holiday_hours = add(temp_holiday_hours, tcra.time_pay_hours).toFixed(2);
                                    });
                                });
                                return (
                                    <tr className="row" key={ss.user_ID}>
                                        <td className="col-2">
                                            {ss.last_name}, {ss.first_name}
                                        </td>
                                        <td className={`col-2 ps-3`}>
                                            {dayjs(BaseState.schedule_start).format('M/D/YY')} - {dayjs(BaseState.schedule_end).format('M/D/YY')} 
                                        </td>
                                        <td className={`col-1 ps-3`}>
                                            {temp_total_hours_paid >= 40 && '40'}
                                            {temp_total_hours_paid < 40 && temp_total_hours_paid}
                                        </td>
                                        <td className={`col-1 ps-3`}>
                                            {temp_total_hours_paid > 40 && add(temp_total_hours_paid, -40).toFixed(2)}
                                        </td>
                                        <td className={`col-1 ps-3`}>
                                            {temp_holiday_hours > 0 && temp_holiday_hours}   
                                        </td>
                                        {/* <td className={`col-1 ps-3`}>

                                        </td>
                                        <td className={`col-1 ps-3`}>
                                            
                                        </td> */}
                                        <td className={`col-3 ps-3`}>
                                            {add(temp_holiday_hours, temp_total_hours_paid).toFixed(2)}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                }                
            </div>
        </>
    )
}

export const TimeClockRecordsNewComponent = compose(
	queryWithLoading({
		gqlString: GetTimeClockRecordsPage,
        name: "componentData"
	})
)(TimeClockRecordsContent)

