import { gql } from '@apollo/client';

export const GetMachineBulletin = gql`
    query GetMachineBulletin($machine_ID: Int) {
        machine_bulletin (machine_ID: $machine_ID) {
            machine_bulletin_ID
            archiver_ID
            creator_ID
            date_archived
            date_created
            first_name
            last_name
            machine_ID
            note
            replaced_by_machine_bulletin_ID
        }
    }
`;

export const GetMachineBulletinsPage = gql`
    query GetMachineBulletinsPage {
        machine_bulletins_axon {
            machine_bulletin_ID
            archiver_ID
            creator_ID
            date_archived
            date_created
            first_name
            last_name
            machine_ID
            note
            replaced_by_machine_bulletin_ID
        }
        machines_axon {
            machine_ID
            machine_name
            machine_short_name
            machine_status_ID
            part_ID
        }
    }
`;

export const GetMachineBulletins = gql`
    query GetMachineBulletins($machine_ID: Int) {
        machine_bulletins (machine_ID: $machine_ID) {
            machine_bulletin_ID
            archiver_ID
            creator_ID
            date_archived
            date_created
            first_name
            last_name
            machine_ID
            note
            replaced_by_machine_bulletin_ID
        }
    }
`;

export const GetMachineBulletinsAxon = gql`
    query GetMachineBulletinsAxon {
        machine_bulletins_axon {
            machine_bulletin_ID
            archiver_ID
            creator_ID
            date_archived
            date_created
            first_name
            last_name
            machine_ID
            note
            replaced_by_machine_bulletin_ID
        }
    }
`;

export const SaveMachineBulletin = gql`
	mutation machineBulletinSave($input: MachineBulletinInput) {
		machineBulletinSave (input: $input) {
            MachineBulletin {
                machine_bulletin_ID
                archiver_ID
                creator_ID
                date_archived
                date_created
                first_name
                last_name
                machine_ID
                note
                replaced_by_machine_bulletin_ID
            }
		}
	}
`;

