import React from 'react';
import _ from 'lodash';
import {flowRight as compose} from 'lodash';
import { queryWithLoading } from '../utilities/NSDb.js';
import { useState } from 'react';
// import dayjs from 'dayjs';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
import { useMutation } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// GRAPHQL QUERY
import { 
    // GetMaterials,
    GetMaterialsPage,
    SaveMaterial
} from './materials_graphql.js';

function MaterialsContent(props) {

    // Set const values from props
    const { suppliers: Suppliers } = props.componentData;

    // Alphabetically sort the materials
    //let TempMaterials = sortMaterialNames(props.Materials.materials);

    // const [archive_material] = useMutation(ArchiveMaterial);
    const [save_material] = useMutation(SaveMaterial);

    const DefaultSelectedMaterial = {
        cost_per_piece: '',
        cost_per_pound: '',
        description: '',
        material_ID: 0,
        material_type_ID: 0,
        part_number: '',
        parts_per: '',
        save_button_text: 'Save',
        save_message: '',
        supplier_ID: 0,
        weight: ''
    };

    const [SelectedMaterial, setSelectedMaterial] = useState({ ...DefaultSelectedMaterial });
    const [BaseState, setBaseState] = useState({
        action: '',
        Materials: props.componentData.materials
    });

    // Alphabetically sort the materials
    function sortMaterialNames(materials) {
        let TempMaterials = [...materials];
        if(TempMaterials.length > 0) {
            return TempMaterials.sort(function(a,b) {
                if(a.part_number.toLowerCase() < b.part_number.toLowerCase()) return -1;
                if(a.part_number.toLowerCase() > b.part_number.toLowerCase()) return 1;
                return 0;
            });
        } else {
            return [];
        }
    }

    // Archive / Unarchive the material
    // archiveMaterial = (material_ID, archiver_ID=0) => {
    //     let new_archiver_ID = archiver_ID > 0 ? 0 : 1;
    //     saveMaterial(material_ID, new_archiver_ID, 'archive');
    // }

    // Save material details
    async function saveMaterial(material_ID) {

        let SaveData = {...SelectedMaterial};

        // Parse string values that are IDs to be integers, saving stringified numbers to the database throws an error.
        let MakeIntegers = ['material_ID', 'supplier_ID', 'material_type_ID'];
        MakeIntegers.forEach((mi) => SaveData[mi] = parseInt(SaveData[mi]));

        // _.omit(SelectedMaterial, ['save_button_text','save_message',''])
        const result = await save_material({ variables: { input: _.omit(SaveData, ['material_type', 'save_button_text', 'save_message', 'supplier_name']) }});

        // Updating a material - save returns a single material object
        if(result.data.materialSave.UpdatedMaterial && result.data.materialSave.UpdatedMaterial.material_ID > 0) {
            // Update the Materials array with the new saved information
            let TempMaterials = [...BaseState.Materials];
            let temp_save_message = '';
            let temp_action = 'edited_material';

            // If this was an update, update the Materials object for the updated material_ID
            if(material_ID > 0) {
                TempMaterials = TempMaterials.map((m) => {
                    if(parseInt(m.material_ID) === parseInt(result.data.materialSave.UpdatedMaterial.material_ID)) {
                        return result.data.materialSave.UpdatedMaterial
                    } else {
                        return m
                    }
                })

                // Set save_message for next to Save button
                temp_save_message = 'Materials successfully updated'

                // Update the button and save message to show for updated success
                setSelectedMaterial({
                    ...SelectedMaterial,
                    save_button_text: 'Saved',
                    save_message: temp_save_message
                })

            } else {
            
                // For inserts, add the new tooling object to the Materials array, then re-sort the Materials by description string
                TempMaterials.push(result.data.materialSave.UpdatedMaterial);
                TempMaterials = sortMaterialNames(TempMaterials);

                // Set save_message for next to Save button
                temp_save_message = 'Materials successfully added';
                temp_action = 'added_material';

                // Update the SelectedMaterial with the new material_ID that was created
                setSelectedMaterial({
                    ...SelectedMaterial,
                    material_ID: parseInt(result.data.materialSave.UpdatedMaterial.material_ID),
                    save_button_text: 'Saved',
                    save_message: temp_save_message
                });

            }

            setBaseState({
                ...BaseState,
                action: temp_action,
                Materials: TempMaterials
            });
        }
    }

    // Handler function for form's changing inputs
    function changeInputValue(name, value) {
        // For inputs that must be integers, do not allow non numeric characters, and parseInt on the value
        if ( name === 'cost_per_piece' || name === 'cost_per_pound' || name === 'parts_per' || name === 'weight' ) {
            if(value !== '') {
                // Strips non-numeric characters, leaves decimal
                value = value.replace(/[^\d.-]/g, '');
                // Handle decimal length restrictions
                if (value.includes('.')) {
                    let decimal_length = value.split('.')[1].length;
                    if( (name === 'cost_per_piece' || name === 'cost_per_pound' || name === 'weight') && decimal_length > 4 ) {
                        value = parseFloat(value).toFixed(4)
                    } else if ( name === 'parts_per' && decimal_length > 2 ) {
                        value = parseFloat(value).toFixed(2)
                    }
                };
            }
        }

        setSelectedMaterial({
            ...SelectedMaterial, 
            [name]: value,
            save_button_text: 'Save', 
            save_message: ''
        });

    }

    // Handler function for all non-save buttons - Selected will always be the SelectedMaterial object of whatever is being clicked. 'Add' is the defaultSelectedMaterial object.
    function handleButtonClick(action, Selected) {
        let TempSelected = action === 'close_material' ? { ...Selected } : { ...Selected, save_button_text: 'Save', save_message: ''};
        setSelectedMaterial({
            ...TempSelected
        });

        setBaseState({
            ...BaseState,
            action: action
        })
    }

    return (
        <>
            <div>
                <div className="row clearfix mb-3">
                    {/* RESULTS COUNTER AND FILTERS */}
                    <div className="col h4 mb-0 pt-3">
                        {BaseState.Materials.length} Materials
                    </div>

                    <div className="col float-end">
                        {
                            BaseState.action === 'add_material' &&
                            <div className="">
                                <span className="col-auto float-end ms-2">
                                    <button 
                                        className="col-auto btn border-primary border-3 text-primary fw-bold disabled" 
                                        disabled={true}
                                    >
                                    Enter New Material Info Below
                                    </button>
                                </span>
                            </div>
                        }
                        {
                            ((SelectedMaterial.material_ID === 0 && BaseState.action !== 'add_material') || SelectedMaterial.material_ID > 0) &&
                            <div className="">
                                <button 
                                    className="btn fw-bold col-auto border-primary border-3 float-end text-primary" 
                                    onClick={() => handleButtonClick('add_material', DefaultSelectedMaterial)}
                                >
                                    <FontAwesomeIcon icon="fa-plus" className="me-2" />Add Material
                                </button>
                                {
                                    BaseState.action === 'added_material' &&
                                    <span className="col-auto float-end me-2">
                                        <div className="alert alert-success p-2">Material added successfully</div>
                                    </span>
                                }
                            </div>
                        }
                    </div>
                </div>
                {
                    <table className="table table-hover">
                        <thead className="row h6 border border-2 border-top-0 border-start-0 border-end-0 border-dark">
                            <tr className="col-1">Edit</tr>
                            <tr className="col-2">Part Number</tr>
                            <tr className="col-2">Material Type</tr>
                            <tr className="col-3">Description</tr>
                            <tr className="col-2">Supplier</tr>
                            <tr className="col-1">Cost</tr>
                            <tr className="col-1">Weight</tr>
                        </thead>
                        {
                            (BaseState.Materials.length > 0 || BaseState.action === 'add_material') &&
                            <tbody className="">
                                {BaseState.action === 'add_material' &&
                                    <tr className="row" key="0">
                                        <td className="border-0 col-1">
                                        </td>
                                        <td className="border-0 col-2">
                                            <div className="form-floating">
                                                <input className="form-control float" id="part_number" value={SelectedMaterial.part_number} onChange={(event) => changeInputValue('part_number', event.target.value)} /> 
                                                <label className="text-muted" htmlFor="part_number">Part Number</label>
                                            </div>
                                        </td>
                                        {/* Material Type select */}
                                        <td className="border-0 col-2">
                                            <div className="form-floating">
                                                <select 
                                                    className="form-select" 
                                                    disabled={false}
                                                    id="material_type"
                                                    onChange={(event) => changeInputValue('material_type_ID', event.target.value)}
                                                    placeholder="Material Type"
                                                    value={SelectedMaterial.material_type_ID}
                                                >
                                                    <option value={0}>Choose a Material Type</option>
                                                    <option value="1">Bar Stock</option>
                                                    <option value="2">Packaging</option>
                                                </select> 
                                                <label className="text-muted" htmlFor="material_type">Material Type</label>                                                   
                                            </div>
                                        </td>
                                        {/* Material Description */}
                                        <td className="border-0 col-3">
                                            <div className="form-floating">
                                                <input className="form-control mb-3" id="description" placeholder="Enter a material description" value={SelectedMaterial.description || ""} onChange={(event) => changeInputValue('description', event.target.value)} />
                                                <label className="text-muted" htmlFor="description">Description</label>
                                            </div>
                                        </td>
                                        {/* Selecting Supplier from list */}
                                        <td className="border-0 col-2">
                                            <div className="form-floating">
                                                <select 
                                                    className="form-select mb-3" 
                                                    disabled={false}
                                                    id="Supplier"
                                                    onChange={(event) => changeInputValue('supplier_ID', event.target.value)}
                                                    placeholder="Supplier Name"
                                                    value={SelectedMaterial.supplier_ID}
                                                >
                                                    <option key={0} value={0}>Choose a Supplier</option>
                                                    {Suppliers.map((s) => {
                                                        return (
                                                            <option key={s.supplier_ID} value={s.supplier_ID}>{s.supplier_name}</option>
                                                        )
                                                    })}
                                                </select>
                                                <label className="text-muted" htmlFor="supplier">Supplier</label>
                                            </div>
                                        </td>
                                        {/* Cost inputs */}
                                        <td className="border-0 col-1">
                                            <div className="input-group" style={{height: 25+'px'}}>
                                                <input className="form-control float-end px-1" style={{height: 25+'px', fontSize: 13+'px'}} value={SelectedMaterial.cost_per_pound} onChange={(event) => changeInputValue('cost_per_pound', event.target.value)} />
                                                <span className="input-group-text px-1" style={{height: 25+'px', fontSize: 13+'px'}}>/ lb.</span>
                                            </div>
                                            <div className="input-group mt-2" style={{height: 25+'px'}}>
                                                <input className="form-control float-end px-1" style={{height: 25+'px', fontSize: 13+'px'}} value={SelectedMaterial.cost_per_piece} onChange={(event) => changeInputValue('cost_per_piece', event.target.value)} />
                                                <span className="input-group-text px-1" style={{height: 25+'px', fontSize: 13+'px'}}>/ pc</span>
                                            </div>
                                        </td>
                                        {/* Weight */}
                                        <td className="border-0 col-1">
                                            <div className="form-floating">
                                                <input className="form-control float" id="weight" value={SelectedMaterial.weight} onChange={(event) => changeInputValue('weight', event.target.value)} /> 
                                                <label className="text-muted" htmlFor="weight">Weight</label>
                                            </div>
                                        </td>

                                        {/* Buttons Row */}
                                        <td className="border-0 col-1" />
                                        <td className="border-0 col-11 pt-0">
                                            <div className="row">
                                                <span className="col-auto float-start">
                                                    <button className="btn fw-bold col-auto border-success border-3 text-success" disabled={SelectedMaterial.part_number === ''} onClick={() => saveMaterial(0)}>{SelectedMaterial.save_button_text} {SelectedMaterial.save_button_text === 'Saved' && <FontAwesomeIcon icon="fa-check" className="ms-1" />}</button>
                                                </span>
                                                {
                                                    SelectedMaterial.save_message !== '' &&
                                                    <span className="col-auto float-start">
                                                        <div className="alert alert-success p-2 mb-0">{SelectedMaterial.save_message}</div>
                                                    </span>
                                                }
                                                <span className="col float-end">
                                                    <button className="btn fw-bold col-auto border-secondary border-3 text-secondary float-end" onClick={() => handleButtonClick('', DefaultSelectedMaterial)}>{(parseInt(SelectedMaterial.material_ID) > 0 && 'Close') || 'Cancel'}</button>
                                                </span>
                                            </div>
                                        </td>

                                    </tr>
                                }
                                {BaseState.Materials.map((m) => {
                                    // let archive_button_class = m.archiver_ID > 0 ? 'btn-success' : 'btn-danger';
                                    // let archive_button_text = m.archiver_ID > 0 ? 'Unarchive' : 'Archive';
                                    if(parseInt(m.material_ID) === parseInt(SelectedMaterial.material_ID)) {
                                        // Disable the save button until an input has changed.
                                        // let {part_number, hourly_standard_rate, sale_price, standard_tool_cost, scrap_percentage_cost_sheet} = SelectedMaterial;
                                        // let inputs_not_changed = part_number === m.part_number && hourly_standard_rate === m.hourly_standard_rate && sale_price === m.sale_price && standard_tool_cost === m.standard_tool_cost && scrap_percentage_cost_sheet === m.scrap_percentage_cost_sheet;
                                        // let disable_save_button = inputs_not_changed || SelectedMaterial.save_button_text === 'Saving' || SelectedMaterial.save_button_text === 'Saved';
                                        // let disable_save_button = SelectedMaterial.save_button_text === 'Saving' || SelectedMaterial.save_button_text === 'Saved';
                                        // let save_button_tip = disable_save_button ? "Nothing has not been changed" : '';
                                        // If adding a new material and the part_number is blank, do not allow saving
                                        // if(save_button_tip === '' && SelectedMaterial.part_number === '') {
                                        //     disable_save_button = true;
                                        //     save_button_tip = 'You must enter a material number'
                                        // }
                                        return (
                                            <React.Fragment key={m.material_ID}>
                                                <tr className="row">
                                                    <td className="border-0 col-1">
                                                    </td>
                                                    <td className="border-0 col-2">
                                                        <div className="form-floating">
                                                            <input className="form-control float" id="part_number" value={SelectedMaterial.part_number} onChange={(event) => changeInputValue('part_number', event.target.value)} /> 
                                                            <label className="text-muted" htmlFor="part_number">Part Number</label>
                                                        </div>
                                                    </td>
                                                    {/* Material Type select */}
                                                    <td className="border-0 col-2">
                                                        <div className="form-floating">
                                                            <select 
                                                                className="form-select" 
                                                                disabled={false}
                                                                id="material_type"
                                                                onChange={(event) => changeInputValue('material_type_ID', event.target.value)}
                                                                placeholder="Material Type"
                                                                value={SelectedMaterial.material_type_ID}
                                                            >
                                                                <option value={0}>Choose a Material Type</option>
                                                                <option value="1">Bar Stock</option>
                                                                <option value="2">Packaging</option>
                                                            </select> 
                                                            <label className="text-muted" htmlFor="material_type">Material Type</label>                                                   
                                                        </div>
                                                    </td>
                                                    {/* Material Description */}
                                                    <td className="border-0 col-3">
                                                        <div className="form-floating">
                                                            <input className="form-control mb-3" id="description" placeholder="Enter a material description" value={SelectedMaterial.description || ""} onChange={(event) => changeInputValue('description', event.target.value)} />
                                                            <label className="text-muted" htmlFor="description">Description</label>
                                                        </div>
                                                    </td>
                                                    {/* Selecting Supplier from list */}
                                                    <td className="border-0 col-2">
                                                        <div className="form-floating">
                                                            <select 
                                                                className="form-select mb-3" 
                                                                disabled={false}
                                                                id="Supplier"
                                                                onChange={(event) => changeInputValue('supplier_ID', event.target.value)}
                                                                placeholder="Supplier Name"
                                                                value={SelectedMaterial.supplier_ID}
                                                            >
                                                                <option key={0} value={0}>Choose a Supplier</option>
                                                                {Suppliers.map((s) => {
                                                                    return (
                                                                        <option key={s.supplier_ID} value={s.supplier_ID}>{s.supplier_name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <label className="text-muted" htmlFor="supplier">Supplier</label>
                                                        </div>
                                                    </td>
                                                    {/* Cost inputs */}
                                                    <td className="border-0 col-1">
                                                        <div className="input-group" style={{height: 25+'px'}}>
                                                            <input className="form-control float-end px-1" style={{height: 25+'px', fontSize: 13+'px'}} value={SelectedMaterial.cost_per_pound} onChange={(event) => changeInputValue('cost_per_pound', event.target.value)} />
                                                            <span className="input-group-text px-1" style={{height: 25+'px', fontSize: 13+'px'}}>/ lb.</span>
                                                        </div>
                                                        <div className="input-group mt-2" style={{height: 25+'px'}}>
                                                            <input className="form-control float-end px-1" style={{height: 25+'px', fontSize: 13+'px'}} value={SelectedMaterial.cost_per_piece} onChange={(event) => changeInputValue('cost_per_piece', event.target.value)} />
                                                            <span className="input-group-text px-1" style={{height: 25+'px', fontSize: 13+'px'}}>/ pc</span>
                                                        </div>
                                                    </td>
                                                    {/* Weight */}
                                                    <td className="border-0 col-1">
                                                        <div className="form-floating">
                                                            <input className="form-control float" id="weight" value={SelectedMaterial.weight} onChange={(event) => changeInputValue('weight', event.target.value)} /> 
                                                            <label className="text-muted" htmlFor="weight">Weight</label>
                                                        </div>
                                                    </td>

                                                    {/* Buttons Row */}
                                                    <td className="border-0 col-1" />
                                                    <td className="border-0 col-11 pt-0">
                                                        <div className="row">
                                                            <span className="col-auto float-start">
                                                                <button className="btn fw-bold col-auto border-success border-3 text-success" disabled={SelectedMaterial.part_number === ''} onClick={() => saveMaterial(SelectedMaterial.material_ID)}>{SelectedMaterial.save_button_text} {SelectedMaterial.save_button_text === 'Saved' && <FontAwesomeIcon icon="fa-check" className="ms-1" />}</button>
                                                            </span>
                                                            {
                                                                SelectedMaterial.save_message !== '' &&
                                                                <span className="col-auto float-start">
                                                                    <div className="alert alert-success p-2 mb-0">{SelectedMaterial.save_message}</div>
                                                                </span>
                                                            }
                                                            <span className="col float-end">
                                                                <button className="btn fw-bold col-auto border-secondary border-3 text-secondary float-end" onClick={() => handleButtonClick('', DefaultSelectedMaterial)}>Close</button>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )

                                    } else {
                                        let part_number_text = m.archiver_ID > 0 ? `(${m.part_number})` : m.part_number;
                                        // let archive_button_class = parseInt(m.archiver_ID) > 0 ? 'border-success text-success' : 'border-danger text-danger';
                                        // let archive_button_icon = parseInt(m.archiver_ID) > 0 ? 'fa-rotate-left' : 'fa-trash-can';

                                        return (
                                            <tr className="row" key={m.material_ID}>
                                                <td className="col-1"><button className="btn btn-sm border-primary text-primary" onClick={() => handleButtonClick('edit_material', m)}><FontAwesomeIcon icon="fa-pencil" className="" /></button></td>
                                                <td className={`col-2 ps-3 ${m.archiver_ID > 0 && 'text-muted'}`}>{part_number_text}</td>
                                                <td className={`col-2 ps-3 ${m.archiver_ID > 0 && 'text-muted'}`}>{m.material_type}</td>
                                                <td className={`col-3 ps-3 ${m.archiver_ID > 0 && 'text-muted'}`}>{m.description}</td>
                                                <td className={`col-2 ps-3 ${m.archiver_ID > 0 && 'text-muted'}`}>{m.supplier_name}</td>
                                                <td className={`col-1 ${m.archiver_ID > 0 && 'text-muted'}`}>
                                                    {m.cost_per_pound !== '' && m.cost_per_pound !== null && <div>${parseFloat(m.cost_per_pound)}<span style={{fontSize: 13+'px'}}> / lb.</span></div>}
                                                    {m.cost_per_piece !== '' && m.cost_per_piece !== null && <div>${parseFloat(m.cost_per_piece)}<span style={{fontSize: 13+'px'}}> / pc</span></div>}
                                                </td>
                                                <td className={`col-1 ${m.archiver_ID > 0 && 'text-muted'}`}>{m.weight !== '' && m.weight !== null && <div>{parseFloat(m.weight)} lb.</div>}</td>
                                            </tr>
                                        )
                                    }
                                })}
                            </tbody>
                        }
                    </table>
                }
            </div>
        </>
    )
}

export const MaterialsComponent = compose(
	queryWithLoading({
		gqlString: GetMaterialsPage,
        name: "componentData"
	})
)(MaterialsContent)

