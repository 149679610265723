import { gql } from '@apollo/client';

export const GetSuppliers = gql`
    query GetSuppliers {
        suppliers {
            supplier_ID,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            supplier_name
        }
    }
`;

export const SaveSupplier = gql`
	mutation supplierSave($input: SupplierInput, $supplier_ID: ID) {
		supplierSave (input: $input) {
            UpdatedSupplier (supplier_ID: $supplier_ID) {
                supplier_ID,
                archiver_ID,
                creator_ID,
                date_archived,
                date_created,
                supplier_name
            }
            Supplier {
                supplier_ID,
                archiver_ID,
                creator_ID,
                date_archived,
                date_created,
                supplier_name
            }
		}
	}
`;