import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import {flowRight as compose} from 'lodash';
import { queryWithLoading } from '../utilities/NSDb.js';
import { useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import dayjs from 'dayjs';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
import { useMutation, useLazyQuery } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// GRAPHQL QUERY
import { 
    GetMachineBulletins,
    GetMachineBulletinsAxon,
    GetMachineBulletinsPage,
    SaveMachineBulletin
} from './machine_bulletins_graphql.js';

function BulletinContent(props) {
    const auth = useAuthUser();
console.log(auth())
    const [save_machine_bulletin] = useMutation(SaveMachineBulletin);
    const [get_machine_bulletins] = useLazyQuery(GetMachineBulletins, { fetchPolicy: 'network-only'});
    const [get_machine_bulletins_axon] = useLazyQuery(GetMachineBulletinsAxon, { fetchPolicy: 'network-only'});

    let DefaultMachine = {
        machine_ID: 0,
        MachineBulletins: []
    }

    let DefaultMachineBulletin = {
        archiver_ID: null,
        creator_ID: null,
        date_archived: null,
        date_created: null,
        first_name: '',
        last_name: '',
        machine_ID: 0,
        machine_bulletin_ID: 0,
        note: '',
        old_note: '',
        replaced_by_machine_bulletin_ID: null,
        saved: false
    };

    const [SelectedMachine, setSelectedMachine] = useState({ ...DefaultMachine });
    const [SelectedMachineBulletin, setSelectedMachineBulletin] = useState({ ...DefaultMachineBulletin });
    const [MachineBulletins, setMachineBulletins] = useState( props.componentData.machine_bulletins_axon );
    const [Machines, setMachines] = useState( props.componentData.machines_axon );

    // Save shift details
    async function saveMachineBulletin(MachineBulletin) {

        let SaveData = {...MachineBulletin};

        // Parse string values that are IDs to be integers, saving stringified numbers to the database throws an error.
        let MakeIntegers = ['machine_bulletin_ID', 'machine_ID'];
        MakeIntegers.forEach((mi) => SaveData[mi] = parseInt(SaveData[mi]));

        // Remove replaced_by_machine_bulletin_ID and old_note from object for saving
        SaveData = _.omit(SaveData, [ 'first_name', 'last_name', 'old_note', 'replaced_by_machine_bulletin_ID','saved']);

        const result = await save_machine_bulletin({ variables: { input: { ...SaveData, creator_ID: parseInt(auth().User.user_ID), date_created: dayjs().format('YYYY-MM-DD HH:mm:ss') } }});

        // Update the MachineBulletins with the newly saved one
        if( result.data && result.data.machineBulletinSave.MachineBulletin ) {

            let NewBulletin = result.data.machineBulletinSave.MachineBulletin;
            let TempMachineBulletins = [ ...MachineBulletins ];

            // If we are on All Machines view, replace this machine_ID's object. Otherwise add this MachineBulletin to the end of the array
            if ( parseInt(SelectedMachine.machine_ID) === 0 ) {

                // If there is a bulletin for this machine_ID already, replace it, otherwise just push the NewBulletin to TempMachineBulletins
                if ( TempMachineBulletins.findIndex((b) => parseInt(b.machine_ID) === parseInt(SaveData.machine_ID)) > -1 ) {
                
                    TempMachineBulletins = TempMachineBulletins.map((b) => {

                        if ( parseInt(b.machine_ID) === parseInt(NewBulletin.machine_ID) ) {
                            return NewBulletin;
                        } else {
                            return b;
                        }
                    });
                } else {
                    TempMachineBulletins.push(NewBulletin)
                }

            } else {

                // Push the new bulletin to the front of the machine bullet array
                TempMachineBulletins.unshift(NewBulletin);
            }

            // Update Selected Machine Bulletin
            setSelectedMachineBulletin({
                ...SelectedMachineBulletin,
                ...NewBulletin,
                old_note: NewBulletin.note,
                saved: true
            });

            // Update MachineBulletins
            setMachineBulletins(
                [...TempMachineBulletins]
            );

        }
    }

    // Handler function for changing filters
    async function handleChangeFilter(name, value) {
        
        if ( name === 'machine_ID' ) {

            // Get the matching Machines object and make it the selected machine
            if ( parseInt(value) === 0 ) {
            
                // Get all of the Bulletins for this machine
                let TempBulletins = await get_machine_bulletins_axon();

                if ( TempBulletins.data && TempBulletins.data.machine_bulletins_axon ) {
                    setMachineBulletins([ ...TempBulletins.data.machine_bulletins_axon ]);
                }

                // Set the SelectedMachineBulletin to the default because this loads a new result and nothing would be selected yet
                setSelectedMachineBulletin({ ...DefaultMachineBulletin });

                setSelectedMachine({
                    ...DefaultMachine
                });

            } else {
            
                // Get all of the Bulletins for this machine
                let TempBulletins = await get_machine_bulletins({ variables: { machine_ID: parseInt(value) } });

                if ( TempBulletins.data && TempBulletins.data.machine_bulletins ) {
                    setMachineBulletins([ ...TempBulletins.data.machine_bulletins ]);
                }

                let TempMachine = Machines.filter((m) => parseInt(m.machine_ID) === parseInt(value))[0];
                setSelectedMachine({
                    ...TempMachine
                });

                // Set the SelectedMachineBulletin to the default because this loads a new result and nothing would be selected yet
                setSelectedMachineBulletin({ ...DefaultMachineBulletin, machine_ID: parseInt(value) });
            }
        }

    }

    console.log({SelectedMachine})
    console.log({SelectedMachineBulletin})
    console.log({MachineBulletins})
console.log("auht:", auth())
    return (
        <div className="">
            {/* WAIT UNTIL MACHINES LIST LOADS BEFORE SHOWING CONTENT */}
            {
                Machines.length > 0 &&
                <>
                    {/* LOGGED OUT MESSAGE */}
                    {
                        auth() === null &&
                        <div className="row justify-content-center">
                            <div className="col-auto">
                                <div className="alert alert-warning text-center py-2 mb-2">THIS VIEW IS NOT LOGGED IN SO BULLETINS CANNOT BE EDITED HERE. TO EDIT BULLETINS DO SO IN A DIFFERENT BROWSER AND LOGIN.</div>
                            </div>
                        </div>
                    }

                    {/* MACHINE SELECTION */}
                    <div className="row justify-content-center mb-2">
                        {/* SMALL SCREENS GET DROPDOWN SELECTION */}
                        {
                            window.innerWidth < 1200 &&
                            <div className="col-auto">
                                <div className="form-floating">
                                    <select 
                                        className="form-select" 
                                        disabled={false}
                                        id="machine_ID"
                                        onChange={(event) => handleChangeFilter('machine_ID', event.target.value)}
                                        placeholder="Machine Selection"
                                        value={SelectedMachine.machine_ID}
                                    >
                                        <option key="0" value="0">All Machines</option>
                                        {Machines.map((m) => {
                                            return <option key={parseInt(m.machine_ID)} value={parseInt(m.machine_ID)}>{( m.machine_short_name !== '' && m.machine_short_name !== null && m.machine_short_name ) || m.machine_name}</option>
                                        })}
                                    </select> 
                                    <label className="text-muted" htmlFor="machine_ID">Machine Selection</label>                                                   
                                </div>
                            </div>
                        }
                        {/* LARGE SCREENS GET BUTTON SELECTION */}
                        {
                            window.innerWidth >= 1200 &&
                            <div className="col-auto">
                                <div className="btn-group btn-group-sm" role="group">
                                    <button className={`btn btn-outline-secondary ${(parseInt(SelectedMachine.machine_ID) === 0 && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('machine_ID', 0)}>ALL</button>
                                    {Machines.map((m) => {
                                        return (
                                            <button key={parseInt(m.machine_ID)} className={`btn btn-outline-secondary ${(parseInt(SelectedMachine.machine_ID) === parseInt(m.machine_ID) && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleChangeFilter('machine_ID', parseInt(m.machine_ID))}>
                                                {( m.machine_short_name !== '' && m.machine_short_name !== null && m.machine_short_name ) || m.machine_name}
                                            </button>
                                        )
                                    })}
                                </div>                            
                            </div>
                        }
                    </div>

                    {/* BULLETIN LIST DISPLAY - ALL MACHINES */}
                    {
                        parseInt(SelectedMachine.machine_ID) === 0 &&
                        <div className="row row-cols-xxl-4 row-cols-xl-3 row-cols-md-2 row-cols-1">
                            {Machines.map((m) => {

                                // Get this Machine's bulletin if there is one
                                let temp_index = MachineBulletins.findIndex((mb) => parseInt(mb.machine_ID) === parseInt(m.machine_ID));
                                return (
                                    <div key={m.machine_ID} className="col mb-4">
                                        <div className={`card h-100 ${parseInt(SelectedMachineBulletin.machine_ID) > 0 && parseInt(SelectedMachineBulletin.machine_ID) !== parseInt(m.machine_ID) && 'bg-secondary'}`}>
                                            <div className="card-header h4 text-center border border-secondary">
                                                {/* VIEW ALL BUTTON */}
                                                <button className="btn border border-primary border-3 bg-white fw-bolder text-primary py-0 float-start" disabled={parseInt(SelectedMachineBulletin.machine_ID) > 0 || temp_index === -1} onClick={(machine_ID) => handleChangeFilter('machine_ID', parseInt(m.machine_ID))} ><FontAwesomeIcon icon="fa-eye" className="mt-1 me-2" />View All</button>
                                                {/* MACHINE NAME */}
                                                {( m.machine_short_name !== '' && m.machine_short_name !== null && m.machine_short_name ) || m.machine_name}
                                                {/* EDIT BUTTON - ONLY IF LOGGED IN*/}
                                                {
                                                    auth() !== null && parseInt(SelectedMachineBulletin.machine_ID) !== parseInt(m.machine_ID)  &&
                                                    <>
                                                        {
                                                            temp_index > -1 &&
                                                            <button  
                                                                className="btn border border-primary border-3 bg-white fw-bolder text-primary py-0 float-end" 
                                                                disabled={parseInt(SelectedMachineBulletin.machine_ID) > 0 && parseInt(SelectedMachineBulletin.machine_ID) !== parseInt(m.machine_ID)}
                                                                onClick={() => setSelectedMachineBulletin({ ...MachineBulletins[temp_index], old_note: MachineBulletins[temp_index].note ? MachineBulletins[temp_index].note : ''})}>
                                                                <FontAwesomeIcon icon="fa-pencil" className="mt-1 me-2" />Edit
                                                            </button>
                                                        }
                                                        {/* IF NO BULLETIN, JUST SET MACHINE_ID IN SELECTEDMACHINEBULLETIN */}
                                                        {
                                                            temp_index === -1 &&
                                                            <button  
                                                                className="btn border border-primary border-3 bg-white fw-bolder text-primary py-0 float-end" 
                                                                disabled={parseInt(SelectedMachineBulletin.machine_ID) > 0 && parseInt(SelectedMachineBulletin.machine_ID) !== parseInt(m.machine_ID)}
                                                                onClick={() => setSelectedMachineBulletin({ ...DefaultMachineBulletin, machine_ID: parseInt(m.machine_ID)})}>
                                                                <FontAwesomeIcon icon="fa-pencil" className="mt-1 me-2" />Edit
                                                            </button>
                                                        }
                                                    </>
                                                }
                                                {
                                                    parseInt(SelectedMachineBulletin.machine_ID) === parseInt(m.machine_ID) && 
                                                    <button  
                                                        className="btn border border-primary border-3 bg-white fw-bolder text-primary py-0 float-end" 
                                                        onClick={() => setSelectedMachineBulletin({ ...DefaultMachineBulletin })}>
                                                        <FontAwesomeIcon icon="fa-times" className="mt-1 me-2" />{ ( SelectedMachineBulletin.saved && 'Close' ) || 'Cancel'}
                                                    </button>
                                                }

                                            </div>

                                            <div className="card-body border border-secondary border-top-0">
                                                {/* DISPLAY BULLETIN */}
                                                {
                                                    parseInt(SelectedMachineBulletin.machine_ID) !== parseInt(m.machine_ID) && 
                                                    <>
                                                        { temp_index > -1 && <div style={{whiteSpace: 'pre-wrap'}}>{MachineBulletins[temp_index].note !== '' && MachineBulletins[temp_index].note}{MachineBulletins[temp_index].note === '' && <div className="alert alert-info text-center">Bulletin Cleared</div>}</div> }
                                                        { temp_index === -1 && <div className="alert alert-warning text-center">No Bulletin Yet</div>}
                                                    </>
                                                }
                                                {/* EDITING BULLETIN */}
                                                {
                                                    parseInt(SelectedMachineBulletin.machine_ID) === parseInt(m.machine_ID) && 
                                                        <>
                                                        <div className="mb-1">
                                                            <button  
                                                                className="btn border-secondary border-3 text-secondary py-0 me-1" 
                                                                onClick={() => setSelectedMachineBulletin({ ...SelectedMachineBulletin, note: '' })}>Clear
                                                            </button>
                                                            or add to existing note
                                                        </div>
                                                        <textarea className="form-control" id="bulletin" placeholder="Enter notes here" value={SelectedMachineBulletin.note} rows="10" onChange={(event) => setSelectedMachineBulletin({...SelectedMachineBulletin, note: event.target.value, saved: false})} />
                                                        </>
                                                }
                                            </div>

                                            {/* BULLETIN CREATOR / DATE DISPLAYED */}
                                            {
                                                temp_index > -1 && parseInt(SelectedMachineBulletin.machine_ID) !== parseInt(m.machine_ID) && 
                                                <div className="card-footer text-center fw-bold border border-secondary border-top-0">
                                                    {MachineBulletins[temp_index].first_name} {MachineBulletins[temp_index].last_name} {dayjs(MachineBulletins[temp_index].date_created).format('M-D-YY h:mm a')}
                                                </div>
                                            }
                                            {/* BULLETIN EDITING - SAVE BUTTON */}
                                            {
                                                parseInt(SelectedMachineBulletin.machine_ID) === parseInt(m.machine_ID) && 
                                                <div className="card-footer text-center border border-secondary border-top-0">
                                                    <button className="btn fw-bold col-auto border-success border-3 text-success" 
                                                        disabled={SelectedMachineBulletin.note === SelectedMachineBulletin.old_note}
                                                        onClick={() => saveMachineBulletin(SelectedMachineBulletin)}>
                                                        <FontAwesomeIcon icon="fa-floppy-disk" className="mt-1 me-2" />
                                                        {SelectedMachineBulletin.saved ? 'Saved' : 'Save Bulletin'}
                                                    </button>                                    
                                                </div>
                                            }

                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }

                    {/* BULLETIN DISPLAY FOR A SINGLE MACHINE */}
                    {
                        parseInt(SelectedMachine.machine_ID) > 0 &&
                        <>
                            {/* MACHINES WITH BULLETINS - SHOW THEM NEWEST TO OLDEST */}
                            {
                                MachineBulletins.length > 0 && 
                                <div className="row row-cols-xxl-4 row-cols-xl-3 row-cols-md-2 row-cols-1">

                                    {MachineBulletins.map((m, index) => {

                                        return (
                                            <div key={parseInt(m.machine_bulletin_ID)} className="col mb-4">
                                                <div className={`card h-100 ${parseInt(SelectedMachineBulletin.machine_bulletin_ID) > 0 && parseInt(SelectedMachineBulletin.machine_bulletin_ID) !== parseInt(m.machine_bulletin_ID) && 'bg-secondary'}`}>

                                                    {/* BULLETIN CARD HEADER */}
                                                    <div className="card-header h5 text-center border border-secondary">

                                                        <div className="float-start mt-1 clearfix">
                                                            { m.date_created !== null && <div className="mt-1 float-start">{dayjs(m.date_created).format('M-D-YY h:mm a')}</div>}
                                                            { m.first_name !== null && m.last_name !== null && <div className="ms-2 float-start h6 badge bg-white text-secondary border border-3 border-secondary">{m.first_name} {m.last_name}</div>}
                                                        </div>
                                                        
                                                        {/* EDIT BUTTON */}
                                                        <div className="float-end mt-1">
                                                            {
                                                                auth() !== null && parseInt(SelectedMachineBulletin.machine_bulletin_ID) !== parseInt(m.machine_bulletin_ID)  && index === 0 &&
                                                                <button  
                                                                    className="btn border border-primary border-3 bg-white fw-bolder text-primary py-0" 
                                                                    disabled={parseInt(SelectedMachineBulletin.machine_bulletin_ID) > 0 && parseInt(SelectedMachineBulletin.machine_bulletin_ID) !== parseInt(m.machine_bulletin_ID)}
                                                                    onClick={() => setSelectedMachineBulletin({ ...m, old_note: m.note})}>
                                                                    <FontAwesomeIcon icon="fa-pencil" className="mt-1 me-2" />Edit
                                                                </button>
                                                            }
                                                            {
                                                                parseInt(SelectedMachineBulletin.machine_bulletin_ID) === parseInt(m.machine_bulletin_ID) && 
                                                                <button  
                                                                    className="btn border border-primary border-3 bg-white fw-bolder text-primary py-0" 
                                                                    onClick={() => setSelectedMachineBulletin({ ...DefaultMachineBulletin })}>
                                                                    <FontAwesomeIcon icon="fa-times" className="mt-1 me-2" />{ ( SelectedMachineBulletin.saved && 'Close' ) || 'Cancel'}
                                                                </button>
                                                            }
                                                        </div>

                                                    </div>

                                                    {/* BULLETIN CARD BODY */}
                                                    <div className="card-body border border-secondary border-top-0">

                                                        {/* DISPLAY BULLETIN */}
                                                        {
                                                            parseInt(SelectedMachineBulletin.machine_bulletin_ID) !== parseInt(m.machine_bulletin_ID) && 
                                                            <div style={{whiteSpace: 'pre-wrap'}}>{m.note !== '' && m.note}{m.note === '' && <div className="alert alert-info text-center">Bulletin Cleared</div>}</div>
                                                        }
                                                        {/* EDITING BULLETIN */}
                                                        {
                                                            parseInt(SelectedMachineBulletin.machine_bulletin_ID) === parseInt(m.machine_bulletin_ID) && 
                                                                <>
                                                                <div className="mb-1">
                                                                    <button  
                                                                        className="btn border-secondary border-3 text-secondary py-0 me-1" 
                                                                        onClick={() => setSelectedMachineBulletin({ ...SelectedMachineBulletin, note: '' })}>Clear
                                                                    </button>
                                                                    or add to existing note
                                                                </div>
                                                                <textarea className="form-control" id="bulletin" placeholder="Enter notes here" value={SelectedMachineBulletin.note} rows="10" onChange={(event) => setSelectedMachineBulletin({...SelectedMachineBulletin, note: event.target.value, saved: false})} />
                                                                </>
                                                        }
                                                    </div>

                                                    {/* BULLETIN CARD FOOTER */}

                                                        {/* BULLETIN EDITING - SAVE BUTTON */}
                                                        {
                                                            parseInt(SelectedMachineBulletin.machine_bulletin_ID) === parseInt(m.machine_bulletin_ID) && 
                                                            <div className="card-footer text-center border border-secondary border-top-0">
                                                                <button className="btn fw-bold col-auto border-success border-3 text-success" 
                                                                    disabled={SelectedMachineBulletin.note === SelectedMachineBulletin.old_note}
                                                                    onClick={() => saveMachineBulletin(SelectedMachineBulletin)}>
                                                                    <FontAwesomeIcon icon="fa-floppy-disk" className="mt-1 me-2" />
                                                                    {SelectedMachineBulletin.saved ? 'Saved' : 'Save Bulletin'}
                                                                </button>                                    
                                                            </div>
                                                        }

                                                        {/* BULLETIN COMMENT ABOUT ONLY FIRST BULLETIN IS EDITABLE */}
                                                        {
                                                            parseInt(SelectedMachineBulletin.machine_bulletin_ID) !== parseInt(m.machine_bulletin_ID) && 
                                                            <div className="card-footer text-center fw-bold border border-secondary border-top-0">
                                                                { ( index === 0 && 'Only the latest Bulletin is editable' ) || 'Not Editable' }
                                                            </div>
                                                        }

                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            }

                            {/* MACHINES WITHOUT BULLETINS - SHOW THE EMPTY CARD */}
                            {
                                MachineBulletins.length === 0 &&
                                <>
                                    {
                                        auth() !== null && 
                                        <div className="row">
                                            <div className="col-12 mb-4">
                                                <div className={`card h-100 `}>
                                                    <div className="card-header h4 text-center border border-secondary">
                                                        {/* MACHINE NAME */}
                                                        {( SelectedMachine.machine_short_name !== '' && SelectedMachine.machine_short_name !== null && SelectedMachine.machine_short_name ) || SelectedMachine.machine_name}

                                                        <button  
                                                            className="btn border border-primary border-3 bg-white fw-bolder text-primary py-0 float-end" 
                                                            onClick={() => handleChangeFilter('machine_ID', 0)}>
                                                            <FontAwesomeIcon icon="fa-times" className="mt-1 me-2" />{ ( SelectedMachineBulletin.saved && 'Close' ) || 'Cancel'}
                                                        </button>
                                                    </div>

                                                    <div className="card-body border border-secondary border-top-0">                                            
                                                        {/* EDITING BULLETIN */}
                                                        <div className="mb-1">
                                                            <button  
                                                                className="btn border-secondary border-3 text-secondary py-0 me-1" 
                                                                onClick={() => setSelectedMachineBulletin({ ...SelectedMachineBulletin, note: '' })}>Clear
                                                            </button>
                                                            or add to existing note
                                                        </div>
                                                        <textarea className="form-control" id="bulletin" placeholder="Enter notes here" value={SelectedMachineBulletin.note} rows="10" onChange={(event) => setSelectedMachineBulletin({...SelectedMachineBulletin, note: event.target.value, saved: false})} />
                                                    </div>

                                                    {/* BULLETIN EDITING - SAVE BUTTON */}
                                                    <div className="card-footer text-center border border-secondary border-top-0">
                                                        <button className="btn fw-bold col-auto border-success border-3 text-success" 
                                                            disabled={SelectedMachineBulletin.note === SelectedMachineBulletin.old_note}
                                                            onClick={() => saveMachineBulletin(SelectedMachineBulletin)}>
                                                            <FontAwesomeIcon icon="fa-floppy-disk" className="mt-1 me-2" />
                                                            {SelectedMachineBulletin.saved ? 'Saved' : 'Save Bulletin'}
                                                        </button>                                    
                                                    </div>

                                                </div>
                                            </div>
                                        </div>                              
                                    }
                                    {
                                        auth() === null &&
                                        <div className="alert alert-warning text-center">This Machine does not have a Bulletin yet. You must be logged in to edit Bulletins</div>
                                    }
                                </>
                            }
                        </>
                    }
                </>
            }
        </div>
    )
}

export const BulletinComponent = compose(
	queryWithLoading({
		gqlString: GetMachineBulletinsPage,
        name: "componentData"
	})
)(BulletinContent)

