import { gql } from '@apollo/client';

export const GetParts = gql`
    query GetParts {
        parts {
            part_ID,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            hourly_standard_rate,
            image_ID,
            image_filename,
            part_number,
            part_number_external,
            sale_price,
            scrap_percentage_cost_sheet,
            standard_tool_cost
        }
    }
`;

export const GetPartsByCustomer = gql`
    query GetPartsByCustomer($customer_ID: Int, $inspection_type_ID: Int) {
        inspection_parts_by_customer(customer_ID: $customer_ID, inspection_type_ID: $inspection_type_ID) {
            part_ID,
            part_number
        }
    }
`;

export const GetPartsComponentData = gql`
    query GetPartsComponentData($active: Int) {
        parts {
            part_ID,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            hourly_standard_rate,
            image_ID,
            image_filename,
            part_number,
            part_number_external,
            sale_price,
            scrap_percentage_cost_sheet,
            standard_tool_cost
        }
        materials {
            material_ID,
            archiver_ID,
            cost_per_piece,
            cost_per_pound,
            creator_ID,
            date_archived,
            date_created,
            description,
            material_type,
            material_type_ID,
            part_number,
            parts_per,
            supplier_ID,
            supplier_name,
            weight
        }
        material_BOMs {
            material_BOM_ID,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            description,
            material_ID,
            material_type,
            part_ID,
            part_number,
            requirement_per_piece,
            supplier_name
        }
        tooling(active: $active) {
            tooling_ID,
            active,
            archiver_ID,
            creator_ID,
            cutting_edges,
            date_archived,
            date_created,
            manufacturer_ID,
            manufacturer_name,
            manufacturer_part_number,
            notes,
            supplier_active,
            supplier_ID,
            supplier_name,
            supplier_part_number,
            tooling_cost,
            tooling_description,
            tooling_min,
            tooling_part_IDs,
            tooling_type_ID
        }
        tooling_BOMs {
            tooling_BOM_ID,
            archiver_ID,
            cost_per_part,
            creator_ID,
            cutting_edges,
            date_archived,
            date_created,
            manufacturer_name,
            manufacturer_part_number,
            part_ID,
            pieces_per_edge,
            supplier_name,
            supplier_part_number,
            tool_station,
            tooling_description,
            tooling_ID,
            tooling_type
        }
    }
`;



export const SavePart = gql`
	mutation partSave($input: PartInput) {
		partSave (input: $input) {
            UpdatedPart {
                part_ID,
                archiver_ID,
                creator_ID,
                date_archived,
                date_created,
                hourly_standard_rate,
                image_ID,
                image_filename,
                part_number,
                part_number_external,
                sale_price,
                scrap_percentage_cost_sheet,
                standard_tool_cost
            }
		}
	}
`;