import React, { useState } from 'react';
// import React, { useEffect, useState } from 'react';
// import _ from 'lodash';
import {flowRight as compose} from 'lodash';
import { queryWithLoading } from '../utilities/NSDb.js';
import { useAuthUser } from 'react-auth-kit';
import { add, divide, multiply, random, subtract } from 'mathjs';
import dayjs from 'dayjs';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
import { useMutation, useLazyQuery } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// GRAPHQL QUERY GetMachineStatuesChanges
import { GetChecksheetProcesses, SaveChecksheetRecord } from './checksheets_graphql.js';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
// import { useQuery, useMutation } from '@apollo/client';

export function ChecksheetReviewComponent(props) {
    const auth = useAuthUser();

    const { Machine } = props;
    const [save_checksheet_record] = useMutation(SaveChecksheetRecord);
    const [SelectedChecksheet, setSelectedChecksheet] = useState({ ...props.SelectedChecksheet });
    
    const [BaseState, setBaseState] = useState({
        message: '',
        message_class: ''
    });

    let used_PRs = [];

    return (
        <div className="">

            {
                SelectedChecksheet.ChecksheetProcesses.length > 0 &&
                <>
                    <table className="table table-striped">
                        <thead className="">
                            <tr className="row">
                                <th className="col-1">Op Num</th>
                                <th className="col-2">Specification</th>
                                <th className="col-2">Target</th>
                                <th className="col-2">Measurements</th>
                                <th className="col-2">Gage</th>
                                <th className="col-3">Sample</th>
                            </tr>
                        </thead>
                        <tbody>
                            {SelectedChecksheet.ChecksheetProcesses.map((c, index) => {
                                console.log({used_PRs})
                                let final_value = 0;
                                if ( c.input_type === 'input' ) {
                                
                                    // Determine what the value of the measurement should be. Get the difference between the max and min.
                                    let temp_min = c.tolerance_min === null ? 0 : c.tolerance_min;
                                    let tolerance_diff = subtract(c.tolerance_max, temp_min).toFixed(3);

                                    // Get the bottom 10th percentile value and add to that
                                    let bottom_value = add(temp_min, multiply(tolerance_diff, 0.1));
                                    let top_value = add(temp_min, multiply(tolerance_diff, 0.9));

                                    let multiplier = parseInt(SelectedChecksheet.production_record_ID) % 2 ? divide(index+1, SelectedChecksheet.ChecksheetProcesses.length).toFixed(5) : divide(subtract(SelectedChecksheet.ChecksheetProcesses.length, index), SelectedChecksheet.ChecksheetProcesses.length).toFixed(5);
                                    
                                    multiplier = multiplier < 0.1 ? multiply(multiplier, 6) : multiplier;
                                    
                                    final_value = add(temp_min, multiply(subtract(top_value, bottom_value), multiplier)).toFixed(3);

                                    // Update final value based on the production_record_ID - use the ones digit to make new multiplier
                                    let temp_pr_ID = parseInt(SelectedChecksheet.production_record_ID);
                                    console.log({temp_pr_ID})
                                    // See if this PR has been used.
                                    // if ( used_PRs.findIndex((i) => parseInt(i) === temp_pr_ID) > -1 ) {
                                    //     let match = true;
                                    //     let i;
                                    //     for(i=0; i < 100; i++) {
                                    //         if ( match === true) {
                                    //             temp_pr_ID = add(temp_pr_ID, 103);
                                    //             if ( used_PRs.findIndex((i) => parseInt(i) === temp_pr_ID) === -1 ) {
                                    //                 match = false;
                                    //             }
                                    //         }
                                    //     }
                                    // }
                                    let pr_multiplier = temp_pr_ID % 10;
                                    console.log({pr_multiplier})
                                    // Add pr_multipler percentage of difference from final_value and max to the final_value
                                    final_value = add(final_value, multiply(subtract(c.tolerance_max, final_value), divide(pr_multiplier, 10))).toFixed(3);

                                    // Correct for any negative
                                    final_value = final_value < 0 ? multiply(final_value, -1) : final_value;

                                    // Add PR to list of used ones:
                                    used_PRs.push(temp_pr_ID);

                                }
                                return (
                                    <tr key={index} className="row">
                                        <td className="col-1">{c.char_number}</td>
                                        {
                                            c.specifications !== '' && c.tolerance_target !== ''
                                        }
                                        <td className="col-2">{c.char_product}</td>
                                        <td className="col-2">{c.specifications}{c.tolerance_target}</td>
                                        <td className="col-2 text-center">
                                            {
                                                c.input_type === 'pass_fail' &&
                                                <div className="btn-group btn-group-sm" role="group">
                                                    <button className={`btn btn-success text-white`}>
                                                        Pass
                                                    </button>
                                                    <button className={`btn text-dark btn-outline-secondary`} disabled={true}>
                                                        Fail
                                                    </button>
                                                </div>                            
                                            }
                                            {
                                                c.input_type === 'input' &&
                                                <div className="input-group" >
                                                    {/* style={{height: 38+'px'}} */}
                                                    { c.tolerance_min !== '' && c.tolerance_min !== null && <span className="input-group-text px-1 border border-success bg-success" style={{fontSize: 15+'px'}}>{parseFloat(c.tolerance_min)}</span> }
                                                    <input className="form-control text-center fw-bolder border border-success bg-white" disabled={true} style={{fontSize: 18+'px'}} value={final_value} />
                                                    { c.tolerance_max !== '' && <span className="input-group-text px-1 border border-success bg-success" style={{fontSize: 15+'px'}}>{parseFloat(c.tolerance_max)}</span> }
                                                    {/* { c.tolerance_min !== '' && c.tolerance_min !== null && <span className="input-group-text px-1 border border-success bg-success" style={{fontSize: 15+'px'}}>{parseFloat(c.tolerance_min)}</span> }
                                                    <input className={`form-control text-center fw-bolder border border-success  ${final_value <= c.tolerance_max && final_value >= c.tolerance_min && 'bg-success'}`}  style={{fontSize: 18+'px'}} value={final_value} />
                                                    { c.tolerance_max !== '' && <span className="input-group-text px-1 border border-success bg-success" style={{fontSize: 15+'px'}}>{parseFloat(c.tolerance_max)}</span> } */}
                                                </div>
                                            }
                                        </td>
                                        <td className="col-2">{c.evaluation_measurement_technique}</td>
                                        <td className="col-3">{c.sample_size_frequency}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </>
            }
        </div>
    )
}

