import { gql } from '@apollo/client';

export const GetMessage = gql`
    query GetMessage($machine_ID: Int) {
        message (machine_ID: $machine_ID) {
            message_ID
            archiver_ID
            company
            company_ID
            company_short
            creator_ID
            date_archived
            date_created
            first_name
            last_name
            machine_ID
            note
            replaced_by_message_ID
        }
    }
`;

export const GetMessagesPage = gql`
    query GetMessagesPage($active: Int, $date_end: DateTime, $date_start: DateTime, $user_type_ID: Int) {
        companies (active: $active) {
            company_ID
            company
            company_short
        }
        messages (date_end: $date_end, date_start: $date_start) {
            message_ID
            archiver_ID
            company
            company_ID
            company_short
            creator_ID
            date_archived
            date_created
            date_end
            date_start
            first_name
            last_name
            message
        }
        users (active: $active, user_type_ID: $user_type_ID) {
            user_ID
            company_ID
            first_name
            last_name
        }
    }
`;

export const GetMessages = gql`
    query GetMessages($machine_ID: Int) {
        messages (machine_ID: $machine_ID) {
            message_ID
            archiver_ID
            company
            company_ID
            company_short
            creator_ID
            date_archived
            date_created
            first_name
            last_name
            machine_ID
            note
            replaced_by_message_ID
        }
    }
`;

export const SaveMessage = gql`
	mutation messageSave($input: MessageInput) {
		messageSave (input: $input) {
            Message {
                message_ID
                archiver_ID
                company
                company_ID
                company_short
                creator_ID
                date_archived
                date_created
                date_end
                date_start
                first_name
                last_name
                message
            }
		}
	}
`;

