import { gql } from '@apollo/client';

export const GetScheduling = gql`
    query GetScheduling {
        shifts_scheduling {
            shift_ID,
            active,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            break_end_1,
            break_end_2,
            break_start_1,
            break_start_2,
            lunch_end,
            lunch_start,
            lunch_paid,
            note,
            shift_end,
            shift_group,
            shift_hours_worked,
            shift_name,
            shift_start
        }
    }
`;

export const GetShifts = gql`
    query GetShifts {
        shifts {
            shift_ID,
            active,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            break_end_1,
            break_end_2,
            break_start_1,
            break_start_2,
            lunch_end,
            lunch_start,
            lunch_paid,
            note,
            shift_end,
            shift_group,
            shift_hours_worked,
            shift_name,
            shift_start
        }
    }
`;

export const SaveShift = gql`
	mutation shiftSave($input: ShiftInput) {
		shiftSave (input: $input) {
            Shifts {
                shift_ID,
                active,
                archiver_ID,
                creator_ID,
                date_archived,
                date_created,
                break_end_1,
                break_end_2,
                break_start_1,
                break_start_2,
                lunch_end,
                lunch_start,
                lunch_paid,
                note,
                shift_end,
                shift_group,
                shift_hours_worked,
                shift_name,
                shift_start
            }
		}
	}
`;