import React from 'react';
import _ from 'lodash';
import {flowRight as compose} from 'lodash';
import { queryWithLoading } from '../utilities/NSDb.js';
import { useState } from 'react';
import { useAuthUser } from 'react-auth-kit';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
import { useMutation } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// GRAPHQL QUERY
import { 
    GetPartsComponentData,
    SavePart
} from './parts_graphql.js';
import { SaveMaterialBOM } from '../materials/materials_graphql.js';
import { SaveToolingBOM } from '../tooling/tooling_graphql.js';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
// import { useQuery, useMutation } from '@apollo/client';

function PartsContent(props) {
    const auth = useAuthUser();

    // Set const values from props
    const { materials: Materials, material_BOMs: MaterialBOM, tooling: Tooling, tooling_BOMs: ToolingBOM } = props.componentData;

    // let TempParts = [...props.componentData.parts];
    let TempParts = props.componentData.parts.map((p) => {
        let TempMaterialBOM = MaterialBOM.filter((mb) => parseInt(mb.part_ID) === parseInt(p.part_ID));
        let TempToolingBOM = ToolingBOM.filter((tb) => parseInt(tb.part_ID) === parseInt(p.part_ID));
        return { ...p, MaterialBOM: TempMaterialBOM, ToolingBOM: TempToolingBOM };
    });

    const [save_part] = useMutation(SavePart);
    const [save_material_BOM] = useMutation(SaveMaterialBOM);
    const [save_tooling_BOM] = useMutation(SaveToolingBOM);

    const DefaultSelectedMaterial = {
        material_BOM_ID: 0,
        material_ID: 0,
        part_ID: 0,
        requirement_per_piece: '',
        save_button_text: 'Save Material',
        save_message: ''
    };

    const DefaultSelectedPart = {
        hourly_standard_rate: null,
        MaterialBOM: [],
        part_ID: '',
        part_number: '',
        sale_price: '',
        save_button_text: 'Save Part',
        save_message: '',
        scrap_percentage_cost_sheet: '',
        standard_tool_cost: '',
        ToolingBOM: ''
    };

    const DefaultSelectedTooling = {
        tooling_BOM_ID: 0,
        part_ID: 0,
        pieces_per_edge: '',
        tool_station: '',
        tooling_ID: 0,
        save_button_text: 'Save Tooling',
        save_message: ''
    }

    const [SelectedMaterial, setSelectedMaterial] = useState({ ...DefaultSelectedMaterial });
    const [SelectedPart, setSelectedPart] = useState({ ...DefaultSelectedPart });
    const [SelectedTooling, setSelectedTooling] = useState({ ...DefaultSelectedTooling });

    const [BaseState, setBaseState] = useState({
        action: '',
        action_material_BOM: '',
        action_tooling_BOM: '',
        filter: 'all',
        FilteredParts: TempParts,
        Materials: Materials,
        Parts: TempParts,
        Tooling: Tooling
    });

    // Alphabetically sort the parts
    // function sortPartNames (parts) {
    //     let TempParts = [...parts];
    //     if(TempParts.length > 0) {
    //         return TempParts.sort(function(a,b) {
    //             if(a.part_number.toLowerCase() < b.part_number.toLowerCase()) return -1;
    //             if(a.part_number.toLowerCase() > b.part_number.toLowerCase()) return 1;
    //             return 0;
    //         });
    //     } else {
    //         return [];
    //     }
    // }

    // Archive / Unarchive the part
    function archivePart(part_ID, archiver_ID=0) {
        let new_archiver_ID = archiver_ID > 0 ? 0 : 1;
        savePart(part_ID, new_archiver_ID, 'archive');
    }

    // Archive / Unarchive the Tooling BOM record
    function archiveToolingBOM(tooling_BOM_ID, archiver_ID=0) {
        let new_archiver_ID = archiver_ID > 0 ? 0 : 1;
        saveToolingBOM(tooling_BOM_ID, new_archiver_ID, 'archive');
    }

    // Archive / Unarchive the Material BOM record
    function archiveMaterialBOM(material_BOM_ID, archiver_ID=0) {
        let new_archiver_ID = archiver_ID > 0 ? 0 : 1;
        saveMaterialBOM(material_BOM_ID, new_archiver_ID, 'archive');
    }
    
    // Save part details
    async function savePart(part_ID, archiver_ID=0, action='') {

        let SaveData = action === 'archive' ? { part_ID, archiver_ID } : { ...SelectedPart };;

        // Parse string values that are IDs to be integers, saving stringified numbers to the database throws an error.
        // let MakeIntegers = ['part_ID'];
        // MakeIntegers.forEach((mi) => SaveData[mi] = parseInt(SaveData[mi]));

        const result = await save_part({ variables: { input: _.omit(SaveData, ['MaterialBOM', 'save_button_text', 'save_message', 'ToolingBOM']) }});

        // Updating a part - save returns a single part object
        if(result.data.partSave.UpdatedPart && result.data.partSave.UpdatedPart.part_ID > 0) {
            // Update the Parts array with the new saved information
            let TempParts = [...BaseState.Parts];
            let TempFilteredParts = [...BaseState.FilteredParts]
            let temp_action = 'edited_part';
            let temp_filter = BaseState.filter;
            let temp_save_message = '';

            // If this was an update, update the Parts object for the updated part_ID
            if(part_ID > 0) {
                TempParts = TempParts.map((p) => {
                    if(parseInt(p.part_ID) === parseInt(result.data.partSave.UpdatedPart.part_ID)) {
                        return { ...p, ...result.data.partSave.UpdatedPart };
                    } else {
                        return p
                    }
                })

                if(action === 'archive') {
                    // If archive button was clicked, close any open add/edit form by resetting SelectedPart to default.
                    setSelectedPart({
                        DefaultSelectedPart
                    });
                    temp_action = '';
                } else {
                    // Set save_message for next to Save button
                    temp_save_message = 'Parts successfully updated'

                    // Update the button and save message to show for updated success
                    setSelectedPart({
                        ...SelectedPart,
                        save_button_text: 'Saved',
                        save_message: temp_save_message
                    })
                }

                // Get the new filtered parts saved on the updated part values
                TempFilteredParts = handlePartsFilter(temp_filter, false, TempParts);

            } else {
            
                // For inserts, add the new tooling object to the Parts array, then re-sort the Parts by description string
                TempParts.unshift({ ...result.data.partSave.UpdatedPart, MaterialBOM: [], ToolingBOM: [] });

                temp_filter = 'all';
                // After creating a new part, go back to the 'all' filter view
                TempFilteredParts = handlePartsFilter(temp_filter, false, TempParts);

                // Set save_message for next to Save button
                temp_action = 'added_part';
                temp_save_message = 'Parts successfully added';

                // Update the SelectedPart with the new part_ID that was created
                setSelectedPart({
                    ...SelectedPart,
                    MaterialBOM: [],
                    part_ID: parseInt(result.data.partSave.UpdatedPart.part_ID),
                    save_button_text: 'Saved',
                    save_message: temp_save_message,
                    ToolingBOM: []
                });
            }

            // Update BaseState for both add / edit
            setBaseState({
                ...BaseState,
                action: temp_action,
                filter: temp_filter,
                FilteredParts: TempFilteredParts, 
                Parts: TempParts
            })
        } 

    }

    async function saveToolingBOM(tooling_BOM_ID, archiver_ID=0, action='') {

        let SaveData = action === 'archive' ? { tooling_BOM_ID, archiver_ID } : { ...SelectedTooling, part_ID: SelectedPart.part_ID };;

        // Parse string values that are IDs to be integers, saving stringified numbers to the database throws an error.
        if(action !== 'archive') {
            let MakeIntegers = ['part_ID', 'tooling_ID'];
            MakeIntegers.forEach((mi) => SaveData[mi] = parseInt(SaveData[mi]));
        }

        const result = await save_tooling_BOM({ variables: { input: _.omit(SaveData, ['cutting_edges', 'manufacturer_name', 'manufacturer_part_number', 'save_button_text', 'save_message', 'supplier_name', 'supplier_part_number', 'tooling_description', 'tooling_type']) }});

        // Updating a part - save returns a single part object
        if(result.data.toolingBOMSave.UpdatedToolingBOM && result.data.toolingBOMSave.UpdatedToolingBOM.tooling_BOM_ID > 0) {
            // Update the Parts array with the new saved information
            let TempParts = [...BaseState.Parts];
            let TempFilteredParts = [...BaseState.FilteredParts]
            let temp_action_tooling_BOM = 'edited_tooling_BOM';
            let temp_filter = BaseState.filter;
            let temp_save_message = '';

            // If this was an update, update the Parts object's ToolingBom array for the updated tooling_BOM_ID
            if(parseInt(tooling_BOM_ID) > 0) {
                TempParts = TempParts.map((p) => {
                    if(parseInt(p.part_ID) === parseInt(SelectedPart.part_ID)) {
                        // Update SelectedPart's ToolingBOM to saved info
                        let TempToolingBOM = p.ToolingBOM.map((tb) => {
                            if(parseInt(tb.tooling_BOM_ID) === parseInt(result.data.toolingBOMSave.UpdatedToolingBOM.tooling_BOM_ID)) {
                                return result.data.toolingBOMSave.UpdatedToolingBOM;
                            } else {
                                return tb;
                            }
                        });
                        let TempSelectedPart = { ...p, ToolingBOM: TempToolingBOM };

                        setSelectedPart({
                            ...SelectedPart,
                            ...TempSelectedPart
                        })
                        return TempSelectedPart;
                    } else {
                        return p;
                    }
                });

                if(action === 'archive') {
                    // If archive button was clicked, close any open add/edit form by resetting SelectedTooling to default.
                    setSelectedTooling({
                        DefaultSelectedTooling
                    });
                    temp_action_tooling_BOM = '';
                } else {
                    // Set save_message for next to Save button
                    temp_save_message = 'Tooling BOM successfully updated'

                    // Update the button and save message to show for updated success
                    setSelectedTooling({
                        ...SelectedTooling,
                        save_button_text: 'Saved',
                        save_message: temp_save_message
                    })
                }

                // Get the new filtered parts saved on the updated part values
                TempFilteredParts = handlePartsFilter(temp_filter, false, TempParts);

            } else {
            
                let TempToolingBOM = SelectedPart.ToolingBOM;

                // For inserts, add the new tooling object to the Part's object ToolingBom array
                TempParts = TempParts.map((p) => {
                    if(parseInt(p.part_ID) === parseInt(SelectedPart.part_ID)) {
                        p.ToolingBOM.unshift(result.data.toolingBOMSave.UpdatedToolingBOM);
                        TempToolingBOM = p.ToolingBOM;
                    }
                    return p;
                });

                // After creating a new part, go back to the 'all' filter view
                TempFilteredParts = handlePartsFilter(temp_filter, false, TempParts);

                // Set save_message for next to Save button
                temp_action_tooling_BOM = 'added_tooling_BOM';
                temp_save_message = 'Tooling BOM successfully added';

                // Update the SelectedTooling with the new tooling_BOM_ID that was created
                setSelectedTooling({
                    ...SelectedTooling,
                    tooling_BOM_ID: parseInt(result.data.toolingBOMSave.UpdatedToolingBOM.tooling_BOM_ID),
                    save_button_text: 'Saved',
                    save_message: temp_save_message
                });

                // Add the new Tooling BOM to the SelectedPart
                setSelectedPart({
                    ...SelectedPart,
                    ToolingBOM: TempToolingBOM
                })
            }

            // Update BaseState for both add / edit
            setBaseState({
                ...BaseState,
                action_tooling_BOM: temp_action_tooling_BOM,
                FilteredParts: TempFilteredParts, 
                Parts: TempParts
            })
        } 
    }

    async function saveMaterialBOM(material_BOM_ID, archiver_ID=0, action='') {
        
        let SaveData = action === 'archive' ? { material_BOM_ID, archiver_ID } : { ...SelectedMaterial, part_ID: SelectedPart.part_ID };;

        // Parse string values that are IDs to be integers, saving stringified numbers to the database throws an error.
        if(action !== 'archive') {
            let MakeIntegers = ['part_ID', 'material_ID'];
            MakeIntegers.forEach((mi) => SaveData[mi] = parseInt(SaveData[mi]));
        }

        const result = await save_material_BOM({ variables: { input: _.omit(SaveData, ['description', 'material_type', 'part_number', 'save_button_text', 'save_message', 'supplier_name']) }});
        console.log("SaveMaterial: ", result.data.materialBOMSave.UpdatedMaterialBOM)
        // Updating a part - save returns a single part object
        if(result.data.materialBOMSave.UpdatedMaterialBOM && result.data.materialBOMSave.UpdatedMaterialBOM.material_BOM_ID > 0) {
            // Update the Parts array with the new saved information
            let TempParts = [...BaseState.Parts];
            let TempFilteredParts = [...BaseState.FilteredParts]
            let temp_action_material_BOM = 'edited_tooling_BOM';
            let temp_filter = BaseState.filter;
            let temp_save_message = '';

            // If this was an update, update the Parts object's MaterialBom array for the updated material_BOM_ID
            if(parseInt(material_BOM_ID) > 0) {
                TempParts = TempParts.map((p) => {
                    if(parseInt(p.part_ID) === parseInt(SelectedPart.part_ID)) {
                        // Update SelectedPart's MaterialBOM to saved info
                        let TempMaterialBOM = p.MaterialBOM.map((tb) => {
                            if(parseInt(tb.material_BOM_ID) === parseInt(result.data.materialBOMSave.UpdatedMaterialBOM.material_BOM_ID)) {
                                return result.data.materialBOMSave.UpdatedMaterialBOM;
                            } else {
                                return tb;
                            }
                        });
                        let TempSelectedPart = { ...p, MaterialBOM: TempMaterialBOM };

                        setSelectedPart({
                            ...SelectedPart,
                            ...TempSelectedPart
                        })
                        return TempSelectedPart;
                    } else {
                        return p;
                    }
                });

                if(action === 'archive') {
                    // If archive button was clicked, close any open add/edit form by resetting SelectedMaterial to default.
                    setSelectedMaterial({
                        DefaultSelectedMaterial
                    });
                    temp_action_material_BOM = '';
                } else {
                    // Set save_message for next to Save button
                    temp_save_message = 'Material BOM successfully updated'

                    // Update the button and save message to show for updated success
                    setSelectedMaterial({
                        ...SelectedMaterial,
                        save_button_text: 'Saved',
                        save_message: temp_save_message
                    })
                }

                // Get the new filtered parts saved on the updated part values
                TempFilteredParts = handlePartsFilter(temp_filter, false, TempParts);

            } else {
            
                let TempMaterialBOM = SelectedPart.MaterialBOM;
                // For inserts, add the new tooling object to the Part's object MaterialBom array
                TempParts = TempParts.map((p) => {
                    if(parseInt(p.part_ID) === parseInt(SelectedPart.part_ID)) {
                        p.MaterialBOM.unshift(result.data.materialBOMSave.UpdatedMaterialBOM);
                        TempMaterialBOM = p.MaterialBOM;
                    }
                    return p;
                });

                // After creating a new part, go back to the 'all' filter view
                TempFilteredParts = handlePartsFilter(temp_filter, false, TempParts);

                // Set save_message for next to Save button
                temp_action_material_BOM = 'added_tooling_BOM';
                temp_save_message = 'Material BOM successfully added';

                // Update the SelectedMaterial with the new material_BOM_ID that was created
                setSelectedMaterial({
                    ...SelectedMaterial,
                    material_BOM_ID: parseInt(result.data.materialBOMSave.UpdatedMaterialBOM.material_BOM_ID),
                    save_button_text: 'Saved',
                    save_message: temp_save_message
                });

                // Add the new Material BOM to the SelectedPart
                setSelectedPart({
                    ...SelectedPart,
                    MaterialBOM: TempMaterialBOM
                })

            }

            // Update BaseState for both add / edit
            setBaseState({
                ...BaseState,
                action_material_BOM: temp_action_material_BOM,
                FilteredParts: TempFilteredParts, 
                Parts: TempParts
            })
        } 
    }

    // Filter Parts list
    function handlePartsFilter(filter, do_filtering=true, Parts=[]) {
        console.log({filter})
        console.log({do_filtering})
        console.log({Parts})
        let TempFilteredParts = Parts.length > 0 ? Parts : BaseState.Parts;
        if(filter === 'active') {
            TempFilteredParts = TempFilteredParts.filter((p) => !parseInt(p.archiver_ID) > 0);
            console.log("FILTER ACTIVE: ", TempFilteredParts)
        } else if(filter === 'archived') {
            TempFilteredParts = TempFilteredParts.filter((p) => parseInt(p.archiver_ID) > 0);
            console.log("FILTER ARCHIVED: ", TempFilteredParts)
        }

        if(do_filtering) {
            console.log("DO FILTER")
            setBaseState({
                ...BaseState,
                filter: filter,
                FilteredParts: TempFilteredParts
            })
        } else {
            return TempFilteredParts;
        }

    }

    // Update SelectedPart state values
    function changeInputValue(name, value) {
        // For inputs that must be integers, do not allow non numeric characters, and parseInt on the value
        if ( name === 'hourly_standard_rate' ) {
            if(value !== '') {
                value = parseInt(value)
            } else {
                // This is to avoid attempting to save "" as an INT
                value = null;
            }
        } else if ( name === 'sale_price' || name === 'scrap_percentage_cost_sheet' || name === 'standard_tool_cost' ) {
            if(value !== '') {
                // Strips non-numeric characters, leaves decimal
                value = value.replace(/[^\d.-]/g, '');
                // Handle decimal length restrictions
                if (value.includes('.')) {
                    let decimal_length = value.split('.')[1].length;
                    if( (name === 'sale_price' || name === 'standard_tool_cost' ) && decimal_length > 4 ) {
                        value = parseFloat(value).toFixed(4)
                    } else if ( name === 'scrap_percentage_cost_sheet' && decimal_length > 2 ) {
                        value = parseFloat(value).toFixed(2)
                    }
                };
            }
        }

        setSelectedPart({
            ...SelectedPart,
            [name]: value,
            save_button_text: 'Save Part',
            save_message: ''
        });
    }

    // Update SelectedTooling state values
    function changeSelectedToolingInputValue(name, value) {
    
        let temp_tooling_values = { save_button_text: 'Save Tooling', save_message: ''};

        if ( name === 'pieces_per_edge' ) {
            if(value !== '') {
                // Strips non-numeric characters, leaves decimal
                value = value.replace(/[^\d.-]/g, '');
                // Handle decimal length restrictions
                if (value.includes('.')) {
                    let decimal_length = value.split('.')[1].length;
                    if( (name === 'pieces_per_edge' ) && decimal_length > 2 ) {
                        value = parseFloat(value).toFixed(2)
                    }
                };
            }
        } else if ( name === 'tooling_ID' ) {
            // Get the cutting_edges from the Tooling array
            if(parseInt(value) > 0) {
                let temp_cutting_edges = Tooling.filter((t) => parseInt(t.tooling_ID) === parseInt(value)).cutting_edges;
                temp_tooling_values = { ...temp_tooling_values, cutting_edges: temp_cutting_edges };
            }
        }
        setSelectedTooling({
            ...SelectedTooling,
            [name]: value, 
            ...temp_tooling_values
        })

    }

    // Update SelectedMaterial state values
    function changeSelectedMaterialInputValue(name, value) {
    
        let temp_material_values = { save_button_text: 'Save Material', save_message: ''};

        if ( name === 'pieces_per_edge' ) {
            if(value !== '') {
                // Strips non-numeric characters, leaves decimal
                value = value.replace(/[^\d.-]/g, '');
                // Handle decimal length restrictions
                if (value.includes('.')) {
                    let decimal_length = value.split('.')[1].length;
                    if( (name === 'pieces_per_edge' ) && decimal_length > 2 ) {
                        value = parseFloat(value).toFixed(2)
                    }
                };
            }
        } 
        // else if ( name === 'tooling_ID' ) {
        //     // Get the cutting_edges from the Material array
        //     if(parseInt(value) > 0) {
        //         let temp_cutting_edges = Material.filter((t) => parseInt(t.tooling_ID) === parseInt(value)).cutting_edges;
        //         temp_material_values = { ...temp_material_values, cutting_edges: temp_cutting_edges };
        //     }
        // }
        setSelectedMaterial({
            ...SelectedMaterial,
            [name]: value, 
            ...temp_material_values
        })

    }

    // Handler function for all non-save buttons - Selected will always be the SelectedPart object of whatever is being clicked. 'Add' is the defaultSelectedPart object.
    function handleButtonClick(action, Selected) {
        console.log("handleButtonClick, action: ", action)
        let TempSelected = { ...Selected };

        // Parts buttons
        if(action === 'add_part' || action === 'edit_part') {
            TempSelected = { ...DefaultSelectedPart, ...Selected, save_message: ''};
        } 
        if(action === 'add_part' || action === 'edit_part' || action === 'close_part') {
            let temp_action = action === 'close_part' ? '' : action;
            setSelectedPart({ ...TempSelected });
            setSelectedMaterial({ ...DefaultSelectedMaterial });
            setSelectedTooling({ ...DefaultSelectedTooling });
            setBaseState({
                ...BaseState,
                action: temp_action,
                action_material_BOM: '',
                action_tooling_BOM: ''
            })
        } 

        // Material BOM buttons
        if(action === 'add_material_BOM' || action === 'edit_material_BOM') {
            TempSelected = { ...DefaultSelectedMaterial, ...Selected, save_message: ''};
        } 
        if(action === 'add_material_BOM' || action === 'edit_material_BOM' || action === 'close_material_BOM') {
            let temp_action = action === 'close_material_BOM' ? '' : action;
            setSelectedMaterial({ ...TempSelected });
            setBaseState({
                ...BaseState,
                action_material_BOM: temp_action
            })
        } 

        // Tooling BOM buttons
        if(action === 'add_tooling_BOM' || action === 'edit_tooling_BOM') {
            TempSelected = { ...DefaultSelectedTooling, ...Selected, save_message: ''};
        } 
        if(action === 'add_tooling_BOM' || action === 'edit_tooling_BOM' || action === 'close_tooling_BOM') {
            let temp_action = action === 'close_tooling_BOM' ? '' : action;
            setSelectedTooling({ ...TempSelected });
            setBaseState({
                ...BaseState,
                action_tooling_BOM: temp_action
            })
        } 




    }

    console.log("BaseState: ", BaseState);
    console.log({SelectedPart})
    console.log({SelectedMaterial})
    console.log({SelectedTooling})
    console.log(auth())

    // For tooling bom, need to add tooling station, pieces per edge, and tool selection 
    // material bom - requirement per piece input, raw material selection.
    return (
        <React.Fragment>
            <div>
                <div className="row clearfix mb-3">
                    <div className="col">
                        <div className="btn-group btn-group-sm" role="group">
                            <button className={`btn btn-outline-secondary ${(BaseState.filter === 'all' && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handlePartsFilter('all')}>
                                All Parts
                            </button>
                            <button className={`btn btn-outline-secondary ${(BaseState.filter === 'active' && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handlePartsFilter('active')}>
                                Only Active
                            </button>
                            <button className={`btn btn-outline-secondary ${(BaseState.filter === 'archived' && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handlePartsFilter('archived')}>
                                Only Archived
                            </button>
                        </div>                            
                    </div>
                    <div className="col-auto">
                        <div className="float-end">
                        {
                            SelectedPart.part_ID !== '' && SelectedPart.part_ID === 0 && BaseState.action !== 'added_part' &&
                            <div className="">
                                <span className="col-auto float-end ms-2">
                                    <button 
                                        className="col-auto btn border-primary border-3 text-primary fw-bold disabled" 
                                        disabled={true}
                                    >
                                    Enter New Part Info Below
                                    </button>
                                </span>
                            </div>
                        }
                        {
                            (SelectedPart.part_ID !== 0 || BaseState.action === 'added_part') &&
                            <div className="">
                                <button 
                                    className="btn fw-bold col-auto border-primary border-3 float-end text-primary" 
                                    onClick={() => handleButtonClick('add_part', {...DefaultSelectedPart, part_ID: 0 })}
                                >
                                    <FontAwesomeIcon icon="fa-plus" className="me-2" />Add Part
                                </button>
                                {
                                    BaseState.action === 'added_part' &&
                                    <span className="col-auto float-end me-2">
                                        <div className="alert alert-success p-2">Part added successfully</div>
                                    </span>
                                }
                            </div>
                        }
                        </div>
                    </div>
                </div>
                {
                    <table className="table">
                        <thead className="row h6 border border-2 border-top-0 border-start-0 border-end-0 border-dark">
                            <tr className="col-1">Archive / Edit</tr>
                            <tr className="col-7">Part Number</tr>
                            <tr className="col-1">Hourly Std Rate</tr>
                            <tr className="col-1">Sale Price</tr>
                            <tr className="col-1">Std Tool Cost</tr>
                            <tr className="col-1">Scrap %</tr>
                        </thead>
                        {
                            (BaseState.FilteredParts.length > 0 || BaseState.action === 'add_part') &&
                            <tbody className="">
                                {BaseState.action === 'add_part' &&
                                    <tr className="row" key="0">
                                        <td className="border-0 col-1" />
                                        <td className="border-0 col-7">
                                            <input className="form-control float-end" value={SelectedPart.part_number || ""} onChange={(event) => changeInputValue('part_number', event.target.value)} />
                                        </td>
                                        <td className="border-0 col-1">
                                            <div className="input-group" style={{height: 38+'px'}}>
                                                <input className="form-control float-end text-end px-1" style={{fontSize: 13+'px'}} value={SelectedPart.hourly_standard_rate || ""} onChange={(event) => changeInputValue('hourly_standard_rate', event.target.value)} />
                                                <span className="input-group-text px-1" style={{fontSize: 13+'px'}}>/ hr</span>
                                            </div>
                                        </td>
                                        <td className="border-0 col-1">
                                            <div className="input-group" style={{height: 38+'px'}}>
                                                <span className="input-group-text px-1" style={{fontSize: 13+'px'}}>$</span>
                                                <input className="form-control float-end px-1" style={{fontSize: 13+'px'}} value={SelectedPart.sale_price || ""} onChange={(event) => changeInputValue('sale_price', event.target.value)} />
                                            </div>
                                        </td>
                                        <td className="border-0 col-1">
                                            <div className="input-group" style={{height: 38+'px'}}>
                                                <span className="input-group-text px-1" style={{fontSize: 13+'px'}}>$</span>
                                                <input className="form-control float-end px-1" style={{fontSize: 13+'px'}} value={SelectedPart.standard_tool_cost || ""} onChange={(event) => changeInputValue('standard_tool_cost', event.target.value)} />
                                            </div>
                                        </td>
                                        <td className="border-0 col-1">
                                            <div className="input-group" style={{height: 38+'px'}}>
                                                <input className="form-control float-end text-end px-1" style={{fontSize: 13+'px'}} value={SelectedPart.scrap_percentage_cost_sheet || ""} onChange={(event) => changeInputValue('scrap_percentage_cost_sheet', event.target.value)} />
                                                <span className="input-group-text px-1" style={{fontSize: 13+'px'}}>%</span>
                                            </div>
                                        </td>
                                        <td className="border-0 col-1" />
                                        <td className="border-0 col-11">
                                            <button className="btn fw-bold col-auto border-success border-3 text-success float-start" disabled={SelectedPart.part_number === ''} onClick={() => savePart(0)}>{SelectedPart.save_button_text} {SelectedPart.save_button_text === 'Saved' && <FontAwesomeIcon icon="fa-check" className="ms-1" />}</button>
                                            <button className="btn fw-bold col-auto border-secondary border-3 text-secondary float-end" onClick={() => handleButtonClick('close_part', DefaultSelectedPart)}>Cancel</button>
                                        </td>
                                        <td className="border-t-0 pb-0 col-1" />
                                        <td className="border-t-0 pb-0 col-11">
                                            <div className="alert alert-info">Save the new Part in order to add Material and Tooling BOMs</div>
                                        </td>

                                    </tr>
                                }
                                {BaseState.FilteredParts.map((p) => {
                                    // let archive_button_class = p.archiver_ID > 0 ? 'btn-success' : 'btn-danger';
                                    // let archive_button_text = p.archiver_ID > 0 ? 'Unarchive' : 'Archive';
                                    if(parseInt(p.part_ID) === parseInt(SelectedPart.part_ID)) {
                                        // Disable the save button until an input has changed.
                                        let {part_number, hourly_standard_rate, sale_price, standard_tool_cost, scrap_percentage_cost_sheet} = SelectedPart;
                                        let inputs_not_changed = part_number === p.part_number && hourly_standard_rate === p.hourly_standard_rate && sale_price === p.sale_price && standard_tool_cost === p.standard_tool_cost && scrap_percentage_cost_sheet === p.scrap_percentage_cost_sheet;
                                        let disable_save_button = inputs_not_changed || SelectedPart.save_button_text === 'Saving' || SelectedPart.save_button_text === 'Saved';
                                        let save_button_tip = disable_save_button ? "Nothing has not been changed" : '';
                                        // If adding a new part and the part_number is blank, do not allow saving
                                        if(save_button_tip === '' && SelectedPart.part_number === '') {
                                            disable_save_button = true;
                                            save_button_tip = 'You must enter a part number'
                                        }
                                        return (
                                            <React.Fragment key={p.part_ID}>
                                                <tr className="row border border-secondary rounded bg-light my-1">
                                                    {/* Part details input */}
                                                    <td className="border-0 col-1" />
                                                    <td className="border-0 col-7">
                                                        <input className="form-control float-end" value={SelectedPart.part_number || ""} onChange={(event) => changeInputValue('part_number', event.target.value)} />
                                                    </td>
                                                    <td className="border-0 col-1">
                                                        <div className="input-group" style={{height: 38+'px'}}>
                                                            <input className="form-control float-end text-end px-1" style={{fontSize: 13+'px'}} value={SelectedPart.hourly_standard_rate || ""} onChange={(event) => changeInputValue('hourly_standard_rate', event.target.value)} />
                                                            <span className="input-group-text px-1" style={{fontSize: 13+'px'}}>/ hr</span>
                                                        </div>
                                                    </td>
                                                    <td className="border-0 col-1">
                                                        <div className="input-group" style={{height: 38+'px'}}>
                                                            <span className="input-group-text px-1" style={{fontSize: 13+'px'}}>$</span>
                                                            <input className="form-control float-end px-1" style={{fontSize: 13+'px'}} value={SelectedPart.sale_price || ""} onChange={(event) => changeInputValue('sale_price', event.target.value)} />
                                                        </div>
                                                    </td>
                                                    <td className="border-0 col-1">
                                                        <div className="input-group" style={{height: 38+'px'}}>
                                                            <span className="input-group-text px-1" style={{fontSize: 13+'px'}}>$</span>
                                                            <input className="form-control float-end px-1" style={{fontSize: 13+'px'}} value={SelectedPart.standard_tool_cost || ""} onChange={(event) => changeInputValue('standard_tool_cost', event.target.value)} />
                                                        </div>
                                                    </td>
                                                    <td className="border-0 col-1">
                                                        <div className="input-group" style={{height: 38+'px'}}>
                                                            <input className="form-control float-end text-end px-1" style={{fontSize: 13+'px'}} value={SelectedPart.scrap_percentage_cost_sheet || ""} onChange={(event) => changeInputValue('scrap_percentage_cost_sheet', event.target.value)} />
                                                            <span className="input-group-text px-1" style={{fontSize: 13+'px'}}>%</span>
                                                        </div>
                                                    </td>
                                                    {/* Save / Close buttons for Part Details */}
                                                    <td className="border-0 col-1" />
                                                    <td className="border-0 col-11" data-bs-toggle="tooltip" data-bs-placement="top" title={save_button_tip} >
                                                        <button className="btn border-secondary border-3 fw-bold text-secondary float-end" onClick={() => handleButtonClick('close_part', DefaultSelectedPart)}>Close</button>
                                                        <button className="btn border-success border-3 fw-bold text-success" disabled={disable_save_button} onClick={() => savePart(p.part_ID)}>{SelectedPart.save_button_text} {SelectedPart.save_button_text === 'Saved' && <FontAwesomeIcon icon="fa-check" className="ms-1" />}</button>
                                                    </td>

                                                    {/* TOOLING BOM RECORD CONTENT */}
                                                    <td className="border-0 col-1" />
                                                    <td className="border-0 col-11" data-bs-toggle="tooltip" data-bs-placement="top" title={save_button_tip} >
                                                        <span className="h5 text-secondary">
                                                            Tooling BOM Details
                                                            <span 
                                                                className={`col-auto border border-primary btn btn-sm text-primary ms-2 mb-1 ${BaseState.action_tooling_BOM === 'add_tooling_BOM' && 'disabled'}`}
                                                                onClick={() => handleButtonClick('add_tooling_BOM', DefaultSelectedTooling)}
                                                            >
                                                                <FontAwesomeIcon icon="fa-plus" size="lg" className="" />
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td className="border-0 col-1 mb-0 pt-0" />
                                                    <td className="border-0 col-11 mb-0 pt-0" data-bs-toggle="tooltip" data-bs-placement="top" title={save_button_tip} >
                                                        {/* Tooling BOM list for SelectedPart */}
                                                        {
                                                            (SelectedPart.ToolingBOM.length > 0 || BaseState.action_tooling_BOM === 'add_tooling_BOM') &&
                                                                <table className="table">
                                                                    <thead className="row me-1 border border-2 border-top-0 border-start-0 border-end-0 border-dark mb-0">
                                                                        <th className="col-1">Archive / Edit</th>
                                                                        <th className="col-2">Tool Station</th>
                                                                        <th className="col-6">Tooling</th>
                                                                        <th className="col-2">Pieces Per Edge</th>
                                                                        <th className="col-1">Cutting Edges</th>
                                                                    </thead>
                                                                    <tbody>
                                                                        {BaseState.action_tooling_BOM === 'add_tooling_BOM' &&
                                                                            <tr key={0} className="row me-1">
                                                                                {/* Tooling Bom inputs*/}
                                                                                <td className="border-0 col-1" />
                                                                                {/* Tool Station input */}
                                                                                <td className="border-0 col-2">
                                                                                    <div className="form-floating">
                                                                                        <input className="form-control" id="tool_station" placeholder="Tooling Station" value={SelectedTooling.tool_station} onChange={(event) => changeSelectedToolingInputValue('tool_station', event.target.value)} />
                                                                                        <label className="text-muted" htmlFor="tool_station">Tool Station</label>
                                                                                    </div>
                                                                                </td>
                                                                                {/* Tooling Selection input */}
                                                                                <td className="border-0 col-6">
                                                                                    <div className="form-floating">
                                                                                        <select 
                                                                                            className="form-select mb-3" 
                                                                                            disabled={false}
                                                                                            id="tooling"
                                                                                            onChange={(event) => changeSelectedToolingInputValue('tooling_ID', event.target.value)}
                                                                                            placeholder="Tooling"
                                                                                            value={SelectedTooling.tooling_ID}
                                                                                        >
                                                                                            <option key={0} value={0}>Select Tooling</option>
                                                                                            {Tooling.map((t) => {
                                                                                                return (
                                                                                                    <option key={t.tooling_ID} value={t.tooling_ID}>{t.tooling_description} - {t.manufacturer_name} {t.manufacturer_part_number}</option>
                                                                                                )
                                                                                            })}
                                                                                        </select>
                                                                                        <label className="text-muted" htmlFor="tooling">Tooling Used</label>
                                                                                    </div>
                                                                                </td>
                                                                                {/* Pieces Per Edge input */}
                                                                                <td className="border-0 col-2">
                                                                                    <div className="form-floating">
                                                                                        <input className="form-control" id="pieces_per_edge" placeholder="Pieces Per Edge" value={SelectedTooling.pieces_per_edge} onChange={(event) => changeSelectedToolingInputValue('pieces_per_edge', event.target.value)} />
                                                                                        <label className="text-muted" htmlFor="pieces_per_edge">Pieces Per Edge</label>
                                                                                    </div>
                                                                                </td>
                                                                                {/* Cutting Edges output */}
                                                                                <td className="border-0 col-1">
                                                                                    <div className="form-floating">
                                                                                        {SelectedTooling.cutting_edges}
                                                                                    </div>
                                                                                </td>
                                                                                {/* Save / Close buttons for Tooling BOM Details */}
                                                                                <td className="border-0 col-1 pt-0" />
                                                                                <td className="border-0 col-11 pt-0">
                                                                                    <button className="btn border-secondary border-3 fw-bold text-secondary float-end" onClick={() => handleButtonClick('close_tooling_BOM', DefaultSelectedTooling)}>Close Tooling</button>
                                                                                    <button className="btn border-success border-3 fw-bold text-success" onClick={() => saveToolingBOM(0)}>{SelectedTooling.save_button_text} {SelectedTooling.save_button_text === 'Saved' && <FontAwesomeIcon icon="fa-check" className="ms-1" />}</button>
                                                                                </td>
                                                                            </tr>
                                                                        }
                                                                        {SelectedPart.ToolingBOM.length > 0 && 
                                                                            SelectedPart.ToolingBOM.map((tb, index) => {
                                                                                if(parseInt(tb.tooling_BOM_ID) === parseInt(SelectedTooling.tooling_BOM_ID)) {
                                                                                    return (
                                                                                        <tr key={index} className="row me-1">
                                                                                            {/* Tooling Bom inputs*/}
                                                                                            <td className="border-0 col-1" />
                                                                                            {/* Tool Station input */}
                                                                                            <td className="border-0 col-2">
                                                                                                <div className="form-floating">
                                                                                                    <input className="form-control" id="tool_station" placeholder="Tooling Station" value={SelectedTooling.tool_station} onChange={(event) => changeSelectedToolingInputValue('tool_station', event.target.value)} />
                                                                                                    <label className="text-muted" htmlFor="tool_station">Tool Station</label>
                                                                                                </div>
                                                                                            </td>
                                                                                            {/* Tooling Selection input */}
                                                                                            <td className="border-0 col-6">
                                                                                                <div className="form-floating">
                                                                                                    <select 
                                                                                                        className="form-select mb-3" 
                                                                                                        disabled={false}
                                                                                                        id="tooling"
                                                                                                        onChange={(event) => changeSelectedToolingInputValue('tooling_ID', event.target.value)}
                                                                                                        placeholder="Tooling"
                                                                                                        value={SelectedTooling.tooling_ID}
                                                                                                    >
                                                                                                        <option key={0} value={0}>Select Tooling</option>
                                                                                                        {Tooling.map((t) => {
                                                                                                            return (
                                                                                                                <option key={t.tooling_ID} value={t.tooling_ID}>{t.tooling_description} - {t.manufacturer_name} {t.manufacturer_part_number}</option>
                                                                                                            )
                                                                                                        })}
                                                                                                    </select>
                                                                                                    <label className="text-muted" htmlFor="tooling">Tooling Used</label>
                                                                                                </div>
                                                                                            </td>
                                                                                            {/* Pieces Per Edge input */}
                                                                                            <td className="border-0 col-2">
                                                                                                <div className="form-floating">
                                                                                                    <input className="form-control" id="pieces_per_edge" placeholder="Pieces Per Edge" value={SelectedTooling.pieces_per_edge} onChange={(event) => changeSelectedToolingInputValue('pieces_per_edge', event.target.value)} />
                                                                                                    <label className="text-muted" htmlFor="pieces_per_edge">Pieces Per Edge</label>
                                                                                                </div>
                                                                                            </td>
                                                                                            {/* Cutting Edges output */}
                                                                                            <td className="border-0 col-1">
                                                                                                <div className="form-floating">
                                                                                                    {SelectedTooling.cutting_edges}
                                                                                                </div>
                                                                                            </td>
                                                                                            {/* Save / Close buttons for Tooling BOM Details */}
                                                                                            <td className="border-0 col-1" />
                                                                                            <td className="border-0 col-11">
                                                                                                <button className="btn border-secondary border-3 fw-bold text-secondary float-end" onClick={() => handleButtonClick('close_tooling_BOM', DefaultSelectedTooling)}>Close Tooling</button>
                                                                                                <button className="btn border-success border-3 fw-bold text-success" onClick={() => saveToolingBOM(SelectedTooling.tooling_BOM_ID)}>{SelectedTooling.save_button_text} {SelectedTooling.save_button_text === 'Saved' && <FontAwesomeIcon icon="fa-check" className="ms-1" />}</button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                } else {
                                                                                    let tool_station_text = tb.archiver_ID > 0 ? `(${tb.tool_station})` : tb.tool_station;
                                                                                    let archive_button_class = parseInt(tb.archiver_ID) > 0 ? 'border-success text-success' : 'border-danger text-danger';
                                                                                    let archive_button_icon = parseInt(tb.archiver_ID) > 0 ? 'fa-rotate-left' : 'fa-trash-can';
                                                                                    return (
                                                                                        <tr key={index} className={`row me-1 ${tb.archiver_ID > 0 && 'text-muted'}`}>
                                                                                            <td className="col-1">
                                                                                                <button className="btn btn-sm text-primary border-primary float-end" onClick={() => handleButtonClick('edit_tooling_BOM', tb)}><FontAwesomeIcon icon="fa-pencil" className="" /></button>
                                                                                                <button className={`btn btn-sm float-start ${archive_button_class}`} onClick={() => archiveToolingBOM(tb.tooling_BOM_ID, tb.archiver_ID)}><FontAwesomeIcon icon={archive_button_icon} className="" /></button>
                                                                                            </td>
                                                                                            <td className="col-2"><span className="ps-1">{tool_station_text}</span></td>
                                                                                            <td className="col-6">
                                                                                                <span className="ps-1">{tb.tooling_description}</span><span className="text-muted" style={{fontSize: 13+'px'}}> ({tb.tooling_type})</span>
                                                                                                <span style={{fontSize: 13+'px'}}>
                                                                                                    <div>{ tb.manufacturer_name !== '' && <><span className="ps-1 text-muted">Manufacturer:</span> {tb.manufacturer_name}</>}{tb.manufacturer_name !== '' && tb.manufacturer_part_number !== '' && <span> - {tb.manufacturer_part_number}</span>}</div>
                                                                                                    <div>{ tb.supplier_name !== '' && <><span className="ps-1 text-muted">Supplier:</span> {tb.supplier_name}</>}{tb.supplier_name !== '' && tb.supplier_part_number !== '' && <span> - {tb.supplier_part_number}</span>}</div>
                                                                                                </span>
                                                                                            </td>
                                                                                            <td className="col-2"><span className="ps-1">{parseFloat(tb.pieces_per_edge)}</span></td>
                                                                                            <td className="col-1"><span className="ps-1">{tb.cutting_edges}</span></td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                            })}
                                                                    </tbody>
                                                                </table>
                                                        }
                                                    </td>

                                                    {/* MATERIAL BOM RECORD CONTENT */}
                                                    <td className="border-0 col-1" />
                                                    <td className="border-0 col-11">
                                                        <span className="h5 text-secondary">
                                                            Material BOM Details
                                                            <span 
                                                                className={`col-auto border border-primary btn btn-sm text-primary ms-2 mb-1 ${BaseState.action_material_BOM === 'add_material_BOM' && 'disabled'}`}
                                                                onClick={() => handleButtonClick('add_material_BOM', DefaultSelectedMaterial)}
                                                            >
                                                                <FontAwesomeIcon icon="fa-plus" size="lg" className="" />
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td className="border-0 col-1 mb-0 pt-0" />
                                                    <td className="border-0 col-11 mb-0 pt-0">
                                                        {/* Material BOM list for SelectedPart */}
                                                        {
                                                            (SelectedPart.MaterialBOM.length > 0 || BaseState.action_material_BOM === 'add_material_BOM') &&
                                                                <table className="table">
                                                                    <thead className="row me-1 border border-2 border-top-0 border-start-0 border-end-0 border-dark mb-0">
                                                                        <th className="col-1">Archive / Edit</th>
                                                                        <th className="col-2">Part Number</th>
                                                                        <th className="col-4">Description</th>
                                                                        <th className="col-1">Type</th>
                                                                        <th className="col-1">Supplier</th>
                                                                        <th className="col-2">Requirement Per Piece</th>
                                                                        <th className="col-1">On Hand</th>
                                                                    </thead>
                                                                    <tbody>
                                                                        {BaseState.action_material_BOM === 'add_material_BOM' &&
                                                                            <tr key={0} className="row me-1">
                                                                                {/* Material Bom inputs*/}
                                                                                <td className="border-0 col-1" />
                                                                                {/* Material Selection input */}
                                                                                <td className="border-0 col-8">
                                                                                    <div className="form-floating">
                                                                                        <select 
                                                                                            className="form-select mb-3" 
                                                                                            disabled={false}
                                                                                            id="material"
                                                                                            onChange={(event) => changeSelectedMaterialInputValue('material_ID', event.target.value)}
                                                                                            placeholder="Material"
                                                                                            value={SelectedMaterial.material_ID}
                                                                                        >
                                                                                            <option key={0} value={0}>Select Material</option>
                                                                                            {Materials.map((m) => {
                                                                                                return (
                                                                                                    <option key={m.material_ID} value={m.material_ID}>{m.part_number} - {m.description} - {m.material_type} - {m.supplier_name}</option>
                                                                                                )
                                                                                            })}
                                                                                        </select>
                                                                                        <label className="text-muted" htmlFor="material">Material</label>
                                                                                    </div>
                                                                                </td>
                                                                                {/* Requirement Per Piece input */}
                                                                                <td className="border-0 col-2">
                                                                                    <div className="form-floating">
                                                                                        <input className="form-control" id="requirement_per_piece" placeholder="Requirement Per Piece" value={SelectedMaterial.requirement_per_piece} onChange={(event) => changeSelectedMaterialInputValue('requirement_per_piece', event.target.value)} />
                                                                                        <label className="text-muted" htmlFor="requirement_per_piece">Requirement Per Piece</label>
                                                                                    </div>
                                                                                </td>
                                                                                {/* On Hand output */}
                                                                                <td className="border-0 col-1" />
                                                                                {/* Save / Close buttons for Material BOM Details */}
                                                                                <td className="border-0 col-1 pt-0" />
                                                                                <td className="border-0 col-11 pt-0">
                                                                                    <button className="btn border-secondary border-3 fw-bold text-secondary float-end" onClick={() => handleButtonClick('close_material_BOM', DefaultSelectedMaterial)}>Close Material</button>
                                                                                    <button className="btn border-success border-3 fw-bold text-success" onClick={() => saveMaterialBOM(0)}>{SelectedMaterial.save_button_text} {SelectedMaterial.save_button_text === 'Saved' && <FontAwesomeIcon icon="fa-check" className="ms-1" />}</button>
                                                                                </td>
                                                                            </tr>
                                                                        }
                                                                        {SelectedPart.MaterialBOM.length > 0 && 
                                                                            SelectedPart.MaterialBOM.map((mb, index) => {
                                                                                if(parseInt(mb.material_BOM_ID) === parseInt(SelectedMaterial.material_BOM_ID)) {
                                                                                    return (
                                                                                        <tr key={index} className="row me-1">
                                                                                            {/* Material Bom inputs*/}
                                                                                            <td className="border-0 col-1" />
                                                                                            {/* Material Selection input */}
                                                                                            <td className="border-0 col-8">
                                                                                                <div className="form-floating">
                                                                                                    <select 
                                                                                                        className="form-select mb-3" 
                                                                                                        disabled={false}
                                                                                                        id="material"
                                                                                                        onChange={(event) => changeSelectedMaterialInputValue('material_ID', event.target.value)}
                                                                                                        placeholder="Material"
                                                                                                        value={SelectedMaterial.material_ID}
                                                                                                    >
                                                                                                        <option key={0} value={0}>Select Material</option>
                                                                                                        {Materials.map((m) => {
                                                                                                            return (
                                                                                                                <option key={m.material_ID} value={m.material_ID}>{m.part_number} - {m.description} - {m.material_type} - {m.supplier_name}</option>
                                                                                                            )
                                                                                                        })}
                                                                                                    </select>
                                                                                                    <label className="text-muted" htmlFor="material">Material</label>
                                                                                                </div>
                                                                                            </td>
                                                                                            {/* Requirement Per Piece input */}
                                                                                            <td className="border-0 col-2">
                                                                                                <div className="form-floating">
                                                                                                    <input className="form-control" id="requirement_per_piece" placeholder="Requirement Per Piece" value={SelectedMaterial.requirement_per_piece} onChange={(event) => changeSelectedMaterialInputValue('requirement_per_piece', event.target.value)} />
                                                                                                    <label className="text-muted" htmlFor="requirement_per_piece">Requirement Per Piece</label>
                                                                                                </div>
                                                                                            </td>
                                                                                            {/* On Hand output */}
                                                                                            <td className="border-0 col-1" />
                                                                                            {/* Save / Close buttons for Material BOM Details */}
                                                                                            <td className="border-0 col-1 pt-0" />
                                                                                            <td className="border-0 col-11 pt-0">
                                                                                                <button className="btn border-secondary border-3 fw-bold text-secondary float-end" onClick={() => handleButtonClick('close_material_BOM', DefaultSelectedMaterial)}>Close Material</button>
                                                                                                <button className="btn border-success border-3 fw-bold text-success" onClick={() => saveMaterialBOM(SelectedMaterial.material_BOM_ID)}>{SelectedMaterial.save_button_text} {SelectedMaterial.save_button_text === 'Saved' && <FontAwesomeIcon icon="fa-check" className="ms-1" />}</button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                } else {
                                                                                    let material_part_number_text = mb.archiver_ID > 0 ? `(${mb.part_number})` : mb.part_number;
                                                                                    let archive_button_class = parseInt(mb.archiver_ID) > 0 ? 'border-success text-success' : 'border-danger text-danger';
                                                                                    let archive_button_icon = parseInt(mb.archiver_ID) > 0 ? 'fa-rotate-left' : 'fa-trash-can';
                                                                                    return (
                                                                                        <tr key={index} className={`row me-1 ${mb.archiver_ID > 0 && 'text-muted'}`}>
                                                                                            <td className="col-1">
                                                                                                <button className="btn btn-sm text-primary border-primary float-end" onClick={() => handleButtonClick('edit_material_BOM', mb)}><FontAwesomeIcon icon="fa-pencil" className="" /></button>
                                                                                                <button className={`btn btn-sm float-start ${archive_button_class}`} onClick={() => archiveMaterialBOM(mb.material_BOM_ID, mb.archiver_ID)}><FontAwesomeIcon icon={archive_button_icon} className="" /></button>
                                                                                            </td>
                                                                                            <td className="col-2"><span className="ps-1">{material_part_number_text}</span></td>
                                                                                            <td className="col-4">
                                                                                                <span className="ps-1">{mb.description}</span>
                                                                                                {/* <span style={{fontSize: 13+'px'}}>
                                                                                                    <div>{ mb.manufacturer_name !== '' && <><span className="ps-1 text-muted">Manufacturer:</span> {mb.manufacturer_name}</>}{mb.manufacturer_name !== '' && mb.manufacturer_part_number !== '' && <span> - {mb.manufacturer_part_number}</span>}</div>
                                                                                                    <div>{ mb.supplier_name !== '' && <><span className="ps-1 text-muted">Supplier:</span> {mb.supplier_name}</>}{mb.supplier_name !== '' && mb.supplier_part_number !== '' && <span> - {mb.supplier_part_number}</span>}</div>
                                                                                                </span> */}
                                                                                            </td>
                                                                                            <td className="col-1"><span className="ps-1">{mb.material_type}</span></td>
                                                                                            <td className="col-1"><span className="ps-1">{mb.supplier_name}</span></td>
                                                                                            <td className="col-2"><span className="ps-1">{parseFloat(mb.requirement_per_piece)}</span></td>
                                                                                            <td className="col-1"><span className="ps-1"></span></td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                            })}
                                                                    </tbody>
                                                                </table>
                                                        }
                                                    </td>

                                                </tr>
                                            </React.Fragment>
                                        )
                                    } else {
                                        let part_number_text = p.archiver_ID > 0 ? `(${p.part_number})` : p.part_number;
                                        let archive_button_class = parseInt(p.archiver_ID) > 0 ? 'border-success text-success' : 'border-danger text-danger';
                                        let archive_button_icon = parseInt(p.archiver_ID) > 0 ? 'fa-rotate-left' : 'fa-trash-can';
                                        
                                        return (
                                            <tr className={`row ${SelectedPart.part_ID > 0 && 'table-secondary'}`} key={p.part_ID}>
                                                <td className="col-1">
                                                    <button className={`btn btn-sm ${archive_button_class}`} disabled={SelectedPart.save_button_text === 'Saving'} onClick={() => archivePart(p.part_ID, p.archiver_ID)}><FontAwesomeIcon icon={archive_button_icon} className="" /></button>
                                                    <button className="btn btn-sm border-primary text-primary float-end" onClick={() => handleButtonClick('edit_part', p)}><FontAwesomeIcon icon="fa-pencil" className="" /></button>
                                                </td>
                                                <td className={`col-7 ps-4 ${p.archiver_ID > 0 && 'text-muted'}`}>{part_number_text}</td>
                                                <td className={`col-1 ps-4 ${p.archiver_ID > 0 && 'text-muted'}`}>{p.hourly_standard_rate}</td>
                                                <td className={`col-1 ps-4 ${p.archiver_ID > 0 && 'text-muted'}`}>${p.sale_price}</td>
                                                <td className={`col-1 ps-4 ${p.archiver_ID > 0 && 'text-muted'}`}>${p.standard_tool_cost}</td>
                                                <td className={`col-1 ps-4 ${p.archiver_ID > 0 && 'text-muted'}`}>{p.scrap_percentage_cost_sheet}%</td>
                                            </tr>
                                        )
                                    }
                                })}
                            </tbody>
                        }
                        {
                            BaseState.FilteredParts.length === 0 &&
                            <tbody className="">
                                <tr>
                                    <td colSpan="7">
                                        <div className="alert alert-info m-0">There are no Parts matching this filter</div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </table>
                }
            </div>
        </React.Fragment>
    )
}

export const PartsComponent = compose(
	queryWithLoading({
		gqlString: GetPartsComponentData,
        name: "componentData",
        variablesFunction: () => ({ active: 1 })
	}),
)(PartsContent)