import { gql } from '@apollo/client';

export const GetControlPanelPlan = gql`
    query GetControlPanelPlan($machine_ID: Int, $part_ID: Int) {
        control_panel_plans(machine_ID: $machine_ID, part_ID: $part_ID) {
            control_plan_ID
            archiver_ID
            creator_ID
            date_archived
            date_created
            building_ID
            checksheet_ID
            control_panel_visible
            control_plan_number
            control_plan_type
            instructions,
            inspection_step
            last_checksheet_creator_ID
            last_checksheet_date_created
            note
            operation_name
            operation_number
            part_ID
            part_number
            required_every
            responsibility
        }
    }
`
// Get the Control Plans for Parts 1,2,3 (157,161,162)
export const GetControlPanelPlans = gql`
    query GetControlPanelPlans {
        control_panel_plans2 {
            control_plan_ID
            archiver_ID
            creator_ID
            date_archived
            date_created
            building_ID
            checksheet_ID
            control_panel_visible
            control_plan_number
            control_plan_type
            instructions,
            inspection_step
            last_checksheet_creator_ID
            last_checksheet_date_created
            note
            operation_name
            operation_number
            part_ID
            part_number
            required_every
            responsibility
        }
    }
`
