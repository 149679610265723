import { gql } from '@apollo/client';

export const GetMachineOperators = gql`
    query GetMachineOperators($ender_ID: Int, $machine_ID: Int, $starter_ID: Int) {
        machines_operators (ender_ID: $ender_ID, machine_ID: $machine_ID, starter_ID: $starter_ID) {
            machine_operator_ID,
            date_ended,
            date_started,
            ender_ID,
            first_name,
            last_name,
            machine_ID,
            starter_ID,
            user_ID
        }
    }
`;

// export const GetMaterialsPage = gql`
//     query GetMaterialsPage {
//         materials {
//             material_ID,
//             archiver_ID,
//             cost_per_piece,
//             cost_per_pound,
//             creator_ID,
//             date_archived,
//             date_created,
//             description,
//             material_type,
//             material_type_ID,
//             part_number,
//             parts_per,
//             supplier_ID,
//             supplier_name,
//             weight
//         }
//         suppliers {
//             supplier_ID,
//             archiver_ID,
//             creator_ID,
//             date_archived,
//             date_created,
//             supplier_name
//         }
//     }
// `;

export const SaveMachineOperator = gql`
	mutation machineOperatorSave($input: MachineOperatorInput) {
		machineOperatorSave (input: $input) {
            MachineOperators {
                machine_operator_ID,
                date_ended,
                date_started,
                ender_ID,
                first_name,
                last_name,
                machine_ID,
                starter_ID,
                user_ID
            }
		}
	}
`;

