import { gql } from '@apollo/client';

export const GetUsers = gql`
    query GetUsers($user_type_ID: Int) {
        users(user_type_ID: $user_type_ID) {
            user_ID,
            active,
            apprenticeship,
            archiver_ID,
            assignment,
            badge_number,
            citizenship,
            citizenship_note,
            college,
            company_ID,
            creator_ID,
            date_archived,
            date_birth,
            date_created,
            date_hired,
            date_next_review,
            date_visa_expiration,
            date_visa_issued,
            default_cost_sub_group,
            department_ID,
            educational_grade,
            email,
            emergency_note,
            employee_fax,
            employee_phone,
            employee_number,
            employment_status,
            first_name,
            gender,
            home_address,
            home_phone,
            hourly_rate,
            individual_job_description,
            individual_mission_statement,
            last_name,
            logged_in,
            martial_status,
            middle_name,
            mobile_phone,
            next_review_form_ID,
            other_experience,
            overtime_rate,
            password,
            position_ID,
            reports_to_user_ID,
            source_of_hire,
            visa_type,
            user_name,
            user_type_ID,
            work_eligibility_proof,
            work_schedule_ID
        }
    }
`;

export const GetUser = gql`
    query GetUser($user_ID: Int) {
        users(user_ID: $user_ID) {
            user_ID,
            active,
            apprenticeship,
            archiver_ID,
            assignment,
            badge_number,
            citizenship,
            citizenship_note,
            college,
            company_ID,
            creator_ID,
            date_archived,
            date_birth,
            date_created,
            date_hired,
            date_next_review,
            date_visa_expiration,
            date_visa_issued,
            default_cost_sub_group,
            department_ID,
            educational_grade,
            email,
            email_personal,
            emergency_note,
            employee_fax,
            employee_phone,
            employee_number,
            employment_status,
            first_name,
            gender,
            home_address,
            home_phone,
            hourly_rate,
            individual_job_description,
            individual_mission_statement,
            last_name,
            logged_in,
            martial_status,
            middle_name,
            mobile_phone,
            next_review_form_ID,
            other_experience,
            overtime_rate,
            password,
            position_ID,
            reports_to,
            reports_to_user_ID,
            source_of_hire,
            visa_type,
            user_name,
            user_type_ID,
            work_eligibility_proof,
            work_schedule_ID
        }
    }
`;

export const GetEmployeesList = gql`
    query GetEmployeesList($user_type_ID: Int) {
        companies {
            company_ID
            company
            company_short
            active
        }
        users(user_type_ID: $user_type_ID) {
            user_ID,
            active,
            apprenticeship,
            archiver_ID,
            assignment,
            badge_number,
            benefits_cost,
            citizenship,
            citizenship_note,
            college,
            company_ID,
            creator_ID,
            date_archived,
            date_birth,
            date_created,
            date_hired,
            date_next_review,
            date_visa_expiration,
            date_visa_issued,
            default_cost_sub_group,
            department_ID,
            direct_labor,
            educational_grade,
            email,
            email_personal,
            emergency_note,
            employee_fax,
            employee_phone,
            employee_number,
            employment_status,
            first_name,
            gender,
            home_address,
            home_phone,
            hourly_rate,
            individual_job_description,
            individual_mission_statement,
            last_name,
            logged_in,
            martial_status,
            middle_name,
            mobile_phone,
            next_review_form_ID,
            other_experience,
            overtime_rate,
            password,
            position_ID,
            reports_to,
            reports_to_user_ID,
            salary,
            source_of_hire,
            visa_type,
            user_name,
            user_type_ID,
            work_eligibility_proof,
            work_schedule_ID
        }
        work_schedules {
            work_schedule_ID,
            active,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            description,
            sunday1_shift_ID,
            monday1_shift_ID,
            tuesday1_shift_ID,
            wednesday1_shift_ID,
            thursday1_shift_ID,
            friday1_shift_ID,
            saturday1_shift_ID,
            sunday2_shift_ID,
            monday2_shift_ID,
            tuesday2_shift_ID,
            wednesday2_shift_ID,
            thursday2_shift_ID,
            friday2_shift_ID,
            saturday2_shift_ID,
            note
        }

    }
`;

export const GetOperators = gql`
    query GetOperators {
        logged_in_operators {
            user_ID
            first_name
            last_name
            logged_in
        }
    }
`;

export const GetUserDetailsPage = gql`
    query GetUserDetailsPage {
        departments {
            department_ID,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            building,
            department_code,
            department_name,
            mission,
            note
        }
        positions {
            position_ID,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            position,
            position_category,
            note
        }
    }
`;

export const SaveUser= gql`
	mutation userSave($input: UserInput) {
		userSave (input: $input) {
            User {
                user_ID,
                active,
                apprenticeship,
                archiver_ID,
                assignment,
                badge_number,
                benefits_cost,
                citizenship,
                citizenship_note,
                college,
                company_ID,
                creator_ID,
                date_archived,
                date_birth,
                date_created,
                date_hired,
                date_next_review,
                date_visa_expiration,
                date_visa_issued,
                default_cost_sub_group,
                department_ID,
                direct_labor,
                educational_grade,
                email,
                email_personal,
                emergency_note,
                employee_fax,
                employee_phone,
                employee_number,
                employment_status,
                first_name,
                gender,
                home_address,
                home_phone,
                hourly_rate,
                individual_job_description,
                individual_mission_statement,
                last_name,
                logged_in,
                martial_status,
                middle_name,
                mobile_phone,
                next_review_form_ID,
                other_experience,
                overtime_rate,
                password,
                position_ID,
                reports_to,
                reports_to_user_ID,
                salary,
                source_of_hire,
                visa_type,
                user_name,
                user_type_ID,
                work_eligibility_proof,
                work_schedule_ID
            }
		}
	}
`;
