import React from 'react';
// import { Multiselect } from 'multiselect-react-dropdown';
import {flowRight as compose} from 'lodash';
import { queryWithLoading } from '../utilities/NSDb.js';
import { useState } from 'react';
import dayjs from 'dayjs';
import { add } from 'mathjs';
import { useAuthUser } from 'react-auth-kit';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
// import { useMutation, useLazyQuery } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// GRAPHQL QUERY
import { 
    GetEmployeeTCRAs,
    GetEmployeeSchedule,
    GetEmployeeSchedulePage
    // ,
    // SaveTimeClockRecord,
    // SaveTimeClockRecordScheduleDesignation
} from './time_clock_records_graphql.js';

function EmployeeScheduleContent(props) {
    console.log({props})
    const auth = useAuthUser();

    const { schedule_designations: ScheduleDesignations } = props.componentData;
    // let FilterFlags = [ 
    //     { flag: 'Late', value: 'late' }, 
    //     { flag: 'Left Early', value: 'left_early' }, 
    //     { flag: 'Approved', value: 'approved' }, 
    //     { flag: 'Needs Approval', value: 'approver_ID' }, 
    //     { flag: 'Started Early', value: 'came_early' }, 
    //     { flag: 'Left Late', value: 'left_late' } ];

    // if ( parseInt(auth().User.user_ID) === 32 || parseInt(auth().User.user_ID) === 1 ) FilterFlags.push({ flag: 'Amat', value: 'Amat' }); FilterFlags.push({ flag: 'Larry', value: 'Larry' });

    // const [save_time_clock_record] = useMutation(SaveTimeClockRecord);
    // const [save_time_clock_record_schedule_designation] = useMutation(SaveTimeClockRecordScheduleDesignation);
    const [get_employee_TCRAs ] = useLazyQuery(GetEmployeeTCRAs, { fetchPolicy: 'network-only'});
    const [get_employee_schedule ] = useLazyQuery(GetEmployeeSchedule, { fetchPolicy: 'network-only'});

    const DefaultScheduleDesignation = {
        hours: '', 
        minutes: '',
        schedule_designation_ID: 0,
        time_clock_record_ID: 0,
        time_clock_record_schedule_designation_ID: 0
    };

    const DefaultSelectedTimeClockRecord = {
        time_clock_record_ID: 0,
        approved: 0,
        approver_ID: 0,
        clockin_type_ID: 0,
        date_scheduled: '',
        lunch_paid: '',
        note: '',
        save_button_text: 'Save',
        save_message: '',
        save_message_class: 'success',
        shift_group: 0,
        shift_ID: 0,
        shift_name: '',
        tcrn_creator: '',
        tcrn_creator_ID: 0,
        tcrn_date_created: '',
        tcrn_date_updated: '',
        tcrn_note: '',
        tcrn_note_original: '',
        tcrn_own_note: 0,
        tcrn_updater: '',
        tcrn_updater_ID: 0,
        time_clock_record_note_ID: 0,
        time_scheduled_in: '',
        time_scheduled_out: '',
        time_scheduled_hours: '',
        time_actual_in: '',
        time_actual_out: '',
        time_actual_hours: '',
        time_pay_in: '',
        time_pay_out: '',
        time_pay_hours: '',
        user_ID: 0,

        time_scheduled_in_hour: '-1',
        time_scheduled_in_minute: '-1',
        time_scheduled_in_am_pm: '',
        time_scheduled_out_hour: '-1',
        time_scheduled_out_minute: '-1',
        time_scheduled_out_am_pm: '',

        time_actual_in_hour: '-1',
        time_actual_in_minute: '-1',
        time_actual_in_am_pm: '',
        time_actual_out_hour: '-1',
        time_actual_out_minute: '-1',
        time_actual_out_am_pm: '',

        time_pay_in_hour: '-1',
        time_pay_in_minute: '-1',
        time_pay_in_am_pm: '',
        time_pay_out_hour: '-1',
        time_pay_out_minute: '-1',
        time_pay_out_am_pm: '',

        // for schedule designation
        ScheduleDesignation: DefaultScheduleDesignation

    };

    let temp_schedule_filter = handleScheduleFilter('this_week');

    const [SelectedSchedule, setSelectedSchedule] = useState({ Employees: [] });
    const [SelectedTimeClockRecord, setSelectedTimeClockRecord] = useState({ ...DefaultSelectedTimeClockRecord });
    const [BaseState, setBaseState] = useState({
        action: '',
        EmployeesFiltered: [],
        filter_date_range: 'this_week',
        filtered_results_count: 0,
        message: '',
        message_class: '',
        schedule_end: temp_schedule_filter.temp_schedule_end,
        schedule_start: temp_schedule_filter.temp_schedule_start,
        view: ''
    });

    // Handler function for changing filters
    function handleChangeFilter(name, value) {
        if ( name === 'filter_date_range' ) {
            let temp_schedule_filter = handleScheduleFilter(value);
            setBaseState({
                ...BaseState,
                schedule_end: temp_schedule_filter.temp_schedule_end,
                schedule_start: temp_schedule_filter.temp_schedule_start,
                action: '',
                filter_date_range: value,
                message: '',
                message_class: '',
                view: ''
            })
        }
    }

    // Take today's date and loop backwards until you get Sunday to use as your start day
    function handleScheduleFilter(filter) {
        let temp_weekday = dayjs().format('dddd');
        let temp_schedule_start = dayjs().format('YYYY-MM-DD');
        let temp_schedule_end = dayjs().add(6, 'day').format('YYYY-MM-DD');

        // Set this week's start and end
        if ( temp_weekday !== 'Sunday') {
            [...Array(6)].forEach((d, index) => {
                // If this is Sunday, set schedule_start
                if ( dayjs().add(-index, 'day').format('dddd') === 'Sunday' ) {
                    temp_schedule_start = dayjs().add(-index, 'day').format('YYYY-MM-DD');
                    temp_schedule_end = dayjs(temp_schedule_start).add(6, 'day').format('YYYY-MM-DD');
                }
            });
        }

        if ( filter === 'last_week' ) {
            temp_schedule_start = dayjs(temp_schedule_start).add(-7, 'day').format('YYYY-MM-DD');
            temp_schedule_end = dayjs(temp_schedule_end).add(-7, 'day').format('YYYY-MM-DD');
        } else if ( filter === 'next_week' ) {
            temp_schedule_start = dayjs(temp_schedule_start).add(7, 'day').format('YYYY-MM-DD');
            temp_schedule_end = dayjs(temp_schedule_end).add(7, 'day').format('YYYY-MM-DD');
        } else if ( filter === 'next_two_weeks' ) {
            temp_schedule_start = dayjs(temp_schedule_start).add(7, 'day').format('YYYY-MM-DD');
            temp_schedule_end = dayjs(temp_schedule_end).add(14, 'day').format('YYYY-MM-DD');
        }

        return { temp_schedule_end, temp_schedule_start };
    }

    // Take the start and end dates and create a SelectedSchedule for each employee - this is the table of displayed shifts only, this function does not save anything
    async function handleGenerateSchedule(view) {
        // Get all Timeclock Records that match the filters of the Schedule created
        const GetEmployeeSchedule = await get_employee_schedule({ variables: { date_scheduled_end: BaseState.schedule_end, date_scheduled_start: BaseState.schedule_start, user_ID: parseInt(auth().User.user_ID) } });

        if (GetEmployeeSchedule.data && GetEmployeeSchedule.data.employee_schedule) {
            console.log("GetEmployeeSchedule.data.employee_schedule: ", GetEmployeeSchedule.data.employee_schedule)

            const GetTCRAs = await get_employee_TCRAs({ variables: { date_scheduled_end: BaseState.schedule_end, date_scheduled_start: BaseState.schedule_start, user_ID: parseInt(auth().User.user_ID) } });

            // First Create array of employees to schedule
            let TempSchedule = [auth().User];

            // Number of days of schedule to build out:
            let days = dayjs(BaseState.schedule_end).diff(dayjs(BaseState.schedule_start), 'day')+1;

            // Add Schedule of 'days' for each employee
            TempSchedule = TempSchedule.map((s) => {

                // As we loop through the filters' dates next, get the filtered array of TimeClockRecords for this user so we can compare against that smaller array each time in the loop below
                // let TempUserTimeClockRecords = [];

                // if ( GetEmployeeSchedule.data && GetEmployeeSchedule.data.employee_schedule && GetEmployeeSchedule.data.employee_schedule.length > 0 ) {
                //     TempUserTimeClockRecords = GetEmployeeSchedule.data.employee_schedule.filter((tcr) => parseInt(tcr.user_ID) === parseInt(s.user_ID) );
                // }
                let TempUserTimeClockRecords = GetEmployeeSchedule.data.employee_schedule;

                // Map over the TCRs and add in the TCRAs array to a new key
                TempUserTimeClockRecords = TempUserTimeClockRecords.map((tcr) => {
                    let TempTCRAs = GetTCRAs.data.employee_TCRAs.filter((tcra) => parseInt(tcra.time_clock_record_ID) === parseInt(tcr.time_clock_record_ID));
                    TempTCRAs = TempTCRAs.length > 0 ? TempTCRAs : [];
                    return { ... tcr, TCRAs: TempTCRAs };
                })

                // Create an array of the days choosen
                let TempUserDays = [];
                [...Array(days)].map((d, index) => {

                    // This is the date of the selected range of the filter
                    // let temp_date_scheduled = dayjs(BaseState.schedule_start).add(index, 'day').format('YYYY-MM-DD')
                    
                    // If there is already a TimeClockRecord for this user for this date, add that data to the return object here
                    let TempTCRsDay = TempUserTimeClockRecords.filter((tcr) => dayjs(tcr.date_scheduled).isSame(dayjs(BaseState.schedule_start).add(index, 'day')));

                    // TimeClockRecord(s) already exists for this day, use that
                    if ( TempTCRsDay.length > 0 ) {

                        // Push each TCR to the TempUserDays array, which goes into the Schedule object for each User
                        TempTCRsDay.forEach((tcr) => {
                            let current_TCR = tcr;

                            // Handle time variables for each of the TCRAs
                            current_TCR.TCRAs = current_TCR.TCRAs.map((tcra) => {
                                let temp_tcra = { ...tcra };
                                // Set Time Actual values
                                temp_tcra.time_actual_in = temp_tcra.time_actual_in !== '' && temp_tcra.time_actual_in !== null ? dayjs(`${temp_tcra.time_actual_in}`).format('YYYY-M-D h:mm a') : '';
                                
                                temp_tcra.time_actual_out = temp_tcra.time_actual_out !== '' && temp_tcra.time_actual_out !== null ? dayjs(`${temp_tcra.time_actual_out}`).format('YYYY-M-D h:mm a') : ''; 
                                // Use the database's hours record if there is one
                                temp_tcra.time_actual_hours = temp_tcra.time_actual_hours !== '' && temp_tcra.time_actual_hours !== null ? temp_tcra.time_actual_hours : '';
                                temp_tcra.time_actual_hours = temp_tcra.time_actual_hours === '' && temp_tcra.time_actual_in !== '' && temp_tcra.time_actual_out !== '' ?  dayjs(`${temp_tcra.time_actual_out}`).diff(dayjs(`${temp_tcra.time_actual_in}`), 'hour', true).toFixed(2) : temp_tcra.time_actual_hours;
                                // Account for overnight shifts - Leave this for now, but once temp_tcra is working, this line can go away
                                temp_tcra.time_actual_hours = temp_tcra.time_actual_hours < 0 ? add(temp_tcra.time_actual_hours, 24).toFixed(2) : temp_tcra.time_actual_hours;

                                // Set Time Pay values
                                temp_tcra.time_pay_in = temp_tcra.time_pay_in !== '' && temp_tcra.time_pay_in !== null ? dayjs(`${temp_tcra.time_pay_in}`).format('YYYY-M-D h:mm a') : '';
                                temp_tcra.time_pay_out = temp_tcra.time_pay_out !== '' && temp_tcra.time_pay_out !== null ? dayjs(`${temp_tcra.time_pay_out}`).format('YYYY-M-D h:mm a') : '';
                                // Use the database's hours record if there is one
                                temp_tcra.time_pay_hours = temp_tcra.time_pay_hours !== '' && temp_tcra.time_pay_hours !== null ? temp_tcra.time_pay_hours : '';
                                temp_tcra.time_pay_hours = temp_tcra.time_pay_hours === '' && temp_tcra.time_pay_in !== '' && temp_tcra.time_pay_out !== '' ?  dayjs(`${temp_tcra.time_pay_out}`).diff(dayjs(`${temp_tcra.time_pay_in}`), 'hour', true).toFixed(2) : temp_tcra.time_pay_hours;
                                // Account for overnight shifts - Leave this for now, but once temp_tcra is working, this line can go away
                                temp_tcra.time_pay_hours = temp_tcra.time_pay_hours < 0 ? add(temp_tcra.time_pay_hours, 24).toFixed(2) : temp_tcra.time_pay_hours;
                                
                                // Temporary fix for calculating pay hours 
                                /// Compare date_schdeuled in to actual in, if more than 10 minutes before shift, pay is set to actual, otherwise pay = scheduled
                                // If we have a value saved in the db, use that instead of calculating it from the actual times in/out

                                temp_tcra.time_pay_in = temp_tcra.time_pay_in !== '' && temp_tcra.time_pay_in !== null ? temp_tcra.time_pay_in : '';
                                // Positive temp_pay_in_diff means getting in before scheduled, negative means they're late. 
                                let temp_pay_in_diff = dayjs(`${current_TCR.date_scheduled_in}`).diff(dayjs(`${dayjs(`${temp_tcra.time_actual_in}`).format('YYYY-MM-DD HH:mm')}`), 'minute', true);
                                if(temp_tcra.time_pay_in === '' && temp_tcra.time_actual_in !== '') {
                                    temp_tcra.time_pay_in = temp_pay_in_diff > 10 ? dayjs(`${temp_tcra.time_actual_in}`).format('YYYY-M-D h:mm a') : dayjs(`${current_TCR.date_scheduled_in}`).format('YYYY-M-D h:mm a');
                                    temp_tcra.time_pay_in = temp_pay_in_diff < 0 ? dayjs(`${temp_tcra.time_actual_in}`).format('YYYY-M-D h:mm a') : temp_tcra.time_pay_in;
                                }

                                temp_tcra.time_pay_out = temp_tcra.time_pay_out !== '' && temp_tcra.time_pay_out !== null ? temp_tcra.time_pay_out : '';
                                // positive temp_pay_out_diff means getting in before scheduled, negative means they're late. 
                                let temp_pay_out_diff = dayjs(`${dayjs(`${temp_tcra.time_actual_out}`).format('YYYY-MM-DD HH:mm')}`).diff(dayjs(`${current_TCR.date_scheduled_out}`), 'minute', true);
                                if(temp_tcra.time_pay_out === '' && temp_tcra.time_actual_out !== '') {
                                    temp_tcra.time_pay_out = temp_pay_out_diff > 10 ? dayjs(`${temp_tcra.time_actual_out}`).format('YYYY-M-D h:mm a') : dayjs(`${current_TCR.time_scheduled_out}`).format('YYYY-M-D h:mm a');
                                    temp_tcra.time_pay_out = temp_pay_out_diff < 0 ? dayjs(`${temp_tcra.time_actual_out}`).format('YYYY-M-D h:mm a') : temp_tcra.time_pay_out;
                                }

                                temp_tcra.time_pay_hours = temp_tcra.time_pay_hours !== '' && temp_tcra.time_pay_hours !== null ? temp_tcra.time_pay_hours : '';
                                // Calculate time_pay_hours if it is not in the db
                                if(temp_tcra.time_pay_hours === '' && temp_tcra.time_pay_in !== '' && temp_tcra.time_pay_out !== '') {
                                    temp_tcra.time_pay_hours = dayjs(`${temp_tcra.time_pay_out}`).diff(dayjs(`${temp_tcra.time_pay_in}`), 'hour', true).toFixed(2);
                                    temp_tcra.time_pay_hours = temp_tcra.time_pay_hours < 0 ? add(temp_tcra.time_pay_hours, 24).toFixed(2) : temp_tcra.time_pay_hours;
                                    // Subtract lunch 0.5 hours if unpaid lunch on shift
                                    temp_tcra.time_pay_hours = (current_TCR.lunch_paid !== null && parseInt(current_TCR.lunch_paid) === 0) || (current_TCR.lunch_paid === null && parseInt(current_TCR.shifts_lunch_paid) === 0) ? add(temp_tcra.time_pay_hours, -0.5).toFixed(2) : temp_tcra.time_pay_hours;
                                }
                                return temp_tcra;
                            });

                            // If the time_clock_record has lunch_paid or shift_group on it, use that, otherwise get it from the shift_ID
                            TempUserDays.push({ 
                                ...current_TCR,
                                absent: 0,
                                came_early: 0,
                                late: 0,
                                left_early: 0,
                                left_late: 0,
                                // absent: dayjs(current_TCR.date_scheduled).diff(dayjs(), 'day') < 0 && temp_time_actual_in === '' && parseInt(current_TCR.schedule_designation_ID) !== 5 ? 1 : 0,
                                // came_early: temp_pay_in_diff > 10 ? 1 : 0,
                                // late: temp_pay_in_diff < 0 ? 1 : 0,
                                // left_early: temp_pay_out_diff < 0 ? 1 : 0,
                                // left_late: temp_pay_out_diff > 10 ? 1 : 0,
                                ScheduleDesignation: {
                                    hours: current_TCR.hours === null ? '' : current_TCR.hours, 
                                    minutes: current_TCR.minutes === null ? '' : current_TCR.minutes,
                                    schedule_designation_ID: current_TCR.schedule_designation_ID === null ? 0 : current_TCR.schedule_designation_ID,
                                    time_clock_record_ID: current_TCR.time_clock_record_ID === null ? 0 : current_TCR.time_clock_record_ID,
                                    time_clock_record_schedule_designation_ID: current_TCR.time_clock_record_schedule_designation_ID === null ? 0 : current_TCR.time_clock_record_schedule_designation_ID
                                },
                                time_scheduled_hours: current_TCR.time_scheduled_hours !== null ? current_TCR.time_scheduled_hours : '',
                                time_scheduled_in: current_TCR.time_scheduled_in !== null ? dayjs(`${current_TCR.date_scheduled} ${current_TCR.time_scheduled_in}`).format('h:mm a') : '', 
                                time_scheduled_out: current_TCR.time_scheduled_out !== null ? dayjs(`${current_TCR.date_scheduled} ${current_TCR.time_scheduled_out}`).format('h:mm a') : '',
                                date_scheduled_in: current_TCR.date_scheduled_in !== null ? dayjs(`${current_TCR.date_scheduled_in}`).format('YYYY-MM-DD HH:mm') : '', 
                                date_scheduled_out: current_TCR.date_scheduled_out !== null ? dayjs(`${current_TCR.date_scheduled_out}`).format('YYYY-MM-DD HH:mm') : '',
                                time_actual_in: '', 
                                time_actual_out: '', 
                                time_actual_hours: '', 
                                time_pay_in: '', 
                                time_pay_out: '', 
                                time_pay_hours: ''
                                // time_actual_in: temp_time_actual_in, 
                                // time_actual_out: temp_time_actual_out, 
                                // time_actual_hours: temp_time_actual_hours, 
                                // time_pay_in: temp_time_pay_in !== '' ? dayjs(`${current_TCR.date_scheduled} ${temp_time_pay_in}`).format('h:mm a') : '', 
                                // time_pay_out: temp_time_pay_out !== '' ? dayjs(`${current_TCR.date_scheduled} ${temp_time_pay_out}`).format('h:mm a') : '', 
                                // time_pay_hours: temp_time_pay_hours
                            });
                        })

                    } 
                    else {
                        return { time_clock_record_ID: -1 };
                    }
                });

                return { ...s, Schedule: TempUserDays}
            });

            setSelectedSchedule({
                Employees: [...TempSchedule]
            });

            if ( view !== '' ) {
                setBaseState({ ...BaseState, action: '', message: '', message_class: '', view: view});
                setSelectedTimeClockRecord({ ...DefaultSelectedTimeClockRecord });
            }
        }
    }

    console.log({SelectedSchedule})
    console.log({SelectedTimeClockRecord})
    console.log(BaseState)
    return (
        <>
            <div>
                <div className="row clearfix">
                    {/* RESULTS COUNTER AND FILTERS */}
                    <div className="col-auto">
                        <div className="form-floating">
                            <select 
                                className="form-select" 
                                disabled={false}
                                id="filter_date_range"
                                onChange={(event) => handleChangeFilter('filter_date_range', event.target.value)}
                                placeholder="Date Range"
                                value={BaseState.filter_date_range}
                            >
                                <option value="this_week">This Week</option>
                                <option value="last_week">Last Week</option>
                                <option value="next_week">Next Week</option>
                                <option value="next_two_weeks">Next 2 Weeks</option>
                            </select> 
                            <label className="text-muted" htmlFor="filter_date_range">Date Range</label>                                                   
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-floating">
                            <input type="date" className="form-control mb-3" id="schedule_start" placeholder="Enter a Start Date" value={BaseState.schedule_start || ""} onChange={(event) => setBaseState({ ...BaseState, action: '', save_button_text: 'Save', save_message: '', schedule_start: event.target.value, view: '' })} />
                            <label className="text-muted" htmlFor="schedule_start">Start Date</label>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-floating">
                            <input type="date" className="form-control mb-3" id="schedule_end" placeholder="Enter an End Date" value={BaseState.schedule_end || ""} onChange={(event) => setBaseState({ ...BaseState, action: '', save_button_text: 'Save', save_message: '', schedule_end: event.target.value, view: '' })} />
                            <label className="text-muted" htmlFor="schedule_end">End Date</label>
                        </div>
                    </div>
                    <div className="col-auto h-100">
                        <button 
                            className="btn btn-lg mt-1 fw-bold col-auto border-primary border-3 float-end text-primary" 
                            onClick={() => handleGenerateSchedule('view_attendance_summary')}
                            disabled={BaseState.schedule_end === '' || BaseState.schedule_start === ''}
                        >
                            Search Schedule
                        </button>
                    </div>
                    {
                        BaseState.message !== '' &&
                        <div className="col-auto">
                            <div className={`alert m-0 mt-1 py-2 alert-${BaseState.message_class}`}>{BaseState.message}</div>
                        </div>
                    }
                </div>

                {/* Key definitions */}
                <div className="row clearfix h4">
                    <div className="col-auto">Time Clock Records - Color Meanings</div>
                    <div>
                        <span className="col-auto badge bg-warning">More Than 10 Min Early or Past Shift End</span>
                        <span className="col-auto badge bg-danger ms-2">Late or Left Early</span>
                    </div>
                </div>

                {/* Attendance Summary - BaseState.view = 'view_attendance_summary */}
                {
                    BaseState.view === 'view_attendance_summary' &&
                    SelectedSchedule.Employees.length > 0 &&
                    <table className="table table-striped mt-3">
                        <thead className="row h6 border border-2 border-top-0 border-start-0 border-end-0 border-dark mb-0">
                            <tr className="col-4">Scheduled</tr>
                            <tr className="col-4">Clock In / Out</tr>
                            <tr className="col-4">Pay In / Out</tr>
                            {/* <tr className="col-6">Note - See an issue with a time clock record? Leave a note for your manager</tr> */}
                        </thead>
                        <tbody className="">
                            {SelectedSchedule.Employees.map((ss) => {
                                let temp_total_hours_scheduled = 0;
                                let temp_total_hours_actual = 0;
                                let temp_total_hours_holiday = 0;
                                let temp_total_hours_paid = 0;
                                return (
                                    <React.Fragment key={ss.user_ID}>

                                        {/* Loop through employee's scheduled shifts */}
                                        {ss.Schedule.map((s, index) => {
                                            console.log({s})
                                            if ( s.time_clock_record_ID > -1 ) {
                                                temp_total_hours_scheduled = add(temp_total_hours_scheduled, s.time_scheduled_hours).toFixed(2);
                                                temp_total_hours_actual = add(temp_total_hours_actual, s.time_actual_hours).toFixed(2);
                                                // let temp_shift_hours_actual = 0;
                                                // let temp_shift_hours_actual_count = 0;
                                                // let temp_shift_hours_pay = 0;
                                                // let temp_shift_hours_pay_count = 0;
                                                return (
                                                    <React.Fragment key={`${ss.user_ID}_${index}`}>

                                                        {/* Row of Scheduled Shift Details */}
                                                        <tr className={`row ${parseInt(s.shift_ID) === 0 && 'bg-light'} ${parseInt(SelectedTimeClockRecord.time_clock_record_ID) > 0 && parseInt(SelectedTimeClockRecord.time_clock_record_ID) === parseInt(s.time_clock_record_ID) && 'border border-success border-bottom-0'}`}>
                                                            <td className={`col-4 ps-3`}>
                                                                <div className="">
                                                                    {s.time_scheduled_in}{ (s.time_scheduled_in !== '' || s.time_scheduled_out !== '') && ' - '} {s.time_scheduled_out}
                                                                </div>
                                                                <div className="fw-bolder">
                                                                    {dayjs(s.date_scheduled).format('ddd M/D')}
                                                                    {/* {s.time_scheduled_hours !== null && s.time_scheduled_hours !== '' && <span className="ms-3">{parseFloat(s.time_scheduled_hours)} hrs</span>} */}
                                                                </div>
                                                            </td>
                                                            {/* <td className={`col-2 ps-3`}>
                                                                {((s.lunch_paid !== null && parseInt(s.lunch_paid) === 1) || (s.lunch_paid === null && parseInt(s.shifts_lunch_paid) === 1)) && <span className="float-end text-dark me-3"><FontAwesomeIcon icon="fa-dollar-sign" className="" /></span>}
                                                            </td> */}

                                                            {/* TIME ACTUAL */}
                                                            <td className={`col-4 ps-3`}>
                                                                { s.absent === 1 && parseInt(s.ScheduleDesignation.schedule_designation_ID) === 0 && <span className="badge bg-white rounded-pill border border-danger text-danger">Absent</span> }
                                                                
                                                                {/* Loop through TCRA for this shift */}
                                                                { s.TCRAs.map((tcra) => {
                                                                    return (
                                                                        <div key={`${tcra.time_clock_records_actual_ID}_actual`}>
                                                                            <span className={`${s.late === 1 && 'text-danger'} ${s.came_early === 1 && 'text-warning'}`}>{ tcra.time_actual_in !== '' && dayjs(tcra.time_actual_in).format('h:mm a') }</span>
                                                                            { (tcra.time_actual_in !== '' || tcra.time_actual_out !== '') && ' - '} 
                                                                            <span className={`${s.left_early === 1 && 'text-danger'} ${s.left_late === 1 && 'text-warning'}`}>{ tcra.time_actual_out !== '' && dayjs(tcra.time_actual_out).format('h:mm a') }</span>
                                                                        </div>
                                                                    )
                                                                })}
                                                                
                                                                {/* <div>
                                                                    <span className={`${s.late === 1 && 'text-danger'} ${s.came_early === 1 && 'text-warning'}`}>{s.time_actual_in}</span>
                                                                    { (s.time_actual_in !== '' || s.time_actual_out !== '') && ' - '} 
                                                                    <span className={`${s.left_early === 1 && 'text-danger'} ${s.left_late === 1 && 'text-warning'}`}>{s.time_actual_out}</span>
                                                                </div> */}
                                                                
                                                                <div className="">
                                                                    {/* Schedule Designations */}
                                                                    { 
                                                                        parseInt(s.ScheduleDesignation.schedule_designation_ID) > 0 &&
                                                                        <span className="me-2">
                                                                            <span className="badge bg-white rounded-pill border border-primary text-primary">{ScheduleDesignations.filter((sd) => parseInt(sd.schedule_designation_ID) === parseInt(s.ScheduleDesignation.schedule_designation_ID))[0].schedule_designation}</span>
                                                                            <span className="text-primary ms-2">{s.ScheduleDesignation.hours !== '' && parseInt(s.ScheduleDesignation.hours) !== 0 && <span>{s.ScheduleDesignation.hours}h</span>} {s.ScheduleDesignation.minutes !== '' && parseInt(s.ScheduleDesignation.minutes) !== 0 &&  <span>{s.ScheduleDesignation.minutes}m</span>}</span>
                                                                        </span>
                                                                    }
                                                                    { s.time_actual_in !== '' && s.time_actual_out !== '' && <span className="fw-bolder">{s.time_actual_hours} hours</span>} 
                                                                </div>                                                                
                                                            </td>

                                                            {/* TIME PAY */}
                                                            <td className={`col-4 ps-3`}>

                                                                {/* Loop through TCRA for this shift */}
                                                                { s.TCRAs.map((tcra) => {
                                                                    console.log({tcra})
                                                                    // Do not add holiday shifts to their total hours paid yet for purposes of distinguishing OT total
                                                                    temp_total_hours_holiday = parseInt(s.ScheduleDesignation.schedule_designation_ID) === 5 ? add(temp_total_hours_holiday, tcra.time_pay_hours).toFixed(2) : temp_total_hours_holiday;
                                                                    temp_total_hours_paid = parseInt(s.ScheduleDesignation.schedule_designation_ID) === 0 ? temp_total_hours_paid : add(temp_total_hours_paid, tcra.time_pay_hours).toFixed(2);
                                                console.log({temp_total_hours_paid})

                                                                    return (
                                                                        <div key={`${tcra.time_clock_records_actual_ID}_pay`}>
                                                                            { tcra.time_pay_in !== '' && dayjs(tcra.time_pay_in).format('h:mm a') }
                                                                            { (tcra.time_pay_in !== '' || tcra.time_pay_out !== '') && ' - '} 
                                                                            { tcra.time_pay_out !== '' && dayjs(tcra.time_pay_out).format('h:mm a') }
                                                                        </div>
                                                                    )
                                                                })}

                                                                {/* <div>{s.time_pay_in}{ (s.time_pay_in !== '' || s.time_pay_out !== '') && ' - '} {s.time_pay_out}</div>
                                                                { s.time_pay_in !== '' && s.time_pay_out !== '' && <div className="fw-bolder">{s.time_pay_hours} hours</div>} */}
                                                            </td>
                                                        </tr>

                                                        {/* Bottom totals row */}
                                                        {
                                                            index+1 === ss.Schedule.length &&
                                                            <tr className="row border border-secondary border-3 border-bottom-0 border-start-0 border-end-0 mb-2">
                                                                <td className={`col-4 ps-3`}>
                                                                    <div>Scheduled:</div>
                                                                    <div className="fw-bolder">{temp_total_hours_scheduled} hours</div>
                                                                </td>
                                                                <td className={`col-4 ps-3`}>
                                                                    {/* <span className="fw-bolder">{temp_total_hours_actual}</span> */}
                                                                </td>
                                                                <td className={`col-4 ps-3`}>
                                                                    <div>Pay:</div>
                                                                    <div className="fw-bolder">{add(temp_total_hours_paid, temp_total_hours_holiday)} hours</div>
                                                                    {temp_total_hours_holiday !== 0 && <div> ({temp_total_hours_holiday} Holiday)</div>} 
                                                                    {dayjs(BaseState.schedule_start).format('dddd') === 'Sunday' && dayjs(BaseState.schedule_end).format('dddd') === 'Saturday' && dayjs(BaseState.schedule_end).diff(dayjs(BaseState.schedule_start), 'day') === 6 && temp_total_hours_paid > 40 && <div>({add(temp_total_hours_paid, -40).toFixed(2)} OT)</div>}
                                                                </td>
                                                                {/* <td className={`col-6 ps-3`}>
                                                                </td> */}
                                                            </tr>
                                                        }
                                                    </React.Fragment>
                                                )
                                            } else if ( index+1 === ss.Schedule.length ) {

                                                // Bottom totals row 
                                                return (
                                                    <tr className="row border border-secondary border-3 border-bottom-0 border-start-0 border-end-0 mb-2" key={`${ss.user_ID}_${index}_0`}>
                                                        <td className={`col-4 ps-3`}>
                                                            <div>Scheduled:</div>
                                                            <div className="fw-bolder">{temp_total_hours_scheduled} hours</div>
                                                        </td>
                                                        <td className={`col-4 ps-3`}>
                                                            {/* <span className="fw-bolder">{temp_total_hours_actual}</span> */}
                                                        </td>
                                                        <td className={`col-4 ps-3`}>
                                                            <div>Pay:</div>
                                                            <div className="fw-bolder">{add(temp_total_hours_paid, temp_total_hours_holiday)} hours</div>
                                                            {temp_total_hours_holiday !== 0 && <div> ({temp_total_hours_holiday} Holiday)</div>} 
                                                            {dayjs(BaseState.schedule_start).format('dddd') === 'Sunday' && dayjs(BaseState.schedule_end).format('dddd') === 'Saturday' && dayjs(BaseState.schedule_end).diff(dayjs(BaseState.schedule_start), 'day') === 6 && temp_total_hours_paid > 40 && <div>({add(temp_total_hours_paid, -40).toFixed(2)} OT)</div>}
                                                        </td>
                                                        {/* <td className={`col-6 ps-3`}>
                                                        </td> */}
                                                    </tr>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </React.Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                }

            </div>
        </>
    )
}

export const EmployeeScheduleComponent = compose(
	queryWithLoading({
		gqlString: GetEmployeeSchedulePage,
        name: "componentData"
	})
)(EmployeeScheduleContent)

