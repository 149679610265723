import React from 'react';
import { useState } from 'react';
import _ from 'lodash';
import { NavLink, useNavigate} from "react-router-dom";
import { useSignIn, useAuthUser } from 'react-auth-kit';
import {flowRight as compose} from 'lodash';
import dayjs from 'dayjs';

// import { withSession } from "../utilities/session";
// import { Collapse, DropdownToggle, DropdownMenu, DropdownItem, Nav, Navbar, NavItem, NavbarToggler, UncontrolledDropdown } from 'reactstrap';
// import { Collapse, Nav, Navbar, NavbarToggler } from 'reactstrap';
// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
import { useMutation } from '@apollo/client';
import { queryWithLoading } from '../utilities/NSDb.js';
// import { queryWithLoading, displayDateTime } from '../utilities/NSDb.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// GRAPHQL QUERY
import { LoginMutation, GetUsersNoPassword, 
//UserClockInOutLogInOut 
} from './auth_graphql.js';

// import { UserCreateTemporaryPasswordMutation } from "../users/users_graphql";

// MAIN RENDER COMPONENT:  main content that will render, need to wrap the output function in withRouter to get the Breadcrumb to have match.url
function LoginPassword(props) {

    const { users_no_login: UsersNoPassword } = props.componentData;
    const navigate = useNavigate();
    const signIn = useSignIn();
    const auth = useAuthUser();
    const [login_check] = useMutation(LoginMutation);
    //const [clock_in_log_in] = useMutation(UserClockInOutLogInOut);

    const [LoginState, setLoginState] = useState({
        action: '',
        message: '',
        Messages: [],
        no_password: false,
        password: '',
        password2: '',
        user_name: ''
    });

	// let logoImage = "cs_logo_small.png";
	//let logoImage = "";
	// if(props.Account.accountPrefix) {
	// 	logoImage = `${props.Account.accountPrefix}_logo.png`;
	// }
	// set background images
	let style = {};

	// if(props.Account.accountPrefix === "loyalpaws") {
	// 	let bgNumber = 1;
	// 	style.backgroundImage = `url(/images/ui/${props.Account.accountPrefix}_background${bgNumber}.jpg)`;
	// 	style.backgroundSize = 'cover';
	// 	style.backgroundPosition = 'center center';
	// 	style.backgroundRepeat = 'no-repeat';
	// } else {
	// 	style.backgroundColor = '#DDD';
	// }

	// function toggleTopNav() {
	// 	//e.preventDefault();
	// 	setState({
	// 		topNavOpen: !topNavOpen
	// 	})
	// }

    // Handler function for form's changing inputs
    function changeInputValue(name, value) {

        if ( (UsersNoPassword.length > 0 && UsersNoPassword.filter((u) => u.user_name === value).length === 1) || LoginState.action === 'new_password') {
            if ( name === 'user_name' && LoginState.action === 'new_password' ) {
                setLoginState({
                    ...LoginState, 
                    action: '',
                    logged_out_message: '',
                    message: '',
                    [name]: value,
                    password2: '',
                    no_password: false
                });
            } else {
                setLoginState({
                    ...LoginState, 
                    action: 'new_password',
                    logged_out_message: '',
                    message: '',
                    [name]: value,
                    no_password: true
                });
            }
        } else {
            setLoginState({
                ...LoginState, 
                action: '',
                logged_out_message: '',
                message: '',
                [name]: value
            });
        
        };
    }


    async function handleLogin() {
        const result = await login_check({ variables: { input: { ..._.omit(LoginState, ['logged_out_message', 'message', 'Messages', 'no_password', 'password2'] ), date_actual_in: dayjs().format('YYYY-MM-DD H:mm:ss'), time_actual_in: dayjs().format('H:mm:ss'), UTC: dayjs().format('Z'), today: dayjs().format('YYYY-MM-DD'), using_mobile: window.matchMedia("only screen and (max-width: 500px)").matches, mobile_login: dayjs().format('M-D H:mm') } } });
console.log({result})
        // Setup cookies and signin if valid user is returned
        if ( parseInt(result.data.login && result.data.login.user_ID) > 0 ) {

            let Login = result.data.login;
            console.log({Login})
            
            if (parseInt(Login.logged_in) === 1) {
                let newAuthState = { ...Login, date_scheduled: Login.date_scheduled, login_width: window.innerWidth, Messages: Login.Messages };
                signIn({
                    token: 345234523452345,
                    expiresIn: 3600*16,
                    tokenType: "Bearer",
                    authState: { User: newAuthState }
                })
                // console.log("New Auth state: ", newAuthState)

                // Redirect Operators to Control Panel
                // if( LoginState.user_name === 'inspection' ) {
                //     navigate("/inspection");
                // } else if( parseInt(newAuthState.user_type_ID) < 3 ) {
                //     navigate("/control_panel");
                // }

            } else if ( window.matchMedia("only screen and (min-width: 1025px)").matches ) {
                setLoginState({
                    ...LoginState,
                    action: 'attempted_mobile_login',
                    logged_out_message: ' - There was an issue logging in, please notify Barrett ASAP.'
                })
            }

            // Set messaging
            if ( Login.message === 'failed_clockin' ) {
            console.log("1")
                setLoginState({
                    ...LoginState,
                    action: 'failed_clockin',
                    message: ' - You are now logged in, but your Clock In was not recorded. Please notify Barrett or your supervisor ASAP.'
                })
            } else if ( Login.message === 'successful_login' ) {
            console.log("2")
                setLoginState({
                    ...LoginState,
                    action: 'successful_login',
                    message: 'You are now logged in'
                })
            } else if ( Login.message === 'successful_clockin' ) {
            console.log("3")
                setLoginState({
                    ...LoginState,
                    action: 'successful_clockin',
                    message: ` - You are now logged in and Clocked In`
                });
                //  at ${dayjs(`01-01-2010 ${Login.date_actual_in}`).format('h:mm A')}
            }

            
        }
        else {
            setLoginState({
                ...LoginState,
                logged_out_message: 'Invalid User Name and Password'
            })
        }

        // // One source
        //         // Create token
        //         const token = jwt.sign(
        //         { user_id: user._id, email },
        //         process.env.TOKEN_KEY,
        //         {
        //             expiresIn: "2h",
        //         }
        //         );
        //         // save user token
        //         user.token = token;

        //         // return new user
        //         res.status(201).json(user);

        // // Main youtube
        // const jwtToken = jwt.sign(
        //     { id: result.data.login.id, user_name: result.data.login.user_name },
        //     process.env.JWT_SECRET
        // );

        // res.json({ message: "Welcome Back!", token: jwtToken });


    }

    // function displayOnlyOnDesktop() {
    //     const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;

    //     if(isMobile){
    //         return <div>Sorry, this website is only available on desktop devices.</div>
    //     }
    //     return <div>Hooray, you're on a desktop device, so you can see this website!</div>
    // }
        
    // Prevent mobile users initially. TO DO: Figure out how to prevent this for just user_type_ID < 3
    // if(window.matchMedia("only screen and (min-width: 1025px)").matches) {
    //     return (
    //         <div>Mobile usage is not allowed. Please clock-in from a work station in the building. </div>
    //     )
    // }


console.log({LoginState})
    if(auth() === null) {
        // Login FORM
        return (
            <React.Fragment>
                <div id="root-bg" style={style}></div>
                <div id="top-container" className="flex-child column-parent">	{/* TOP CONTAINER INSIDE OF ROOT */}
                    <div id="content-container" className="flex-child row-parent" style={{marginTop: 106 + 'px'}}>
                        <div id="main-content-container" className="flex-child column-parent">
                            <div id="main-content" className="flex-child row-parent">	{/* MAIN CONTENT COLUMNS */}

                                <div className="container text-center mt-2">
                                    <div className="row justify-content-center">
                                        <div className="col-auto text-secondary border bg-light rounded py-5 px-5">
                                            <h4 className="mb-3">Panther</h4>
                                            <div className="form-floating mb-2">
                                                <input className="form-control float" id="user_name" value={LoginState.user_name} onChange={(event) => changeInputValue('user_name', event.target.value)} /> 
                                                <label className="text-muted" htmlFor="user_name">User Name</label>
                                            </div>
                                            <div className="form-floating mb-2">
                                                <input className="form-control float" type="password" id="password" value={LoginState.password} onChange={(event) => changeInputValue('password', event.target.value)} /> 
                                                <label className="text-muted" htmlFor="password">Password</label>
                                            </div>
                                            {
                                                LoginState.no_password &&
                                                <div className="form-floating mb-2">
                                                    <input className="form-control float" type="password" id="password2" value={LoginState.password2} onChange={(event) => changeInputValue('password2', event.target.value)} /> 
                                                    <label className="text-muted" htmlFor="password2">Confirm Password</label>
                                                </div>
                                            }
                                            <button className="btn w-100 fw-bold col-auto border-success border-3 text-success" style={{'backgroundColor': 'white'}} 
                                                disabled={( LoginState.no_password && LoginState.password !== LoginState.password2 ) } 
                                                // disabled={(LoginState.password === '' || LoginState.user_name === '') || ( LoginState.no_password === false || ( LoginState.no_password && LoginState.password !== LoginState.password2 ) )} 
                                                onClick={() => handleLogin()}>
                                                <FontAwesomeIcon icon="fas fa-solid fa-fw fa-clock" className="me-2" />                                                
                                                Clock In
                                            </button>
                                            {
                                                LoginState.logged_out_message !== "" && <div className="text-danger mt-3">{LoginState.logged_out_message}</div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        LoginState.no_password &&
                                        <>
                                            {
                                                (
                                                    LoginState.password !== LoginState.password2 &&
                                                    <div className="row justify-content-center mt-3">
                                                        <div className="col-auto">
                                                            <div className="alert alert-danger">Your password inputs do not match yet</div>
                                                        </div>
                                                    </div>
                                                ) || (
                                                    <div className="row justify-content-center mt-3">
                                                        <div className="col-auto">
                                                            <div className="alert alert-success">Your password inputs match</div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            <div className="row justify-content-center">
                                                <div className="col-auto">
                                                    <div className="alert alert-secondary">You do not have a password yet. Please enter your desired password twice to save your new password and login.</div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="row justify-content-center" style={{marginTop: 25000+'px'}}>
                                        <div className="w-auto">
                                            <NavLink to={`/inspection`} className="" exact="true">
                                                <button className="btn btn-lg btn-success">Inspection</button>
                                            </NavLink>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    } else {
        return (
            <>
                {
                    LoginState.message !== '' &&
                    <div className="row justify-content-center">
                        <div className={`alert ${(LoginState.action === 'failed_clockin' && 'alert-danger') || 'alert-success'} col-auto`}>
                            {LoginState.message}
                        </div>
                            {/* 
                                {parseInt(auth().User.user_type_ID) < 3 &&
                                    (<Navigate to="/control_panel" replace={true} />)
                                }
                            */}

                    </div>
                }
                {
                    auth().User.Messages && auth().User.Messages.length > 0 &&
                    auth().User.Messages.map((m, index) => {
                        return (
                            <div key={index} className="row justify-content-center">
                                <div className="card mb-3 px-0 w-50">
                                    <div className="card-header text-center text-secondary h3 bg-info border border-info">
                                        New Message
                                    </div>
                                    <div className="card-body text-center">
                                        {m.message}
                                    </div>
                                    <div className="card-footer text-center">
                                        From {m.first_name} {m.last_name}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    auth().User.user_type_ID < 3 &&
                    <div className="row justify-content-center">
                        <div className="w-auto">
                            <NavLink to={`/control_panel`} className="" exact="true">
                                <button className="btn btn-lg btn-success">Click Here To Go To Control Panel</button>
                            </NavLink>
                        </div>
                    </div>
                }
            </>
        )
    }
};

export const LoginPasswordComponent = compose(
	queryWithLoading({
		gqlString: GetUsersNoPassword,
        name: "componentData",
		options: {
			fetchPolicy: 'network-only'
		}
	})
)(LoginPassword)

