import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import {flowRight as compose} from 'lodash';
import { queryWithLoading } from '../utilities/NSDb.js';
import { useState } from 'react';
// import dayjs from 'dayjs';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
import { useMutation } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// GRAPHQL QUERY
import { 
    GetWorkSchedules,
    SaveWorkSchedule
} from './work_schedules_graphql.js';

function WorkSchedulesContent(props) {
    console.log({props})
    const [save_work_schedule] = useMutation(SaveWorkSchedule);

    const DefaultSelectedWorkSchedule = {
        work_schedule_ID: 0,
        active: 1,
        archiver_ID: 0,
        creator_ID: 0,
        date_archived: '',
        date_created: '',
        sunday1_shift_ID: 0,
        monday1_shift_ID: 0,
        tuesday1_shift_ID: 0,
        wednesday1_shift_ID: 0,
        thursday1_shift_ID: 0,
        friday1_shift_ID: 0,
        saturday1_shift_ID: 0,
        sunday2_shift_ID: 0,
        monday2_shift_ID: 0,
        tuesday2_shift_ID: 0,
        wednesday2_shift_ID: 0,
        thursday2_shift_ID: 0,
        friday2_shift_ID: 0,
        saturday2_shift_ID: 0,
        note: ''
    };

    const [SelectedWorkSchedule, setSelectedWorkSchedule] = useState({ ...DefaultSelectedWorkSchedule });
    const [BaseState, setBaseState] = useState({
        action: '',
        Shifts: props.componentData.shifts,
        WorkSchedules: props.componentData.work_schedules
    });

    // Archive / Unarchive the work_schedule
    // archiveWorkSchedule = (work_schedule_ID, archiver_ID=0) => {
    //     let new_archiver_ID = archiver_ID > 0 ? 0 : 1;
    //     saveWorkSchedule(work_schedule_ID, new_archiver_ID, 'archive');
    // }

    // Save work_schedule details
    async function saveWorkSchedule(work_schedule_ID) {

        let SaveData = {...SelectedWorkSchedule};

        // Parse string values that are IDs to be integers, saving stringified numbers to the database throws an error.
        let MakeIntegers = ['active', 'sunday1_shift_ID', 'monday1_shift_ID', 'tuesday1_shift_ID', 'wednesday1_shift_ID', 'thursday1_shift_ID', 'friday1_shift_ID', 'saturday1_shift_ID', 'sunday2_shift_ID', 'monday2_shift_ID', 'tuesday2_shift_ID', 'wednesday2_shift_ID', 'thursday2_shift_ID', 'friday2_shift_ID', 'saturday2_shift_ID', 'work_schedule_ID'];
        

        MakeIntegers.forEach((mi) => SaveData[mi] = parseInt(SaveData[mi]));

        // Saving each work_schedule part's hour, minute, AM/PM - needs to convert these into a single time variale

        // Remove save mesasge and button text from SelectedWorkSchedule object for saving
        SaveData = _.omit(SaveData, ['save_button_text','save_message','']);
        const result = await save_work_schedule({ variables: { input: { ...SaveData } }});
        console.log("Save Result: ",result.data)
        // Update the BaseState.WorkSchedules - save returns an array of all WorkSchedule objects
        if(result.data && result.data.workScheduleSave.WorkSchedules) {

            let TempWorkSchedules = [ ...BaseState.WorkSchedules ];
            let temp_action = '';

            // If this was a create, update the SelectedWorkSchedule object with the new work_schedule_ID
            if(parseInt(work_schedule_ID) === 0) {

                // Update the button and save message to show for created success
                setSelectedWorkSchedule({
                    ...SelectedWorkSchedule,
                    work_schedule_ID: parseInt(result.data.workScheduleSave.WorkSchedules[0].work_schedule_ID),
                    save_button_text: 'Saved',
                    save_message: 'Work Schedule successfully added'
                });

                // Push the new WorkSchedule object to the front of the WorkSchedules array
                TempWorkSchedules.unshift(result.data.workScheduleSave.WorkSchedules[0]);
                temp_action = 'added_work_schedule';

            } else {
            
                // For updates, replace the entire array of work_schedules
                TempWorkSchedules = result.data.workScheduleSave.WorkSchedules;
                temp_action = 'updated_work_schedule';

                // Update the SelectedWorkSchedule with the new work_schedule_ID that was created
                setSelectedWorkSchedule({
                    ...SelectedWorkSchedule,
                    save_button_text: 'Saved',
                    save_message: 'Work Schedule successfully updated'
                });

            }

            // For insert and update, set the action and new WorkSchedules
            setBaseState({
                ...BaseState,
                action: temp_action,
                WorkSchedules: TempWorkSchedules
            });
        }
    }

    // Handler function for form's changing inputs
    function changeInputValue(name, value) {
        setSelectedWorkSchedule({
            ...SelectedWorkSchedule, 
            [name]: value,
            save_button_text: 'Save', 
            save_message: ''
        });

    }

    // Handler function for all non-save buttons - Selected will always be the SelectedWorkSchedule object of whatever is being clicked. 'Add' is the defaultSelectedWorkSchedule object.
    function handleButtonClick(action, Selected) {
        let TempSelected = action === 'close_work_schedule' ? { ...Selected } : { ...Selected, save_button_text: 'Save', save_message: ''};
        // console.log("Temp: ", TempSelected.break_end_1)
        // console.log("moment hour: ", moment(TempSelected.break_end_1, 'hh:mm:ss').format('A'));
        // console.log("moment min: ", moment(TempSelected.break_end_1, 'hh:mm:ss').format('mm'));

        setSelectedWorkSchedule({
            ...TempSelected
        });

        setBaseState({
            ...BaseState,
            action: action
        })
    }

    // Select input for days of the week
    function ShiftSelection(day, number) {
        return (
            <>
                <select 
                    className="form-select" 
                    disabled={false}
                    id={`${day}${number}_shift_ID`}
                    onChange={(event) => changeInputValue(`${day}${number}_shift_ID`, event.target.value)}
                    placeholder="WorkSchedule Group"
                    value={SelectedWorkSchedule[`${day}${number}_shift_ID`]}
                >
                    <option value={0}>Select Shift</option>
                    {BaseState.Shifts.map((s) => {
                        return (
                            <option value={parseInt(s.shift_ID)}>{moment(`'01-01-2000' ${s.shift_start}`).format('h:mma')} - {moment(`'01-01-2000' ${s.shift_end}`).format('h:mma')} : {s.shift_name}</option>
                        )
                    })}
                </select> 
                <label className="text-muted" htmlFor={`${day}${number}_shift_ID`}>{(parseInt(number) === 1 && '1st') || '2nd'} {day}</label>         
            </>
        )
    }

    // Given a shift_ID, output the details for the table view
    function GetShiftDetails(shift_ID, number) {

        let Shift = BaseState.Shifts.filter((s) => parseInt(s.shift_ID) === parseInt(shift_ID));

        // Only show the shift details if there is a shift available
        if ( Shift.length === 1 ) {
            let start = moment(`01-01-2000 ${Shift[0].shift_start}`).format('h:mma');
            start = start.substring(0,start.length-1);
            let end = moment(`01-01-2000 ${Shift[0].shift_end}`).format('h:mma');
            end = end.substring(0,end.length-1);
            return (
                <div className="">
                    <span className="text-muted">{number}:</span> {start} - {end}
                </div>
            )
        } else {
            return <div className="text-white">No Shift</div>;
        }
    }

    console.log({SelectedWorkSchedule})
    console.log(BaseState)
    return (
        <>
            <div>
                <div className="row clearfix mb-3">
                    {/* RESULTS COUNTER AND FILTERS */}
                    <div className="col h4 mb-0 pt-3">
                        {BaseState.WorkSchedules.length} WorkSchedules
                    </div>

                    <div className="col float-end">
                        {
                            BaseState.action === 'add_work_schedule' &&
                            <div className="">
                                <span className="col-auto float-end ms-2">
                                    <button 
                                        className="col-auto btn border-primary border-3 text-primary fw-bold disabled" 
                                        disabled={true}
                                    >
                                    Enter New WorkSchedule Info Below
                                    </button>
                                </span>
                            </div>
                        }
                        {
                            ((SelectedWorkSchedule.work_schedule_ID === 0 && BaseState.action !== 'add_work_schedule') || SelectedWorkSchedule.work_schedule_ID > 0) &&
                            <div className="">
                                <button 
                                    className="btn fw-bold col-auto border-primary border-3 float-end text-primary" 
                                    onClick={() => handleButtonClick('add_work_schedule', DefaultSelectedWorkSchedule)}
                                >
                                    <FontAwesomeIcon icon="fa-plus" className="me-2" />Add WorkSchedule
                                </button>
                                {
                                    BaseState.action === 'added_work_schedule' &&
                                    <span className="col-auto float-end me-2">
                                        <div className="alert alert-success p-2">WorkSchedule added successfully</div>
                                    </span>
                                }
                            </div>
                        }
                    </div>
                </div>
                {
                    <table className="table">
                        <thead className="row h6 border border-2 border-top-0 border-start-0 border-end-0 border-dark">
                            <tr className="col-1">Edit</tr>
                            <tr className="col-1">Work Schedule</tr>
                            <tr className="col-1">Monday</tr>
                            <tr className="col-1">Tuesday</tr>
                            <tr className="col-1">Wednesday</tr>
                            <tr className="col-1">Thursday</tr>
                            <tr className="col-1">Friday</tr>
                            <tr className="col-1">Saturday</tr>
                            <tr className="col-1">Sunday</tr>
                            <tr className="col-3">Note</tr>
                        </thead>
                        {
                            (BaseState.WorkSchedules.length > 0 || BaseState.action === 'add_work_schedule') &&
                            <tbody className="">

                                {/* Add new work_schedule form */}
                                {BaseState.action === 'add_work_schedule' &&
                                    <tr className="row" key="0">
                                        <td className="border-0 col-1">
                                        </td>
                                        <td className="border-0 col-1">
                                            <div className="form-floating">
                                                <input className="form-control float" id="description" value={SelectedWorkSchedule.description} onChange={(event) => changeInputValue('description', event.target.value)} /> 
                                                <label className="text-muted" htmlFor="description">Description</label>
                                            </div>
                                            <div className="form-floating mt-2">
                                                <select 
                                                    className="form-select" 
                                                    disabled={false}
                                                    id="active"
                                                    onChange={(event) => changeInputValue('active', event.target.value)}
                                                    placeholder="Active"
                                                    value={SelectedWorkSchedule.active}
                                                >
                                                    <option value={1}>WS Active</option>
                                                    <option value={0}>WS Inactive</option>
                                                </select> 
                                                <label className="text-muted" htmlFor="active">Active</label>                                                   
                                            </div>
                                        </td>
                                        <td className="border-0 pt-1 col-1">
                                            <div className="form-floating float-start">
                                                {ShiftSelection('monday', 1)}                                                  
                                            </div>
                                            <div className="form-floating float-start mt-2">
                                                {ShiftSelection('monday', 2)}                                                  
                                            </div>
                                        </td>
                                        <td className="border-0 pt-1 col-1">
                                            <div className="form-floating float-start">
                                                {ShiftSelection('tuesday', 1)}                                                  
                                            </div>
                                            <div className="form-floating float-start mt-2">
                                                {ShiftSelection('tuesday', 2)}                                                  
                                            </div>
                                        </td>
                                        <td className="border-0 pt-1 col-1">
                                            <div className="form-floating float-start">
                                                {ShiftSelection('wednesday', 1)}                                                  
                                            </div>
                                            <div className="form-floating float-start mt-2">
                                                {ShiftSelection('wednesday', 2)}                                                  
                                            </div>
                                        </td>
                                        <td className="border-0 pt-1 col-1">
                                            <div className="form-floating float-start">
                                                {ShiftSelection('thursday', 1)}                                                  
                                            </div>
                                            <div className="form-floating float-start mt-2">
                                                {ShiftSelection('thursday', 2)}                                                  
                                            </div>
                                        </td>
                                        <td className="border-0 pt-1 col-1">
                                            <div className="form-floating float-start">
                                                {ShiftSelection('friday', 1)}                                                  
                                            </div>
                                            <div className="form-floating float-start mt-2">
                                                {ShiftSelection('friday', 2)}                                                  
                                            </div>
                                        </td>
                                        <td className="border-0 pt-1 col-1">
                                            <div className="form-floating float-start">
                                                {ShiftSelection('saturday', 1)}                                                  
                                            </div>
                                            <div className="form-floating float-start mt-2">
                                                {ShiftSelection('saturday', 2)}                                                  
                                            </div>
                                        </td>
                                        <td className="border-0 pt-1 col-1">
                                            <div className="form-floating float-start">
                                                {ShiftSelection('sunday', 1)}                                                  
                                            </div>
                                            <div className="form-floating float-start mt-2">
                                                {ShiftSelection('sunday', 2)}                                                  
                                            </div>
                                        </td>
 
                                        <td className="border-0 pt-1 col-3">
                                            <div className="form-floating h-100">
                                                <input className="form-control h-100" id="note" value={SelectedWorkSchedule.note} onChange={(event) => changeInputValue('note', event.target.value)} /> 
                                                <label className="text-muted" htmlFor="note">Note</label>
                                            </div>
                                        </td>

                                        {/* Buttons Row */}
                                        <td className="border-0 col-1" />
                                        <td className="border-0 col-11 pt-0">
                                            <div className="row">
                                                <span className="col-auto float-start">
                                                    <button className="btn fw-bold col-auto border-success border-3 text-success" disabled={SelectedWorkSchedule.work_schedule_name === ''} onClick={() => saveWorkSchedule(0)}>{SelectedWorkSchedule.save_button_text} {SelectedWorkSchedule.save_button_text === 'Saved' && <FontAwesomeIcon icon="fa-check" className="ms-1" />}</button>
                                                </span>
                                                {
                                                    SelectedWorkSchedule.save_message !== '' &&
                                                    <span className="col-auto float-start">
                                                        <div className="alert alert-success p-2 mb-0">{SelectedWorkSchedule.save_message}</div>
                                                    </span>
                                                }
                                                <span className="col float-end">
                                                    <button className="btn fw-bold col-auto border-secondary border-3 text-secondary float-end" onClick={() => handleButtonClick('', DefaultSelectedWorkSchedule)}>{(parseInt(SelectedWorkSchedule.work_schedule_ID) > 0 && 'Close') || 'Cancel'}</button>
                                                </span>
                                            </div>
                                        </td>

                                    </tr>
                                }
                                {BaseState.WorkSchedules.map((m) => {
                                    // let archive_button_class = m.archiver_ID > 0 ? 'btn-success' : 'btn-danger';
                                    // let archive_button_text = m.archiver_ID > 0 ? 'Unarchive' : 'Archive';
                                    if(parseInt(m.work_schedule_ID) === parseInt(SelectedWorkSchedule.work_schedule_ID)) {
                                        // Disable the save button until an input has changed.
                                        // let {part_number, hourly_standard_rate, sale_price, standard_tool_cost, scrap_percentage_cost_sheet} = SelectedWorkSchedule;
                                        // let inputs_not_changed = part_number === m.part_number && hourly_standard_rate === m.hourly_standard_rate && sale_price === m.sale_price && standard_tool_cost === m.standard_tool_cost && scrap_percentage_cost_sheet === m.scrap_percentage_cost_sheet;
                                        // let disable_save_button = inputs_not_changed || SelectedWorkSchedule.save_button_text === 'Saving' || SelectedWorkSchedule.save_button_text === 'Saved';
                                        // let disable_save_button = SelectedWorkSchedule.save_button_text === 'Saving' || SelectedWorkSchedule.save_button_text === 'Saved';
                                        // If adding a new work_schedule and the part_number is blank, do not allow saving
                                        // if(save_button_tip === '' && SelectedWorkSchedule.part_number === '') {
                                        //     disable_save_button = true;
                                        //     save_button_tip = 'You must enter a work_schedule number'
                                        // }
                                        return (
                                            <tr className="row" key="0">
                                                <td className="border-0 col-1">
                                                </td>
                                                <td className="border-0 col-1">
                                                    <div className="form-floating">
                                                        <input className="form-control float" id="description" value={SelectedWorkSchedule.description} onChange={(event) => changeInputValue('description', event.target.value)} /> 
                                                        <label className="text-muted" htmlFor="description">Description</label>
                                                    </div>
                                                    <div className="form-floating mt-2">
                                                        <select 
                                                            className="form-select" 
                                                            disabled={false}
                                                            id="active"
                                                            onChange={(event) => changeInputValue('active', event.target.value)}
                                                            placeholder="Active"
                                                            value={SelectedWorkSchedule.active}
                                                        >
                                                            <option value={1}>WS Active</option>
                                                            <option value={0}>WS Inactive</option>
                                                        </select> 
                                                        <label className="text-muted" htmlFor="active">Active</label>                                                   
                                                    </div>
                                                </td>
                                                <td className="border-0 pt-1 col-1">
                                                    <div className="form-floating float-start">
                                                        {ShiftSelection('monday', 1)}                                                  
                                                    </div>
                                                    <div className="form-floating float-start mt-2">
                                                        {ShiftSelection('monday', 2)}                                                  
                                                    </div>
                                                </td>
                                                <td className="border-0 pt-1 col-1">
                                                    <div className="form-floating float-start">
                                                        {ShiftSelection('tuesday', 1)}                                                  
                                                    </div>
                                                    <div className="form-floating float-start mt-2">
                                                        {ShiftSelection('tuesday', 2)}                                                  
                                                    </div>
                                                </td>
                                                <td className="border-0 pt-1 col-1">
                                                    <div className="form-floating float-start">
                                                        {ShiftSelection('wednesday', 1)}                                                  
                                                    </div>
                                                    <div className="form-floating float-start mt-2">
                                                        {ShiftSelection('wednesday', 2)}                                                  
                                                    </div>
                                                </td>
                                                <td className="border-0 pt-1 col-1">
                                                    <div className="form-floating float-start">
                                                        {ShiftSelection('thursday', 1)}                                                  
                                                    </div>
                                                    <div className="form-floating float-start mt-2">
                                                        {ShiftSelection('thursday', 2)}                                                  
                                                    </div>
                                                </td>
                                                <td className="border-0 pt-1 col-1">
                                                    <div className="form-floating float-start">
                                                        {ShiftSelection('friday', 1)}                                                  
                                                    </div>
                                                    <div className="form-floating float-start mt-2">
                                                        {ShiftSelection('friday', 2)}                                                  
                                                    </div>
                                                </td>
                                                <td className="border-0 pt-1 col-1">
                                                    <div className="form-floating float-start">
                                                        {ShiftSelection('saturday', 1)}                                                  
                                                    </div>
                                                    <div className="form-floating float-start mt-2">
                                                        {ShiftSelection('saturday', 2)}                                                  
                                                    </div>
                                                </td>
                                                <td className="border-0 pt-1 col-1">
                                                    <div className="form-floating float-start">
                                                        {ShiftSelection('sunday', 1)}                                                  
                                                    </div>
                                                    <div className="form-floating float-start mt-2">
                                                        {ShiftSelection('sunday', 2)}                                                  
                                                    </div>
                                                </td>
        
                                                <td className="border-0 pt-1 col-3">
                                                    <div className="form-floating h-100">
                                                        <input className="form-control h-100" id="note" value={SelectedWorkSchedule.note} onChange={(event) => changeInputValue('note', event.target.value)} /> 
                                                        <label className="text-muted" htmlFor="note">Note</label>
                                                    </div>
                                                </td>
                                                
                                                {/* Buttons Row */}
                                                <td className="border-0 col-1" />
                                                <td className="border-0 col-11">
                                                    <div className="row">
                                                        <span className="col-auto float-start">
                                                            <button className="btn fw-bold col-auto border-success border-3 text-success" disabled={SelectedWorkSchedule.work_schedule_name === ''} onClick={() => saveWorkSchedule(parseInt(SelectedWorkSchedule.work_schedule_ID))}>{SelectedWorkSchedule.save_button_text} {SelectedWorkSchedule.save_button_text === 'Saved' && <FontAwesomeIcon icon="fa-check" className="ms-1" />}</button>
                                                        </span>
                                                        {
                                                            SelectedWorkSchedule.save_message !== '' &&
                                                            <span className="col-auto float-start">
                                                                <div className="alert alert-success p-2 mb-0">{SelectedWorkSchedule.save_message}</div>
                                                            </span>
                                                        }
                                                        <span className="col float-end">
                                                            <button className="btn fw-bold col-auto border-secondary border-3 text-secondary float-end" onClick={() => handleButtonClick('', DefaultSelectedWorkSchedule)}>{(parseInt(SelectedWorkSchedule.work_schedule_ID) > 0 && 'Close') || 'Cancel'}</button>
                                                        </span>
                                                    </div>
                                                </td>

                                            </tr>
                                        )

                                    } else {
                                        // let archive_button_class = parseInt(m.archiver_ID) > 0 ? 'border-success text-success' : 'border-danger text-danger';
                                        // let archive_button_icon = parseInt(m.archiver_ID) > 0 ? 'fa-rotate-left' : 'fa-trash-can';

                                        return (
                                            <tr className="row" key={m.work_schedule_ID}>
                                                <td className="col-1 d-flex align-items-center">
                                                    <button className="btn btn-sm border-primary text-primary" onClick={() => handleButtonClick('edit_work_schedule', m)}><FontAwesomeIcon icon="fa-pencil" className="" /></button>
                                                    { parseInt(m.active) === 0 && <span className="ms-4 badge rounded-pill border border-danger text-danger">Inactive</span> }
                                                    { parseInt(m.active) === 1 && <span className="ms-4 badge rounded-pill border border-success text-success">Active</span> }
                                                </td>
                                                <td className={`col-1 ${m.archiver_ID > 0 && 'text-muted'}`}>
                                                        {m.description}
                                                </td>
                                                <td className={`col-1 ${m.archiver_ID > 0 && 'text-muted'}`}>
                                                    {GetShiftDetails(parseInt(m.monday1_shift_ID), 1)}
                                                    {GetShiftDetails(parseInt(m.monday2_shift_ID), 2)}
                                                </td>
                                                <td className={`col-1 ${m.archiver_ID > 0 && 'text-muted'}`}>
                                                    {GetShiftDetails(parseInt(m.tuesday1_shift_ID), 1)}
                                                    {GetShiftDetails(parseInt(m.tuesday2_shift_ID), 2)}
                                                </td>
                                                <td className={`col-1 ${m.archiver_ID > 0 && 'text-muted'}`}>
                                                    {GetShiftDetails(parseInt(m.wednesday1_shift_ID), 1)}
                                                    {GetShiftDetails(parseInt(m.wednesday2_shift_ID), 2)}
                                                </td>
                                                <td className={`col-1 ${m.archiver_ID > 0 && 'text-muted'}`}>
                                                    {GetShiftDetails(parseInt(m.thursday1_shift_ID), 1)}
                                                    {GetShiftDetails(parseInt(m.thursday2_shift_ID), 2)}
                                                </td>
                                                <td className={`col-1 ${m.archiver_ID > 0 && 'text-muted'}`}>
                                                    {GetShiftDetails(parseInt(m.friday1_shift_ID), 1)}
                                                    {GetShiftDetails(parseInt(m.friday2_shift_ID), 2)}
                                                </td>
                                                <td className={`col-1 ${m.archiver_ID > 0 && 'text-muted'}`}>
                                                    {GetShiftDetails(parseInt(m.saturday1_shift_ID), 1)}
                                                    {GetShiftDetails(parseInt(m.saturday2_shift_ID), 2)}
                                                </td>
                                                <td className={`col-1 ${m.archiver_ID > 0 && 'text-muted'}`}>
                                                    {GetShiftDetails(parseInt(m.sunday1_shift_ID), 1)}
                                                    {GetShiftDetails(parseInt(m.sunday2_shift_ID), 2)}
                                                </td>
                                                <td className="col-3">                                                    
                                                    {m.note}
                                                </td>
                                            </tr>
                                        )
                                    }
                                })}
                            </tbody>
                        }
                    </table>
                }
            </div>
        </>
    )
}

export const WorkSchedulesComponent = compose(
	queryWithLoading({
		gqlString: GetWorkSchedules,
        name: "componentData"
	})
)(WorkSchedulesContent)

