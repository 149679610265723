import React, { useState } from 'react';
// import React, { useEffect, useState } from 'react';
// import _ from 'lodash';
import {flowRight as compose} from 'lodash';
import { queryWithLoading } from '../utilities/NSDb.js';
import { useAuthUser } from 'react-auth-kit';
import dayjs from 'dayjs';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
import { useMutation, useLazyQuery } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// GRAPHQL QUERY GetMachineStatuesChanges
import { GetChecksheetProcesses, SaveChecksheetRecord } from './checksheets_graphql.js';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
// import { useQuery, useMutation } from '@apollo/client';

export function ChecksheetComponent(props) {
    console.log({props})
    const auth = useAuthUser();
console.log("ComponentData: ", props.componentData)
    const { Machine } = props;
    // const [get_machine_operators, { data: data_machine_operators }] = useLazyQuery(GetMachineOperators, { fetchPolicy: 'network-only'});
    
    // console.log(data_machine_operators);
    // console.log(data_operators);

    const [save_checksheet_record] = useMutation(SaveChecksheetRecord);
    
    const [SelectedChecksheet, setSelectedChecksheet] = useState({ ...props.SelectedChecksheet });
    
    const [BaseState, setBaseState] = useState({
        message: '',
        message_class: ''
    });



    // Update Checksheet input state values
    function changeInputValue(value, checksheet_process_ID, input_type) {
        console.log("Value: ", value, " | ID: ", checksheet_process_ID);

        let TempProcess = SelectedChecksheet.ChecksheetProcesses.filter((c) => parseInt(c.checksheet_process_ID) === parseInt(checksheet_process_ID))[0];

        // For inputs that must be numeric, do not allow non numeric characters, and parseInt on the value
        if ( input_type === 'input' ) {
           if(value !== '') {
                // Strips non-numeric characters, leaves decimal
                value = value.replace(/[^\d.-]/g, '');
                // Handle decimal length restrictions
                if (value.includes('.')) {
                    let decimal_length = value.split('.')[1].length;
                    if( decimal_length > 5 ) {
                        value = parseFloat(value).toFixed(5)
                    }
                };
            }
        }

        TempProcess = { ...TempProcess, value: value };

        let TempSelectedChecksheetProcesses = [ ...SelectedChecksheet.ChecksheetProcesses ];

        // Update the single ChecksheetProcess in the SelectedChecksheet.ChecksheetProcesses
        TempSelectedChecksheetProcesses = TempSelectedChecksheetProcesses.map((tscp) => {
            if ( parseInt(tscp.checksheet_process_ID) === parseInt(checksheet_process_ID) ) {
                return TempProcess;
            } else {
                return tscp;
            }
        });

        setSelectedChecksheet({
            ...SelectedChecksheet,
            ChecksheetProcesses: [ ...TempSelectedChecksheetProcesses ]
        });

        setBaseState({
            message: '',
            message_class: ''
        });
         
    }

    async function handleSaveSelectedChecksheet() {

        // Check that all inputs are completed
        let IncompleteProcesses = SelectedChecksheet.ChecksheetProcesses.filter((csp) => csp.value === '' || csp.value === null);
        console.log({IncompleteProcesses});

        if(IncompleteProcesses.length > 0) {
            setBaseState({
                message: `Please complete all Checksheet inputs`,
                message_class: 'alert alert-danger'
            });
        } else {
        
            // Build out the SaveData input
            let SaveData = {
                checksheet_record_ID: 0,
                ChecksheetProcessRecords: [],
                checksheet_ID: parseInt(SelectedChecksheet.checksheet_ID),
                creator_ID: parseInt(auth().User.user_ID),
                job_ID: 0,
                machine_ID: parseInt(Machine.machine_ID),
                part_ID: parseInt(SelectedChecksheet.part_ID),
                passed: 1,
                updater_ID: 0
            }

            SaveData.ChecksheetProcessRecords = SelectedChecksheet.ChecksheetProcesses.map((csp) => {
                return {
                    checksheet_process_record_ID: 0,
                    checksheet_ID: parseInt(SelectedChecksheet.checksheet_ID),
                    checksheet_process_ID: parseInt(csp.checksheet_process_ID),
                    checksheet_record_ID: 0,
                    creator_ID: parseInt(auth().User.user_ID),
                    failed_approver_ID: 0,
                    passed: 1,
                    passed_first_attempt: 1,
                    updater_ID: 0,
                    value: csp.value
                }
            });

            console.log({SaveData});

            // Save new checksheet record and processes record
            const save_result = await save_checksheet_record({ variables: { input: { ...SaveData, date_created: dayjs().format('YYYY-MM-DD HH:mm:ss') } } });
            console.log({save_result})

            // If checksheet saved, return to ControlPanel and send back saved data
            if ( parseInt(save_result.data && save_result.data.checksheetRecordSave.ChecksheetRecord.checksheet_record_ID) > 0) {

                props.handleChecksheetRecordSave(save_result.data.checksheetRecordSave.ChecksheetRecord);

            }
        }
                // THIS IS THE RESPONSE BACK FROM THE SAVE
                // type ChecksheetRecord {
                //     checksheet_record_ID: ID
                //     creator_ID: Int
                //     date_created: DateTime
                //     date_updated: DateTime
                //     job_ID: Int
                //     machine_ID: Int
                //     part_ID: Int
                //     passed: String
                //     updater_ID: Int
                // }

                // THESE 2 ARE THE INPUT TYPES FOR SENDING IN SAVEDATA
                // input ChecksheetProcessInput {
                //     checksheet_process_record_ID: ID
                //     checksheet_ID: Int
                //     checksheet_process_ID: ID
                //     checksheet_record_ID: Int
                //     creator_ID: Int
                //     date_created: DateTime
                //     date_updated: DateTime
                //     failed_approver_ID: Int
                //     passed: Int
                //     passed_first_attempt: Int
                //     updater_ID: Int
                //     value: String
                // }

                // input ChecksheetRecordInput {
                //     checksheet_record_ID: ID
                //     ChecksheetProcessRecords: [ChecksheetProcessInput]
                //     checksheet_ID: Int
                //     creator_ID: Int
                //     date_created: DateTime
                //     date_updated: DateTime
                //     job_ID: Int
                //     machine_ID: Int
                //     part_ID: Int
                //     passed: String
                //     updater_ID: Int
                // }
        
    }

    // console.log("BaseState: ", BaseState);
    console.log({SelectedChecksheet})
    console.log(auth())

    return (
        <div className="">
            <div className="row border-bottom mb-2">
                <div className="col-2">Part</div>                
                <div className="col-2">Time</div>                
                <div className="col-2">Operation</div>                
                <div className="col-2">Inspection Mode</div>                
                <div className="col-2">Inspector</div>                
                <div className="col-2">Workcenter / Job</div>                
            </div>
            <div className="row fw-bolder mb-4">
                <div className="col-2">{SelectedChecksheet.part_number}</div>                
                <div className="col-2">{dayjs().format('M-D-YYYY h:mm A')}</div>                
                <div className="col-2">{SelectedChecksheet.operation_name}</div>                
                <div className="col-2">{SelectedChecksheet.inspection_step}</div>                
                <div className="col-2">{auth().User.first_name} {auth().User.last_name}</div>                
                <div className="col-2">{Machine.machine_name} {/* Job goes here too */}</div>                
            </div>
            {
                SelectedChecksheet.ChecksheetProcesses.length === 0 &&
                <div className="alert alert-danger">Something went wrong, tell Barrett, and close form. Refresh page and try to load again.</div>
            }
            {
                SelectedChecksheet.ChecksheetProcesses.length > 0 &&
                <>
                    <table className="table table-striped">
                        <thead className="">
                            <tr className="row">
                                <th className="col-1">Op Num</th>
                                <th className="col-2">Specification</th>
                                <th className="col-2">Target</th>
                                <th className="col-2">Measurements</th>
                                <th className="col-2">Gage</th>
                                <th className="col-3">Sample</th>
                            </tr>
                        </thead>
                        <tbody>
                            {SelectedChecksheet.ChecksheetProcesses.map((c) => {
                                return (
                                    <tr key={c.checksheet_process_ID} className="row">
                                        <td className="col-1">{c.char_number}</td>
                                        {
                                            c.specifications !== '' && c.tolerance_target !== ''
                                        }
                                        <td className="col-2">{c.char_product}</td>
                                        <td className="col-2">{c.specifications}{c.tolerance_target}</td>
                                        <td className="col-2 text-center">
                                            {
                                                c.input_type === 'pass_fail' &&
                                                <div className="btn-group btn-group-sm" role="group">
                                                    <button className={`btn ${(c.value === 'Pass' && 'btn-success text-white') || 'text-dark btn-outline-secondary '}`} onClick={() => changeInputValue('Pass', parseInt(c.checksheet_process_ID), c.input_type)}>
                                                        Pass
                                                    </button>
                                                    <button className={`btn ${(c.value === 'Fail' && 'btn-danger text-white') || 'text-dark btn-outline-secondary '}`} onClick={() => changeInputValue('Fail', parseInt(c.checksheet_process_ID), c.input_type)}>
                                                        Fail
                                                    </button>
                                                </div>                            
                                            }
                                            {
                                                c.input_type === 'input' &&
                                                <div className="input-group" >
                                                    {/* style={{height: 38+'px'}} */}
                                                    { c.tolerance_min !== '' && <span className="input-group-text px-1" style={{fontSize: 15+'px'}}>{parseFloat(c.tolerance_min)}</span> }
                                                    <input className="form-control text-center fw-bolder" style={{fontSize: 18+'px'}} value={c.value || ''} onChange={(event) => changeInputValue(event.target.value, parseInt(c.checksheet_process_ID), c.input_type)} />
                                                    { c.tolerance_max !== '' && <span className="input-group-text px-1" style={{fontSize: 15+'px'}}>{parseFloat(c.tolerance_max)}</span> }
                                                </div>
                                            }
                                        </td>
                                        <td className="col-2">{c.evaluation_measurement_technique}</td>
                                        <td className="col-3">{c.sample_size_frequency}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="w-100 text-center">
                        <button className="btn fw-bold border-3 border-success text-success" onClick={() => handleSaveSelectedChecksheet()}>Save {Machine.machine_short_name} {SelectedChecksheet.inspection_step} Checksheet</button>
                    </div>
                    {
                        BaseState.message !== '' &&
                        <div className="row m-0 mt-3 justify-content-center">
                            <div className={`${BaseState.message_class} m-0 col-auto`}>{BaseState.message}</div>
                        </div>
                    }
                </>
            }
        </div>
    )
}

