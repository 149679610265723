import React from 'react';
import {flowRight as compose} from 'lodash';
import { withMutation, queryWithLoading } from '../utilities/NSDb.js';
// import { graphql } from '@apollo/react-hoc';
import { useState } from 'react';
import _ from "lodash";

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
import { useMutation } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// GRAPHQL QUERY
import { 
    GetTooling,
    GetToolingParts,
    SaveTooling,
    SaveToolingPart
} from './tooling_graphql.js';

import { 
    GetToolingTypes,
    //SaveToolingType
} from '../tooling_types/tooling_types_graphql.js';

import { 
    GetSuppliers
} from '../suppliers/suppliers_graphql.js';

import { 
    GetParts
} from '../parts/parts_graphql.js';

import { 
    GetManufacturers
} from '../manufacturers/manufacturers_graphql.js';


function ToolingContent(props) {
    // Alphabetically sort the Tooling
    //let TempTooling = sortToolingNames(props.Tooling.tooling);

    // Set the first tool_type_ID to be the active filter
    // let TempToolingTypes = [...props.ToolingTypes.tooling_types];
    // TempToolingTypes.unshift({ tooling_type_ID: 0, tooling_type: 'All Types' });
    // let temp_filter_tooling_type_ID = parseInt(TempToolingTypes[0].tooling_type_ID);

    // const [save_tooling, { data, loading, error, reset }] = useMutation(SaveTooling, {
    //     refetchQueries: [GetTooling]
    // });
    const [save_tooling] = useMutation(SaveTooling);

    // Create an All Types tool_type_ID to be the default active filter
    let TempToolingTypes = [...props.ToolingTypes.tooling_types];
    TempToolingTypes.unshift({ tooling_type_ID: 0, tooling_type: 'All Types' });
    let temp_filter_tooling_type_ID = parseInt(TempToolingTypes[0].tooling_type_ID);

    // Take ToolingParts and put them into the Tooling array object
    let TempTooling = [...props.Tooling.tooling];
    TempTooling = TempTooling.map((t) => {
        let ToolingParts = props.ToolingParts.tooling_parts.filter((tp) => parseInt(tp.tooling_ID) === parseInt(t.tooling_ID));
        return { ...t, ToolingParts: ToolingParts}
    })

    // Create the default SelectedTooling object for setting after form is closed
    const DefaultSelectedTooling = {
        active: 1,
        archiver_ID: 0,
        creator_ID: 0,
        cutting_edges: 0,
        date_archived: '',
        date_created: '', 
        manufacturer_ID: 0,
        manufacturer_name: '',
        manufacturer_part_number: '',
        notes: '',
        supplier_ID: 0,
        supplier_name: '',
        supplier_part_number: '',
        tooling_ID: 0,
        tooling_cost: '',
        tooling_description: '',
        tooling_min: 0,
        ToolingParts: [],
        tooling_type_ID: 0
    };

    const [SelectedTooling, setSelectedTooling] = useState({ ...DefaultSelectedTooling });

    const [BaseState, setBaseState] = useState(
        {
            action: '',
            filter: '',
            filter_search: '',
            filter_search_message: '',
            filter_text_searched: '',
            filter_tooling_type_ID: temp_filter_tooling_type_ID,
            FilteredTooling: TempTooling,
            Tooling: TempTooling,
            ToolingTypes: TempToolingTypes,
            save_button_text: 'Save',
            save_message: ''
        }
    );

    // Alphabetically sort the Tooling
    function sortToolingNames(Tooling) {
        let TempTooling = [...Tooling];
        if(TempTooling.length > 0) {
            return TempTooling.sort(function(a,b) {
                if(a.tooling_description.toLowerCase() < b.tooling_description.toLowerCase()) return -1;
                if(a.tooling_description.toLowerCase() > b.tooling_description.toLowerCase()) return 1;
                return 0;
            });
        } else {
            return [];
        }
    }

    // Update the state for any changing input
    function changeInputValue(name, value) {

        // For inputs that must be integers, do not allow non numeric characters, and parseInt on the value
        if ( name === 'cutting_edges' || name === 'tooling_min' ) {
            if(value !== '') {
                value = parseInt(value)
            } else {
                // This is to avoid attempting to save "" as an INT
                value = null;
            }
        } else if ( name === 'tooling_cost' ) {
            if(value !== '') {
                // Strips non-numeric characters, leaves decimal
                value = value.replace(/[^\d.-]/g, '');
                // Handle decimal length restrictions
                if (value.includes('.')) {
                    let decimal_length = value.split('.')[1].length;
                    value = decimal_length > 1 ? parseFloat(value).toFixed(2) : value;
                };
            }
        } 

        // Reset the Save button to show that the form can be saved.
        setBaseState({
            ...BaseState,
            save_button_text: 'Save', 
            save_message: ''
        })
        setSelectedTooling({
            ...SelectedTooling,
                [name]: value 
        })

    }

    // Archive / Unarchive the tooling
    function archiveTooling(tooling_ID, archiver_ID=0) {
        let new_archiver_ID = archiver_ID > 0 ? 0 : 1;
        saveTooling(tooling_ID, new_archiver_ID, 'archive');
    }

    // Save button clicked in Tooling form, if tooling_ID > 0 this is an edit/update, if tooling_ID=0 this is adding a tooling record
    async function saveTooling(tooling_ID, archiver_ID=0, action='') {

        let result;

        if(action !== 'archive') {

            setBaseState({
                ...BaseState, 
                save_button_text: 'Saving'
            });

            // Add new input key for ToolingParts to be a list of part_IDs for ease of saving to the tooling_parts database
            let SaveData = { ...SelectedTooling, tooling_part_IDs: SelectedTooling.ToolingParts.map((tp) => tp.part_ID).join() };

            // Parse string values that are IDs to be integers, saving stringified numbers to the database throws an error.
            let MakeIntegers = ['manufacturer_ID', 'supplier_ID', 'tooling_type_ID'];
            MakeIntegers.forEach((mi) => SaveData[mi] = parseInt(SaveData[mi]));

            // Save this tooling's form details and await the resulting tooling object to update overall state with
            result = await save_tooling({ variables: { input: _.omit(SaveData, ['manufacturer_active','manufacturer_name','supplier_active','supplier_name','ToolingParts']) } })

        } else {
        
            result = await save_tooling({ variables: { input: { tooling_ID, archiver_ID, active: archiver_ID > 0 ? 0 : 1, action } } })
        }

        // Save returns a single tooling object, either an updated one if tooling_ID > 0 or a new object if this was adding a tooling record
        if(result.data.toolingSave.UpdatedTooling && result.data.toolingSave.UpdatedTooling.tooling_ID > 0) {

            // Update the Tooling array with the new saved information
            let TempTooling = [...BaseState.Tooling];
            let temp_save_message = '';

            // If this was an update, update the Tooling object for the updated tooling_ID
            if(tooling_ID > 0) {
                TempTooling = TempTooling.map((t) => {
                    if(parseInt(t.tooling_ID) === parseInt(result.data.toolingSave.UpdatedTooling.tooling_ID)) {
                        return result.data.toolingSave.UpdatedTooling
                    } else {
                        return t
                    }
                })

                // Set save_message for next to Save button
                temp_save_message = 'Tooling successfully updated'

            } else {
            
                // For inserts, add the new tooling object to the Tooling array, then re-sort the Tooling by description string
                TempTooling.push(result.data.toolingSave.UpdatedTooling);
                TempTooling = sortToolingNames(TempTooling);

                // Update the SelectedTooling with the new tooling_ID that was created
                setSelectedTooling({
                    ...SelectedTooling,
                    tooling_ID: parseInt(result.data.toolingSave.UpdatedTooling.tooling_ID)
                })

                // Set save_message for next to Save button
                temp_save_message = 'Tooling successfully added'

            }

            // Get the new filtered Tooling saved on the updated tooling values
            let TempFilteredTooling = handleToolingFilter(BaseState.filter, BaseState.filter_tooling_type_ID, BaseState.filter_search, false, TempTooling);
            setBaseState({
                ...BaseState,
                FilteredTooling: TempFilteredTooling,
                Tooling: TempTooling, 
                save_button_text: 'Saved',
                save_message: temp_save_message
            });

        } else {
            console.log("SOMETHING WENT WRONG DURING SAVING")
        }
    }

    // Handler for clicking the 'Add Tooling', 'Close', and edit icon (pencil) buttons that open and close the Tooling form
    function handleButtonClicked(action='', tooling) {

        // Set BaseState, the only variable between the buttons here is what 'action' they pass in
        setBaseState({
            ...BaseState, 
            action: action, 
            save_button_text: 'Save', 
            save_message: ''
        });

        // Open Add Tooling Form
        if(action === 'add_tooling') {
            setSelectedTooling({ 
                ...SelectedTooling, 
                ...DefaultSelectedTooling, 
                tooling_ID: 0
            });
        } 
        // Open Edit Tooling Form
        else if(action === 'edit_tooling') {
            setSelectedTooling({ 
                ...tooling
            });
        } 
        // Close Tooling Form
        else if(action === '' ) {
            setSelectedTooling({ 
                ...SelectedTooling, 
                ...DefaultSelectedTooling
            });
        }
    }

    // Handler for the Search and Clear buttons for the filter input search
    function handleFilterSearch(action, value) {
        if(action === 'filter_search') {
            // Perform filtering with regular filters and the new search value
            handleToolingFilter(BaseState.filter, BaseState.filter_tooling_type_ID, BaseState.filter_search);

        } else if(action === 'clear') {
            // Clear search input and re-filter
            handleToolingFilter(BaseState.filter, BaseState.filter_tooling_type_ID, '', true, [], 'clear_filters');

        } else if(action === 'change_input') {
            let temp_filter_search_message = value.includes(' ') ? 'Search works best without spaces' : '';

            setBaseState({
                ...BaseState,
                filter_search_message: temp_filter_search_message,
                filter_search: value
            });
        }
    }

    // Filter Tooling list - this is called whenever a filter button is clicked
    function handleToolingFilter(filter, filter_tooling_type_ID, filter_search, do_filtering=true, Tooling=[], action) {
        let TempFilteredTooling = Tooling.length > 0 ? Tooling : BaseState.Tooling;

        let save_filter_text_searched = filter_search !== '' ? true : false;
        // Search input filtering - compare to most of the shown columns in the table
        if(filter_search !== '') {
            TempFilteredTooling = TempFilteredTooling.filter((t) => {
                // DO LATER: If the search includes spaces, then split the search result into individual strings for comparing
                // if(filter_search.includes(' ')) {
                //     let search_string_list = filter_search.split(" ");
                //     const index = array.indexOf(5);
                //     if (index > -1) { // only splice array when item is found
                //     array.splice(index, 1); // 2nd parameter means remove one item only
                //     }
                // }
                if(
                    (t.tooling_description !== null && t.tooling_description.toLowerCase().includes(filter_search.toLowerCase()) ) ||
                    (t.supplier_name !== null && t.supplier_name.toLowerCase().includes(filter_search.toLowerCase()) ) ||
                    (t.manufacturer_name !== null && t.manufacturer_name.toLowerCase().includes(filter_search.toLowerCase()) ) ||
                    (t.manufacturer_part_number !== null && t.manufacturer_part_number.toLowerCase().includes(filter_search.toLowerCase()) ) ||
                    (t.supplier_part_number !== null && t.supplier_part_number.toLowerCase().includes(filter_search.toLowerCase()) ) ||
                    (t.notes !== null && t.notes.toLowerCase().includes(filter_search.toLowerCase()) )
                ) {
                    return t
                } else {
                    return null
                }
            });
        }

        // Tool Type filtering
        if(parseInt(filter_tooling_type_ID) > 0) {
            TempFilteredTooling = TempFilteredTooling.filter((t) => parseInt(t.tooling_type_ID) === parseInt(filter_tooling_type_ID));
        }

        // All / Active / Obsolete filtering
        if(filter === 'Active') {
            TempFilteredTooling = TempFilteredTooling.filter((t) => parseInt(t.active) === 1);
        } else if(filter === 'Obsolete') {
            TempFilteredTooling = TempFilteredTooling.filter((t) => parseInt(t.active) === 0);
        }

        if(do_filtering) {
            // If the text search was just clicked, update the state variable to preserve that string for the filter details output
            let temp_filter_text_searched = save_filter_text_searched ? BaseState.filter_search : BaseState.filter_text_searched;
            setBaseState({
                ...BaseState,
                filter: filter,
                filter_search: action === 'clear_filters' ? '' : BaseState.filter_search,
                filter_search_message: action === 'clear_filters' ? '' : BaseState.filter_search_message,
                filter_text_searched: action === 'clear_filters' ? '' : temp_filter_text_searched,
                filter_tooling_type_ID: filter_tooling_type_ID,
                FilteredTooling: sortToolingNames(TempFilteredTooling)
            })
        } else {
            return TempFilteredTooling;
        }

    }

    // Called when "part number" button is clicked in tooling edit /add form, this changes if the part and tool are linked
    function handleToolingPartClick(part_ID, tooling_ID, tooling_part_ID=0, part_number) {

        // Update the state data saying this record has been added / removed.
        let TempToolingParts = [...SelectedTooling.ToolingParts];
        if(tooling_part_ID !== 0) {
            TempToolingParts = TempToolingParts.filter((tp) => parseInt(tp.tooling_part_ID) !== parseInt(tooling_part_ID));
        } else {
            TempToolingParts.push({ part_ID, tooling_ID, tooling_part_ID: `${part_ID}${tooling_ID}`, part_number })
        }

        // Reset the Save button to show that the form can be saved.
        setBaseState({
            ...BaseState,
            save_button_text: 'Save', 
            save_message: ''
        })
        setSelectedTooling({
            ...SelectedTooling,
            ToolingParts: TempToolingParts
        });
    }
console.log({SelectedTooling})
    return (
        <>
            <div>
                <div className="row clearfix mb-3 justify-content-center">

                    {/* ACTIVE / OBSOLETE FILTER BUTTONS */}
                    <div className="col-auto">
                        <div className="btn-group btn-group-sm" role="group">
                            <button className={`btn btn-outline-secondary ${(BaseState.filter === '' && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleToolingFilter('', BaseState.filter_tooling_type_ID, BaseState.filter_search)}>
                                All Tooling
                            </button>
                            <button className={`btn btn-outline-secondary ${(BaseState.filter === 'Active' && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleToolingFilter('Active', BaseState.filter_tooling_type_ID, BaseState.filter_search)}>
                                Only Active
                            </button>
                            <button className={`btn btn-outline-secondary ${(BaseState.filter === 'Obsolete' && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleToolingFilter('Obsolete', BaseState.filter_tooling_type_ID, BaseState.filter_search)}>
                                Only Obsolete
                            </button>
                        </div>                            
                    </div>

                    {/* TOOLING TYPES FILTER BUTTONS */}
                    <div className="col-auto">
                        <div className="btn-group btn-group-sm" role="group">
                            {BaseState.ToolingTypes.map((tt) => {
                                return (
                                    <button key={tt.tooling_type_ID} className={`btn btn-outline-secondary ${(BaseState.filter_tooling_type_ID === tt.tooling_type_ID && 'btn-secondary text-white') || 'text-dark'}`} onClick={() => handleToolingFilter(BaseState.filter, tt.tooling_type_ID, BaseState.filter_search)}>
                                        {tt.tooling_type}
                                    </button>
                                )
                            })}
                        </div>                            
                    </div>

                    {/* SEARCH INPUT */}
                    <div className="col-auto">
                        <div className="input-group input-group-sm">
                            <button 
                                className="btn btn-success" 
                                onClick={() => handleFilterSearch('filter_search')}
                            >
                                Search
                            </button>
                            <input className="form-control border border-secondary" value={BaseState.filter_search || ""} onChange={(event) => handleFilterSearch( 'change_input', event.target.value )} />
                            <button 
                                className="btn btn-light border border-secondary" 
                                onClick={() => handleFilterSearch('clear')}
                            >
                                Clear
                            </button>
                        </div>
                        {BaseState.filter_search_message !== '' &&
                            <div className="text-danger">{BaseState.filter_search_message}</div>
                        }
                    </div>
                </div>

                <div className="row mb-3">

                    {/* RESULTS COUNTER AND FILTERS */}
                    <div className="col h4 mb-1 pt-1">
                        {BaseState.FilteredTooling.length}
                        {BaseState.filter === 'Active' && ' Active'}{BaseState.filter === 'Obsolete' && ' Obsolete'}
                        {` ${BaseState.ToolingTypes.filter((tt) => parseInt(tt.tooling_type_ID) === parseInt(BaseState.filter_tooling_type_ID))[0].tooling_type}`}
                        {` ${(BaseState.FilteredTooling.length === 1 && 'Tool') || 'Tools'}`}
                        {
                            BaseState.filter_text_searched !== '' &&
                            <span className=""> - Matching Search "{BaseState.filter_text_searched}"</span>
                        }
                    </div>

                    {/* ADD TOOLING BUTTON */}
                    <div className="col-auto">
                        <div className="float-end">
                        {
                            SelectedTooling.tooling_ID === 0 && BaseState.action === 'add_tooling' &&
                            <div className="">
                                <span className="col-auto float-end ms-2">
                                    <button 
                                        className="col-auto btn border-primary border-3 text-primary fw-bold" 
                                        disabled={true}
                                    >
                                    Enter New Tooling Info Below
                                    </button>
                                </span>
                            </div>
                        }
                        {
                            ((SelectedTooling.tooling_ID === 0 && BaseState.action !== 'add_tooling') || (SelectedTooling.tooling_ID > 0)) &&
                            <div className="">
                                <button 
                                    className="btn fw-bold col-auto border-primary border-3 float-end text-primary" 
                                    onClick={() => handleButtonClicked('add_tooling')}
                                >
                                    <FontAwesomeIcon icon="fa-plus" className="me-2" />Add Tooling
                                </button>
                            </div>
                        }
                        </div>
                    </div>

                </div>
                {
                    <table className={`table ${SelectedTooling.tooling_ID === '' && 'table-hover'}`}>
                        <thead className="row h6 border border-2 border-top-0 border-start-0 border-end-0 border-dark mb-0">
                            <tr className="col-1">Archive / Edit</tr>
                            <tr className="col-3">Tooling Description</tr>
                            <tr className="col-2">Supplier</tr>
                            <tr className="col-2">Manufacturer</tr>
                            <tr className="col-1">Cost</tr>
                            <tr className="col-1">Cutting Edges</tr>
                            <tr className="col-1">On Hand / Min</tr>
                            <tr className="col-1">Parts Using Tool</tr>
                        </thead>
                        {
                            (BaseState.FilteredTooling.length > 0 || BaseState.action === 'add_tooling') &&
                            <tbody className="">
                                {BaseState.action === 'add_tooling' &&
                                    <tr className="row bg-light border border-secondary">
                                        <td className="border-0 col-1">
                                        </td>
                                        <td className="border-0 col-3">
                                            {/* Tooling Description */}
                                            <div className="form-floating">
                                                <input className="form-control mb-3" id="description" placeholder="Enter a tool description" value={SelectedTooling.tooling_description || ""} onChange={(event) => changeInputValue('tooling_description', event.target.value)} />
                                                <label className="text-muted" htmlFor="description">Description</label>
                                            </div>

                                            {/* Tool Type select */}
                                            <div className="form-floating">
                                                <select 
                                                    className="form-select" 
                                                    disabled={false}
                                                    id="tooling_type"
                                                    onChange={(event) => changeInputValue('tooling_type_ID', event.target.value)}
                                                    placeholder="Tooling Type"
                                                    value={SelectedTooling.tooling_type_ID}
                                                >
                                                    <option key={0} value={0}>Choose a Tooling Type</option>
                                                    {props.ToolingTypes.tooling_types.map((tt) => {
                                                        return (
                                                            <option key={tt.tooling_type_ID} value={tt.tooling_type_ID}>{tt.tooling_type}</option>
                                                        )
                                                    })}
                                                </select> 
                                                <label className="text-muted" htmlFor="tooling_type">Tooling Type</label>                                                   
                                            </div>
                                        </td>

                                        <td className="border-0 col-2">
                                            {/* Selecting Supplier from list */}
                                            <div className="form-floating">
                                                <select 
                                                    className="form-select mb-3" 
                                                    disabled={false}
                                                    id="Supplier"
                                                    onChange={(event) => changeInputValue('supplier_ID', event.target.value)}
                                                    placeholder="Supplier Name"
                                                    value={SelectedTooling.supplier_ID}
                                                >
                                                    <option key={0} value={0}>Choose a Supplier</option>
                                                    {props.Suppliers.suppliers.map((s) => {
                                                        return (
                                                            <option key={s.supplier_ID} value={s.supplier_ID}>{s.supplier_name}</option>
                                                        )
                                                    })}
                                                </select>
                                                <label className="text-muted" htmlFor="supplier">Supplier</label>
                                            </div>
                                            {/* Supplier Part Number */}
                                            <div className="form-floating">
                                                <input className="form-control" id="supplier_part" placeholder="Supplier Part Number" value={SelectedTooling.supplier_part_number || ""} onChange={(event) => changeInputValue('supplier_part_number', event.target.value)} />
                                                <label className="text-muted" htmlFor="supplier_part text-muted">Supplier Part Number</label>
                                            </div>
                                        </td>

                                        <td className="border-0 col-2">
                                            {/* Selecting Manufacturer from list */}
                                            <div className="form-floating">
                                                <select 
                                                    className="form-select mb-3" 
                                                    disabled={false}
                                                    id="manufacturer"
                                                    onChange={(event) => changeInputValue('manufacturer_ID', event.target.value)}
                                                    placeholder="Manufacturer Name"
                                                    value={SelectedTooling.manufacturer_ID}
                                                >
                                                    <option key={0} value={0}>Choose a Manufacturer</option>
                                                    {props.Manufacturers.manufacturers.map((m) => {
                                                        return (
                                                            <option key={m.manufacturer_ID} value={m.manufacturer_ID}>{m.manufacturer_name}</option>
                                                        )
                                                    })}
                                                </select>
                                                <label className="text-muted" htmlFor="manufacturer">Manufacturer</label>  
                                            </div>
                                            {/* Manufacturer Part Number */}
                                            <div className="form-floating">
                                                <input className="form-control" id="manufacturer_part" placeholder="Manufacturer Part Number" value={SelectedTooling.manufacturer_part_number || ""} onChange={(event) => changeInputValue('manufacturer_part_number', event.target.value)} />
                                                <label className="text-muted" htmlFor="manufacturer_part">Manufacturer Part Number</label>
                                            </div>
                                        </td>
                                        <td className="border-0 col-1">
                                            <div className="form-floating">
                                                <input className="form-control" id="tooling_cost" placeholder="Tooling Cost" value={`$${SelectedTooling.tooling_cost}`} onChange={(event) => changeInputValue('tooling_cost', event.target.value)} />
                                                <label className="text-muted" htmlFor="tooling_cost">Tool Cost</label>
                                            </div>
                                        </td>
                                        <td className="border-0 col-1">
                                            <div className="form-floating">
                                                <input className="form-control" id="cutting_edges" placeholder="Cutting Edges" value={SelectedTooling.cutting_edges || ""} onChange={(event) => changeInputValue('cutting_edges', event.target.value)} />
                                                <label className="text-muted" htmlFor="cutting_edges">Edges</label>
                                            </div>
                                        </td>
                                        <td className="border-0 col-1">
                                            <div className="form-floating">
                                                <input className="form-control" id="tooling_min" placeholder="Tooling Min" value={SelectedTooling.tooling_min || ""} onChange={(event) => changeInputValue('tooling_min', event.target.value)} />
                                                <label className="text-muted" htmlFor="tooling_min">Tool Min</label>
                                            </div>
                                        </td>

                                        {/* Selecting Parts for Tooling/Parts connection from list */}
                                        <td className="border-0 col-1">
                                            {props.Parts.parts.map((p) => {
                                                let temp_index = SelectedTooling.ToolingParts.findIndex((tp) => parseInt(tp.part_ID) === parseInt(p.part_ID));
                                                let temp_class = temp_index > -1 ? 'badge rounded-pill border border-success bg-success text-white' : 'badge rounded-pill border border-secondary text-secondary bg-white';
                                                let temp_tooling_part_ID = temp_index > -1 ? parseInt(SelectedTooling.ToolingParts[temp_index].tooling_part_ID) : 0;
                                                return (
                                                    <div key={p.part_ID} className={temp_class} onClick={() => handleToolingPartClick(p.part_ID, 0, temp_tooling_part_ID, p.part_number)}>{p.part_number}</div>
                                                )
                                            })}
                                        </td>
                                        <td className="border-0 col-1">
                                            
                                        </td>
                                        <td className="border-0 col-11 pt-0">
                                            <div className="form-floating">
                                                <textarea className="form-control" id="notes" placeholder="Enter notes here" value={SelectedTooling.notes} rows="2" onChange={(event) => changeInputValue('notes', event.target.value)} />
                                                <label className="text-muted" htmlFor="notes">Notes</label>
                                            </div>
                                            <div className="row mt-3">
                                                <span className="col-auto float-start">
                                                    <button className="btn border-success border-3 fw-bold text-success" disabled={SelectedTooling.tooling_description === ''} onClick={() => saveTooling(SelectedTooling.tooling_ID)}><FontAwesomeIcon icon="fa-floppy-disk" className="me-2" />{BaseState.save_button_text}</button>
                                                </span>
                                                {
                                                    BaseState.save_message !== '' &&
                                                    <span className="col-auto float-start">
                                                        <div className="alert alert-success p-2 mb-0"><FontAwesomeIcon icon="fa-check-circle" className="me-2 fa-lg" />{BaseState.save_message}</div>
                                                    </span>
                                                }
                                                <span className="col float-end">
                                                    <button className="btn border-secondary border-3 fw-bold text-secondary float-end" onClick={() => handleButtonClicked()}><FontAwesomeIcon icon="fa-times" className="me-2" />Close</button>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                }
                                {BaseState.FilteredTooling.map((t) => {
                                    if(parseInt(t.tooling_ID) === parseInt(SelectedTooling.tooling_ID) && BaseState.action === 'edit_tooling') {
                                        // Disable the save button until an input has changed.
                                        let inputs_not_changed = true;

                                        // Compare SelectedTooling to it's state values to see if anything has been changed
                                        for (const [key, value] of Object.entries(SelectedTooling)) {
                                            if(key !== 'ToolingParts') {
                                                // If the unchanged Tooling array object's key if typeof 'number', and the changed SelectedTooling object's key is typeof 'string', change the SelectTooling to 'number'
                                                let temp_value = typeof t[key] === 'number' && typeof value === 'string' ? parseInt(value) : value;
                                                if(temp_value !== t[key]) {
                                                    inputs_not_changed = false;
                                                }
                                            } else if(key === 'ToolingParts') {
                                                // If the ToolingParts array is not the same length, we automatically know that something has changed, we dont need to both with a compare function of the arrays.
                                                if(t.ToolingParts.length !== value.length) {
                                                    inputs_not_changed = false;
                                                } else {
                                                    // Loop through the server's values, and if the part_ID of each array object is found in the SelectedTooling.ToolingParts array objects, then nothing has changed. This cannot be a forEach loop because of ESLint rule warning/error no-loop-func
                                                    for (let i = 0; i < t.ToolingParts.length; i++) {
                                                        if(value.findIndex((v) => parseInt(v.part_ID) === parseInt(t.ToolingParts[i].part_ID)) === -1) {
                                                            inputs_not_changed = false;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        let disable_save_button = inputs_not_changed || BaseState.save_button_text === 'Saving' || BaseState.save_button_text === 'Saved';

                                        return (
                                            <React.Fragment key={t.tooling_ID}>
                                                <tr className="row bg-light border border-secondary">
                                                    <td className="border-0 col-1">
                                                    </td>
                                                    <td className="border-0 col-3">
                                                        {/* Tooling Description */}
                                                        <div className="form-floating">
                                                            <input className="form-control mb-3" id="description" placeholder="Enter a tool description" value={SelectedTooling.tooling_description || ""} onChange={(event) => changeInputValue('tooling_description', event.target.value)} />
                                                            <label className="text-muted" htmlFor="description">Description</label>
                                                        </div>

                                                        {/* Tool Type select */}
                                                        <div className="form-floating">
                                                            <select 
                                                                className="form-select" 
                                                                disabled={false}
                                                                id="tooling_type"
                                                                onChange={(event) => changeInputValue('tooling_type_ID', event.target.value)}
                                                                placeholder="Tooling Type"
                                                                value={SelectedTooling.tooling_type_ID}
                                                            >
                                                                <option key={0} value={0}>Choose a Tooling Type</option>
                                                                {props.ToolingTypes.tooling_types.map((tt) => {
                                                                    return (
                                                                        <option key={tt.tooling_type_ID} value={tt.tooling_type_ID}>{tt.tooling_type}</option>
                                                                    )
                                                                })}
                                                            </select> 
                                                            <label className="text-muted" htmlFor="tooling_type">Tooling Type</label>                                                   
                                                        </div>
                                                    </td>

                                                    <td className="border-0 col-2">
                                                        {/* Selecting Supplier from list */}
                                                        <div className="form-floating">
                                                            <select 
                                                                className="form-select mb-3" 
                                                                disabled={false}
                                                                id="Supplier"
                                                                onChange={(event) => changeInputValue('supplier_ID', event.target.value)}
                                                                placeholder="Supplier Name"
                                                                value={SelectedTooling.supplier_ID}
                                                            >
                                                                <option key={0} value={0}>Choose a Supplier</option>
                                                                {props.Suppliers.suppliers.map((s) => {
                                                                    return (
                                                                        <option key={s.supplier_ID} value={s.supplier_ID}>{s.supplier_name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <label className="text-muted" htmlFor="supplier">Supplier</label>
                                                        </div>
                                                        {/* Supplier Part Number */}
                                                        <div className="form-floating">
                                                            <input className="form-control" id="supplier_part" placeholder="Supplier Part Number" value={SelectedTooling.supplier_part_number || ""} onChange={(event) => changeInputValue('supplier_part_number', event.target.value)} />
                                                            <label className="text-muted" htmlFor="supplier_part text-muted">Supplier Part Number</label>
                                                        </div>
                                                    </td>

                                                    <td className="border-0 col-2">
                                                        {/* Selecting Manufacturer from list */}
                                                        <div className="form-floating">
                                                            <select 
                                                                className="form-select mb-3" 
                                                                disabled={false}
                                                                id="manufacturer"
                                                                onChange={(event) => changeInputValue('manufacturer_ID', event.target.value)}
                                                                placeholder="Manufacturer Name"
                                                                value={SelectedTooling.manufacturer_ID}
                                                            >
                                                                <option key={0} value={0}>Choose a Manufacturer</option>
                                                                {props.Manufacturers.manufacturers.map((m) => {
                                                                    return (
                                                                        <option key={m.manufacturer_ID} value={m.manufacturer_ID}>{m.manufacturer_name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <label className="text-muted" htmlFor="manufacturer">Manufacturer</label>  
                                                        </div>
                                                        {/* Manufacturer Part Number */}
                                                        <div className="form-floating">
                                                            <input className="form-control" id="manufacturer_part" placeholder="Manufacturer Part Number" value={SelectedTooling.manufacturer_part_number || ""} onChange={(event) => changeInputValue('manufacturer_part_number', event.target.value)} />
                                                            <label className="text-muted" htmlFor="manufacturer_part">Manufacturer Part Number</label>
                                                        </div>
                                                    </td>
                                                    <td className="border-0 col-1">
                                                        <div className="form-floating">
                                                            <input className="form-control" id="tooling_cost" placeholder="Tooling Cost" value={`$${SelectedTooling.tooling_cost}`} onChange={(event) => changeInputValue('tooling_cost', event.target.value)} />
                                                            <label className="text-muted" htmlFor="tooling_cost">Tool Cost</label>
                                                        </div>
                                                    </td>
                                                    <td className="border-0 col-1">
                                                        <div className="form-floating">
                                                            <input className="form-control" id="cutting_edges" placeholder="Cutting Edges" value={SelectedTooling.cutting_edges || ""} onChange={(event) => changeInputValue('cutting_edges', event.target.value)} />
                                                            <label className="text-muted" htmlFor="cutting_edges">Edges</label>
                                                        </div>
                                                    </td>
                                                    <td className="border-0 col-1">
                                                        <div className="form-floating">
                                                            <input className="form-control" id="tooling_min" placeholder="Tooling Min" value={SelectedTooling.tooling_min || ""} onChange={(event) => changeInputValue('tooling_min', event.target.value)} />
                                                            <label className="text-muted" htmlFor="tooling_min">Tool Min</label>
                                                        </div>
                                                    </td>

                                                    {/* Selecting Parts for Tooling/Parts connection from list */}
                                                    <td className="border-0 col-1">
                                                        {props.Parts.parts.map((p) => {
                                                            let temp_index = SelectedTooling.ToolingParts.findIndex((tp) => parseInt(tp.tooling_ID) === parseInt(t.tooling_ID) && parseInt(tp.part_ID) === parseInt(p.part_ID));
                                                            let temp_class = temp_index > -1 ? 'badge rounded-pill border border-success bg-success text-white' : 'badge rounded-pill border border-secondary text-secondary bg-white';
                                                            let temp_tooling_part_ID = temp_index > -1 ? parseInt(SelectedTooling.ToolingParts[temp_index].tooling_part_ID) : 0;
                                                            return (
                                                                <div key={p.part_ID} className={temp_class} onClick={() => handleToolingPartClick(p.part_ID, t.tooling_ID, temp_tooling_part_ID, p.part_number)}>{p.part_number}</div>
                                                            )
                                                        })}
                                                    </td>
                                                    <td className="border-0 col-1">
                                                        
                                                    </td>
                                                    <td className="border-0 col-11 pt-0">
                                                        <div className="form-floating">
                                                            <textarea className="form-control" id="notes" placeholder="Enter notes here" value={SelectedTooling.notes} rows="2" onChange={(event) => changeInputValue('notes', event.target.value)} />
                                                            <label className="text-muted" htmlFor="notes">Notes</label>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <span className="col-auto float-start">
                                                                <button className="btn border-success border-3 fw-bold text-success" disabled={disable_save_button} onClick={() => saveTooling(t.tooling_ID)}><FontAwesomeIcon icon="fa-floppy-disk" className="me-2" />{BaseState.save_button_text}</button>
                                                            </span>
                                                            {
                                                                BaseState.save_message !== '' &&
                                                                <span className="col-auto float-start">
                                                                    <div className="alert alert-success p-2 mb-0"><FontAwesomeIcon icon="fa-check-circle" className="me-2 fa-lg" />{BaseState.save_message}</div>
                                                                </span>
                                                            }
                                                            <span className="col float-end">
                                                                <button className="btn border-secondary border-3 fw-bold text-secondary float-end" onClick={() => handleButtonClicked()}><FontAwesomeIcon icon="fa-times" className="me-2" />Close</button>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    } else {
                                        let archive_button_class = parseInt(t.active) === 1 ? 'border-danger text-danger' : 'border-success text-success';
                                        let archive_button_icon = parseInt(t.active) === 1 ? 'fa-trash-can' : 'fa-rotate-left';
                                        //let archive_button_text = parseInt(t.active) === 1 ? 'Active' : 'Obsolete';
                                        let tooling_description_text = parseInt(t.active) === 0 ? `(${t.tooling_description})` : t.tooling_description;
                                        let tooling_type = BaseState.filter_tooling_type_ID === 0 ? `Type: ${BaseState.ToolingTypes.filter((tt) => parseInt(tt.tooling_type_ID) === parseInt(t.tooling_type_ID))[0].tooling_type}` : '';

                                        return (
                                            <tr className={`row ${(BaseState.action === 'add_tooling' || BaseState.action === 'edit_tooling') && 'table-secondary text-muted'}`} key={t.tooling_ID}>
                                                <td className="col-1 align-middle">
                                                    {/* {archive_button_text} */}
                                                    <button className={`btn btn-sm ${archive_button_class} float-start`} disabled={BaseState.save_button_text === 'Saving'} onClick={() => archiveTooling(t.tooling_ID, t.archiver_ID)}><FontAwesomeIcon icon={archive_button_icon} className="" /></button>
                                                    <button className="btn btn-sm border-primary text-primary float-end" onClick={() => handleButtonClicked('edit_tooling', t)}><FontAwesomeIcon icon="fa-pencil" className="" /></button>
                                                </td>
                                                <td className={`col-3 ps-3 ${parseInt(t.active) === 0 && 'text-muted'}`}>
                                                    {tooling_description_text}<br />
                                                    <span className="text-muted">{tooling_type}</span><br />
                                                    {t.notes !== '' && t.notes}
                                                </td>
                                                <td className={`col-2 ps-3 ${parseInt(t.active) === 0 && 'text-muted'}`}>{t.supplier_name}<br />{t.supplier_part_number}</td>
                                                <td className={`col-2 ps-3 ${parseInt(t.active) === 0 && 'text-muted'}`}>{t.manufacturer_name}<br />{t.manufacturer_part_number}</td>
                                                <td className={`col-1 ps-3 ${parseInt(t.active) === 0 && 'text-muted'}`}>${t.tooling_cost}</td>
                                                <td className={`col-1 ps-3 ${parseInt(t.active) === 0 && 'text-muted'}`}>{t.cutting_edges}</td>
                                                <td className={`col-1 ps-3 ${parseInt(t.active) === 0 && 'text-muted'}`}>100 / {t.tooling_min}</td>
                                                <td className={`col-1 small ps-3 ${parseInt(t.active) === 0 && 'text-muted'}`}>{t.ToolingParts.map((tp) => { return (<div className="small" key={tp.tooling_part_ID}>- {tp.part_number}</div> ) } )}</td>
                                            </tr>
                                        )
                                    }
                                })}
                            </tbody>
                        }
                        {
                            BaseState.FilteredTooling.length === 0 &&
                            <tbody className="">
                                <tr>
                                    <td colSpan="7">
                                        <div className="alert alert-info m-0">No Tooling matching filters</div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </table>
                }
            </div>
        </>
    )
}

export const ToolingComponent = compose(
	queryWithLoading({
		gqlString: GetManufacturers,
        name: "Manufacturers"
	}),
	queryWithLoading({
		gqlString: GetParts,
        name: "Parts"
	}),
	queryWithLoading({
		gqlString: GetSuppliers,
        name: "Suppliers"
	}),
	queryWithLoading({
		gqlString: GetTooling,
        name: "Tooling",
        variablesFunction: (props) => ({include_tooling_parts: true})
	}),
	queryWithLoading({
		gqlString: GetToolingParts,
        name: "ToolingParts"
	}),
	queryWithLoading({
		gqlString: GetToolingTypes,
        name: "ToolingTypes"
	}),
	// withMutation(SaveTooling, "SaveTooling"),
	withMutation(SaveToolingPart, "SaveToolingPart"),
    // graphql(SaveTooling)
)(ToolingContent)

	// withMutation(ToolingSaveMutation, "ToolingSaveMutation", [{query: getToolingQuery}]),
