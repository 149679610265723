import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from './App.js';
import _ from 'lodash';
// import logo from './logo.svg';
import './App.css';
// import gql from 'apollo-boost';
import reportWebVitals from './reportWebVitals.js';
import {
    ApolloClient,
    ApolloProvider,
    //createHttpLink,
    InMemoryCache
    // HttpLink,
    // from
} from "@apollo/client";
// import { setContext } from '@apollo/client/link/context';

import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'react-auth-kit';
import { ApplicationLayout } from "./layouts/application.js";

// INCLUDE POPPER JS for tooltips
// import { createPopper } from '@popperjs/core';

// Include bootstrap 5
import 'bootstrap/dist/css/bootstrap.min.css';

// INCLUDE FONTASWESOME 5 - must specify icons to use here - ADD imported FA ICONS to library
import { library } from '@fortawesome/fontawesome-svg-core';
import * as icons from "@fortawesome/free-solid-svg-icons";

// Single list of icons to add to the library.  Will split on whitespace before adding to the library.
const iconList = `
	faAmbulance
	faAlignCenter
	faAngleLeft
	faAngleRight
	faArchive
	faArrowsAltV
	faArrowDown
	faArrowRight
    faArrowRightFromBracket
    faArrowRightToBracket
	faBan
	faBarcode
	faBars
	faBell
	faBox
	faBoxOpen
	faBoxes
	faBoxesStacked
	faBuilding
	faBullhorn
	faBusinessTime
	faCalendarAlt
	faCaretDown
	faCaretRight
	faCartPlus
	faChartBar
	faChartLine
	faChartPie
	faCheck
	faCheckCircle
    faCheckCircleFil
	faChevronDown
	faChevronRight
	faCircle
    faClipboard
	faClipboardList
	faClock
	faCogs
	faComment
	faComments
	faCreditCard
	faCut
	faDog
	faDollarSign
	faDolly
	faExclamation
	faExclamationCircle
	faExclamationTriangle
	faEye
	faEyeSlash
	faFaceFrown
	faFile
	faFileExcel
	faFileImage
	faFileInvoiceDollar
	faFilePdf
	faFileWord
	faFire
	faFireAlt
    faFloppyDisk
    faFolderMinus
	faFolderOpen
    faFolderPlus
	faFolderTree
    faGear
	faGrinBeam
	faGripVertical
	faHandPaper
	faHandSparkles
	faHome
	faHospital
    faHourglass
	faIdCard
	faInbox
	faIndent
	faInfoCircle
	faLayerGroup
    faLight
	faLinkSlash
	faList
	faLock
	faMagic
	faMapMarkerAlt
	faMinus
	faMoneyBill1
	faMonument
    faNoteSticky
	faOutdent
	faPaperPlane
	faPaw
	faPen
	faPenAlt
    faPencil
	faPenSquare
    faPenToSquare
	faPencilSquareo
	faPenSquareo
	faPhone
	faPlus
	faPrint
	faReply
    faRotateLeft
    faScrewdriverWrench
	faSearch
	faShoppingCart
	faShuttleVan
	faSitemap
	faSort
	faSortDown
	faSortUp
	faSpinner
	faSquare
    faSquarePlus
	faStore
	faTachometerAlt
	faTag
	faTags
	faTasks
    faThin
	faThList
	faThumbsUp
	faTimes
    faToiletsPortable
    faToolbox
	faTrash
	faTrashAlt
    faTrashCan
	faTruck
	faTruckFast
	faTruckLoading
	faTruckMoving
	faUndo
	faUnlock
	faUpload
	faUserCircle
	faUserMd
	faUsers
	faUserSecret
	faWalking
	faWarehouse
`.trim().split(/\s+|,/g);

// Add the requested icons to the list.
library.add( _.pick(icons, iconList) );

// APOLLO CACHE - Mapping the primary keys from the db to an id field in the Apollo Cache for each GraphQL Type
// const id_type_map = {
//     "Machines": "machine_ID",
//     "Manufacturers": "manufacturer_ID",
//     "Parts": "part_ID",
//     "ProductionRecords": "production_ID",
//     "Suppliers": "supplier_ID",
//     "Tooling": "tooling_ID",
//     "ToolingParts": "tooling_part_ID",
//     "ToolingTypes": "tooling_type_ID",
// 	"Users": "user_ID"
// }

// NS ToDo: Will have to add this for cacheing
// const cache = new InMemoryCache({
// 	dataIdFromObject: (object) => {
// 		// If the id_type_map has a record for the particular type then lookup the value in the object based on the field name in the map.
// 		if(id_type_map.hasOwnProperty(object.__typename)) {
// 			return object.__typename + '_' + object[id_type_map[object.__typename]];
// 		} else {
// 			return defaultDataIdFromObject(object);
// 		}
// 	}
// });

// // Option 1
// const link = createHttpLink({
//   uri: 'http://localhost:4000', // uri specifies the location of our GraphQL Server
//   credentials: 'same-origin'
// });

// // Option 2
// const httpLink = createHttpLink({
//   uri: 'http://localhost:4000',
// });

// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = localStorage.getItem('token');
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : "",
//     }
//   }
// });

const client = new ApolloClient({
 // uri: 'http://localhost:4000', // uri specifies the location of our GraphQL Server - Dev
  uri: 'https://panthertp.com:4000', // uri specifies the location of our GraphQL Server - PRODUCTION
  cache: new InMemoryCache({
    addTypename: false
  })
//   ,
//   defaultOptions: {
//     fetchPolicy: 'network-only'
//   }
  //link: authLink.concat(httpLink)
});


const root = ReactDOM.createRoot(document.getElementById('root'));
// IMPORTANT - HAVE TO MAKE COOKIESECURE=TRUE WHEN IN PRODUCTION
root.render(
    <AuthProvider
        authType={"cookie"}
        authName={"auth"}
        cookieDomain={window.location.hostname}
        cookieSecure={false}
    >
        <ApolloProvider client={client}>
            <BrowserRouter>
                <ApplicationLayout />
            </BrowserRouter>
        </ApolloProvider>
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
