import React, { useRef } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import {flowRight as compose} from 'lodash';
import { queryWithLoading } from '../utilities/NSDb.js';
// import { graphql } from '@apollo/react-hoc';
import { useState } from 'react';
import _ from "lodash";
import dayjs from 'dayjs';
import { useAuthUser } from 'react-auth-kit';
import { useReactToPrint } from 'react-to-print';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
import { useMutation, useLazyQuery } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// GRAPHQL QUERY
// import {  
//     ArchiveDowntime,
//     // ,
//     SaveDowntime 
// } from '../downtimes/downtimes_graphql.js';
// import {  GetMachines } from '../machines/machines_graphql.js';
// import {  GetParts } from '../parts/parts_graphql.js';
// import {  GetShifts } from '../shifts/shifts_graphql.js';
// import {  GetTooling } from '../tooling/tooling_graphql.js';
import {  ArchiveToolingUsage, SaveToolingUsage } from '../tooling_usage/tooling_usage_graphql.js';
import {  ProductionTag } from '../printing/printing_component.js';
// import {  GetUsers } from '../users/users_graphql.js';

import { 
    GetOperatorProductionPage,
    SaveProductionRecord,
    SaveProductionRecordsContainers
} from '../production_records/production_records_graphql.js';

// IMPORT COMPONENTS
import {DowntimeFormContent} from '../downtimes/downtime_form.js'
import {ToolingUsageFormContent} from '../tooling_usage/tooling_usage_form.js'
import { NoUndefinedVariablesRule } from 'graphql';



function OperatorProductionContent(props) {

    const auth = useAuthUser();

    console.log({props})
    // Set const values from props
    const { 
        downtime_reasons: DowntimeReasons,
        tooling: Tooling
    } = props.componentData;

    // const [archive_tooling_usage] = useMutation(ArchiveToolingUsage);
    // const [archive_downtime] = useMutation(ArchiveDowntime);
    // const [save_downtime] = useMutation(SaveDowntime);
    const [save_production_record] = useMutation(SaveProductionRecord);
    const [save_production_records_containers] = useMutation(SaveProductionRecordsContainers);
    const [save_tooling_usage] = useMutation(SaveToolingUsage);

    // Create the default SelectedProductionRecord object for setting after form is closed
    const DefaultSelectedProductionRecord = {
        creator_ID: props.user_ID,
        date_production: dayjs(props.User.date_scheduled).format('YYYY-MM-DD'),
        date_scheduled: props.date_scheduled,
        Downtimes: [],
        good_parts_produced: 0,
        hours_worked: '',
        machine_ID: props.machine_ID,
        notes: '',
        Operators: props.Operators || [],
        part_ID: props.part_ID,
        part_number: props.part_number,
        production_record_ID: 0,
        scrap_parts_produced: 0,
        serial_number: 0,
        shift_ID: props.shift_ID,
        time_clock_record_ID: props.time_clock_record_ID,
        ToolingUsage: [],
        updated_standard_hourly_rate: ''
    };

    // const DefaultSelectedDowntime = {
    //     date_scheduled: props.date_scheduled,
    //     downtime_ID: 0,
    //     archiver_ID: 0,
    //     creator_ID: props.user_ID,
    //     date_archived: '',
    //     date_created: '',
    //     downtime_hours: 0,
    //     downtime_minutes: 0,
    //     downtime_reason_ID: 0,
    //     machine_ID: props.machine_ID,
    //     notes: '',
    //     save_button_text: 'Save',
    //     save_message: '',
    //     shift_ID: props.shift_ID,
    //     time_clock_record_ID: props.time_clock_record_ID
    // };

    const DefaultSelectedTooling = {
        tooling_usage_ID: 0,
        archiver_ID: 0,
        creator_ID: props.user_ID,
        date_archived: '',
        date_created: '',
        date_scheduled: props.date_scheduled,
        machine_ID: props.machine_ID,
        notes: '',
        production_record_ID: 0,
        save_button_text: 'Save',
        save_message: '',
        shift_ID: props.shift_ID,
        time_clock_record_ID: props.time_clock_record_ID,
        tooling_ID: 0,
        tooling_usage_quantity: 1
    };

    const DefaultContainerOptions = {
        create: false,
        MergeTo: [],
        Printing: {
            blanks: false,
            unique: true
        }
    };

    const DefaultBaseState = {
        action: '',
        action_downtime: '',
        action_tooling_usage: '',
        CheckedProductionRecords: [],
        ContainerOptions: DefaultContainerOptions,
        // Downtimes: props.ProductionDetails.Downtimes,
        machine_ID: props.machine_ID,
        ProductionRecords: props.ProductionDetails.ProductionRecords,
        save_button_text: 'Save',
        save_button_text_downtime: 'Save',
        save_button_text_tooling: 'Save',
        save_message: '',
        save_message_downtime: '',
        save_message_tooling: '',
        ToolingUsage: props.ProductionDetails.ToolingUsages
    }

    // const [SelectedDowntime, setSelectedDowntime] = useState({ ...DefaultSelectedDowntime });
    const [SelectedProductionRecord, setSelectedProductionRecord] = useState({ ...DefaultSelectedProductionRecord });
    const [SelectedTooling, setSelectedTooling] = useState({ ...DefaultSelectedTooling });

    const [BaseState, setBaseState] = useState({ ...DefaultBaseState });

    // If the Control Panel's machine_ID has changed, update BaseState Production Details
    if ( parseInt(BaseState.machine_ID) !== parseInt(props.machine_ID)) {
        setBaseState({
            ...DefaultBaseState
        })
    }

    // const marginTop="10px"
    // const marginRight="5px"
    // const marginBottom="10px"
    // const marginLeft="5px"
    // const getPageMargins = () => {
    // return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    // };
    // console.log("PR Records: ", BaseState.ProductionRecords)
    function PrintingComponent(printing_props) {
        const componentRef = useRef();
        const handlePrint = useReactToPrint({
            content: () => componentRef.current,
        });
        //marginTop: 150+'px', marginLeft: 50+'px', 

        // Grinder machines need original tag
        let temp_tag = ( parseInt(props.machine_ID) > 50 && parseInt(props.machine_ID) < 55 ) || parseInt(props.machine_ID) === 60 ? "ProductionTag" : "ProductionTagOperator";
        return (
           <>
                <span style={{ display: "none" }}>
                {/* <span > */}
                    <ProductionTag 
                        action={printing_props.action} 
                        machine_ID={props.machine_ID}
                        machine_name={props.machine_short_name !== '' && props.machine_short_name !== null ? props.machine_short_name : props.machine_name}
                        part_description={props.part_description} 
                        part_number={props.part_number} 
                        part_revision={props.Part[0].revision ? props.Part[0].revision : ''}
                        part_deviation={props.Part[0].deviation ? props.Part[0].deviation : ''}
                        records={printing_props.records} 
                        ref={componentRef} 
                        Shifts={props.Shifts}
                        tag={temp_tag}
                        User={props.User}
                    />
                </span>
                {/* <button onClick={handlePrint}>Print this out!</button> */}
                <button className={printing_props.class} onClick={handlePrint}><FontAwesomeIcon icon="fa-print" size="xl" className="me-2" />{printing_props.button}</button>
            </>
        );
    };

    // Update the state for any changing input in the Production Record details form
    function changeSelectedProductionRecordInputValue(name, value) {
        let update_selected_production_record = true;
        // For inputs that must be integers, do not allow non numeric characters, and parseInt on the value
        if ( name === 'good_parts_produced' || name === 'scrap_parts_produced' ) {
            if(value !== '') {
                value = parseInt(value);
                if( isNaN(value) ) {
                    value = 0;
                }
            } else {
                // This is to avoid attempting to save "" as an INT
                value = null;
            }
        } 

        // Reset the Save button to show that the form can be saved.
        setBaseState({
            ...BaseState,
            save_button_text: 'Save', 
            save_message: ''
        })

        // Don't do this update here for shift_ID, its changing value occurs above
        if(update_selected_production_record) {
            setSelectedProductionRecord({
                ...SelectedProductionRecord,
                    [name]: value,
            })
        }
    }

    // Save Downtime Form
    // async function saveDowntime(Downtime) {
    //     let SaveData = { ...Downtime };

    //     let MakeIntegers = ['downtime_reason_ID'];
    //     MakeIntegers.forEach((mi) => SaveData[mi] = parseInt(SaveData[mi]));

    //     let result = await save_downtime({ variables: { input: _.omit(SaveData, ['save_button_text', 'save_message']) } })
    //     // Reset the SelecteDowntime state
    //     setSelectedDowntime({
    //         DefaultSelectedDowntime
    //     });

    //     let TempDowntimes = [ ...BaseState.Downtimes ];
    //     // Push this result to the Downtimes array
    //     if ( result.data && result.data.downtimeSave.UpdatedDowntime ) {
    //         TempDowntimes.push(result.data.downtimeSave.UpdatedDowntime) 
    //     }
    //     // Reset downtime action in order to close the Downtime form
    //     setBaseState({
    //         ...BaseState,
    //         action_downtime: '',
    //         Downtimes: TempDowntimes
    //     })
    // }

    async function saveTooling(ToolingUsage) {
        let SaveData = { ...ToolingUsage };

        let MakeIntegers = ['tooling_ID'];
        MakeIntegers.forEach((mi) => SaveData[mi] = parseInt(SaveData[mi]));

        let result = await save_tooling_usage({ variables: { input: _.omit(SaveData, ['save_button_text', 'save_message']) } })

        // Reset the SelectedTooling state
        setSelectedTooling({
            DefaultSelectedTooling
        });

        // Turn tooling usage inputs into an object to push to the SelectedProductionRecord's array
        let TempToolingUsage = [...BaseState.ToolingUsage];

        // Push this result to the Tooling array
        if ( result.data && result.data.toolingUsageSave.UpdatedToolingUsage ) {
            TempToolingUsage.push(result.data.toolingUsageSave.UpdatedToolingUsage) 
        }

        setBaseState({
            ...BaseState,
            action_tooling_usage: '',
            ToolingUsage: TempToolingUsage
        })
    
    }

    // Validate the production reocrd
    function handleSaveProductionRecord() {
        // VALIDATION: Must select a downtime reason
        if(SelectedProductionRecord.good_parts_produced === 0 && SelectedProductionRecord.scrap_parts_produced === 0) {
            setBaseState({
                ...BaseState,
                save_button_text: 'Save', 
                save_message: 'Enter good or scrap parts'
            })
        }
        // VALIDATION: Must have note
        else if(SelectedProductionRecord.notes === '') {
            setBaseState({
                ...BaseState,
                save_button_text: 'Save', 
                save_message: 'Note required'
            })
        }
        else {
            setBaseState({
                ...BaseState, 
                save_button_text: 'Saved'
            })

            // Save Downtime form to parent component
            saveProductionRecord(0, 0, 'save')
        }
    }

    // Save button clicked in Tooling form, if production_record_ID > 0 this is an edit/update, if production_record_ID=0 this is adding a tooling record
    async function saveProductionRecord(production_record_ID, archiver_ID=0, action='') {
        let result;

        if(action !== 'archive') {

            setBaseState({
                ...BaseState, 
                save_button_text: 'Saving'
            });

            // Add new input key for ToolingParts to be a list of part_IDs for ease of saving to the tooling_parts database
            //let SaveData = { ...SelectedProductionRecord, tooling_part_IDs: SelectedProductionRecord.ToolingParts.map((tp) => tp.part_ID).join() };
            
            let SaveData = { ...SelectedProductionRecord };
            // Parse string values that are IDs to be integers, saving stringified numbers to the database throws an error.
            let MakeIntegers = ['machine_ID', 'part_ID', 'serial_number', 'shift_ID', 'time_clock_record_ID', 'updated_standard_hourly_rate'];
            MakeIntegers.forEach((mi) => SaveData[mi] = parseInt(SaveData[mi]));

            // Parse ints for Downtimes if there are any
            // if(SaveData.Downtimes.length > 0) { 
            //     // let TempDowntimes = [...SaveData.Downtimes];
            //     SaveData.Downtimes = SaveData.Downtimes.map((dt) => { return {..._.omit(dt, ['downtime_hours_string','downtime_minutes_string','downtime_reason']), downtime_reason_ID: parseInt(dt.downtime_reason_ID) } });
            //     // SaveData.Downtimes = TempDowntimes;
            // }

            // // Parse ints for ToolingUsage if there are any
            // if(SaveData.ToolingUsage.length > 0) { 
            //     SaveData.ToolingUsage = SaveData.ToolingUsage.map((tu) => { return {...tu, tooling_ID: parseInt(tu.tooling_ID) } });
            // }

            // Parse Operators into array of just user_ID and production_record_ID for passing to server
            SaveData.Operators = SaveData.Operators.map((o) => { return { user_ID: parseInt(o.user_ID), production_record_ID: parseInt(production_record_ID)} });

            // Save this Production Record's form details and await the resulting object to update overall state with. Do not send the Operators arrays.
            result = await save_production_record({ variables: { input: { ..._.omit(SaveData, ['downtime_hours', 'downtime_minutes', 'machine_name', 'machine_short_name', 'part_number', 'shift_name']), shift_group: parseInt(auth().User.shift_group), date_created: dayjs().format('YYYY-MM-DD HH:mm:ss') } }})
            //_.omit(SaveData, ['Operators'])

        } else {
        
            result = await save_production_record({ variables: { input: { production_record_ID, archiver_ID, active: archiver_ID > 0 ? 0 : 1, action } } })
        }

        // Save returns a single Production Record object, either an updated one if production_record_ID > 0 or a new object if this was adding a Production Record
        if(result.data.productionRecordSave.UpdatedProductionRecord && result.data.productionRecordSave.UpdatedProductionRecord.production_record_ID > 0) {
            let TempProductionRecords = [...BaseState.ProductionRecords];
            let temp_save_message = '';

            // UPDATES: update the Production Record object for the updated production_record_ID
            if(production_record_ID > 0) {
                TempProductionRecords = TempProductionRecords.map((pr) => {
                    if(parseInt(pr.production_record_ID) === parseInt(result.data.productionRecordSave.UpdatedProductionRecord.production_record_ID)) {
                        return result.data.productionRecordSave.UpdatedProductionRecord
                    } else {
                        return pr
                    }
                })

                // Set save_message for next to Save button
                // temp_save_message = 'Production record successfully updated'
                temp_save_message = '';
            } 
            // INSERTS
            else {
            
                // For inserts, add the new Production Record object to the Production Record array, then re-sort the Production Record by description string
                TempProductionRecords.push(result.data.productionRecordSave.UpdatedProductionRecord);
                // Update the SelectedProductionRecord with the new production_record_ID that was created
                setSelectedProductionRecord({
                    ...DefaultSelectedProductionRecord
                })

                // Set save_message for next to Save button
                // temp_save_message = 'Production record successfully added'
                temp_save_message = '';
            }

            setBaseState({
                ...BaseState,
                action: '',
                ProductionRecords: TempProductionRecords, 
                save_button_text: 'Saved',
                save_message: temp_save_message
            });

        } else {
            // console.log("SOMETHING WENT WRONG DURING SAVING")
        }
    }

    // Handler for clicking the 'Add Production Record', 'Close', and edit icon (pencil) buttons that open and close the Production Record form
    function handleButtonClicked(action='', Selected) {

        // Open Add Production Record Form
        if(action === 'add_production_record') {
            setSelectedProductionRecord({ 
                ...SelectedProductionRecord, 
                ...DefaultSelectedProductionRecord,
                date_production: dayjs(props.User.date_scheduled).format('YYYY-MM-DD'),
                production_record_ID: 0
            });
            setBaseState({
                ...BaseState,
                action: action
            });
        } 
        // Open Edit Production Record Form
        else if(action === 'edit_production_record') {
            setSelectedProductionRecord({ 
                ...Selected
            });
            setBaseState({
                ...BaseState,
                action: action
            });
        } 
        // Close Production Record Form
        else if(action === 'close_production_record' ) {
            setSelectedProductionRecord({ 
                ...SelectedProductionRecord, 
                ...DefaultSelectedProductionRecord
            });
            setBaseState({
                ...BaseState,
                action: ''
            })
        }

        // Open Add Downtime Form
        // else if(action === 'add_downtime' || action === 'edit_downtime') {
        //     setSelectedDowntime({ 
        //         ...Selected
        //     });
        //     setBaseState({
        //         ...BaseState,
        //         action_downtime: action
        //     });
        // } 
        // else if(action === 'close_downtime') {
        //     setSelectedDowntime({ 
        //         ...SelectedDowntime, 
        //         ...DefaultSelectedDowntime
        //     });
        //     setBaseState({
        //         ...BaseState,
        //         action_downtime: ''
        //     })
        // } 

        // Open Add Downtime Form
        else if(action === 'add_tooling_usage' || action === 'edit_tooling_usage') {
            setSelectedTooling({ 
                ...Selected
            });
            setBaseState({
                ...BaseState,
                action_tooling_usage: action
            });
        } 
        else if(action === 'close_tooling_usage') {
            setSelectedTooling({ 
                ...SelectedTooling, 
                ...DefaultSelectedTooling
            });
            setBaseState({
                ...BaseState,
                action_tooling_usage: ''
            })
        } 

    }

    // Handler for checking all of the production records in the list
    function handleCheckAllProductionRecords() {
        let TempChecked = [];
        // If length of BaseState checked array is the same as the length of the Production Records, we know that all of them are checked, and we empty the BaseState array, otherwise, just create a new array with all of the PRs checked.
        if ( BaseState.CheckedProductionRecords.length !== BaseState.ProductionRecords.length) {
            TempChecked = BaseState.ProductionRecords;
        }

        let TempContainerOptions = handleProductionRecordsPrint(TempChecked, { ...DefaultContainerOptions });

        setBaseState({
            ...BaseState,
            CheckedProductionRecords: TempChecked,
            ContainerOptions: TempContainerOptions
        });

    }

    // Handler for checking a sinlge production record in the list
    function handleCheckProductionRecord(PR, index) {
        // Push or remove this PR from BaseState.CheckedProductionRecords. If index > -1, remove from that index of the array.
        let TempChecked = [ ...BaseState.CheckedProductionRecords ];

        if ( index > -1 ) {
            TempChecked.splice(index, 1);
        } else {
            TempChecked.push(PR);
        }

        // Evaluate the remaining Checked PRs for which actions can be taken 
        let TempContainerOptions = { ...DefaultContainerOptions };

        if ( TempChecked.length > 0 ) {

            // If only PR(s) without a Serial Number are checked, then our only option is to create a new Serial Number
            if ( TempChecked.findIndex((tc) => tc.serial_number !== null) === -1 ) {
                TempContainerOptions.create = true;
            } 
            // Otherwise, add all of the selected container's Serial Numbers to the options array
            else {
                TempChecked.forEach((tc) => {
                    // If this PR's Serial Number is already in the options array, do nothing, otherwise add it to the array
                    if ( TempContainerOptions.MergeTo.findIndex((mt) => mt === tc.serial_number ) === -1 ) {
                        TempContainerOptions.MergeTo.push(tc.serial_number);
                    }
                });
            }
            
        }

        TempContainerOptions = handleProductionRecordsPrint(TempChecked, TempContainerOptions);

        // console.log({TempContainerOptions})

        setBaseState({
            ...BaseState,
            CheckedProductionRecords: TempChecked,
            ContainerOptions: TempContainerOptions
        });

    }

    // Function for determining what the Checked PRs should be able to print
    function handleProductionRecordsPrint(ProductionRecords, ContainerOptions) {

        // If there are multiple containers selected, or a PR without a container, do not allow printing.
        let TempUnique = [];
        let blanks_selected = false;
        // Loop PRs and get array of unique serial numbers
        ProductionRecords.forEach((pr) => {
            if ( pr.serial_number !== null && TempUnique.findIndex((u) => parseInt(u) === parseInt(pr.serial_number)) === -1 ) {
                TempUnique.push(pr.serial_number);
            } else if ( pr.serial_number === null ) {
                blanks_selected = true;
            }
        });

        return { ...ContainerOptions, Printing: { blank: blanks_selected, unique: TempUnique.length < 2}};
    }

    // Handler function for the Container buttons getting clicked. "Create" and "Print" buttons
    async function handleContainer(action) {
    
        // Create a new container for all Selected PRs
        if ( action === 'create' ) {
            let TempPRCs = BaseState.CheckedProductionRecords.map((cpr) => {
                return { production_record_ID: parseInt(cpr.production_record_ID), creator_ID: parseInt(auth().User.user_ID), date_created: dayjs().format('YYYY-MM-DD HH:mm:ss'), container_status: 1 }
            })
            let result = await save_production_records_containers({ variables: { input: { action: 'create',  PRCs: TempPRCs } } });

            let TempProductionRecords = [ ...BaseState.ProductionRecords ];

            // Loop through the results and update the BaseState.ProductionRecords for ones that had containers created
            if ( result.data && result.data.productionRecordContainerSave.ProductionRecordsContainers && result.data.productionRecordContainerSave.ProductionRecordsContainers.length > 0 ) {

                let TempNewContainers = result.data.productionRecordContainerSave.ProductionRecordsContainers;
                TempProductionRecords = TempProductionRecords.map((tpr) => {

                    let temp_index = TempNewContainers.findIndex((tnc) => parseInt(tnc.production_record_ID) === parseInt(tpr.production_record_ID));
                    // If this PR is in the results' saved new container, update the BaseState PR
                    if ( temp_index > -1) {
                        return { ...tpr, container_status: TempNewContainers[temp_index].container_status, serial_number: TempNewContainers[temp_index].serial_number };
                    } else { return tpr; }
                });

            }

            // Update Production Records and clear out checkboxes
            setBaseState({
                ...BaseState,
                ProductionRecords: TempProductionRecords,
                CheckedProductionRecords: [],
                ContainerOptions: DefaultContainerOptions
            });

        }

    }

    // Get Select input of all unique containers in the PRs
    function uniqueContainersSelectInput() {

        let TempUnique = [];
        // Loop PRs and get array of unique serial numbers
        BaseState.ProductionRecords.forEach((pr) => {
            if ( pr.serial_number !== null && TempUnique.findIndex((u) => parseInt(u) === parseInt(pr.serial_number)) === -1 ) {
                TempUnique.push(pr.serial_number);
            }
        })

        return (
            <select 
                className="form-select mb-3" 
                disabled={false}
                id="serial_number"
                onChange={(event) => changeSelectedProductionRecordInputValue('serial_number', event.target.value)}
                placeholder="Serial Number"
                value={SelectedProductionRecord.serial_number}
            >
                <option key={0} value={0}>No Container</option>
                {TempUnique.map((serial_number) => {
                    return (
                        <option key={serial_number} value={serial_number}>W{serial_number}</option>
                    )
                })}
                <option key={-1} value={-1}>Create New Container</option>
            </select>
        )

    }
    // console.log({BaseState})
    // console.log("Container Options: ", BaseState.ContainerOptions)
    return (
        <>
            <div>
                {/* PRODUCTION RECORD DETAILS CONTENT */}
                <div className="">
                    <div className="row text-secondary pb-1">
                        <div className="float-start col-auto h5">
                            <span 
                                className={`col-auto border border-primary btn btn-sm text-primary me-2 mb-1 ${BaseState.action !== '' && 'disabled'}`}
                                onClick={() => handleButtonClicked('add_production_record')}
                            >
                                <FontAwesomeIcon icon="fa-plus" size="lg" className="" />
                            </span>
                            Record Production
                        </div>
                        
                        {/* Loop through Container Options */}
                        {
                            BaseState.CheckedProductionRecords.length > 0 &&
                            <>
                                <div className="float-start col-auto px-0 ms-3">
                                    {/* Show the Add new container button first, which would always be shown if there are PRs selected */}
                                    <button className="btn border-success border-3 fw-bold text-success" onClick={() => handleContainer('create')}><FontAwesomeIcon icon="fa-plus" className="me-2" />New Container</button>
                                </div>
                                {/* If only unqiue containers are checked, show printing buttons. If all of a container's PRs are selected, then show just an option to print all, otherwise show option for printing selected PRs or entire container  */}
                                {
                                    ((BaseState.ContainerOptions.Printing.blank === true || BaseState.ContainerOptions.Printing.unique === false) &&
                                        <div className="float-start col-auto">
                                            <div className="alert alert-warning mb-0 ms-3 p-2">
                                                <FontAwesomeIcon icon="fa-print" size="xl" className="me-2" />
                                                <span className="h5">
                                                    {BaseState.ContainerOptions.Printing.unique === true && BaseState.ContainerOptions.Printing.blank === true && "Cannot print tag for records without a container."}
                                                    {BaseState.ContainerOptions.Printing.unique === false && "Cannot print tag for records with different containers."}
                                                    {/* {BaseState.ContainerOptions.Printing.unique === false && BaseState.ContainerOptions.Printing.blank === true && "Cannot print tag for records with different or no containers."} */}
                                                </span>
                                            </div>
                                        </div>
                                    ) || 
                                    <div className="float-start col-auto px-0 ms-4">
                                        {/* Show the Add new container button first, which would always be shown if there are PRs selected */}
                                        { BaseState.CheckedProductionRecords.length === 1 && 
                                            <>
                                                <PrintingComponent action={'print_tag'} class={'btn border-primary border-3 fw-bold text-primary'} records={BaseState.CheckedProductionRecords} button={"Print Selected Only"} />
                                                {/* <button className="btn border-primary border-3 fw-bold text-primary" onClick={() => handleContainer('print_tag', BaseState.CheckedProductionRecords)}><FontAwesomeIcon icon="fa-print" size="xl" className="me-2" />Print Tag</button> */}
                                                {   
                                                    BaseState.ProductionRecords.filter((pr) => parseInt(pr.serial_number) === parseInt(BaseState.ContainerOptions.MergeTo[0])).length > 1 && 
                                                    <PrintingComponent action={'print_tag_all_quantities'} class={'btn ms-4 border-primary border-3 fw-bold text-primary'} records={BaseState.ProductionRecords.filter((pr) => parseInt(pr.serial_number) === parseInt(BaseState.ContainerOptions.MergeTo[0]))} button={`W${BaseState.ContainerOptions.MergeTo[0]} All Quantities`} />
                                                }
                                                {/* <button className="btn ms-4 border-primary border-3 fw-bold text-primary" onClick={() => handleContainer('print_tag_all_quantities', BaseState.CheckedProductionRecords)}><FontAwesomeIcon icon="fa-print" size="xl" className="me-2" />W{BaseState.ContainerOptions.MergeTo[0]} All Quantities</button> */}
                                            </>
                                        }
                                        { BaseState.CheckedProductionRecords.length > 1 && 
                                            <>
                                                <PrintingComponent action={'print_tag_selected_quantities'} class={'btn border-primary border-3 fw-bold text-primary'} records={BaseState.CheckedProductionRecords} button={"Combine Selected Quantities"} />
                                                {/* <button className="btn border-primary border-3 fw-bold text-primary" onClick={() => handleContainer('print_tag_selected_quantities', BaseState.CheckedProductionRecords)}><FontAwesomeIcon icon="fa-print" size="xl" className="me-2" />Combine Selected Quantities</button>  */}
                                                {
                                                    BaseState.ProductionRecords.filter((pr) => parseInt(pr.serial_number) === parseInt(BaseState.ContainerOptions.MergeTo[0])).length !== BaseState.CheckedProductionRecords.length && 
                                                    <PrintingComponent action={'print_tag_all_quantities'} class={'btn ms-4 border-primary border-3 fw-bold text-primary'} records={BaseState.ProductionRecords.filter((pr) => parseInt(pr.serial_number) === parseInt(BaseState.ContainerOptions.MergeTo[0]))} button={`W${BaseState.ContainerOptions.MergeTo[0]} All Quantities`} />
                                                }
                                                {/* <button className="btn ms-4 border-primary border-3 fw-bold text-primary" onClick={() => handleContainer('print_tag_all_quantities', BaseState.CheckedProductionRecords)}><FontAwesomeIcon icon="fa-print" size="xl" className="me-2" />W{BaseState.ContainerOptions.MergeTo[0]} All Quantities</button> */}
                                            </>
                                        }
                                    </div>
                                }

                            </>
                        }
                    </div>
                    {/* <div><PrintingComponent tooling_ID={5} /></div> */}

                    {/* Production list */}
                    {
                        (BaseState.ProductionRecords.length > 0 || BaseState.action === 'add_production_record') &&
                        <div className="ms-2 me-3 mt-1">
                            <table className="table">
                                <thead className="mb-0">
                                    <tr className="row me-2">
                                        <th className="col-2">
                                            <input className="form-check-input border-secondary mt-1 h5 me-2" type="checkbox" id="" checked={false} onChange={() => handleCheckAllProductionRecords()} />
                                            Recorded
                                        </th>
                                        <th className="col-2">Part / Serial</th>                                        
                                        <th className="col-1">Good</th>
                                        <th className="col-1">Scrap</th>
                                        <th className="col-6">Note</th>
                                        {/* <th className="col-1"></th>
                                        <th className="col-1"></th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {BaseState.action === 'add_production_record' &&
                                        <tr key={0} className="row me-2">
                                            {/* Production Time */}
                                            <td className="border-0 col-2">
                                                {dayjs().format("M-D-YY h:mm a")}<br />
                                                <span className="fw-bolder">
                                                    {props.machine_short_name !== null && props.machine_short_name !== '' && props.machine_short_name}
                                                    {(props.machine_short_name === null || props.machine_short_name === '') && props.machine_name}
                                                </span>
                                            </td>
                                            <td className="border-0 col-2">
                                                <span className="fw-bolder">{props.part_number}</span><br />
                                                {uniqueContainersSelectInput()}
                                            </td>
                                            {/* Good Parts Produced */}
                                            <td className="border-0 col-1">
                                                <span className="form-floating">
                                                    <input className="form-control" id="good_parts" placeholder="Good" value={SelectedProductionRecord.good_parts_produced || ""} onChange={(event) => changeSelectedProductionRecordInputValue('good_parts_produced', event.target.value)} />
                                                    <label className="text-muted" htmlFor="good_parts">Good</label>
                                                </span>
                                            </td>

                                            {/* Scrap Parts Produced */}
                                            <td className="border-0 col-1">
                                                <span className="form-floating">
                                                    <input className="form-control" id="scrap_parts" placeholder="Scrap" value={SelectedProductionRecord.scrap_parts_produced || ""} onChange={(event) => changeSelectedProductionRecordInputValue('scrap_parts_produced', event.target.value)} />
                                                    <label className="text-muted" htmlFor="scrap_parts">Scrap</label>
                                                </span>
                                            </td>

                                            {/* Production Record Notes */}
                                            <td className="border-0 col-4">
                                                <span className="form-floating">
                                                    <textarea className="form-control" id="production_record_notes" placeholder="Enter notes here" value={SelectedProductionRecord.notes} rows="2" onChange={(event) => changeSelectedProductionRecordInputValue('notes', event.target.value)} />
                                                    <label className="text-muted" htmlFor="production_record_notes">Note - <span className="text-danger">Required</span></label>
                                                </span>
                                            </td>

                                            {/* Save PR button and Message */}
                                            {
                                                BaseState.save_message !== '' && 
                                                <td className="border-0 col-2 pt-4">
                                                    <span className="text-danger">{BaseState.save_message}</span>
                                                </td>
                                            }
                                            {
                                                BaseState.save_message === '' && 
                                                <>
                                                    <td className="border-0 col-1 pt-0">
                                                        <button className="btn mt-3 border-success border-3 fw-bold text-success" disabled={SelectedProductionRecord.good_parts_produced === ''} onClick={() => handleSaveProductionRecord()}><FontAwesomeIcon icon="fa-floppy-disk" className="me-2" />{BaseState.save_button_text}</button>
                                                    </td>
                                                    <td className="border-0 col-1 pt-0 pe-0">
                                                        <button className="btn mt-3 border-secondary float-end border-3 fw-bold text-secondary" onClick={() => handleButtonClicked('close_production_record')}><FontAwesomeIcon icon="fa-times" className="me-2" />Close</button>
                                                    </td>
                                                </>
                                            }

                                        </tr>
                                    }
                                    {BaseState.ProductionRecords.length > 0 && 
                                        BaseState.ProductionRecords.map((pr, index) => {
                                            let temp_index = BaseState.CheckedProductionRecords.findIndex((cpr) => parseInt(cpr.production_record_ID) === parseInt(pr.production_record_ID));
                                            return (
                                                <tr key={index} className="row me-2">
                                                    <td className="col-2">
                                                        <>
                                                            <div className="clearfix">
                                                                <div className="float-start">
                                                                    <input className="form-check-input border-secondary mt-1 h5 me-2" type="checkbox" id="" checked={temp_index > -1} value="" onChange={() => handleCheckProductionRecord(pr, temp_index)} />
                                                                </div>
                                                                <div className="float-start">
                                                                    {dayjs(pr.date_created).format('h:mm A M-DD-YY')}<br />
                                                                    {pr.first_name} {pr.last_name}
                                                                </div>
                                                            </div>
                                                        </>
                                                    </td>
                                                    <td className="col-2">
                                                        {pr.part_number}<br />
                                                        {pr.serial_number === null && <span className="text-muted">(No Container)</span>}
                                                        {pr.serial_number !== null && 
                                                            <span className="h5">
                                                                {parseInt(pr.container_status) === 1 && <span className="badge bg-success text-white">W{pr.serial_number}</span>}
                                                                {parseInt(pr.container_status) === 3 && <span className="badge bg-secondary text-white">W{pr.serial_number}</span>}
                                                            </span>
                                                        }
                                                    </td>
                                                    <td className="col-1"><span className="ps-1">{pr.good_parts_produced}</span></td>
                                                    <td className="col-1"><span className="ps-1">{pr.scrap_parts_produced}</span></td>
                                                    <td className="col-6"><span className="ps-1 text-wrap">{pr.notes}</span></td>
                                                    {/* <td className="col-1">
                                                        <button className="btn ms-1 text-primary border-primary" onClick={() => handleButtonClicked('edit_production_record', pr)}><FontAwesomeIcon icon="fa-pencil" className="" /></button>
                                                    </td>
                                                    <td className="col-1"></td> */}
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>

                {/* TOOLING USAGE RECORD CONTENT */}
                <div className="row mt-3">
                    <div className="h5 text-secondary">
                        <span 
                            className={`col-auto border border-primary btn btn-sm text-primary me-2 mb-1 ${BaseState.action_tooling_usage !== '' && 'disabled'}`}
                            onClick={() => handleButtonClicked('add_tooling_usage', DefaultSelectedTooling)}
                        >
                            <FontAwesomeIcon icon="fa-plus" size="lg" className="" />
                        </span>
                        Record Tooling Usage
                    </div>

                    {/* Tooling Usage list */}
                    {
                        (BaseState.ToolingUsage.length > 0 || BaseState.action_tooling_usage === 'add_tooling_usage') &&
                        <div className="ms-2 me-3 mt-1">
                            <table className="table">
                                <thead className="mb-0">
                                    <tr className="row me-2">
                                        <th className="col-6">Tooling Usage</th>
                                        <th className="col-1">Quantity</th>
                                        <th className="col-5">Note</th>
                                        {/* <th className="col-1"></th>
                                        <th className="col-1"></th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {BaseState.action_tooling_usage === 'add_tooling_usage' &&
                                        <tr key={0} className="row me-2">
                                            <ToolingUsageFormContent 
                                                closeTooling={(action) => handleButtonClicked('close_tooling_usage')}
                                                Tooling={Tooling}
                                                saveTooling={(SelectedTooling) => saveTooling(SelectedTooling)}
                                                SelectedTooling={SelectedTooling}
                                            />
                                        </tr>
                                    }
                                    {
                                        BaseState.ToolingUsage.length > 0 && 
                                        BaseState.ToolingUsage.map((tu, index) => {
                                            let TempToolingUsage = Tooling.filter((t) => parseInt(tu.tooling_ID) === parseInt(t.tooling_ID))[0];
                                            if ( parseInt(tu.tooling_usage_ID) === parseInt(SelectedTooling.tooling_usage_ID)) {
                                                return (
                                                    <tr key={index} className="row me-2">
                                                        <ToolingUsageFormContent 
                                                            closeTooling={(action) => handleButtonClicked('close_tooling_usage')}
                                                            Tooling={Tooling}
                                                            saveTooling={(SelectedTooling) => saveTooling(SelectedTooling)}
                                                            SelectedTooling={SelectedTooling}
                                                        />
                                                    </tr>
                                                )
                                            } else {
                                                return (
                                                    <tr key={index} className="row me-2">
                                                        <td className="col-6"><span className="ps-1">{TempToolingUsage.tooling_description} - {TempToolingUsage.manufacturer_name} {TempToolingUsage.manufacturer_part_number}</span></td>
                                                        <td className="col-1"><span className="ps-1">{tu.tooling_usage_quantity}</span></td>
                                                        <td className="col-5"><span className="ps-1">{tu.notes}</span></td>
                                                        {/* <td className="col-1">
                                                            <button className="btn ms-1 text-primary border-primary" onClick={() => handleButtonClicked('edit_tooling_usage', tu)}><FontAwesomeIcon icon="fa-pencil" className="" /></button>
                                                        </td>
                                                        <td className="col-1">
                                                        </td> */}
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>

            </div>
        </>
    )
}


export const OperatorProductionComponent = compose(
	queryWithLoading({
		gqlString: GetOperatorProductionPage,
        name: "componentData",
        variablesFunction: () => ({ active: 1, user_type_ID: 2 })
	})
)(OperatorProductionContent)
