import { gql } from '@apollo/client';

export const GetWorkSchedules = gql`
    query GetWorkSchedules {
        work_schedules {
            work_schedule_ID,
            active,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            description,
            sunday1_shift_ID,
            monday1_shift_ID,
            tuesday1_shift_ID,
            wednesday1_shift_ID,
            thursday1_shift_ID,
            friday1_shift_ID,
            saturday1_shift_ID,
            sunday2_shift_ID,
            monday2_shift_ID,
            tuesday2_shift_ID,
            wednesday2_shift_ID,
            thursday2_shift_ID,
            friday2_shift_ID,
            saturday2_shift_ID,
            note
        }
        shifts {
            shift_ID,
            active,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            break_end_1,
            break_end_2,
            break_start_1,
            break_start_2,
            lunch_end,
            lunch_start,
            lunch_paid,
            note,
            shift_end,
            shift_group,
            shift_hours_worked,
            shift_name,
            shift_start
        }
    }
`;

export const SaveWorkSchedule = gql`
	mutation workScheduleSave($input: WorkScheduleInput) {
		workScheduleSave (input: $input) {
            WorkSchedules {
                work_schedule_ID,
                active,
                archiver_ID,
                creator_ID,
                date_archived,
                date_created,
                description,
                sunday1_shift_ID,
                monday1_shift_ID,
                tuesday1_shift_ID,
                wednesday1_shift_ID,
                thursday1_shift_ID,
                friday1_shift_ID,
                saturday1_shift_ID,
                sunday2_shift_ID,
                monday2_shift_ID,
                tuesday2_shift_ID,
                wednesday2_shift_ID,
                thursday2_shift_ID,
                friday2_shift_ID,
                saturday2_shift_ID,
                note
            }
		}
	}
`;