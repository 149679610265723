import { gql } from '@apollo/client';

        // production_records {
        //     production_record_ID,
        //     active,
        //     archiver_ID,
        //     creator_ID,
        //     date_archived,
        //     date_created,
        //     date_production,
        //     good_parts_produced,
        //     hours_worked,
        //     machine_ID,
        //     machine_name,
        //     machine_short_name,
        //     notes,
        //     part_ID,
        //     part_number,
        //     scrap_parts_produced,
        //     shift_ID,
        //     shift_name,
        //     updated_standard_hourly_rate,
        //     use_updated_standard
        // }
        // production_records_users {
        //     production_record_ID
        //     user_ID,
        //     first_name,
        //     last_name
        // }

export const GetControlPanelPage = gql`
    query GetControlPanelPage($active: Int) {
        downtime_reasons {
            downtime_reason_ID,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            downtime_reason
        }
        downtimes {
            downtime_ID
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            downtime_hours,
            downtime_minutes,
            downtime_reason_ID,
            downtime_reason,
            notes,
            production_record_ID
        }
        machines {
            machine_ID,
            machine_name,
            machine_short_name,
            machine_status_ID,
            creator_ID,
            archiver_ID,
            date_created,
            date_archived,
            part_ID
        }
        machine_statuses {
            machine_status_ID,
            machine_status,
            idle_status,
            off_status,
            production_status,
            setup_status
        }
        machines_operators (ender_ID: -1) {
            machine_operator_ID,
            date_ended,
            date_started,
            ender_ID,
            first_name,
            last_name,
            machine_ID,
            starter_ID,
            user_ID
        }
        parts {
            part_ID,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            description,
            hourly_standard_rate,
            image_ID,
            image_filename,
            part_number,
            revision,
            sale_price,
            scrap_percentage_cost_sheet,
            standard_tool_cost,
            deviation
        }
        shifts {
            shift_ID,
            active,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            break_end_1,
            break_end_2,
            break_start_1,
            break_start_2,
            lunch_end,
            lunch_start,
            lunch_paid,
            note,
            shift_end,
            shift_group,
            shift_hours_worked,
            shift_name,
            shift_start
        }
        tooling(active: $active) {
            tooling_ID,
            active,
            archiver_ID,
            creator_ID,
            cutting_edges,
            date_archived,
            date_created,
            manufacturer_ID,
            manufacturer_name,
            manufacturer_part_number,
            notes,
            supplier_active,
            supplier_ID,
            supplier_name,
            supplier_part_number,
            tooling_cost,
            tooling_description,
            tooling_min,
            tooling_part_IDs,
            tooling_type_ID
        }
        tooling_usages {
            tooling_usage_ID,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            notes,
            production_record_ID,
            tooling_ID,
            tooling_usage_quantity
        }
        users {
            user_ID,
            first_name,
            last_name
        }
    }
`;

export const SaveProductionRecord = gql`
	mutation productionRecordSave($input: ProductionRecordInput) {
		productionRecordSave (input: $input) {
            UpdatedProductionRecord {
                production_record_ID,
                active,
                archiver_ID,
                creator_ID,
                date_archived,
                date_created,
                date_production,
                Downtimes {
                    downtime_ID
                    archiver_ID,
                    creator_ID,
                    date_archived,
                    date_created,
                    downtime_hours,
                    downtime_minutes,
                    downtime_reason_ID,
                    notes,
                    production_record_ID
                },
                good_parts_produced,
                hours_worked,
                machine_ID,
                notes,
                Operators {
                    production_record_user_ID,
                    production_record_ID,
                    user_ID
                }
                part_ID,
                scrap_parts_produced,
                shift_ID,
                ToolingUsage {
                    tooling_usage_ID,
                    archiver_ID,
                    creator_ID,
                    date_archived,
                    date_created,
                    notes,
                    production_record_ID,
                    tooling_ID,
                    tooling_usage_quantity
                },
                updated_standard_hourly_rate,
                use_updated_standard
            }
		}
	}
`;