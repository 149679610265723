import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import {flowRight as compose} from 'lodash';
import { queryWithLoading } from '../utilities/NSDb.js';
import { useState } from 'react';
// import dayjs from 'dayjs';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
import { useMutation } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { EmployeeComponent } from "./employee_component.js";

// GRAPHQL QUERY
import { 
    GetEmployeesList,
    SaveUser
} from '../users/users_graphql.js';

function EmployeesListContent(props) {

    const { companies: Companies, work_schedules: WorkSchedules } = props.componentData;

    const [save_user] = useMutation(SaveUser);

    const DefaultSelectedUser = {
        user_ID: 0,
        active: 1,
        apprenticeship: '',
        archiver_ID: 0,
        assignment: '',
        badge_number: '',
        citizenship: '',
        citizenship_note: '',
        college: '',
        creator_ID: 0,
        date_archived: '',
        date_birth: '',
        date_created: '',
        date_hired: '',
        date_next_review: '',
        date_visa_expiration: '',
        date_visa_issued: '',
        default_cost_sub_group: '',
        department_ID: 0,
        educational_grade: '',
        email: '',
        emergency_note: '',
        employee_fax: '',
        employee_phone: '',
        employee_number: '',
        employment_status: 1,
        first_name: '',
        gender: '',
        home_address: '',
        home_phone: '',
        hourly_rate: '',
        individual_job_description: '',
        individual_mission_statement: '',
        last_name: '',
        logged_in: 0,
        martial_status: '',
        middle_name: '',
        mobile_phone: '',
        next_review_form_ID: 0,
        other_experience: '',
        overtime_rate: '',
        password: '',
        position_ID: 0,
        reports_to_user_ID: 0,
        source_of_hire: '',
        visa_type: '',
        user_name: '',
        user_type_ID: 0,
        work_eligibility_proof: '',
        work_schedule_ID: 0
    };

    const [SelectedUser, setSelectedUser] = useState({ ...DefaultSelectedUser });
    const [BaseState, setBaseState] = useState({
        action: '',
        filter_active_users: 1,
        filter_direct_labor: 2,
        Users: props.componentData.users
    });

    // Handler function for all non-save buttons - Selected will always be the SelectedUser object of whatever is being clicked. 'Add' is the defaultSelectedUser object.
    function handleButtonClick(action, Selected) {
        let TempSelected = action === 'close_user' ? { ...Selected } : { ...Selected, save_button_text: 'Save', save_message: ''};

        setSelectedUser({
            ...TempSelected
        });

        setBaseState({
            ...BaseState,
            action: action
        })
    }


    // Show different views if we are just looking at list, or editing / adding an employee.
    if ( BaseState.action === '' ) {
        return (
            <>
                <div>
                    <div className="row clearfix mb-3">
                        {/* RESULTS COUNTER AND FILTERS */}
                        <div className="col h4 mb-0 pt-3">
                            {BaseState.Users.filter((u) => parseInt(u.active) === parseInt(BaseState.filter_active_users) && (parseInt(u.direct_labor) === parseInt(BaseState.filter_direct_labor) || parseInt(BaseState.filter_direct_labor) === 2) ).length} Users
                        </div>

                        <div className="col">
                            <div className="btn-group me-3" role="group">
                                <button className={`btn btn-outline-secondary ${(parseInt(BaseState.filter_active_users) === 1 && 'btn-secondary fw-bold text-white') || 'text-dark'}`} onClick={() => setBaseState({ ...BaseState, filter_active_users: 1 })}>Active</button>
                                <button className={`btn btn-outline-secondary ${(parseInt(BaseState.filter_active_users) === 0 && 'btn-secondary fw-bold text-white') || 'text-dark'}`} onClick={() => setBaseState({ ...BaseState, filter_active_users: 0 })}>Inactive</button>
                            </div>                            
                            <div className="btn-group" role="group">
                                <button className={`btn btn-outline-secondary ${(parseInt(BaseState.filter_direct_labor) === 2 && 'btn-secondary fw-bold text-white') || 'text-dark'}`} onClick={() => setBaseState({ ...BaseState, filter_direct_labor: 2 })}>All</button>
                                <button className={`btn btn-outline-secondary ${(parseInt(BaseState.filter_direct_labor) === 1 && 'btn-secondary fw-bold text-white') || 'text-dark'}`} onClick={() => setBaseState({ ...BaseState, filter_direct_labor: 1 })}>Direct</button>
                                <button className={`btn btn-outline-secondary ${(parseInt(BaseState.filter_direct_labor) === 0 && 'btn-secondary fw-bold text-white') || 'text-dark'}`} onClick={() => setBaseState({ ...BaseState, filter_direct_labor: 0 })}>Indirect</button>
                            </div>                            
                        </div>

                        <div className="col float-end">
                            {
                                BaseState.action === 'add_user' &&
                                <div className="">
                                    <span className="col-auto float-end ms-2">
                                        <button 
                                            className="col-auto btn border-primary border-3 text-primary fw-bold disabled" 
                                            disabled={true}
                                        >
                                        Enter New User Info Below
                                        </button>
                                    </span>
                                </div>
                            }
                            {
                                ((SelectedUser.user_ID === 0 && BaseState.action !== 'add_user') || SelectedUser.user_ID > 0) &&
                                <div className="">
                                    <button 
                                        className="btn fw-bold col-auto border-primary border-3 float-end text-primary" 
                                        onClick={() => handleButtonClick('add_user', DefaultSelectedUser)}
                                    >
                                        <FontAwesomeIcon icon="fa-plus" className="me-2" />Add User
                                    </button>
                                    {
                                        BaseState.action === 'added_user' &&
                                        <span className="col-auto float-end me-2">
                                            <div className="alert alert-success p-2">User added successfully</div>
                                        </span>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    {
                        <table className="table table-hover">
                            <thead className="row h6 border border-2 border-top-0 border-start-0 border-end-0 border-dark">
                                <tr className="col-1"></tr>
                                <tr className="col-1">Employee</tr>
                                <tr className="col-1">Company</tr>
                                <tr className="col-1">Pay Type</tr>
                                <tr className="col-1">Employee / Badge</tr>
                                <tr className="col-1">Work Schedule</tr>
                                <tr className="col-1">Department Code</tr>
                                <tr className="col-1">Position</tr>
                                <tr className="col-1">Reports To</tr>
                                <tr className="col-1">Shift Group</tr>
                                <tr className="col-1">Home Phone</tr>
                                <tr className="col-1">Hired Date</tr>
                            </thead>
                            {
                                BaseState.Users.length > 0 &&
                                <tbody className="">

                                    {BaseState.Users.filter((u) => parseInt(u.active) === parseInt(BaseState.filter_active_users) && (parseInt(u.direct_labor) === parseInt(BaseState.filter_direct_labor) || parseInt(BaseState.filter_direct_labor) === 2) ).map((u) => {

                                        // Set Reports to name
                                        // let reports_to 
                                        // parseInt(u.reports_to_user_ID) > 0 && BaseState.Users.filter((bsu) => parseInt(bsu.user_ID) === parseInt(u.reports_to_user_ID))[0].first_name
                                        return (
                                            <tr className="row" key={u.user_ID} onClick={() => handleButtonClick('edit_user', u)}>
                                                <td className="col-1 d-flex align-items-center">
                                                    { parseInt(u.active) === 0 && <span className="ms-4 badge rounded-pill border border-danger text-danger">Inactive</span> }
                                                    { parseInt(u.active) === 1 && <span className="ms-4 badge rounded-pill border border-success text-success">Active</span> }
                                                </td>
                                                <td className={`col-1 ${u.archiver_ID > 0 && 'text-muted'}`}>
                                                    {u.first_name} {u.last_name}
                                                </td>
                                                <td className={`col-1 ${u.archiver_ID > 0 && 'text-muted'}`}>
                                                    {parseInt(u.company_ID) > 0 && Companies.filter((c) => parseInt(c.company_ID) === parseInt(u.company_ID))[0].company_short}
                                                </td>
                                                <td className={`col-1 ${u.archiver_ID > 0 && 'text-muted'}`}>
                                                    {parseInt(u.direct_labor) === 1 && 'Direct'}{parseInt(u.direct_labor) === 0 && 'Indirect'}
                                                </td>
                                                <td className={`col-1 ${u.archiver_ID > 0 && 'text-muted'}`}>
                                                    {u.employee_number}<br />{u.badge_number}
                                                </td>
                                                <td className={`col-1 ${u.archiver_ID > 0 && 'text-muted'}`}>
                                                    {parseInt(u.work_schedule_ID) > 0 && WorkSchedules.filter((ws) => parseInt(ws.work_schedule_ID) === parseInt(u.work_schedule_ID))[0].description}
                                                </td>
                                                <td className={`col-1 ${u.archiver_ID > 0 && 'text-muted'}`}>
                                                    
                                                </td>
                                                <td className={`col-1 ${u.archiver_ID > 0 && 'text-muted'}`}>
                                                    
                                                </td>
                                                <td className={`col-1 ${u.archiver_ID > 0 && 'text-muted'}`}>
                                                    {u.reports_to}
                                                </td>
                                                <td className={`col-1 ${u.archiver_ID > 0 && 'text-muted'}`}>
                                                
                                                </td>
                                                <td className={`col-1 ${u.archiver_ID > 0 && 'text-muted'}`}>

                                                </td>
                                                <td className={`col-1 ${u.archiver_ID > 0 && 'text-muted'}`}>
                                                    {u.date_hired !== '' && u.date_hired !== null && moment(u.date_hired).format('MM/DD/YYYY')}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            }
                        </table>
                    }
                </div>
            </>
        )
    } else if ( BaseState.action === 'add_user' || 'edit_user' ) {
        return (
            <EmployeeComponent 
                User={SelectedUser}
                Users={BaseState.Users}
                action={BaseState.action}
                called_from="employee_list"
                Companies={Companies}
                handleChangeParentBaseState={ (action, Users) => setBaseState({ ...BaseState, action: action, Users: Users }) }
                WorkSchedules={WorkSchedules}
            />
        )
    }
}

export const EmployeesListComponent = compose(
	queryWithLoading({
		gqlString: GetEmployeesList,
        name: "componentData"
	})
)(EmployeesListContent)

