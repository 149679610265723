import { gql } from '@apollo/client';

export const GetTooling = gql`
    query GetTooling($active: Int) {
        tooling(active: $active) {
            tooling_ID,
            active,
            archiver_ID,
            creator_ID,
            cutting_edges,
            date_archived,
            date_created,
            manufacturer_ID,
            manufacturer_name,
            manufacturer_part_number,
            notes,
            supplier_active,
            supplier_ID,
            supplier_name,
            supplier_part_number,
            tooling_cost,
            tooling_description,
            tooling_min,
            tooling_part_IDs,
            tooling_type_ID
        }
    }
`;

export const GetToolingParts = gql`
    query GetToolingParts {
        tooling_parts {
            tooling_part_ID,
            part_ID,
            part_number,
            tooling_ID
        }
    }
`;

export const GetToolingPartsSingleTool = gql`
    query GetToolingPartsSingleTool($input: tooling_ID!) {
        tooling_parts_single_tool (input: $input) {
            part_ID
        }
    }
`;

export const SaveToolingBOM = gql`
	mutation toolingBOMSave($input: ToolingBOMInput) {
		toolingBOMSave (input: $input) {
            UpdatedToolingBOM {
                tooling_BOM_ID,
                archiver_ID,
                cost_per_part,
                creator_ID,
                cutting_edges,
                date_archived,
                date_created,
                manufacturer_name,
                manufacturer_part_number,
                part_ID,
                pieces_per_edge,
                supplier_name,
                supplier_part_number,
                tool_station,
                tooling_description,
                tooling_ID,
                tooling_type
            }
		}
	}
`;

export const SaveToolingPart = gql`
	mutation toolingPartSave($input: [ToolingPartInput]) {
		toolingPartSave (input: $input) {
            ToolingParts {
                tooling_part_ID,
                part_ID,
                part_number,
                tooling_ID
            }
		}
	}
`;

export const SaveTooling = gql`
	mutation toolingSave($input: ToolingInput) {
		toolingSave (input: $input) {
            UpdatedTooling {
                tooling_ID,
                active,
                archiver_ID,
                creator_ID,
                cutting_edges,
                date_archived,
                date_created,
                manufacturer_ID,
                manufacturer_name,
                manufacturer_part_number,
                notes,
                supplier_active,
                supplier_ID,
                supplier_name,
                supplier_part_number,
                tooling_cost,
                tooling_description,
                tooling_min,
                tooling_part_IDs,
                tooling_type_ID,
                ToolingParts {
                    tooling_part_ID,
                    tooling_ID,
                    part_ID,
                    part_number
                }
            }
		}
	}
`;

