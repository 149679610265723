import { gql } from '@apollo/client';

// Get all employee time clock records
export const GetTimeClockRecords = gql`
    query GetTimeClockRecords($active: Int, $date_scheduled_end: DateTime, $date_scheduled_start: DateTime) {
        time_clock_records(active: $active, date_scheduled_end: $date_scheduled_end, date_scheduled_start: $date_scheduled_start) {
            time_clock_record_ID
            clockin_type_ID
            archiver_ID
            creator_ID
            date_archived
            date_created
            date_scheduled
            date_scheduled_in
            date_scheduled_out
            approved
            approver_ID
            first_name
            hours
            last_name
            lunch_paid
            minutes
            note
            reports_to
            schedule_designation_ID
            shift_group
            shift_ID
            shift_name
            shifts_lunch_paid
            shifts_shift_group
            tcrn_creator
            tcrn_creator_ID
            tcrn_date_created
            tcrn_date_updated
            tcrn_note
            tcrn_note_original
            tcrn_own_note
            tcrn_updater
            tcrn_updater_ID
            time_clock_record_note_ID
            time_clock_record_schedule_designation_ID
            time_scheduled_in
            time_scheduled_out
            time_scheduled_hours
            time_actual_in
            time_actual_out
            time_actual_hours
            time_pay_in
            time_pay_out
            time_pay_hours
            user_ID
        }
    }
`;

// Get all employee time clock records actual
export const GetTimeClockRecordsActual = gql`
    query GetTimeClockRecordsActual($active: Int, $date_scheduled_end: DateTime, $date_scheduled_start: DateTime) {
        time_clock_records_actual(active: $active, date_scheduled_end: $date_scheduled_end, date_scheduled_start: $date_scheduled_start) {
            time_clock_records_actual_ID
            time_clock_record_ID
            time_actual_in
            time_actual_out
            time_actual_hours
            time_pay_in
            time_pay_out
            time_pay_hours
            approved
            approver_ID
            archiver_ID
            came_early
            date_archived
            late
            late_excuser_ID
            left_early
            left_early_excuser_ID
            left_late
            user_ID            
        }
    }
`;

// Get Employee Schedule for single employee
export const GetEmployeeSchedule = gql`
    query GetEmployeeSchedule($date_scheduled_end: DateTime, $date_scheduled_start: DateTime, $user_ID: Int) {
        employee_schedule(date_scheduled_end: $date_scheduled_end, date_scheduled_start: $date_scheduled_start, user_ID: $user_ID) {
            time_clock_record_ID
            clockin_type_ID
            archiver_ID
            creator_ID
            date_archived
            date_created
            date_scheduled
            approved
            approver_ID
            first_name
            hours
            last_name
            lunch_paid
            minutes
            note
            reports_to
            schedule_designation_ID
            shift_group
            shift_ID
            shift_name
            shifts_lunch_paid
            shifts_shift_group
            tcrn_creator
            tcrn_creator_ID
            tcrn_date_created
            tcrn_date_updated
            tcrn_note
            tcrn_note_original
            tcrn_own_note
            tcrn_updater
            tcrn_updater_ID
            time_clock_record_note_ID
            time_clock_record_schedule_designation_ID
            time_scheduled_in
            time_scheduled_out
            time_scheduled_hours
            time_actual_in
            time_actual_out
            time_actual_hours
            time_pay_in
            time_pay_out
            time_pay_hours
            user_ID
        }
    }
`;

// Get Employee TCRAs for single employee
export const GetEmployeeTCRAs = gql`
    query GetEmployeeTCRAs($date_scheduled_end: DateTime, $date_scheduled_start: DateTime, $user_ID: Int) {
        employee_TCRAs(date_scheduled_end: $date_scheduled_end, date_scheduled_start: $date_scheduled_start, user_ID: $user_ID) {
            time_clock_records_actual_ID
            time_clock_record_ID
            time_actual_in
            time_actual_out
            time_actual_hours
            time_pay_in
            time_pay_out
            time_pay_hours
            approved
            approver_ID
            archiver_ID
            came_early
            date_archived
            late
            left_early
            left_late
            user_ID            
        }
    }
`;



export const GetEmployeeSchedulePage = gql`
    query GetEmployeeSchedule {
        schedule_designations {
            schedule_designation_ID
            schedule_designation
        }
        shifts {
            shift_ID,
            active,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            break_end_1,
            break_end_2,
            break_start_1,
            break_start_2,
            lunch_end,
            lunch_start,
            lunch_paid,
            note,
            shift_end,
            shift_group,
            shift_hours_worked,
            shift_name,
            shift_start
        }
    }
`;

export const GetTimeClockRecordsPage = gql`
    query GetTimeClockRecords {
        schedule_designations {
            schedule_designation_ID
            schedule_designation
        }
        shifts {
            shift_ID,
            active,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            break_end_1,
            break_end_2,
            break_start_1,
            break_start_2,
            lunch_end,
            lunch_start,
            lunch_paid,
            note,
            shift_end,
            shift_group,
            shift_hours_worked,
            shift_name,
            shift_start
        }
        work_schedules {
            work_schedule_ID,
            active,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            description,
            sunday1_shift_ID,
            monday1_shift_ID,
            tuesday1_shift_ID,
            wednesday1_shift_ID,
            thursday1_shift_ID,
            friday1_shift_ID,
            saturday1_shift_ID,
            sunday2_shift_ID,
            monday2_shift_ID,
            tuesday2_shift_ID,
            wednesday2_shift_ID,
            thursday2_shift_ID,
            friday2_shift_ID,
            saturday2_shift_ID,
            note
        }
        users_scheduled {
            user_ID,
            active,
            department_ID,
            first_name,
            last_name,
            position_ID,
            reports_to_first_name,
            reports_to_last_name,
            reports_to_user_ID,
            user_name,
            user_type_ID,
            work_schedule_ID
        }
    }
`;

// Single Save TCR
export const SaveTimeClockRecord = gql`
	mutation timeClockRecordSave($input: TimeClockRecordInput) {
		timeClockRecordSave (input: $input) {
            TimeClockRecords {
                time_clock_record_ID
                clockin_type_ID
                archiver_ID
                creator_ID
                date_archived
                date_created
                date_scheduled
                date_scheduled_in
                date_scheduled_out
                time_scheduled_in
                time_scheduled_out
                time_scheduled_hours
                time_actual_in
                time_actual_out
                time_actual_hours
                time_pay_in
                time_pay_out
                time_pay_hours
                approved
                approver_ID
                lunch_paid
                note
                reports_to
                shift_group
                shift_ID
                tcrn_creator
                tcrn_creator_ID
                tcrn_date_created
                tcrn_date_updated
                tcrn_note
                tcrn_note_original
                tcrn_own_note
                tcrn_updater
                tcrn_updater_ID
                time_clock_record_note_ID
                TCRAs {
                    time_clock_records_actual_ID
                    time_clock_record_ID
                    time_actual_in
                    time_actual_out
                    time_actual_hours
                    time_pay_in
                    time_pay_out
                    time_pay_hours
                    approved
                    approver_ID
                    archiver_ID
                    came_early
                    date_archived
                    late
                    late_excuser_ID
                    left_early
                    left_early_excuser_ID
                    left_late
                    user_ID            
                }
            }
		}
	}
`;

// Multiple Records Save
export const SaveTimeClockRecords = gql`
	mutation timeClockRecordsSave($input: TimeClockRecordsInput) {
		timeClockRecordsSave (input: $input) {
            TimeClockRecords {
                time_clock_record_ID
                clockin_type_ID
                archiver_ID
                creator_ID
                date_archived
                date_created
                date_scheduled
                time_scheduled_in
                time_scheduled_out
                time_scheduled_hours
                time_actual_in
                time_actual_out
                time_actual_hours
                time_pay_in
                time_pay_out
                time_pay_hours
                approved
                approver_ID
                lunch_paid
                note
                reports_to
                shift_group
                shift_ID
            }
		}
	}
`;

// Single Save TCRA
export const SaveTimeClockRecordActual = gql`
	mutation timeClockRecordActualSave($input: TimeClockRecordActualInput) {
		timeClockRecordActualSave (input: $input) {
            TimeClockRecordsActual {
                time_clock_records_actual_ID
                time_clock_record_ID
                time_actual_in
                time_actual_out
                time_actual_hours
                time_pay_in
                time_pay_out
                time_pay_hours
                approved
                approver_ID
                archiver_ID
                came_early
                date_archived
                late
                late_excuser_ID
                left_early
                left_early_excuser_ID
                left_late
                user_ID            
            }
		}
	}
`;


// Save into time_clock_records_schedule_designations
export const SaveTimeClockRecordScheduleDesignation = gql`
	mutation timeClockRecordScheduleDesignationSave($input: TimeClockRecordScheduleDesignationInput) {
		timeClockRecordScheduleDesignationSave (input: $input) {
            TimeClockRecordScheduleDesignation {
                time_clock_record_schedule_designation_ID
                time_clock_record_ID
                schedule_designation_ID
                hours
                minutes
            }
		}
	}
`;

