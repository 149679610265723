import React, { useState } from 'react';
// import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {flowRight as compose} from 'lodash';
import { queryWithLoading } from '../utilities/NSDb.js';
import { useAuthUser } from 'react-auth-kit';
import dayjs from 'dayjs';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
import { useMutation, useLazyQuery } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// GRAPHQL QUERY GetMachineStatuesChanges
import { GetChecksheetProcesses } from '../checksheets/checksheets_graphql.js';
import { GetControlPanelPage } from './control_panel_graphql.js';
import { GetControlPanelPlan } from '../control_plans/control_plans_graphql.js';
import { SaveDowntime } from '../downtimes/downtimes_graphql.js';
import { GetMachineBulletin, GetMachineBulletins, SaveMachineBulletin } from '../machine_bulletins/machine_bulletins_graphql.js';
import { GetMachineStatusChanges, SaveMachineStatusChange } from '../machine_statuses_changes/machine_statuses_changes_graphql.js';
// GetMachineStatusChange, 
import { GetMachineOperators, SaveMachineOperator } from '../machines_operators/machines_operators_graphql.js';
import { SaveMachine } from '../machines/machines_graphql.js';
import { GetOperatorProductionDetails } from '../production_records/production_records_graphql.js';


// import { 
//     // GetPartsComponentData,
//     SavePart
// } from '../parts/parts_graphql.js';
// import { SaveMaterialBOM } from '../materials/materials_graphql.js';
import { GetOperators } from '../users/users_graphql.js';

import { ChecksheetComponent } from '../checksheets/checksheet_component.js'
import { OperatorProductionComponent } from './operator_production_component.js'
// import { parse } from 'graphql';

// DOCS: https://www.apollographql.com/docs/react/get-started/
// Import everything needed to use the `useQuery` hook
// import { useQuery, useMutation } from '@apollo/client';

function ControlPanelContent(props) {
    const auth = useAuthUser();

    // Set const values from props
    const { downtime_reasons: DowntimeReasons, machine_statuses: MachineStatuses, parts: Parts, shifts: Shifts, tooling: Tooling, users: Users } = props.componentData;

    // let TempParts = [...props.componentData.parts];
    // let TempParts = props.componentData.parts.map((p) => {
    //     let TempMaterialBOM = MaterialBOM.filter((mb) => parseInt(mb.part_ID) === parseInt(p.part_ID));
    //     let TempToolingBOM = ToolingBOM.filter((tb) => parseInt(tb.part_ID) === parseInt(p.part_ID));
    //     return { ...p, MaterialBOM: TempMaterialBOM, ToolingBOM: TempToolingBOM };
    // });

        const [get_checksheet_processes] = useLazyQuery(GetChecksheetProcesses);
        const [get_machine_bulletin] = useLazyQuery(GetMachineBulletin, { fetchPolicy: 'network-only'});
        const [get_machine_bulletins] = useLazyQuery(GetMachineBulletins, { fetchPolicy: 'network-only'});
        const [get_machine_operators] = useLazyQuery(GetMachineOperators, { fetchPolicy: 'network-only'});
        const [get_machine_status_changes] = useLazyQuery(GetMachineStatusChanges, { fetchPolicy: 'network-only'});
        const [get_operators] = useLazyQuery(GetOperators);
        const [get_operator_production_details] = useLazyQuery(GetOperatorProductionDetails, { fetchPolicy: 'network-only'});
        const [get_control_panel_plan] = useLazyQuery(GetControlPanelPlan);
        
        const [save_downtime] = useMutation(SaveDowntime);
        const [save_machine_bulletin] = useMutation(SaveMachineBulletin);
        const [save_machine] = useMutation(SaveMachine);
        const [save_machine_operator] = useMutation(SaveMachineOperator);
        const [save_machine_status_change] = useMutation(SaveMachineStatusChange);
        
        // const [save_material_BOM] = useMutation(SaveMaterialBOM);
        // const [save_part] = useMutation(SavePart);

        const ZincHourly = [
            {
                machine_ID: 56,
                parts: [
                    { part_ID: 10, hourly: 60 },
                    { part_ID: 11, hourly: 55 },
                    { part_ID: 5, hourly: 55 },
                    { part_ID: 7, hourly: 45 }
                ]
            },
            {
                machine_ID: 57,
                parts: [
                    { part_ID: 10, hourly: 70 },
                    { part_ID: 11, hourly: 70 },
                    { part_ID: 5, hourly: 55 },
                    { part_ID: 7, hourly: 60 }
                ]
            }
        ]

        const DefaultChecksheet = {
            ChecksheetProcesses: [],
            checksheet_ID: 0,
            control_plan_ID: 0,
            control_plan_number: '',
            inspection_step: '',
            instructions: '',
            last_checksheet_creator_ID: 0,
            last_checksheet_date_created: '',
            note: '',
            operation_name: '',
            operation_number: '',
            part_ID: 0,
            part_number: '',
            required_every: '',
            responsibility: ''        
        };

        const DefaultDowntime = {
            date_scheduled: props.date_scheduled,
            downtime_ID: 0,
            archiver_ID: 0,
            creator_ID: 0,
            date_archived: '',
            date_created: '',
            downtime_hours: 0,
            downtime_minutes: 0,
            downtime_reason_ID: 0,
            machine_ID: 0,
            notes: '',
            save_button_text: 'Save',
            save_message: '',
            shift_ID: 0,
            time_clock_record_ID: 0
        };

        const DefaultMachine = {
            Checksheets: [],
            hourly_standard_rate: '',
            machine_ID: 0,
            machine_status_ID: 0,
            MachineOperators: [],
            part_ID: 0,
            part_number: '',
            ProductionDetails: {
                Downtimes: [],
                ProductionRecords: [],
                ToolingUsages: []
            },
            remove_machine_operator_ID: 0,
            StatusChanges: []
        };

        let DefaultMachineBulletin = {
            editing: false,
            machine_ID: 0,
            machine_bulletin_ID: 0,
            note: '',
            old_note: ''
        };

        // If there is a SelectedMachine or a User's last open machine, grab the status of that machine and set it as the default
        if ( MachineStatuses.length > 0 ) {

        }

        const [SelectedChecksheet, setSelectedChecksheet] = useState({ ...DefaultChecksheet });
        const [SelectedDowntime, setSelectedDowntime] = useState({ ...DefaultDowntime });
        const [SelectedMachineBulletin, setSelectedMachineBulletin] = useState({ ...DefaultMachineBulletin });
        const [SelectedMachine, setSelectedMachine] = useState({ ...DefaultMachine });
        // const [SelectedPart, setSelectedPart] = useState({ ...DefaultSelectedPart });
        // const [SelectedTooling, setSelectedTooling] = useState({ ...DefaultSelectedTooling });

        // For Clock
        // useEffect(() => {
        //     const interval = setInterval(() => setValue(new Date()), 1000);
        
        //     return () => {
        //       clearInterval(interval);
        //     };
        //   }, []);

        // Filter down the Machines in the Workcenter drop down for the Control Panel
        let TempMachines = props.componentData.machines;
        if ( TempMachines.length > 0 ) {
            TempMachines = TempMachines.filter((M) => parseInt(M.archiver_ID) === 0);

            if(TempMachines.length > 0) {
                TempMachines.sort(function(a,b) {
                    if(a.machine_short_name.toLowerCase() < b.machine_short_name.toLowerCase()) return -1;
                    if(a.machine_short_name.toLowerCase() > b.machine_short_name.toLowerCase()) return 1;
                    return 0;
                });
                TempMachines = TempMachines.sort(function(a,b) {
                    if(a.machine_type_ID < b.machine_type_ID) return -1;
                    if(a.machine_type_ID > b.machine_type_ID) return 1;
                    return 0;
                });
            }
        }
            
        const [BaseState, setBaseState] = useState({
            action: '',
            first_load: true,
            MachineStatusChange: {},
            Machines: TempMachines,
            Operators: [],
            Robots: TempMachines.length > 0 ? TempMachines.filter((m) => parseInt(m.machine_type_ID) === 2) : [],
            selected_operator_ID: 0,
            selected_part_ID: 0,
            Tooling: Tooling
        });

    // TO DO: Figure out how to load an operators machine on a page refresh
    if(BaseState.first_load) {
        let TempUserMachines = props.componentData.machines_operators.filter((mo) => parseInt(mo.user_ID) === parseInt(auth().User.user_ID));
        if(TempUserMachines.length > 0) {
            // Do a machine load here
            setBaseState({...BaseState, first_load: false})
            handleMachineChange('machine_ID', parseInt(TempUserMachines[0].machine_ID))
        
        }
    }
console.log("Machines: ", BaseState.Machines)
    // Update Machine state values - initially just selecting the machine for display on the control panel
    async function handleMachineChange(name, value) {
        // For changing the machine, need to update all of the information in the control panel that is machine specific.
        if ( name === 'machine_ID' && parseInt(value) > 0) {

            // Update the selected bulletin for this machine - this gets only the most recent Bulletin for this Machine
            let TempBulletin = await get_machine_bulletin({ variables: { machine_ID: parseInt(value) } });

            if ( TempBulletin.data && TempBulletin.data.machine_bulletin ) {
                if ( TempBulletin.data.machine_bulletin.length > 0 ) {
                    setSelectedMachineBulletin({ ...TempBulletin.data.machine_bulletin[0], editing: false });
                } else {
                    setSelectedMachineBulletin({ ...DefaultMachineBulletin });
                }
            }

            // Get the most update to date MachinesOperators record for this Machine
            let TempMachineOperators = await get_machine_operators({ variables: { machine_ID: parseInt(value), ender_ID: 0 } });

            // Change SelectedMachine
            let TempMachine = BaseState.Machines.filter((m) => parseInt(m.machine_ID) === parseInt(value))[0];

            // Add Part data to machine
            let TempPart = Parts.filter((p) => parseInt(p.part_ID) === parseInt(TempMachine.part_ID))[0];
            TempMachine = { ...TempMachine, ...TempPart };

            let TempChecksheets = await get_control_panel_plan({ variables: { part_ID: parseInt(TempMachine.part_ID), machine_ID: parseInt(value) } });

            let TempChecksheetList = TempChecksheets.data && TempChecksheets.data.control_panel_plans ? TempChecksheets.data && TempChecksheets.data.control_panel_plans : [];

            // Get the status changes for this machine that are the same status as the current status
            let TempStatusChanges = await get_machine_status_changes({ variables: { machine_ID: parseInt(value) } })

            // Get how much time is left on the checksheet based on the TempChecksheets' date_last_completed and how long the production statuses have been running since then.
            if( TempChecksheets.data && TempChecksheets.data.control_panel_plans.length > 0 && TempStatusChanges.data && TempStatusChanges.data.machine_status_changes.length > 0 ) {

                // Filter to only do this for checksheets with a required_every column value
                TempChecksheetList = TempChecksheetList.map((cs) => {
                    let temp_last_checksheet_completed = null;
                    let temp_next_checksheet_due_minutes = '';
                    let temp_next_checksheet_due_time = '';
                    let temp_diff = 0;
                    if(cs.last_checksheet_date_created !== null) {
                        temp_last_checksheet_completed = dayjs(cs.last_checksheet_date_created).format('YYYY-MM-DD HH:mm:ss');
                        // Compare this to how long we have been in production since then. Get Production status => last checksheet
                        let TempProductionStatuses = TempStatusChanges.data.machine_status_changes.filter((sc) => parseInt(sc.machine_status_ID) === 3 && (dayjs(sc.date_end).format('YYYY-MM-DD HH:mm:ss') >= temp_last_checksheet_completed || sc.date_end === null));

                        // Determine length of each ProductionStatus that has date_start > temp_last_checksheet_completed, and for any that started after that, get the length from last checksheet to end of status
                        // let temp_production_time_since_last_checksheet = 0;
                        TempProductionStatuses.forEach((ps) => {
                            let temp_start = dayjs(ps.date_start).format('YYYY-MM-DD HH:mm:ss');
                            // let temp_end = dayjs(ps.date_end).format('YYYY-MM-DD HH:mm:ss');
                            if (  temp_start > temp_last_checksheet_completed && ps.date_end !== null) {
                                let temp_new_diff = dayjs(ps.date_end).diff(dayjs(ps.date_start), 'minute');
                                temp_diff = temp_diff+temp_new_diff;
                            } 
                            else if (temp_start > temp_last_checksheet_completed && ps.date_end === null) {
                                let temp_new_diff = dayjs().diff(dayjs(ps.date_start), 'minute');
                                temp_diff = temp_diff+temp_new_diff;
                            }
                        })

                        // Substract the determined minutes that we have been in production from the required_every to get the next_checksheet_due value
                        temp_next_checksheet_due_minutes = cs.required_every-temp_diff;
                        // Add these minutes to now 
                        temp_next_checksheet_due_time = dayjs().add(temp_next_checksheet_due_minutes, 'minute').format('M-D-YY h:mm A');

                    }
                    return { ...cs, next_checksheet_due_minutes: temp_next_checksheet_due_minutes, next_checksheet_due_time: temp_next_checksheet_due_time}
                });
            }

            let TempOperatorProductionDetails = await get_operator_production_details({ variables: { active: 1, date_scheduled: props.date_scheduled, machine_ID: parseInt(value), shift_ID: parseInt(auth().User.shift_ID), time_clock_record_ID: parseInt(auth().User.time_clock_record_ID), user_ID: parseInt(auth().User.user_ID) }  });

            // For Z4 and Zinc Inspection, calculate their hourly std based on the part that the machine is running:
            if(parseInt(value) === 56 || parseInt(value) === 57) {
                let TempHourly = ZincHourly.filter((machine) => parseInt(machine.machine_ID) === parseInt(value));
                if (TempHourly.length > 0) {
                    let PartHourly = TempHourly[0].parts.filter((part) => parseInt(part.part_ID) === parseInt(TempPart.part_ID));
                    TempMachine.hourly_standard_rate = PartHourly.length === 1 ? PartHourly[0].hourly : TempMachine.hourly_standard_rate
                }
            }
        
            setSelectedMachine({
                ...SelectedMachine,
                ...TempMachine,
                Checksheets: TempChecksheetList,
                MachineOperators: TempMachineOperators.data.machines_operators,
                ProductionDetails: {
                    Downtimes: TempOperatorProductionDetails.data.downtimes_control_panel,
                    ProductionRecords: TempOperatorProductionDetails.data.production_records_control_panel,
                    ToolingUsages: TempOperatorProductionDetails.data.tooling_usages
                },
                StatusChanges: TempStatusChanges.data.machine_status_changes
            });

        }

    }

    // Update Machine's Bulletin
    function changeSelectedMachineBulletinInputValue(value) {
        setSelectedMachineBulletin({
            ...SelectedMachineBulletin,
            note: value
        });
    }

    // Update the selected machine's status
    async function handleMachineStatusChange(MachineStatus) {

        // Only save the change once user has confirmed it.
        if ( BaseState.MachineStatusChange === MachineStatus) {
            // Save machines record with new machine_status_ID
            const result = await save_machine({ variables: { input: { machine_ID: parseInt(SelectedMachine.machine_ID), machine_status_ID: parseInt(MachineStatus.machine_status_ID) } }});

            // Save machine_statuses_changes record with new machine_status_ID, end the old status change log
            await save_machine_status_change({ variables: { input: { machine_ID: parseInt(SelectedMachine.machine_ID), machine_status_ID: parseInt(MachineStatus.machine_status_ID), user_ID: parseInt(auth().User.user_ID) } }});

            // Update SelectedMachine and BaseState.Machines with the new status
            setSelectedMachine({
                ...SelectedMachine,
                machine_status_ID: parseInt(result.data.machineSave.UpdatedMachine.machine_status_ID)
            });

            let TempMachines = [...BaseState.Machines];

            TempMachines = TempMachines.map((m) => {
                if ( parseInt(m.machine_ID) === parseInt(SelectedMachine.machine_ID) ) {
                    return { ...m, machine_status_ID: parseInt(result.data.machineSave.UpdatedMachine.machine_status_ID) }
                } else {
                    return m;
                }
            });

            setBaseState({
                ...BaseState,
                MachineStatusChange: '',
                Machines: TempMachines
            })
        } else {
            setBaseState({
                ...BaseState,
                MachineStatusChange: MachineStatus
            })
        }

    }

    // Handle opening checksheet inside of Checksheets card
    async function handleOpenChecksheet(checksheet_ID) {

        if ( parseInt(checksheet_ID) > 0) {
            // Get the checksheet / processes
            const checksheet_result = await get_checksheet_processes({ variables: { checksheet_ID: parseInt(checksheet_ID), active: 1 } });

            if ( checksheet_result.data && checksheet_result.data.checksheet_processes ) {
                let TempChecksheet = SelectedMachine.Checksheets.filter((c) => parseInt(c.checksheet_ID) === parseInt(checksheet_ID))[0];
                // Update the SelectedChecksheet
                setSelectedChecksheet({
                    ...SelectedChecksheet,
                    ...TempChecksheet,
                    ChecksheetProcesses: checksheet_result.data.checksheet_processes.ChecksheetProcesses
                });

                //  ...checksheet_result.data.checksheet_processes.Checksheet

            }
        } else {
            setSelectedChecksheet({ ...DefaultChecksheet });
        }
    }

    // Add new Operator to the Machine - get a fresh list of logged in Operators
    async function handleAddOperatorClick(action) {

        if(action === 'add' ) {
            // Get the most update to date logged in Operators
            let TempOperators = await get_operators({ variables: { logged_in: 1, user_type_ID: 2 } });

            // Add Logged in Operators to the BaseState
            setBaseState({
                ...BaseState,
                Operators: TempOperators.data.logged_in_operators
            });

        } else if ( action === 'save' ) {

            let result = await save_machine_operator({ variables: { input: { machine_ID: parseInt(SelectedMachine.machine_ID), starter_ID: parseInt(auth().User.user_ID), user_ID: parseInt(BaseState.selected_operator_ID) } }});

            // Add this operator into the SelectedMachine's MachineOperators array
            if ( result.data && result.data.machineOperatorSave.MachineOperators ) {

                setSelectedMachine({
                    ...SelectedMachine,
                    MachineOperators: result.data.machineOperatorSave.MachineOperators
                });
            }

            // Clear out Operators in the BaseState
            setBaseState({
                ...BaseState,
                Operators: [],
                selected_operator_ID: 0
            });
        } else if ( action === 'close' ) {
            setBaseState({
                ...BaseState,
                Operators: []
            });
        }
    }

    // Show option to remove the Operator from the Machine
    async function handleOperatorClick(MachineOperator, action) {
        if ( action === 'remove' ) {
            setSelectedMachine({
                ...SelectedMachine,
                remove_machine_operator_ID: parseInt(MachineOperator.user_ID)
            });
        } else if ( action === 'save' ) {
            let result = await save_machine_operator({ variables: { input: { machine_ID: parseInt(SelectedMachine.machine_ID), ender_ID: parseInt(auth().User.user_ID), user_ID: parseInt(SelectedMachine.remove_machine_operator_ID) } }});

            // Remove this operator into the SelectedMachine's MachineOperators array
            if ( result.data && result.data.machineOperatorSave.MachineOperators ) {

                setSelectedMachine({
                    ...SelectedMachine,
                    MachineOperators: result.data.machineOperatorSave.MachineOperators,
                    remove_machine_operator_ID: 0
                });
            }

        } else if ( action === 'cancel' ) {
            setSelectedMachine({
                ...SelectedMachine,
                remove_machine_operator_ID: 0
            });
        }

    }

    // Save Machine's Bulletin - If the bulletin was empty, save the new bulletin note into the machine_bulletin_ID that had the empty note.
    // Otherwise, create a new record with the new note, and save the new machine_bulletin_ID into the old record's replaced_by_machine_bulletin_ID column.
    // If 'clear' was clicked, create a new blank note and record, saving the old record's ID on the replaced column
    async function saveMachineBulletin(action='') {

        let SaveData = {...SelectedMachineBulletin};
        if ( action === 'save_bulletin' ) {

            // Parse string values that are IDs to be integers, saving stringified numbers to the database throws an error.
            let MakeIntegers = ['machine_ID', 'machine_bulletin_ID', 'replaced_by_machine_bulletin_ID'];
            MakeIntegers.forEach((mi) => SaveData[mi] = parseInt(SaveData[mi]));

            const result = await save_machine_bulletin({ variables: { input: { ..._.omit(SaveData, ['editing', 'first_name', 'last_name', 'old_note']), creator_ID: parseInt(auth().User.user_ID), date_created: dayjs().format('YYYY-MM-DD HH:mm:ss'), machine_ID: parseInt(SelectedMachine.machine_ID)} }});

            // Update the MachineBulletin with this new data
            if ( result.data && result.data.machineBulletinSave.MachineBulletin ) {
                setSelectedMachineBulletin({
                    ...result.data.machineBulletinSave.MachineBulletin,
                    editing: false
                });
            }

        }
    }

    // Save Downtime Form
    async function saveDowntime() {
        let SaveData = { ...SelectedDowntime, creator_ID: parseInt(auth().User.user_ID), date_scheduled: auth().User.date_scheduled, machine_ID: parseInt(SelectedMachine.machine_ID), shift_ID: parseInt(auth().User.shift_ID), time_clock_record_ID: parseInt(auth().User.time_clock_record_ID)  };

        let MakeIntegers = ['downtime_reason_ID'];
        MakeIntegers.forEach((mi) => SaveData[mi] = parseInt(SaveData[mi]));

        let result = await save_downtime({ variables: { input: _.omit(SaveData, ['save_button_text', 'save_message']) } })
        let TempDowntimes = [ ...SelectedMachine.ProductionDetails.Downtimes ];
        // Push this result to the Downtimes array

        if ( result.data && result.data.downtimeSave.UpdatedDowntime ) {
            TempDowntimes.push(result.data.downtimeSave.UpdatedDowntime) 

            // Reset the SelecteDowntime state
            setSelectedDowntime({
                DefaultDowntime
            });

            // Update the status change in the UI
            // Save machines record with new machine_status_ID
            const result_machine = await save_machine({ variables: { input: { machine_ID: parseInt(SelectedMachine.machine_ID), machine_status_ID: parseInt(BaseState.MachineStatusChange.machine_status_ID) } }});

            // Save machine_statuses_changes record with new machine_status_ID, end the old status change log
            await save_machine_status_change({ variables: { input: { machine_ID: parseInt(SelectedMachine.machine_ID), machine_status_ID: parseInt(BaseState.MachineStatusChange.machine_status_ID), user_ID: parseInt(auth().User.user_ID) } }});

            // Update SelectedMachine and BaseState.Machines with the new status
            // Push this new Downtime to the SelectedMachine
            setSelectedMachine({
                ...SelectedMachine,
                machine_status_ID: parseInt(result_machine.data.machineSave.UpdatedMachine.machine_status_ID),
                ProductionDetails: {
                    ...SelectedMachine.ProductionDetails,
                    Downtimes: TempDowntimes
                }
            });

            let TempMachines = [...BaseState.Machines];

            TempMachines = TempMachines.map((m) => {
                if ( parseInt(m.machine_ID) === parseInt(SelectedMachine.machine_ID) ) {
                    return { ...m, machine_status_ID: parseInt(result_machine.data.machineSave.UpdatedMachine.machine_status_ID) }
                } else {
                    return m;
                }
            });

            setBaseState({
                ...BaseState,
                MachineStatusChange: '',
                Machines: TempMachines
            })
        }

    }

    // Handler function for returning a saved Checksheet Record from the checksheet_component
    function handleChecksheetRecordSave(ChecksheetRecord) {

        // Update the SelectedMachine's Checksheets with the new last date and user info
        let TempChecksheets = [ ...SelectedMachine.Checksheets ];
        TempChecksheets = TempChecksheets.map((c) => {
            if ( parseInt(c.checksheet_ID) === parseInt(ChecksheetRecord.checksheet_ID) ) {
                return { ...c, date_last_completed: ChecksheetRecord.date_created, user_ID_last_completed: parseInt(ChecksheetRecord.creator_ID) };
            } else {
                return c;
            }
        });

        setSelectedMachine({
            ...SelectedMachine,
            Checksheets: TempChecksheets
        });

        // Reset the SelectedChecksheet to the default so it closes the Checksheet container
        setSelectedChecksheet({ ...DefaultChecksheet });
    
    }

    // Handler function for updating the part_ID on the selected machine.
    async function handleChangePart() {

        let TempOffStatus = MachineStatuses.filter((ms) => ms.machine_status === 'Off')[0];

        // First Update the machine's part_ID
        await save_machine({ variables: { input: { machine_ID: parseInt(SelectedMachine.machine_ID), part_ID: parseInt(BaseState.selected_part_ID), machine_status_ID: parseInt(TempOffStatus.machine_status_ID) } }});

        // Save machine_statuses_changes record with new machine_status_ID, end the old status change log
        await save_machine_status_change({ variables: { input: { machine_ID: parseInt(SelectedMachine.machine_ID), machine_status_ID: parseInt(TempOffStatus.machine_status_ID), user_ID: parseInt(auth().User.user_ID) } }});

        setBaseState({
            ...BaseState,
            action: 'part_changed',
            selected_part_ID: 0
        });

    }

    // For tooling bom, need to add tooling station, pieces per edge, and tool selection 
    // material bom - requirement per piece input, raw material selection.
    if ( BaseState.action === 'part_changed' ) {
        return (
            <div className="alert alert-success">Part successfully changed. Please reload this page so the Control Panel can accurate display the new details.</div>
        )
    } else {

        // Set the bg color of this page based on the machine status
        let bg_color = 'bg-secondary';
        MachineStatuses.forEach((ms) => {
            if(parseInt(SelectedMachine.machine_status_ID) === parseInt(ms.machine_status_ID)) {
                // Add in conditions for color statuses
                if(ms.machine_status === 'Production')bg_color = 'bg-success';
                else if(ms.machine_status === 'Idle')bg_color = 'bg-warning';
                else if(ms.machine_status === 'Off')bg_color = 'bg-warning';
                else if(ms.machine_status === 'Downtime')bg_color = 'bg-danger';
            }
        });
        return (
            <React.Fragment>
                <div className={`${bg_color} p-4 rounded`}>
                    <div className="row">
                        <div className="col-3">
                            <div className="card h-100">
                                <div className="card-header h4 text-center">
                                    Workcenter
                                </div>
                                <div className="card-body">
                                <div className="form-floating">
                                        <select 
                                            className="form-select mb-3" 
                                            disabled={false}
                                            id="Machine"
                                            onChange={(event) => handleMachineChange('machine_ID', event.target.value)}
                                            placeholder="Machine Name"
                                            value={SelectedMachine.machine_ID}
                                        >
                                            <option key={0} value={0}>Select Machine</option>
                                            {BaseState.Machines.map((m) => {
                                                if (m.machine_short_name !== '' && m.machine_short_name !== null) {
                                                    return <option key={m.machine_ID} value={m.machine_ID}>{m.machine_short_name} - {m.machine_name}</option>
                                                } else {
                                                    return <option key={m.machine_ID} value={m.machine_ID}>{m.machine_name}</option>
                                                }
                                            })}
                                        </select>
                                        <label className="text-muted" htmlFor="Machine">Machine</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card h-100">
                                <div className="card-header h4">
                                    Setup
                                    { parseInt(SelectedMachine.machine_ID) > 0 && <button className="btn float-end border-primary border-3 text-primary py-0" disabled={ BaseState.action === 'change_part' } onClick={() => setBaseState({ ...BaseState, action: 'change_part'})}><FontAwesomeIcon icon="fa-gear" className="me-2" />Change Part</button> }
                                </div>
                                <div className="card-body">
                                    {
                                        parseInt(SelectedMachine.machine_ID) > 0 && BaseState.action !== 'change_part' &&
                                        <span className="text-center">
                                            <div className="fw-bolder h2">{SelectedMachine.part_number}</div>
                                            <div className="fw-bolder h4">{SelectedMachine.description}</div>
                                            {SelectedMachine.hourly_standard_rate !== '' && SelectedMachine.hourly_standard_rate !== null && <div className="">{SelectedMachine.hourly_standard_rate} / hour</div>}
                                            {/* Custome Hourly - Temp: TO DO - need to make it so part_ID can have different hourly on different machines. Need a new table machines_parts for this. */}
                                        </span>
                                    }
                                    {
                                        parseInt(SelectedMachine.machine_ID) > 0 && BaseState.action === 'change_part' &&
                                        <>
                                            <div className="clearfix">
                                                <div className="form-floating w-100">
                                                    <select 
                                                        className="form-select" 
                                                        disabled={false}
                                                        id="part_ID"
                                                        onChange={(event) => setBaseState({ ...BaseState, selected_part_ID: event.target.value })}
                                                        placeholder="Part"
                                                        value={BaseState.selected_part_ID}
                                                    >
                                                        <option key={0} value={0}>Select New Part</option>
                                                        {Parts.filter((p) => p.archiver_ID === null || (p.archiver_ID !== null && p.archiver_ID !== '' && parseInt(p.archiver_ID) === 0 ) ).map((p) => {
                                                            return (
                                                                <option key={p.part_ID} value={p.part_ID}>{p.part_number}: {p.description}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    <label className="text-muted" htmlFor="part_ID">Change {(SelectedMachine.machine_short_name !== '' && SelectedMachine.machine_short_name !== null && SelectedMachine.machine_short_name) || SelectedMachine.machine_name} To Part</label>
                                                </div>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <button  
                                                            disabled={BaseState.selected_part_ID === '' || parseInt(BaseState.selected_part_ID) === 0}
                                                            className="btn mt-2 border-success border-3 fw-bold text-success float-start" 
                                                            onClick={() => handleChangePart()}>
                                                            <FontAwesomeIcon icon="fa-floppy-disk" className="me-2" />
                                                            Save Part Change
                                                        </button>
                                                    </div>
                                                    <div className="col">
                                                        <button  
                                                            className="btn mt-2 border-secondary border-3 fw-bold text-secondary float-end" 
                                                            onClick={() => setBaseState({ ...BaseState, action: ''})}>
                                                            <FontAwesomeIcon icon="fa-times" className="me-2" />
                                                            Cancel Part Change
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="card h-100">
                                <div className="card-header h4 text-center">
                                    Workcenter Status
                                </div>
                                <div className="card-body pb-0">
                                    <div className="row">
                                        {
                                            MachineStatuses.map((ms) => {
                                                let button_class = "btn btn-outline-secondary text-dark w-100";
                                                if(parseInt(SelectedMachine.machine_status_ID) === parseInt(ms.machine_status_ID)) {
                                                    // Add in conditions for color statuses
                                                    if(ms.machine_status === 'Production')button_class = `${button_class} bg-success`;
                                                    else if(ms.machine_status === 'Idle')button_class = `${button_class} bg-warning`;
                                                    else if(ms.machine_status === 'Off')button_class = `${button_class} bg-secondary`;
                                                    else if(ms.machine_status === 'Downtime')button_class = `${button_class} bg-danger`;
                                                }
                                                return (
                                                    <div className="col-6 mb-3" key={ms.machine_status_ID}>
                                                        <button
                                                            className={`${button_class}`} 
                                                            disabled={parseInt(SelectedMachine.machine_ID) === 0}
                                                            onClick={() => handleMachineStatusChange(ms)}>
                                                            {ms.machine_status}
                                                        </button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>                                    
                                </div>
                                {/* Card Footer options */}
                                {
                                    parseInt(SelectedMachine.machine_ID) === 0 &&
                                    <div className="card-footer text-danger text-center">
                                        Choose a Work Center to change the status
                                    </div>
                                }
                                {
                                    BaseState.MachineStatusChange.machine_status &&
                                    <>
                                    {
                                        BaseState.MachineStatusChange.machine_status === 'Downtime' &&
                                        <div className="card-footer bg-light">
                                            <div className="">
                                                <div className="form-floating mb-2">
                                                    <textarea style={{minHeight: 100+'px'}} className="form-control" id="downtime_record_notes" placeholder="Enter notes here" value={SelectedDowntime.notes} rows="2" onChange={(event) => setSelectedDowntime({ ...SelectedDowntime, notes: event.target.value })} />
                                                    <label className="text-muted" htmlFor="downtime_record_notes"><span className="text-danger">Required: </span>Note about Downtime Reason</label>
                                                </div>
                                                <div className="clearfix">
                                                    <span className="form-floating float-start">
                                                        <select 
                                                            className="form-select" 
                                                            disabled={false}
                                                            id="downtime_reason"
                                                            onChange={(event) => setSelectedDowntime({ ...SelectedDowntime, downtime_reason_ID: event.target.value })}
                                                            placeholder="Downtime Reason"
                                                            value={SelectedDowntime.downtime_reason_ID}
                                                        >
                                                            <option key={0} value={0}>Select Downtime Reason</option>
                                                            {DowntimeReasons.map((dr) => {
                                                                return (
                                                                    <option key={dr.downtime_reason_ID} value={dr.downtime_reason_ID}>{dr.downtime_reason}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        <label className="text-muted" htmlFor="downtime_reason"><span className="text-danger">Required: </span>Downtime Reason</label>
                                                    </span>
                                                    <button  
                                                        disabled={SelectedDowntime.notes === '' || parseInt(SelectedDowntime.downtime_reason_ID) === 0}
                                                        className="btn mt-2 border-success border-3 fw-bold text-success float-end" 
                                                        onClick={() => saveDowntime()}>
                                                        <FontAwesomeIcon icon="fa-floppy-disk" className="me-2" />
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        BaseState.MachineStatusChange.machine_status !== 'Downtime' &&
                                        <div className="card-footer bg-transparent d-flex align-items-center text-center">
                                            <button  
                                                className="btn border border-danger text-danger me-auto" 
                                                onClick={() => setBaseState({ ...BaseState, MachineStatusChange: {} })}>
                                                Cancel
                                            </button>
                                            Change to {BaseState.MachineStatusChange.machine_status}?
                                            <button  
                                                className="btn border border-success text-success ms-auto" 
                                                onClick={() => handleMachineStatusChange(BaseState.MachineStatusChange)}>
                                                Confirm
                                            </button>
                                        </div>
                                    }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    {   SelectedMachine.Checksheets && SelectedMachine.Checksheets.length > 0 &&
                        <div className="row mt-4">
                            <div className="col-12">
                                <div className="card h-100">
                                    <div className="card-header h4 text-center">
                                        { SelectedChecksheet.control_plan_number === '' && <>Control Plan Checksheets</> }
                                        { SelectedChecksheet.control_plan_number !== '' && <button className="btn fw-bold border-3 border-danger text-danger" onClick={() => handleOpenChecksheet(0)}>Close and Cancel Checksheet</button>}
                                    </div>

                                    {
                                        SelectedChecksheet.control_plan_number !== '' &&
                                        <div className="m-4">
                                            <ChecksheetComponent
                                                SelectedChecksheet={SelectedChecksheet}
                                                Machine={{ ..._.omit(SelectedMachine, ['Checksheets', 'StatusChanges', 'archiver_ID', 'creator_ID', 'date_archived', 'date_created', 'remove_machine_operator_ID']) }}
                                                handleChecksheetRecordSave={(ChecksheetRecord) => handleChecksheetRecordSave(ChecksheetRecord) }
                                            />
                                        </div>
                                    }
                                    {
                                        SelectedChecksheet.control_plan_number === '' &&
                                        <div className="card-body">
                                            {
                                                SelectedMachine.Checksheets && SelectedMachine.Checksheets.length > 0 &&
                                                <div className="card-group">

                                                    {/* Show all Checksheets */}
                                                    {SelectedMachine.Checksheets.map((c) => {

                                                        // Get the Latest record for this machine status so we can get the datetime for that and use this object multiple times below
                                                        let LatestMachineStatus = SelectedMachine.StatusChanges.filter((ms) => ms.date_end === null);
                                                        let LastChecksheetData = parseInt(c.last_checksheet_creator_ID) > 0 ? { User: Users.filter((u) => parseInt(u.user_ID) === parseInt(c.last_checksheet_creator_ID)), last_checksheet_date_created: c.last_checksheet_date_created } : { User: [], last_checksheet_date_created: '' };
                                                        return (
                                                            <div key={c.checksheet_ID} className="card border border-secondary" onClick={() => handleOpenChecksheet(c.checksheet_ID)}>
                                                                <div className="card-header h4 text-center">
                                                                    {c.inspection_step}
                                                                </div>
                                                                <div className="card-body text-center">
                                                                    <div>{c.part_number} - Op {c.operation_number}</div>
                                                                    {c.responsibility !== '' && c.responsibility !== null && <div><span className="text-muted">Responsibility:</span> {c.responsibility}</div>}
                                                                    {/* 
                                                                        {LastChecksheetData.User.length > 0 && <div className="fw-bold">{LastChecksheetData.User[0].last_name}, {LastChecksheetData.User[0].first_name}</div>}
                                                                        {LastChecksheetData.last_checksheet_date_created !== '' && <div className="">{dayjs(LastChecksheetData.last_checksheet_date_created).format('M-D-YY h:mm A')}</div>}
                                                                        If the machine status is Production and has a "required_every", then show the time that Production started and how many minutes are left. Otherwise show how often the checksheet is required
                                                                        {
                                                                            SelectedMachine.machine_status_ID > 0 &&
                                                                            (
                                                                                (
                                                                                    MachineStatuses.filter((ms) => parseInt(ms.machine_status_ID) === parseInt(SelectedMachine.machine_status_ID))[0].production_status === 1 &&
                                                                                    c.required_every !== '' && 
                                                                                    c.required_every !== null && 
                                                                                    (
                                                                                        (c.next_checksheet_due_minutes && c.next_checksheet_due_minutes !== "" && 
                                                                                            <>
                                                                                                <div className="fw-bold"><span className="text-muted">Time Remaining:</span> {c.next_checksheet_due_minutes} minutes</div>
                                                                                                <div className="fw-bold"><span className="text-muted">Due At:</span> {c.next_checksheet_due_time}</div>
                                                                                            </>
                                                                                        )
                                                                                        || 
                                                                                        // This code below may never get hit
                                                                                        (
                                                                                            LatestMachineStatus.length !== 1 &&
                                                                                            c.required_every !== '' && 
                                                                                            c.required_every !== null && 
                                                                                            <div className="fw-bold"><span className="text-muted">Required Every:</span> {c.required_every}</div>
                                                                                        )
                                                                                    )
                                                                                ) || (
                                                                                    (
                                                                                        c.required_every !== '' && 
                                                                                        c.required_every !== null && 
                                                                                        (
                                                                                            (c.next_checksheet_due_minutes && c.next_checksheet_due_minutes !== "" && <div className="fw-bold"><span className="text-muted">Time Remaining:</span> {c.next_checksheet_due_minutes} minutes</div>)
                                                                                            ||
                                                                                            <div className="fw-bold"><span className="text-muted">Required Every:</span> {c.required_every}</div>
                                                                                        )
                                                                                    ) || (
                                                                                        <div className="fw-bold">{c.note}</div>
                                                                                    )
                                                                                )
                                                                            )
                                                                        }
                                                                     */}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row mt-4">
                        <div className="col-12 mb-4">
                            <div className="card h-100">
                                <div className="card-header h4 text-center">
                                    Production History
                                </div>
                                <div className={`card-body`}>
                                    {/* This literally seems like the same thing as workcenter log, but a more detailed view. Likely combine this row into a single view of status changes.  */}
                                    {/* Operator must be on the machine to add PR details */}
                                    {   
                                        parseInt(SelectedMachine.machine_ID) > 0 && 
                                        SelectedMachine.MachineOperators.findIndex((mo) => parseInt(mo.user_ID) === parseInt(auth().User.user_ID)) > -1 &&
                                        <>
                                            <OperatorProductionComponent
                                                called_from="control_panel"
                                                date_scheduled={auth().User.date_scheduled}
                                                machine_ID={parseInt(SelectedMachine.machine_ID)}
                                                machine_name={SelectedMachine.machine_name}
                                                machine_short_name={SelectedMachine.machine_short_name}
                                                machine_type_ID={SelectedMachine.machine_type_ID}
                                                Operators={SelectedMachine.MachineOperators}
                                                Part={Parts.filter((p) => parseInt(p.part_ID) === parseInt(SelectedMachine.part_ID))}
                                                part_description={SelectedMachine.description}
                                                part_ID={parseInt(SelectedMachine.part_ID)}
                                                part_number={SelectedMachine.part_number}
                                                ProductionDetails={SelectedMachine.ProductionDetails}
                                                Robots={BaseState.Robots}
                                                shift_ID={auth().User.shift_ID}
                                                Shifts={Shifts}
                                                time_clock_record_ID={auth().User.time_clock_record_ID}
                                                User={auth().User}
                                                user_ID={parseInt(auth().User.user_ID)}
                                            />

                                            {/* DOWNTIME RECORD CONTENT */}
                                            <div className="row mt-4">
                                                <div className="h5 text-secondary pb-1">
                                                    {/* <span 
                                                        className={`col-auto border border-primary btn btn-sm text-primary me-2 mb-1 ${BaseState.action_downtime !== '' && 'disabled'}`}
                                                        onClick={() => handleButtonClicked('add_downtime', DefaultSelectedDowntime)}
                                                    >
                                                        <FontAwesomeIcon icon="fa-plus" size="lg" className="" />
                                                    </span> */}
                                                    Recorded Downtimes
                                                </div>

                                                {/* Downtime list */}
                                                {
                                                    SelectedMachine.ProductionDetails.Downtimes.length > 0 &&                                                                         
                                                    <div className="ms-2 me-3 mt-1">
                                                        <table className="table">
                                                            <thead className="mb-0">
                                                                <tr className="row me-2">
                                                                    <th className="col-2">Reason</th>
                                                                    <th className="col-1">Hours</th>
                                                                    <th className="col-1">Minutes</th>
                                                                    <th className="col-8">Note</th>
                                                                    {/* <th className="col-1"></th>
                                                                    <th className="col-1"></th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {/* {BaseState.action_downtime === 'add_downtime' &&
                                                                    <tr key={0} className="row me-2">
                                                                        <DowntimeFormContent 
                                                                            closeDowntime={(action) => handleButtonClicked('close_downtime')}
                                                                            DowntimeReasons={DowntimeReasons}
                                                                            saveDowntime={(Downtime) => saveDowntime(Downtime)}
                                                                            SelectedDowntime={SelectedDowntime}
                                                                        />
                                                                    </tr>
                                                                } */}
                                                                {SelectedMachine.ProductionDetails.Downtimes.map((dt, index) => {
                                                                    // if ( parseInt(dt.downtime_ID) === parseInt(SelectedDowntime.downtime_ID)) {
                                                                    //     return (
                                                                    //         <tr key={index} className="row me-2">
                                                                    //             <DowntimeFormContent 
                                                                    //                 closeDowntime={(action) => handleButtonClicked('close_downtime')}
                                                                    //                 DowntimeReasons={DowntimeReasons}
                                                                    //                 saveDowntime={(Downtime) => saveDowntime(Downtime)}
                                                                    //                 SelectedDowntime={SelectedDowntime}
                                                                    //             />
                                                                    //         </tr>
                                                                    //     )
                                                                    // } else {
                                                                        return (
                                                                            <tr key={index} className="row me-2">
                                                                                <td className="col-2"><span className="ps-1">{DowntimeReasons.filter((r) => parseInt(dt.downtime_reason_ID) === parseInt(r.downtime_reason_ID))[0].downtime_reason}</span></td>
                                                                                <td className="col-1"><span className="ps-1">{dt.downtime_hours}</span></td>
                                                                                <td className="col-1"><span className="ps-1">{dt.downtime_minutes}</span></td>
                                                                                <td className="col-8"><span className="ps-1">{dt.notes}</span></td>
                                                                                {/* <td className="col-1">
                                                                                    <button className="btn ms-1 text-primary border-primary" onClick={() => handleButtonClicked('edit_downtime', dt)}><FontAwesomeIcon icon="fa-pencil" className="" /></button>
                                                                                </td>
                                                                                <td className="col-1"></td> */}
                                                                            </tr>
                                                                        )
                                                                    // }
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    }
                                    {   
                                        parseInt(SelectedMachine.machine_ID) > 0 && 
                                        SelectedMachine.MachineOperators.findIndex((mo) => parseInt(mo.user_ID) === parseInt(auth().User.user_ID)) === -1 &&
                                        <div className="alert alert-info text-center">Logged in user {auth().User.first_name} {auth().User.last_name} must be in the Operators list below to add Production Details.</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* {
                            BaseState.action !== 'add_production_record' && 
                            <div className="col-4 mb-3">
                                <div className="card h-100">
                                    <div className="card-header h4 text-center">
                                        Workcenter Log
                                    </div>
                                    <div className="card-body text-center">
                                        TO DO: This will be a list of production records and status changes for this machine. 
                                        Specifically a log of each time the status changes, maybe add a link to the production record for each time it is production status.
                                    </div>
                                </div>
                            </div>
                        } */}
                        <div className="col-6">
                            <div className="card h-100">
                                <div className="card-header h4 text-center">
                                    Operators
                                </div>
                                <div className="card-body">
                                    {
                                        parseInt(SelectedMachine.machine_ID) > 0 &&
                                        <div className="">
                                            {
                                                SelectedMachine.MachineOperators.length > 0 &&
                                                SelectedMachine.MachineOperators.map((mo) => {
                                                    let remove_this_operator = parseInt(mo.user_ID) === parseInt(SelectedMachine.remove_machine_operator_ID);
                                                    return (
                                                        <div key={mo.machine_operator_ID} className="">
                                                            <button className="btn border border-success text-success mb-3" disabled={remove_this_operator} onClick={() => handleOperatorClick(mo, 'remove')}>{mo.first_name} {mo.last_name}</button>
                                                            { remove_this_operator && 
                                                                <div className="btn-group">
                                                                    <button className="btn border border-danger text-danger mb-3 ms-3" onClick={() => handleOperatorClick(mo, 'save')}>
                                                                        Confirm
                                                                    </button>
                                                                    <button className="btn border border-secondary mb-3" disabled={true}>
                                                                        Remove {mo.first_name} from {(SelectedMachine.machine_short_name !== '' && SelectedMachine.machine_short_name !== null && SelectedMachine.machine_short_name) || SelectedMachine.machine_name}?
                                                                    </button>
                                                                    <button className="btn border border-secondary mb-3" onClick={() => handleOperatorClick(mo, 'cancel')}>
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="">
                                                {
                                                    BaseState.Operators.length > 0 &&
                                                    <div className="">
                                                        <select 
                                                            className="form-select w-auto mb-3" 
                                                            disabled={false}
                                                            id="Operator"
                                                            onChange={(event) => setBaseState({ ...BaseState, selected_operator_ID: event.target.value }) }
                                                            placeholder="Operator Name"
                                                            value={BaseState.selected_operator_ID}
                                                        >
                                                            <option key={0} value={0}>Logged in Operators:</option>
                                                            {BaseState.Operators.map((o) => {
                                                                // Do not show operators already on this machine
                                                                if ( SelectedMachine.MachineOperators.findIndex((mo) => parseInt(mo.user_ID) === parseInt(o.user_ID)) > -1 ) {
                                                                    return false;
                                                                } else {
                                                                    return (
                                                                        <option key={o.user_ID} value={o.user_ID}>{o.first_name} {o.last_name}</option>
                                                                    )
                                                                }
                                                            })}
                                                        </select>
                                                        <button className="btn fw-bold border-3 border-success text-success" disabled={parseInt(BaseState.selected_operator_ID) === 0} onClick={() => handleAddOperatorClick('save')}>Save Operator</button>
                                                        <button className="btn fw-bold border-3 border-secondary text-secondary ms-3"  onClick={() => handleAddOperatorClick('close')}>Cancel</button>
                                                    </div>
                                                }
                                                {
                                                    BaseState.Operators.length === 0 &&
                                                    <button className="btn fw-bold border-3 border border-primary text-primary" disabled={parseInt(SelectedMachine.remove_machine_operator_ID) > 0} onClick={() => handleAddOperatorClick('add')}><FontAwesomeIcon icon="fa-plus" className="me-2" />Add an Operator</button>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                                {
                                    parseInt(SelectedMachine.machine_ID) > 0 &&
                                    <div className="card-footer h5 text-center">
                                        Click on an operator's name to remove them from {(SelectedMachine.machine_short_name !== '' && SelectedMachine.machine_short_name !== null && SelectedMachine.machine_short_name) || SelectedMachine.machine_name}. Then click the red confirmation button to confirm their removal.
                                    </div>
                                }                            
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="card h-100">
                                <div className="card-header h4 text-center">
                                    Instructions
                                </div>
                                <div className="card-body text-center">
                                    {
                                        parseInt(SelectedMachine.machine_ID) > 0 && 
                                        ((
                                            parseInt(SelectedMachine.machine_ID) > 0 && parseInt(SelectedMachine.part_ID) > 0 &&
                                            <>
                                                {/* 154 */}
                                                {parseInt(SelectedMachine.part_ID) === 37 && 
                                                    <>
                                                        {/* <div className="mb-2"><a href="../../images/MMM0154_Rev_A_Control_Plan.pdf" target = "_blank">MMM0154 Rev A Control Plan</a></div> */}
                                                        <div className="mb-2"><a href="../../images/MMM0154_A_Customer_Drawing.pdf" target = "_blank">MMM0154 Rev A Customer Drawing</a></div>
                                                        <div className="mb-2"><a href="../../images/MMM0154_Rev_A_Inspection_Instructions.pdf" target = "_blank">MMM0154 Rev A Inspection Instructions</a></div>
                                                        <div className="mb-2"><a href="../../images/MMM0154_Rev_A_TURNING_HANWHA.pdf" target = "_blank">MMM0154 Rev A Turning Hanwha</a></div>
                                                        <div className="mb-2"><a href="../../images/New/MMM0154_Rev_A_FMEA.pdf" target = "_blank">MMM0154 Rev A FMEA</a></div>
                                                        <div className="mb-2"><a href="../../images/New/MMM0154_Rev_A_Flow_Chart_Diagram.pdf" target = "_blank">MMM0154 Rev A Flow Chart Diagram</a></div>
                                                        <div className=""><a href="../../images/New/MMM0154_Rev_A_Control_Plan_2.pdf" target = "_blank">MMM0154 Rev A Control Plan</a></div>
                                                    </>
                                                }
                                                {/* 157 */}
                                                {( parseInt(SelectedMachine.part_ID) === 1 || parseInt(SelectedMachine.part_ID) === 36 ) && 
                                                    <>
                                                        <div className="mb-2"><a href="../../images/157_Inspection_Instructions.pdf" target = "_blank">MMM0157 Inspection Instruction</a></div>
                                                        <div className="mb-2"><a href="../../images/MMM0157_Rev_B_Turning_Tsugami.pdf" target = "_blank">MMM0157 Rev B Turning Tsugami</a></div>
                                                        <div className="mb-2"><a href="../../images/New/MMM0157_Rev_X13_Control_Plan.pdf" target = "_blank">MMM0157 Rev X13 Control Plan</a></div>
                                                        <div className="mb-2"><a href="../../images/New/MMM0157_Rev_X13_Customer_Drawing.pdf" target = "_blank">MMM0157 Rev X13 Customer Drawing</a></div>
                                                        <div className="mb-2"><a href="../../images/New/MMM0157_Rev_X13_Flow_Chart_Diagram.pdf" target = "_blank">MMM0157 Rev X13 Flow Chart Diagram</a></div>
                                                        <div className="mb-2"><a href="../../images/New/MMM0157_Rev_X13_Process_FMEA.pdf" target = "_blank">MMM0157 Rev X13 Process FMEA</a></div>
                                                        <div className="mb-2"><a href="../../images/New/MMM0157_Rev_F_OP10_Turning_Tsugami_BW209.pdf" target = "_blank">MMM0157 Rev F OP10 Turning Tsugami BW209</a></div>
                                                        <div className=""><a href="../../images/New/MMM0157_Rev_F_OP10_Turning_Tsugami_B0205.pdf" target = "_blank">MMM0157 Rev F OP10 Turning Tsugami BO205</a></div>
                                                    </>
                                                }
                                                {/* 161 */}
                                                {parseInt(SelectedMachine.part_ID) === 2 && 
                                                    <>
                                                        <div className="mb-2"><a href="../../images/161_Inspection_Instructions.pdf" target = "_blank">MMM0161 Inspection Instruction</a></div>
                                                        <div className="mb-2"><a href="../../images/MMM0161_Rev_A_Turning_Tsugami.pdf" target = "_blank">MMM0161 Rev A Turning Tsugami</a></div>
                                                        <div className="mb-2"><a href="../../images/New/MMM0161_X20_Process_FMEA.pdf" target = "_blank">MMM0161 Rev X20 FMEA</a></div>
                                                        <div className="mb-2"><a href="../../images/New/MMM0161_Rev_X20_Control_Plan.pdf" target = "_blank">MMM0161 Rev X20 Control Plan</a></div>
                                                        <div className="mb-2"><a href="../../images/New/MMM0161_Rev_X20_Customer_Drawing.pdf" target = "_blank">MMM0161 Rev X20 Customer Drawing</a></div>
                                                        <div className=""><a href="../../images/New/MMM0161_Rev_A_Flow_Chart_Diagram.pdf" target = "_blank">MMM0161 Rev A Flow Chart Diagram</a></div>
                                                    </>
                                                }
                                                {/* 162 */}
                                                {parseInt(SelectedMachine.part_ID) === 3 && 
                                                    <>
                                                        <div className="mb-2"><a href="../../images/162_Inspection_Instructions.pdf" target = "_blank">MMM0162 Inspection Instruction</a></div>
                                                        <div className="mb-2"><a href="../../images/MMM0162_Rev_B_Tsugami.pdf" target = "_blank">MMM0162 Rev B Tsugami</a></div>
                                                        <div className="mb-2"><a href="../../images/New/MMM0162_Rev_X20_FMEA.pdf" target = "_blank">MMM0162 Rev X20 FMEA</a></div>
                                                        <div className="mb-2"><a href="../../images/New/MMM0162_Rev_X20_Control_Plan.pdf" target = "_blank">MMM0162 Rev X20 Control Plan</a></div>
                                                        <div className="mb-2"><a href="../../images/New/MMM0162_REV_C.pdf" target = "_blank">MMM0162 Rev C</a></div>
                                                        <div className=""><a href="../../images/New/MMM0162_Rev_A_Flow_Chart_Diagram.pdf" target = "_blank">MMM0162 Rev A Flow Chart Diagram</a></div>
                                                        
                                                    </>
                                                }
                                                {/* 192 */}
                                                {parseInt(SelectedMachine.part_ID) === 38 && 
                                                    <>
                                                        <div className="mb-2"><a href="../../images/New/MMM0192_Rev_A_TSUGAMI_BW209Z.pdf" target = "_blank">MMM0192 Rev A Tsugami BW209Z</a></div>
                                                        <div className="mb-2"><a href="../../images/New/MMM0192_Rev_A_TSUGAMI.pdf" target = "_blank">MMM0192 Rev A Tsugami</a></div>
                                                        <div className="mb-2"><a href="../../images/New/MMM0192_Rev_X1_Control_Plan.pdf" target = "_blank">MMM0192 Rev X1 Control Plan</a></div>
                                                        <div className="mb-2"><a href="../../images/New/MMM0192_Rev_X1_Customer_Drawing.pdf" target = "_blank">MMM0192 Rev X1 Customer Drawing</a></div>
                                                        <div className="mb-2"><a href="../../images/New/MMM0192_Rev_X1_Flow_Chart_Diagram.pdf" target = "_blank">MMM0192 Rev X1 Flow Chart Diagram</a></div>
                                                        <div className="mb-2"><a href="../../images/New/MMM0192_Rev_X1_FMEA.pdf" target = "_blank">MMM0192 Rev X1 FMEA</a></div>
                                                        <div className=""><a href="../../images/New/MMM0192.1_PR_WI_Turning_Work_Instructions.pdf" target = "_blank">MMM0192.1 PR-WI Turning Work Instructions</a></div>
                                                    </>
                                                }
                                                {/* 60 */}
                                                {parseInt(SelectedMachine.part_ID) === 4 && 
                                                    <>
                                                        <div className="mb-2"><a href="../../images/Axon feature 22.jpg" target = "_blank">Axon Feature 22</a></div>
                                                        <div className="mb-2"><a href="../../images/Axon Features 6-14.jpg" target = "_blank">Axon Features 6-14</a></div>
                                                        <div className="mb-2"><a href="../../images/Axon Way 1.jpg" target = "_blank">Axon Way 1</a></div>
                                                        <div className="mb-2"><a href="../../images/Axon Way 2.jpg" target = "_blank">Axon Way 2</a></div>
                                                        <div className="mb-2"><a href="../../images/MMC0060_FLASH_INSPECTION_INSTRUCTIONS.pdf" target = "_blank">Flash Inspection Instructions</a></div>
                                                        <div className="mb-2"><a href="../../images/MMC0060_Balloned.pdf" target = "_blank">MMC0060 Balloned</a></div>
                                                        <div className="mb-2"><a href="../../images/New/MMC0060_PR_WI_Probe_Cleaning_Packaging.pdf" target = "_blank">MMC0060 PR-WI Probe Cleaning Packaging</a></div>
                                                    </>
                                                }
                                            </>
                                        ) || 
                                        <div className="">No Instructions Attached To This Machine / Part</div>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="card h-100">

                                {/* BULLETIN CARD HEADER */}
                                <div className="card-header h4 text-center">
                                    <span className="float-start">Bulletin</span> 
                                    {
                                        parseInt(SelectedMachine.machine_ID) > 0 && SelectedMachineBulletin.editing === false &&
                                        <button  
                                            className="btn float-end border-primary border-3 text-primary py-0" 
                                            onClick={() => setSelectedMachineBulletin({ ...SelectedMachineBulletin, editing: true, old_note: SelectedMachineBulletin.note})}>
                                            <FontAwesomeIcon icon="fa-pencil" className="mt-1 me-2" />Edit
                                        </button>
                                    }
                                    {
                                        parseInt(SelectedMachine.machine_ID) > 0 && SelectedMachineBulletin.editing === true &&
                                        <button  
                                            className="btn float-end border-secondary border-3 text-secondary py-0" 
                                            onClick={() => setSelectedMachineBulletin({ ...SelectedMachineBulletin, editing: false, note: SelectedMachineBulletin.old_note })}>
                                            <FontAwesomeIcon icon="fa-times" className="mt-1 me-2" />Close
                                        </button>
                                    }
                                </div>

                                {/* BULLETIN CARD BODY */}
                                <div className="card-body">
                                    {
                                        parseInt(SelectedMachine.machine_ID) > 0 &&
                                        <>
                                            {/* EDITING BULLETIN */}
                                            {
                                                SelectedMachineBulletin.editing === true &&
                                                    <>
                                                    <div className="mb-1">
                                                        <button  
                                                            className="btn border-secondary border-3 text-secondary py-0 me-1" 
                                                            onClick={() => setSelectedMachineBulletin({ ...SelectedMachineBulletin, note: '' })}>Clear
                                                        </button>
                                                        or add to existing note
                                                    </div>
                                                    <textarea className="form-control" id="bulletin" placeholder="Enter notes here" value={SelectedMachineBulletin.note} rows="10" onChange={(event) => changeSelectedMachineBulletinInputValue(event.target.value)} />
                                                    </>
                                            }
                                            {/* DISPLAYING BULLETIN */}
                                            {
                                                SelectedMachineBulletin.editing === false &&
                                                <div className="">
                                                    <>
                                                        { 
                                                            (parseInt(SelectedMachineBulletin.machine_bulletin_ID) > 0 && 
                                                            <div style={{whiteSpace: 'pre-wrap'}}>
                                                                {SelectedMachineBulletin.note !== '' && SelectedMachineBulletin.note !== null && SelectedMachineBulletin.note}{SelectedMachineBulletin.note === '' && <div className="alert alert-info text-center">Bulletin Cleared</div>}
                                                            </div>) ||
                                                            <div className="alert alert-warning">No Bulletin Yet</div>
                                                        }
                                                    </>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>

                                {/* FOOTER */}
                                    {/* NO WORKCENTER SELECTED */}
                                    {
                                        parseInt(SelectedMachine.machine_ID) === 0 &&
                                        <div className="card-footer text-danger text-center">
                                            Choose a Work Center to view the bulletin
                                        </div>
                                    }
                                    {/* BULLETIN CREATOR / DATE DISPLAYED */}
                                    {
                                        parseInt(SelectedMachine.machine_ID) > 0 && parseInt(SelectedMachineBulletin.machine_bulletin_ID) > 0 && SelectedMachineBulletin.editing === false && 
                                        <div className="card-footer text-center">
                                            {SelectedMachineBulletin.first_name} {SelectedMachineBulletin.last_name} {dayjs(SelectedMachineBulletin.date_created).format('M-D-YY h:mm a')}
                                        </div>
                                    }
                                    {/* BULLETIN EDITING - SAVE BUTTON */}
                                    {
                                        parseInt(SelectedMachine.machine_ID) > 0 && SelectedMachineBulletin.editing === true && 
                                        <div className="card-footer text-center">
                                            <button className="btn fw-bold col-auto border-success border-3 text-success" disabled={SelectedMachineBulletin.note === SelectedMachineBulletin.old_note} onClick={() => saveMachineBulletin('save_bulletin', SelectedMachineBulletin)}>
                                                <FontAwesomeIcon icon="fa-floppy-disk" className="mt-1 me-2" />Save Bulletin
                                            </button>                                    
                                        </div>
                                    }
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export const ControlPanelComponent = compose(
	queryWithLoading({
		gqlString: GetControlPanelPage,
        name: "componentData",
        variablesFunction: () => ({ active: 1 })
	}),
)(ControlPanelContent)