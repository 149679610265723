import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { BulletinComponent } from "../machine_bulletins/machine_bulletin_component.js";
import { ProductionRecordsReportComponent } from "../production_records/production_records_report_component.js";

// GRAPHQL QUERY
// import { 
//     GetMachineBulletins,
//     GetMachineBulletinsAxon,
//     GetMachineBulletinsPage,
//     SaveMachineBulletin
// } from './machine_bulletins_graphql.js';

export function ShopTvComponent(props) {
console.log({props})

    // Get URL parameters - display_ID
    let URLData = useParams();

    let DefaultDisplay = {
        Pages: [],
        current_page: '',
        current_page_index: 0
    }

    if ( parseInt(URLData.display_ID) === 1) {
        DefaultDisplay = {
            ...DefaultDisplay,
            Pages: [ 'Bulletin', 'Production' ],
            current_page: 'Bulletin'
        }
    }

    const [Display, setDisplay] = useState({ ...DefaultDisplay });

    const [time, setTime] = useState(new Date());

    // Change the Display Page every interval, 1000 = 1 second
    useEffect(() => {
        const interval = setInterval(() => {

            // Only update Display if we have Pages to change between
            if ( Display.Pages.length > 0 ) {
                let next_page_index = Display.current_page_index < Display.Pages.length-1 ? Display.current_page_index+1 : 0;
                setDisplay({ ...Display, current_page_index: next_page_index, current_page: Display.Pages[next_page_index] });
            }
            // setTime(new Date());
        }, 300000);
        return () => clearInterval(interval);
    }, [Display]);
    

    // PAGE OUTPUT
    
    // If URL display = 1, show that
    if ( parseInt(URLData.display_ID) === 1 ) {
        if ( Display.current_page === 'Bulletin' ) {
            return (
                <>
                    <div className="row justify-content-center mb-1">
                        <div className="col-auto btn btn-success" onClick={() => setDisplay({ ...Display, current_page: 'Production', current_page_index: 1})}>Click Here To Go To Production Records</div>
                    </div>
                    <BulletinComponent />
                </>
            )
        } else if ( Display.current_page === 'Production' ) {
            return (
                <>
                    <div className="row justify-content-center mb-1">
                        <div className="col-auto btn btn-success" onClick={() => setDisplay({ ...Display, current_page: 'Bulletin', current_page_index: 0})}>Click Here To Go To Machine Bulletins</div>
                    </div>
                    <ProductionRecordsReportComponent />
                </>
            )
        }
    } else {
        return (
            <>
                <div>Stuff goes here</div>
            </>
        )
    }
}

