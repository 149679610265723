import { gql } from '@apollo/client';

export const GetMachines = gql`
    query GetMachines {
        machines {
            machine_ID,
            machine_name,
            machine_short_name,
            creator_ID,
            archiver_ID,
            date_created,
            date_archived,
            part_ID
        }
    }
`;

export const GetMachinesByType = gql`
    query GetMachinesByType($machine_type_ID: Int) {
        machines_by_type(machine_type_ID: $machine_type_ID) {
            machine_ID,
            machine_name,
            machine_short_name
        }
    }
`;

export const SaveMachine = gql`
	mutation machineSave($input: MachineInput, $machine_ID: ID) {
		machineSave (input: $input) {
            UpdatedMachine (machine_ID: $machine_ID) {
                machine_ID
                machine_name
                machine_short_name
                machine_status_ID
                creator_ID
                archiver_ID
                date_created
                date_archived
                part_ID
            }
            Machines {
                machine_ID
                machine_name
                machine_short_name
                machine_status_ID
                creator_ID
                archiver_ID
                date_created
                date_archived
                part_ID
            }
		}
	}
`;



			// Response {
			// 	success
			// 	message
			// }



