import { gql } from '@apollo/client';

export const GetToolingTypes = gql`
    query GetToolingTypes {
        tooling_types {
            tooling_type_ID,
            active,
            tooling_type
        }
    }
`;

export const SaveToolingTypes = gql`
	mutation toolingTypeSave($input: ToolingTypeInput, $tooling_type_ID: ID) {
		toolingTypeSave (input: $input) {
            UpdatedToolingType (tooling_type_ID: $tooling_type_ID) {
                tooling_type_ID,
                active,
                tooling_type
            }
            ToolingTypes {
                tooling_type_ID,
                active,
                tooling_type
            }
		}
	}
`;