import { gql } from '@apollo/client';

export const GetChecksheetProcesses = gql`
    query GetChecksheetProcesses($active: Int, $checksheet_ID: Int) {
        checksheet_processes(active: $active, checksheet_ID: $checksheet_ID) {
            Checksheet {
                control_plan_number
                instructions
                inspection_step
                note
                operation_name
                operation_number
                required_every
                responsibility
            }
            ChecksheetProcesses {
                checksheet_process_ID
                checksheet_ID
                char_number
                char_product
                control_method
                evaluation_measurement_technique
                input_count
                input_type
                reaction_plan
                sample_size_frequency
                specifications
                sort_by
                tolerance_max
                tolerance_min
                tolerance_target
                tolerance_units
                value
            }
        }
    }
`; 
        //    checksheet_process_ID
        //     active
        //     archiver_ID
        //     creator_ID
        //     date_archived
        //     date_created
        //     checksheet_ID
        //     char_number
        //     char_product
        //     control_method
        //     evaluation_measurement_technique
        //     input_count
        //     input_type
        //     inspection_step
        //     instructions
        //     note
        //     operation_name
        //     operation_number
        //     reaction_plan
        //     required_every
        //     responsibility
        //     sample_size_frequency
        //     specifications
        //     sort_by
        //     tolerance_max
        //     tolerance_min
        //     tolerance_target
        //     tolerance_units

export const GetChecksheets = gql`
    query GetChecksheets {
        checksheets {
            checksheet_ID,
            active,
            archiver_ID,
            creator_ID,
            date_archived,
            date_created,
            break_end_1,
            break_end_2,
            break_start_1,
            break_start_2,
            lunch_end,
            lunch_start,
            lunch_paid,
            note,
            checkhsheet_end,
            checkhsheet_group,
            checkhsheet_hours_worked,
            checkhsheet_name,
            checkhsheet_start
        }
    }
`;


// Used for checksheet_report_component.js on load
export const GetChecksheetRecords = gql`
    query GetChecksheetRecords($date_end: DateTime, $date_start: DateTime) {
        checksheets_records(date_end: $date_end, date_start: $date_start) {
            checksheet_record_ID
            checksheet_ID
            creator_ID
            date_created
            date_updated
            job_ID
            machine_ID
            part_ID
            passed
            updater_ID
        }
    }
`;

// Used for checksheet_report_component.js on load
export const GetChecksheetRecords2 = gql`
    query GetChecksheetRecords2($date_end: DateTime, $date_start: DateTime) {
        checksheets_records2(date_end: $date_end, date_start: $date_start) {
            date_created
            first_name
            last_name
            machine_ID
            machine_name
            machine_short_name
            part_ID
            part_number
            production_record_ID
            shift_group
        }
    }
`;

// Used for checksheet_report_component.js on load
export const GetChecksheetReportPage = gql`
    query GetCheckseetReportPage {
        parts {
            part_ID,
            archiver_ID,
            creator_ID,
            customer_ID,
            date_archived,
            date_created,
            hourly_standard_rate,
            image_ID,
            image_filename,
            part_number,
            sale_price,
            scrap_percentage_cost_sheet,
            standard_tool_cost
        }
        users {
            user_ID,
            creator_ID,
            date_birth,
            date_created,
            email,
            first_name,
            hourly_rate,
            last_name,
            overtime_rate,
            user_type_ID
        }
    }
`;

export const SaveChecksheetRecord = gql`
	mutation checksheetRecordSave($input: ChecksheetRecordInput) {
		checksheetRecordSave (input: $input) {
            ChecksheetRecord {
                checksheet_record_ID
                checksheet_ID
                creator_ID
                date_created
                date_updated
                job_ID
                machine_ID
                part_ID
                passed
                updater_ID
            }
		}
	}
`;

export const SaveChecksheet = gql`
	mutation checksheetSave($input: ChecksheetInput) {
		checksheetSave (input: $input) {
            Checksheets {
                checksheet_ID,
                active,
                archiver_ID,
                creator_ID,
                date_archived,
                date_created,
                break_end_1,
                break_end_2,
                break_start_1,
                break_start_2,
                lunch_end,
                lunch_start,
                lunch_paid,
                note,
                checkhsheet_end,
                checkhsheet_group,
                checkhsheet_hours_worked,
                checkhsheet_name,
                checkhsheet_start
            }
		}
	}
`;