import React from "react";
import dayjs from 'dayjs';

// import { Breadcrumbs } from "react-breadcrumbs";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';	// IMPORTANT: Add any icons you want to use to the index.js import { ... } from '@fortawesome/free-solid-svg-icons' AND library.add(...)
import { NavLink 
, useNavigate
// , Navigate 
} from "react-router-dom";
import { useAuthUser
// , useSignIn
, useSignOut } from 'react-auth-kit'

// IWD CREATED
import  { 
    AppRoutes 
} from "./routes.js";

import { UserClockInOutLogInOut } from '../auth/auth_graphql.js';
import { useMutation } from '@apollo/client';

// import { Logout } from "../auth/logout_component";

// INCLUDE BOOTSTRAP 5 COMPONENTS - REACTSTRAP
import { Collapse, DropdownToggle, DropdownMenu, DropdownItem, Nav, Navbar, UncontrolledDropdown } from 'reactstrap';
// import { withSession } from "../utilities/session";

// import { withTranslate } from '../translations/IWDTranslation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// HELP COLUMN


// TOP LEVEL APPLICATION FRAMEWORK
export function ApplicationLayout() {
    const auth = useAuthUser();
    const navigate = useNavigate();
    // const signIn = useSignIn();
    const signOut = useSignOut()
    const [clock_out_logout] = useMutation(UserClockInOutLogInOut);

	// constructor(props) {
	// 	super(props)
	// 	this.state = {
	// 		leftNavCollapsed: false,						// left nav - defaulting to open and showing labels with icons
	// 		topNavOpen: false
	// 	}
	// }
	// // handler to update the application state when collapsing the left navigation - will show icons only in left nav when collapsed
	// toggleLeftNav = (e) => {
	// 	e.preventDefault();
	// 	this.setState({
	// 		leftNavCollapsed: !this.state.leftNavCollapsed
	// 	})
	// }
	// toggleTopNav = (e) => {
	// 	//e.preventDefault();
	// 	this.setState({
	// 		topNavOpen: !this.state.topNavOpen
	// 	})
	// }

	/* VARY UI BASED ON USER TYPE ID
		1	NS Admin
		2	Customer Admin
		3	Customer Staff
        4	Athlete
        
	*/

    // For hourly employees, enter their time_actual_out into their time_clock_record_ID
    async function handleClockOutSignOut() {

        // OLD TCR: Calculate the total hours actually worked on this shift here since we have the info
        let actual_datetime_in = `${auth().User.date_scheduled} ${auth().User.time_actual_in}`;
        let time_actual_hours = parseFloat(dayjs().diff(dayjs(actual_datetime_in), 'h', true)).toFixed(2);

        // NEW TCRA: 
        let date_actual_in = `${auth().User.date_actual_in}`;
        console.log({date_actual_in})

        const result = await clock_out_logout({ variables: { input: { 
            action: 'clock_out_logout', 
            date_actual_in: auth().User.date_actual_in, 
            date_actual_out: dayjs().format('YYYY-MM-DD H:mm:ss'), 
            shift_ID: parseInt(auth().User.shift_ID), 
            time_actual_hours, 
            time_actual_out: dayjs().format('H:mm:ss'), 
            time_clock_record_ID: parseInt(auth().User.time_clock_record_ID), 
            time_clock_records_actual_ID: parseInt(auth().User.time_clock_records_actual_ID), 
            user_ID: parseInt(auth().User.user_ID), 
            user_type_ID: parseInt(auth().User.user_type_ID) 
        } } });

        // localStorage.clear()
        if ( result.data && result.data.userClockInOutLogInOut && result.data.userClockInOutLogInOut.message ) {
            signOut();
            navigate("/")
            // const Logout = result.data.userClockInOutLogInOut;
            // // Server did not receive a user_ID, did not logout.
            // if(Logout.message === 'no_user_ID') {
            
            // } else if(Logout.message === 'no_user_ID') {
            
            // } else if(Logout.message === 'successful_logout') {
            
            // } else if(Logout.message === 'successful_clockout') {
            
            // } else if(Logout.message === 'already_clocked_out') {
            
            // } else if(Logout.message === 'failed_clockout') {
            
            // }

        } else {
            // Something went wrong, tell Barrett
        }
    }

    async function handleSignOut() {

        await clock_out_logout({ variables: { input: { action: 'logout', shift_ID: parseInt(auth().User.shift_ID), time_actual_out: dayjs().format('H:mm:ss'), user_ID: parseInt(auth().User.user_ID), user_type_ID: parseInt(auth().User.user_type_ID) } } })
        // localStorage.clear()
        signOut();
        navigate("/")
        // navigate("../login", { replace: true });
        
        //<Navigate to="/login" replace={true}  />
    }

    return (
        <div className="container-fluid">
            <div id="root-bg"></div>
            <div id="top-container" className="flex-child column-parent">	{/* TOP CONTAINER INSIDE OF ROOT */}
                {
                    auth() && auth().User.user_name !== "inspection" &&
                    <Navbar color="white" light expand="sm" className="border-bottom fixed-top flex-child ">
                        {/* <NavLink to={`/`} className="navbar-brand" exact="true">
                            <img src={process.env.PUBLIC_URL + "/images/logos/cloud_logo_small.png"} alt="Home" />
                        </NavLink> */}
                        {/* <NavbarToggler onClick={this.toggleTopNav} /> */}
                        {
                            auth().User.user_type_ID < 3 &&
                            <Nav className="mr-auto" navbar>
                                <NavLink to={`/control_panel`} className="navbar-brand" exact="true">
                                    <button className="btn btn-success">Control Panel</button>
                                </NavLink>
                                <NavLink to={`/bulletin`} className="navbar-brand" exact="true">
                                    <button className="btn btn-success">Machine Bulletins</button>
                                </NavLink>
                                {/* {
                                    auth().User.work_schedule_ID > 0 &&
                                    <NavLink to={`/employee_schedule`} className="navbar-brand" exact="true">
                                        <button className="btn btn-success">{auth().User.first_name}'s Schedule</button>
                                    </NavLink>
                                } */}
                            </Nav>
                        }
                        {
                            auth().User.user_type_ID > 2 &&
                            <Collapse  navbar>
                                <Nav className="mr-auto" navbar>
                                    <UncontrolledDropdown nav setActiveFromChild>
                                        <DropdownToggle caret className="me-3">
                                            Production
                                        </DropdownToggle>
                                        <DropdownMenu className="bg-white">
                                            <DropdownItem tag="div"><NavLink to={`/checksheet_report`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-list" /> <span className="nav-label">Checksheet Report</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/control_panel`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-house" /> <span className="nav-label">Control Panel</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/bulletin`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-clipboard" /> <span className="nav-label">Machine Bulletins</span></NavLink></DropdownItem>
                                            {/* <DropdownItem tag="div"><NavLink to={`/downtime`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-hourglass" /> <span className="nav-label">Downtime</span></NavLink></DropdownItem> */}
                                            {/* <DropdownItem tag="div"><NavLink to={`/production_records`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-list" /> <span className="nav-label">Production Records</span></NavLink></DropdownItem> */}
                                            <DropdownItem tag="div"><NavLink to={`/production_records_report`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-list" /> <span className="nav-label">Production Records Report</span></NavLink></DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    {/* <UncontrolledDropdown nav setActiveFromChild>
                                        <DropdownToggle caret className="me-3">
                                            Engineering
                                        </DropdownToggle>
                                        <DropdownMenu className="bg-white">
                                            <DropdownItem tag="div"><NavLink to={`/checksheets`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-check" /> <span className="nav-label">Checksheets</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/control_plans`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-layer-group" /> <span className="nav-label">Control Plans</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/FMEA`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-folder-open" /> <span className="nav-label">FMEA</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/job_allocation`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-store" /> <span className="nav-label">Job Allocation</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/part_list`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-gear" /> <span className="nav-label">Part List</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/part_sheets`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-gear" /> <span className="nav-label">Part Sheets</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/part_specifications`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-gear" /> <span className="nav-label">Part Specifications</span></NavLink></DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown> */}
                                    {/* <UncontrolledDropdown nav setActiveFromChild>
                                        <DropdownToggle caret className="me-3">
                                            Inventory
                                        </DropdownToggle>
                                        <DropdownMenu className="bg-white">
                                            <DropdownItem tag="div"><NavLink to={`/inventory`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-boxes-stacked" /> <span className="nav-label">Inventory</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/inventory_reports`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-folder-open" /> <span className="nav-label">Inventory Reports</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/purchase_orders`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-store" /> <span className="nav-label">Purchase Orders</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/purchasing`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-money-bill-1" /> <span className="nav-label">Purchasing</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/scrap`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-face-frown" /> <span className="nav-label">Scrap</span></NavLink></DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown> */}
                                    <UncontrolledDropdown nav setActiveFromChild>
                                        <DropdownToggle caret className="me-3">
                                            Human Resources
                                        </DropdownToggle>
                                        <DropdownMenu className="bg-white">
                                            {/* <DropdownItem tag="div"><NavLink to={`/attendance_summary`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-users" /> <span className="nav-label">Attendance Summary</span></NavLink></DropdownItem> */}
                                            {/* <DropdownItem tag="div"><NavLink to={`/employee_details`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-id-card" /> <span className="nav-label">Employee Details</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/hr_reports`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-folder-open" /> <span className="nav-label">HR Reports</span></NavLink></DropdownItem> */}
                                            <DropdownItem tag="div"><NavLink to={`/employees`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-users" /> <span className="nav-label">Employees</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/time_clock_records`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-calendar-alt" /> <span className="nav-label">NEW: Schedules</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/schedules`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-calendar-alt" /> <span className="nav-label">OLD: Schedules</span></NavLink></DropdownItem>
                                            {/* <DropdownItem tag="div"><NavLink to={`/skills_matrix`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-folder-tree" /> <span className="nav-label">Skills Matrix</span></NavLink></DropdownItem> */}
                                            <DropdownItem tag="div"><NavLink to={`/shifts`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-clock" /> <span className="nav-label">Shifts</span></NavLink></DropdownItem>
                                            {/* <DropdownItem tag="div"><NavLink to={`/time_clock_records`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-clock" /> <span className="nav-label">Time Clock Records</span></NavLink></DropdownItem> */}
                                            <DropdownItem tag="div"><NavLink to={`/work_schedules`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-calendar-alt" /> <span className="nav-label">Work Schedules</span></NavLink></DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    {/* <UncontrolledDropdown nav setActiveFromChild>
                                        <DropdownToggle caret className="me-3">
                                            Maintenance
                                        </DropdownToggle>
                                        <DropdownMenu className="bg-white">
                                            <DropdownItem tag="div"><NavLink to={`/`} className="nav-link p-0" ><span className="nav-label"></span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/`} className="nav-link p-0" ><span className="nav-label"></span></NavLink></DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown> */}
                                    {/* <UncontrolledDropdown nav setActiveFromChild>
                                        <DropdownToggle caret className="me-3">
                                            Quality
                                        </DropdownToggle>
                                        <DropdownMenu className="bg-white">
                                            <DropdownItem tag="div"><NavLink to={`/customer_returns`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-boxes-stacked" /> <span className="nav-label">Customer Returns</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/data_collection`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-folder-open" /> <span className="nav-label">Data Collection</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/gage_control`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-store" /> <span className="nav-label">Gage Control</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/spc_inspection_data`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-folder-open" /> <span className="nav-label">SPC Inspection Data</span></NavLink></DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown> */}
                                    {/* <UncontrolledDropdown nav setActiveFromChild>
                                        <DropdownToggle caret className="me-3">
                                            Receiving
                                        </DropdownToggle>
                                        <DropdownMenu className="bg-white">
                                            <DropdownItem tag="div"><NavLink to={`/customer_returns`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-store" /> <span className="nav-label">Customer Returns</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/general_receiving`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-tags" /> <span className="nav-label">General Receiving</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/receipt_history`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-boxes-stacked" /> <span className="nav-label">Receipt History</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/receive_material_components`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-boxes-stacked" /> <span className="nav-label">Receive Material / Components</span></NavLink></DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown> */}
                                    {/* <UncontrolledDropdown nav setActiveFromChild>
                                        <DropdownToggle caret className="me-3">
                                            Shipping
                                        </DropdownToggle>
                                        <DropdownMenu className="bg-white">
                                            <DropdownItem tag="div"><NavLink to={`/customer_shipping_menu`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-truck" /> <span className="nav-label">Customer Shipping Menu</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/subcontractor_shipping`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-truck-fast" /> <span className="nav-label">Subcontractor Shipping Menu</span></NavLink></DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown> */}
                                    <UncontrolledDropdown nav setActiveFromChild>
                                        <DropdownToggle caret className="me-3">
                                            Shop
                                        </DropdownToggle>
                                        <DropdownMenu className="bg-white">
                                            {/* 
                                                This was old code example of how to toggleTopNav
                                                <DropdownItem tag="div"><NavLink onClick={this.toggleTopNav} to={`/machines`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-toilets-portable" /> <span className="nav-label">Machines</span></NavLink></DropdownItem>
                                            */}
                                            <DropdownItem tag="div"><NavLink to={`/machines`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-toilets-portable" /> <span className="nav-label">Machines</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/materials`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-dolly" /> <span className="nav-label">Materials</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/parts`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-gear" /> <span className="nav-label">Parts</span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/tooling`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-screwdriver-wrench" /> <span className="nav-label">Tooling</span></NavLink></DropdownItem>
                                            {/* <DropdownItem tag="div"><NavLink to={`/tooling`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-toolbox" /> <span className="nav-label">Tooling</span></NavLink></DropdownItem> */}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>   
                                    <UncontrolledDropdown nav setActiveFromChild>
                                        <DropdownToggle caret className="me-3">
                                            Admin
                                        </DropdownToggle>
                                        <DropdownMenu className="bg-white">
                                            <DropdownItem tag="div"><NavLink to={`/admin_messages`} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-comment" /> <span className="nav-label">Messages</span></NavLink></DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    {/* <UncontrolledDropdown nav setActiveFromChild>
                                        <DropdownToggle caret className="me-3">
                                            Shop Floor
                                        </DropdownToggle>
                                        <DropdownMenu className="bg-white">
                                            <DropdownItem tag="div"><NavLink to={`/`} className="nav-link p-0" ><span className="nav-label"></span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/`} className="nav-link p-0" ><span className="nav-label"></span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/`} className="nav-link p-0" ><span className="nav-label"></span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/`} className="nav-link p-0" ><span className="nav-label"></span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/`} className="nav-link p-0" ><span className="nav-label"></span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/`} className="nav-link p-0" ><span className="nav-label"></span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/`} className="nav-link p-0" ><span className="nav-label"></span></NavLink></DropdownItem>
                                            <DropdownItem tag="div"><NavLink to={`/`} className="nav-link p-0" ><span className="nav-label"></span></NavLink></DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown> */}

                                    {/* <UncontrolledDropdown nav setActiveFromChild>
                                        <DropdownToggle caret className="ml-auto">
                                            { `${firstName} ${lastName} ` }
                                        </DropdownToggle>
                                        <DropdownMenu className="bg-white">
                                            <DropdownItem tag="div"><NavLink to={`/my_account`} className="nav-link p-0" ><FontAwesomeIcon icon="user-circle" />My Account</NavLink></DropdownItem>
                                            <DropdownItem divider />
                                            <div className="text-center"></div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown> */}
                                </Nav>
                            </Collapse>
                        }
                        <Nav>
                            <UncontrolledDropdown nav setActiveFromChild>
                                <DropdownToggle caret className="me-3">
                                    {auth().User.first_name} {auth().User.last_name}
                                </DropdownToggle>
                                <DropdownMenu className="bg-white">
                                    {
                                        (parseInt(auth().User.user_type_ID) < 3 || parseInt(auth().User.user_ID) === 1 )&&
                                        <>
                                            <DropdownItem tag="div"><NavLink onClick={() => handleClockOutSignOut()} className="nav-link mb-5 p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-clock" /> <span className="nav-label h5">Clock Out</span></NavLink></DropdownItem>
                                            {/* <DropdownItem tag="div"><NavLink onClick={() => handleSignOut()} className="nav-link mb-5 p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-clock" /> <span className="nav-label h5">Clock Out - Stay Logged In</span></NavLink></DropdownItem> */}
                                            <DropdownItem tag="div"><NavLink onClick={() => handleSignOut()} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-arrow-right-from-bracket" /> <span className="nav-label h5">Logout - Stay Clocked In</span></NavLink></DropdownItem>
                                        </>
                                    }
                                    {
                                        parseInt(auth().User.user_type_ID) > 2 && parseInt(auth().User.user_ID) !== 1 &&
                                        <DropdownItem tag="div"><NavLink onClick={() => handleSignOut()} className="nav-link p-0" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-arrow-right-from-bracket" /> <span className="nav-label">Logout</span></NavLink></DropdownItem>
                                    }
                                </DropdownMenu>

                                {/* For hourly employees that clock in / out, show option in User's dropdown to clock out, and logout and clock out */}
                            </UncontrolledDropdown>
                        </Nav>

                        {/* {!auth() && <NavLink to={`/login`} className="btn border-success border-3 fw-bold text-success me-3" ><FontAwesomeIcon icon="fas fa-solid fa-fw fa-arrow-right-to-bracket" className="me-2" />Sign In</NavLink>} */}

                    </Navbar>                    
                }
                {/* CONTENT CONTAINER */}
                {
                    auth() && 
                    <div id="content-container" className="flex-child row-parent" style={{marginTop: 106 + 'px'}}>
                        {/* MAIN CONTENT CONTAINER */}
                        <div id="main-content-container" className="m-4 flex-child column-parent">
                            <div id="main-content" className="flex-child row-parent">	{/* MAIN CONTENT COLUMNS */}
                                <AppRoutes />
                                {/* <PublicRoutes /> */}
                            </div>
                        </div>
                    </div>
                }
                {
                    auth() === null && 
                    <div id="content-container" className="flex-child row-parent" style={{marginTop: 50 + 'px'}}>
                        {/* MAIN CONTENT CONTAINER */}
                        <div id="main-content-container" className="m-4 flex-child column-parent">
                            <div id="main-content" className="flex-child row-parent">	{/* MAIN CONTENT COLUMNS */}
                                {
                                    window.location.pathname !== '/inspection' &&
                                    <Navbar color="white" light expand="sm" className="border-bottom py-0 fixed-top flex-child ">
                                        <Nav className="mr-auto" navbar>
                                            <NavLink to={`/shop_tv/1`} className="navbar-brand" exact="true">
                                                <button className="btn btn-sm btn-success">Shop TV</button>
                                            </NavLink>
                                            <NavLink to={`/bulletin`} className="navbar-brand" exact="true">
                                                <button className="btn btn-sm btn-success">Bulletins</button>
                                            </NavLink>
                                            <NavLink to={`/inspection`} className="navbar-brand" exact="true">
                                                <button className="btn btn-sm btn-success">Inspection</button>
                                            </NavLink>
                                        </Nav>
                                        <Nav >
                                            <NavLink to={`/login`} className="navbar-brand" exact="true">
                                                <button className="btn btn-sm btn-success">Login</button>
                                            </NavLink>
                                        </Nav>
                                    </Navbar>
                                }
                                <AppRoutes />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}


// const ApplicationLayout = withSession(ApplicationLayoutComponent);
// const ApplicationLayout = ApplicationLayoutComponent;

// export { ApplicationLayout, DetailColumn, SidebarColumn }
// export default ApplicationLayout
